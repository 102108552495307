<section class="p-4 basic-user-subscription-dialog">
	<div class="row">
		<div class="col">
			<mat-icon [matDialogClose]="false" class="close-icon">close</mat-icon>
		</div>
	</div>

	<div class="row justify-content-center">
		<div class="col promotional-content-container">
			<ch-promotional-content>
				<img class="img-fluid" src="assets/images/illustration-paywall.png" alt="paywall-illustration" />

				<span class="content-heading">{{ data.heading | translate }}</span>

				<ul class="content-list">
					<li *ngFor="let point of data.points">{{ point | translate }}</li>
				</ul>

				<div class="action-buttons">
					<button *ngIf="isOwner$ | async" [matDialogClose]="true" class="w-100" color="accent" mat-flat-button>
						{{ data.upgradeButtonText | translate }}
						<ng-template #defaultUpgradeButtonText></ng-template>
					</button>
				</div>
			</ch-promotional-content>
		</div>
	</div>
</section>
