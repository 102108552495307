<div class="ch-dialog">
	<div class="d-flex align-items-center mb-2">
		<h2 class="flex-grow-1 m-0" data-cy="create-folder-title">{{ 'files-section.dialog.create-folder.title' | translate }}</h2>
		<mat-icon class="pointer" mat-dialog-close data-cy="create-folder-close">close</mat-icon>
	</div>
	<mat-dialog-content>
		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>{{ 'files-section.dialog.create-folder.label' | translate }}</mat-label>
			<input
				trim="blur"
				data-cy="new-folder-editbox"
				[(ngModel)]="folderName"
				matInput
				placeholder="{{ 'files-section.dialog.create-folder.label' | translate }}"
			/>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions class="justify-content-center p-0">
		<button [disabled]="!folderName" [mat-dialog-close]="folderName" color="accent" mat-flat-button data-cy="create-folder-button">
			{{ 'files-section.dialog.create-folder.confirm-btn' | translate }}
		</button>
	</mat-dialog-actions>
</div>
