import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { initProfileAction, updateLastActiveAction } from '../actions/profile.actions';
import { selectUserId } from '../selectors/app.selectors';
import moment from 'moment';
import { AppState } from '../state/app.state';
import { WINDOW } from '@craftnote/shared-utils';

@Injectable()
export class ProfileEffects {
	constructor(
		private readonly profileService: ProfileService,
		private readonly store: Store<AppState>,
		private readonly actions$: Actions,
		@Inject(WINDOW) private readonly windowRef: Window,
	) {}

	updateUserStateEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateLastActiveAction),
				switchMap(() => {
					return this.store.pipe(
						select(selectUserId),
						filter((id) => !!id),
						take(1),
					);
				}),
				tap(async (userId) => {
					await this.profileService.updateProfile(userId, {
						lastActive: moment().unix(),
						timezone: this.windowRef['Intl'].DateTimeFormat().resolvedOptions().timeZone,
					});
				}),
			),
		{ dispatch: false },
	);

	ngrxOnInitEffects(): Action {
		return initProfileAction();
	}
}
