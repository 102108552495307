import {
	bufferToggle,
	distinctUntilChanged,
	filter,
	merge,
	mergeMap,
	Observable,
	share,
	startWith,
	windowToggle,
} from 'rxjs';
import { delay, retryWhen, shareReplay, take, tap } from 'rxjs/operators';
import { last } from 'lodash';

export const takeWhenTrue =
	<T>(condition: Observable<boolean>) =>
	(source: Observable<T>) =>
		new Observable<T>((observer) => {
			return source.subscribe({
				next(x): void {
					condition.pipe(take(1)).subscribe((isTrue) => {
						if (isTrue) {
							observer.next(x);
						}
					});
				},
			});
		});

export const skipWhenTrue =
	<T>(condition: Observable<boolean>) =>
	(source: Observable<T>) =>
		new Observable<T>((observer) => {
			return source.subscribe({
				next(x): void {
					condition.pipe(take(1)).subscribe((isTrue) => {
						if (!isTrue) {
							observer.next(x);
						}
					});
				},
			});
		});

export function tapOnce<T>(fn: (value) => void) {
	return function (source: Observable<T>) {
		source
			.pipe(
				take(1),
				tap((value) => fn(value)),
			)
			.subscribe();

		return source;
	};
}

export const retryOnError = () =>
	function <T>(source: Observable<T>): Observable<T> {
		return source.pipe(retryWhen((errors) => errors.pipe(delay(3000), take(10))));
	};

export const shareReplayOne =
	() =>
	<T>(source: Observable<T>) =>
		source.pipe(shareReplay({ bufferSize: 1, refCount: true }));

export const pause =
	(pauser: Observable<boolean>, mode: 'skip' | 'last' | 'replay' = 'last') =>
	<T>(source: Observable<T>) => {
		const startedPauser$ = pauser.pipe(distinctUntilChanged(), share());
		const on$ = startedPauser$.pipe(filter((v) => !v));
		const off$ = startedPauser$.pipe(filter((v) => v));

		const convert = (x: T[]) => {
			if (mode === 'skip') {
				return [];
			} else if (mode === 'last') {
				const lastValue = last(x);
				return lastValue ? [lastValue] : [];
			} else {
				return x;
			}
		};

		return merge(
			source.pipe(
				bufferToggle(off$, () => on$),
				mergeMap((x) => convert(x)),
			),
			source.pipe(
				windowToggle(on$.pipe(startWith(true)), () => off$),
				mergeMap((x) => x),
			),
		);
	};
