import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from '@store/state/CompanyState';
import { selectUserEmail } from '@store/selectors/app.selectors';

const selectCompanyState = createFeatureSelector<CompanyState>('companyState');

export const selectCompanyEmployees = createSelector(
	selectCompanyState,
	(state) => state.companyEmployees.entities,
);

export const selectCompanyEmployee = createSelector(selectCompanyEmployees, (employees, props) => {
	return employees[props.employeeId];
});

export const selectCurrentUserFromCompanyMembers = createSelector(
	selectCompanyState,
	selectUserEmail,
	(company, userEmail) => {
		return company.company?.members[userEmail];
	},
);

export const selectLoadingStateOfCompanyEmployees = createSelector(
	selectCompanyState,
	(state) => state.loadingStatus,
);
