import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProjectMemberFilterBy } from '../project-member-list/project-member-list.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { InviteState, Member } from 'domain-entities';
import { map } from 'rxjs/operators';
import {
	isAcceptedInternalMember,
	isExternalMember,
} from '@shared/functions/project/project.functions';
import { shareReplayOne } from '@craftnote/shared-utils';

@Component({
	selector: 'app-project-member-list-header',
	templateUrl: './project-member-list-header.component.html',
	styleUrls: ['./project-member-list-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMemberListHeaderComponent implements OnInit {
	@Input() projects$: Observable<Member[]>;
	@Input() filterBy$$: BehaviorSubject<ProjectMemberFilterBy>;

	noOfMembers$: Observable<number>;
	noOfInternalMembers$: Observable<number>;
	noOfExternalMembers$: Observable<number>;

	ProjectMemberFilterBy = ProjectMemberFilterBy;

	ngOnInit(): void {
		const acceptedMembers$ = this.projects$.pipe(
			map((members) => members.filter((member) => member.inviteState === InviteState.ACCEPTED)),
			shareReplayOne(),
		);

		this.noOfMembers$ = acceptedMembers$.pipe(
			map((members) => {
				return this.getCountByFilterBy(members, ProjectMemberFilterBy.ALL);
			}),
			shareReplayOne(),
		);
		this.noOfInternalMembers$ = acceptedMembers$.pipe(
			map((members) => {
				return this.getCountByFilterBy(members, ProjectMemberFilterBy.INTERNAL);
			}),
			shareReplayOne(),
		);
		this.noOfExternalMembers$ = acceptedMembers$.pipe(
			map((members) => {
				return this.getCountByFilterBy(members, ProjectMemberFilterBy.EXTERNAL);
			}),
			shareReplayOne(),
		);
	}

	private getCountByFilterBy(members: Member[], filterBy: ProjectMemberFilterBy): number {
		return members.filter((member) => {
			if (filterBy === ProjectMemberFilterBy.ALL) {
				return true;
			}
			if (filterBy === ProjectMemberFilterBy.INTERNAL) {
				return isAcceptedInternalMember(member);
			}
			if (filterBy === ProjectMemberFilterBy.EXTERNAL) {
				return isExternalMember(member);
			}
			return false;
		}).length;
	}
}
