import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiMenuComponent } from './multi-menu.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
	declarations: [MultiMenuComponent],
	imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatBadgeModule],
	exports: [MultiMenuComponent],
})
export class MultiMenuModule {}
