import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MultiMenuModule, PromotionalContentModule } from '@craftnote/material-theme';
import { ProjectSearchComponent } from './project-search.component';
import { DropdownsModule } from '@modules/shared/components/dropdowns/dropdowns.module';
import { MultiSelectPanelModule } from '@modules/shared/components/multi-select-panel/multi-select-panel.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateRangeDialogComponent } from './components/date-range-dialog/date-range-dialog.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RoleNamePipeModule } from '@modules/shared/pipes/role-name/role-name-pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { RouterModule } from '@angular/router';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ProjectSortComponent } from './components/project-sort/project-sort.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDividerModule } from '@angular/material/divider';
import { PaywallCtaModule } from '@modules/shared/components/paywall-cta/paywall-cta.module';
import { ProjectSearchPaywallComponent } from '@work/project-search/components/project-search-paywall/project-search-paywall.component';

@NgModule({
	imports: [
		FormsModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatRadioModule,
		MatButtonModule,
		MultiMenuModule,
		DropdownsModule,
		MultiSelectPanelModule,
		MatChipsModule,
		MatDatepickerModule,
		MatMomentDateModule,
		ReactiveFormsModule,
		RoleNamePipeModule,
		PromotionalContentModule,
		MatIconModule,
		SharedModule,
		RouterModule,
		MatMenuModule,
		MatSelectModule,
		MatDividerModule,
		PaywallCtaModule,
	],
	declarations: [
		ProjectSearchComponent,
		DateRangeDialogComponent,
		ProjectSortComponent,
		ProjectSearchPaywallComponent,
	],
	exports: [ProjectSearchComponent],
})
export class ProjectSearchModule {}
