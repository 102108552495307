import { inject, Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { clearEntities, EntityChanges } from '@craftnote/shared-utils';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { ActivityStateService } from '@injectables/services/real-time/activity-state.service';
import { EntityList, RestApiService } from '@injectables/services/rest-api/rest-api.service';
import { ENVIRONMENT } from '@shared/tokens/environment';
import { getUnixTime } from 'date-fns';
import { Project } from 'domain-entities';
import {
	BehaviorSubject,
	combineLatest,
	concat,
	firstValueFrom,
	lastValueFrom,
	NEVER,
	Observable,
	of,
	retry,
	Subject,
	withLatestFrom,
} from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';

const BACKOFF_DEFAULT = 1000;

class RestConnectorError extends Error {
	readonly name = 'RestConnectorError';

	constructor(err: Error) {
		super(err.message);
	}
}

@Injectable({ providedIn: 'root' })
export class ProjectRestConnector {
	private readonly firebaseAuth = inject(Auth);
	private readonly environment = inject(ENVIRONMENT);
	private readonly api = inject(RestApiService);
	private readonly errorHandlerService = inject(ErrorHandlerService);
	private readonly activityStateService = inject(ActivityStateService);

	watchProjectChanges(
		userId: string,
		scope: 'active' | 'archived',
	): Observable<EntityChanges<Project>> {
		if (!userId) {
			return of(clearEntities);
		}

		const restartStream$ = new BehaviorSubject<void>(null);
		let backoffCount = BACKOFF_DEFAULT;

		const concatBuilder = () =>
			concat(
				of(clearEntities),
				this.getProjectsFromRestApi(scope, userId).pipe(
					// retry({ delay: 200 }),
					tap(() => (backoffCount = BACKOFF_DEFAULT)),
				),
				this.getRealtimeUpdates(scope, userId),
			);

		return restartStream$.pipe(
			switchMap(() =>
				concatBuilder().pipe(
					catchError((err) => {
						const restConnectorError = new RestConnectorError(err);
						this.errorHandlerService.handleError(restConnectorError);
						setTimeout(() => {
							restartStream$.next();
						}, (backoffCount *= 1.5));
						return NEVER;
					}),
				),
			),
		);
	}

	private async getProjectFromRestApi(projectId: string): Promise<Project> {
		return (await firstValueFrom(this.api.getProjectById(projectId))) as unknown as Project;
	}

	private getProjectsFromRestApi(
		status: 'active' | 'archived',
		userId: string,
		updatedSince?: number,
	): Observable<EntityChanges<Project>> {
		const projects$ = this.getProjectsStartingAt(status, updatedSince);
		return projects$.pipe(
			take(1),
			switchMap((projects) => {
				const entityChangesUpdated: EntityChanges<Project> = {
					changeType: 'updated',
					entities: projects.data.filter(
						(project) =>
							(status === 'active' && project.membersActive?.includes(userId)) ||
							(status === 'archived' && project.membersArchived?.includes(userId)),
					),
				};
				const outOfScopeProjects = projects.data.filter(
					(project) =>
						(status === 'active' && project.membersArchived?.includes(userId)) ||
						(status === 'archived' && project.membersActive?.includes(userId)),
				);
				const entityChangesRemoved: EntityChanges<Project> = {
					changeType: 'deleted',
					entities: [
						...((projects.formerEntityIds?.map((id) => ({ id })) as Project[]) ?? []),
						...outOfScopeProjects,
					],
				};

				return concat(of(entityChangesUpdated), of(entityChangesRemoved));
			}),
		);
	}

	private getProjectsStartingAt(
		status: 'active' | 'archived',
		updateSince?: number,
		startAfter?: string,
	): Observable<EntityList> {
		return this.api
			.getProjects(status, 1000, updateSince, startAfter)
			.pipe(
				switchMap((list) =>
					list.startAfter
						? this.getProjectsStartingAt(status, updateSince, list.startAfter).pipe(
								map((newList) => ({ ...list, data: [...list.data, ...newList.data] })),
						  )
						: of(list),
				),
			);
	}

	private getRealtimeUpdates(
		status: 'active' | 'archived',
		userId: string,
	): Observable<EntityChanges<Project>> {
		const realtimeChanges$$ = new Subject<EntityChanges<Project>>();
		const restartWebsocket$$ = new BehaviorSubject<number>(null);
		let socket: Socket;

		const getRealtimeChangesFactory = (replaySince) =>
			combineLatest([
				idToken(this.firebaseAuth),
				this.activityStateService.isActiveAndVisible$,
			]).pipe(
				tap(([, isActiveAndVisible]) => {
					if (!isActiveAndVisible) {
						console.log('Websocket: Waiting for app to become active and visible before connecting');
					}
				}),
				filter(([token, isActiveAndVisible]) => !!token && isActiveAndVisible),
				tap(() => {
					console.log('Websocket: App is active and visible, establishing connection');
				}),
				switchMap(async ([token]) => {
					if (replaySince) {
						const replay = this.getProjectsFromRestApi(status, userId, replaySince).pipe(
							catchError((error) => {
								if (error.status !== 0) {
									realtimeChanges$$.error(error);
									restartWebsocket$$.complete();
									return of(null);
								}
								throw error;
							}),
							retry({ delay: 1000 }),
						);
						await lastValueFrom(
							replay.pipe(
								tap((changes) => {
									if (changes) {
										realtimeChanges$$.next(changes);
									}
								}),
							),
						);
					}
					return token;
				}),
				tap((token) => {
					socket = io(this.environment.realtimeApiUrl, {
						reconnection: false,
						auth: { Authorization: `Bearer ${token}` },
						transports: ['websocket'],
					});

					this.activityStateService.isActiveAndVisible$.subscribe((isActiveAndVisible) => {
						//only disconnect socket between 10pm and 4am
						if (!isActiveAndVisible && socket?.connected) {
							console.log('Websocket: Disconnecting due to app becoming invisible');
							socket.disconnect();
						}
					});
					
					socket.on('connect', () => {
						console.log('Websocket: Connected successfully');
						
						// Load delta of changed projects since last disconnect (if any)
						if (restartWebsocket$$.value) {
							this.getProjectsFromRestApi(status, userId, restartWebsocket$$.value)
								.pipe(take(1))
								.subscribe(changes => {
									if (changes.entities?.length > 0) {
										realtimeChanges$$.next(changes);
									}
								});
						}

						socket.emit('register-entity-changes', [
							{ path: '/projects' },
							{ path: '/projects/members' },
						]);
					});
					socket.on('disconnect', () => {
						console.log('Websocket: Disconnected, will attempt restart when app is active and visible');
						socket.removeAllListeners();
						restartWebsocket$$.next(getUnixTime(new Date()));
					});
					socket.on('entity-changes', async (changes) => {
						const updateChange: EntityChanges<Project> = { changeType: 'updated', entities: [] };
						const removalChanges: EntityChanges<Project> = { changeType: 'deleted', entities: [] };
						for (const change of changes) {
							const resourceIds = change.resourceIds as string[];
							const projectWithOnlyId = resourceIds.map((id) => ({
								id,
							})) as Project[];
							if (change.path === '/projects') {
								if (change.action === 'deleted') {
									removalChanges.entities.push(...projectWithOnlyId);
								} else {
									const updateProjectsAll = await Promise.allSettled(
										resourceIds.map((id) => this.getProjectFromRestApi(id)),
									);
									const updateProjects = updateProjectsAll
										.filter((res) => res.status === 'fulfilled')
										.map((res) => (res as PromiseFulfilledResult<Project>).value);
									for (const updateProject of updateProjects) {
										const isActive = !!updateProject.membersActive?.includes(userId);
										if (!(isActive === (status === 'active'))) {
											removalChanges.entities.push(...projectWithOnlyId);
										} else {
											updateChange.entities.push(updateProject);
										}
									}
								}
							}
							if (change.path === '/projects/members' && change.action === 'deleted') {
								removalChanges.entities.push(...projectWithOnlyId);
							}
						}
						if (updateChange.entities.length > 0) {
							realtimeChanges$$.next(updateChange);
						}
						if (removalChanges.entities.length > 0) {
							realtimeChanges$$.next(removalChanges);
						}
					});
					socket.on('exception', (exception) => {
						this.errorHandlerService.handleError(exception);
					});
				}),
			);

		return restartWebsocket$$.pipe(
			withLatestFrom(this.activityStateService.isActiveAndVisible$),
			tap(([, isActiveAndVisible]) => {
				if (!isActiveAndVisible) {
					console.log('Websocket: Restart attempted but app is not active/visible - waiting');
				}
			}),
			filter(([, isActiveAndVisible]) => isActiveAndVisible),
			tap(() => {
				console.log('Websocket: Restarting connection as app is now active and visible');
			}),
			switchMap(([replaySince]) =>
				combineLatest([
					getRealtimeChangesFactory(replaySince).pipe(startWith(null)),
					realtimeChanges$$.asObservable(),
				]),
			),
			map(([, realtimeChanges]) => realtimeChanges),
		);
	}
}
