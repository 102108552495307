import { NgModule } from '@angular/core';
import { LineComponent } from './line.component';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [LineComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule],
	exports: [COMPONENT],
})
export class LineModule {}
