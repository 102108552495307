import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilKeyChanged, map, take } from 'rxjs/operators';
import { orderBy, unionBy } from 'lodash';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppState } from '@store/state/app.state';
import { selectAllActiveProjects } from '@store/selectors/projects.selectors';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { selectProfile } from '@store/selectors/app.selectors';
import { File, File as FileEntity } from 'domain-entities';
import {
	FileSectionCopyToOtherProjectOperation,
	ProjectFile,
} from '@shared/models/project-file.model';
import { TrackingService } from '@injectables/services/tracking.service';
import { FilesFileForwardedEventBuilder } from '@generated/events/FilesEvents.generated';
import { shareReplayOne } from '@craftnote/shared-utils';
import { ProjectSortAndSearchHelper } from '@injectables/services/project-sort-and-search-helper.service';

interface ProjectDialogFilter {
	searchKey: string;
	selectedParentProjectId: string;
	selectedProject: string;
}

@Component({
	selector: 'app-copy-to-other-project-dialog',
	templateUrl: './copy-to-other-project-dialog.component.html',
	styleUrls: ['./copy-to-other-project-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToOtherProjectDialogComponent {
	@ViewChild('projectsSearch') searchInput: ElementRef;

	selectedFiles: ProjectFile[] = [];
	isLoading = false;
	projectDialogFilter$ = new BehaviorSubject<ProjectDialogFilter>({
		searchKey: '',
		selectedParentProjectId: null,
		selectedProject: undefined,
	});

	selectedParentProjectId$ = this.projectDialogFilter$.pipe(
		distinctUntilKeyChanged('selectedParentProjectId'),
		map((selectedFilter) => selectedFilter.selectedParentProjectId),
		shareReplayOne(),
	);

	projects$ = combineLatest([
		this.store.select(selectAllActiveProjects),
		this.projectDialogFilter$,
	]).pipe(
		map(([projects, selectedFilters]) => {
			let filteredProjects = projects.filter((project) => {
				if (!selectedFilters.selectedParentProjectId && !project.parentProject) {
					return true;
				}
				return project.parentProject === selectedFilters.selectedParentProjectId;
			});

			if (selectedFilters.searchKey) {
				const projectsUnderCurrentDirectory = filteredProjects.filter((project) =>
					this.projectSortAndSearchHelper.defaultSearchCondition(
						project,
						selectedFilters.searchKey,
					),
				);
				const resultsInSubDirectories = projects.filter(
					(project) =>
						(!selectedFilters.selectedParentProjectId ||
							project.parentProject === selectedFilters.selectedParentProjectId) &&
						this.projectSortAndSearchHelper.defaultSearchCondition(
							project,
							selectedFilters.searchKey,
						),
				);

				filteredProjects = unionBy(
					projectsUnderCurrentDirectory,
					resultsInSubDirectories,
					(project) => project.id,
				);
			}

			return orderBy(filteredProjects, ['projectType', (element) => element.name.toLowerCase()]);
		}),
	);

	selectedParentProject$ = combineLatest([
		this.store.select(selectAllActiveProjects),
		this.selectedParentProjectId$,
	]).pipe(
		map(([projects, selectedProjectId]) => {
			return projects.find((project) => project.id === selectedProjectId);
		}),
	);

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			selectedFiles: ProjectFile[];
			operation: FileSectionCopyToOtherProjectOperation;
		},
		private readonly store: Store<AppState>,
		private readonly projectSortAndSearchHelper: ProjectSortAndSearchHelper,
		private readonly dialogRef: MatDialogRef<CopyToOtherProjectDialogComponent>,
		private readonly fileService: FileExplorerService,
		private readonly trackingService: TrackingService,
	) {
		this.selectedFiles = this.data.selectedFiles;
	}

	onProjectSearchChange(searchTerm: string): void {
		this.projectDialogFilter$.next({ ...this.projectDialogFilter$.value, searchKey: searchTerm });
	}

	onFolderSelected(folderId: string = null): void {
		this.projectDialogFilter$.next({
			...this.projectDialogFilter$.value,
			selectedParentProjectId: folderId,
			searchKey: '',
		});
	}

	goBack(event: Event): void {
		event.preventDefault();
		this.onFolderSelected();
	}

	onProjectSelected(selectedId: string): void {
		const state = { ...this.projectDialogFilter$.value };

		if (state.selectedProject === selectedId) {
			state.selectedProject = null;
		} else {
			state.selectedProject = selectedId;
		}

		this.projectDialogFilter$.next(state);
	}

	async copyToSelectedProject(): Promise<void> {
		const { operation } = this.data;

		if (operation === 'files-section') {
			await this.copyToFilesSection();
			await this.trackEvent('files');
		} else if (operation === 'chat') {
			await this.copyToChat();
			await this.trackEvent('chat');
		}
	}

	async copyToChat(): Promise<void> {
		const selectedProjectId = this.projectDialogFilter$.value.selectedProject;
		this.isLoading = true;

		try {
			const currentProfile = await this.store.select(selectProfile).pipe(take(1)).toPromise();
			await this.fileService.uploadFilesToChat(
				this.selectedFiles.map((projectFile) => projectFile.base as FileEntity),
				currentProfile,
				selectedProjectId,
			);
			this.dialogRef.close(true);
		} catch (e) {
			this.dialogRef.close(false);
		}

		this.isLoading = false;
	}

	async copyToFilesSection(): Promise<void> {
		const selectedProjectId = this.projectDialogFilter$.value.selectedProject;
		this.isLoading = true;
		try {
			await this.fileService.copyFilesToProject(
				this.selectedFiles.map((file) => file.base as FileEntity),
				selectedProjectId,
			);
			this.dialogRef.close(true);
		} catch (e) {
			this.dialogRef.close(false);
		}
		this.isLoading = false;
	}

	closeWithoutSaving(): void {
		this.dialogRef.close();
	}

	private async trackEvent(operation: 'files' | 'chat'): Promise<void> {
		const selectedProjectId = this.projectDialogFilter$.value.selectedProject;

		await Promise.all(
			this.selectedFiles.map((file) =>
				this.trackingService.trackEvent(
					new FilesFileForwardedEventBuilder({
						sourceProjectId: (file.base as File)?.projectId,
						destinationProjectId: selectedProjectId,
						target: operation,
					}),
				),
			),
		);
	}
}
