import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';

const DIRECTIVE = [AutofocusDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class AutofocusModule {}
