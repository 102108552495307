import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserRole } from '@store/selectors/app.selectors';
import { skipWhile, take } from 'rxjs/operators';
import { MemberRole } from 'domain-entities';

@Injectable({ providedIn: 'root' })
export class MemberRoleGuard {
	constructor(private readonly router: Router, private readonly store: Store) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const routeDataRoles: MemberRole[] = route.data?.memberRoleGuard || [];
		const routeDataRedirection: string = route.data?.memberRoleGuardRedirection || '/project';

		if (routeDataRoles.length === 0) {
			throw Error('MemberRoleGuard: Please provide memberRoleGuard values in route data object');
		}

		if (await this.memberRoleExists(routeDataRoles)) {
			return true;
		} else {
			return this.router.parseUrl(routeDataRedirection);
		}
	}

	private async memberRoleExists(rolesToVerify: MemberRole[]): Promise<boolean> {
		const userRole: MemberRole = await this.store
			.select(selectUserRole)
			.pipe(
				skipWhile((role) => !role),
				take(1),
			)
			.toPromise();
		return rolesToVerify.includes(userRole);
	}
}
