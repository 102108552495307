import { InviteState, Member, MemberRole, Project, ProjectType } from 'domain-entities';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { Dictionary } from '@ngrx/entity';
import { intersection, keys } from 'lodash';

type ResolveBy = 'email' | 'id';

const PROJECT_FIELDS_REQUIRING_LAST_EDITED_UPDATE: (keyof Project)[] = [
	'name',
	'orderNumber',
	'startDate',
	'endDate',
	'street',
	'zipcode',
	'city',
	'country',
	'contacts',
	'billingCity',
	'billingCountry',
	'billingEmail',
	'billingName',
	'billingStreet',
	'billingZipcode',
	// We assume that a note change always includes a change of the noteTimestamp so we don't need to check for the note itself
	'noteTimestamp',
];

export function getRoleOfProjectMember(
	identifier: string,
	project: Project,
	resolveBy: ResolveBy = 'id',
): MemberRole | undefined {
	if (!project) {
		return undefined;
	}
	return Object.values(project.members).find((member) => member[resolveBy] === identifier)?.role;
}

export function isUserProjectAdmin(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return [MemberRole.OWNER, MemberRole.SUPERVISOR, MemberRole.EXTERNALSUPERVISOR].includes(role);
}

export function isUserInternalOrExternalProjectEmployee(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return [MemberRole.EMPLOYEE, MemberRole.EXTERNAL].includes(role);
}

export function isUserOwnerOfProject(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return role === MemberRole.OWNER;
}

export function isUserSupervisorOfProject(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return [MemberRole.SUPERVISOR, MemberRole.EXTERNALSUPERVISOR].includes(role);
}

export function isUserInternalSupervisorOfProject(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return MemberRole.SUPERVISOR === role;
}

export function isUserOwnerOrSupervisorOfProject(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	return (
		isUserOwnerOfProject(identifier, project, resolveBy) ||
		isUserSupervisorOfProject(identifier, project, resolveBy)
	);
}

export function getMembersOfProject(project: Project): Member[] {
	return project ? Object.values(project.members) : [];
}

export function getAcceptedMembersOfProject(project: Project): Member[] {
	return project ? Object.values(project.members).filter(isAcceptedMember) : [];
}

export function isAcceptedMember(member: Member): boolean {
	return member?.inviteState === InviteState.ACCEPTED;
}

export function isProjectAdmin(member: Member): boolean {
	return [MemberRole.EXTERNALSUPERVISOR, MemberRole.OWNER, MemberRole.SUPERVISOR].includes(
		member?.role,
	);
}

export function isUserInternalProjectAdmin(
	identifier: string,
	project: Project,
	resolveBy?: ResolveBy,
): boolean {
	const role = getRoleOfProjectMember(identifier, project, resolveBy);
	return [MemberRole.OWNER, MemberRole.SUPERVISOR].includes(role);
}

export function isInternalProjectAdmin(member: Member): boolean {
	return [MemberRole.OWNER, MemberRole.SUPERVISOR].includes(member?.role);
}

export function isPendingProjectAdmin(member: Member): boolean {
	return (
		member?.inviteState === InviteState.INVITED &&
		[MemberRole.EXTERNALSUPERVISOR, MemberRole.OWNER, MemberRole.SUPERVISOR].includes(member?.role)
	);
}

export function isOwner(member: Member): boolean {
	return isAcceptedMember(member) && [MemberRole.OWNER].includes(member?.role);
}

export function isAcceptedInternalMember(member: Member): boolean {
	return isAcceptedMember(member) && isProjectInternalMember(member);
}

export function isProjectInternalMember(member: Member): boolean {
	return [MemberRole.OWNER, MemberRole.SUPERVISOR, MemberRole.EMPLOYEE].includes(member?.role);
}

export function isExternalMember(member: Member): boolean {
	return (
		isAcceptedMember(member) &&
		[MemberRole.EXTERNAL, MemberRole.EXTERNALSUPERVISOR].includes(member?.role)
	);
}

export function isPendingExternalMember(member: Member): boolean {
	return (
		member?.inviteState === InviteState.INVITED &&
		[MemberRole.EXTERNAL, MemberRole.EXTERNALSUPERVISOR].includes(member?.role)
	);
}

export function isInProject(project: Project, userId: string): boolean {
	return project.membersActive?.includes(userId) || project.membersArchived?.includes(userId);
}

export function getAddressOfProject(project: Project): string {
	let address = '';

	if (!project) {
		return address;
	}

	if (project.street) {
		address += project.street;
	}

	if (project.city) {
		if (address) {
			address += ', ';
		}

		address += project.city;
	}

	if (project.zipcode) {
		if (address) {
			address += ', ';
		}

		address += project.zipcode;
	}

	if (project.country) {
		if (address) {
			address += ', ';
		}

		address += project.country;
	}

	return address;
}

export function getProjectTypeAsString(project: Project): string {
	switch (project.projectType) {
		case ProjectType.PROJECT:
			return 'project';
		case ProjectType.FOLDER:
			return 'folder';
		default:
			throw new Error('Project does not have a type');
	}
}

export function getProjectHeight(
	project: Project,
	searchActive: boolean,
	isMuted: boolean,
	projectStatuses: Dictionary<ProjectStatus>,
): number {
	const hasStreet = project.street || project.city || project.country || project.zipcode;
	const hasId = project.orderNumber;
	const longName = project.name.length > 16;

	// Start with min height of name area + margin of id number
	let height = 38;

	if (hasId || project.projectType === ProjectType.FOLDER) {
		height += 16;
	}
	if (hasStreet) {
		height += 16;
	}
	if (project.statusId && projectStatuses[project.statusId]) {
		height += 40;
	} else if (longName) {
		height += 22;
	}

	const defaultProjectHeight = isMuted ? 68 : 46;

	if (project.projectType === ProjectType.PROJECT) {
		height = height > defaultProjectHeight ? height : defaultProjectHeight;
	} else {
		height = height > 54 ? height : 54;
	}
	// Add padding and 2 px divider
	height += 28;

	if (searchActive) {
		height += 32;
	}
	return height;
}

export function doesProjectChangeRequireLastEditedUpdate(project: Partial<Project>): boolean {
	const projectKeys = keys(project);
	return intersection(projectKeys, PROJECT_FIELDS_REQUIRING_LAST_EDITED_UPDATE).length > 0;
}
