import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	FormGroupDirective,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	SharedSliderDialogData,
	SharedSliderDialogSideType,
} from '../../entities/shared-slider-dialog.data';
import {
	blockInitialAnimation,
	enterExitLeftAnimation,
} from '../../animations/shared-slider.animations';
import { map, pairwise, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash';

export interface StepCompletedEvent {
	currentIndex: number;
	value: number | string | object;
}

@Component({
	selector: 'app-shared-slider-dialog',
	templateUrl: './shared-slider-dialog.component.html',
	styleUrls: ['./shared-slider-dialog.component.scss'],
	animations: [blockInitialAnimation, enterExitLeftAnimation],
})
export class SharedSliderDialogComponent implements OnInit {
	@Input() slides: SharedSliderDialogData;
	@Output() values = new EventEmitter<unknown[]>();
	@Output() changes = new EventEmitter<boolean[]>();
	@Output() stepCompleted = new EventEmitter<StepCompletedEvent>();

	currentSlideIndex = 0;
	formGroup: UntypedFormGroup;

	constructor(private readonly formBuilder: UntypedFormBuilder) {
		this.formGroup = this.formBuilder.group({});
	}

	ngOnInit(): void {
		this.initControls();
	}

	initControls(): void {
		this.addControlsToTheForm(this.slides, this.formGroup);

		this.formGroup.valueChanges
			.pipe(
				map(() => Object.values(this.formGroup.controls).map((control) => control.value !== null)),
				startWith(this.slides.map(() => false)),
				pairwise(),
				map(([prev, current]) => prev.map((val, i) => !isEqual(current[i], val))),
			)
			.subscribe((changeArray) => {
				this.changes.emit(changeArray);
			});
	}

	private addControlsToTheForm(slides: SharedSliderDialogData, formGroup: UntypedFormGroup): void {
		slides.forEach((slide, i) => {
			if (slide.type === SharedSliderDialogSideType.FORM) {
				const childForm = this.formBuilder.group({});
				this.addControlsToTheForm(slide.controls, childForm);
				formGroup.addControl(i.toString(), childForm);
			} else if (slide.type === SharedSliderDialogSideType.TEXT) {
				formGroup.addControl(
					i.toString(),
					new UntypedFormControl(slide.value, [Validators.required]),
				);
				if (slide.validation) {
					formGroup.controls[i.toString()].addValidators(slide.validation.validators);
				}
			} else {
				formGroup.addControl(
					i.toString(),
					new UntypedFormControl(undefined, [Validators.required]),
				);
			}
		});
	}

	nextSlide(): void {
		this.currentSlideIndex += 1;
	}

	onNext(formGroupDirective: FormGroupDirective): void {
		this.stepCompleted.emit({
			currentIndex: this.currentSlideIndex,
			value: this.formGroup.controls[this.currentSlideIndex].value,
		});
		this.formGroup.markAsUntouched();
		this.formGroup.markAsPristine();
		this.currentSlideIndex += 1;
		if (this.currentSlideIndex === this.slides.length) {
			const values = this.mapFormGroupToArray(this.formGroup);
			this.values.emit(values);
		}
		/**
		 * We need to reset the forms submitted state in order to prevent a right highlight
		 * on subsequent input cards.
		 * Apparently setting submitted on the fromGroupDirective is the only way see
		 * https://stackoverflow.com/a/48217303
		 */
		setTimeout(() => ((<any>formGroupDirective).submitted = false));
	}

	private mapFormGroupToArray(fromGroup: UntypedFormGroup): unknown[] {
		return Object.values(fromGroup.controls).map((control) => {
			if (control instanceof UntypedFormGroup) {
				return this.mapFormGroupToArray(control);
			}

			return control.value;
		});
	}
}
