import { Component, EventEmitter, Output } from '@angular/core';
import { Contact, MemberRole, Project } from 'domain-entities';
import { ProjectService } from '@injectables/services/project/project.service';
import { ActivatedRoute } from '@angular/router';
import { map, pluck, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectProject } from '@store/selectors/projects.selectors';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { shareReplayOne } from '@craftnote/shared-utils';

export interface ContactUI {
	index?: number;
	contact: Contact;
}

@Component({
	selector: 'app-project-contacts-list',
	templateUrl: './project-contacts-list.component.html',
	styleUrls: ['./project-contacts-list.component.scss'],
})
export class ProjectContactsListComponent {
	project$: Observable<Project> = this.activateRoute.params.pipe(
		pluck('projectId'),
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
		shareReplayOne(),
	);
	projectContacts$: Observable<Contact[]> = this.project$.pipe(
		map((project) => project?.contacts || []),
	);
	@Output() close = new EventEmitter<void>();
	MemberRole = MemberRole;
	addEditProjectContactVisible = false;
	addEditProjectContact: ContactUI = null;

	constructor(
		private readonly projectService: ProjectService,
		private readonly activateRoute: ActivatedRoute,
		private readonly store: Store,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {}

	addContact(): void {
		this.addEditProjectContactVisible = true;
		this.addEditProjectContact = null;
	}

	editContact(contact: Contact, index: number): void {
		this.addEditProjectContactVisible = true;
		this.addEditProjectContact = { contact, index };
	}

	closeAddEditProjectContact(): void {
		this.addEditProjectContactVisible = false;
		this.addEditProjectContact = null;
	}

	async deleteContact(index: number): Promise<void> {
		const result = await this.confirmDialogService
			.open({
				title: this.translateService.instant('project-contacts-list.delete-contact-dialog.title'),
				message: this.translateService.instant(
					'project-contacts-list.delete-contact-dialog.message',
				),
				primaryButtonColor: 'warn',
				showCrossBtn: false,
				primaryButtonText: 'Delete',
				secondaryButtonText: 'Close',
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();
		if (!result) {
			return;
		}

		const projectContactsList = await this.projectContacts$.pipe(take(1)).toPromise();
		const project = await this.project$.pipe(take(1)).toPromise();
		const newProjectContactsList = [...projectContactsList];
		newProjectContactsList.splice(index, 1);
		await this.projectService.updateProjectPartial(project.id, {
			contacts: newProjectContactsList,
		});
	}

	async onUpdateContact(updateContactRequest: ContactUI): Promise<void> {
		const projectContactsList = await this.projectContacts$.pipe(take(1)).toPromise();
		const project = await this.project$.pipe(take(1)).toPromise();

		if (updateContactRequest.index === undefined) {
			// Adding new contact
			await this.projectService.updateProjectPartial(project.id, {
				contacts: [...projectContactsList, updateContactRequest.contact],
			});
			return;
		} else {
			// Updating contact
			const newProjectContactsList = [...projectContactsList];
			newProjectContactsList.splice(updateContactRequest.index, 1, updateContactRequest.contact);
			await this.projectService.updateProjectPartial(project.id, {
				contacts: newProjectContactsList,
			});
		}
		this.closeAddEditProjectContact();
	}
}
