<div class="icon-container">
	<mat-icon class="animate pulse logo" svgIcon="company-logo"></mat-icon>
	<div class="mt-4 font-weight-light loading">{{ 'loading.label' | translate }}</div>
</div>
<div class="hint-container">
	<div *ngIf="shouldShowProjectsHint" class="font-weight-light">
		{{ 'loading.hint.projects' | translate }}
	</div>
	<div *ngIf="shouldShowFirewallHint" class="mt-4 font-weight-light" [innerHTML]="'loading.hint.firewall' | translate"></div>
</div>
