import { createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { FirebaseApp } from '@angular/fire/compat';
import { of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ActivityStateService } from '@injectables/services/real-time/activity-state.service';

@Injectable()
export class WindowVisibilityEffects {
	constructor(
		private readonly remoteConfig: RemoteConfig,
		private readonly app: FirebaseApp,
		private readonly activityStateService: ActivityStateService,
	) {}

	/**
	 * This effect only exists to maintain some code that needs to be executed once at the
	 * app start. This is the easiest way to wrap such code in a dedicated file.
	 */
	turnDatabaseOfflineIfHidden$ = createEffect(
		() =>
			of(null).pipe(
				switchMap(async () => {
					if (!await this.remoteConfig.getBooleanAsync('feature_no_data_on_invisible_tabs') || true) {
						return;
					}

					// Create a promise-based wrapper for the Firebase operations
					const updateNetworkState = async (isActive: boolean) => {
						try {
							if (!isActive) {
								console.log('disableNetwork');
								await this.app.firestore().disableNetwork();
								this.app.database().goOffline();
							} else {
								console.log('enableNetwork');
								await this.app.firestore().enableNetwork();
								this.app.database().goOnline();
							}
						} catch (error) {
							console.error('Error updating Firebase network state:', error);
						}
					};

					// Subscribe to visibility changes
					this.activityStateService.isActiveAndVisible$
						.pipe(distinctUntilChanged())
						.subscribe(isActiveAndVisible => {
							updateNetworkState(isActiveAndVisible);
						});
				}),
			),
		{ dispatch: false },
	);
}
