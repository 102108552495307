import { NgModule } from '@angular/core';
import { EventStopPropagationDirective } from './event-stop-propagation.directive';

const DIRECTIVE = [EventStopPropagationDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class EventStopPropagationModule {}
