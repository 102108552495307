import { createAction, props } from '@ngrx/store';
import { Message } from 'domain-entities';
import { Search } from '@shared/models/search.model';

export const LoadMessages = createAction(
	'[Message] Load Messages',
	props<{ messages: Message[] }>(),
);

export const LoadAllMessages = createAction('[Message] Load All Messages');

export const LoadingEnabled = createAction('[Message] Loading is enabled');

export const LoadingDisabled = createAction('[Message] Loading is Disabled');

export const NextBatchNotAvailable = createAction(
	'[Message] Next Batch messages are not available',
);

export const NextBatchAvailable = createAction('[Message] Next Batch messages are available');

export const LoadBatchMessagesByProject = createAction(
	'[Message] Load Batch messages by project',
	props<{
		projectId: string;
		lastMessageId: string;
		loadedMessages?: number;
		startFromMessageId?: string;
	}>(),
);

export const LoadMessagesByProject = createAction(
	'[Message] Load Messages By Project',
	props<{ projectId: string }>(),
);

export const AddMessage = createAction('[Message] Add Message', props<{ message: Message }>());

export const UpsertMessage = createAction(
	'[Message] Upsert Message',
	props<{ message: Message }>(),
);

export const UpsertMessages = createAction(
	'[Message] Upsert Messages',
	props<{ messages: Message[] }>(),
);

export const ClearMessages = createAction('[Message] Clear Messages');

export const SetSearchOptions = createAction(
	'[Message] Setting search options',
	props<{ searchOptions: Search }>(),
);

export const FilterEnabled = createAction('[Message] Filter is enabled');

export const FilterDisabled = createAction('[Message] Filter is disabled');

export const ToggleFilter = createAction('[Message] Toggle filter');

export const SearchAndFilter = createAction(
	'[Messages] Searching and filtering the messages',
	props<{ searchAndFilterOptions: Search }>(),
);

export const ClearMessageState = createAction('[Message] Clear state');

export const ClearSearchAndFilter = createAction('[Messages] Clear search and filter');

export const TogglePdfExport = createAction('[Message] Toggle Pdf export');

export const PdfExportEnable = createAction('[Message] Enable Pdf export');

export const PdfExportDisable = createAction('[Message] Disable Pdf export');

export const SetReplyMessage = createAction(
	'[Message] Set reply message',
	props<{ message: Message }>(),
);

export const ClearReplyMessage = createAction('[Message] Clear reply message');

export const setSelectedParentMessageAction = createAction(
	'[Message] Set selected parent message',
	props<{ messageId: string }>(),
);

export const selectParentMessageAction = createAction(
	'[Message] Select parent message',
	props<{ message: Message }>(),
);

export const clearSelectedParentMessageAction = createAction('[Message] Clear parent message');

export const setSelectChatMessageToShareAction = createAction(
	'[Message] Select chat message to share',
	props<{ message: Message }>(),
);

export const clearSelectedChatMessageToShareAction = createAction(
	'[Message] Clear chat message to share',
);

export const loadChatMediaByProjectAction = createAction(
	'[Message] Load chat media',
	props<{ projectId: string }>(),
);

export const upsertChatMediaMessagesAction = createAction(
	'[Message] Set chat media',
	props<{ messages: Message[] }>(),
);

export const updateInputCache = createAction(
	'[Message] Update input cache',
	props<{ projectId: string; text: string }>(),
);
export const clearInputCache = createAction(
	'[Message] Clear input cache of a project',
	props<{ projectId: string }>(),
);
