import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Message, MessageType, Profile, Task } from 'domain-entities';
import { filter, first, map, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AlertService } from '@injectables/services/alert/alert.service';
import { MESSAGES } from '@shared/constants/firebase';
import { THUMB } from '@shared//constants/thumbnail';
import { AAC, JPEG, JPG, MP3, MP4, PNG } from '@shared//constants/upload.types';
import { AuthService } from '@injectables/services/auth/auth.service';
import {
	ChatFileUploadParameters,
	FileUploadService,
} from '@injectables/services/file-upload/file-upload.service';
import { WINDOW } from '@craftnote/shared-utils';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { upsertCompanyTagsFactory } from '@shared/firebase/company/company-update.functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	ChatFileCreatedEventBuilder,
	ChatMessageDeletedEventBuilder,
} from '@generated/events/ChatEvents.generated';
import { FilesFileForwardedEventBuilder } from '@generated/events/FilesEvents.generated';
import { ChatMessageService } from './chat-message.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { CompanyService } from '../company/company.service';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public progress: number;
	public uploading = false;

	constructor(
		public database: AngularFireDatabase,
		public alertService: AlertService,
		private http: HttpClient,
		public authService: AuthService,
		private chatMessageService: ChatMessageService,
		public profileService: ProfileService,
		public companyService: CompanyService,
		public uploadService: FileUploadService,
		public afStore: AngularFirestore,
		private readonly storage: AngularFireStorage,
		@Inject(WINDOW) private readonly windowRef: Window,
		@Inject(DOCUMENT) public document: Document,
		private readonly store: Store<AppState>,
		private readonly trackingService: TrackingService,
	) {}

	sendMessage(projectId: string, message: Message): Promise<void> {
		return this.database.list(MESSAGES + projectId).set(message.id, message);
	}

	public getTaskCalendarFile(task: Task): void {
		this.http
			.get(`${environment.baseUrl}getCalendarFile?taskId=${task.id}`, {
				responseType: 'arraybuffer',
			})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/calendar');
			});
	}

	private downLoadFile(data: any, type: string): void {
		const blob = new Blob([data], { type: type });
		const url = window.URL.createObjectURL(blob);
		const pwa = this.windowRef.open(url);
		if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
			this.alertService.showAlert('Please disable your Pop-up blocker and try again.');
		}
	}

	updateTagsToMessage(projectId: string, messageId: string, tags: string[]): Promise<void> {
		return this.database.list(MESSAGES + projectId).update(messageId, {
			tags: tags,
		});
	}

	async addTagToMessage(projectId: string, messageId: string, tag: string): Promise<void> {
		const foundMessages = await this.database
			.list<Message>(MESSAGES + projectId, (ref) => ref.orderByChild('id').equalTo(messageId))
			.valueChanges()
			.pipe(take(1))
			.toPromise();

		const message = foundMessages && foundMessages[0];
		const tags = message.tags || [];
		if (tags.indexOf(tag) !== -1) {
			return;
		}
		tags.push(tag);

		await this.updateTagsToMessage(projectId, messageId, tags);
		const companyId = await this.store
			.select(selectCompanyId)
			.pipe(filter<string>(Boolean), first())
			.toPromise();

		const updateFunction = upsertCompanyTagsFactory(tag);
		return this.companyService.updateCompanyTransactional(companyId, updateFunction);
	}

	getMessageTags(projectId: string, messageId: string) {
		return this.database
			.list(MESSAGES + projectId, (ref) => ref.orderByChild('id').equalTo(messageId))
			.valueChanges()
			.pipe(
				map((messages: any[]) => {
					return (messages && messages[0] && messages[0].tags) || [];
				}),
			);
	}

	getFileName(name): string {
		const types = name.split('.');
		return types.length > 1 ? types.slice(0, -1).join('.') : name;
	}

	async deleteMessage(projectId: string, message: Message): Promise<void> {
		await this.database.list(MESSAGES + projectId).update(message.id, {
			deleted: true,
			deletedTimestamp: Math.floor(new Date().getTime() / 1000),
		});
		const messageDeletionEvent = new ChatMessageDeletedEventBuilder({
			projectId,
			messageId: message.id,
		});
		void this.trackingService.trackEvent(messageDeletionEvent);
	}

	checkFileType(name): MessageType {
		switch (this.chatMessageService.getFileType(name).toLowerCase()) {
			case JPG:
			case JPEG:
			case PNG:
				return MessageType.IMAGE;
			case AAC:
			case MP3:
				return MessageType.AUDIO;
			case MP4:
				return MessageType.VIDEO;
			default:
				return MessageType.DOCUMENT;
		}
	}

	createMessage(file, profile: Profile, projectId: string): Message {
		const type = this.checkFileType(file.name);
		const id = this.database.createPushId();

		return {
			id: id,
			projectId: projectId,
			messageType: type,
			timestamp: Math.floor(new Date().getTime() / 1000),
			authorId: this.authService.currentUserId(),
			author: profile.name + ' ' + profile.lastname,
			content: this.uploadService.getFullFileName(file, id),
			fileSize: file.size,
			fileName: file.name,
		};
	}

	async newUploadFile(file: File, params: ChatFileUploadParameters): Promise<void> {
		if (file.size > 500 * 1024 * 1024) {
			return this.alertService.showAlert('error.file_upload');
		}
		await this.uploadService.uploadFile(params);
	}

	public async addToQueue(files: FileList, profile: Profile, projectId: string): Promise<void> {
		for (const file of Array.from(files)) {
			await this.addMessageTypeFileToQueue(file, profile, projectId);
		}
	}

	async addMessageTypeFileToQueue(
		file: File,
		profile: Profile,
		projectId: string,
		source: 'chat' | 'files' = 'chat',
	): Promise<void> {
		const message = this.createMessage(file, profile, projectId);
		const messageWithUnsetContent: Message = { ...message, content: MessageType.NOTSET };
		await this.database.list(MESSAGES + projectId).set(message.id, messageWithUnsetContent);
		const params: ChatFileUploadParameters = {
			id: message.id,
			file: file,
			fileName: this.getFileName(message.content),
			projectId,
			context: FileContext.CHAT,
		};
		await this.newUploadFile(file, params);
		await this.database.list(MESSAGES + projectId).set(message.id, message);

		if (source === 'chat') {
			await this.trackingService.trackEvent(
				new ChatFileCreatedEventBuilder({
					projectId,
					mimeType: file.type,
					source: 'storage',
				}),
			);
		} else if (source === 'files') {
			await this.trackingService.trackEvent(
				new FilesFileForwardedEventBuilder({
					sourceProjectId: projectId,
					destinationProjectId: projectId,
					target: 'chat',
				}),
			);
		}
	}

	getFile(projectId: string, message: Message, type: string, thumbnail: boolean) {
		const storage = this.storage.storage.ref();
		const extension = thumbnail ? JPG : type;

		const content = thumbnail
			? this.getFileName(message.content) + THUMB + '.' + extension
			: message.content;

		return storage
			.child(projectId + '/' + content)
			.getDownloadURL()
			.then((url) => {
				return url;
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}
}
