export const COLLECTION_PROFILE_LIMITS = 'profileLimits';

export const COLLECTION_PROJECTS = '/projects/';

export const COLLECTION_PROJECTS_DELETED = 'projectsDeleted';

export const COLLECTION_INVITES = 'invites';

export const COLLECTION_COMPANIES = 'companies';

export const COLLECTION_COMPANY_FILES = '/companyFiles/';

export const COLLECTION_COMPANY_LIMITS = '/companyLimits/';

export const COLLECTION_SUBSCRIPTIONS = 'subscriptions';

export const COLLECTION_PROFILE_SETTING = 'profileSetting';

export const COLLECTION_COMPANY_SETTINGS = 'companySettings';

export const MESSAGES = '/messages/';

export const TASKS = '/tasks/';

export const FILES = '/files/';

export const TASK_FILES = '/taskFiles/';

export const TASKS_TIMES = '/taskTimes/';

export const TASK_COMMENTS = '/taskComments/';

export const COLLAPSED_TASK_COMMENT_AMOUNT = 3;

export const NOTIFICATION_TOKENS = 'notificationTokens';

export const ERROR = 'firebase-error.';

export const ERROR_FORGOTTEN = 'firebase-error.forgotten.';
