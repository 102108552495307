import { NgModule } from '@angular/core';
import { LineRightComponent } from './line-right.component';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [LineRightComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule],
	exports: [COMPONENT],
})
export class LineRightModule {}
