import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	HostBinding,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { timer } from 'rxjs';
import { flatten, isNil, remove, values } from 'lodash';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';

export interface NotificationSnackbarConfigStrict {
	componentTypes?: { [name: string]: any };
	level: number;
	timeout: number;
}

@Component({
	selector: 'app-notification-snackbar',
	templateUrl: './notification-snackbar.component.html',
	styleUrls: ['./notification-snackbar.component.scss'],
})
export class NotificationSnackbarComponent implements OnInit {
	@ViewChild('notificationSnackbarContainerRef', { read: ViewContainerRef })
	notificationSnackbarContainerRef: ViewContainerRef;
	componentRef: ComponentRef<any>;
	@HostBinding('class.notification-snackbar-visible') notificationSnackbarVisible = false;

	private viewsByLevel: { [level: number]: ViewRef[] } = {};

	constructor(
		private notificationSnackbarService: NotificationSnackbarService,
		private resolver: ComponentFactoryResolver,
	) {}

	ngOnInit(): void {
		this.notificationSnackbarService.registerComponent(this);
	}

	show<T>(
		componentOrTemplateRef: TemplateRef<T> | ComponentType<T>,
		config: NotificationSnackbarConfigStrict,
	): ViewRef {
		if (componentOrTemplateRef instanceof TemplateRef) {
			this.notificationSnackbarContainerRef.createEmbeddedView(componentOrTemplateRef);
		} else {
			const factory: ComponentFactory<T> =
				this.resolver.resolveComponentFactory(componentOrTemplateRef);
			this.componentRef = this.notificationSnackbarContainerRef.createComponent(factory);
			if (config?.componentTypes) {
				Object.keys(config.componentTypes).forEach((key) => {
					this.componentRef.instance[key] = config.componentTypes[key];
				});
			}
		}
		const newViewRef = this.notificationSnackbarContainerRef.get(
			this.notificationSnackbarContainerRef.length - 1,
		);

		this.insertNotificationView(newViewRef, config.level);
		this.updateSnackbarVisibility();

		if (!isNil(config.timeout)) {
			timer(config.timeout).subscribe(() => this.discardView(newViewRef));
		}
		return newViewRef;
	}

	private insertNotificationView(view: ViewRef, level: number): void {
		const levelArray = this.viewsByLevel[level] || [];
		levelArray.unshift(view);
		this.viewsByLevel[level] = levelArray;

		this.notificationSnackbarContainerRef.insert(view, this.getIndexOfView(view));
	}

	private getIndexOfView(view: ViewRef): number | null {
		const orderedViews = Object.keys(this.viewsByLevel)
			.sort((a, b) => parseInt(a) - parseInt(b))
			.reduce((acc, levelInner) => {
				acc.push(...this.viewsByLevel[+levelInner]);
				return acc;
			}, [] as ViewRef[]);

		const index = orderedViews.indexOf(view);
		return isNil(index) ? null : index;
	}

	private getLevelOfView(view: ViewRef): number | null {
		const leveOfView = +Object.keys(this.viewsByLevel).find((level) => {
			return !!this.viewsByLevel[+level].find((viewInner) => viewInner === view);
		});
		return isNil(leveOfView) ? null : leveOfView;
	}

	private updateSnackbarVisibility(): void {
		this.notificationSnackbarVisible = !!this.notificationSnackbarContainerRef.length;
	}

	discardView(view: ViewRef): void {
		const index = this.getIndexOfView(view);
		if (isNil(index)) {
			return;
		}
		remove(this.viewsByLevel[this.getLevelOfView(view)], view);
		this.notificationSnackbarContainerRef.detach(index);
	}

	discardAll(): void {
		flatten(values(this.viewsByLevel)).forEach((ref) => this.discardView(ref));
	}

	hide(viewRef: ViewRef): void {
		this.discardView(viewRef);
	}
}
