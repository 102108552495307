import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCompiler, TranslateLoader } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export const TRANSLATE_LOADER = (http: HttpClient) => {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json?versionHash={{CURRENT_APP_HASH}}');
};

export const TRANSLATE_CONFIG = {
	loader: {
		provide: TranslateLoader,
		useFactory: TRANSLATE_LOADER,
		deps: [HttpClient],
	},
	compiler: {
		provide: TranslateCompiler,
		useFactory: () => new TranslateMessageFormatCompiler(),
	},
};
