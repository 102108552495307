import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';
import { DOCUMENT } from '@angular/common';

export interface ScrollEvent {
	isReachingBottom: boolean;
	isReachingTop: boolean;
	originalEvent: Event;
	isWindowEvent: boolean;
}

@Directive({
	selector: '[detect-scroll]',
})
export class ScrollDirective {
	@Output() public onScroll = new EventEmitter<ScrollEvent>();
	@Input() public bottomOffset = 100;
	@Input() public topOffset = 100;

	constructor(
		@Inject(WINDOW) private readonly windowRef: Window,
		@Inject(DOCUMENT) private document: Document,
	) {}

	// handle host scroll
	@HostListener('scroll', ['$event'])
	public scrolled($event: Event) {
		this.elementScrollEvent($event);
	}

	// handle window scroll
	@HostListener('window:scroll', ['$event'])
	public windowScrolled($event: Event) {
		this.windowScrollEvent($event);
	}

	protected windowScrollEvent($event: Event) {
		const target = <Document>$event.target;
		const scrollTop =
			this.windowRef.pageYOffset ||
			this.document.documentElement.scrollTop ||
			this.document.body.scrollTop ||
			0;
		const isReachingTop = scrollTop < this.topOffset;
		const isReachingBottom =
			target.body.offsetHeight - (this.windowRef.innerHeight + scrollTop) < this.bottomOffset;
		const emitValue: ScrollEvent = {
			isReachingBottom,
			isReachingTop,
			originalEvent: $event,
			isWindowEvent: true,
		};
		this.onScroll.emit(emitValue);
	}

	protected elementScrollEvent($event: Event) {
		const target = <HTMLElement>$event.target;
		const scrollPosition = target.scrollHeight - target.scrollTop;
		const offsetHeight = target.offsetHeight;
		const isReachingTop = target.scrollTop < this.topOffset;
		const isReachingBottom = scrollPosition - offsetHeight < this.bottomOffset;
		const emitValue: ScrollEvent = {
			isReachingBottom,
			isReachingTop,
			originalEvent: $event,
			isWindowEvent: false,
		};
		this.onScroll.emit(emitValue);
	}
}
