import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Export, isExport } from 'domain-entities';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { Observable } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ExportConnector {
	private static COLLECTION_NAME = 'exports';

	constructor(private readonly connector: FirestoreConnector) {}

	createExports(exportEntity: Export): Promise<void> {
		return this.connector.create(
			ExportConnector.COLLECTION_NAME,
			exportEntity.id,
			exportEntity,
			isExport,
		);
	}

	updateExport(id: string, updateFunction: (exp: Export) => Partial<Export> | undefined): void {
		void this.connector.updateDocumentTransactional<Export>(
			ExportConnector.COLLECTION_NAME,
			id,
			updateFunction,
			isExport,
		);
	}

	watchExports(userId: string): Observable<EntityChanges<Export>> {
		const condition: DataQueryCondition<Export> = {
			field: 'userId',
			operator: '==',
			value: userId,
		};
		return this.connector.watchDocumentsChanges<Export>(ExportConnector.COLLECTION_NAME, [
			condition,
		]);
	}
}
