import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NamedIconComponent } from './named-icon.component';

@NgModule({
	declarations: [NamedIconComponent],
	imports: [CommonModule],
	exports: [NamedIconComponent],
})
export class NamedIconModule {}
