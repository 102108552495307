import { NgModule } from '@angular/core';
import { CustomWebComponent } from './custom-web.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { BrowserCompatibilityErrorModule } from './components/browser-compatibility-error/browser-compatibility-error.module';

const COMPONENT = [CustomWebComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [MatProgressSpinnerModule, BrowserCompatibilityErrorModule, SharedModule],
	exports: [COMPONENT],
})
export class CustomWebModule {}
