import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appEventStopPropagation]',
})
export class EventStopPropagationDirective {
	@HostListener('click', ['$event']) onClick(clickEvent): void {
		clickEvent.stopPropagation();
	}
}
