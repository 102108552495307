import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isTaskComment, TaskComment } from 'domain-entities';
import { EQUALS } from '@shared/constants/expression';
import { TASK_COMMENTS } from '@shared/constants/firebase';
import { DataQueryCondition } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class TaskCommentsConnector {
	constructor(private readonly connector: FirestoreConnector) {}

	getTaskComments(taskId: string): Observable<TaskComment[]> {
		const condition: DataQueryCondition<TaskComment> = {
			field: 'taskId',
			operator: EQUALS,
			value: taskId,
		};

		return this.connector.watchDocuments(TASK_COMMENTS, [condition]);
	}

	createTaskComment(taskComment: TaskComment): Promise<void> {
		return this.connector.create(TASK_COMMENTS, taskComment.id, taskComment, isTaskComment);
	}
}
