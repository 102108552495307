import { Injectable } from '@angular/core';
import { ProfileLimit } from 'domain-entities';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectUserProfileLimitsSelector } from '@store/selectors/profile-limits.selectors';
import { subscribeCurrentUserProfileLimits } from '@store/actions/profile-limits.actions';
import { COLLECTION_PROFILE_LIMITS } from '@shared/constants/firebase';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
	providedIn: 'root',
})
export class ProfileLimitsService {
	private profileLimit$ = new BehaviorSubject<ProfileLimit>(null);

	constructor(private afStore: AngularFirestore, private store: Store<AppState>) {
		this.initLoadProfileLimits();

		this.store.select(selectUserProfileLimitsSelector).subscribe((profileLimit) => {
			this.profileLimit$.next(profileLimit);
		});
	}

	getProfileLimits(): Observable<ProfileLimit> {
		return this.profileLimit$.asObservable();
	}

	getProfileLimitsById(userId: string): Observable<ProfileLimit> {
		return this.afStore
			.collection(COLLECTION_PROFILE_LIMITS)
			.doc<ProfileLimit>(userId)
			.valueChanges();
	}

	isAccessibleLimit(limitKey: ProfileLimitKey): boolean {
		const profileLimit = this.profileLimit$.getValue();

		if (!profileLimit) {
			return true; // Some of the profiles in production doesn't have the Profile limits document in firestore
		}
		return profileLimit[limitKey] || false;
	}

	private initLoadProfileLimits(): void {
		this.store.dispatch(subscribeCurrentUserProfileLimits());
	}
}
