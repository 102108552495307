<div class="my-3 d-flex align-items-center">
	<div class="mx-1" *ngFor="let toggle of toggles; let i = index">
		<app-shared-slider-input-toggle-option
			(selected)="onSelection(i)"
			[selected]="i === control.value"
			[source]="toggle.imageSource"
			[caption]="toggle.caption"
		></app-shared-slider-input-toggle-option>
	</div>
</div>
