import { Injectable } from '@angular/core';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';
import { Member, MemberRole, Project, ProjectType } from 'domain-entities';
import { orderBy } from 'lodash';
import {
	removeMembersFromProjectFactory,
	updateMemberInProjectFactory,
} from '@shared/firebase/project/project-update.functions';
import { first } from 'rxjs/operators';
import { ProjectService } from '@injectables/services/project/project.service';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogConfig, ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	ProjectEditDetailDialogComponent,
	ProjectEditDetailDialogData,
} from '@work/project-members/components/project-edit-detail-dialog/project-edit-detail-dialog.component';
import { ProjectmembersMemberAddedEventBuilder } from '@generated/events/ProjectmembersEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { isProjectInternalMember } from '@shared/functions/project/project.functions';
import { firstValueFrom, noop } from 'rxjs';
import { InvitationsConnector } from '@shared/firebase/connectors/firestore/collections/invitations/invitations.connector';
import { createProjectInvitationByProjectIdAndMember } from '@shared/functions/invitations/invitations.functions';
import { selectChildrenOfActiveFolder, selectProject } from '@store/selectors/projects.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProjectMemberService {
	constructor(
		private readonly dialog: MatDialog,
		private readonly fullNamePipe: MemberFullNamePipe,
		private readonly projectService: ProjectService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translate: TranslateService,
		private readonly store: Store<AppState>,
		private readonly trackingService: TrackingService,
		private readonly invitationsConnector: InvitationsConnector,
	) {}

	getOrderByName(members: Member[]): Member[] {
		return orderBy(
			members,
			[(member) => this.fullNamePipe.transform(member, 'email', true).toLowerCase(), 'id'],
			['asc', 'asc'],
		);
	}

	searchFilterCondition(member: Member, searchKey: string = ''): boolean {
		const searchText = searchKey.toLowerCase();

		if (member?.email?.toLowerCase().includes(searchText)) {
			return true;
		}

		const memberName = this.fullNamePipe.transform(member, 'email', true).toLowerCase();
		return memberName.includes(searchText);
	}

	async addMember(
		member: Member,
		project: Project,
		inProgressHandler = noop,
		addToFolder: 'folderOnly' | 'folderAndChildren' = 'folderOnly',
	): Promise<void> {
		const invite = createProjectInvitationByProjectIdAndMember(
			project.id,
			member,
			addToFolder === 'folderAndChildren',
		);

		inProgressHandler();

		await this.invitationsConnector.createInvitation(invite);

		this.trackingService.trackEvent(
			new ProjectmembersMemberAddedEventBuilder({
				projectId: project.id,
				type: isProjectInternalMember(member) ? 'internal' : 'external',
			}),
		);
	}

	async updateMember(member: Member, projectId: string): Promise<void> {
		await this.projectService.updateProjectTransactional(
			projectId,
			updateMemberInProjectFactory(member),
		);
	}

	async removeMembers(members: Member[], projectId: string): Promise<void> {
		await this.projectService.updateProjectTransactional(
			projectId,
			removeMembersFromProjectFactory(members),
		);
	}

	async removeMember(
		member: Member,
		projectId: string,
		inProgressHandler = noop,
		removeFromFolder: 'folderOnly' | 'folderAndChildren' = 'folderOnly',
	): Promise<boolean> {
		const project = await firstValueFrom(this.store.select(selectProject, { projectId }));
		const dialogMessage =
			project?.projectType === ProjectType.FOLDER && removeFromFolder === 'folderAndChildren'
				? 'projectOrFolderMembers.remove_member_folder-children.subTitle'
				: 'projectOrFolderMembers.remove_member.subTitle';
		const projectOrFolderKey =
			project?.projectType === ProjectType.FOLDER ? 'global_folder' : 'global_project';
		const dialogConfig: ConfirmDialogConfig = {
			title: this.translate.instant('projectMembers.remove_member.title'),
			message: this.translate.instant(dialogMessage, {
				projectName: project.name,
				projectType: this.translate.instant(projectOrFolderKey),
			}),
			primaryButtonText: this.translate.instant('projectOrFolderMembers.remove_member.yes', {
				projectType: this.translate.instant(projectOrFolderKey),
			}),
			primaryButtonColor: 'warn',
			secondaryButtonText: this.translate.instant('button.cancel'),
		};

		const result = await this.confirmDialogService
			.open(dialogConfig)
			.afterClosed()
			.pipe(first())
			.toPromise();

		if (!result) {
			return false;
		}

		/**
		 * Method handler to know that the operation is in progress.
		 */
		inProgressHandler();

		if (removeFromFolder === 'folderAndChildren' && project?.projectType === ProjectType.FOLDER) {
			const childProjects = await firstValueFrom(
				this.store.select(selectChildrenOfActiveFolder(projectId)),
			);
			await Promise.all(
				childProjects.map((childProject) =>
					this.projectService.updateProjectTransactional(
						childProject.id,
						removeMembersFromProjectFactory([member]),
					),
				),
			);
		}

		await this.projectService.updateProjectTransactional(
			projectId,
			removeMembersFromProjectFactory([member]),
		);
		return true;
	}

	async updateMemberNote(member: Member, projectId: string): Promise<Member | undefined> {
		const result = await this.editProjectMemberDetailDialog({
			title: this.translate.instant('projectMembers.dialog.edit-note.title'),
			primaryBtnText: this.translate.instant('projectMembers.dialog.edit-note.btnText'),
			formLabel: this.translate.instant('projectMembers.dialog.edit-note.label'),
			formValue: member.note,
			formType: 'textarea',
		});

		if (result === null) {
			return;
		}

		const updatedMember = { ...member, note: result };
		await this.updateMember(updatedMember, projectId);
		return updatedMember;
	}

	async updateMemberFunction(member: Member, projectId: string): Promise<Member | undefined> {
		const result = await this.editProjectMemberDetailDialog({
			title: this.translate.instant('projectMembers.dialog.edit-function.title'),
			primaryBtnText: this.translate.instant('projectMembers.dialog.edit-function.btnText'),
			formLabel: this.translate.instant('projectMembers.dialog.edit-function.label'),
			formValue: member.function,
			formType: 'text',
		});

		if (result === null) {
			return;
		}
		const updatedMember = { ...member, function: result };
		await this.updateMember(updatedMember, projectId);
		return updatedMember;
	}

	async updateMemberRole(
		member: Member,
		projectId: string,
		role: MemberRole,
	): Promise<Member | undefined> {
		const updatedMember = { ...member, role };
		await this.updateMember(updatedMember, projectId);
		return updatedMember;
	}

	private async editProjectMemberDetailDialog(
		dialogConfig: ProjectEditDetailDialogData,
	): Promise<string | null> {
		const matDialogConfig: MatDialogConfig<ProjectEditDetailDialogData> = {
			disableClose: true,
			hasBackdrop: true,
			autoFocus: true,
			width: 'min(100%, 600px)',
			data: { ...dialogConfig },
		};

		return this.dialog
			.open<ProjectEditDetailDialogComponent, ProjectEditDetailDialogData, string | null>(
				ProjectEditDetailDialogComponent,
				matDialogConfig,
			)
			.afterClosed()
			.pipe(first())
			.toPromise();
	}
}
