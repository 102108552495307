import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadCompanyTasks, setCompanyTasks } from '@store/actions/tasks.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { CompanyTasksService } from '@craftnote/shared-injectables';
import { Store } from '@ngrx/store';
import { selectCompanyId } from '@store/selectors/app.selectors';

@Injectable()
export class CompanyTasksEffect {
	constructor(
		private actions$: Actions,
		private readonly companyTasksService: CompanyTasksService,
		private readonly store: Store,
	) {}

	loadCompanyTasks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCompanyTasks),
			take(1),
			switchMap(() => this.store.select(selectCompanyId)),
			switchMap((companyId) => this.companyTasksService.getCompanyTasks(companyId)),
			map((changes) => setCompanyTasks({ changes })),
		),
	);
}
