<ng-template #globalSearchDialogTemplateRef>
	<mat-icon class="position-absolute close-button" mat-dialog-close="[]">close</mat-icon>

	<section [class.dark]="isDarkTheme$ | async" class="position-relative global-search-dialog-section h-100 overflow-x-hidden">
		<form [class.filter-container-hidden]="isFilterContainerHidden" [formGroup]="globalSearchForm" class="d-flex h-100 px-2">
			<div class="flex-scroll flex-1 global-search-dialog-content overflow-x-hidden">
				<div class="flex-scroll__container w-100">
					<div class="d-flex">
						<mat-form-field class="w-100 pr-2">
							<mat-label>{{ 'global-search-dialog.input-placeholder' | translate }}</mat-label>
							<input #searchTermRef autocomplete="off" formControlName="searchTerm" matInput trim="blur" type="text" />
							<button (click)="resetSearchTerm($event)" *ngIf="searchTermRef.value" mat-icon-button matSuffix>
								<mat-icon [size]="25">close</mat-icon>
							</button>
							<button *ngIf="!searchTermRef.value" mat-icon-button matSuffix>
								<mat-icon [size]="25">search</mat-icon>
							</button>
						</mat-form-field>
					</div>

					<ng-container *ngIf="searchResults?.error === 'searchTermIsLessThanThreeCharacters' || searchResults === undefined">
						<div
							*ngIf="lastInteractionSearchResults?.length === 0 || (isNotPremiumUser$ | async)"
							class="flex-1 d-flex align-items-center justify-content-center flex-column text-center"
						>
							<mat-icon [size]="40" class="mb-1">rocket_launch</mat-icon>
							<p [innerHTML]="'global-search-dialog.initial-screen-text' | translate"></p>
						</div>

						<div
							class="flex-scroll__container__scrollable-content position-relative w-100"
							*ngIf="lastInteractionSearchResults?.length !== 0 && (isPremiumUser$ | async)"
						>
							<ng-container
								*ngTemplateOutlet="
									searchResultsTemplate;
									context: {
										$implicit: lastInteractionSearchResults,
										heading: 'global-search-dialog.heading.last-interaction' | translate,
										containerClass: ''
									}
								"
							>
							</ng-container>
						</div>
					</ng-container>

					<div
						*ngIf="searchResults?.error === 'somethingWentWrong'"
						class="flex-1 d-flex align-items-center justify-content-center flex-column text-center pb-5"
					>
						<mat-icon [size]="40" class="mb-1">error_outline</mat-icon>
						<p [innerHTML]="'global-search-dialog.error-screen-text' | translate"></p>
					</div>

					<div
						*ngIf="searchResults?.result?.total === 0"
						class="flex-1 d-flex align-items-center justify-content-center flex-column text-center pb-5"
					>
						<mat-icon [size]="40" class="mb-1">search</mat-icon>
						<p [innerHTML]="'global-search-dialog.no-search-results' | translate"></p>
					</div>

					<div *ngIf="searchResults === null">
						<mat-spinner class="absolute-center" color="accent" diameter="45"></mat-spinner>
					</div>

					<ng-container *ngIf="searchResults?.result?.total > 0">
						<div class="border-bottom pb-3 mb-3">
							{{ 'global-search-dialog.total-results' | translate }} ({{ searchResults.result.total }})
						</div>

						<div
							class="flex-scroll__container__scrollable-content position-relative w-100"
							[class.overflow-y-hidden]="isNotPremiumUser$ | async"
						>
							<ng-container *ngIf="(isPremiumUser$ | async) && !(isFilterSelected$ | async)">
								<ng-container
									*ngTemplateOutlet="
										searchResultsTemplate;
										context: {
											$implicit: searchResults.result.bestHits,
											heading: 'global-search-dialog.heading.top-hits' | translate,
											containerClass: 'global-search-highlight-text',
											type: 'bestHits'
										}
									"
								>
								</ng-container>
							</ng-container>

							<ng-container
								*ngTemplateOutlet="
									searchResultsTemplate;
									context: {
										$implicit: searchResults.result.hits.projects,
										heading: 'global-search-dialog.heading.projects' | translate,
										containerClass:
											(isNotPremiumUser$ | async)
												? ' opaque-disable-projects global-search-highlight-text'
												: 'global-search-highlight-text',
										type: 'projects'
									}
								"
							>
							</ng-container>

							<ng-container
								*ngTemplateOutlet="
									searchResultsTemplate;
									context: {
										$implicit: searchResults.result.hits.folders,
										heading: 'global-search-dialog.heading.folders' | translate,
										containerClass:
											(isNotPremiumUser$ | async) ? ' opaque-disable global-search-highlight-text' : 'global-search-highlight-text'
									}
								"
							>
							</ng-container>

							<ng-container
								*ngTemplateOutlet="
									searchResultsTemplate;
									context: {
										$implicit: searchResults.result.hits.chatmessages,
										heading: 'global-search-dialog.heading.chat-messages' | translate,
										containerClass:
											(isNotPremiumUser$ | async) ? ' opaque-disable global-search-highlight-text' : 'global-search-highlight-text'
									}
								"
							>
							</ng-container>

							<ng-container
								*ngTemplateOutlet="
									searchResultsTemplate;
									context: {
										$implicit: searchResults.result.hits.tasks,
										heading: 'global-search-dialog.heading.tasks' | translate,
										containerClass:
											(isNotPremiumUser$ | async) ? ' global-search-highlight-text opaque-disable' : 'global-search-highlight-text'
									}
								"
							>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</div>

			<div
				class="flex-scroll px-2 py-1 flex-column filter-container border-left position-relative"
				[class.opaque-disable]="isNotPremiumUser$ | async"
			>
				<div class="flex-scroll__container filter-container-content">
					<div class="flex-scroll__container__scrollable-content">
						<mat-button-toggle-group class="border-0 mb-3" formControlName="selectedFilter" vertical="true">
							<div class="font-weight-bold mb-1 mat-h2">{{ 'global-search-dialog.project-filter.heading' | translate }}</div>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.PROJECTS_ALL">
								{{ 'global-search-dialog.project-filter.all' | translate }}
							</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.PROJECTS_ACTIVE">
								{{ 'global-search-dialog.project-filter.active' | translate }}
							</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.PROJECTS_ARCHIVED">
								{{ 'global-search-dialog.project-filter.archive' | translate }}
							</mat-button-toggle>

							<div class="font-weight-bold mt-3 mb-1 mat-h2">{{ 'global-search-dialog.folder-filter.heading' | translate }}</div>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.FOLDERS_ALL">
								{{ 'global-search-dialog.folder-filter.all' | translate }}
							</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.FOLDERS_ACTIVE">
								{{ 'global-search-dialog.folder-filter.active' | translate }}
							</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.FOLDERS_ARCHIVED">
								{{ 'global-search-dialog.folder-filter.archive' | translate }}
							</mat-button-toggle>

							<div class="font-weight-bold mt-3 mb-1 mat-h2">{{ 'global-search-dialog.chat-message-filter.heading' | translate }}</div>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.MESSAGES">{{
								'global-search-dialog.chat-message-filter.all' | translate
							}}</mat-button-toggle>

							<div class="font-weight-bold mt-3 mb-1 mat-h2">{{ 'global-search-dialog.task-filter.heading' | translate }}</div>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.TASKS_ALL">{{
								'global-search-dialog.task-filter.all' | translate
							}}</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.TASKS_OPEN">{{
								'global-search-dialog.task-filter.open' | translate
							}}</mat-button-toggle>
							<mat-button-toggle class="border-0 text-left" [value]="GlobalSearchFilterValue.TASKS_CLOSED">{{
								'global-search-dialog.task-filter.closed' | translate
							}}</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<div class="flex-1 d-flex flex-column justify-content-end align-items-start">
						<button (click)="resetFilters()" [class.d-none]="!(isFilterSelected$ | async)" color="warn" mat-button>
							{{ 'global-search-dialog.filters-reset-button' | translate }}
						</button>
					</div>
				</div>

				<button (click)="isFilterContainerHidden = !isFilterContainerHidden" class="filter-toggle-button" mat-icon-button>
					<mat-icon fontSet="material-icons-outlined" [size]="25">{{
						isFilterContainerHidden ? 'arrow_circle_left_outline' : 'arrow_circle_right_outline'
					}}</mat-icon>
				</button>
			</div>
		</form>

		<div class="global-search-paywall-info mat-elevation-z6" *ngIf="searchResults?.result && isNotPremiumUser$ | async">
			<h1 class="text-center">{{ 'global-search-dialog.paywall.heading' | translate }}</h1>

			<div class="d-flex mb-3 justify-content-around mr-2 pr-2 mat-small">
				<ul class="list-unstyled">
					<li class="orange-tick">{{ 'global-search-dialog.paywall.point-1' | translate }}</li>
					<li class="orange-tick">{{ 'global-search-dialog.paywall.point-2' | translate }}</li>
				</ul>

				<ul class="list-unstyled">
					<li class="orange-tick">{{ 'global-search-dialog.paywall.point-3' | translate }}</li>
					<li class="orange-tick">{{ 'global-search-dialog.paywall.point-4' | translate }}</li>
				</ul>
			</div>

			<a
				*ngIf="isOwner$ | async"
				[mat-dialog-close]="[['/settings/subscription/products']]"
				(click)="dispatchUpgradeEvent()"
				class="w-100"
				rel="noopener"
				mat-flat-button
				color="accent"
				>{{ 'global-search-dialog.paywall.cta-owner' | translate }}</a
			>
			<a
				*ngIf="!(isOwner$ | async)"
				href="https://v2.craftnote.de/preise"
				class="w-100"
				rel="noopener"
				target="_blank"
				mat-flat-button
				color="accent"
			>
				{{ 'global-search-dialog.paywall.cta-non-owner' | translate }}
			</a>
		</div>
	</section>
</ng-template>

<ng-template #searchResultsTemplate let-heading="heading" let-searchResults let-containerClass="containerClass" let-type="type">
	<div *ngIf="searchResults?.length" class="mb-3 pr-2" [ngClass]="containerClass">
		<div class="font-weight-bold mb-2">{{ heading }} ({{ searchResults.length }})</div>
		<ul
			#appShowMoreItemsRef="appShowMoreItems"
			[appShowMoreItems]="type === 'bestHits' ? null : maxItemsForShowMore"
			class="list-unstyled search-results-container m-0"
		>
			<li *ngFor="let searchItem of searchResults">
				<ng-container [ngSwitch]="searchItem.type">
					<ng-container
						*ngSwitchCase="'project'"
						[ngTemplateOutletContext]="{ $implicit: addHighlightsForProjects(searchItem) }"
						[ngTemplateOutlet]="projectTemplate"
					></ng-container>
					<ng-container
						*ngSwitchCase="'task'"
						[ngTemplateOutletContext]="{ $implicit: addHighlightsForTasks(searchItem) }"
						[ngTemplateOutlet]="taskTemplate"
					></ng-container>
					<ng-container
						*ngSwitchCase="'chatMessage'"
						[ngTemplateOutletContext]="{ $implicit: addHighlightsForChats(searchItem) }"
						[ngTemplateOutlet]="chatMessageTemplate"
					></ng-container>
				</ng-container>
			</li>
		</ul>

		<div *ngIf="appShowMoreItemsRef.childElementsExceedsMaxItemsToShow && type !== 'bestHits'" class="mt-1 show-more-button-container">
			<button
				(click)="appShowMoreItemsRef.itemsHidden ? appShowMoreItemsRef.showItems() : appShowMoreItemsRef.hideItems()"
				class="text-muted"
				mat-button
			>
				{{
					(appShowMoreItemsRef.itemsHidden ? 'global-search-dialog.show-more-button' : 'global-search-dialog.show-less-button') | translate
				}}
				({{ searchResults.length - maxItemsForShowMore }})
			</button>
		</div>
	</div>

	<div *ngIf="type === 'projects' && !searchResults?.length && isNotPremiumUser$ | async">
		<div class="font-weight-bold mb-2">{{ heading }} (0)</div>
		<p>{{ 'global-search-dialog.paywall.no-projects' | translate }}</p>
	</div>
</ng-template>

<ng-template #projectTemplate let-project>
	<div (click)="openProject(project)" class="d-flex align-items-center pointer py-1">
		<mat-icon [size]="30" class="mr-1">{{ project.projectType === ProjectType.PROJECT ? 'description' : 'folder' }}</mat-icon>
		<div [ngClass]="projectColorTags[project.colorTag]" class="mr-2 rounded project-color-tag"></div>
		<div class="d-inline-flex flex-column">
			<div class="mb-1 font-weight-bold text-break">
				<span [innerHTML]="project.name"></span> <span class="dash-prefix" [innerHTML]="project.orderNumber"></span>
			</div>
			<div>
				<span class="dash-suffix text-break" [innerHTML]="project | projectAddress"></span>
				<ng-container *ngVar="projectStatusEntities$ | async as projectStatusEntities">
					<span *ngIf="projectStatusEntities" class="dash-suffix text-break">{{ projectStatusEntities[project?.statusId]?.name }}</span>
				</ng-container>
				<span class="text-muted">{{
					(project.archived ? 'global-search-dialog.project-archive' : 'global-search-dialog.project-active') | translate
				}}</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #taskTemplate let-task>
	<div (click)="openTask(task)" class="d-flex align-items-center pointer py-1">
		<mat-icon [size]="30" class="mr-2" fontSet="material-icons-outlined">check_box</mat-icon>
		<div class="d-inline-flex flex-column">
			<div class="mb-1 text-break" [innerHTML]="task.title"></div>
			<div>
				<span class="dash-suffix text-break" [innerHTML]="task.assignee"></span>
				<span *ngIf="task.projectId && projectEntities[task.projectId]?.name" class="dash-suffix text-break">
					{{ 'global-search-dialog.projects' | translate }}: {{ projectEntities[task.projectId]?.name }}
				</span>
				<span class="text-muted">
					{{ (task.done ? 'global-search-dialog.task-completed' : 'global-search-dialog.task-done') | translate }}
				</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #chatMessageTemplate let-chatMessage>
	<div (click)="openChatMessage(chatMessage)" class="d-flex align-items-center pointer py-1">
		<mat-icon [size]="30" class="mr-2" fontSet="material-icons-outlined">chat</mat-icon>
		<div class="d-inline-flex flex-column">
			<div class="mb-1 chat-message-content" [innerHTML]="chatMessage.content"></div>
			<div>
				<span class="text-break" [innerHTML]="chatMessage.author"></span>
				<span class="dash-prefix"> {{ chatMessage.timestamp | unixToMillis | date: 'shortDate' }} </span>
				<span *ngIf="chatMessage.projectId && projectEntities[chatMessage.projectId]?.name" class="dash-prefix text-break">
					{{ 'global-search-dialog.projects' | translate }}: {{ projectEntities[chatMessage.projectId]?.name }}
				</span>
			</div>
		</div>
	</div>
</ng-template>
