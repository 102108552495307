import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, first, map, switchMap, switchMapTo } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AppState } from '../state/app.state';
import { ProjectService } from '@injectables/services/project/project.service';
import { selectCompanyId } from '../selectors/app.selectors';
import * as fromCompanyProjects from '../actions/company-projects.actions';

@Injectable()
export class CompanyProjectsEffect {
	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly projectService: ProjectService,
	) {}

	subscribeCompanyProjects = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCompanyProjects.subscribeCompanyProjects),
			first(),
			switchMapTo(this.store.select(selectCompanyId)),
			switchMap((companyId) =>
				companyId ? this.projectService.watchCompanyProjects(companyId) : EMPTY,
			),
			map((payload) => fromCompanyProjects.companyProjectsChanged({ payload })),
			catchError((error) => {
				console.error(error);
				return of(fromCompanyProjects.subscribeCompanyProjectsFailed(error));
			}),
		),
	);
}
