import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { UpdateProjectStatusDialogComponent } from '@modules/features/settings/components/update-project-status-dialog/update-project-status-dialog.component';
import { Store } from '@ngrx/store';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectService } from '@injectables/services/project/project.service';
import {
	getRoleOfProjectMember,
	isInternalProjectAdmin,
} from '@shared/functions/project/project.functions';
import {
	NO_PROJECT_STATUS,
	ProjectContext,
	ProjectItemAction,
	ProjectItemActions,
	ProjectStatusInformation,
} from '@shared/models/project.type';
import {
	isOwnerOfCompany,
	selectCompany,
	selectProjectStatus,
	selectUserEmail,
	selectUserId,
	selectUserRole,
} from '@store/selectors/app.selectors';
import {
	selectOpenedFolder,
	selectParentProject,
	selectProject,
} from '@store/selectors/projects.selectors';
import { AppState } from '@store/state/app.state';
import { MemberRole, Project, ProjectType } from 'domain-entities';
import moment from 'moment';
import { combineLatest, firstValueFrom, NEVER, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsProjectStatusUpdatedEventBuilder } from '@generated/events/ProjectsEvents.generated';
import { ProjectMoveDialogComponent } from '@work/project/project-move-dialog/project-move-dialog.component';
import { ProjectDuplicateDialogComponent } from '@work/project/project-duplicate-dialog/project-duplicate-dialog.component';
import { Company } from '@shared/models/company.model';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { TrackingService } from '@injectables/services/tracking.service';
import { findIndex } from 'lodash';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { ProjectAreaStore } from '../../store/project-area.store';
import {
	selectKeepActiveProjectUnread,
	selectProjectUnreadByProjectId,
} from '@store/selectors/project-unreads.selectors';
import { selectIsProjectsRoute } from '@store/selectors/route.selectors';
import { ProfileSettingService } from '@injectables/services/profile-setting.service';
import { ProjectUnreadsService } from '@injectables/services/project-unreads.service';
import { setProjectAsUnread } from '@store/actions/project-unreads.actions';
import { selectIsCurrentUserHasProjectInvitationsRemaining } from '@store/selectors/profile-limits.selectors';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { selectSubscriptionsOfTypeSubscription } from '@store/selectors/subscriptions.selectors';
import { LinkService } from '@injectables/services/link/link.service';
import {
	ProjectmembersLimitPaywallOpenedEventBuilder,
	ProjectmembersLimitPaywallSelectedEventBuilder,
} from '@generated/events/ProjectmembersEvents.generated';
import { pause } from '@craftnote/shared-utils';

interface ProjectItemData {
	project: Project;
	openedFolder?: Project;
	parentProject?: Project;
}

@Component({
	selector: 'app-project-item-wrapper',
	templateUrl: './project-item-wrapper.component.html',
	styleUrls: ['./project-item-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectItemWrapperComponent implements OnChanges {
	@Input() projectId: string;
	@Input() selected: boolean;
	@Input() context: ProjectContext;
	@Input() isOpenFolderCard = false;
	@Input() lastOpenedTimestamp: number;
	@Input() showParentHint = false;
	@Input() isLastItem = false;
	@Input() isChatMessagesMuted = false;
	@Input() pauseProject$: Observable<boolean>;

	@Output() backClicked = new EventEmitter<void>();
	@Output() menuOpened = new EventEmitter<boolean>();

	projectStatus$ = this.store.select(selectProjectStatus);
	company$ = this.store.select(selectCompany).pipe(filter((company) => !!company));
	projectItemData$: Observable<ProjectItemData>;

	constructor(
		private readonly router: Router,
		private readonly datePipe: DatePipe,
		private readonly authService: AuthService,
		private readonly store: Store<AppState>,
		private readonly projectService: ProjectService,
		private readonly projectHelperService: ProjectHelperService,
		private readonly matDialog: MatDialog,
		private readonly translate: TranslateService,
		private readonly trackingService: TrackingService,
		private readonly componentStore: ProjectAreaStore,
		private readonly profileSettingService: ProfileSettingService,
		private readonly projectUnreadsService: ProjectUnreadsService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly linkService: LinkService,
	) {}

	get projectTime(): string {
		if (typeof this.lastOpenedTimestamp !== 'number') {
			return '';
		}

		const date = new Date(this.lastOpenedTimestamp * 1000);
		if (moment().startOf('day') > moment(date)) {
			return this.datePipe.transform(date, 'shortDate');
		} else {
			return this.datePipe.transform(date, 'shortTime');
		}
	}

	isProjectUnread = (project: Project) =>
		combineLatest([
			this.store.select(selectProjectUnreadByProjectId(project.id)),
			this.store.select(selectIsProjectsRoute),
			this.store.select(selectKeepActiveProjectUnread),
		]).pipe(
			map(
				([isProjectUnread, isProjectsRoute, keepCurrentProjectUnread]) =>
					isProjectsRoute &&
					isProjectUnread &&
					!(
						project.projectType === ProjectType.PROJECT &&
						this.selected &&
						!keepCurrentProjectUnread
					),
			),
		);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['projectId']) {
			this.getProject();
		}
	}

	getProjectStatus(
		project: Project,
		projectStatus: ProjectStatus[],
	): ProjectStatusInformation | null {
		if (!project.statusId) {
			return null;
		}

		const statusIndex = findIndex(projectStatus, (status) => status.id === project.statusId);

		if (statusIndex < 0) {
			return null;
		}
		const statusName = projectStatus[statusIndex].name;
		const totalCompanyStatuses = projectStatus.length;

		return { statusName, statusIndex, totalCompanyStatuses };
	}

	getProject(): void {
		const project$ = this.store.select(selectProject, { projectId: this.projectId }).pipe(
			pause(this.pauseProject$ || NEVER),
			filter((project) => !!project),
		);
		const parentProject$ = this.store.select(selectParentProject, { projectId: this.projectId });
		const openedFolder$ = this.store.select(selectOpenedFolder);

		this.projectItemData$ = combineLatest([project$, openedFolder$, parentProject$]).pipe(
			map(([project, openedFolder, parentProject]) => ({ project, openedFolder, parentProject })),
		);
	}

	getUserRole(project: Project): MemberRole {
		return getRoleOfProjectMember(this.authService.currentUserId(), project, 'id');
	}

	isCurrentUserArchivedInProject(project: Project): Observable<boolean> {
		if (project.status?.name === 'archived') {
			return of(true);
		}
		return this.store
			.select(selectUserId)
			.pipe(
				map((id) => project.membersLastOpenedArchived && !!project.membersLastOpenedArchived[id]),
			);
	}

	isCurrentUserInternalAdminOfProject(project: Project): Observable<boolean> {
		return this.store
			.select(selectUserEmail)
			.pipe(map((email) => isInternalProjectAdmin(project.members[email])));
	}

	isExternalMemberOfProject$(project: Project): Observable<boolean> {
		return this.projectService.isExternalMemberOfProject$(project).pipe(take(1));
	}

	async performProjectItemAction(projectAction: {
		action: ProjectItemAction;
		project: Project;
	}): Promise<void> {
		const { action, project } = projectAction;
		switch (action) {
			case ProjectItemActions.STATUS_UPDATE:
				await this.showStatusUpdateDialog(project);
				break;

			case ProjectItemActions.MOVE_PROJECT:
				this.openMoveProjectDialog(project);
				break;

			case ProjectItemActions.ARCHIVE_ROJECT:
				await this.showArchiveDialog(project);
				break;

			case ProjectItemActions.DUPLICATE_FOLDER:
				await this.showDuplicateFolderDialog(project);
				break;

			case ProjectItemActions.RESTORE_PROJECT:
				await this.restoreProject(project);
				break;

			case ProjectItemActions.MUTE_UNMUTE_CHAT:
				await this.muteUnMuteProjectChat(project);
				break;

			case ProjectItemActions.MARK_PROJECT_AS_READ:
				await this.markProjectAsRead(project);
				break;

			case ProjectItemActions.MARK_PROJECT_AS_UNREAD:
				this.markProjectAsUnread(project);
				break;
		}
	}

	async showStatusUpdateDialog(project: Project): Promise<void> {
		const dialogRef = this.matDialog.open(UpdateProjectStatusDialogComponent, {
			width: '500px',
			data: {
				projectName: project.name,
				statuses$: this.projectStatus$,
				selectedStatusId: project.statusId,
			},
		});
		const selectedStatusId = await dialogRef.afterClosed().pipe(take(1)).toPromise();

		if (!selectedStatusId || selectedStatusId === project.statusId) {
			return;
		}

		const updateObject: Partial<Project> = {
			statusId: selectedStatusId === NO_PROJECT_STATUS ? null : selectedStatusId,
		};
		await this.projectService.updateProjectPartial(
			project.id,
			updateObject,
			this.translate.instant('project.actions.updateStatus.approval'),
		);

		await this.trackingService.trackEvent(
			new ProjectsProjectStatusUpdatedEventBuilder({
				projectId: project.id,
				isInitial: !project.statusId,
			}),
		);
	}

	openMoveProjectDialog(project: Project): void {
		this.matDialog.open(ProjectMoveDialogComponent, {
			width: 'min(100%, 600px)',
			maxHeight: '80vh',
			data: {
				project: project,
			},
		});
	}

	async showArchiveDialog(project: Project): Promise<void> {
		const archivationResult = await this.projectService.archiveProject(project);

		if (!archivationResult) {
			return;
		}

		this.projectHelperService.setLastOpenedProject(null);

		if (project.parentProject) {
			await this.router.navigate(['projects', project.parentProject]);
		} else {
			this.projectHelperService.goToHome();
		}
	}

	async showDuplicateFolderDialog(project: Project): Promise<void> {
		if (
			!(await firstValueFrom(
				this.store.select(selectIsCurrentUserHasProjectInvitationsRemaining()),
			))
		) {
			const role = (await firstValueFrom(this.store.select(selectUserRole))).toLowerCase();
			this.trackingService.trackEvent(
				new ProjectmembersLimitPaywallOpenedEventBuilder({ context: 'project-duplicate', role }),
			);
			const result = await firstValueFrom(
				this.confirmDialogService
					.open({
						title: this.translate.instant('project-item-wrapper.project-duplicate-title'),
						message: this.translate.instant(
							'project-item-wrapper.project-duplicate-restriction-description',
						),
						primaryButtonColor: 'accent',
						showSecondaryButton: true,
						showCrossBtn: false,
						primaryButtonText: this.translate.instant(
							'project-item-wrapper.project-duplicate-restriction-to-upgrade',
						),
						secondaryButtonText: this.translate.instant(
							'project-item-wrapper.project-duplicate-restriction-cancel',
						),
						primaryButtonValue: 'open-upgrade',
						maxWidth: '40vw',
					})
					.afterClosed(),
			);
			if (result === 'open-upgrade') {
				const isOwner = await firstValueFrom(this.store.select(isOwnerOfCompany));
				if (isOwner) {
					this.trackingService.trackEvent(
						new ProjectmembersLimitPaywallSelectedEventBuilder({
							context: 'project-duplicate',
							role,
						}),
					);
					const hasSubscriptions = await firstValueFrom(
						this.store.select(selectSubscriptionsOfTypeSubscription),
					);
					await this.router.navigate([
						hasSubscriptions.length ? '/settings/subscription' : 'settings/subscription/products',
					]);
				} else {
					this.linkService.openLinkInNewTab(this.linkService.pricePage);
				}
			}
			return;
		}
		this.matDialog.open(ProjectDuplicateDialogComponent, {
			data: {
				project: project,
				company: new Company().deserialize(await this.company$.pipe(take(1)).toPromise()),
				title:
					project.projectType === ProjectType.PROJECT
						? 'project.actions.archive.dialog.title'
						: 'folder.actions.archive.dialog.title',
				content:
					project.projectType === ProjectType.PROJECT
						? 'project.actions.archive.dialog.approval'
						: 'folder.actions.archive.dialog.approval',
			},
			width: '600px',
		});
	}

	getChildProjects(project: Project): Observable<Project[] | []> {
		if (project.projectType === ProjectType.FOLDER) {
			return this.componentStore.childrenOfFolder(project.id);
		}

		return of([]);
	}

	private async restoreProject(project: Project): Promise<void> {
		const result = await this.projectService.unarchiveProject(project);
		if (!result) {
			return;
		}

		if (result !== 'unarchive-folder' && project.parentProject) {
			await this.router.navigate(['archive', project.parentProject]);
		} else {
			await this.projectHelperService.goToHome(null, true);
		}
	}

	private async muteUnMuteProjectChat(project: Project): Promise<void> {
		void this.profileSettingService.muteUnMuteProjectChat(project.id);
	}

	private async markProjectAsRead(project: Project): Promise<void> {
		await this.projectUnreadsService.removeProjectUnread(project);
	}

	private markProjectAsUnread(project: Project): void {
		this.store.dispatch(setProjectAsUnread({ project }));
	}
}
