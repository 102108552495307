import { Injectable } from '@angular/core';
import { AuthService } from '@injectables/services/auth/auth.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { DevicesConnector } from '@shared/firebase/connectors/firestore/collections/profiles/notification-tokens/devices.connector';
import { Device } from 'domain-entities';
import { BrowserService } from '@injectables/services/browser.service';
import { selectUserId } from '@store/selectors/app.selectors';
import { switchMap, take } from 'rxjs/operators';
import { BrowserDetectionService } from '@injectables/services/browser-detection.service';
import { removeNotificationToken } from '@shared/firebase/profile/profile-update.functions';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { firstValueFrom, from, Observable } from 'rxjs';
import { selectCurrentDevice } from '@store/selectors/device.selectors';
import { getId, getInstallations } from 'firebase/installations';
import { FirebaseApp } from '@angular/fire/app';
import { getUnixTime } from 'date-fns';

const ALL_DAY = { enabled: true, from: { hour: 0, minute: 0 }, to: { hour: 23, minute: 59 } };
export const NOTIFICATIONS_ENABLED_AT_DEFAULT_VALUE: Pick<Device, 'notificationsEnabledAt'> = {
	notificationsEnabledAt: {
		enabled: true,
		days: {
			MONDAY: ALL_DAY,
			TUESDAY: ALL_DAY,
			WEDNESDAY: ALL_DAY,
			THURSDAY: ALL_DAY,
			FRIDAY: ALL_DAY,
			SATURDAY: ALL_DAY,
			SUNDAY: ALL_DAY,
		},
	},
};

@Injectable({ providedIn: 'root' })
export class NotificationInfoService {
	constructor(
		private readonly store: Store<AppState>,
		private readonly authService: AuthService,
		private readonly browserDetectionService: BrowserDetectionService,
		readonly browser: BrowserService,
		private readonly devicesConnector: DevicesConnector,
		private readonly profileService: ProfileService,
		private readonly firebase: FirebaseApp,
	) {
		this.registerNotificationCleanup();
	}

	async upsertNotificationInfo(device: Partial<Device>): Promise<void> {
		const userId = await firstValueFrom(this.store.select(selectUserId));
		const deviceId = await this.getDeviceId();
		const existingDevice = await this.devicesConnector.getDevice(userId, deviceId);

		if (existingDevice) {
			await this.devicesConnector.updateDevicePartial(userId, deviceId, device);
		} else {
			const now = getUnixTime(new Date());
			const info: Device = {
				lastUsedDate: now,
				name: this.browserDetectionService.getBrowserName(),
				notificationPermissionEnabled: true,
				platform: 'web',
				id: await this.getDeviceId(),
				notificationToken: device.notificationToken,
				creationDate: now,
			};
			await this.devicesConnector.createDevice(userId, info);
		}

		this.removeLegacyToken(device.notificationToken, userId);
	}

	async updateNotificationChannels(channelKey: string, action: 'add' | 'remove'): Promise<void> {
		const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const device = await this.store.select(selectCurrentDevice).pipe(take(1)).toPromise();

		if (!(device && userId)) {
			return;
		}

		await this.devicesConnector.updateChannel(userId, device.id, channelKey, action);
	}

	watchDevice(userId: string): Observable<Device> {
		return from(this.getDeviceId()).pipe(
			switchMap((device) => this.devicesConnector.watchDevice(userId, device)),
		);
	}

	/**
	 * This method ensures that the token is removed from the profile itself to
	 * avoid having the token on the profile AND on the subcollection
	 * @private
	 */
	private removeLegacyToken(token: string | null, userId: string): void {
		if (!token) {
			return;
		}
		const updateFunction = removeNotificationToken(token);
		void this.profileService.updateProfileTransactional(userId, updateFunction);
	}

	private async getDeviceId(): Promise<string> {
		return await getId(getInstallations(this.firebase));
	}

	private registerNotificationCleanup(): void {
		const logoutAction = async () => {
			const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
			await this.devicesConnector.updateDevicePartial(userId, await this.getDeviceId(), {
				notificationToken: undefined,
			});
		};
		this.authService.registerLogoutAction(logoutAction);
	}
}
