import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { v4 as uuid } from 'uuid';
import { map, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { EQUALS } from '@shared/constants/expression';
import { FILES } from '@shared/constants/firebase';
import { THUMB } from '@shared/constants/thumbnail';
import { JPG } from '@shared/constants/upload.types';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ChatService } from '@injectables/services/chat/chat.service';
import { BaseFileService } from '@injectables/services/base-file.service';
import { COMPANY_FILE_TYPE, CompanyTemplate } from '@shared/models/company-template.model';
import { FILE_TYPE, FileDocument } from '@craftnote/shared-utils';

import {
	File as FileEntity,
	Message as MessageEntity,
	Permissions,
	Profile,
} from 'domain-entities';
import moment from 'moment';
import { Observable } from 'rxjs';
import {
	FileUploadService,
	ProjectFileUploadParameters,
} from '@injectables/services/file-upload/file-upload.service';
import { FileDownloadService } from '@injectables/services/file-download/file-download.service';
import { ProjectFilesConnector } from '@shared/firebase/connectors/firestore/collections/project-file/project-files.connector';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { readFileAsync } from '@utils/files/file-utils.functions';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	FilesFileCreatedEventBuilder,
	FilesFolderCreatedEventBuilder,
} from '@generated/events/FilesEvents.generated';
import { DownlaodFileType } from '@globalTypes/interfaces/downloadFileType.model';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';

@Injectable({
	providedIn: 'root',
})
export class FileExplorerService {
	public loaded = true;

	constructor(
		private baseFile: BaseFileService,
		public database: AngularFireDatabase,
		private http: HttpClient,
		private authService: AuthService,
		private chatService: ChatService,
		private fileUploadService: FileUploadService,
		private readonly fileDownloadService: FileDownloadService,
		private readonly projectFilesConnector: ProjectFilesConnector,
		private readonly afStore: AngularFirestore,
		private readonly storage: AngularFireStorage,
		private readonly trackingService: TrackingService,
	) {}

	clone(element: FileDocument) {
		return JSON.parse(JSON.stringify(element));
	}

	/**
	 * Legacy method to support FileDocument class
	 * Should be removed once the the class is removed
	 *
	 * @param file
	 */
	createFolder(file: FileDocument): void {
		void this.createFolderEntity(JSON.parse(JSON.stringify(file)));
	}

	async createFolderEntity(file: FileEntity): Promise<void> {
		await this.projectFilesConnector.createProjectFile(file);
		await this.trackingService.trackEvent(
			new FilesFolderCreatedEventBuilder({
				projectId: file.projectId,
				folderId: file.id,
			}),
		);
	}

	public rootFiles(files: FileDocument[]) {
		const projectFiles = files.filter(
			(file: FileDocument) => !file.folderId && file.type !== FILE_TYPE.FOLDER,
		);
		const projectFolders = files.filter(
			(file: FileDocument) => file.type === FILE_TYPE.FOLDER && !file.folderId,
		);
		return [...projectFiles, ...projectFolders];
	}

	public createTemplateFolder(companyId: string) {
		const companyFolder: CompanyTemplate = new CompanyTemplate();

		companyFolder.id = uuid();
		companyFolder.companyId = companyId;
		companyFolder.folderId = null;
		companyFolder.creationTimestamp = Math.floor(+new Date() / 1000);
		companyFolder.name = 'Vorlagen';
		companyFolder.type = COMPANY_FILE_TYPE.FOLDER;

		return companyFolder;
	}

	getProjectFiles(projectId: string) {
		return this.afStore
			.collection(FILES, (ref) => ref.where('projectId', EQUALS, projectId))
			.valueChanges()
			.pipe(
				map((json) => {
					const files: FileDocument[] = [];
					for (const file of json) {
						const fileDoc = new FileDocument().deserialize(file);
						files.push(fileDoc);
					}
					return files;
				}),
			);
	}

	getProjectFileById(projectId: string, fileId: string): Observable<FileDocument> {
		return this.afStore
			.collection(FILES, (ref) =>
				ref.where('projectId', EQUALS, projectId).where('id', EQUALS, fileId),
			)
			.valueChanges()
			.pipe(
				map((json) => {
					const files: FileDocument[] = [];
					for (const file of json) {
						const fileDoc = new FileDocument().deserialize(file);
						files.push(fileDoc);
					}
					return files[0];
				}),
			);
	}

	storageThumbRef(projectId: string, _file, id) {
		const storage = this.storage.storage.ref();
		return storage.child(projectId + FILES + id + THUMB + '.' + JPG);
	}

	async deleteFileFromStorage(file: FileDocument, projectId: string): Promise<void> {
		const storage = this.storage.storage.ref();

		try {
			switch (file.type) {
				case FILE_TYPE.IMAGE:
				case FILE_TYPE.VIDEO:
					await this.storageThumbRef(projectId, file, file.id).delete();
					await storage
						.child(projectId + FILES + file.id + '.' + this.baseFile.getFileExtOrName(file))
						.delete();
					break;
				case FILE_TYPE.DOCUMENT:
					if (this.baseFile.getFileExtension(file.name) === 'pdf') {
						await this.storageThumbRef(projectId, file, file.id).delete();
					}
					await storage
						.child(projectId + FILES + file.id + '.' + this.baseFile.getFileExtOrName(file))
						.delete();
					break;
				case FILE_TYPE.FOLDER:
					break;
				default:
					await storage
						.child(projectId + FILES + file.id + '.' + this.baseFile.getFileExtOrName(file))
						.delete();
					break;
			}
		} catch (e) {
			// Ignoring the error while deleting the file from storage
		}
	}

	async deleteMultipleFiles(
		filesAndFolders: FileDocument[],
		projectId: string,
		files: FileDocument[],
	): Promise<void> {
		const promises = [];

		filesAndFolders.forEach((file) => {
			if (file.type === FILE_TYPE.FOLDER) {
				promises.push(this.deleteAllInsideFolder(file, projectId, files));
			}

			promises.push(this.removeFile(file, projectId));
		});

		await Promise.all(promises);
	}

	async copyFilesToProject(
		filesList: Array<FileEntity>,
		toProjectId: string,
		toFolderId: string = null,
	): Promise<void> {
		await Promise.all(
			filesList.map((file) => this.copyFileFromOneToAnotherProject(file, toProjectId, toFolderId)),
		);
	}

	async copyFileFromOneToAnotherProject(
		file: FileEntity,
		toProjectId: string,
		toFolderId: string = null,
	): Promise<void> {
		const ext = this.baseFile.getFileExtension(file.name, false);
		const fileBlob = await this.fileDownloadService.getBlob(
			`${file.projectId}/files/${file.id}.${ext}`,
		);
		await this.uploadFileToStorageFromBlob(toProjectId, file, fileBlob, toFolderId);
	}

	async uploadFileToStorageFromBlob(
		projectId: string,
		fileDocument: FileEntity,
		fileBlob: Blob,
		folderId: string = null,
	): Promise<string> {
		const arrayBuffer = await readFileAsync(fileBlob);
		const file = new File([arrayBuffer], fileDocument.name, { type: fileBlob.type });
		const id = uuid();
		const parameters: ProjectFileUploadParameters = {
			id,
			file: file,
			fileName: id,
			projectId: projectId,
			context: FileContext.PROJECT,
		};

		await this.fileUploadService.uploadFile(parameters);
		await this.afStore
			.collection(FILES)
			.doc(id)
			.set({
				...fileDocument,
				id,
				creatorId: this.authService.currentUserId(),
				name: this.baseFile.getFilenameWithLowercaseExtension(file.name),
				projectId: projectId,
				size: fileBlob.size,
				creationTimestamp: moment().unix(),
				folderId: folderId,
			});

		return id;
	}

	async uploadPdfToStorageFromBlob(
		projectId: string,
		fileDocument: FileEntity,
		fileBlob: ArrayBuffer,
		fromTemplate?: boolean,
	): Promise<string> {
		const file = new File([fileBlob], fileDocument.name, { type: 'application/pdf' });
		const id = uuid();
		const parameters: ProjectFileUploadParameters = {
			id,
			file: file,
			fileName: id,
			projectId: projectId,
			context: FileContext.PROJECT,
			fromTemplate,
		};
		await this.fileUploadService.uploadFile(parameters);

		await this.afStore
			.collection(FILES)
			.doc(id)
			.set({
				...fileDocument,
				id,
				creatorId: this.authService.currentUserId(),
				name: this.baseFile.getFilenameWithLowercaseExtension(file.name),
				projectId: projectId,
				size: fileBlob.byteLength,
				creationTimestamp: moment().unix(),
			});

		return id;
	}

	async overwritePdfByBlob(
		projectId: string,
		fileDocument: FileEntity,
		fileBlob: ArrayBuffer,
	): Promise<void> {
		const file = new File([fileBlob], fileDocument.name, { type: 'application/pdf' });
		const parameters: ProjectFileUploadParameters = {
			id: fileDocument.id,
			file: file,
			fileName: fileDocument.id,
			projectId: projectId,
			context: FileContext.PROJECT,
		};

		await this.fileUploadService.uploadFile(parameters);
	}

	async uploadFilesToChat(
		filesList: Array<FileEntity>,
		profile: Profile,
		projectId: string,
	): Promise<void> {
		await Promise.all(filesList.map((file) => this.uploadFileToChat(file, profile, projectId)));
	}

	async uploadFileToChat(file: FileEntity, profile: Profile, projectId: string): Promise<void> {
		const ext = this.baseFile.getFileExtension(file.name, false);
		const fileBlob = await this.fileDownloadService.getBlob(
			`${file.projectId}/files/${file.id}.${ext}`,
		);
		const arrayBuffer = await readFileAsync(fileBlob);
		const fileToUpload = new File([arrayBuffer], file.name, { type: fileBlob.type });
		await this.chatService.addMessageTypeFileToQueue(fileToUpload, profile, projectId, 'files');
	}

	async getFile(
		companyId: string,
		file: DownlaodFileType,
		thumbnail: boolean = true,
	): Promise<string | null> {
		const fileExt = this.baseFile.getFileExtOrName(file, true);
		const storage = this.storage.storage.ref();
		const extension = thumbnail ? JPG : fileExt;
		const content = thumbnail
			? companyId + FILES + file.id + THUMB + '.' + extension
			: companyId + FILES + file.id + '.' + fileExt;

		return storage
			.child(content)
			.getDownloadURL()
			.then((url) => {
				return url;
			})
			.catch(() => {
				return null;
			});
	}

	async copyMessageFileToFiles(
		message: MessageEntity,
		projectId: string,
		folderId?: string,
	): Promise<void> {
		const name = message.fileName ?? message.content;
		const filePath = `${projectId}/${message.content}`;
		const fileBlob = await this.fileDownloadService.getBlob(filePath);
		const fileDocument: FileEntity = {
			id: uuid(),
			projectId,
			name,
			type: this.fileUploadService.getFileType(message.content),
		};

		const uploadedFileId = await this.uploadFileToStorageFromBlob(
			projectId,
			fileDocument,
			fileBlob,
			folderId,
		);

		await this.trackingService.trackEvent(
			new FilesFileCreatedEventBuilder({
				projectId,
				mimeType: fileBlob.type,
				fileId: uploadedFileId,
				source: 'share',
			}),
		);
	}

	/**
	 * @deprecated
	 * Legacy method to support FileDocument class
	 * Should be removed once the the class is removed
	 *
	 * @param file
	 */
	updateFileOrFolder(file: FileDocument): Promise<void> {
		const entity = JSON.parse(JSON.stringify(file)) as FileEntity;
		return this.projectFilesConnector.updateProjectFilePartial(file.id, entity);
	}

	async updateFileOrFolderById(fileId: string, file: Partial<FileEntity>): Promise<void> {
		await this.projectFilesConnector.updateProjectFilePartial(fileId, file);
	}

	updateFilePermissions(fileId: string, permissions: Permissions): Promise<void> {
		return this.projectFilesConnector.updateProjectFilePartial(fileId, { permissions });
	}

	private async removeFile(file: FileDocument, projectId: string): Promise<void> {
		await this.projectFilesConnector.deleteProjectFile(file.id);
		await this.deleteFileFromStorage(file, projectId);
	}

	private async deleteAllInsideFolder(
		file: FileDocument,
		projectId: string,
		allFiles: FileDocument[],
	): Promise<void> {
		for (const f of allFiles) {
			if (file.id === f.folderId) {
				await this.removeFile(f, projectId);
			}
		}
	}

	public isFolderCurrentlyPublic(folder: FileDocument): boolean {
		return folder.share && moment.unix(folder.share.expirationTimestamp).isAfter(moment());
	}

	public generateShareLink(folderId: string, token: string): string {
		return `${environment.appUrl}publicFolders/${folderId}?token=${token}`;
	}

	public async makeFolderPublic(folder: FileDocument, token: string): Promise<void> {
		if (this.isFolderCurrentlyPublic(folder)) {
			return;
		}

		return this.http
			.patch(`${environment.baseUrl}publicFolders/${folder.id}`, { token: token })
			.pipe(take(1))
			.toPromise()
			.then();
	}

	public async makeFolderPrivate(folder: FileDocument): Promise<void> {
		return this.projectFilesConnector.updateProjectFilePartial(folder.id, { share: undefined });
	}
}
