<div class="toc mat-typography">
	<div class="mb-3">
		<span class="craftnote-label mat-body-2 dark bold invert-color">
			{{ 'toc.dialog.label' | translate }}
		</span>
	</div>
	<h1
		mat-dialog-title
		class="mat-display-1"
		[innerHTML]="'toc.dialog.title' | translate: { name: userName$ | async, deadline: deadLine$ | async }"
	></h1>
	<p class="my-4 text-muted" [innerHTML]="'toc.dialog.body' | translate: { tocLink: linkService.tocLink }"></p>
	<div mat-dialog-content [formGroup]="tocForm">
		<mat-radio-group formControlName="status" class="example-radio-group d-flex flex-column">
			<mat-radio-button value="accepted" class="mb-1" (click)="acceptedHandler()">
				{{ 'toc.dialog.acceptText' | translate }}
			</mat-radio-button>
			<mat-radio-button value="declined" class="mb-1">
				{{ 'toc.dialog.declineText' | translate }}
			</mat-radio-button>
		</mat-radio-group>
		<div class="pl-4" *ngIf="tocForm.value.status === 'declined'">
			<p class="pl-1 mb-4 mt-2">
				{{ 'toc.dialog.declineConfirmText' | translate }}
			</p>
			<ul class="toc-ul text-warn" [formGroup]="tocDeclineForm">
				<li>
					<mat-checkbox class="mat-body-2" formControlName="aaInfoDeleted">
						{{ 'toc.dialog.declinePoint-1' | translate }}
					</mat-checkbox>
				</li>
				<li>
					<mat-checkbox class="mat-body-2" formControlName="mcAccountsDeleted">
						{{ 'toc.dialog.declinePoint-2' | translate }}
					</mat-checkbox>
				</li>
				<li>
					<mat-checkbox class="mat-body-2" formControlName="mdmcDataDeleted">
						{{ 'toc.dialog.declinePoint-3' | translate }}
					</mat-checkbox>
				</li>
				<li>
					<mat-checkbox class="mat-body-2" formControlName="cannotBeUndone">
						{{ 'toc.dialog.declinePoint-4' | translate }}
					</mat-checkbox>
				</li>
				<li>
					<mat-checkbox class="mat-body-2" formControlName="discussWithOwners">
						{{ 'toc.dialog.declinePoint-5' | translate }}
					</mat-checkbox>
				</li>
			</ul>
		</div>
	</div>
	<div mat-dialog-actions class="justify-content-end">
		<button mat-flat-button color="accent" [disabled]="!isValidToc" type="submit" (click)="formSubmitHandler()">
			{{ 'toc.dialog.confirm' | translate }}
		</button>
	</div>
</div>
