<section
	*ngVar="trackedTimesListUI$ | async as trackedTimesListUI"
	class="w-100 tracked-times-list-section px-2 d-flex flex-column h-100 position-relative"
>
	<div class="tracked-times-filter-container mat-caption py-4 pb-5 d-flex">
		<span *ngVar="trackedTimesFilterBy$$ | async as trackedTimesFilterBy">
			{{ 'time-tracking-list.show' | translate }}:
			<a [matMenuTriggerFor]="trackedTimesFilterMenu" class="text-primary font-weight-bold" href="">
				<ng-container [ngSwitch]="trackedTimesFilterBy">
					<ng-container *ngSwitchCase="TrackedTimesFilterBy.ALL">
						{{ 'time-tracking-list.show-all' | translate }} ({{ allTrackedTimesLength$ | async }})
					</ng-container>
					<ng-container *ngSwitchCase="TrackedTimesFilterBy.ASSIGNED_TO_ME">
						{{ 'time-tracking-list.show-assigned-to-me' | translate }} ({{ assignedToMeTrackedTimesLength$ | async }})
					</ng-container>
				</ng-container>
			</a>
			<mat-menu #trackedTimesFilterMenu="matMenu">
				<button
					(click)="updateTrackedTimesFilterBy(TrackedTimesFilterBy.ALL)"
					[class.text-primary]="trackedTimesFilterBy === TrackedTimesFilterBy.ALL"
					mat-menu-item
				>
					{{ 'time-tracking-list.show-all' | translate }} ({{ allTrackedTimesLength$ | async }})
				</button>
				<button
					(click)="updateTrackedTimesFilterBy(TrackedTimesFilterBy.ASSIGNED_TO_ME)"
					[class.text-primary]="TrackedTimesFilterBy === TrackedTimesFilterBy.ASSIGNED_TO_ME"
					mat-menu-item
				>
					{{ 'time-tracking-list.show-assigned-to-me' | translate }} ({{ assignedToMeTrackedTimesLength$ | async }})
				</button>
			</mat-menu>
		</span>
	</div>

	<div *ngIf="trackedTimesListUI" class="flex-grow-1 overflow-auto">
		<div class="pb-5">
			<ng-container *ngFor="let trackedTimesList of trackedTimesListUI">
				<div [class.d-none]="!trackedTimesList.list.length">
					<h3 class="mat-caption mb-0 text-muted">{{ trackedTimesList.title }} ({{ trackedTimesList.list.length }})</h3>

					<ul class="list-unstyled">
						<li *ngFor="let timeTracking of trackedTimesList.list" class="border-bottom py-2">
							<div
								*ngVar="timeTracking['trackedTimeCanNotEditObservable'] | async as timeTrackingCanNotEdit"
								(click)="openTrackedTimeEdit(timeTracking, timeTrackingCanNotEdit)"
							>
								<div class="tracked-times-title d-flex align-items-center position-relative pb-1" data-cy="time-entry-details">
									<div class="d-inline-flex pr-1">
										<mat-icon *ngIf="timeTracking.type !== 'live'" [mtMatIconDisabled]="timeTrackingCanNotEdit">schedule</mat-icon>
										<mat-icon
											*ngIf="timeTracking.type === 'live'"
											[mtMatIconDisabled]="timeTrackingCanNotEdit"
											svgIcon="custom-live-time-tracking-enabled"
										></mat-icon>
									</div>

									<div *ngIf="getStartEndDuration(timeTracking) as duration" class="m-0 pointer pr-5">
										<h2 class="d-inline" data-cy="time-entry-details-duration">
											{{ duration.hours }} {{ 'time-tracking-list.duration-hrs' | translate }} {{ duration.minutes }}
											{{ 'time-tracking-list.mins' | translate }}
										</h2>
										<ng-container *ngIf="timeTracking.lastEditedBy">({{ 'time-tracking-list.edited' | translate }}) </ng-container>
									</div>

									<h2 class="pull-to-right d-flex m-0" data-cy="time-entry-details-work-type">
										{{ timeTracking.workTypeName }}
									</h2>
								</div>
								<div class="position-relative text-muted pointer d-flex justify-content-between">
									<div class="mat-caption tracked-times-assignee-name" data-cy="time-entry-details-assignee">{{ timeTracking.userName }}</div>
									<div class="mat-caption tracked-start-end-time px-3" data-cy="time-entry-details-date-time-details">
										{{ timeTracking.startTime | unixToMillis | date: 'shortDate' }},
										{{ timeTracking.startTime | unixToMillis | date: 'shortTime' }} -
										{{ timeTracking.endTime | unixToMillis | date: 'shortTime' }}
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</ng-container>

			<div *ngIf="noEntriesInView$ | async" class="empty-state absolute-center text-center">
				<img alt="" class="mb-2" src="assets/images/tt-empty-state.svg" />
				<p class="empty-state-text" data-cy="time-entry-details-blank">{{ 'time-tracking-list.no-entries' | translate }}</p>
			</div>
		</div>
	</div>
</section>
