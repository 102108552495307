import { Action, createReducer, on } from '@ngrx/store';
import * as projectStatusesActions from '../actions/company-settings.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { WorkType } from 'domain-entities';

export interface ProjectStatus {
	id: string;
	name: string;
	description?: string;
}

const workTypeEntityAdapter = createEntityAdapter<WorkType>();

const initialState: Array<ProjectStatus> = [];

export const workTypeEntityState = workTypeEntityAdapter.getInitialState();

const projectStatusesReducer = createReducer(
	initialState,
	on(projectStatusesActions.setProjectStatuses, (_state, payload) => {
		return [...payload.projectStatuses];
	}),
	on(projectStatusesActions.clearProjectStatuses, () => {
		return [];
	}),
);

const reducerWorkTypes = createReducer(
	workTypeEntityState,
	on(projectStatusesActions.updateManyWorkTypesAction, (state, { workTypes }) => {
		return workTypeEntityAdapter.upsertMany(workTypes, state);
	}),
	on(projectStatusesActions.clearWorkTypesAction, (state) => {
		return workTypeEntityAdapter.removeAll(state);
	}),
);

export const workTypeSelectors = workTypeEntityAdapter.getSelectors();

// tslint:disable-next-line:typedef
export function companySettingsReducer(state: Array<ProjectStatus>, action: Action) {
	return projectStatusesReducer(state, action);
}

// tslint:disable-next-line:typedef
export function workTypesReducer(state: EntityState<WorkType> | undefined, action: Action) {
	return reducerWorkTypes(state, action);
}
