import { createAction, props } from '@ngrx/store';
import { ProjectStatus } from '../reducers/company-settings.reducer';
import { WorkType } from 'domain-entities';

export const initCompanySettingsAction = createAction('[Company Settings] Init company Settings');

export const loadProjectStatusesByCompany = createAction(
	'[Project Statuses] Load Project Statuses',
);

export const setProjectStatuses = createAction(
	'[Project Statuses] Set Project Statuses',
	props<{ projectStatuses: Array<ProjectStatus> }>(),
);

export const clearProjectStatuses = createAction('[Project Statuses] Clear Project Statuses');

export const updateProjectStatus = createAction(
	'[Project Statuses] update Project Statuses',
	props<{ projectStatuses: Array<ProjectStatus> }>(),
);

export const loadWorkTypesAction = createAction('[Work Types] Load Work Types');

export const updateManyWorkTypesAction = createAction(
	'[Work Types] update Work Types',
	props<{ workTypes: Array<WorkType> }>(),
);

export const clearWorkTypesAction = createAction('[Work Types] clear Work Types');
