import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, of } from 'rxjs';
import { Message } from 'domain-entities';
import { MESSAGES } from '@shared/constants/firebase';
import { catchError, map, take } from 'rxjs/operators';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';

export const MESSAGES_BATCH_SIZE = 20;

@Injectable({
	providedIn: 'root',
})
export class MessageService {
	constructor(private readonly database: AngularFireDatabase) {}

	getLatestMessage(projectId: string): Observable<Message> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.limitToLast(1).orderByKey();
			})
			.valueChanges()
			.pipe(
				take(1),
				map((messages) => messages[0]),
			);
	}

	loadMessageBatchEndAtKey(
		projectId: string,
		lastMessageId: string,
		batch: number = MESSAGES_BATCH_SIZE,
	): Observable<SnapshotAction<Message>[]> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.limitToLast(batch).endAt(lastMessageId).orderByKey();
			})
			.snapshotChanges();
	}

	loadAllMessagesStartAtKey(
		projectId: string,
		messageId: string,
	): Observable<SnapshotAction<Message>[]> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.startAt(messageId).orderByKey();
			})
			.snapshotChanges();
	}

	loadLatestMessagesFromKey(
		projectId: string,
		lastMessageId: string,
	): Observable<SnapshotAction<Message>> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.startAt(lastMessageId).orderByKey();
			})
			.stateChanges();
	}

	loadMessageBatchFromKey(
		projectId: string,
		lastMessageId: string,
		batch: number = MESSAGES_BATCH_SIZE,
	): Observable<SnapshotAction<Message>[]> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.limitToFirst(batch).startAt(lastMessageId).orderByKey();
			})
			.snapshotChanges();
	}

	loadMessages(projectId: string): Observable<SnapshotAction<Message>> {
		return this.database
			.list<Message>(MESSAGES + projectId, (ref) => {
				return ref.orderByKey();
			})
			.stateChanges();
	}

	isChatMessageExists(projectId: string, messageId: string): Promise<boolean> {
		return this.loadMessageBatchFromKey(projectId, messageId, 1)
			.pipe(
				take(1),
				map((messageSnapshot) => messageSnapshot.map((message) => message.payload.val())),
				map((messages) => !(messages.length === 0 || messages[0].deleted === true)),
				catchError((_) => of(false)),
			)
			.toPromise();
	}
}
