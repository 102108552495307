import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { checkMessageType } from '@work/chat/utils/utils';
import { Message, MessageType } from 'domain-entities';
import { AuthService } from '@injectables/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ChatMessageService {
	linkRegex =
		"^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.%]+$";

	constructor(
		private authService: AuthService,
		private datePipe: DatePipe,
		private http: HttpClient,
	) {}

	getTagClass(message: Message) {
		if (this.isOwnMessage(message)) {
			return 'own-tag';
		}
		return 'other-tag';
	}

	getMessageClass(message: Message, pdfExportActive?: boolean) {
		if (
			message.deleted ||
			checkMessageType(
				message,
				MessageType.STATUS,
				MessageType.TASK_CREATED,
				MessageType.TASK_CHANGED,
				MessageType.TASK_COMPLETED,
			)
		) {
			return 'info-message mt-2';
		}

		if (this.isOwnMessage(message)) {
			if (pdfExportActive) {
				return 'own-message left-side-msg-to-pdf export-message';
			}
			return 'own-message';
		} else {
			if (pdfExportActive) {
				return 'other-message left-side-msg-to-pdf export-message';
			}
			return 'other-message';
		}
	}

	isOwnMessage(message: Message) {
		return message.authorId === this.authService.currentUserId();
	}

	getCroppedStatusMessage(text) {
		let message = text.substring(0, 80);

		if (text.length > 80) {
			message += '...';
		}

		return message;
	}

	getAuthorClass(message: Message, pdfExportActive?: boolean) {
		if (message.messageType === MessageType.STATUS) {
			return 'd-none';
		}

		if (this.isOwnMessage(message)) {
			if (pdfExportActive) {
				return 'author right-side-msg-to-pdf';
			}
			return 'author';
		} else {
			if (pdfExportActive) {
				return 'other-author left-side-msg-to-pdf';
			}
			return 'other-author';
		}
	}

	getCircleClass(message: Message) {
		if (this.isOwnMessage(message)) {
			return 'grey-circle';
		}

		return '';
	}

	getTime(message: Message) {
		try {
			const date = new Date(message.timestamp * 1000);
			return this.datePipe.transform(date, 'HH:mm');
		} catch (error) {
			return '';
		}
	}

	getInfoTranslateValues(deletedDate, author, content) {
		deletedDate *= 1000;
		const time = this.datePipe.transform(deletedDate, 'HH:mm');
		return {
			time: time,
			author: author,
			note: content,
		};
	}

	getFileType(name = ''): string {
		const types = name.split('.');
		return types[types.length - 1].toLowerCase();
	}

	getTranslateValues(deletedDate) {
		deletedDate *= 1000;
		const date = this.datePipe.transform(deletedDate, 'dd.MM.yyyy');
		const time = this.datePipe.transform(deletedDate, 'HH:mm');
		return {
			date: date,
			time: time,
		};
	}

	setMessageStatus(
		projectId: string,
		messageIds: string[],
		status: Message['status'],
	): Observable<Object> {
		return this.http.post(`${environment.baseUrl}setMessageStatus`, {
			data: { projectId, messageIds, status },
		});
	}
}
