<div class="d-flex h-100 w-100">
	<ng-container *ngIf="!archive" [ngTemplateOutlet]="active"></ng-container>
	<ng-container *ngIf="archive" [ngTemplateOutlet]="archived"></ng-container>
</div>

<ng-template #active>
	<div class="projects projects-sm position-relative flex-shrink-0 overflow-hidden">
		<app-projects-area></app-projects-area>
	</div>
	<div class="flex-grow-1 d-flex flex-column chat-container">
		<section>
			<app-chat-header (activeButton)="handleMenuButton($event)"></app-chat-header>
		</section>
		<div class="d-flex flex-grow-1 overflow-hidden">
			<div class="app-chat-container d-flex flex-grow-1">
				<app-chat class="app-chat flex-grow-1"></app-chat>
			</div>
			<ng-container [ngTemplateOutlet]="rightSideMenu"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #archived>
	<app-archive (menuButtonRightSide)="handleMenuButton($event)" class="w-100">
		<ng-container [ngTemplateOutlet]="rightSideMenu"></ng-container>
	</app-archive>
</ng-template>

<ng-template #rightSideMenu>
	<div class="menu-container position-relative flex-shrink-0" [ngClass]="{ active: rightSideMenuActive$ | async }">
		<router-outlet></router-outlet>
	</div>
</ng-template>
