import { Currency } from 'domain-entities';
import * as moment from 'moment';

export interface VatRate {
	start: moment.Moment;
	end: moment.Moment;
	value: number;
}

export const GERMAN_VAT_RATES: VatRate[] = [
	{
		start: undefined,
		end: moment.unix(1593554400), // 2020-07-01
		value: 19,
	},
	{
		start: moment.unix(1593554400), // 2020-07-01
		end: moment.unix(1609455600), // 2021-01-01
		value: 16,
	},
	{
		start: moment.unix(1609455600), // 2021-01-01
		end: undefined,
		value: 19,
	},
];

export const DEFAULT_CURRENCY = Currency.EUR;
