import { Injectable } from '@angular/core';
import {
	CompanyEmployeeDialogComponent,
	CompanyMemberDialogData,
} from '@modules/shared/dialog/components/company-employee-dialog/company-employee-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { first } from 'rxjs/operators';
import { Member } from 'domain-entities';
import { CompanyMemberOperation } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.component';
import { AddCompanyEmployeeDialogComponent } from '@modules/shared/dialog/components/add-company-employee-dialog/add-company-employee-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class CompanyEmployeeService {
	constructor(
		private readonly dialog: MatDialog,
		private readonly store: Store<AppState>,
		private readonly remoteConfig: RemoteConfig,
	) {}

	get isNewInvitationsEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_company_invitation');
	}

	async openAddEmployeeDialog(context: 'company-members' | 'project-members'): Promise<void> {
		if (this.isNewInvitationsEnabled) {
			this.dialog.open<CompanyEmployeeDialogComponent, CompanyMemberDialogData>(
				CompanyEmployeeDialogComponent,
				{
					data: {
						operation: CompanyMemberOperation.ADD,
						context,
					},
					width: 'min(100%, 1020px)',
					disableClose: true,
					restoreFocus: false,
				},
			);
			return;
		}

		const companyId = await this.store.select(selectCompanyId).pipe(first()).toPromise();
		this.dialog.open(AddCompanyEmployeeDialogComponent, {
			data: {
				company: companyId,
			},
			width: 'min(100%, 1020px)',
			disableClose: true,
		});
	}

	openEditEmployee(
		member: Member,
		context: 'company-members' | 'project-members',
		companyId?: string,
	): void {
		if (this.isNewInvitationsEnabled) {
			this.dialog.open<CompanyEmployeeDialogComponent, CompanyMemberDialogData>(
				CompanyEmployeeDialogComponent,
				{
					data: {
						operation: CompanyMemberOperation.EDIT,
						member: member,
						context,
					},
					width: 'min(100%, 1020px)',
					disableClose: true,
				},
			);
			return;
		}

		this.dialog.open(AddCompanyEmployeeDialogComponent, {
			data: {
				company: companyId,
				member: member.email,
				memberId: member.id,
			},
			width: 'min(100%, 1020px)',
			disableClose: true,
		});
	}
}
