import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-rename-dialog',
	templateUrl: './rename-dialog.component.html',
	styleUrls: ['./rename-dialog.component.scss'],
})
export class RenameDialogComponent {
	name: string;

	constructor(
		public dialogRef: MatDialogRef<RenameDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.name = this.data.name;
	}
}
