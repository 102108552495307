import { Inject, Injectable } from '@angular/core';
import { selectIsLoggedIn, selectProfile } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';
import { firstValueFrom, Subject } from 'rxjs';
import { WINDOW } from '@craftnote/shared-utils';

declare const Calendly: any;

export type CalendlyEvent =
	| 'calendly.profile_page_viewed'
	| 'calendly.event_type_viewed'
	| 'calendly.date_and_time_selected'
	| 'calendly.event_scheduled';

export enum CalendlyLinks {
	NO_REMAINING_PROJECTS = 'https://calendly.com/craftnote/online-vorstellung?utm_source=outbound&utm_medium=call&utm_campaign=Niclas&month=2023-03',
}

@Injectable({
	providedIn: 'root',
})
export class CalendlyService {
	calendlyEvent$: Subject<CalendlyEvent> = new Subject();

	calendlyEventHandlerBound = this.useCalendlyEventHandler.bind(this);

	constructor(
		private readonly store: Store<AppState>,
		private readonly memberFullNamePipe: MemberFullNamePipe,
		@Inject(WINDOW) private readonly windowRef: Window,
	) {
		this.init();
	}

	private init(): void {
		this.store.select(selectIsLoggedIn).subscribe((isLoggedIn) => {
			if (isLoggedIn) {
				this.windowRef.addEventListener('message', this.calendlyEventHandlerBound);
			} else {
				this.windowRef.removeEventListener('message', this.calendlyEventHandlerBound);
			}
		});
	}

	private isCalendlyEvent(event): boolean {
		return (
			event.origin === 'https://calendly.com' &&
			event.data.event &&
			event.data.event.indexOf('calendly.') === 0
		);
	}

	private useCalendlyEventHandler(event): void {
		if (this.isCalendlyEvent(event)) {
			this.calendlyEvent$.next(event.data.event);
		}
	}

	async openCalendly(link: string): Promise<void> {
		const user = await firstValueFrom(this.store.select(selectProfile));

		Calendly.showPopupWidget(link, 'PopupText', {
			prefill: {
				email: user.email,
				name: this.memberFullNamePipe.transform(user),
			},
		});
	}
}
