// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

import { Environment } from '@shared/tokens/environment';
import { environmentTemplate } from './develop/environment-template.develop';

export const environment: Environment = {
	...environmentTemplate,
	pspdfkit: {
		license_key:
			'jOuYN8xMQcl58Svd_i82dmUEpBammZx53q9GcAnPE9VNubbCG-Waz-xWHDG_74GI7rOD0nQE8aFLMf5vctb1ZVNf6iueDwKis-cnnu_Lj4jjvNkOQqgs0GDMZ-1zI6OQp8rDPZPcJN5_9lYRdsl3jyRPWMDGFyIbmyOEEteAe7TjVn3_GI_SfJeBMYz6_7hZcnsBjDVLjDT749modRh4R28LTqMpMJgCuFyXOM680ycN4F2R5pfoG0kdppWP0B1BJ6OJ94kW1VGTe-qvwKxfma4u87nbv1LQwiG1BWPdojAlGS9eljSkoCWBEIRVsh6Edsft3bPfkjwJg1RqJpzsFLGzgAtem4yv-Ok6G1Fz-nmPJba4uPerBTBWbGho3AHS9Bs0BoItMllSKL5ElXut6-3o0DCT5v7fWzWYPeWYYNGppY_8D0E1LwFQ_gMx8d-5IEBjskxAvJMC442U4AiT_-mJqntd_caVhvPs-Zt46sRST0HV37I2UQbJRpBB8VPB3xdrrlTZ5-Fg_MQX06KGfmtZy2ob_2x_lOxMnyRZxZ2kTyIbqq9yizDBJGvtQ8fVVBif_K27p9gk7dnoCWGCFK4vwNNznXxUD3fF8EvA5-8=',
	},
};
