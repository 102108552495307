<div class="user-deactivated-dialog mat-typography">
	<h1 mat-dialog-title class="mat-display-1" [innerHTML]="'toc.dialog.userDeactivated.title' | translate"></h1>
	<div mat-dialog-content class="text-muted">
		<p class="mb-3" [innerHTML]="bodyOneText$ | async"></p>
		<p [innerHTML]="bodyTwoText$ | async"></p>
	</div>
	<div mat-dialog-actions class="justify-content-end">
		<button mat-flat-button color="accent" (click)="clickHandler()">
			{{ 'toc.dialog.userDeactivated.okBtn' | translate }}
		</button>
	</div>
</div>
