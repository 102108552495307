import { Serializable } from '@craftnote/shared-utils';
import { Member } from 'domain-entities';

export class Contact implements Serializable<Contact> {
	constructor(
		public id?: string,
		public name?: string,
		public lastname?: string,
		public email?: string,
		public mobile?: string,
		public jobTitle?: string,
		public role?: string,
		public inviteState?: string,
		public company?: string,
		public membersFunction?: string,
		public note?: string,
	) {}

	deserialize(input: any): Contact {
		this.id = input.id;
		this.name = input.name;
		this.lastname = input.lastname;
		this.email = input.email;
		this.mobile = input.mobile;
		this.jobTitle = input.jobTitle;
		this.role = input.role;
		this.inviteState = input.inviteState;
		this.company = input.company;
		this.membersFunction = input.function;
		this.note = input.note;
		return this;
	}

	toDomainEntityMember(): Member {
		const pureObject = Object.assign({}, this) as Member;
		Object.keys(pureObject).forEach((key) =>
			pureObject[key] === undefined ? delete pureObject[key] : {},
		);
		if (pureObject['membersFunction']) {
			pureObject['function'] = this.membersFunction;
			delete pureObject['membersFunction'];
		}
		return pureObject;
	}
}
