import { NgModule } from '@angular/core';
import { PromotionalContentComponent } from './promotional-content.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [PromotionalContentComponent],
	imports: [CommonModule],
	exports: [PromotionalContentComponent],
})
export class PromotionalContentModule {}
