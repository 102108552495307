import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { selectUserRegistrationType } from '@store/selectors/app.selectors';
import { Location } from '@angular/common';
import { setAppInitState } from '@store/actions/app-init.actions';
import { AppInitStatus } from '@store/state/app-init.state';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRegistrationType } from '@store/state/auth.state';
import { AppInitStepperService } from '@modules/shared/components/app-init/services/app-init-stepper.service';
import { AppInitService } from '../../../../app-init.service';
import { EXAMPLE_PROJECT_NAMES } from '@injectables/services/project/project.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserDeactivatedDialogComponent } from '@modules/shared/dialog/components/user-deactivated-dialog/user-deactivated-dialog.component';
import { NotificationTokenService } from '@injectables/services/notification-token/notification-token.service';
import {
	BrowserNotificationService,
	NotificationPermission,
} from '@injectables/services/browser-notification.service';
import { BrowserService } from '@injectables/services/browser.service';
import { ThemeService } from '@injectables/services/theme.service';

enum AppInitStep {
	LOAD_PROFILE_AND_COMPANY = 'load_profile_and_company',
	ACCEPT_COMPANY_INVITATION = 'accept_companyInvitation',
	COMPANY_INITIALIZATION = 'company_initialization',
	LOAD_PROJECTS = 'load_projects',
	LOAD_EXAMPLE_PROJECT = 'load_example_project',
	ENABLE_PUSH_NOTIFICATION_SETTINGS = 'enable_push_notification_settings',
	REQUEST_FOR_NOTIFICATION_TOKEN = 'request_for_notification_token',
}

@Component({
	selector: 'app-app-init',
	templateUrl: './app-init.component.html',
	styleUrls: ['./app-init.component.scss'],
})
export class AppInitComponent implements OnInit {
	currentStep: AppInitStep = AppInitStep.LOAD_PROFILE_AND_COMPANY;
	AppInitStep = AppInitStep;
	isCompanyInitStepCompleted$ = new BehaviorSubject(false);
	isPushNotificationsCompleted$ = new BehaviorSubject(false);
	isNotificationTokenCompleted$ = new BehaviorSubject(false);

	constructor(
		private readonly store: Store<AppState>,
		private readonly projectHelperService: ProjectHelperService,
		private readonly routerState: ActivatedRoute,
		private readonly router: Router,
		private readonly location: Location,
		private readonly appInitStepperService: AppInitStepperService,
		private readonly dialog: MatDialog,
		private readonly appInitService: AppInitService,
		private readonly notificationTokenService: NotificationTokenService,
		private readonly browserNotificationService: BrowserNotificationService,
		private readonly browserService: BrowserService,
		private readonly themeService: ThemeService,
	) {}

	async ngOnInit(): Promise<void> {
		void this.initLoading();
		this.themeService.initTheme();
	}

	onCompanyInitEvent(isVisited: boolean): void {
		this.isCompanyInitStepCompleted$.next(isVisited);
	}

	onSettingsCompleted(): void {
		this.isPushNotificationsCompleted$.next(true);
	}

	onNotificationTokenCompleted(): void {
		this.isNotificationTokenCompleted$.next(true);
	}

	private async initLoading(): Promise<void> {
		const isMobile = await this.appInitService.getMobilePlatform().pipe(take(1)).toPromise();

		/**
		 * Step 1: Wait for profile and company is loaded
		 */
		await this.appInitStepperService.isProfileAndCompanyLoaded();
		/**
		 * Depends on user registration type the steps are different
		 */

		const registerType = await this.getUserRegistrationType();
		if (registerType === UserRegistrationType.EXISTING) {
			/**
			 * Step 2: Should show the userDeactivated popup when company declined the toc
			 */
			const isCompanyTocDeclined = await this.isCompanyTocDeclined();
			if (isCompanyTocDeclined) {
				return;
			}

			/**
			 * Step 3 : Wait for the projects to be loaded
			 */
			this.currentStep = AppInitStep.LOAD_PROJECTS;
			await this.appInitStepperService.isProjectsLoaded();
		} else if (registerType === UserRegistrationType.FRESH) {
			// Step 2: Accept the invitation in silence when invitation's deeplink is used
			this.currentStep = AppInitStep.ACCEPT_COMPANY_INVITATION;
			const newCompanyId = await this.appInitStepperService.getCompanyIdOfAcceptedInvitation();

			/**
			 * Step 3: Should show the userDeactivated popup when company declined the toc
			 */
			const isCompanyTocDeclined = await this.isCompanyTocDeclined();
			if (isCompanyTocDeclined) {
				return;
			}
			/**
			 * STEP 4:
			 * If newCompanyId is available the user joined in the new company by using invitationLink, if it undefined when
			 * the user is FRESH registered and when Accepting invitation has failed scenarios
			 */

			if (!isMobile) {
				await this.continueToCompanyInitializationStep(newCompanyId);
			}

			// step 4 : Wait for the example project to be loaded
			this.currentStep = AppInitStep.LOAD_EXAMPLE_PROJECT;
			await this.appInitStepperService.isExampleProjectLoaded();
		}

		/**
		 * Step to check the browser and enabling the notifications, in case of firefox
		 */
		if (!isMobile) {
			await this.continueToPushNotificationsSettings();
		}

		/**
		 * Step to ask for notification token if permission API is supported
		 */
		if (this.browserService.isPermissionAPISupported() && !isMobile) {
			await this.continueToNotificationTokenPermission();
		}

		await this.redirect();
		this.store.dispatch(setAppInitState({ state: AppInitStatus.DONE }));
	}

	private async continueToNotificationTokenPermission(): Promise<void> {
		const isTimestampExpiredOrNotExists =
			await this.notificationTokenService.isTimestampExpiredOrNotExists();
		const isNotificationPermissionNotGranted =
			!this.browserNotificationService.isNotificationPermission(NotificationPermission.GRANTED);

		if (isTimestampExpiredOrNotExists && isNotificationPermissionNotGranted) {
			this.currentStep = AppInitStep.REQUEST_FOR_NOTIFICATION_TOKEN;
			await this.isNotificationTokenCompleted$.pipe(filter(Boolean), take(1)).toPromise();
		}
	}

	private async continueToPushNotificationsSettings(): Promise<void> {
		const isEnabled = await this.appInitStepperService.isPushNotificationsScreenEnabled();
		if (isEnabled) {
			this.currentStep = AppInitStep.ENABLE_PUSH_NOTIFICATION_SETTINGS;
			await this.isPushNotificationsCompleted$.pipe(filter(Boolean), take(1)).toPromise();
		}
	}

	private async continueToCompanyInitializationStep(
		newCompanyId: string | undefined,
	): Promise<void> {
		if (await this.appInitStepperService.isCompanyInitShouldEnabled(newCompanyId)) {
			this.currentStep = AppInitStep.COMPANY_INITIALIZATION;
			await this.isCompanyInitStepCompleted$.pipe(filter(Boolean), take(1)).toPromise();
		}
	}

	private async isCompanyTocDeclined(): Promise<boolean> {
		const isCompanyDeclined = await this.appInitStepperService.isTocDeclinedByCompany();
		if (isCompanyDeclined) {
			await this.dialog
				.open(UserDeactivatedDialogComponent, {
					autoFocus: false,
					disableClose: true,
					width: 'min(100%, 600px)',
				})
				.afterClosed()
				.toPromise();
		}
		return isCompanyDeclined;
	}

	private async getUserRegistrationType(): Promise<UserRegistrationType> {
		return this.store
			.select(selectUserRegistrationType)
			.pipe(
				filter((type) =>
					[UserRegistrationType.FRESH, UserRegistrationType.EXISTING].includes(type),
				),
				take(1),
			)
			.toPromise();
	}

	private queryParamsContainsUtmSource(): boolean {
		return !!this.routerState.snapshot.queryParamMap.get('utm_source');
	}

	private async redirect(): Promise<void> {
		const locationPath = this.location.path(); // This location path is used to land on the same page when user refreshes the application
		const isFreshUser = (await this.getUserRegistrationType()) === UserRegistrationType.FRESH;
		const redirectUrl =
			(this.routerState?.snapshot?.queryParams['redirectUrl'] as string) || locationPath;
		const blacklistedUrls = ['/register'];

		const whitelistedUrlsForFreshUsers = ['/checkout'];
		const isRedirectUrlWhitelistedFoFreshUser = whitelistedUrlsForFreshUsers.some((whitelisted) =>
			redirectUrl.startsWith(whitelisted),
		);

		if (
			redirectUrl &&
			(!isFreshUser || isRedirectUrlWhitelistedFoFreshUser) &&
			!blacklistedUrls.includes(redirectUrl) &&
			!this.queryParamsContainsUtmSource()
		) {
			this.router.navigateByUrl(redirectUrl);
			return;
		}
		this.projectHelperService.goToHome(isFreshUser ? EXAMPLE_PROJECT_NAMES[0] : null);
	}
}
