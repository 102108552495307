import { Pipe, PipeTransform } from '@angular/core';
import anchorme from 'anchorme';

@Pipe({
	name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
	transform(str: string): string {
		return str
			? anchorme({
					input: str,
					options: {
						attributes: {
							target: '_blank',
							class: 'link-url',
						},
					},
			  })
			: str;
	}
}
