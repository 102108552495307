import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { RoleNamePipe } from './role-name.pipe';

@NgModule({
	declarations: [RoleNamePipe],
	providers: [RoleNamePipe],
	imports: [SharedModule],
	exports: [RoleNamePipe],
})
export class RoleNamePipeModule {}
