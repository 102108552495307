import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { noop } from 'lodash';

interface MultiSelectPanelOption {
	value: any;
	displayValue: string;
	hintValue?: string;
	secondHintValue?: string;
}

export interface MultiSelectPanelConfig {
	options: MultiSelectPanelOption[];
}

@Component({
	selector: 'app-multi-select-panel',
	templateUrl: './multi-select-panel.component.html',
	styleUrls: ['./multi-select-panel.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiSelectPanelComponent),
			multi: true,
		},
	],
})
export class MultiSelectPanelComponent implements AfterViewInit, ControlValueAccessor {
	@Input() config: MultiSelectPanelConfig = {
		options: [],
	};

	@ViewChild('list') list: MatSelectionList;
	@ViewChild('search') searchInput: ElementRef;

	searchInputValue = '';

	private viewReady$$ = new BehaviorSubject<boolean>(false);

	get options(): MultiSelectPanelOption[] {
		return this.config.options;
	}

	ngAfterViewInit(): void {
		this.viewReady$$.next(true);
		setTimeout(() => this.searchInput.nativeElement.focus());
	}

	reset(): void {
		this.list?.deselectAll();
	}

	async registerOnChange(fn: any): Promise<void> {
		await this.viewReady$$.pipe(filter(Boolean), take(1)).toPromise();
		this.list.registerOnChange(fn);
	}

	async writeValue(values: string[]): Promise<void> {
		await this.viewReady$$.pipe(filter(Boolean), take(1)).toPromise();

		if (!values) {
			return;
		}
		this.list.writeValue(values);
	}

	showItem(option: MultiSelectPanelOption): boolean {
		const displayValueSearch = option.displayValue
			.toLowerCase()
			.trim()
			.includes(this.searchInputValue.toLowerCase().trim());

		let hintValueSearch;
		if (option?.hintValue) {
			hintValueSearch = option.hintValue
				.toLowerCase()
				.trim()
				.includes(this.searchInputValue.toLowerCase().trim());
		}
		let secondHintValueSearch;
		if (option?.secondHintValue) {
			secondHintValueSearch = option.secondHintValue
				.toLowerCase()
				.trim()
				.includes(this.searchInputValue.toLowerCase().trim());
		}

		return !!displayValueSearch || !!hintValueSearch || !!secondHintValueSearch;
	}

	// Required for Interface, not required for functionality
	registerOnTouched(): void {
		noop();
	}
}
