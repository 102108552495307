<div class="d-flex py-3 justify-content-start align-items-center">
	<mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
	<h2 class="font-weight-bold m-0 ml-3">{{ 'project.actions.move.title' | translate }}</h2>
</div>
<mat-dialog-content>
	<div>
		<mat-form-field class="w-100">
			<input
				#search
				(keyup)="searchProjects(search.value)"
				matInput
				placeholder="{{ 'project.search.main' | translate }}"
				data-cy="search-move-dlg"
			/>
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div (click)="moveProjectApproval()" *ngIf="isProjectNotInRoot" class="p-2 pointer">
		<h1>
			<span>{{ 'project.actions.move.root' | translate }}</span>
			<span class="ml-2" data-cy="move-to-root">
				<img *ngIf="theme === 1" height="16px" src="assets/images/icons/move_black.png" alt="move_back" />
				<img *ngIf="theme === 0" height="16px" src="assets/images/icons/move.png" alt="move" />
			</span>
		</h1>
	</div>
	<div [class.with-root]="isProjectNotInRoot" class="project-list__container">
		<div (click)="moveProjectApproval(project)" *ngFor="let project of filteredProjects" class="project-content">
			<div class="d-flex justify-content-start p-2">
				<app-project-indicator [project]="project" class="d-flex"></app-project-indicator>
				<div class="text-truncate ml-3">
					<h1 class="font-weight-bold" data-cy="found-folder-to-move">{{ project?.name }}</h1>
					<div class="project-street-text text-muted">
						<span>{{ project.street ? project.street + ', ' : '' }}</span>
						<span>{{ project.city ? project.city : '' }}</span>
						<span *ngIf="!project.street && !project.city">-</span>
					</div>
				</div>
			</div>
			<hr />
		</div>
	</div>
</mat-dialog-content>
