import { CollectionReference } from '@angular/fire/compat/firestore/interfaces';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { Query } from '@angular/fire/compat/firestore';
import { query, where } from '@angular/fire/firestore';
import {
	CollectionReference as CollectionReferenceModular,
	Query as QueryModular,
} from '@firebase/firestore';

export const buildFirestoreQueryWithRef = <T>(
	ref: CollectionReference,
	conditions: DataQueryCondition<T>[],
	limit?: number,
) => {
	let collectionQuery: CollectionReference | Query = ref;

	for (const condition of conditions) {
		if (condition?.value) {
			collectionQuery = collectionQuery.where(condition.field, condition.operator, condition.value);
		}
		if (limit) {
			collectionQuery = collectionQuery.limit(limit);
		}
	}

	return collectionQuery;
};

export function buildModularFirestoreQuery<T>(
	ref: CollectionReferenceModular<T>,
	conditions: DataQueryCondition<T>[],
): QueryModular<T> {
	const conds = conditions.map((condition) =>
		where(condition.field, condition.operator, condition.value),
	);
	return query<T>(ref, ...conds);
}
