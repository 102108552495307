import { Injectable } from '@angular/core';
import { CompanySetting, WorkType } from 'domain-entities';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { COLLECTION_COMPANY_SETTINGS } from '@shared/constants/firebase';
import { Observable } from 'rxjs';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { map } from 'rxjs/operators';
import { shareReplayOne } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class ProjectStatusService {
	constructor(private readonly afStore: AngularFirestore) {}

	private getCompanySettings(companyId: string): Observable<CompanySetting> {
		return this.afStore
			.collection<CompanySetting>(COLLECTION_COMPANY_SETTINGS)
			.doc<CompanySetting>(companyId)
			.valueChanges()
			.pipe(shareReplayOne());
	}

	getProjectStatus(companyId: string): Observable<Array<ProjectStatus>> {
		return this.getCompanySettings(companyId).pipe(
			map((companySetting) => {
				return companySetting && companySetting.status ? companySetting.status : [];
			}),
		);
	}

	setProjectStatus(companyId: string, companySetting: Array<ProjectStatus> = []): Promise<void> {
		return this.afStore
			.collection<CompanySetting>(COLLECTION_COMPANY_SETTINGS)
			.doc<CompanySetting>(companyId)
			.update({ companyId, status: companySetting });
	}

	getWorkTypes(companyId: string): Observable<Array<WorkType>> {
		return this.getCompanySettings(companyId).pipe(
			map((companySetting) => {
				return companySetting && companySetting.workTypes ? companySetting.workTypes : [];
			}),
		);
	}

	setWorkTypes(companyId: string, workTypes: Array<WorkType> = []): Promise<void> {
		return this.afStore
			.collection<CompanySetting>(COLLECTION_COMPANY_SETTINGS)
			.doc<CompanySetting>(companyId)
			.update({ companyId, workTypes: workTypes });
	}
}
