import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

const DEFAULT_LOCALE = 'de-DE';
const GERMAN_LOCALE_REGEX = '^de-?';

enum LocalPrefix {
	DE = 'de',
	EN = 'en',
}

@Injectable({
	providedIn: 'root',
})
export class GeoLocationService {
	currentLocation: any;

	constructor(
		private readonly http: HttpClient,
		private readonly errorHandler: ErrorHandlerService,
	) {}

	async getLocationFromAddress(address: string): Promise<Array<any> | null> {
		const options = {
			params: {
				q: address,
				format: 'json',
				limit: '1',
			},
		};

		try {
			return await this.http
				.get<any>('https://nominatim.openstreetmap.org/search', options)
				.toPromise();
		} catch (error) {
			this.errorHandler.handleError(error);
			return null;
		}
	}

	get locale(): string {
		const locale = navigator.language;
		if (!locale) {
			return DEFAULT_LOCALE;
		}
		if (isValidLocale(locale)) {
			return locale;
		}
		return 'en-US';
	}

	getLocalePrefix(): LocalPrefix {
		if (this.locale.startsWith('de')) {
			return LocalPrefix.DE;
		} else {
			return LocalPrefix.EN;
		}
	}

	getTimezone(): string {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}
}

const isValidLocale: (locale: string) => boolean = (locale: string) => {
	return !!locale.match(GERMAN_LOCALE_REGEX);
};
