import { Export } from 'domain-entities';

const failedStates: Export['state'][] = ['errored', 'failed'];
const runningStates: Export['state'][] = ['requested', 'in-progress'];
const nonCancelableStates: Export['state'][] = ['complete', 'deleted'];

export function isRunning(exportEntity: Export): boolean {
	return runningStates.includes(exportEntity.state);
}

export function isFailed(exportEntity: Export): boolean {
	return failedStates.includes(exportEntity.state);
}

export function isCancelable(exportEntity: Export): boolean {
	return !nonCancelableStates.includes(exportEntity.state);
}
