import { NgModule } from '@angular/core';
import { AddProjectMemberComponent } from './add-project-member.component';
import { EditMemberFieldDialogComponent } from './edit-member-field-dialog.component';
import { ProjectMemberDialogComponent } from './project-member-dialog/project-member-dialog.component';
import { ProjectMemberInfoComponent } from './project-members-details.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectMemberListComponent } from './components/project-member-list/project-member-list.component';
import { ProjectMemberListItemComponent } from './components/project-member-list-item/project-member-list-item.component';
import { ProjectMemberListHeaderComponent } from './components/project-member-list-header/project-member-list-header.component';
import { ButtonLoaderModule, NamedIconModule } from '@craftnote/material-theme';
import { ProjectMemberDrawerComponent } from './components/project-member-drawer/project-member-drawer.component';
import { ProjectInternalMemberComponent } from './components/project-internal-member/project-internal-member.component';
import { ProjectInternalInviteMemberComponent } from './components/project-internal-invite-member/project-internal-invite-member.component';
import { ProjectExternalMemberComponent } from './components/project-external-member/project-external-member.component';
import { LoaderModule } from '@modules/shared/components/loader/loader.module';
import { ProjectMemberDetailsComponent } from './components/project-member-details/project-member-details.component';
import { ProjectEditDetailDialogComponent } from './components/project-edit-detail-dialog/project-edit-detail-dialog.component';
import { AutosizeModule } from 'ngx-autosize';
import { DarkOverlayModule } from '@modules/shared/components/dark-overlay/dark-overlay.module';
import { EmptyStateModule } from '@modules/shared/components/empty-state/empty-state.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { IsNilPipeModule } from '@modules/shared/pipes/is-nil/is-nil-pipe.module';
import { MemberFullNamePipeModule } from '@modules/shared/pipes/member-full-name/member-full-name-pipe.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
	declarations: [
		ProjectMemberDialogComponent,
		ProjectMemberInfoComponent,
		AddProjectMemberComponent,
		EditMemberFieldDialogComponent,
		ProjectMemberListComponent,
		ProjectMemberListItemComponent,
		ProjectMemberListHeaderComponent,
		ProjectMemberDrawerComponent,
		ProjectInternalMemberComponent,
		ProjectInternalInviteMemberComponent,
		ProjectExternalMemberComponent,
		ProjectMemberDetailsComponent,
		ProjectEditDetailDialogComponent,
	],
	imports: [
		MatToolbarModule,
		NamedIconModule,
		ButtonLoaderModule,
		LoaderModule,
		AutosizeModule,
		DarkOverlayModule,
		EmptyStateModule,
		MatButtonModule,
		MatIconModule,
		SharedModule,
		MatDialogModule,
		MatMenuModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FormsModule,
		MatInputModule,
		NgxTrimDirectiveModule,
		IsNilPipeModule,
		MemberFullNamePipeModule,
		MatListModule,
		MatCheckboxModule,
	],
})
export class ProjectMembersModule {}
