import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { OrderByType, Project, SortByType } from 'domain-entities';
import { ProjectActionDialogComponent } from '../project-action-dialog/project-action-dialog.component';
import { ProjectService } from '@injectables/services/project/project.service';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectType } from '@shared/models/project.type';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { ProfileSettingService } from '@injectables/services/profile-setting.service';
import { isUserOwnerOrSupervisorOfProject } from '@shared/functions/project/project.functions';
import { Store } from '@ngrx/store';
import { selectUserId } from '@store/selectors/app.selectors';
import {
	selectAllActiveProjects,
	selectAllArchivedProjects,
} from '@store/selectors/projects.selectors';
import { ThemeService } from '@injectables/services/theme.service';
import { ProjectSortAndSearchHelper } from '@injectables/services/project-sort-and-search-helper.service';

@Component({
	selector: 'app-project-move-dialog',
	templateUrl: './project-move-dialog.component.html',
	styleUrls: ['./project-move-dialog.component.scss'],
})
export class ProjectMoveDialogComponent implements OnInit, OnDestroy {
	theme: number;

	project: Project;
	destroyer$ = new Subject();
	projects$ = new BehaviorSubject<Project[]>([]);
	searchKey$ = new BehaviorSubject<string>(null);
	filteredProjects: Project[] = [];

	isProjectNotInRoot = false;

	themeSubscription: Subscription;

	constructor(
		private dialogRef: MatDialogRef<ProjectMoveDialogComponent>,
		private approvalDialog: MatDialog,
		private projectService: ProjectService,
		private authService: AuthService,
		private themeService: ThemeService,
		private projectSortAndSearchHelper: ProjectSortAndSearchHelper,
		private profileSettingService: ProfileSettingService,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {}

	ngOnInit(): void {
		this.project = this.data.project;
		this.isProjectNotInRoot = !!this.project.parentProject;
		this.loadProjects();
		this.initTheme();
	}

	async initTheme(): Promise<void> {
		this.theme = await this.themeService.getTheme();

		this.themeSubscription = this.themeService
			.getThemeAsync()
			.pipe(takeUntil(this.destroyer$))
			.subscribe((theme) => {
				this.theme = theme;
			});
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	async loadProjects(): Promise<void> {
		const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const projectSelector = this.project.membersArchived.includes(userId)
			? selectAllArchivedProjects
			: selectAllActiveProjects;
		this.store
			.select(projectSelector)
			.pipe(
				take(1),
				map((projects) =>
					projects.filter(
						(project) =>
							project.projectType === ProjectType.FOLDER &&
							project.id !== this.project.parentProject &&
							isUserOwnerOrSupervisorOfProject(this.authService.currentUserId(), project, 'id'),
					),
				),
			)
			.subscribe((projects) => this.projects$.next(projects));

		combineLatest([
			this.projects$,
			this.searchKey$.pipe(debounceTime(200), distinctUntilChanged()),
			this.profileSettingService.getProfileSettings(),
		])
			.pipe(takeUntil(this.destroyer$))
			.subscribe(([projects, searchKey, profileSettings]) => {
				const sortByType =
					(profileSettings && profileSettings.sortOptions.sortBy) || SortByType.LAST_EDITED;
				const sortByDirection =
					(profileSettings && profileSettings.sortOptions.orderBy) || OrderByType.DESC;

				const sortedProjects = this.projectSortAndSearchHelper.sort(
					projects,
					sortByType,
					sortByDirection,
				);
				this.filteredProjects = this.projectSortAndSearchHelper.searchProjectFolders(
					sortedProjects,
					searchKey,
				);
			});
	}

	moveProjectApproval(folder?: Project): void {
		const dialogRef = this.approvalDialog.open(ProjectActionDialogComponent, {
			data: {
				title: 'project.actions.move.dialog.title',
				content: 'project.actions.move.dialog.approval',
				name: this.project.name,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.moveProject(folder);
			}
		});
	}

	moveProject(toFolder?: Project): void {
		this.projectService.moveProject(this.project, toFolder, 'project.moveSuccess');
		this.closeDialog();
	}

	ngOnDestroy(): void {
		if (this.themeSubscription) {
			this.themeSubscription.unsubscribe();
		}

		this.destroyer$.next(null);
		this.destroyer$.complete();
	}

	searchProjects(searchKey: string): void {
		this.searchKey$.next(searchKey);
	}
}
