import { NgModule } from '@angular/core';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';

const PIPE = [MemberFullNamePipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class MemberFullNamePipeModule {}
