<div class="row p-3 justify-content-between">
	<div class="col-auto">
		<em class="material-icons pointer" (click)="closeDialog()">close</em>
	</div>
	<h2 class="col-8 text-center">{{ 'project.prefill.dialog.title' | translate }}</h2>
	<div class="col-auto">
		<a (click)="skip()" style="cursor: pointer">{{ 'project.prefill.dialog.skip' | translate }}</a>
	</div>
</div>

<mat-dialog-content>
	<div class="form-group container">
		<div>{{ 'project.prefill.dialog.content' | translate }}</div>

		<form class="form mt-5" role="form" #prefillForm="ngForm" (ngSubmit)="apply()">
			<div>
				<div class="float-left">
					{{ 'project.prefill.dialog.invite' | translate }}
				</div>
				<div class="float-right">
					<mat-slide-toggle color="accent" name="invite" [(ngModel)]="invite" data-cy="list-of-invites-toggle"></mat-slide-toggle>
				</div>
			</div>

			<br />
			<hr />

			<div>
				<div class="float-left">
					{{ 'project.prefill.dialog.address' | translate }}
				</div>
				<div class="float-right">
					<mat-slide-toggle color="accent" name="address" [(ngModel)]="address" data-cy="folder-address-toggle"></mat-slide-toggle>
				</div>
			</div>

			<br />
			<hr />

			<div>
				<div class="float-left">
					{{ 'project.prefill.dialog.billing' | translate }}
				</div>
				<div class="float-right">
					<mat-slide-toggle color="accent" name="billing" [(ngModel)]="billing" data-cy="billing-toggle"></mat-slide-toggle>
				</div>
			</div>

			<br />
			<hr />

			<div>
				<div class="float-left">
					{{ 'project.prefill.dialog.customer' | translate }}
				</div>
				<div class="float-right">
					<mat-slide-toggle [(ngModel)]="contacts" color="accent" name="customer" data-cy="customer-info-toggle"></mat-slide-toggle>
				</div>
			</div>

			<br />
			<hr />

			<div align="center" class="mt-5">
				<button type="submit" color="accent" data-cy="create-a-project" mat-flat-button>
					{{ 'project.prefill.dialog.button' | translate }}
				</button>
			</div>
		</form>
	</div>
</mat-dialog-content>
