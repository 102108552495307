import { NgModule } from '@angular/core';
import { UnixToMillisPipe } from './unix-to-millis.pipe';

const PIPE = [UnixToMillisPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class UnixToMillisPipeModule {}
