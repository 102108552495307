import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class LinkService {
	constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

	get tocLink(): string {
		return `https://v2.craftnote.de/agb`;
	}

	get avvLink(): string {
		return `https://v2.craftnote.de/agb`;
	}

	get legalNoticeLink(): string {
		return 'https://v2.craftnote.de/datenschutz';
	}

	get imprintLink(): string {
		return 'https://v2.craftnote.de/impressum';
	}

	openLinkInNewTab(link: string): void {
		this.windowRef.open(link, '_blank');
	}

	get pricePage(): string {
		return 'https://www.craftnote.de/preise';
	}
}
