import { NgModule } from '@angular/core';
import { LineLeftComponent } from './line-left.component';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [LineLeftComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule],
	exports: [COMPONENT],
})
export class LineLeftModule {}
