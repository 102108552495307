import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as productActions from '../actions/products.actions';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { ProductService } from '@injectables/services/product/product.service';
import { selectUserId } from '../selectors/app.selectors';
import { AppState } from '../state/app.state';
import { of } from 'rxjs';

@Injectable()
export class ProductsEffects implements OnInitEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly productService: ProductService,
		private readonly store: Store<AppState>,
	) {}

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(productActions.loadProducts),
			switchMapTo(this.store.select(selectUserId)),
			switchMap((userId) => {
				if (!userId) {
					return of([]);
				}
				return this.productService.getAvailableProducts();
			}),
			map((products) => productActions.setProducts({ products })),
		),
	);

	ngrxOnInitEffects(): Action {
		return productActions.loadProducts();
	}
}
