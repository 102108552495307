<div class="project-status-progress-bar-container mt-1">
	<div class="project-status-wrapper d-flex flex-row justify-content-center">
		<div class="project-status mr-1">
			<span>{{ projectStatus.statusIndex + 1 }}</span
			>/<span>{{ projectStatus.totalCompanyStatuses }}</span>
		</div>
		<div class="progress-bar">
			<mat-progress-bar
				data-cy="project-list-status"
				class="project-status-progress-bar"
				color="accent"
				mode="determinate"
				[value]="percent"
			></mat-progress-bar>
		</div>
	</div>
	<p class="mb-0 text-truncate">{{ projectStatus.statusName }}</p>
</div>
