import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCompanyProjectsReducers from '../reducers/company-projects.reducer';
import * as fromCompanyProjectsState from '../state/company-projects.state';
import { Member, Project } from 'domain-entities';
import { Dictionary } from '@ngrx/entity';
import { selectUserId } from '@store/selectors/app.selectors';
import { isInProject } from '@shared/functions/project/project.functions';

const { selectIds, selectAll, selectEntities } =
	fromCompanyProjectsReducers.companyProjectsEntityAdapter.getSelectors();

const companyProjectsSelector =
	createFeatureSelector<fromCompanyProjectsState.CompanyProjectsState>('companyProjects');

export const selectCompanyProjectsLoaded = createSelector(
	companyProjectsSelector,
	(state) => state.loaded,
);

// Note:: Either returns all the company projects, or not at all if user is not owner.
// For selecting projects for which user is only authorized to see, check 'selectAllProjects' selector
export const selectCompanyProjects = createSelector(companyProjectsSelector, selectAll);

export const selectCompanyProjectsEntities = createSelector(
	companyProjectsSelector,
	selectEntities,
);

export const selectCompanyProjectIds = createSelector(companyProjectsSelector, selectIds);

export const selectCompanyProjectById = createSelector(
	selectCompanyProjectsEntities,
	(projectEntities: Dictionary<Project>, { id }) => projectEntities[id],
);

export const selectCompanyProjectsByParentId = createSelector(
	selectCompanyProjects,
	(projects: Project[], { id }) => {
		if (!id) {
			return [];
		}

		return projects.filter((project) => project.parentProject === id);
	},
);

export const selectCompanyProjectsMembers = createSelector(selectCompanyProjects, (projects) => {
	const members: { [emails: string]: Member } = {};
	projects.forEach((project) => {
		Object.keys(project.members).forEach((email) => (members[email] = project.members[email]));
	});
	return members;
});

export const selectCompanyProjectsMembersAcceptedEntities = createSelector(
	selectCompanyProjectsMembers,
	(companyProjectsMembers) => {
		const members = { ...companyProjectsMembers };
		Object.keys(members).forEach((email) => {
			if (!members[email].id) {
				delete members[email];
			}
		});
		return members;
	},
);

export const selectMyCompanyProjects = createSelector(
	selectCompanyProjects,
	selectUserId,
	(projects, userId) => projects.filter((project) => isInProject(project, userId)),
);

export const selectTopLevelCompanyProjects = createSelector(selectCompanyProjects, (projects) => {
	return findTopLevelProjects(projects);
});

export const selectMyTopLevelCompanyProjects = createSelector(
	selectMyCompanyProjects,
	(projects) => {
		return findTopLevelProjects(projects);
	},
);

function findTopLevelProjects(projects: Project[]): Project[] {
	const projectsById = projects.reduce((acc, project) => {
		acc[project.id] = project;
		return acc;
	}, {} as { [keys: string]: Project });

	const filteredProjects = [];
	for (const project of projects) {
		if (!project.parentProject || !projectsById[project.parentProject]) {
			filteredProjects.push(project);
		}
	}
	return filteredProjects;
}
