import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Input,
	Renderer2,
} from '@angular/core';

@Directive({
	selector: '[appOverflowClass]',
})
export class OverflowClassDirective implements AfterViewInit {
	_appOverflowClass: string;
	get appOverflowClass(): string {
		return this._appOverflowClass;
	}

	@Input() set appOverflowClass(value: string) {
		this._appOverflowClass = value;
		this.addRemoveClassOnOverflow();
	}

	private readonly ele: HTMLDivElement;

	constructor(readonly eleRef: ElementRef, private readonly renderer: Renderer2) {
		this.ele = eleRef.nativeElement;
	}

	ngAfterViewInit(): void {
		this.addRemoveClassOnOverflow();
	}

	@HostListener('window:resize', ['$event'])
	private addRemoveClassOnOverflow(): void {
		if (this.isOverflowing()) {
			this.renderer.addClass(this.ele, this.appOverflowClass);
		} else {
			this.renderer.removeClass(this.ele, this.appOverflowClass);
		}
	}

	private isOverflowing(): boolean {
		return this.ele.offsetHeight < this.ele.scrollHeight;
	}
}
