export enum EmptyStateEnum {
	FILE_TAB = 'FILE_TAB',
	TIME_TRACKING = 'TIME_TRACKING',
	PROJECTS = 'PROJECTS',
	ARCHIVE = 'ARCHIVE',
	PROJECT_MEMBER = 'PROJECT_MEMBER',
	PROJECT_MEMBER_INTERNAL = 'PROJECT_MEMBER_INTERNAL',
	PROJECT_MEMBER_EXTERNAL = 'PROJECT_MEMBER_EXTERNAL',
	PROJECT_CHAT = 'PROJECT_CHAT',
	PROJECT_TASKS = 'PROJECT_TASKS',
	COMPANY_TEMPLATE = 'COMPANY_TEMPLATE',
}
