import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyFile } from 'domain-entities';
import { CompanyFileConnector } from '@shared/firebase/connectors/firestore/collections/company-file/company-file.connector';
import { EntityChanges } from '@craftnote/shared-utils';

@Injectable({ providedIn: 'root' })
export class CompanyFileService {
	constructor(private readonly companyFileConnector: CompanyFileConnector) {}

	getCompanyFileEntityStateChanges(companyId: string): Observable<EntityChanges<CompanyFile>> {
		return this.companyFileConnector.watchCompanyFiles(companyId);
	}
}
