<div class="firefox-compatability-settings d-flex align-items-center justify-content-center h-100 w-75 flex-column m-auto">
	<div class="pointer d-flex align-items-center m-4 align-self-end" (click)="close()">
		<div>{{ 'browserCompatability.firefoxSettings.cancel' | translate }}</div>
		<mat-icon class="ml-2">close</mat-icon>
	</div>
	<div class="mat-typography w-75">
		<section class="d-flex flex-column flex-nowrap mat-typography schedule-demo-dialog-section">
			<div class="d-flex flex-column">
				<div class="mat-body-2 mb-4">
					{{ 'browserCompatability.firefoxSettings.title' | translate }}
				</div>
				<ul class="d-flex setting-steps">
					<li>
						<div class="mr-2">
							<div class="mb-2 mat-body-2">
								{{ 'browserCompatability.firefoxSettings.step1' | translate }}
							</div>
							<div class="mb-2 mat-body-1">
								{{ 'browserCompatability.firefoxSettings.step1SubText' | translate }}
							</div>
							<img class="img-fluid w-75" [src]="changeSettingsMenuImageURL" alt="firefox browser settings" />
						</div>
					</li>
					<li>
						<div class="ml-2">
							<div class="mb-2 mat-body-2">
								{{ 'browserCompatability.firefoxSettings.step2' | translate }}
							</div>
							<div class="mb-2 mat-body-1">
								{{ 'browserCompatability.firefoxSettings.step2SubText' | translate }}
							</div>
							<img class="img-fluid w-100" [src]="changeSettingsImageURL" alt="firefox browser settings" />
						</div>
					</li>
				</ul>
			</div>
		</section>
	</div>
</div>
