import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { NotificationCenterService } from './notification-center.service';
import {
	Notification,
	NotificationContinuation,
	NotificationFilter,
} from '@injectables/connectors/notifications.connector';
import { ActivatedRoute, Router } from '@angular/router';
import { debounce } from 'lodash';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	NotificationsCenterOpenedEventBuilder,
	NotificationsCenterPaywallOpenedEventBuilder,
	NotificationsCenterPaywallSelectedEventBuilder,
} from '@generated/events/NotificationsEvents.generated';
import { isOwnerOfCompany, selectUserRole } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { buffer, firstValueFrom, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { ResourceType } from 'domain-entities';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { MatLegacyMenu } from '@angular/material/legacy-menu';

const listAnimation = trigger('listAnimation', [
	transition('* <=> *', [
		query(
			':enter',
			[style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
			{ optional: true },
		),
		query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
	]),
]);

const NOTIFICATION_LIMIT = 20;

@Component({
	selector: 'app-notification-center',
	templateUrl: './notification-center.component.html',
	styleUrls: ['./notification-center.component.scss'],
	animations: [listAnimation],
})
export class NotificationCenterComponent implements OnDestroy {
	fetchingNotifications = false;
	notificationsList: Notification[] = null;
	notificationFilter: NotificationFilter = {};
	notificationContinuation: NotificationContinuation = {};
	showErrorState = false;
	@ViewChild('sidenav') sidenav: MatSidenav;
	@ViewChild('notificationMenu') notificationMenu: MatLegacyMenu;
	isSidenavOpen = false;
	onScrollDebounced = debounce(this.onScroll, 100);
	markNotificationAsRead$ = new Subject();
	markNotificationAsReadBuffered$ = this.markNotificationAsRead$.pipe(
		buffer(this.markNotificationAsRead$.pipe(debounceTime(3500))),
	);
	destroy$ = new Subject<void>();
	isNotificationCenterEnabled$ = this.profileLimitsService.getProfileLimits().pipe(
		map((profileLimits) => profileLimits && profileLimits[ProfileLimitKey.NOTIFICATION_CENTER]),
		distinctUntilChanged(),
	);
	isOwner$ = this.store.select(isOwnerOfCompany);
	private showPaywall$$ = new Subject();
	showPaywall$ = this.showPaywall$$.asObservable().pipe(
		map(
			(isNotificationCenterEnabled) =>
				!isNotificationCenterEnabled && this.notificationsList?.length,
		),
		distinctUntilChanged(),
		tap(async (showPaywall) => {
			if (showPaywall) {
				const role = (await firstValueFrom(this.store.select(selectUserRole))).toLowerCase();
				this.trackingService.trackEvent(new NotificationsCenterPaywallOpenedEventBuilder({ role }));
			}
		}),
	);

	@HostListener('document:click', ['$event.target'])
	onClick(target: HTMLElement): void {
		const isInside = this.elementRef.nativeElement.contains(target);
		if (!isInside && this.isSidenavOpen) {
			this.close();
		}
	}

	constructor(
		readonly notificationCenterService: NotificationCenterService,
		private readonly router: Router,
		private readonly elementRef: ElementRef,
		private readonly activatedRoute: ActivatedRoute,
		private readonly trackingService: TrackingService,
		private readonly profileLimitsService: ProfileLimitsService,
		private readonly store: Store<AppState>,
	) {
		this.markNotificationAsReadBufferedSubscription();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	private markNotificationAsReadBufferedSubscription(): void {
		this.markNotificationAsReadBuffered$
			.pipe(takeUntil(this.destroy$))
			.subscribe((notifications: Notification[]) => {
				this.notificationCenterService.markNotificationsAsRead(notifications);
			});
	}

	private initialize(): void {
		this.onClickNotificationFilter({});
	}

	private reset(): void {
		this.fetchingNotifications = false;
		this.notificationsList = null;
		this.notificationFilter = {
			resourceTypes: [
				ResourceType.PROJECT,
				ResourceType.TASK,
				ResourceType.CHAT_MESSAGE,
				ResourceType.FILE,
			],
		};
		this.notificationContinuation = { startAfter: null, limit: NOTIFICATION_LIMIT };
		this.notificationCenterService.notificationUnreadCount = '';
	}

	private async fetchNotifications(): Promise<void> {
		if (!this.fetchingNotifications && this.notificationContinuation.startAfter !== undefined) {
			this.fetchingNotifications = true;
			try {
				this.showErrorState = false;
				const listNotificationResponseData =
					await this.notificationCenterService.fetchNotifications(
						this.notificationContinuation,
						this.notificationFilter,
					);
				// @ts-ignore
				if (this.fetchingNotifications === false) {
					return;
				}
				this.notificationsList = [
					...(this.notificationsList ?? []),
					...listNotificationResponseData.data,
				];
				this.notificationContinuation.startAfter = listNotificationResponseData.startAfter;
				this.fetchingNotifications = false;
				this.showPaywall$$.next(await firstValueFrom(this.isNotificationCenterEnabled$));
			} catch (e) {
				this.showErrorState = true;
				this.reset();
				this.fetchingNotifications = false;
				throw new Error(e);
			}
		}
	}

	private updateURLFragment(fragment: string): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			fragment,
		});
	}

	private onScroll(event: Event): void {
		const srcElement = event.srcElement as HTMLElement;
		// scroll once the 200 px are left at the bottom
		if (200 + srcElement.offsetHeight + srcElement.scrollTop >= srcElement.scrollHeight) {
			this.fetchNotifications();
		}
	}

	onClickNotificationFilter(notificationFilter: NotificationFilter): void {
		this.reset();
		this.notificationFilter = { ...this.notificationFilter, ...notificationFilter };
		this.fetchNotifications();
	}

	async open(): Promise<void> {
		const role = (await firstValueFrom(this.store.select(selectUserRole))).toLowerCase();
		this.trackingService.trackEvent(new NotificationsCenterOpenedEventBuilder({ role }));
		await this.sidenav.open();
		this.isSidenavOpen = true;
		this.initialize();
		this.updateURLFragment('notification-center');
	}

	async close(): Promise<void> {
		this.sidenav.close();
		this.isSidenavOpen = false;
		this.reset();
		this.updateURLFragment(null);
	}

	toggle(): void {
		this.isSidenavOpen ? this.close() : this.open();
	}

	openNotification(notification: Notification): void {
		if (!notification.resourcePath) {
			return;
		}

		this.close();
		this.notificationCenterService.navigate(notification);
	}

	onNotificationRead(notification: Notification): void {
		setTimeout(() => {
			if (notification.isUnread) {
				notification.isUnread = false;
				this.markNotificationAsRead$.next(notification);
			}
		}, 2500);
	}

	async onClickPaywallCta(): Promise<void> {
		this.close();
		const isOwner = await firstValueFrom(this.isOwner$);
		if (isOwner) {
			this.router.navigate(['/settings/subscription/products']);
		}
		const role = (await firstValueFrom(this.store.select(selectUserRole))).toLowerCase();
		this.trackingService.trackEvent(new NotificationsCenterPaywallSelectedEventBuilder({ role }));
	}

	notificationMenuClosed(): void {
		if (this.notificationMenu) {
			this.notificationMenu.closed.emit();
		}
	}

	onClickBackDrop(): void {
		this.notificationMenuClosed();
		this.close();
	}

	onClickNotificationContainer(event: any): void {
		if (event?.target.id === 'notification-menu-icon') {
			return;
		}
		this.notificationMenuClosed();
	}

	markAllNotificationAsRead(): void {
		this.onClickNotificationContainer(null);
		this.notificationCenterService.markAllNotificationsAsRead();
		this.notificationCenterService.notificationUnreadCount = '';
		this.notificationsList = this.notificationsList.map((notification) => {
			notification.isUnread = false;
			return notification;
		});
	}
}
