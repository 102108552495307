import { Member } from 'domain-entities';

export enum InternalMemberInviteStatus {
	/**
	 * INFO: Don't change the order of the enums, its been used in the internal members sorting order.
	 */
	ADDED, // for current logged in user to show just the label "added"
	REMOVE, // If the invitation is accepted and an entry available in the current project member with the state of accepted, to show the REMOVE button
	ADD, // To add into the project
	REMIND, // If an entry available in the invitations collection, to show REMIND button,
	RESEND, // If the invitation is declined and an entry available in the invitations collection, to show the RESEND button
}

export interface InternalMember {
	entity: Member;
	invitedStatus: InternalMemberInviteStatus;
	invitationId?: string;
}
