import { Component } from '@angular/core';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectUserId } from '@store/selectors/app.selectors';
import { map, take } from 'rxjs/operators';
import { updateProfileToc } from '@shared/firebase/profile/profile-update.functions';
import moment from 'moment';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LinkService } from '@injectables/services/link/link.service';
import { from } from 'rxjs';
import { TocService } from '@injectables/services/toc.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Component({
	selector: 'app-toc-declined-dialog',
	templateUrl: './toc-declined-dialog.component.html',
	styleUrls: ['./toc-declined-dialog.component.scss'],
})
export class TocDeclinedDialogComponent {
	deadline$ = from(this.tocService.getDueDate()).pipe(map((date) => date.format('DD.MM.yyyy')));

	constructor(
		public readonly dialogRef: MatDialogRef<TocDeclinedDialogComponent>,
		private readonly localStorageService: LocalStorageService,
		private readonly profileService: ProfileService,
		private readonly store: Store<AppState>,
		private readonly remoteConfig: RemoteConfig,
		public readonly linkService: LinkService,
		private readonly tocService: TocService,
	) {}

	closeDialog(): void {
		this.localStorageService.set('firefoxTocDeclineLastVisit', moment().unix());
		this.dialogRef.close();
	}

	async acceptToc(): Promise<void> {
		this.closeDialog();
		const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const version = Number(await this.remoteConfig.getValueAsync('version_current_toc'));
		await this.profileService.updateProfileTransactional(
			userId,
			updateProfileToc('accepted', moment().unix(), version),
		);
	}
}
