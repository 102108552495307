<section class="notification-token-permission-section d-flex flex-column">
	<div class="remind-me-later-container">
		<a mat-button (click)="onClickRemindMeLater()">
			<mat-icon [size]="32" class="mr-2">close</mat-icon>
			<span class="mat-headline font-weight-normal" data-cy="notifications-remind-later">{{
				'notification-token-permission.remind-me-later' | translate
			}}</span>
		</a>
	</div>
	<div [ngSwitch]="currentBrowserNotificationPermission$ | async">
		<app-notification-token-permission-denied *ngSwitchCase="NotificationPermission.DENIED"></app-notification-token-permission-denied>
		<app-notification-token-permission-default *ngSwitchCase="NotificationPermission.DEFAULT"></app-notification-token-permission-default>
	</div>
</section>
