import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Observable} from 'rxjs';
import {selectCompanyHasSubscriptionOfTypeSubscription} from '@store/selectors/subscriptions.selectors';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {isOwnerOfCompany, isSupervisorOfCompany} from '@store/selectors/app.selectors';
import {WINDOW} from '@craftnote/shared-utils';
import {ScriptLoaderService} from '@injectables/services/script-loader.service';
import {LinkService} from "@injectables/services/link/link.service";

@Component({
	selector: 'app-grouping-paywall',
	templateUrl: './project-search-paywall.html',
	styleUrls: ['./project-search-paywall.scss'],
})
export class ProjectSearchPaywallComponent implements OnInit {
	isOwner$ = this.store.select(isOwnerOfCompany);
	isSupervisor$ = this.store.select(isSupervisorOfCompany);
	@ViewChild('lottiContainer', {read: ElementRef}) lottieContainer: ElementRef;

	get subscriptionRoute(): Observable<'' | '/products'> {
		return this.store
			.select(selectCompanyHasSubscriptionOfTypeSubscription)
			.pipe(map((hasSubscription) => (hasSubscription ? '' : '/products')));
	}

	@Input() feature: 'grouping' | 'filters';
	@Input() dialogRef: MatDialogRef<any>;

	constructor(
		private readonly store: Store,
		private readonly linkService: LinkService,
		private readonly scriptLoaderService: ScriptLoaderService,
		@Inject(WINDOW) private readonly window: Window,
	) {
	}

	async ngOnInit(): Promise<void> {
		await this.scriptLoaderService.loadScript(
			'lottie',
			'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.10.2/lottie.min.js',
		);
		(this.window as any).lottie.loadAnimation({
			container: this.lottieContainer.nativeElement,
			path: 'assets/animations/grouping-animation.json',
			renderer: 'svg',
			loop: true,
			autoplay: true,
		});
	}

	goToPricingTable(): void {
		this.dialogRef.close();
		this.linkService.openLinkInNewTab(this.linkService.pricePage);
	}
}
