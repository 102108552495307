import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { UnsavedChanges } from '@shared/models/has-unsaved-changes.model';

@Injectable({ providedIn: 'root' })
export class HasUnsavedChangesGuard {
	constructor(
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {}

	canDeactivate<T extends UnsavedChanges>(component: T): Observable<boolean> {
		// If parent component destroys the child component using this guard.
		if (!component) {
			return of(true);
		}

		if (component.hasUnsavedChanges()) {
			return of(true);
		}

		return this.confirmDialogService
			.open({
				title: this.translateService.instant('unsavedChanges.dialog.title'),
				message: this.translateService.instant('unsaveChanges.dialog.content'),
				primaryButtonText: this.translateService.instant('unsaveChanges.dialog.discardChanges'),
				primaryButtonColor: 'warn',
				secondaryButtonText: this.translateService.instant('unsaveChanges.dialog.cancel'),
				showSecondaryButton: true,
				showCrossBtn: false,
			})
			.afterClosed();
	}
}
