import { Component, Input } from '@angular/core';
import {
	SharedDialogElement,
	SharedSliderDialogSideType,
} from '../../entities/shared-slider-dialog.data';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-shared-slider-control',
	templateUrl: './shared-slider-control.component.html',
	styleUrls: ['./shared-slider-control.component.scss'],
})
export class SharedSliderControlComponent {
	SharedSliderDialogSideType = SharedSliderDialogSideType;

	@Input() sliderData: SharedDialogElement;
	@Input() sliderControl: UntypedFormControl | UntypedFormGroup;
	protected readonly Object = Object;
}
