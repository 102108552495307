import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconDisabledDirective } from './mat-icon-disabled.directive';

@NgModule({
	declarations: [MatIconDisabledDirective],
	imports: [CommonModule],
	exports: [MatIconDisabledDirective],
})
export class MatIconDisabledModule {}
