import { TimesComponentStoreFilter } from '@modules/features/dashboard/store/component/types/times-dashboard.types';
import { Observable } from 'rxjs';
import { TrackedTime } from 'domain-entities';
import { Injectable } from '@angular/core';
import { TrackedTimeConnector } from '@shared/firebase/connectors/firestore/collections/tracked-time/tracked-time.connector';

@Injectable()
export class TrackedTimesProjectsService {
	constructor(private readonly trackedTimeConnector: TrackedTimeConnector) {}

	getTrackedTimes(companyId: string, filter: TimesComponentStoreFilter): Observable<TrackedTime[]> {
		return this.trackedTimeConnector.watchTrackedTimes(companyId, filter);
	}

	getTrackedTimeById(id: string): Observable<TrackedTime> {
		return this.trackedTimeConnector.getTrackedTimeById(id);
	}
}
