import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { clearEntities, EntityChanges } from '@craftnote/shared-utils';
import { CompanyEmployee, isCompanyEmployee } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class CompanyConnector {
	static COLLECTION_NAME = 'companies';
	private static COMPANY_MEMBERS_COLLECTION_NAME = 'employees';

	constructor(private readonly connector: FirestoreConnector) {}

	/**
	 * This method watches the company members subcollection of a given company
	 * @param companyId
	 */
	watchCompanyMembers(companyId: string): Observable<EntityChanges<CompanyEmployee>> {
		if (!companyId) {
			return of(clearEntities);
		}
		const path = `${CompanyConnector.COLLECTION_NAME}/${companyId}/${CompanyConnector.COMPANY_MEMBERS_COLLECTION_NAME}`;
		return this.connector.watchDocumentsChanges<CompanyEmployee>(path, [], isCompanyEmployee);
	}

	upsertCompanyEmployee(
		companyId: string,
		userId: string,
		updateFunction: (oldCompanyEmployee: CompanyEmployee | undefined) => CompanyEmployee,
	): Promise<void> {
		const path = `${CompanyConnector.COLLECTION_NAME}/${companyId}/${CompanyConnector.COMPANY_MEMBERS_COLLECTION_NAME}`;
		return this.connector.upsertTransactional(path, userId, updateFunction, isCompanyEmployee);
	}
}
