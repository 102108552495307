import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '@injectables/services/date.service';
import { PlanPaymentInterval, Product, Subscription } from 'domain-entities';
import { GeoLocationService } from '@injectables/services/geo-location/geo-location.service';
import { DEFAULT_CURRENCY } from '@shared/constants/subscription';
import {
	SubscriptionChange,
	SubscriptionChangeWithExistingSubscription,
} from '@modules/features/subscription/products/model/SubscriptionChange';
import { SubscriptionChangeDirection } from '@modules/features/subscription/products/model/SubscriptionChangeDirection';
import {
	getCurrentPlan,
	getNextPlan,
	getProductBySubscription,
	getProductForNextPlan,
} from '@modules/features/subscription/products/shared/shared.functions';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionChangeTranslationService {
	constructor(
		private readonly translateService: TranslateService,
		private dateService: DateService,
		private locationService: GeoLocationService,
	) {}

	public getDateChangeText(subscriptionChange: SubscriptionChangeWithExistingSubscription): string {
		if (subscriptionChange.changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
			const renewalTimestamp = subscriptionChange.subscription.renewalDate;
			const renewalDateString = this.dateService.getShortDate(renewalTimestamp);
			return this.translateService.instant('subscription.change.changeAtPeriodEnd', {
				date: renewalDateString,
			});
		}
		return '';
	}

	public getCostChangeText(
		subscriptionChange: SubscriptionChange,
		cost: number,
		vatRate: number,
	): string {
		let periodSelection = 'monthly';
		if (subscriptionChange.to.plan) {
			periodSelection = subscriptionChange.to.plan.paymentInterval.toLowerCase();
		}
		const period = this.translateService.instant('subscription.change.' + periodSelection);

		const vatText = this.getVatText(vatRate);
		const currencyText = this.getCurrencyText(cost);
		const newCostText = this.translateService.instant('subscription.change.newCost', {
			cost: currencyText,
			period: period,
		});
		return `${newCostText} ${vatText}`;
	}

	public getTotalCostText(
		cost: number,
		paymentInterval: PlanPaymentInterval,
		vatRate?: number,
	): string {
		const vatText = this.getVatText(vatRate);
		const currencyText = this.getCurrencyText(cost);
		const costText = this.translateService.instant(
			'subscription.cost.' + paymentInterval.toLowerCase(),
			{ cost: currencyText },
		);
		return `${costText} ${vatText}`;
	}

	private getVatText(vatRate: number | undefined): string {
		if (vatRate) {
			return this.translateService.instant(
				'settings.subscriptionWizard.confirmationStep.includingVat',
				{ rate: vatRate },
			);
		}
		return '';
	}

	public getCurrencyCostText(cost: number): string {
		return cost.toLocaleString(this.locationService.locale, {
			style: 'currency',
			currency: DEFAULT_CURRENCY,
		});
	}

	public getCostPerIntervalText(cost: number, paymentInterval: PlanPaymentInterval): string {
		const currencyCostText = this.getCurrencyCostText(cost);
		return this.translateService.instant('subscription.cost.' + paymentInterval.toLowerCase(), {
			cost: currencyCostText,
		});
	}

	public getProductChangeText(
		product: Product,
		allProducts: Product[],
		subscription: Subscription,
	): string {
		const subscriptionType = product.shortName;
		const renewalDate = subscription.renewalDate;
		const renewalDateString = this.getRenewalDateString(renewalDate);

		const currentProduct = getProductBySubscription(allProducts, subscription);
		const nextProduct = getProductForNextPlan(allProducts, subscription);

		/**
		 * There are three cases to be distinguished:
		 * 1) Cancellation: The subscription has no next plan id. The subscription will end
		 *    at the end of the period.
		 *
		 * 2) Downgrade: The next product differs from the current product. It will be changed to a
		 *    lower plan in the future.
		 *
		 * 3) Renewal: The next product is equal to the current product. The subscription will be renewed
		 *    at the end of the period.
		 */
		if (!subscription.nextState) {
			return this.translateService.instant('subscription.cancellation', {
				subscriptionType,
				date: renewalDateString,
			});
		} else if (currentProduct !== nextProduct) {
			const nextProductName = getProductForNextPlan(allProducts, subscription).shortName;
			return this.translateService.instant('subscription.downgrade', {
				subscriptionType,
				newSubscriptionType: nextProductName,
				date: renewalDateString,
			});
		} else {
			return this.translateService.instant('subscription.renewal', {
				subscriptionType,
				date: renewalDateString,
			});
		}
	}

	public getPaymentIntervalChangeText(subscription: Subscription, products: Product[]): string {
		if (
			!subscription.nextState ||
			!subscription.nextState.planId ||
			subscription.nextState.planId === subscription.currentState.planId
		) {
			return '';
		}

		const oldPlan = getCurrentPlan(products, subscription);
		const newPlan = getNextPlan(products, subscription);
		if (newPlan.paymentInterval === oldPlan.paymentInterval) {
			return '';
		}
		const renewalDate = this.getRenewalDateString(subscription.renewalDate);

		const adjectiveKey =
			newPlan.paymentInterval === PlanPaymentInterval.MONTHLY ? 'monthly' : 'yearly';
		const adjective = this.translateService.instant(
			`subscription.billingPeriod.${adjectiveKey}.adjective`,
		);
		return this.translateService.instant('subscription.billingPeriod.changedPeriod', {
			date: renewalDate,
			period: adjective,
		});
	}

	getLicensesChangeText(subscription: Subscription): string {
		if (
			!subscription.nextState ||
			!subscription.nextState.planId ||
			subscription.nextState.quantity === subscription.currentState.quantity
		) {
			return '';
		}
		const licenses = subscription.nextState.quantity;
		const date = this.getRenewalDateString(subscription.renewalDate);
		return this.translateService.instant('subscription.licensesNumber.changedLicenses', {
			date,
			licenses,
		});
	}

	private getRenewalDateString(renewalDate: number): string {
		return this.dateService.getShortDate(renewalDate);
	}

	private getCurrencyText(cost: number): string {
		return cost.toLocaleString(this.locationService.locale, {
			style: 'currency',
			currency: DEFAULT_CURRENCY,
		});
	}
}
