import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'readableDate',
})
export class ReadableDatePipe implements PipeTransform {
	transform(timeStamp: number): unknown {
		const date = moment.unix(timeStamp);

		return date.diff(moment(), 'days') < -2 ? date.format('DD.MM.YYYY') : date.calendar();
	}
}
