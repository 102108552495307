import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { TrackedTime } from 'domain-entities';
import { TrackedTimesChangesStore } from '@modules/shared/components/time-tracking-add-edit/store/tracked-times-changes.store';
import { map } from 'rxjs/operators';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';

@Component({
	selector: 'app-tracked-time-last-changes',
	templateUrl: './tracked-time-last-changes.component.html',
	styleUrls: ['./tracked-time-last-changes.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackedTimeLastChangesComponent implements OnChanges {
	@Input() trackedTime: TrackedTime;
	@Output() openChangeLog = new EventEmitter();

	auditLogCutoffTime$ = this.remoteConfig.getValueAsync('config_tracked_times_audit_log_cutoff');
	numberOfChanges$ = this.trackedTimesChangesStore.nonCreationChanges$.pipe(
		map((changes) => changes.length),
	);
	creatorName$ = this.trackedTimesChangesStore.creatorName$;
	lastChange$ = this.trackedTimesChangesStore.lastChange$;

	constructor(
		private readonly trackedTimesChangesStore: TrackedTimesChangesStore,
		private readonly remoteConfig: RemoteConfig,
	) {}

	ngOnChanges(): void {
		this.trackedTimesChangesStore.getChanges(this.trackedTime.id);
	}

	onOpenChangeLog(): void {
		this.openChangeLog.emit();
	}
}
