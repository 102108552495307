import { createAction, props } from '@ngrx/store';
import { Project } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';

export const companyProjectsChanged = createAction(
	'[Company Projects] Company projects changed',
	props<{ payload: EntityChanges<Project> }>(),
);

export const subscribeCompanyProjects = createAction(
	'[Company Projects] Subscribe to company projects changes (added, removed, modified)',
);
export const subscribeCompanyProjectsFailed = createAction(
	'[Company Projects] Subscribe to company projects changes (added, removed, modified) failed',
	props<any>(),
);

export const clearCompanyProjects = createAction('[Company Projects] Clear all company projects');
