import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-settings-page-header',
	templateUrl: './settings-page-header.component.html',
	styleUrls: ['./settings-page-header.component.scss'],
})
export class SettingsPageHeaderComponent {
	@Input() backNavigationRoute: string;
}
