import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import {
	debounce,
	map,
	pluck,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
} from 'rxjs/operators';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { firstValueFrom, Observable, Subject, timer } from 'rxjs';
import {
	GlobalSearchChatMessage,
	GlobalSearchDialogService,
	GlobalSearchFilterValue,
	GlobalSearchItem,
	GlobalSearchLastInteraction,
	GlobalSearchProject,
	GlobalSearchResponse,
	GlobalSearchTask,
} from './global-search-dialog.service';
import { ProjectColorTags } from '@shared/models/project.type';
import { ActivatedRoute, Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Project, ProjectType } from 'domain-entities';
import {
	selectAllActiveProjectsEntities,
	selectAllProjectsEntities,
	selectProjectFromActive,
} from '@store/selectors/projects.selectors';
import { Store } from '@ngrx/store';
import {
	isOwnerOfCompany,
	selectProjectStatusEntities,
	selectTheme,
} from '@store/selectors/app.selectors';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { AppTheme } from '@store/reducers/app.reducer';
import { MessageService } from '@injectables/services/message/message.service';
import { TasksService } from '@injectables/services/tasks/tasks.service';
import {
	SearchItemOpenedEventBuilder,
	SearchScreenOpenedEventBuilder,
	SearchSearchExecutedEventBuilder,
	SearchUpgradeSelectedEventBuilder,
} from '@generated/events/SearchEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Component({
	selector: 'app-global-search-dialog',
	templateUrl: './global-search-dialog.component.html',
	styleUrls: ['./global-search-dialog.component.scss'],
})
export class GlobalSearchDialogComponent implements OnDestroy {
	GlobalSearchFilterValue = GlobalSearchFilterValue;
	filterValueTrackingEventMapping = {
		[GlobalSearchFilterValue.PROJECTS_ALL]: 'projects-all',
		[GlobalSearchFilterValue.PROJECTS_ACTIVE]: 'projects-active',
		[GlobalSearchFilterValue.PROJECTS_ARCHIVED]: 'projects-archived',
		[GlobalSearchFilterValue.FOLDERS_ALL]: 'folders-all',
		[GlobalSearchFilterValue.FOLDERS_ACTIVE]: 'folders-active',
		[GlobalSearchFilterValue.FOLDERS_ARCHIVED]: 'folders-archived',
		[GlobalSearchFilterValue.MESSAGES]: 'messages',
		[GlobalSearchFilterValue.TASKS_ALL]: 'tasks-all',
		[GlobalSearchFilterValue.TASKS_OPEN]: 'tasks-open',
		[GlobalSearchFilterValue.TASKS_CLOSED]: 'tasks-completed',
	};
	globalSearchDialogRef: MatDialogRef<void>;
	ProjectType = ProjectType;
	projectStatusEntities$: Observable<Dictionary<ProjectStatus>> = this.store
		.select(selectProjectStatusEntities)
		.pipe(shareReplay(1));
	maxItemsForShowMore = 3;
	globalSearchForm = this.fb.group({
		searchTerm: [''],
		selectedFilter: [null],
	});
	projectEntities: Dictionary<Project> = {};
	searchResults: GlobalSearchResponse;
	lastInteractionSearchResults: GlobalSearchLastInteraction;
	isFilterSelected$ = this.globalSearchForm.valueChanges.pipe(
		map((value) => !!value.selectedFilter),
		startWith(false),
		shareReplay({
			bufferSize: 1,
			refCount: true,
		}),
	);
	projectColorTags = ProjectColorTags;
	isDarkTheme$ = this.store
		.select(selectTheme)
		.pipe(map((currentTheme) => currentTheme === AppTheme.DARK));
	isFilterContainerHidden = false;
	@ViewChild('globalSearchDialogTemplateRef')
	private globalSearchDialogTemplateRef: TemplateRef<any>;
	private destroy$ = new Subject<void>();
	isPremiumUser$: Observable<boolean> = this.profileLimitsService
		.getProfileLimits()
		.pipe(pluck(ProfileLimitKey.GLOBAL_SEARCH), map(Boolean));
	isNotPremiumUser$: Observable<boolean> = this.isPremiumUser$.pipe(
		map((value) => value === false),
	);
	isOwner$ = this.store.select(isOwnerOfCompany);

	constructor(
		private readonly dialog: MatDialog,
		private readonly fb: UntypedFormBuilder,
		private readonly globalSearchDialogService: GlobalSearchDialogService,
		private readonly router: Router,
		private readonly store: Store,
		private readonly activatedRoute: ActivatedRoute,
		private readonly localStorageService: LocalStorageService,
		private readonly messageService: MessageService,
		private readonly tasksService: TasksService,
		private readonly trackingService: TrackingService,
		private readonly profileLimitsService: ProfileLimitsService,
	) {
		this.initializeGlobalSearchFromSubscription();
		this.initializeProjectEntitiesSubscription();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	async openDialog(): Promise<void> {
		this.lastInteractionSearchResults =
			(await this.localStorageService.get('globalSearchLastInteraction')) ?? [];
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
			fragment: 'global-search',
		});
		this.globalSearchDialogRef = this.dialog.open(this.globalSearchDialogTemplateRef, {
			height: '70vh',
			width: '50vw',
			panelClass: 'position-relative',
		});
		this.trackingService.trackEvent(new SearchScreenOpenedEventBuilder({}));
		this.initializeOnCloseDialogSubscription();
	}

	async openProject(project: GlobalSearchProject): Promise<void> {
		await this.dispatchSearchItemOpenedEvent(
			project,
			project.projectType === ProjectType.PROJECT ? 'project' : 'folder',
		);
		const isProjectExists = !!this.projectEntities[project.id];
		isProjectExists
			? await this.addLastInteractionSearchResults(project)
			: await this.deleteLastInteractionSearchResults(project.id);

		this.globalSearchDialogRef.close([[project.archived ? 'archive' : 'projects', project.id]]);
	}

	async openTask(task: GlobalSearchTask): Promise<void> {
		await this.dispatchSearchItemOpenedEvent(task, 'task');
		const isProjectExists = !!this.projectEntities[task.projectId];
		const isTaskExists = await this.tasksService.checkIfTaskExists(task.id);
		isProjectExists && isTaskExists
			? await this.addLastInteractionSearchResults(task)
			: await this.deleteLastInteractionSearchResults(task.id);

		const activeProjectsEntities = await this.store
			.select(selectAllActiveProjectsEntities)
			.pipe(take(1))
			.toPromise();
		const isActiveProject = !!activeProjectsEntities[task.projectId];
		this.globalSearchDialogRef.close([
			[isActiveProject ? 'projects' : 'archive', task.projectId, 'tasks', task.id, 'edit'],
			{
				queryParams: { filter: task.done ? 'completed' : 'open' },
			},
		]);
	}

	async openChatMessage(chatMessage: GlobalSearchChatMessage): Promise<void> {
		await this.dispatchSearchItemOpenedEvent(chatMessage, 'chat-message');
		const isProjectExists = !!this.projectEntities[chatMessage.projectId];
		const isProjectActive = await firstValueFrom(
			this.store.select(selectProjectFromActive, { projectId: chatMessage.projectId }),
		);
		const isChatMessageExists = await this.messageService.isChatMessageExists(
			chatMessage.projectId,
			chatMessage.id,
		);
		isProjectExists && isChatMessageExists
			? await this.addLastInteractionSearchResults(chatMessage)
			: await this.deleteLastInteractionSearchResults(chatMessage.id);

		this.globalSearchDialogRef.close([
			[isProjectActive ? 'projects' : 'archive', chatMessage.projectId],
			{
				queryParams: { chatMessage: chatMessage.id },
			},
		]);
	}

	resetFilters(): void {
		this.globalSearchForm.get('selectedFilter').reset();
	}

	resetSearchTerm(event?: MouseEvent): void {
		if (event?.clientX === 0 && event?.clientY === 0) {
			return;
		}
		this.globalSearchForm.get('searchTerm').reset('');
	}

	initializeGlobalSearchFromSubscription(): void {
		this.globalSearchForm.valueChanges
			.pipe(
				debounce(() => timer(200)),
				switchMap(
					async ({
						searchTerm,
						selectedFilter,
					}: {
						searchTerm: string;
						selectedFilter: GlobalSearchFilterValue;
					}) => {
						this.searchResults = null;
						this.trackingService.trackEvent(
							new SearchSearchExecutedEventBuilder({
								preScope: this.filterValueTrackingEventMapping[selectedFilter] || 'none',
							}),
						);
						return this.globalSearchDialogService.getSearchResults(searchTerm, selectedFilter);
					},
				),
				takeUntil(this.destroy$),
			)
			.subscribe((result: GlobalSearchResponse) => {
				this.searchResults = result;
			});
	}

	addHighlightsForProjects(project: GlobalSearchProject): GlobalSearchProject {
		return this.addHighlights<GlobalSearchProject>(project, [
			'name',
			'street',
			'city',
			'zipcode',
			'country',
			'noteContent',
			'orderNumber',
		]);
	}

	addHighlightsForTasks(task: GlobalSearchTask): GlobalSearchTask {
		return this.addHighlights<GlobalSearchTask>(task, ['title', 'assignee', 'description']);
	}

	addHighlightsForChats(chat: GlobalSearchChatMessage): GlobalSearchChatMessage {
		return this.addHighlights<GlobalSearchChatMessage>(chat, ['author', 'content']);
	}

	addHighlights<T>(item: any, keys: any): T {
		Object.keys(item.highlight).forEach((highlightKey) => {
			if (keys.includes(highlightKey)) {
				item[highlightKey] = item.highlight[highlightKey][0];
			}
		});
		return item;
	}

	dispatchUpgradeEvent(): void {
		this.trackingService.trackEvent(new SearchUpgradeSelectedEventBuilder({}));
	}

	private initializeProjectEntitiesSubscription(): void {
		this.store
			.select(selectAllProjectsEntities)
			.pipe(takeUntil(this.destroy$))
			.subscribe((projects) => {
				this.projectEntities = projects;
			});
	}

	private async initializeOnCloseDialogSubscription(): Promise<void> {
		const [routerCommands, routerExtras] = (await this.globalSearchDialogRef
			.afterClosed()
			.pipe(take(1))
			.toPromise()) ?? [
			[],
			{
				relativeTo: this.activatedRoute,
				fragment: undefined,
			},
		];
		this.resetSearchTerm();
		this.resetFilters();
		await this.router.navigate(routerCommands, routerExtras);
	}

	private async deleteLastInteractionSearchResults(id: string): Promise<void> {
		this.lastInteractionSearchResults = this.lastInteractionSearchResults.filter(
			(result) => result.id !== id,
		);
		await this.localStorageService.set(
			'globalSearchLastInteraction',
			this.lastInteractionSearchResults,
		);
	}

	private async addLastInteractionSearchResults(
		item: GlobalSearchProject | GlobalSearchTask | GlobalSearchChatMessage,
	): Promise<void> {
		this.lastInteractionSearchResults = this.lastInteractionSearchResults.filter(
			(result) => result.id !== item.id,
		);
		this.lastInteractionSearchResults = [item, ...this.lastInteractionSearchResults].slice(0, 15);
		await this.localStorageService.set(
			'globalSearchLastInteraction',
			this.lastInteractionSearchResults,
		);
	}

	private async dispatchSearchItemOpenedEvent(
		item: GlobalSearchItem,
		itemType: 'project' | 'folder' | 'chat-message' | 'task',
	): Promise<void> {
		const isLastInteractedItem = this.lastInteractionSearchResults.some(
			(lastInteractionItem) => lastInteractionItem.id === item.id,
		);
		this.trackingService.trackEvent(
			new SearchItemOpenedEventBuilder({
				itemType,
				isLastInteractedItem,
			}),
		);
	}
}
