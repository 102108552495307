import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { firstValueFrom } from 'rxjs';
import { selectTheme } from '@store/selectors/app.selectors';
import { AppTheme } from '@store/reducers/app.reducer';
import { BrowserService } from '@injectables/services/browser.service';

@Injectable({
	providedIn: 'root',
})
export class MfeHelperService {
	window: Window;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly store: Store<AppState>,
		private readonly browserService: BrowserService,
	) {
		this.window = this.document.defaultView;
	}

	loadScript(
		mfeScriptUrl: string,
		tagName: string,
		onScriptLoadCallback: () => void,
		onScriptErrorCallback: () => void,
	): HTMLScriptElement | undefined {
		if (this.isCustomElementRegistered(tagName)) {
			onScriptLoadCallback();
			return undefined;
		}
		const scriptModule = this.document.createElement('script');
		scriptModule.type = 'module';
		scriptModule.src = mfeScriptUrl;

		scriptModule.onload = () => {
			onScriptLoadCallback();
		};
		scriptModule.onerror = () => {
			onScriptErrorCallback();
		};
		this.document.body.appendChild(scriptModule);
		return scriptModule;
	}

	isCustomElementRegistered(tagName: string): boolean {
		return !!this.window.customElements.get(tagName);
	}

	getMfe(tagName: string): HTMLElement | undefined {
		return this.document.querySelector(tagName);
	}

	async isDarkModeEnabled(): Promise<boolean> {
		const theme = await firstValueFrom(this.store.select(selectTheme));
		return theme === AppTheme.DARK;
	}

	getLanguage(): string {
		return this.browserService.getBrowserLanguage();
	}
}
