<div
	class="project-item-container d-flex flex-column"
	[ngClass]="{
		'project-selected': selected
	}"
>
	<div class="pl-2 project-folder-hint" *ngIf="showParentHint">
		<app-project-folder-hint [projectId]="project.id"></app-project-folder-hint>
	</div>
	<div class="project-item d-flex h-100" [ngClass]="{ 'pt-0': showParentHint }">
		<mat-icon appEventStopPropagation *ngIf="isOpenFolderCard" (click)="onGoBack.emit()" data-cy="go-back-to-project-list-button"
			>arrow_back</mat-icon
		>

		<div class="project-item__color d-flex flex-column">
			<div class="folder-icon" *ngIf="project.projectType === projectType.FOLDER">
				<mat-icon fontSet="material-icons-sharp">folder</mat-icon>
			</div>
			<div *ngIf="!isOpenFolderCard" [ngClass]="projectColorTags[project.colorTag]" class="project-color-tag ml-2 h-100"></div>
		</div>

		<div class="project-item__details pr-3 pl-2" data-cy="project-item-card">
			<div [class.double-line-height]="project.name.length > 16 && !projectStatus" class="project-name two-lines-text">
				<h1 class="m-0 break-all" [ngClass]="{ 'text-truncate': !!projectStatus }" data-cy="project-list-name">
					<!--
                    We need to be able to compute the exact height of a project card. In order to do so we nee to know whether a project name has 1 or 2 lines.
                    Unfortunately there is a "grey area" between 16 and 26 characters where the project name can have 1 or 2 lines depending on the characters included in the name.
                    To make sure the number of lines is predictable we always display two lines once the limit of 16 characters is reached.
                    -->
					{{ project.name }}
				</h1>
			</div>
			<ng-container *ngIf="!isOpenFolderCard">
				<div class="text-muted" data-cy="project-list-idnumber">
					<h3 class="mb-2 mt-2 text-truncate">
						<span *ngIf="project.projectType === projectType.FOLDER"
							>{{ childProjectsCount }} {{ 'project.item.folder.projects' | translate }} </span
						><span *ngIf="project.projectType === projectType.FOLDER && project.orderNumber">-</span>
						{{ project.orderNumber }}
					</h3>
				</div>
				<div class="text-muted two-lines-text">
					<h3 class="body-2 project-address font-weight-normal mb-0 text-truncate" data-cy="project-list-street">
						{{ project | projectAddress }}
					</h3>
				</div>
				<app-project-status-progress-bar *ngIf="projectStatus" [projectStatus]="projectStatus"></app-project-status-progress-bar>
			</ng-container>
		</div>
		<div *ngIf="!isOpenFolderCard" class="project-item__actions d-flex flex-column">
			<div class="d-flex align-self-end" data-cy="project-list-menu">
				<span class="unread-dot mt-1" *ngIf="showUnreadDot">
					<mat-icon class="mr-2" [size]="15">fiber_manual_record</mat-icon>
				</span>
				<mat-icon
					appEventStopPropagation
					*ngIf="showThreeDotsMenu; else noMoveMenu"
					class="text-muted"
					[matMenuTriggerFor]="moveMenu"
					(menuClosed)="onMenuClosed()"
					(menuOpened)="onMenuOpened()"
					data-cy="project-list-menu-three-dots"
					>more_horiz
				</mat-icon>

				<ng-template #noMoveMenu>
					<mat-icon
						*ngIf="!isCurrentUserInternalAdminOfProject || !isCurrentUserArchivedInProject"
						class="no-move-menu-icon text-muted"
						[size]="30"
						>chevron_right
					</mat-icon>
				</ng-template>
				<mat-menu #moveMenu="matMenu">
					<button
						*ngIf="context === projectContext.ACTIVE && project.projectType === projectType.PROJECT"
						(click)="emitAction(projectItemAction.MUTE_UNMUTE_CHAT)"
						mat-menu-item
					>
						{{ (isChatMessagesMuted ? 'project-item-new.unmute-chat' : 'project-item-new.mute-chat') | translate }}
					</button>
					<button
						*ngIf="context === projectContext.ACTIVE && project.projectType === projectType.PROJECT && !showUnreadDot"
						(click)="emitAction(projectItemAction.MARK_PROJECT_AS_UNREAD)"
						mat-menu-item
					>
						{{ 'project.actions.markAsUnread.title' | translate }}
					</button>
					<button
						*ngIf="context === projectContext.ACTIVE && project.projectType === projectType.PROJECT && showUnreadDot"
						(click)="emitAction(projectItemAction.MARK_PROJECT_AS_READ)"
						mat-menu-item
					>
						{{ 'project.actions.markAsRead.title' | translate }}
					</button>
					<button
						(click)="emitAction(projectItemAction.STATUS_UPDATE)"
						*ngIf="isUserAllowedToChangeStatus()"
						mat-menu-item
						data-cy="project-list-update-status"
					>
						{{ 'project.actions.updateStatus.title' | translate }}
					</button>
					<button
						(click)="emitAction(projectItemAction.MOVE_PROJECT)"
						*ngIf="isCurrentUserInternalAdminOfProject && project.projectType === projectType.PROJECT"
						mat-menu-item
						data-cy="project-list-move"
					>
						{{ 'project.actions.move.title' | translate }}
					</button>
					<button
						(click)="emitAction(projectItemAction.ARCHIVE_ROJECT)"
						*ngIf="isCurrentUserInternalAdminOfProject && context === projectContext.ACTIVE"
						mat-menu-item
						data-cy="project-list-archive"
					>
						{{ 'project.actions.archive.title' | translate }}
					</button>
					<button
						(click)="emitAction(projectItemAction.DUPLICATE_FOLDER)"
						*ngIf="isCurrentUserInternalAdminOfProject && context === projectContext.ACTIVE && !isExternalMemberOfProject"
						mat-menu-item
						data-cy="project-list-duplicate"
					>
						{{ 'global.duplicate.verb' | translate }}
					</button>
					<button
						(click)="emitAction(projectItemAction.RESTORE_PROJECT)"
						*ngIf="isCurrentUserInternalAdminOfProject && isCurrentUserArchivedInProject"
						mat-menu-item
						data-cy="archive-list-restore"
					>
						{{ 'project.actions.restore' | translate }}
					</button>
				</mat-menu>

				<mat-icon
					appEventStopPropagation
					*ngIf="project.projectType === projectType.FOLDER && isCurrentUserInternalAdminOfProject && isCurrentUserArchivedInProject"
					[matMenuTriggerFor]="restoreFolderMenu"
					mat-icon-button
					class="text-muted"
					>more_horiz
				</mat-icon>
				<mat-menu #restoreFolderMenu="matMenu">
					<button
						(click)="emitAction(projectItemAction.RESTORE_PROJECT)"
						*ngIf="isCurrentUserArchivedInProject && !isExternalMemberOfProject"
						mat-menu-item
					>
						{{ 'project.actions.restore' | translate }}
					</button>
				</mat-menu>
			</div>
			<mat-icon *ngIf="isChatMessagesMuted" [size]="22" class="grey-icon">volume_off</mat-icon>
			<div class="flex-1 d-flex align-items-end">
				<span class="text-muted mt-1 last-edited-date" data-cy="project-list-lastedited"> {{ projectTime }} </span>
			</div>
		</div>
	</div>
	<div *ngIf="!isLastItem" class="border mr-3 ml-3"></div>
</div>
