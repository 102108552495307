import { createSelector } from '@ngrx/store';
import { getProjectFromProjectState, selectProjectsState } from './projects.selectors';
import { selectAllCompanyFiles } from './company-files.selectors';
import {
	transformCompanyFilesToProjectFiles,
	transformFilesToProjectFiles,
} from '@modules/features/projects/functions/project-files.functions';
import { selectProfile, selectUserId, selectUserRole } from './app.selectors';
import { AppState } from '../state/app.state';
import * as fromProjectFiles from '../reducers/project-files.reducer';
import { FileType, MemberRole, Profile, Project } from 'domain-entities';
import { get } from 'lodash';
import { getMembersOfProject } from '@shared/functions/project/project.functions';
import { selectUserProfileLimitsSelector } from './profile-limits.selectors';
import { selectActiveProject } from '@store/selectors/route.selectors';

export const selectProjectsFilesState = (state: AppState) => state.projectFiles;

export const selectProjectFilesState = createSelector(
	selectProjectsFilesState,
	(state) => state.files,
);

export const selectProjectFilePathsState = createSelector(
	selectProjectsFilesState,
	(state) => state.filePaths,
);

export const selectProjectFilePathsEntityState = createSelector(
	selectProjectFilePathsState,
	(state) => fromProjectFiles.projectFilePathSelectors.selectEntities(state),
);

export const currentSelectedProject = createSelector(
	selectProjectsState,
	selectActiveProject,
	(state, projectId) => {
		return getProjectFromProjectState(state, projectId);
	},
);

export const selectAllProjectFiles = createSelector(
	selectProjectFilesState,
	currentSelectedProject,
	selectUserRole,
	selectProfile,
	(state, currentProject, userRole, profile) => {
		/**
		 * Filtering the files based on the permissions
		 */
		if (!currentProject || !userRole || !profile) {
			return undefined;
		}
		const filteredProjectFiles = fromProjectFiles.projectFilesSelectors
			.selectAll(state)
			.filter((project) => {
				const mappedRole = getComputedProjectRoles(userRole, profile, currentProject);
				return (
					project.type !== FileType.FOLDER ||
					!project.permissions ||
					mappedRole === MemberRole.OWNER ||
					get(project, ['permissions', 'roles', mappedRole, 'length']) > 0
				);
			});

		return filteredProjectFiles;
	},
);

export const selectAllProjectFilePaths = createSelector(selectProjectFilePathsState, (state) =>
	fromProjectFiles.projectFilePathSelectors.selectAll(state),
);

export const selectProjectFileById = createSelector(selectProjectFilesState, (state, props) => {
	const entities = fromProjectFiles.projectFilesSelectors.selectEntities(state);
	return entities[props.id] ? entities[props.id] : null;
});

export const selectProjectFilePathById = createSelector(
	selectProjectFilePathsEntityState,
	(entities, props) => {
		return entities[props.id] ? entities[props.id] : null;
	},
);

export const selectFilesInFilesSection = createSelector(
	selectAllCompanyFiles,
	selectAllProjectFiles,
	currentSelectedProject,
	selectUserProfileLimitsSelector,
	selectUserId,
	(companyFiles, projectFiles, currentProject, profileLimits, userId) => {
		return [
			...(!profileLimits || profileLimits?.companyTemplates
				? transformCompanyFilesToProjectFiles(companyFiles)
				: []),
			...transformFilesToProjectFiles(projectFiles, currentProject, userId),
		];
	},
);

/**
 * Company roles are more reliable then project roles. Therefore we use the company roles as long
 * as the project is a project of the user's company. Only for projects that the user is an external
 * member of we use the project role.
 *
 * @param companyRole
 * @param profile
 * @param project
 */
function getComputedProjectRoles(
	companyRole: MemberRole,
	profile: Profile,
	project: Project,
): MemberRole {
	if (profile.company === project.company) {
		return companyRole;
	}
	return getMembersOfProject(project).find((member) => member.email === profile.email).role;
}
