import { Directive, HostListener, Input } from '@angular/core';
import { isNil } from 'lodash';

@Directive({
	selector: '[appPatternRestrict][type=text]',
})
export class PatternRestrictDirective {
	@Input() pattern = '/.*/';
	@Input() attributes = '';

	@HostListener('keypress', ['$event'])
	onKeypress(event: KeyboardEvent): any {
		const currentValue = (event.target as HTMLInputElement).value;
		if (currentValue.length === 2) {
			(event.target as HTMLInputElement).value = '';
		}
		const regexToTest = new RegExp(this.pattern, this.attributes);
		const value = (event.target as HTMLInputElement).value + event.key;

		if (isNil(value) || !regexToTest.test(value)) {
			event.preventDefault();
		}
	}
}
