import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyFile } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';
import { DataQueryCondition } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class CompanyFileConnector {
	private static COLLECTION_NAME = 'companyFiles';

	constructor(private readonly connector: FirestoreConnector) {}

	watchCompanyFiles(companyId: string): Observable<EntityChanges<CompanyFile>> {
		const condition: DataQueryCondition<CompanyFile> = {
			field: 'companyId',
			operator: '==',
			value: companyId,
		};
		return this.connector.watchDocumentsChanges<CompanyFile>(CompanyFileConnector.COLLECTION_NAME, [
			condition,
		]);
	}
}
