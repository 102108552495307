import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileType } from 'domain-entities';
import { FileSectionOperation, ProjectFile } from '@shared/models/project-file.model';

@Component({
	selector: 'app-files-section-copy-or-move-header',
	templateUrl: './files-section-copy-or-move-header.component.html',
	styleUrls: ['./files-section-copy-or-move-header.component.scss'],
})
export class FilesSectionCopyOrMoveHeaderComponent {
	@Input() selectedFiles: ProjectFile[];
	@Input() filesOperation: FileSectionOperation;
	@Output() addFolder = new EventEmitter<void>();

	FileType = FileType;

	addNewFolder(event: Event): void {
		event.preventDefault();
		this.addFolder.emit();
	}
}
