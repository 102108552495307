<section class="task-edit-comments-section d-flex flex-column mh-100 p-3">
	<div class="d-flex align-items-center mb-3">
		<button (click)="openTaskEditDetails()" class="mr-3" mat-icon-button>
			<mat-icon class="back-btn-icon">arrow_back</mat-icon>
		</button>

		<div class="m-0 d-inline-flex mat-headline font-weight-bold">
			{{ 'my-tasks.comments.title' | translate }}
		</div>
	</div>

	<form (submit)="createNewComment(newCommentControl.value)" class="mb-2">
		<mat-form-field appearance="outline" class="form-field-outline w-100" floatLabel="always">
			<mat-label>{{ 'my-tasks.comments.add' | translate }}</mat-label>
			<textarea class="resize-none" rows="6" [formControl]="newCommentControl" matInput></textarea>
		</mat-form-field>

		<button [disabled]="newCommentControl.invalid" class="w-100" color="accent" mat-flat-button type="submit">
			{{ 'my-tasks.comments.send' | translate }}
		</button>
	</form>

	<hr class="mb-2" />

	<ul class="task-edit-comments-list list-unstyled m-0 flex-grow-1 overflow-auto">
		<li *ngFor="let taskCommentUI of taskCommentsUI$ | async">
			<div>
				<span class="font-weight-bold">{{ taskCommentUI.member | memberFullName }}</span> -
				{{ taskCommentUI.createdTimestamp | unixToMillis | date: 'dd.MM.yyyy HH:mm' }}
			</div>
			<p class="ml-3 mt-1">{{ taskCommentUI.comment }}</p>
		</li>
	</ul>
</section>
