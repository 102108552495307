import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-confirm-button',
	templateUrl: './confirm-button.component.html',
	styleUrls: ['./confirm-button.component.scss'],
})
export class ConfirmButtonComponent {
	@Input() locked: boolean;
	@Input() disabled = false;
	@Input() text: string;
	@Input() large: boolean;
	@Output() confirmed = new EventEmitter();

	onConfirm(): void {
		if (this.disabled || this.locked) {
			return;
		}
		this.confirmed.emit();
	}
}
