import { HttpHeaders } from '@angular/common/http';
import moment from 'moment';
import { Member } from 'domain-entities';
import { CompanyMember } from '../entities/company/company-member.model';
import { each, isArray, isDate, isObject, trim } from 'lodash';

export const removeSpaces = (str: string): string => {
	return str.trim().replace(/\s/g, '');
};

export const trimObjectStringValues = (obj: object): object => {
	const newObject = { ...obj };
	each(newObject, (value, key) => {
		if (value !== undefined) {
			if (isObject(value) && !isArray(value) && !isDate(value)) {
				newObject[key] = trimObjectStringValues(value);
			} else {
				newObject[key] = typeof value === 'string' ? trim(value) : value;
			}
		}
	});

	return newObject;
};

export const combineDateTime = (date: Date, time: Date) => {
	if (date instanceof Date && time instanceof Date) {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			time.getHours(),
			time.getMinutes(),
			time.getSeconds(),
		);
	}
	return new Date();
};

export function createApiTokenHeaders(token: string): HttpHeaders {
	return new HttpHeaders().set('Content-Type', 'application/json').set('api-key', token);
}

export const secondsPassed = (secondsToCompare: number) => (seconds: number) =>
	moment().diff(moment(seconds), 'seconds') > secondsToCompare;

export const getMemberFullName = (
	member: Member | CompanyMember,
	fallBackString: string = '',
	fallBackKey: keyof Member = 'email',
	isLastNameFirst = true,
): string => {
	if (!member) {
		return fallBackString || null;
	}

	let memberName = `${member.name || ''} ${member.lastname || ''}`.trim();

	if (isLastNameFirst) {
		memberName = `${member.lastname?.trim() || ''}${member.name?.trim() ? ', ' + member.name : ''}`;
	}

	if (memberName) {
		return memberName;
	}

	if (fallBackKey && member[fallBackKey]) {
		return member[fallBackKey];
	}

	return fallBackString;
};
