import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MultiSelectPanelComponent } from './multi-select-panel.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDividerModule } from '@angular/material/divider';
import { ShowConditionalModule } from '@modules/shared/directives/show-conditional/show-conditional.module';

@NgModule({
	declarations: [MultiSelectPanelComponent],
	imports: [
		MatInputModule,
		MatFormFieldModule,
		MatDividerModule,
		SharedModule,
		MatListModule,
		MatFormFieldModule,
		FormsModule,
		MatIconModule,
		MatButtonModule,
		ShowConditionalModule,
	],
	exports: [MultiSelectPanelComponent],
})
export class MultiSelectPanelModule {}
