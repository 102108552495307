export class AuthServiceError extends Error {
	constructor(public code: string) {
		super();
	}

	get message(): string {
		switch (this.code) {
			case 'auth/wrong-password': {
				return 'firebase-error.auth/wrong-password';
			}

			default: {
				return 'error.generic';
			}
		}
	}
}
