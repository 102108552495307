import { NgModule } from '@angular/core';
import { PaywallHeaderDialogComponent } from '@modules/shared/components/paywall-header-dialog/paywall-header-dialog.component';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [PaywallHeaderDialogComponent],
	imports: [SharedModule, MatIconModule, MatDialogModule, MatButtonModule],
	exports: [PaywallHeaderDialogComponent],
})
export class PaywallHeaderDialogModule {}
