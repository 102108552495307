<div class="manage-access-title" data-cy="manage-access-header" mat-dialog-title>
	<div class="d-flex flex-column">
		<div class="mb-1 mat-display-2">{{ 'explorer.menu.manageAccess' | translate }}</div>
		<div class="mt-2 mb-3 text-muted mat-body-1">{{ 'explorer.manageAccess.accessToText' | translate }} :</div>
	</div>
</div>
<mat-dialog-content>
	<form [formGroup]="accessForm">
		<div class="row manage-access-content">
			<div class="col-12 row no-gutters">
				<div class="col mat-body-2">{{ 'SUPERVISOR' | translate }}</div>
				<div class="col text-right">
					<mat-slide-toggle
						(change)="changePermissions(MemberRoles.SUPERVISOR, $event)"
						formControlName="{{ MemberRoles.SUPERVISOR }}"
						color="accent"
						data-cy="manage-access-supervisor"
					></mat-slide-toggle>
				</div>
				<mat-divider class="w-100 my-3"></mat-divider>
			</div>
			<div class="col-12 row no-gutters">
				<div class="col mat-body-2">{{ 'EMPLOYEE' | translate }}</div>
				<div class="col text-right">
					<mat-slide-toggle
						(change)="changePermissions(MemberRoles.EMPLOYEE, $event)"
						formControlName="{{ MemberRoles.EMPLOYEE }}"
						color="accent"
						data-cy="manage-access-employee"
					></mat-slide-toggle>
				</div>
				<mat-divider class="w-100 my-3"></mat-divider>
			</div>
			<div class="col-12 row no-gutters">
				<div class="col mat-body-2">{{ 'members.external' | translate }}</div>
				<div class="col text-right">
					<mat-slide-toggle
						(change)="changePermissions(MemberRoles.EXTERNAL, $event)"
						formControlName="{{ MemberRoles.EXTERNAL }}"
						color="accent"
						data-cy="manage-access-external"
					></mat-slide-toggle>
				</div>
				<mat-divider class="w-100 my-3"></mat-divider>
			</div>
			<div class="col-12 row no-gutters">
				<div class="col-9 mat-body-2">{{ 'explorer.manageAccess.public' | translate }}</div>
				<div class="col-3 text-right">
					<mat-slide-toggle
						(change)="changeFolderIsPublic($event)"
						formControlName="isPublic"
						color="accent"
						data-cy="enable-public-folder"
					></mat-slide-toggle>
				</div>
				<mat-divider class="w-100 my-3"></mat-divider>
			</div>
			<div *ngIf="folderIsPublic" class="w-100">
				<div class="container">
					<div class="row pt-2">
						<div class="shared-link col-12">
							{{ sharedLink }}
						</div>
					</div>
					<div (click)="copyLinkToClipboard()" class="row pointer align-items-center my-3">
						<mat-icon color="accent" class="col-auto mr-2">link</mat-icon>
						<strong class="share-text col-auto">
							<ng-template [ngIfElse]="copiedRef" [ngIf]="publicFolderLinkCopied">
								{{ 'explorer.manageAccess.copied' | translate }}
							</ng-template>
							<ng-template #copiedRef>
								{{ 'explorer.manageAccess.copy' | translate }}
							</ng-template>
						</strong>
					</div>
					<div [innerHTML]="'explorer.manageAccess.public-hint' | translate" class="craftnote-panel mat-body-1 mt-1 p-2"></div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button (click)="cancelHandler()" class="pull-right" data-cy="manage-access-cancel" mat-stroked-button>
		{{ 'explorer.manageAccess.cancel' | translate }}
	</button>
	<button
		[mat-dialog-close]="{ permissions: permissions, public: folderIsPublic }"
		class="pull-right"
		color="accent"
		[disabled]="accessForm.pristine"
		mat-flat-button
		data-cy="manage-access-update"
	>
		{{ 'explorer.manageAccess.update' | translate }}
	</button>
</mat-dialog-actions>
