import { AppVersion } from '@store/state/application-version.state';
import { AppState } from '@store/state/app.state';
import { updateApplicationVersionState } from '@store/actions/application-version.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { buildVersionPollingWorker } from '@utils/functions/web-worker/web-worker.functions';

@Injectable({
	providedIn: 'root',
})
export class VersionPollingService {
	private currentHash = '{{CURRENT_APP_HASH}}';
	private readonly workerProxy;

	constructor(private store: Store<AppState>) {
		this.workerProxy = buildVersionPollingWorker();

		this.initVersionPolling();
	}

	async initVersionPolling(): Promise<void> {
		const pollingWorker = await new this.workerProxy();
		const latestVersion: AppVersion = await pollingWorker.initPolling(this.currentHash);

		this.store.dispatch(
			updateApplicationVersionState({ appVersion: { ...latestVersion, isLatest: true } }),
		);
	}

	async isNewVersionAvailable(): Promise<boolean> {
		try {
			const pollingWorker = await new this.workerProxy();
			const latestVersion: AppVersion = await pollingWorker.getNewVersion();
			return latestVersion.hash !== this.currentHash;
		} catch (error) {
			return false;
		}
	}
}
