<div>
	<h2 mat-dialog-title>{{ title }}</h2>
	<mat-dialog-content>{{ content }}</mat-dialog-content>
	<mat-dialog-actions>
		<button *ngIf="!isInfoDialog" mat-button [mat-dialog-close]="null" data-cy="project-delete-no">
			{{ 'settings.dialog.no' | translate }}
		</button>
		<button *ngIf="!isInfoDialog" mat-button [mat-dialog-close]="'single'" data-cy="project-delete-yes">
			{{ 'settings.dialog.yes' | translate }}
		</button>
		<button *ngIf="isInfoDialog" mat-button [mat-dialog-close]="null">
			{{ 'button.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>
