import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileType, MemberRole } from 'domain-entities';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ManageAccessDialogComponent } from '../manage-access-dialog/manage-access-dialog.component';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { v4 as uuid } from 'uuid';
import { keys, sum, values } from 'lodash';
import {
	FileSectionOperation,
	ProjectFile,
	ProjectFileViewOptions,
} from '@shared/models/project-file.model';
import { AppState } from '@store/state/app.state';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { AlertService } from '@injectables/services/alert/alert.service';
import { BaseFileService } from '@injectables/services/base-file.service';
import {
	selectCurrentUserAsMemberInCurrentProject,
	selectIsProjectMemberOwner,
} from '@store/selectors/projects.selectors';
import { FileDocument } from '@craftnote/shared-utils';
import { isInternalProjectAdmin } from '@shared/functions/project/project.functions';
import { isPaidSubscriptionOfCurrentUser } from '@store/selectors/subscriptions.selectors';
import { ThemeService } from '@injectables/services/theme.service';

@Component({
	selector: 'app-files-section-files-list',
	templateUrl: './files-section-files-list.component.html',
	styleUrls: ['./files-section-files-list.component.scss'],
})
export class FilesSectionFilesListComponent implements OnInit {
	@Input() allFilesInSection: ProjectFile[];
	@Input() selectProjectFiles$: Observable<ProjectFile[]>;
	@Input() selectedProjectId: string;
	@Input() viewOption: ProjectFileViewOptions;
	@Input() isAllFilesSelected: boolean;
	@Input() isOneOfFolderSelected: boolean;
	@Input() selectedFiles: { [id: string]: ProjectFile } = {};
	@Input() filesOperation: FileSectionOperation;
	@Output() openFile = new EventEmitter<ProjectFile>();
	@Output() selectedFolder = new EventEmitter<ProjectFile>();
	@Output() selectedFile = new EventEmitter<ProjectFile>();
	@Output() deleteFile = new EventEmitter<ProjectFile>();
	@Output() copyFileToOtherProject = new EventEmitter<ProjectFile>();
	@Output() moveFile = new EventEmitter<ProjectFile>();
	@Output() copyFileWithInTheProject = new EventEmitter<ProjectFile>();
	@Output() copyFileToChat = new EventEmitter<ProjectFile>();
	@Output() copyFileToChatOtherProject = new EventEmitter<ProjectFile>();
	@Output() download = new EventEmitter<ProjectFile>();
	@Output() dropFile = new EventEmitter<string>();
	@Output() dragStartedEnded = new EventEmitter<boolean>();

	ignoreDestinationFolders = ['company-folder'];
	isDragStarted = false;
	fileHoveredId: string | null;
	hasPaidSubscription$ = this.store.select(isPaidSubscriptionOfCurrentUser);
	isOwnerOrInternalSupervisorOfProject$ = this.store
		.select(selectCurrentUserAsMemberInCurrentProject)
		.pipe(map(isInternalProjectAdmin));
	readonly FILE_TYPE = FileType;
	readonly PRO_PREMIUM_BADGE = 'PRO/PREMIUM';
	readonly MEMBER_ROLE = MemberRole;
	isOwnerAndMemberOfProject$: Observable<boolean>;
	themeSuffix$: Observable<string> = this.themeService.getThemeAsync().pipe(
		map((theme) => {
			return !!theme ? '' : '-dark';
		}),
	);

	constructor(
		private readonly store: Store<AppState>,
		private readonly dialog: MatDialog,
		private readonly fileService: FileExplorerService,
		private readonly alertService: AlertService,
		private readonly baseFileService: BaseFileService,
		private readonly themeService: ThemeService,
	) {}

	get selectedFilesLength(): number {
		return keys(this.selectedFiles).length;
	}

	get canDelete(): boolean {
		const selectedFilesList = values(this.selectedFiles);

		return selectedFilesList.length
			? values(selectedFilesList).every((file) => file.canDelete)
			: true;
	}

	isPermissionDotsEnabled$(file: ProjectFile): Observable<boolean> {
		if (
			!file.permissions ||
			file.fileCategory === 'company-file' ||
			this.folderAccessibleByAllRoles(file)
		) {
			return of(false);
		}
		return this.isOwnerAndMemberOfProject$.pipe(take(1));
	}

	getFilesInFolder(file): ProjectFile[] {
		return this.allFilesInSection.filter((fileItem) => fileItem.parentId === file.id);
	}

	selectFolder(file: ProjectFile): void {
		this.selectedFolder.emit(file);
	}

	selectFile(file: ProjectFile, event: Event): void {
		event.stopPropagation();
		this.selectedFile.emit(file);
	}

	copyToOtherProject(file: ProjectFile): void {
		this.copyFileToOtherProject.emit(file);
	}

	copyWithInProject(file: ProjectFile): void {
		this.copyFileWithInTheProject.emit(file);
	}

	moveWithInProject(file: ProjectFile): void {
		this.moveFile.emit(file);
	}

	copyToChat(file: ProjectFile): void {
		this.copyFileToChat.emit(file);
	}

	copyToChatOtherProject(file: ProjectFile): void {
		this.copyFileToChatOtherProject.emit(file);
	}

	openSingleFile(file: ProjectFile): void {
		this.openFile.emit(file);
	}

	onMouseEnter(file: ProjectFile): void {
		this.fileHoveredId = file.id;
	}

	onMouseLeave(): void {
		this.fileHoveredId = null;
	}

	onDropFiles(file: ProjectFile): void {
		if (file.fileCategory !== 'company-file' && this.isDragStarted) {
			this.dropFile.emit(file.id);
		}
	}

	onDragEnded(): void {
		setTimeout(() => {
			this.isDragStarted = false;
			this.dragStartedEnded.emit(this.isDragStarted);
		});
	}

	onDragStarted(): void {
		this.isDragStarted = true;
		this.dragStartedEnded.emit(this.isDragStarted);
	}

	selectFileOnClick(file: ProjectFile, event: Event): void {
		if (this.selectedFilesLength > 0 && file.fileCategory !== 'company-file') {
			this.selectFile(file, event);
		}
	}

	async manageFileAccess(projectFile: ProjectFile): Promise<void> {
		const file = new FileDocument().deserialize(projectFile.base);
		const permissions = file.permissions;
		const token = this.getOrGenerateToken(file);
		const dialogRef = this.dialog.open(ManageAccessDialogComponent, {
			data: { permissions, element: file, token },
			width: 'min(100%, 600px)',
			disableClose: true,
		});

		const dialogResult = await dialogRef.afterClosed().pipe(take(1)).toPromise();

		if (!dialogResult) {
			return;
		}

		const updatedPermissions = dialogResult?.permissions;
		await this.setFolderVisibility(dialogResult.public, file, token);
		try {
			await this.fileService.updateFilePermissions(file.id, updatedPermissions);
			this.alertService.showAlert('explorer.manageAccess.updateSuccess', { duration: 2000 });
		} catch (error) {
			this.alertService.showAlert('error.unknown', { duration: 1000 });
		}
	}

	async renameFileOrFolder(file: ProjectFile): Promise<void> {
		const element = new FileDocument().deserialize(file.base);
		let name = element.name;

		if (!this.baseFileService.isFolder(element)) {
			name = this.baseFileService.getFileExtOrName(element, false);
		}

		const dialogRef = this.dialog.open(RenameDialogComponent, {
			data: { name },
			width: '500px',
		});

		const res = await dialogRef.afterClosed().pipe(take(1)).toPromise();

		if (!res) {
			return;
		}

		if (this.baseFileService.isFolder(element)) {
			name = res;
		} else {
			name = res + '.' + this.baseFileService.getFileExtOrName(element);
		}

		await this.fileService.updateFileOrFolderById(file.id, { name });
	}

	async deleteElement(file: ProjectFile): Promise<void> {
		this.deleteFile.emit(file);
	}

	async ngOnInit(): Promise<void> {
		this.isOwnerAndMemberOfProject$ = this.store.select(selectIsProjectMemberOwner, {
			projectId: this.selectedProjectId,
		});
	}

	isFolderAccessibleByRole(folder: ProjectFile, role: MemberRole): boolean {
		if (!folder.permissions) {
			return true;
		}
		return folder.permissions.roles[role].length > 0;
	}

	noReturnPredicate(): boolean {
		return false;
	}

	private getOrGenerateToken(element: FileDocument): string {
		if (this.fileService.isFolderCurrentlyPublic(element)) {
			return element.share.token;
		}
		return uuid();
	}

	private async setFolderVisibility(
		folderIsPublic: boolean,
		element: FileDocument,
		token: string,
	): Promise<void> {
		if (folderIsPublic === undefined) {
			return;
		}

		if (folderIsPublic) {
			await this.fileService.makeFolderPublic(element, token);
		} else {
			await this.fileService.makeFolderPrivate(element);
		}
	}

	private folderAccessibleByAllRoles(folder: ProjectFile): boolean {
		if (!folder.permissions) {
			return false;
		}
		const relevantRoles = [MemberRole.SUPERVISOR, MemberRole.EMPLOYEE, MemberRole.EXTERNAL];
		const countAccessibleRoles = sum(
			relevantRoles.map((role) => this.isFolderAccessibleByRole(folder, role)),
		);

		return countAccessibleRoles === relevantRoles.length;
	}
}
