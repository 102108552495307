<div class="project-status-indicator">
	<svg
		*ngIf="statusId && (activateStrokeDashArray$ | async) as activeDashArray"
		class="project-status-svg"
		viewBox="0 0 120 120"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			[attr.r]="radius"
			[style.strokeDasharray]="strokeDasharray$ | async | strokeArray"
			[style.strokeWidth]="strokeWidth"
			class="project-status-meter"
			cx="60"
			cy="60"
		/>
		<circle
			[attr.r]="radius"
			[style.strokeDasharray]="activeDashArray | strokeArray"
			[style.strokeWidth]="strokeWidth"
			class="project-status-meter active"
			data-cy="project-list-status"
			cx="60"
			cy="60"
		/>
	</svg>
	<div [ngClass]="colorTagClass" class="project__name text-center">
		{{ name?.charAt(0).toUpperCase() }}
	</div>
</div>
