import { Injectable } from '@angular/core';
import { NotificationsConnector } from '@injectables/connectors/notifications.connector';
import { interval } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { shareReplayOne } from '@craftnote/shared-utils';
import { ResourceType } from 'domain-entities';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NotificationCenterService } from '@modules/shared/components/notification-center/notification-center.service';
const POLLING_INTERVAL = 30000;

@Injectable()
export class NotificationCenterPollingService {
	isUnreadNotificationCenterPolling$ = interval(POLLING_INTERVAL).pipe(
		switchMap(() => this.afAuth.authState),
		filter(Boolean),
		switchMap(() =>
			this.notificationsConnector.getNotifications(undefined, {
				unread: true,
				resourceTypes: [
					ResourceType.PROJECT,
					ResourceType.TASK,
					ResourceType.CHAT_MESSAGE,
					ResourceType.FILE,
				],
			}),
		),
		tap((notificationResponse) =>
			this.notificationCenterService.updateNotificationUnreadCount(notificationResponse),
		),
		map((notificationResponse) => notificationResponse.counts.unread > 0),
		shareReplayOne(),
	);

	constructor(
		private readonly notificationsConnector: NotificationsConnector,
		private readonly afAuth: AngularFireAuth,
		private readonly notificationCenterService: NotificationCenterService,
	) {}
}
