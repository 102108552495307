import { createAction, props } from '@ngrx/store';
import { AppTheme } from '@store/reducers/app.reducer';

export const initAppAction = createAction('[App] Init app');

export const setSidebarStateAction = createAction(
	'[App] Set sidebar State',
	props<{ state: boolean }>(),
);

export const setNavbarStateAction = createAction(
	'[App] Set navbar State',
	props<{ state: boolean }>(),
);

export const setThemeStateAction = createAction(
	'[App] Set theme State',
	props<{ state: AppTheme }>(),
);
