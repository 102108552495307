import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingAddressModel } from '../../billing-address.model';
import { EMAIL_PATTERN } from '@constants/validators.constants';

@Component({
	selector: 'app-project-billing-address',
	templateUrl: './project-billing-address.component.html',
	styleUrls: ['./project-billing-address.component.scss'],
})
export class ProjectBillingAddressComponent implements OnChanges {
	@Output() onBack: EventEmitter<any> = new EventEmitter();
	@Output() onSave: EventEmitter<BillingAddressModel> = new EventEmitter();

	billingFormGroup: UntypedFormGroup;
	@Input() modifiable;
	@Input() billingAddress: BillingAddressModel;

	constructor(private fb: UntypedFormBuilder) {
		this.billingFormGroup = this.fb.group({
			companyName: [],
			companyEmail: ['', Validators.pattern(EMAIL_PATTERN)],
			companyAddressStreet: [],
			companyAddressZipCode: [],
			companyAddressCity: [],
			companyAddressCountry: [],
		});
	}

	ngOnChanges(): void {
		if (!this.modifiable) {
			this.billingFormGroup.disable();
		}
		this.updateFormValues();
	}

	updateFormValues(): void {
		const newFormModel = {
			companyName: this.billingAddress.billingName || '',
			companyEmail: this.billingAddress.billingEmail || '',
			companyAddressStreet: this.billingAddress.billingStreet || '',
			companyAddressZipCode: this.billingAddress.billingZipcode || '',
			companyAddressCity: this.billingAddress.billingCity || '',
			companyAddressCountry: this.billingAddress.billingCountry || '',
		};
		this.billingFormGroup.setValue(newFormModel);
	}

	save(): void {
		const billingAddress = new BillingAddressModel();
		billingAddress.billingName = this.billingFormGroup.controls['companyName'].value;
		billingAddress.billingEmail = this.billingFormGroup.controls['companyEmail'].value;
		billingAddress.billingStreet = this.billingFormGroup.controls['companyAddressStreet'].value;
		billingAddress.billingZipcode = this.billingFormGroup.controls['companyAddressZipCode'].value;
		billingAddress.billingCity = this.billingFormGroup.controls['companyAddressCity'].value;
		billingAddress.billingCountry = this.billingFormGroup.controls['companyAddressCountry'].value;
		this.onSave.emit(billingAddress);
	}

	goBack(): void {
		this.onBack.emit();
	}
}
