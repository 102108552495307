import {
	addSeconds,
	differenceInDays,
	differenceInSeconds,
	isAfter,
	isBefore,
	isWithinInterval,
	startOfDay,
	subMilliseconds,
} from 'date-fns';
import { UntypedFormGroup } from '@angular/forms';
import {
	TrackedTimeAddEditErrors,
	TrackedTimeAddEditForm,
} from '../../types/tracked-time-add-edit-form.types';

// Private helper functions
function isValidBreak(breakItem: any): boolean {
	return breakItem.start && breakItem.end;
}

function isBreakWithinEntryTime(breakItem: any, startTime: Date, endTime: Date): boolean {
	return isWithinInterval(breakItem.start, { start: startTime, end: endTime }) ||
		   isWithinInterval(breakItem.end, { start: startTime, end: endTime });
}

function validateBreakTiming(breakItem: any, startTime: Date, endTime: Date): TrackedTimeAddEditErrors | null {
	if (isAfter(breakItem.end, endTime)) {
		return TrackedTimeAddEditErrors.BREAK_ENDS_AFTER_ENTRY;
	}
	if (isBefore(breakItem.start, startTime)) {
		return TrackedTimeAddEditErrors.BREAK_STARTS_BEFORE_ENTRY;
	}
	if (isAfter(breakItem.start, breakItem.end)) {
		return TrackedTimeAddEditErrors.BREAK_START_AFTER_END;
	}
	return null;
}

export function getPauseStart(trackedTimeForm: TrackedTimeAddEditForm): Date {
	if (
		!(trackedTimeForm.startTime && trackedTimeForm.endTime && trackedTimeForm.pause?.start) ||
		trackedTimeForm.startTime > trackedTimeForm.endTime
	) {
		return null;
	}
	/**
	 * The selected pause start represented as seconds since midnight
	 */
	const pauseSecondsSinceMidnight = differenceInSeconds(
		trackedTimeForm.pause.start,
		startOfDay(trackedTimeForm.pause.start),
	);

	/**
	 * There are (at maximum) two possible pause start dates:
	 * One referencing the day of the tracked time's start and one referencing the day of its end.
	 * Since we assume a maximum tracked time length of 24h there are no other possibilities.
	 */
	const pauseTimeStartCandidates = [trackedTimeForm.startTime, trackedTimeForm.endTime].map(
		(baseTime) => addSeconds(startOfDay(baseTime), pauseSecondsSinceMidnight),
	);

	/**
	 * We check all candidates whether they are within the tracked times interval and return t
	 */
	return (
		pauseTimeStartCandidates.find((pauseTimeStartCandidate) =>
			isWithinInterval(pauseTimeStartCandidate, {
				start: trackedTimeForm.startTime,
				end: trackedTimeForm.endTime,
			}),
		) ?? null
	);
}

export function pauseValidator(trackedTimeForm: UntypedFormGroup): any {
	const formValue: TrackedTimeAddEditForm = trackedTimeForm.value;
	const { startTime, endTime, pause } = formValue;
	const breaks = pause?.breaks || [];

	if (!breaks.length || !startTime || !endTime || isAfter(startTime, endTime)) {
		return undefined;
	}

	breaks.sort((a, b) => a.start?.getTime() - b.start?.getTime());

	let previousBreakEnd = startTime;

	for (const breakItem of breaks) {
		if (!isValidBreak(breakItem)) continue;

		if (!isBreakWithinEntryTime(breakItem, startTime, endTime)) {
			return { error: TrackedTimeAddEditErrors.BREAK_OUTSIDE_ENTRY };
		}

		const timingError = validateBreakTiming(breakItem, startTime, endTime);
		if (timingError) {
			return { error: timingError };
		}

		if (!isBefore(breakItem.start, previousBreakEnd)) {
			previousBreakEnd = breakItem.end;
		} else {
			return { error: TrackedTimeAddEditErrors.OVERLAPPING_BREAKS };
		}
	}

	return undefined;
}

export function trackedTimeValidator(trackedTimeForm: UntypedFormGroup): any {
	const formValue: TrackedTimeAddEditForm = trackedTimeForm.value;
	const start = formValue.startTime;
	const end = formValue.endTime;

	if (isAfter(start, end)) {
		return { error: TrackedTimeAddEditErrors.ENTRY_START_AFTER_END };
	}

	if (differenceInDays(subMilliseconds(end, 1), start) > 0) {
		return { error: TrackedTimeAddEditErrors.ENTRY_TIME_MORE_GREATER_24_HOURS };
	}

	const pauseValidationResult = pauseValidator(trackedTimeForm);
	if (pauseValidationResult) {
		return pauseValidationResult;
	}

	return null;
}
