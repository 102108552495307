import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	mediaMessageSelectors,
	MessageEntityState,
	messagesFeatureKey,
	selectAll,
	selectEntities,
	selectIds,
} from '../reducers/message.reducer';
import { Message, MessageType } from 'domain-entities';
import { chain, last } from 'lodash';
import moment from 'moment';
import { selectUserId } from '@store/selectors/app.selectors';

export const selectMessageState = createFeatureSelector<MessageEntityState>(messagesFeatureKey);

export const selectMediaMessageState = createSelector(selectMessageState, (state) => state.media);

export const selectAllMessages = createSelector(selectMessageState, selectAll);

export const selectMessagesEntities = createSelector(selectMessageState, selectEntities);

export const selectMessageById = (id: string) =>
	createSelector(selectMessagesEntities, (messagesEntities) => messagesEntities[id]);

export const selectAllMessageIds = createSelector(selectMessageState, selectIds);

export const selectAllVisibleMessages = createSelector(
	selectAllMessages,
	selectUserId,
	(allMessages, userId) => allMessages.filter((message) => isVisibleMessage(message, userId)),
);

export const selectAllMediaMessages = createSelector(
	selectMediaMessageState,
	mediaMessageSelectors.selectAll,
);

export const selectMediaMessagesLoadingStatus = createSelector(
	selectMediaMessageState,
	(state) => state.loadingStatus,
);

export const selectLastVisibleMessage = createSelector(selectAllVisibleMessages, (messages) =>
	last(messages),
);

export function isVisibleMessage(message: Message, userId: string): boolean {
	const isOfTextType = message.messageType === MessageType.TEXT;
	const isNotOfTypeNotset = message.content !== MessageType.NOTSET;
	const isOwner = message.authorId === userId;
	return isOwner || isOfTextType || isNotOfTypeNotset;
}

export function isNotStatusMessage(message: Message): boolean {
	if (message.deleted) {
		return false;
	}

	return ![
		MessageType.TASK_ASSIGNEE_CHANGED,
		MessageType.TASK_CHANGED,
		MessageType.TASK_COMPLETED,
		MessageType.TASK_CREATED,
		MessageType.STATUS,
	].includes(message.messageType);
}

export const selectMessagesWithoutTasks = createSelector(selectAllVisibleMessages, (allMessages) =>
	allMessages.filter(isNotStatusMessage),
);

export const selectAllVideos = createSelector(selectAllVisibleMessages, (allMessages) =>
	allMessages.filter((message) => !message.deleted && message.messageType === MessageType.VIDEO),
);

export const selectAllImages = createSelector(selectAllVisibleMessages, (allMessages) =>
	allMessages.filter((message) => !message.deleted && message.messageType === MessageType.IMAGE),
);

export const selectAllDocuments = createSelector(selectAllVisibleMessages, (allMessages) =>
	allMessages.filter((message) => !message.deleted && message.messageType === MessageType.DOCUMENT),
);

export const selectMessagesUIState = createSelector(
	selectMessageState,
	(messagesState) => messagesState.UIState,
);

export const selectSearchOptions = createSelector(
	selectMessageState,
	(messagesState) => messagesState.searchOptions,
);

export const selectLastMessageId = createSelector(
	selectMessageState,
	(messageState) => messageState.ids[0],
);

export const messagesLoading = createSelector(selectMessagesUIState, (messageState) => {
	return messageState.isLoading;
});

export const messagesHasEarlier = createSelector(
	selectMessagesUIState,
	(messages) => messages.hasEarlierMessages,
);

export const messagesSearchEnabled = createSelector(
	selectSearchOptions,
	(searchOptions) => !!Object.keys(searchOptions).length,
);

export const selectSearchText = createSelector(
	selectSearchOptions,
	(searchOptions) => searchOptions.text,
);

export const messagesFilterEnabled = createSelector(
	selectMessagesUIState,
	(messageState) => messageState.isFilterEnabled,
);

export const messageDates = createSelector(selectAllVisibleMessages, (messages) =>
	chain(messages)
		.groupBy((message) => moment.unix(message.timestamp).format('YYYY-MM-DD'))
		.mapValues((groupedMessages) => groupedMessages[0].id)
		.invert()
		.value(),
);

export const messagePdfExportEnabled = createSelector(
	selectMessagesUIState,
	(messages) => messages.isPdfExportEnabled,
);

export const selectReplyMessage = createSelector(
	selectMessagesUIState,
	(messageUIState) => messageUIState.replyMessage,
);

export const selectedParentMessage = createSelector(
	selectMessagesUIState,
	(messageUIState) => messageUIState.selectedParentId,
);

export const selectChatMessageToShare = createSelector(
	selectMessagesUIState,
	(messageUIState) => messageUIState.selectedChatMessageToShare,
);

export const selectInputCacheOfProject = (projectId: string) =>
	createSelector(selectMessageState, (messageState) => messageState.chatInputCache[projectId]);
