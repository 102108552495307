<section class="add-bulk-company-employees-section flex-scroll flex-column">
	<p class="hint-text p-3 rounded"><span class="font-weight-bold">Info:</span> {{ 'add-bulk-company-employees.info' | translate }}</p>

	<div class="d-flex mb-3 font-weight-bold">
		<div class="flex-1 mr-2">{{ 'add-bulk-company-employees.form-heading.email' | translate }} <sup class="text-primary">*</sup></div>

		<div class="flex-1 mr-2">{{ 'add-bulk-company-employees.form-heading.job-title' | translate }}</div>

		<div class="flex-1">
			{{ 'add-bulk-company-employees.form-heading.role-title' | translate }}<sup class="text-primary">*</sup>
			<a class="font-weight-normal text-decoration-underline text-primary ml-2" href="" (click)="openRolesDialog()">{{
				'add-bulk-company-employees.form-heading.role-whatisthis' | translate
			}}</a>
		</div>

		<mat-icon class="ml-2 invisible">delete_outline</mat-icon>
	</div>

	<div class="flex-scroll__container">
		<div #scrollContainer class="flex-scroll__container__scrollable-content pr-2">
			<div *ngFor="let inviteUserForm of inviteUserForms; let firstInviteForm = first; let i = index">
				<form class="d-flex" [formGroup]="inviteUserForm" autocomplete="off">
					<div class="flex-1 mr-2">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{ 'add-bulk-company-employees.form-heading.form-label.email' | translate }}</mat-label>
							<input matInput type="email" data-cy="register-add-company-employee-email" formControlName="email" />
							<mat-error *ngIf="getFormControlError(i, 'email')?.required">{{
								'add-bulk-company-employees.form-heading.required.email' | translate
							}}</mat-error>
							<mat-error *ngIf="getFormControlError(i, 'email')?.pattern">{{
								'add-bulk-company-employees.form-heading.pattern.email' | translate
							}}</mat-error>
						</mat-form-field>
					</div>

					<div class="flex-1 mr-2">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{ 'add-bulk-company-employeesform-heading.form-label.jobtitle' | translate }}</mat-label>
							<input matInput type="text" data-cy="register-add-company-employee-job" formControlName="jobTitle" />

							<mat-error *ngIf="getFormControlError(i, 'jobTitle')?.pattern">{{
								'add-bulk-company-employees.form-heading.pattern.jobtitle' | translate
							}}</mat-error>
						</mat-form-field>
					</div>

					<div class="flex-1">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{ 'add-bulk-company-employees.form-heading.form-label.role' | translate }}</mat-label>
							<mat-select formControlName="memberRole">
								<mat-option [value]="MemberRole.EMPLOYEE" data-cy="register-add-company-employee-role">{{
									'global_roles_employee' | translate
								}}</mat-option>
								<mat-option [value]="MemberRole.SUPERVISOR" data-cy="register-add-company-supervisor-role">{{
									'global_roles_supervisor' | translate
								}}</mat-option>
								<mat-option [value]="MemberRole.OWNER" data-cy="register-add-company-owner-role">{{
									'global_roles_owner' | translate
								}}</mat-option>
							</mat-select>
							<mat-icon color="accent" data-cy="register-add-company-employee-role-combo" matSuffix> keyboard_arrow_down </mat-icon>
						</mat-form-field>
					</div>

					<mat-icon
						class="mt-3 ml-2 pointer"
						data-cy="register-add-company-remove-employee-invite"
						(click)="removeInviteUserForm(i)"
						[class.invisible]="firstInviteForm"
						>delete_outline
					</mat-icon>
				</form>
			</div>
		</div>
	</div>

	<p [class.invisible]="!formHasDuplicateEmails" class="text-warn m-0">
		{{ 'add-bulk-company-employees.form-errors.duplicate-emails' | translate }}
	</p>

	<div class="mt-2">
		<button mat-button color="accent" data-cy="register-add-company-employee-button" (click)="addEmptyInviteUserForm()">
			<mat-icon [size]="24">add</mat-icon>
			{{ 'add-bulk-company-employees.form.add-row' | translate }}
		</button>
	</div>

	<div class="text-center">
		<button
			mat-flat-button
			color="accent"
			data-cy="register-add-company-invite-employee-submit-button"
			[disabled]="!isSubmitButtonEnabled"
			(click)="onPressSubmitButton()"
		>
			{{ 'add-bulk-company-employees.form.submit-button' | translate }}
		</button>
	</div>
</section>
