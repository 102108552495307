import { Injectable } from '@angular/core';
import { OptimusStripeConnector } from '@shared/firebase/connectors/firestore/collections/optimus-stripe/optimus-stripe.connector';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OptimusStripeGuard {
	constructor(
		private readonly optimusStripeConnector: OptimusStripeConnector,
		private readonly store: Store<AppState>,
	) {}

	async canMatch(route: ActivatedRouteSnapshot): Promise<boolean> {
		const companyId$ = this.store.select(selectCompanyId);
		const companyId = await firstValueFrom(companyId$);
		const phase = await this.optimusStripeConnector.getRolloutPhase(companyId);
		const match = route.data.phase;
		return phase === match;
	}
}
