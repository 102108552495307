<section class="task-edit-working-hours-update-section d-flex flex-column h-100 position-relative">
	<div class="p-3">
		<div class="d-flex">
			<div class="d-flex align-items-center mb-3">
				<button (click)="openWorkingHours.emit()" class="mr-3" mat-icon-button>
					<mat-icon class="back-btn-icon">arrow_back</mat-icon>
				</button>

				<div class="m-0 d-inline-flex mat-headline font-weight-bold">
					{{ 'task-edit-working-hours-update.title' | translate }}
				</div>
			</div>

			<div class="ml-auto">
				<button (click)="onDeleteWorkingHours(workingHourId)" mat-icon-button>
					<mat-icon>delete_outline</mat-icon>
				</button>
			</div>
		</div>
		<form (submit)="updateWorkingHours()" [formGroup]="updateWorkingHoursForm">
			<div class="d-flex">
				<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" floatLabel="always" color="accent">
					<mat-label>{{ 'dashboard.timeTracking.filters.startDate' | translate }}</mat-label>
					<input [matDatepicker]="startDatePickerRef" formControlName="startDate" matInput readonly (click)="startDatePickerRef.open()" />
					<mat-datepicker-toggle [for]="startDatePickerRef" matSuffix></mat-datepicker-toggle>
					<mat-datepicker color="accent" #startDatePickerRef></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
					<mat-label>{{ 'task-edit-working-hours.start-time' | translate }}</mat-label>
					<app-time-picker [max]="endDateTime" formControlName="startTime"></app-time-picker>
				</mat-form-field>
			</div>

			<div class="d-flex">
				<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" floatLabel="always" color="accent">
					<mat-label>{{ 'dashboard.timeTracking.filters.endDate' | translate }}</mat-label>
					<input [matDatepicker]="endDatePickerRef" formControlName="endDate" matInput readonly (click)="endDatePickerRef.open()" />
					<mat-datepicker-toggle [for]="endDatePickerRef" matSuffix></mat-datepicker-toggle>
					<mat-datepicker color="accent" #endDatePickerRef></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
					<mat-label>{{ 'task-edit-working-hours.end-time' | translate }}</mat-label>
					<app-time-picker formControlName="endTime"></app-time-picker>
				</mat-form-field>
			</div>

			<button [disabled]="updateWorkingHoursFormInvalid" class="w-100" color="accent" mat-flat-button type="submit">
				{{ 'task-edit-working-hours-update.action-btn' | translate }}
			</button>
		</form>
	</div>
</section>
