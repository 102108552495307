import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'domain-entities';
import { ThemeService } from '@injectables/services/theme.service';
import { AppTheme } from '@store/reducers/app.reducer';

@Component({
	selector: 'app-color-picker',
	templateUrl: './color-picker.component.html',
})
export class ColorPickerComponent implements OnInit {
	@Input() isOwnerOrSupervisor: boolean;
	@Input() project: Project;
	@Input() projectColorTag: string;
	@Output() projectColorTagChange = new EventEmitter<string>();

	themeIsDark = false;

	constructor(private themeService: ThemeService) {}

	async ngOnInit(): Promise<void> {
		this.themeIsDark = (await this.themeService.getTheme()) === AppTheme.DARK;
		this.themeService.getThemeAsync().subscribe((theme: number) => {
			this.themeIsDark = theme === AppTheme.DARK;
		});
	}

	getTagClass(tag): string {
		if (tag === this.projectColorTag && this.themeIsDark) {
			return 'tag-active-dark';
		}

		if (tag === this.projectColorTag && !this.themeIsDark) {
			return 'tag-active-light';
		}

		return '';
	}

	changeActiveTag(activeTag): void {
		this.projectColorTag = activeTag;
		this.projectColorTagChange.emit(this.projectColorTag);
	}
}
