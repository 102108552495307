import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Observable } from 'rxjs';
import { isProduct, Product } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class ProductsConnector {
	static readonly COLLECTION_NAME = 'products';

	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	watchProducts(): Observable<Product[]> {
		return this.firestoreConnector.watchDocuments<Product>(
			ProductsConnector.COLLECTION_NAME,
			[],
			isProduct,
		);
	}
}
