import { createAction, props } from '@ngrx/store';
import { ProfileLimit } from 'domain-entities';
import { HttpErrorResponse } from '@angular/common/http';

export const subscribeCurrentUserProfileLimits = createAction(
	'[Current User Profile Limits] Subscribe user profile limits',
);

export const updateCurrentUserProfileLimitsSuccess = createAction(
	'[Current User Profile Limits] Update user profile limits success',
	props<{ payload: ProfileLimit }>(),
);

export const updateCurrentUserProfileLimitsFail = createAction(
	'[Current User Profile Limits] Update user profile limits fail',
	props<{ payload: HttpErrorResponse }>(),
);

export const loadCompanyMembersProfileLimits = createAction(
	'[Company Members Profile Limits] Load company members profile limits',
);

export const loadCompanyMembersProfileLimitsSuccess = createAction(
	'[Company Members Profile Limits] Load company members profile limits success',
	props<{ payload: ProfileLimit[] }>(),
);

export const clearProfileLimits = createAction('[Profile Limits] Clear profile Limits');
