import { NgModule } from '@angular/core';
import { EmptyStateComponent } from './empty-state.component';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [EmptyStateComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule],
	exports: [COMPONENT],
})
export class EmptyStateModule {}
