import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface TrackedTimeChange {
	changedBy: string;
	changedAt: number;
	changes: string[];
}

export interface AuditLogQueryParameters {
	locale: string;
	resourceType: 'trackedTime';
	resourceId: string;
	timezone: string;
}

@Injectable({ providedIn: 'root' })
export class AuditLogConnector {
	private cloudFunction = this.angularFireFunctions.httpsCallable('getAuditLog');

	constructor(private readonly angularFireFunctions: AngularFireFunctions) {}

	getAuditLog(parameters: AuditLogQueryParameters): Observable<TrackedTimeChange[]> {
		return this.cloudFunction(parameters).pipe(map((changes) => changes.auditLog));
	}
}
