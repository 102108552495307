import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	selectSubscriptionsLoadingStatus,
	selectSubscriptionsOfTypeSubscription,
} from '@store/selectors/subscriptions.selectors';
import { filter, take } from 'rxjs/operators';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

@Injectable({ providedIn: 'root' })
export class CompanyHasNoSubscriptionGuard {
	constructor(private readonly store: Store, private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		await this.store
			.select(selectSubscriptionsLoadingStatus)
			.pipe(
				filter((status) => status === StoreLoadingStatus.LOADED),
				take(1),
			)
			.toPromise();

		const existingSubscriptions = await this.store
			.select(selectSubscriptionsOfTypeSubscription)
			.pipe(take(1))
			.toPromise();

		// No subscription exists so navigation can proceed
		if (!existingSubscriptions.length) {
			return true;
		}
		// Subscription exists so navigation has go to to default page
		if (route.data.mobile) {
			return this.router.parseUrl('mobile-page-blocked');
		} else {
			return this.router.parseUrl('projects');
		}
	}
}
