import { Injectable } from '@angular/core';
import { InvitationLink } from '@store/state/invitations.state';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectUserId } from '@store/selectors/app.selectors';
import { InvitationsConnector } from '@shared/firebase/connectors/firestore/collections/invitations/invitations.connector';
import { ConfirmDialogConfig } from '@craftnote/material-theme';
import {
	ConfirmationDialogTranslatedService,
	InterpolationValuesModel,
} from './confirmation-dialog-translated.service';

@Injectable({
	providedIn: 'root',
})
export class InvitationDialogService {
	private invitationConfirmDialogTemplate: ConfirmDialogConfig = {
		primaryButtonText: 'invitations.dialog.close',
		showCrossBtn: false,
		showSecondaryButton: false,
	};

	private remindResetConfirmDialogTemplate: ConfirmDialogConfig = {
		primaryButtonText: 'invitations.resend-confirm-dialog.yes-btn',
		secondaryButtonText: 'invitations.resend-confirm-dialog.no-btn',
		primaryButtonColor: 'accent',
	};

	constructor(
		private readonly invitationsConnector: InvitationsConnector,
		private readonly store: Store<AppState>,
		private readonly confirmationDialogTranslatedService: ConfirmationDialogTranslatedService,
	) {}

	private async openConfirmDialog(
		dialogConfig: ConfirmDialogConfig,
		interpolationValues?: InterpolationValuesModel,
	): Promise<boolean> {
		return (await this.confirmationDialogTranslatedService.open(dialogConfig, interpolationValues))
			.afterClosed()
			.pipe(take(1))
			.toPromise();
	}

	handleRemindInvitationDialog(): Promise<boolean> {
		const dialogConfig: ConfirmDialogConfig = {
			title: 'invitations.remind-confirm-dialog.body',
			...this.remindResetConfirmDialogTemplate,
			primaryButtonColor: 'accent',
			primaryButtonText: 'invitations.resend-confirm-dialog.yes-btn',
		};

		return this.openConfirmDialog(dialogConfig);
	}

	handleResendInvitationDialog(): Promise<boolean> {
		const dialogConfig: ConfirmDialogConfig = {
			title: 'invitations.resend-confirm-dialog.body',
			...this.remindResetConfirmDialogTemplate,
		};

		return this.openConfirmDialog(dialogConfig);
	}

	handleCompanyInvitationDialog(companyName: string): Promise<boolean> {
		const dialogConfig: ConfirmDialogConfig = {
			title: 'invitations.confirm-dialog.title',
			message: 'invitations.confirm-dialog.sub-title',
			contentHint: 'invitations.confirm-dialog.hint',
			primaryButtonColor: 'warn',
			showCrossBtn: false,
			primaryButtonText: 'invitations.remind-confirm-dialog.yes-btn',
			secondaryButtonText: 'invitations.remind-confirm-dialog.no-btn',
		};

		const interpolationValues = {
			title: {
				companyName: companyName,
			},
		};

		return this.openConfirmDialog(dialogConfig, interpolationValues);
	}

	async isInvitationPreConditionFailed(invitationLink: InvitationLink): Promise<boolean> {
		const invitationsExpiredDialogConfig: ConfirmDialogConfig = {
			title: 'invitations.expired-dialog.title',
			message: 'invitations.expired-dialog.sub-title',
			...this.invitationConfirmDialogTemplate,
		};

		const invitationsExpiredDialogInterpolationValues = {
			message: {
				companyName: invitationLink.companyName,
			},
		};

		if (!invitationLink.expirationDate || moment().unix() > invitationLink.expirationDate) {
			await this.openConfirmDialog(
				invitationsExpiredDialogConfig,
				invitationsExpiredDialogInterpolationValues,
			);

			return true;
		}

		const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const invitation = await this.invitationsConnector.getInvitationById(
			invitationLink.invitationId,
		);

		if (userId === invitation.creatorId) {
			const invitationUserMatchesDialogConfig: ConfirmDialogConfig = {
				title: 'invitations.user-match-dialog.title',
				message: 'invitations.user-match-dialog.sub-title',
				...this.invitationConfirmDialogTemplate,
			};

			const interpolationValues = {
				message: {
					companyName: invitationLink.companyName,
				},
			};

			await this.openConfirmDialog(invitationUserMatchesDialogConfig, interpolationValues);

			return true;
		}

		if (moment().unix() > invitation.expirationDate) {
			await this.openConfirmDialog(
				invitationsExpiredDialogConfig,
				invitationsExpiredDialogInterpolationValues,
			);

			return true;
		}

		if (['declined', 'accepted'].includes(invitation.state)) {
			const invitationsUsedDialogConfig: ConfirmDialogConfig = {
				title: 'invitations.used-link-dialog.title',
				message: 'invitations.used-link-dialog.sub-title',
				...this.invitationConfirmDialogTemplate,
			};

			const interpolationValues = {
				message: {
					companyName: invitationLink.companyName,
				},
			};

			await this.openConfirmDialog(invitationsUsedDialogConfig, interpolationValues);

			return true;
		}

		return false;
	}
}
