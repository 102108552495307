import { Action, createReducer, on } from '@ngrx/store';
import { AppInitState, AppInitStatus } from '../state/app-init.state';
import { setAppInitState } from '../actions/app-init.actions';

const initialState: AppInitState = {
	state: AppInitStatus.BEFORE,
};

const appInitCreator = createReducer(
	initialState,
	on(setAppInitState, (appInitState, { state }) => {
		return {
			...appInitState,
			state,
		};
	}),
);

// tslint:disable-next-line:typedef
export function appInitReducer(state: AppInitState, action: Action) {
	return appInitCreator(state, action);
}
