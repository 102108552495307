export enum PerformanceTrace {
	TIMES_DASHBOARD_INITIAL_LOAD = 'timesDashboard_initial_loaded',
	TIMES_DASHBOARD_ANY_LOAD = 'timesDashboard_any_loaded',
	COLLECTION_CHAT_MESSAGES_LOADED = 'collection_chatMessages_loaded',
	COLLECTION_FILES_LOADED = 'collection_files_loaded',
	COLLECTION_PROJECTS = 'collection_projects_loaded',

	TRANSACTION_NEW_CHAT_LOADED = 'transaction_newChat_loaded',
}

export enum PerformanceTraceConstants {
	PROJECT_COUNT = 'projectCount',
	FILE_COUNT = 'fileCount',
	MESSAGE_COUNT = 'messageCount',
}
