import { Injectable } from '@angular/core';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectCompany } from '@store/selectors/app.selectors';
import { filter, take } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { Company } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class TocService {
	constructor(
		private readonly remoteConfig: RemoteConfig,
		private readonly store: Store<AppState>,
	) {}

	async isFeatureEnabled(): Promise<boolean> {
		return this.remoteConfig.getBooleanAsync('feature_company_toc_warning');
	}

	async getDueDate(): Promise<Moment> {
		const dueDate = await this.remoteConfig.getValueAsync('company_toc_deadline');
		return moment.unix(dueDate || 0);
	}

	async getCompanyStatus(): Promise<'accepted' | 'declined'> {
		const company = await this.store
			.select(selectCompany)
			.pipe(filter<Company>(Boolean), take(1))
			.toPromise();
		return company?.toc?.status;
	}

	async isBeyondDueDate(): Promise<boolean> {
		const dueDate = await this.getDueDate();
		return dueDate.isBefore(moment());
	}

	async isCompanyAccepted(): Promise<boolean> {
		const [isFeatureEnabled, tocStatus] = await Promise.all([
			this.isFeatureEnabled(),
			this.getCompanyStatus(),
		]);

		return isFeatureEnabled && tocStatus === 'accepted';
	}
}
