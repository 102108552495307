import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MobileGuard {
	constructor(private auth: AuthService, private router: Router) {}

	canActivate(): boolean {
		if (
			this.auth.isUserLoggedIn() ||
			!/iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent)
		) {
			this.router.navigate(['projects']);
			return false;
		}

		return true;
	}
}
