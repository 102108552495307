import { Serializable } from '@craftnote/shared-utils';

export class Message implements Serializable<Message> {
	constructor(
		public author?: string,
		public authorId?: string,
		public content?: string,
		public fileSize?: number,
		public id?: string,
		public latitude?: number,
		public longitude?: number,
		public messageType?: MESSAGE_TYPE,
		public timestamp?: number,
		public duration?: number,
		public deleted?: boolean,
		public deletedTimestamp?: number,
		public processed?: boolean,
		public tags?: string[],
	) {}

	deserialize(input: any): Message {
		this.author = input.author;
		this.authorId = input.authorId;
		this.content = input.content;
		this.fileSize = input.fileSize;
		this.id = input.id;
		this.latitude = input.latitude;
		this.longitude = input.longitude;
		this.messageType = input.messageType;
		this.timestamp = input.timestamp;
		this.deleted = input.deleted;
		this.deletedTimestamp = input.deletedTimestamp;
		this.tags = input.tags;
		this.processed = input.processed;
		return this;
	}
}

export enum MESSAGE_TYPE {
	TEXT = 'TEXT',
	STATUS = 'STATUS',
	TASK_CREATED = 'TASK_CREATED',
	TASK_CHANGED = 'TASK_CHANGED',
	TASK_COMPLETED = 'TASK_COMPLETED',
	IMAGE = 'IMAGE',
	AUDIO = 'AUDIO',
	VIDEO = 'VIDEO',
	DOCUMENT = 'DOCUMENT',
	NOTSET = 'NOTSET',
}

export enum MESSAGE_STATE {
	DEFAULT = 'DEFAULT',
	PROCESSED = 'PROCESSED',
	READ_BY_ALL_USERS = 'READ_BY_ALL_USERS',
}
