import { Inject, Injectable } from '@angular/core';
import { AppTheme } from '@store/reducers/app.reducer';
import { setThemeStateAction } from '@store/actions/app.actions';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { isNil } from 'lodash';

const DEFAULT_THEME = AppTheme.LIGHT;

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	theme = new ReplaySubject<AppTheme>(DEFAULT_THEME); // By default lite mode
	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly localStorageService: LocalStorageService,
		private readonly store: Store,
	) {}

	async initTheme(): Promise<void> {
		const selectedThemeInLocalStorage = await this.localStorageService.get('theme');
		const theme = isNil(selectedThemeInLocalStorage) ? DEFAULT_THEME : selectedThemeInLocalStorage;
		this.setTheme(theme);
		this.theme.next(theme);
	}

	setTheme(selectedTheme: AppTheme): void {
		this.document.body.classList.remove(selectedTheme === AppTheme.DARK ? 'light' : 'dark');
		this.document.body.classList.add(selectedTheme === AppTheme.DARK ? 'dark' : 'light');
		this.theme.next(selectedTheme);
		this.localStorageService.set('theme', selectedTheme);
		this.store.dispatch(setThemeStateAction({ state: selectedTheme }));
	}

	getThemeAsync(): Observable<AppTheme> {
		return this.theme.asObservable();
	}

	getTheme(): Promise<AppTheme> {
		return firstValueFrom(this.getThemeAsync());
	}
}
