import { Pipe, PipeTransform } from '@angular/core';
import { isValid } from 'date-fns';

@Pipe({
	name: 'minutesFromDate',
})
export class MinutesFromDatePipe implements PipeTransform {
	transform(date: Date): number | string | null {
		return isValid(date) ? date.getMinutes() : '--';
	}
}
