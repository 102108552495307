import { AfterViewInit, ChangeDetectorRef, Component, ContentChild } from '@angular/core';
import { LineRightComponent } from '../line-right/line-right.component';

@Component({
	selector: 'app-dialog-line',
	templateUrl: './line.component.html',
	styleUrls: ['./line.component.scss'],
})
export class LineComponent implements AfterViewInit {
	@ContentChild(LineRightComponent) rightComponent: LineRightComponent;

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

	hasRightHandContent = true;

	ngAfterViewInit(): void {
		this.hasRightHandContent = !!this.rightComponent;
		this.changeDetectorRef.detectChanges();
	}
}
