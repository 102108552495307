import { NgModule } from '@angular/core';
import { SharedSliderDialogComponent } from './components/shared-slider-dialog/shared-slider-dialog.component';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedSliderDialogSlideComponent } from './components/shared-slider-dialog-slide/shared-slider-dialog-slide.component';
import { SharedSliderInputToggleComponent } from './components/shared-slider-dialog-slide/components/shared-slider-input-toggle/shared-slider-input-toggle.component';
import { SharedSliderInputToggleOptionComponent } from './components/shared-slider-dialog-slide/components/shared-slider-input-toggle/components/shared-slider-input-toggle-option/shared-slider-input-toggle-option.component';
import { SharedSliderComponent } from './components/shared-slider/shared-slider.component';
import { SharedSliderControlComponent } from './components/shared-slider-control/shared-slider-control.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
	declarations: [
		SharedSliderDialogSlideComponent,
		SharedSliderDialogComponent,
		SharedSliderInputToggleComponent,
		SharedSliderInputToggleOptionComponent,
		SharedSliderComponent,
		SharedSliderControlComponent,
	],
	exports: [
		SharedSliderDialogSlideComponent,
		SharedSliderDialogComponent,
		SharedSliderInputToggleComponent,
		SharedSliderInputToggleOptionComponent,
		SharedSliderComponent,
	],
	imports: [
		SharedModule,
		MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatIconModule,
		MatSelectModule,
		MatInputModule,
	],
})
export class SharedSliderDialogModule {}
