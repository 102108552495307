import { createAction, props } from '@ngrx/store';
import { CompanyFile } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';

export const loadCompanyFilesAction = createAction('[Company Files] Load Company Files');
export const companyFilesChangedAction = createAction(
	'[Company Files] Company Files Are changed (Added, modified, deleted)',
	props<{ changes: EntityChanges<CompanyFile> }>(),
);
export const clearCompanyFilesAction = createAction('[Company Files] Clear Company Files');
