import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';

export enum DataProcessingService {
	GOOGLE_ANALYTICS = 'HkocEodjb7',
	ITERABLE = '5ZyS7UWsd',
}

@Injectable({
	providedIn: 'root',
})
export class ConsentManagementService {
	constructor(@Inject(WINDOW) private readonly windowRef: Window) {
		this.watchConsentEvents();
	}

	private consentEvents = new BehaviorSubject(false);

	watchServiceConsent(service: DataProcessingService): Observable<boolean> {
		const consentStatus$ = this.consentEvents.pipe(
			filter(Boolean),
			map(() => this.getConsentInfo(service)),
			// deprication can be ignored, typescript is confused by null
			startWith(null),
		);

		// Allow for three seconds grace period.
		const timeout$ = timer(5000).pipe(startWith(-1));

		return combineLatest([timeout$, consentStatus$]).pipe(
			filter(([time, consent]) => {
				return time >= 0 || consent !== null;
			}),
			map(([, consent]) => (consent === null ? false : consent)),
		);
	}

	private watchConsentEvents(): void {
		this.windowRef.addEventListener('UC_UI_CMP_EVENT', () => {
			this.consentEvents.next(true);
		});
		this.windowRef.addEventListener('UC_UI_INITIALIZED', () => {
			this.consentEvents.next(true);
		});
	}

	private getConsentInfo(service: DataProcessingService): boolean {
		if (!(this.windowRef as any).UC_UI) {
			return false;
		}
		const allInfo = (this.windowRef as any).UC_UI.getServicesBaseInfo();
		const serviceInfo = allInfo.find((currService) => currService.id === service);
		return !!(serviceInfo && serviceInfo.consent.status);
	}
}
