import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ENVIRONMENT, Environment } from '@shared/tokens/environment';

@Injectable()
export class HttpConfigInterceptors implements HttpInterceptor {
	auth: AuthService;

	constructor(inj: Injector, @Inject(ENVIRONMENT) private readonly environment: Environment) {
		this.auth = inj.get(AuthService);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.auth.firebaseUser) {
			return this.handleResponse(next, request);
		}
		return from(this.auth.getUserIdToken()).pipe(
			switchMap((token) => {
				const urlsForTokenAttach = [
					this.environment.baseUrl,
					this.environment.globalSearchElasticSearchUrl,
				];
				if (token && urlsForTokenAttach.some((url) => request.url.startsWith(url))) {
					request = request.clone({
						headers: request.headers.set('Authorization', 'Bearer ' + token),
					});
				}

				if (!request.headers.has('Content-Type')) {
					request = request.clone({
						headers: request.headers.set('Content-Type', 'application/json'),
					});
				}

				request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

				return this.handleResponse(next, request);
			}),
		);
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	private handleResponse(next: HttpHandler, request: HttpRequest<any>) {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}),
			catchError((error) => this.handleError(error)),
		);
	}
}
