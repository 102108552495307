import { Injectable } from '@angular/core';
import { TaskTime } from 'domain-entities';
import { TaskTimeConnector } from '@shared/firebase/connectors/firestore/collections/task-time/task-time.connector';

@Injectable()
export class TaskTimeEditFormService {
	constructor(private readonly taskTimeConnector: TaskTimeConnector) {}

	async updateTaskTime(taskTime: TaskTime): Promise<void> {
		await this.taskTimeConnector.updateTaskTime(taskTime);
	}

	async deleteTaskTime(trackedTimeId: string): Promise<void> {
		await this.taskTimeConnector.removeTaskTime(trackedTimeId);
	}
}
