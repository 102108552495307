<mat-dialog-content>
	<div class="header">{{ 'login.verificationTextHeader' | translate }}</div>
	<div class="body">{{ 'login.verificationTextBody' | translate: { email: data.email } }}</div>
	<div class="w-100 d-flex justify-content-end">
		<button mat-button (click)="logout()">{{ 'login.logout' | translate }}</button>
		<button mat-button (click)="resendVerification()">{{ 'login.sendAgain' | translate }}</button>
		<button mat-flat-button color="accent" (click)="checkVerification()">
			{{ 'login.alreadyVerified' | translate }}
		</button>
	</div>
</mat-dialog-content>
