import { Component } from '@angular/core';
import { Member } from 'domain-entities';

export enum ProjectMemberDrawerSecondaryViewOptions {
	INITIAL = 'initial',
	INTERNAL = 'internal',
	EXTERNAL = 'external',
	MEMBER_DETAILS = 'member-details',
}

@Component({
	selector: 'app-project-member-drawer',
	templateUrl: './project-member-drawer.component.html',
	styleUrls: ['./project-member-drawer.component.scss'],
})
export class ProjectMemberDrawerComponent {
	viewOption = ProjectMemberDrawerSecondaryViewOptions.INITIAL;
	ProjectMemberDrawerSecondaryViewOptions = ProjectMemberDrawerSecondaryViewOptions;
	currentMember: Member | null;

	get activeViewClass(): string {
		return this.viewOption !== ProjectMemberDrawerSecondaryViewOptions.INITIAL
			? 'secondary-active'
			: '';
	}

	openView(option: ProjectMemberDrawerSecondaryViewOptions): void {
		this.viewOption = option;
		this.currentMember = null;
	}

	openMemberDetails(member: Member): void {
		this.viewOption = ProjectMemberDrawerSecondaryViewOptions.MEMBER_DETAILS;
		this.currentMember = member;
	}
}
