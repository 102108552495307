import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { Observable } from 'rxjs';
import { Project } from 'domain-entities';
import { ProjectType } from '@shared/models/project.type';
import { selectProject } from '@store/selectors/projects.selectors';

@Component({
	selector: 'app-copy-to-project-item',
	templateUrl: './copy-to-project-item.component.html',
	styleUrls: ['./copy-to-project-item.component.scss'],
})
export class CopyToProjectItemComponent implements OnInit {
	@Input() project: Project;
	@Input() isSelected: boolean;
	@Input() showParent: boolean;
	@Output() selected = new EventEmitter<string>();
	@Output() folderSelected = new EventEmitter<string>();

	ProjectType = ProjectType;
	parentProject$: Observable<Project>;

	constructor(private store: Store<AppState>) {}

	selectProjectItem(project: Project): void {
		if (project.projectType === ProjectType.FOLDER) {
			this.folderSelected.emit(project.id);
		} else {
			this.selected.emit(project.id);
		}
	}

	ngOnInit(): void {
		if (this.project?.parentProject) {
			this.parentProject$ = this.store.select(selectProject, {
				projectId: this.project.parentProject,
			});
		}
	}
}
