import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { ProfileService } from '../../services/profile/profile.service';
import { CompanyService } from '../../services/company/company.service';
import { AuthService } from '../../services/auth/auth.service';

/**
 * @deprecated since version 3.54
 * Please use member role guard
 * @see {@link MemberRoleGuard}
 */

@Injectable({ providedIn: 'root' })
export class OwnerGuard {
	constructor(
		private auth: AuthService,
		private router: Router,
		private profileService: ProfileService,
		private companyService: CompanyService,
	) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const isOwner = await this.auth.$firebaseUser
			.pipe(
				skipWhile((user) => !user),
				switchMap((user) => this.profileService.getProfile(user.uid)),
				switchMap((profile) => this.companyService.getCompany(profile.company)),
				map((company) => company.isOwner(this.auth.currentUser().email)),
				take(1),
			)
			.toPromise();

		if (isOwner) {
			return true;
		}

		const urlToRedirect = route.data.redirectUnauthorisedTo;

		return urlToRedirect ? this.router.parseUrl(urlToRedirect) : isOwner;
	}
}
