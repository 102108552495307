import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'domain-entities';
import { ProductsConnector } from '@shared/firebase/connectors/firestore/collections/products/products.connector';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	constructor(private readonly productsConnector: ProductsConnector) {}

	getAvailableProducts(): Observable<Product[]> {
		return this.productsConnector.watchProducts();
	}
}
