import { Injectable } from '@angular/core';
import { TasksConnector } from '@craftnote/shared-injectables';
import { Task } from 'domain-entities';
import { selectLoggedInUser } from '@store/pipes/auth.pipes';
import { catchError, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {getUnixTime} from 'date-fns';

@Injectable({ providedIn: 'root' })
export class TasksService {
	constructor(private readonly store: Store, private readonly tasksConnector: TasksConnector) {}

	async updateTask(task: Partial<Task>): Promise<void> {
		const currentUserId = await this.store.pipe(selectLoggedInUser, take(1)).toPromise();
		const taskWithLastChangingUser: Partial<Task> = { ...task, lastChangeByUser: currentUserId };
		await this.tasksConnector.updateTask(taskWithLastChangingUser);
	}

	getTaskById(id: string): Observable<Task> {
		return this.tasksConnector.getTaskById(id);
	}

	removeTask(id: string): Promise<void> {
		return this.tasksConnector.deleteTask(id);
	}

	getTasksByProjectId(projectId: string): Observable<Task[]> {
		return this.tasksConnector.getTasksByProjectId(projectId);
	}

	async createTask(task: Task): Promise<void> {
		const currentUserId = await this.store.pipe(selectLoggedInUser, take(1)).toPromise();
		task.status = { name: 'active', setBy: currentUserId, setAt: getUnixTime(Date.now()) };
		await this.tasksConnector.createTask(task);
	}

	watchTasks(companyId: string, taskIds: string[]): Observable<Task[]> {
		return this.tasksConnector.watchTasks(companyId, taskIds);
	}

	checkIfTaskExists(id: string): Promise<boolean> {
		return this.tasksConnector
			.getTaskById(id)
			.pipe(
				take(1),
				map(Boolean),
				catchError((_) => of(false)),
			)
			.toPromise();
	}
}
