<section class="tracked-time-pause-button-section">
	<div class="d-flex flex-wrap justify-content-between align-items-center w-100 mb-2">
		<div class="align-items-center d-flex">
				<img alt="" class="d-inline-flex mr-1 img-fluid icon-image" src="assets/images/icons/time-tracking/pause.svg" />
					<span data-cy="break-time-entry">{{ 'time-tracking-pause-button.pause' | translate }}</span>
		</div>
		<div *ngIf="breaks.length > 0" class="font-weight-bold">
			{{ getTotalPauseDuration().hr }}:{{ getTotalPauseDuration().min }}h
		</div>
	</div>
	<div *ngFor="let breakControl of timeRangeControl.get('breaks')['controls']; let i = index" class="break-item w-100">
		<div class="break-item-fields w-100">
			<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
				<mat-label>{{ 'time-tracking-add-edit-form.start-time' | translate }}</mat-label>
				<app-time-picker
					[disabled]="disabled"
					[formControl]="breakControl.get('start')"
					[max]="trackedTimeRange.end"
					[min]="trackedTimeRange.start"
				>
				</app-time-picker>
			</mat-form-field>
			<span class="pr-4">{{ 'time-tracking-pause-button.to' | translate }}</span>
			<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
				<mat-label>{{ 'time-tracking-add-edit-form.end-time' | translate }}</mat-label>
				<app-time-picker
					[disabled]="disabled"
					[formControl]="breakControl.get('end')"
					[max]="trackedTimeRange.end"
					[min]="trackedTimeRange.start"
				>
				</app-time-picker>
			</mat-form-field>
		</div>
			<button  (click)="removeBreak(i)" [disabled]="disabled || timeRangeControl.get('breaks').length === 0" class="remove-break-button" mat-icon-button>
			<mat-icon >delete_outline</mat-icon>
		</button>
	</div>
	<button 
	  (click)="addBreak()" 
	  [disabled]="disabled" 
	  class="w-100" 
	  mat-stroked-button
	  *ngIf="showAddBreakButton">
	    {{ 'time-tracking-pause-button.add-another-break' | translate }}
	    <mat-icon>add</mat-icon>
	</button>
</section>
