import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ViewContainerDirective } from '@modules/shared/directives/view-container.directive';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Search } from '@shared/models/search.model';
import moment from 'moment';
import * as fromMessageActions from '../../../modules/features/chat/store/actions/message.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { pickBy } from 'lodash';
import { InviteState, Project } from 'domain-entities';
import * as fromMessageSelectors from '../../../modules/features/chat/store/selectors/message.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChatSearchExecutedEventBuilder } from '@generated/events/ChatEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { Contact } from '@shared/models/contact.model';

@Component({
	selector: 'app-chat-search',
	templateUrl: './chat-search.component.html',
	styleUrls: ['./chat-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSearchComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
	chatFilterEnabled = false;

	@ViewChild('chatFilterMenu', { read: TemplateRef }) chatFilterMenu: TemplateRef<null>;
	@Input() chatFilterMenuContainer: ViewContainerDirective;
	@Input() project: Project = null;
	chatSearchForm = this.fb.group({
		text: ['', [Validators.required]],
	});
	chatFilterForm = this.fb.group(
		{
			member: [null],
			from: [null],
			to: [null],
			type: [[]],
		},
		{ validators: this.chatFilterValidator },
	);
	membersInviteState = InviteState;
	private destroy$ = new Subject();

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly cdr: ChangeDetectorRef,
		private readonly store: Store<AppState>,
		private readonly trackingService: TrackingService,
	) {}

	get chatSearchText(): string {
		return this.chatSearchForm && this.chatSearchForm.value.text;
	}

	ngOnInit(): void {
		this.listenMessagesSearchEnabledState();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.project && changes.project.currentValue.id !== changes.project.previousValue?.id) {
			this.chatFilterForm.reset();
			this.disableFilter();
		}
	}

	ngAfterViewInit(): void {
		if (!this.chatFilterMenuContainer) {
			console.error('Chat Search Component: Not able to find the container for search filter menu');
			return;
		}
		this.chatFilterMenuContainer.viewContainerRef.createEmbeddedView(this.chatFilterMenu);
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	toggleFilter(_chatFilterEnabled: boolean, evt: Event): void {
		evt.preventDefault();
		this.chatFilterEnabled ? this.disableFilter() : this.enableFilter();
	}

	disableFilter(): void {
		this.chatFilterEnabled = false;
		this.cdr.detectChanges();
	}

	enableFilter(): void {
		this.chatFilterEnabled = true;
	}

	onResetChatSearchForm(): void {
		this.store.dispatch(fromMessageActions.ClearSearchAndFilter());
		this.store.dispatch(fromMessageActions.LoadMessagesByProject({ projectId: this.project.id }));
	}

	async onSubmitChatSearchForm(formValue: Partial<Search>): Promise<void> {
		this.dispatchChatSearch(formValue, undefined);

		await this.trackingService.trackEvent(
			new ChatSearchExecutedEventBuilder({ projectId: this.project.id }),
		);
	}

	onSubmitChatFilterForm(formValue: Partial<Search>): void {
		this.dispatchChatSearch(undefined, formValue);
	}

	chatFilterValidator(group: AbstractControl): ValidationErrors | null {
		let isValid = false;
		const search: Partial<Search> = group.value;

		if (search.from || search.member || search.to || (search.type && search.type.length > 0)) {
			isValid = true;
		}
		return isValid ? null : { atLeastOneFieldRequired: false };
	}

	getTodayDate(): Date {
		return moment().toDate();
	}

	getStartOfDay(time = new Date().getTime()): Date {
		return moment(time).startOf('day').toDate();
	}

	private dispatchChatSearch(
		chatSearchValue: Partial<Search> = this.chatSearchForm.value,
		chatFilterValue: Partial<Search> = this.chatFilterForm.value,
	): void {
		let search: Search = { ...chatFilterValue, ...chatSearchValue };
		search.projectId = this.project.id;
		search.member = Object.values(this.project.members).filter(
			(member) => member.id === this.chatFilterForm.value.member,
		)[0] as Contact;

		// Removing filter keys which are null/undefined/[]
		search = pickBy(search, (value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			}
			return Boolean(value);
		});

		search.from = search.from ? moment(search.from).startOf('day').toDate() : search.from;
		if (search.to) {
			const isToDateIsToday = moment(search.to).isSame(moment(), 'day');
			search.to = isToDateIsToday ? moment().toDate() : moment(search.to).endOf('day').toDate();
		}

		this.store.dispatch(fromMessageActions.SearchAndFilter({ searchAndFilterOptions: search }));
	}

	private listenMessagesSearchEnabledState(): void {
		this.store
			.select(fromMessageSelectors.messagesSearchEnabled)
			.pipe(
				filter((isFilterEnabled) => isFilterEnabled === false),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.disableFilter();
				this.chatSearchForm.reset();
				this.chatFilterForm.reset();
			});
	}
}
