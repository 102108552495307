import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@shared/tokens/environment';
import { Project } from 'domain-entities';
import { mergeMap, Observable, take } from 'rxjs';
import { AuthService } from '../auth/auth.service';

export interface EntityList<T = Project> {
	data?: Array<T>;
	startAfter?: string;
	formerEntityIds?: Array<string>;
}

@Injectable({
	providedIn: 'root',
})
export class RestApiService {
	private readonly baseUrl: string;
	private authToken: string;
	private authToken$: Observable<string>;

	constructor(
		private httpClient: HttpClient,
		private auth: AuthService,
		@Inject(ENVIRONMENT) private environment: Environment,
	) {
		this.baseUrl = this.environment.restApiUrl;
		this.authToken$ = this.auth.authToken();
		this.authToken$.subscribe((token) => {
			this.authToken = token;
		});
	}

	public getProjects = (
		status?: 'active' | 'archived',
		limit?: number,
		updatedSince?: number,
		startAfter?: string,
	): Observable<EntityList> => {
		let params = new HttpParams();
		if (status) {
			params = params.set('status', status);
		}
		if (limit) {
			params = params.set('limit', limit);
		}
		if (updatedSince) {
			params = params.set('updatedSince', updatedSince);
		}
		if (startAfter) {
			params = params.set('startAfter', startAfter);
		}

		return this.request<EntityList>('/projects', 'GET', params);
	};

	public getProjectById = (projectId: string): Observable<Project> =>
		this.request<Project>(`/projects/${projectId}`, 'GET');

	private request = <T>(path: string, method: string, params?: HttpParams): Observable<T> => {
		if (!this.authToken) {
			return this.authToken$.pipe(
				take(1),
				mergeMap((token) => {
					this.authToken = token;
					return this.httpClient.request<T>(method, `${this.baseUrl}${path}`, {
						params: params,
						headers: {
							Authorization: `Bearer ${this.authToken}`,
							Accept: 'application/json',
						},
					});
				}),
			);
		} else {
			return this.httpClient.request<T>(method, `${this.baseUrl}${path}`, {
				params: params,
				headers: {
					Authorization: `Bearer ${this.authToken}`,
					Accept: 'application/json',
				},
			});
		}
	};
}
