import { NgModule } from '@angular/core';
import { PadStartPipe } from './pad-start.pipe';

const PIPE = [PadStartPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class PadStartPipeModule {}
