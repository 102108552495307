import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ProjectService } from '@injectables/services/project/project.service';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	selectActiveProjectsLoaded,
	selectAllActiveProjects,
	selectAllArchivedProjects,
	selectArchivedProjectsLoaded,
	selectParentProject,
} from '@store/selectors/projects.selectors';
import { combineLatest } from 'rxjs';
import { selectUserId } from '@store/selectors/app.selectors';
import { initArchivedProjectsAction } from '@store/actions/project.actions';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

/**
 * This guard will redirect old project or archive routes like
 * /project?id=<projectId>
 *
 * to new routes like
 *
 * /project/<projectId>
 *
 */
@Injectable({ providedIn: 'root' })
export class ProjectLastOpenedGuard {
	constructor(
		private readonly projectService: ProjectService,
		private readonly projectHelperService: ProjectHelperService,
		private readonly router: Router,
		private readonly store: Store<AppState>,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly matDialog: MatDialog,
	) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const workRoute = route.url[0].path as 'projects' | 'archive';
		const isArchive = workRoute === 'archive';
		const activeProject = route.children[0]?.url[0].path;

		// TODO:: Move this functionality into the respective components due to SRP
		if (isArchive) {
			this.store.dispatch(initArchivedProjectsAction());
			await this.store
				.select(selectArchivedProjectsLoaded)
				.pipe(filter(Boolean), take(1))
				.toPromise();
		} else {
			await this.store
				.select(selectActiveProjectsLoaded)
				.pipe(filter(Boolean), take(1))
				.toPromise();
		}
		const projects = await this.store
			.select(isArchive ? selectAllArchivedProjects : selectAllActiveProjects)
			.pipe(take(1))
			.toPromise();

		const [parentProject, userId] = await combineLatest([
			this.store.select(selectParentProject, { projectId: activeProject }),
			this.store.select(selectUserId),
		])
			.pipe(take(1))
			.toPromise();

		// Do nothing if a project id has been specified explicitly and available in the list
		if (activeProject) {
			const isProjectAvailableInTheList = projects.some((project) => project.id === activeProject);

			if (
				isProjectAvailableInTheList &&
				(isArchive || !parentProject?.membersArchived.includes(userId))
			) {
				return true;
			} else {
				if (this.matDialog.openDialogs.length === 0) {
					this.confirmDialogService
						.open({
							title: this.translateService.instant('work.project-not-found'),
							primaryButtonColor: 'accent',
							showSecondaryButton: false,
							showCrossBtn: false,
							primaryButtonText: this.translateService.instant('button.close'),
						})
						.afterClosed();
				}
			}
		}

		// Try to open the last opened project if no project has been specified
		const projectId = await this.projectService.getIdOfStandardProject(null, isArchive);

		if (!projectId) {
			return true;
		}

		return this.router.createUrlTree([workRoute, projectId]);
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
	): Promise<boolean | UrlTree> | boolean | UrlTree {
		const { projectId } = childRoute.params;

		if (projectId) {
			this.projectHelperService.setLastOpenedProject(projectId);
		}

		return true;
	}
}
