import { CdkDropList } from '@angular/cdk/drag-drop';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DragAndDropManagerService } from './drag-and-drop-manager.service';

@Directive({
	selector: '[dragAndDropManager]',
})
export class DragAndDropManagerDirective implements OnInit, OnDestroy {
	@Input() ignoreDestinationItems: string[];
	private destroy$ = new Subject<void>();

	constructor(private dropList: CdkDropList, private managerService: DragAndDropManagerService) {}

	ngOnInit(): void {
		this.managerService.register(this.dropList.id, this.ignoreDestinationItems);
		this.managerService
			.onListChange()
			.pipe(takeUntil(this.destroy$))
			.subscribe((filesId) => {
				this.dropList.connectedTo = filesId;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
		this.managerService.unregister(this.dropList.id);
	}
}

@Directive({
	selector: '[dragAndDropManagerRoot]',
	providers: [
		{
			provide: DragAndDropManagerService,
		},
	],
})
export class DragAndDropManagerRootDirective extends DragAndDropManagerDirective {}
