import { NgModule } from '@angular/core';
import { TimePickerComponent } from '@modules/shared/components/time-picker/time-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { PatternRestrictModule } from '@modules/shared/directives/pattern-restrict.module';
import { HoursFromDatePipeModule } from '@modules/shared/pipes/hours-from-date/hours-from-date-pipe.module';
import { PadStartPipeModule } from '@modules/shared/pipes/pad-start/pad-start-pipe.module';
import { MinutesFromDatePipeModule } from '@modules/shared/pipes/minutes-from-date/minutes-from-date-pipe.module';
import { MatIconDisabledModule } from '@craftnote/material-theme';

const COMPONENT = [TimePickerComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [
		MatIconModule,
		PatternRestrictModule,
		HoursFromDatePipeModule,
		PadStartPipeModule,
		MinutesFromDatePipeModule,
		MatIconDisabledModule,
	],
	exports: [COMPONENT],
})
export class TimePickerModule {}
