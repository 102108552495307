import { Component } from '@angular/core';
import { AlertService } from '@injectables/services/alert/alert.service';
import { AuthService } from '@injectables/services/auth/auth.service';
import { LinkService } from '@injectables/services/link/link.service';
import { SignInProvider } from '@shared/models/sign-in-provider.enum';
import { Store } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { AppState } from '@store/state/app.state';
import moment from 'moment';
import { selectInvitationLink } from '@store/selectors/invitations.selectors';
import { selectIsLoggedIn } from '@store/selectors/app.selectors';
import { removeSpaces } from '@craftnote/shared-utils';
import { setUserRegistrationType } from '@store/actions/auth.actions';
import { UserRegistrationType } from '@store/state/auth.state';
import { Router } from '@angular/router';
import { EMAIL_PATTERN } from '@constants/validators.constants';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	SignInProvider = SignInProvider;
	emailPattern = EMAIL_PATTERN;

	username: string;
	password: string;
	isPasswordVisibility = true;

	invitationLink$ = this.store.select(selectInvitationLink);
	isUserAuthenticated$ = this.store.select(selectIsLoggedIn);

	get humanizedDayGreeting(): string {
		const currentHour = moment().hour();
		if (currentHour < 12) {
			return 'morning';
		} else if (currentHour <= 17) {
			return 'afternoon';
		} else {
			return 'evening';
		}
	}

	constructor(
		public readonly authService: AuthService,
		private readonly alertService: AlertService,
		public readonly linkService: LinkService,
		public readonly router: Router,
		public readonly store: Store<AppState>,
	) {}

	async login(): Promise<void> {
		if (!this.areCredentialsValid()) {
			return;
		}

		await this.setPersistenceStrategy();
		this.store.dispatch(
			setUserRegistrationType({ registrationType: UserRegistrationType.IN_PROGRESS }),
		);

		try {
			await this.authService.loginWithEmail(this.username, this.password);
		} catch (error) {
			this.alertService.showAlert(error.message);
			this.password = '';
		}
	}

	isLoaded(): boolean {
		return this.authService.isLoaded();
	}

	trimEmail(): void {
		this.username = removeSpaces(this.username);
	}

	goToRegister(): void {
		void this.router.navigate(['register'], { queryParamsHandling: 'preserve' });
	}

	private areCredentialsValid(): boolean {
		if (
			this.username == null ||
			this.username.length === 0 ||
			this.password == null ||
			this.password.length === 0
		) {
			this.alertService.showAlert('error.form.fields');
			return false;
		}

		return true;
	}

	private async setPersistenceStrategy(): Promise<void> {
		return this.authService.setFirebasePersistence(firebase.auth.Auth.Persistence.LOCAL);
	}
}
