import { createAction, props } from '@ngrx/store';
import { Project } from 'domain-entities';

export const watchProjectUnreads = createAction('[Project Unreads] Watch Project Unreads');

export const clearProjectUnreadsState = createAction('[Project Unreads] Clear Project Unreads');

export const updateProjectUnreads = createAction(
	'[Project Unreads] Update Project Unreads',
	props<{ projectUnreads: string[] }>(),
);

export const removeProjectUnread = createAction(
	'[Project Unreads] Remove Project Unread',
	props<{ project: Project }>(),
);

export const setProjectAsUnread = createAction(
	'[Project Unreads] Set Project To Unread',
	props<{ project: Project }>(),
);

export const setKeepCurrentProjectUnread = createAction(
	'[Project Unreads] Set Keep Current Project Unread',
);

export const clearKeepCurrentProjectUnread = createAction(
	'[Project Unreads] Clear Keep Current Project Unread',
);
