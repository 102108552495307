import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BrowserState } from '../state/browser.state';

const browserState = createFeatureSelector<BrowserState>('browserState');

export const selectIndexDbCanBeMutated = createSelector(
	browserState,
	(state) => state.indexDbCanBeMutated,
);

export const selectNotificationPermission = createSelector(
	browserState,
	(state) => state.notificationPermission,
);
