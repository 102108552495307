import { NgModule } from '@angular/core';
import { AddBulkCompanyEmployeesComponent } from './add-bulk-company-employees.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CompanyEmployeesRoleDialogComponent } from './company-employees-role-dialog/company-employees-role-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [AddBulkCompanyEmployeesComponent, CompanyEmployeesRoleDialogComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatIconModule,
		MatIconSizeModule,
		MatButtonModule,
		MatInputModule,
		MatDialogModule,
		SharedModule,
	],
	exports: [COMPONENT],
})
export class AddBulkCompanyEmployeesModule {}
