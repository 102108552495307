import { Action, createReducer, on } from '@ngrx/store';
import * as companyFilesAction from '../actions/company-files.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { CompanyFile } from 'domain-entities';
import { updateEntityStore } from '@store/reducers/functions/update-entity-state.functions';
import { EntityStore } from '@store/entities/entity-store';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

const companyFileEntityAdapter = createEntityAdapter<CompanyFile>();
const companyFileEntityState: EntityStore<CompanyFile> = {
	...companyFileEntityAdapter.getInitialState(),
	loadingStatus: StoreLoadingStatus.INITIAL,
};

const reducerWorkTypes = createReducer(
	companyFileEntityState,
	on(companyFilesAction.clearCompanyFilesAction, (state) => {
		return companyFileEntityAdapter.removeAll(state);
	}),
	on(companyFilesAction.companyFilesChangedAction, (state, { changes }) => {
		return updateEntityStore(companyFileEntityAdapter, state, changes);
	}),
);

export const companyFileSelectors = companyFileEntityAdapter.getSelectors();

// tslint:disable-next-line:typedef
export function companyFileReducer(state: EntityStore<CompanyFile> | undefined, action: Action) {
	return reducerWorkTypes(state, action);
}
