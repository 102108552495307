import { Company, CompanyEmployee, Member } from 'domain-entities';
import { CompanySize } from '../../models/company-size.type';

export function initializeCompanyFactory(
	companyName: string,
	phoneNumber: string,
	trade: string,
	size: CompanySize,
): (oldProject: Company) => Partial<Company> | undefined {
	return function (oldProject: Company): Partial<Company> {
		const result: Partial<Company> = {};
		result.name = companyName;
		result.trade = trade;
		result.phone = phoneNumber;
		result.additionalInformation = oldProject.additionalInformation
			? { ...oldProject.additionalInformation }
			: {};
		result.additionalInformation.size = size;
		return result;
	};
}

export function addMemberToCompanyFactory(
	member: Member,
): (oldCompany: Company) => Partial<Company> {
	return (oldCompany) => {
		member = { ...member, email: member.email.toLowerCase().trim() };
		const updatedCompany: Partial<Company> = {
			members: oldCompany.members,
		};

		updatedCompany.members[member.email] = member;
		return updatedCompany;
	};
}

export function updateCompanyMemberFactory(
	memberEmail: string,
	memberUpdate: Partial<Member>,
): (oldCompany: Company) => Partial<Company> | undefined {
	return (oldCompany) => {
		const memberInCompany = oldCompany.members[memberEmail];
		if (!memberInCompany) {
			return undefined;
		}
		const updatedCompany: Partial<Company> = { members: oldCompany.members };
		const updatedMember: Member = { ...memberInCompany, ...memberUpdate };

		updatedCompany.members[memberEmail] = updatedMember;
		return updatedCompany;
	};
}

export function removeCompanyMemberFactory(
	memberEmail: string,
): (oldCompany: Company) => Partial<Company> | undefined {
	return (oldCompany) => {
		const members = { ...oldCompany.members };
		if (!members[memberEmail]) {
			return undefined;
		}
		delete members[memberEmail];
		return { members };
	};
}

export function removeTagFromCompanyFunction(
	tag: string,
): (oldCompany: Company) => Partial<Company> | undefined {
	return (oldCompany) => {
		if (!oldCompany.tags) {
			return undefined;
		}
		const tags = { ...oldCompany.tags };
		delete tags[tag];
		return { tags };
	};
}

export function upsertCompanyTagsFactory(
	tag: string,
): (oldCompany: Company) => Partial<Company> | undefined {
	return (oldCompany) => {
		const tags = oldCompany.tags || {};
		tags[tag] = tags[tag] ? tags[tag] + 1 : 1;
		return { tags };
	};
}

export function upsertCompanyEmployee(
	userId: string,
	employeeNumber: string,
): (oldCompanyEmployee: CompanyEmployee | undefined) => CompanyEmployee {
	return (oldCompanyEmployee) => {
		return { ...(oldCompanyEmployee || {}), id: oldCompanyEmployee?.id || userId, employeeNumber };
	};
}
