import { Injectable } from '@angular/core';
import { buildFirestoreCollectionPath } from '@utils/functions/firestore/firestore-path.functions';
import { isProfileClickId, ProfileClickId } from 'domain-entities';
import { find } from 'lodash';
import { FirestoreConnector } from '@craftnote/shared-injectables';

@Injectable({
	providedIn: 'root',
})
export class ProfileClickIdConnector {
	private static COLLECTION_NAME = 'profiles';
	private static SUB_COLLECTION_NAME = 'clickIds';

	constructor(private readonly connector: FirestoreConnector) {}

	async createProfileClickId(profileId: string, profileClickId: ProfileClickId): Promise<void> {
		const clickIdsPath = buildFirestoreCollectionPath(
			[{ collectionName: ProfileClickIdConnector.COLLECTION_NAME, documentId: profileId }],
			ProfileClickIdConnector.SUB_COLLECTION_NAME,
		);

		const allProfileClickId = await this.getAllProfileClickId(clickIdsPath);
		const currentProfileClickId = find(allProfileClickId, (doc) => doc.id === profileClickId.id);

		if (currentProfileClickId) {
			return;
		}

		return this.connector.create(clickIdsPath, profileClickId.id, profileClickId, isProfileClickId);
	}

	private async getAllProfileClickId(path: string): Promise<ProfileClickId[]> {
		return await this.connector.getDocuments(path, isProfileClickId);
	}
}
