import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { selectUserId } from '../selectors/app.selectors';
import { Store } from '@ngrx/store';
import { ClickIdService } from '@injectables/services/click-id.service';

@Injectable()
export class ClickIdEffects {
	constructor(private clickIdService: ClickIdService, private readonly store: Store) {}

	setClickIdEffect = createEffect(
		() =>
			this.store.select(selectUserId).pipe(
				filter<string>(Boolean),
				tap((userId) => {
					this.clickIdService.handleClickIdProfile(userId);
				}),
			),
		{ dispatch: false },
	);
}
