import { Injectable, TemplateRef, ViewRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import {
	NotificationSnackbarComponent,
	NotificationSnackbarConfigStrict,
} from '@modules/shared/components/notification-snackbar/notification-snackbar.component';

const defaultConfig: NotificationSnackbarConfigStrict = {
	level: 0,
	timeout: 2000,
};

export interface NotificationSnackbarConfig {
	componentTypes?: { [name: string]: any };
	level?: number;
	timeout?: number;
}

@Injectable({
	providedIn: 'root',
})
export class NotificationSnackbarService {
	notificationSnackbarComponent: NotificationSnackbarComponent = null;

	registerComponent(component: NotificationSnackbarComponent): void {
		this.notificationSnackbarComponent = component;
	}

	show<T>(
		componentOrTemplateRef: TemplateRef<T> | ComponentType<T>,
		config: NotificationSnackbarConfig,
	): ViewRef {
		const configWithDefaults = { ...defaultConfig, ...(config || {}) };
		return this.notificationSnackbarComponent.show<T>(componentOrTemplateRef, configWithDefaults);
	}

	hide(view: ViewRef): void {
		this.notificationSnackbarComponent.hide(view);
	}

	hideAll(): void {
		this.notificationSnackbarComponent.discardAll();
	}
}
