<section class="project-members px-2 pr-0 h-100 d-flex flex-column">
	<div class="project-members__header">
		<app-project-member-list-header [projects$]="projectMembers$" [filterBy$$]="projectMemberFilter$$"></app-project-member-list-header>
	</div>
	<div class="project-members__body pr-2 d-flex flex-column flex-grow-1 overflow-auto">
		<ng-container *ngIf="filteredProjectMembers$ | async as projectMembers">
			<div class="position-sticky top-0 bg-white z-index-1" *ngIf="isUserProjectAdmin$ | async">
				<mat-checkbox (change)="toggleAllProjectMembersSelection($event)" [checked]="isAllProjectMembersChecked$ | async">
					{{ 'projectMembers.selectAllCheckbox' | translate }}
				</mat-checkbox>
			</div>
			<mat-selection-list
				#projectMembersSelectionListRef
				class="selection-list-project-members"
				[class.show-all-checkboxes]="(selectedMembers$ | async)?.length"
				[compareWith]="onMemberSelectionChangeCompareWith"
			>
				<div *ngFor="let member of projectMembers" class="border-bottom pointer">
					<mat-list-option
						[value]="member"
						*ngIf="(isUserProjectAdmin$ | async) && isMemberRemovable$(member.id) | async; else listItemTemplateRef"
					>
						<app-project-member-list-item (click)="openMemberDetails(member, $event)" [member]="member"></app-project-member-list-item>
					</mat-list-option>

					<ng-template #listItemTemplateRef>
						<mat-list-item>
							<app-project-member-list-item (click)="openMemberDetails(member, $event)" [member]="member"></app-project-member-list-item>
						</mat-list-item>
					</ng-template>
				</div>

				<ng-container *ngIf="pendingInviteMembers$ | async as pendingInvitations">
					<div class="pt-4 pb-2" *ngIf="pendingInvitations.length">
						{{ 'projectMembers.body.pendingInvitations' | translate }}
					</div>

					<div *ngFor="let member of pendingInvitations" class="border-bottom pointer">
						<mat-list-option
							[value]="member"
							*ngIf="(isUserProjectAdmin$ | async) && isMemberRemovable$(member.id) | async; else listItemTemplateRef"
						>
							<app-project-member-list-item (click)="openMemberDetails(member, $event)" [member]="member"></app-project-member-list-item>
						</mat-list-option>

						<ng-template #listItemTemplateRef>
							<mat-list-item>
								<app-project-member-list-item (click)="openMemberDetails(member, $event)" [member]="member"></app-project-member-list-item>
							</mat-list-item>
						</ng-template>
					</div>
				</ng-container>
			</mat-selection-list>
			<div *ngIf="isEmptyContainer$ | async; then emptyFolder"></div>
		</ng-container>
		<div *ngIf="showMemberPlaceholder$ | async" class="project-members__member-placeholder p-5 invisible"></div>
	</div>
	<ng-container *ngIf="showAddMemberOption$ | async" [ngTemplateOutlet]="createMembersMenu"></ng-container>
	<div
		class="project-members-selection-action-buttons mat-elevation-z5 px-3 py-2 rounded-top bg-white"
		[class.show-action-buttons]="(selectedMembers$ | async)?.length"
	>
		<button mat-stroked-button (click)="toggleAllProjectMembersSelection({ checked: false })">
			{{ 'projectMembers.selectAllCheckbox.cancel' | translate }}
		</button>
		<button mat-flat-button color="warn" (click)="removeProjectMembers()">
			{{ 'projectMembers.selectAllCheckbox.remove' | translate }}
		</button>
	</div>
</section>

<ng-template #emptyFolder>
	<div class="project-members__empty-container flex-grow-1 d-flex align-items-center text-center p-4">
		<div>
			<mat-icon class="mb-2 empty-folder-icon" svgIcon="empty-folder"></mat-icon>
			<div class="empty-text">{{ 'projectMembers.body.emptyText.new' | translate: { projectName: (projectName$ | async) } }}</div>
		</div>
	</div>
</ng-template>

<ng-template #createMembersMenu>
	<div class="project-members__create-menu d-flex flex-column align-items-end">
		<ng-template [ngIf]="createButtonsActive">
			<div [@shrinkOut] class="project-members__create-button d-flex align-items-center text-right mb-2">
				<div class="fab-text text-white">
					<div>{{ 'projectMembers.invite.internal.title' | translate }}</div>
					<div class="mat-small">
						{{ 'projectMembers.invite.internal.companyInfo' | translate: { companyName: (companyName$ | async) } }}
					</div>
				</div>
				<button class="ml-3" color mat-fab (click)="openInternalMemberView()" data-cy="add-internal-members">
					<mat-icon>person_add_alt_1</mat-icon>
				</button>
			</div>
			<div [@shrinkOut] class="project-members__create-button d-flex align-items-center text-right mb-2">
				<div class="fab-text text-white">
					<div>{{ 'projectMembers.invite.external.title' | translate }}</div>
					<div class="mat-small">
						{{ 'projectMembers.invite.external.companyInfo' | translate }}
					</div>
				</div>
				<button class="ml-3" color mat-fab (click)="openExternalMemberView()" data-cy="add-external-members">
					<mat-icon>person_add_alt</mat-icon>
				</button>
			</div>
		</ng-template>

		<button
			(click)="toggleActionMenu()"
			class="ml-2"
			color="accent"
			id="trigger-btn"
			mat-fab
			[class.d-none]="(selectedMembers$ | async)?.length"
		>
			<mat-icon [@rotate]="createButtonsActive ? 'in' : 'out'" class="position-relative" id="trigger-btn-icon" data-cy="add-project-members"
				>add
			</mat-icon>
		</button>
	</div>

	<app-dark-overlay (click)="toggleActionMenu()" [visible]="createButtonsActive" [zIndex]="500"></app-dark-overlay>
</ng-template>
