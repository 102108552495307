import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FileSectionUploadState, fileUploadSelectors } from '@store/reducers/file-upload.reducer';
import { selectAllActiveProjectsEntities } from '@store/selectors/projects.selectors';
import { uniq } from 'lodash';

export const fileUpload = createFeatureSelector('fileUpload');

export const fileEntities = createSelector(fileUpload, fileUploadSelectors.selectEntities);
export const allFiles = createSelector(fileUpload, fileUploadSelectors.selectAll);

export const selectAllFileSectionUploadOfProject = (projectId: string) =>
	createSelector(allFiles, (state) => {
		const fileUploadState = state as FileSectionUploadState[];
		return fileUploadState.filter((upload) => {
			return (upload as FileSectionUploadState).projectId === projectId;
		});
	});

export const selectFileUploadPercentage = (uploadId: string) =>
	createSelector(fileEntities, (state) => {
		const upload = state[uploadId];
		if (!upload) {
			return undefined;
		}
		return upload.percentage;
	});

export const selectUploadState = (id: string) =>
	createSelector(fileEntities, (state) => {
		return state[id]?.status;
	});

export const selectFailedUploads = createSelector(allFiles, (state) => {
	return (state as FileSectionUploadState[]).filter((file) => file.status === 'error');
});

export const selectQueuedUploads = createSelector(allFiles, (state) => {
	return (state as FileSectionUploadState[]).filter((file) =>
		['paused', 'ongoing'].includes(file.status),
	);
});

export const selectProjectsWithFailedUploads = createSelector(
	selectAllActiveProjectsEntities,
	selectFailedUploads,
	(projects, failedUploads) => {
		const allProjectsOfFailedUploads = failedUploads
			.filter((upload) => !upload.hiddenInProjectNudge)
			.sort((a, b) => b.failureTime - a.failureTime)
			.map((failedUpload) => projects[failedUpload.projectId])
			.filter(Boolean);
		return uniq(allProjectsOfFailedUploads);
	},
);
