import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

export interface AlertConfig {
	duration?: number;
	oKButtonTranslationKey?: string;
}

const alertConfigDefault: AlertConfig = {
	duration: 4000,
	oKButtonTranslationKey: 'button.ok',
};

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(
		private readonly snackBar: MatSnackBar,
		private readonly translate: TranslateService,
	) {}

	// FIXME:: Combine below two methods into one

	showAlert(message: string, config?: AlertConfig): void {
		const duration = config?.duration ? config.duration : alertConfigDefault.duration;
		const oKButtonTranslationKey = config?.oKButtonTranslationKey
			? config.oKButtonTranslationKey
			: alertConfigDefault.oKButtonTranslationKey;
		this.translate.get(message).subscribe((res: string) => {
			if (oKButtonTranslationKey) {
				this.translate.get(oKButtonTranslationKey).subscribe((ok: string) => {
					this.snackBar.open(res, ok, {
						duration,
					});
				});
			} else {
				this.snackBar.open(res, undefined, {
					duration,
				});
			}
		});
	}

	showAlertWithAction(message: string, time: number, button: string, action: Function): void {
		const snackbarMessage = this.translate.instant(message);
		const snackbarButtonText = this.translate.instant(button);
		this.snackBar
			.open(snackbarMessage, snackbarButtonText, {
				duration: time,
			})
			.onAction()
			.subscribe(() => {
				action();
			});
	}
}
