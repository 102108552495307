import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Project, ProjectType } from 'domain-entities';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { selectUserId } from '@store/selectors/app.selectors';
import {
	isUserInternalProjectAdmin,
	isUserInternalSupervisorOfProject,
} from '@shared/functions/project/project.functions';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { selectProjectAreaProjects } from '@modules/shared/components/projects-area/store/selectors/project-area.selectors';
import { createKeyMap } from '@shared/functions/utils/object.functions';
import { groupBy } from 'lodash';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import {
	selectAllActiveProjects,
	selectSelectedFolderId,
} from '@store/selectors/projects.selectors';
import { getUnixTime, subDays } from 'date-fns';

const UNUSED_DAYS_CUTOFF = 180;

interface ProjectAreaState {
	keyedProjects: { [ids: string]: Project };
	listProjects: Project[];
	projectsByParent: { [ids: string]: Project[] };
	openProjectRequested: string | null;
	openFolderRequested: string | null;
	archivableProjects: Project[];
}

const defaultState: ProjectAreaState = {
	keyedProjects: {},
	listProjects: [],
	projectsByParent: {},
	openProjectRequested: undefined,
	openFolderRequested: undefined,
	archivableProjects: [],
};

@Injectable()
export class ProjectAreaStore extends ComponentStore<ProjectAreaState> {
	private readonly cutoffTime = getUnixTime(subDays(new Date(), UNUSED_DAYS_CUTOFF));
	readonly projects$ = this.select(({ listProjects }) => listProjects);
	readonly archivableProjects$ = this.select(({ archivableProjects }) => archivableProjects);
	readonly openFolder$ = combineLatest([
		this.select(({ openProjectRequested, openFolderRequested, keyedProjects }) => ({
			openProjectRequested,
			openFolderRequested,
			keyedProjects,
		})),
	]).pipe(
		map(([{ keyedProjects, openFolderRequested }]) => {
			return keyedProjects[openFolderRequested] ?? null;
		}),
	);
	readonly openFolderProjects$ = combineLatest([this.projects$, this.openFolder$]).pipe(
		map(([projects, openFolder]) => {
			if (!openFolder) {
				return projects;
			}
			return projects.filter((p) => p.parentProject === openFolder.id);
		}),
	);
	readonly currentMemberIsExternalInSelectedFolder$ = this.openFolder$.pipe(
		switchMap((openFolder) => {
			if (!openFolder) {
				return of(false);
			}
			return this.store
				.select(selectUserId)
				.pipe(map((userId) => !isUserInternalSupervisorOfProject(userId, openFolder)));
		}),
	);

	constructor(private readonly store: Store) {
		super(defaultState);
		this.initProjects();
		this.initArchivableProjects();
		this.initWatchActiveProject();
		this.initWatchOpenFolder();
	}

	openFolder(folderId: string): void {
		this.store.dispatch(setSelectedFolderAction({ folderId: folderId }));
	}

	childrenOfFolder(folderId: string): Observable<Project[]> {
		return this.select(({ projectsByParent }) =>
			projectsByParent[folderId] ? projectsByParent[folderId] : [],
		);
	}

	private initProjects(): void {
		this.store
			.select(selectProjectAreaProjects)
			.pipe(takeUntil(this.destroy$))
			.subscribe((projects) => {
				const keyedProjects = createKeyMap<Project>((project) => project.id, projects);
				const projectsByParent = groupBy(projects, (project) => project.parentProject);
				this.setState((state) => ({
					...state,
					keyedProjects: keyedProjects,
					listProjects: projects,
					projectsByParent,
				}));
			});
	}

	private initWatchActiveProject(): void {
		this.store
			.select(selectActiveProject)
			.pipe(takeUntil(this.destroy$))
			.subscribe((project) =>
				this.setState((state) => ({ ...state, openProjectRequested: project })),
			);
	}

	private initWatchOpenFolder(): void {
		this.store
			.select(selectSelectedFolderId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((openFolderRequested) => {
				this.setState((state) => ({
					...state,
					openFolderRequested,
				}));
			});
	}

	private initArchivableProjects(): void {
		combineLatest([this.store.select(selectUserId), this.store.select(selectAllActiveProjects)])
			.pipe(
				map(([userId, projects]) =>
					projects.filter(
						(innerProject) =>
							innerProject.projectType === ProjectType.PROJECT &&
							innerProject.lastEditedDate < this.cutoffTime &&
							isUserInternalProjectAdmin(userId, innerProject),
					),
				),
				takeUntil(this.destroy$),
			)
			.subscribe((archivableProjects) =>
				this.setState((state) => ({ ...state, archivableProjects })),
			);
	}
}
