import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { File as FileEntity, FileType } from 'domain-entities';
import { selectUserId } from '@store/selectors/app.selectors';
import { getUnixTime } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { v4 as uuid } from 'uuid';
import {
	FileUploadService,
	ProjectFileUploadParameters,
} from '@injectables/services/file-upload/file-upload.service';
import { ProjectFilesConnector } from '@shared/firebase/connectors/firestore/collections/project-file/project-files.connector';
import { Node, Tree } from '@craftnote/shared-utils';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';
import { BaseFileService } from '@injectables/services/base-file.service';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { TranslateService } from '@ngx-translate/core';

type FileTemplate = Pick<
	FileEntity,
	'projectId' | 'creationTimestamp' | 'creatorId' | 'lastModifiedTimestamp' | 'lastModifiedBy'
>;

@Injectable()
export class FolderUploadService {
	private readonly store = inject(Store);
	private readonly fileUploadService = inject(FileUploadService);
	private readonly projectFilesConnector = inject(ProjectFilesConnector);
	private readonly baseFileService = inject(BaseFileService);
	private readonly notificationService = inject(NotificationSnackbarService);
	private readonly translateService = inject(TranslateService);

	async uploadFolder(
		root: DataTransfer | FileList,
		projectId: string,
		rootFolderId?: string,
	): Promise<void> {
		const userId = await firstValueFrom(this.store.select(selectUserId));
		const now = getUnixTime(new Date());
		const template: FileTemplate = {
			projectId,
			lastModifiedTimestamp: now,
			creationTimestamp: now,
			creatorId: userId,
			lastModifiedBy: userId,
		};
		await this.uploadFolderWithTemplate(root, template, rootFolderId);
	}

	private async uploadFolderWithTemplate(
		root: DataTransfer | FileList,
		template: FileTemplate,
		rootFolderId?: string,
	): Promise<void> {
		let tree: Tree<FileSystemEntry>;
		if (root instanceof DataTransfer) {
			tree = new Tree<FileSystemEntry>();
			for (const item of Array.from(root.items)) {
				await this.buildTreeFromFileSystemEntry(
					(item as DataTransferItem).webkitGetAsEntry(),
					tree.root,
				);
			}
		} else {
			tree = this.buildTreeFromFileList(root as FileList);
		}
		await this.uploadTree(tree, template, rootFolderId);
	}

	private async buildTreeFromFileSystemEntry(
		item: FileSystemEntry,
		parent: Node<FileSystemEntry>,
	): Promise<Tree<FileSystemEntry>> {
		const newNode = parent.addChild(item);

		if (item?.isDirectory) {
			const dirReader = (item as FileSystemDirectoryEntry).createReader();
			await new Promise((resolve) =>
				dirReader.readEntries(async (entries: any[]) => {
					const promises: Promise<unknown>[] = [];
					for (const entry of entries) {
						promises.push(this.buildTreeFromFileSystemEntry(entry, newNode));
					}
					await Promise.all(promises);
					resolve(true);
				}),
			);
		}
		return newNode.tree;
	}

	private buildTreeFromFileList(list: FileList): Tree<FileSystemEntry> {
		const intermediateConstructor = (path) =>
			({
				isDirectory: true,
				name: path,
			} as FileSystemDirectoryEntry);
		const pathComparator = (path: string, node: FileSystemEntry) => path === node.name;

		const tree = new Tree<FileSystemEntry>();
		for (const file of Array.from(list)) {
			const fileEntry: FileSystemFileEntry = {
				isDirectory: false,
				isFile: true,
				name: file.name,
				file(callback: (file: File) => void) {
					callback(file);
				},
			} as FileSystemFileEntry;
			tree.addByPath(
				file.webkitRelativePath,
				fileEntry,
				intermediateConstructor,
				pathComparator,
				true,
			);
		}
		return tree;
	}

	private async uploadTree(
		tree: Tree<FileSystemEntry>,
		template: FileTemplate,
		rootFolderId?: string,
	): Promise<void> {
		const folderIdByFolder = new Map<FileSystemEntry, string>();
		const uploads: Promise<unknown>[] = [];

		await tree.traverseDepthFirst(async (node) => {
			if (!node.data) {
				return;
			}

			const id = uuid();
			const fullFile: FileEntity = {
				...template,
				name: node.data.name,
				id,
			};

			if (node.parent?.data) {
				fullFile.folderId = folderIdByFolder.get(node.parent.data);
			} else if (rootFolderId) {
				fullFile.folderId = rootFolderId;
			}

			if (node.data.isDirectory) {
				fullFile.type = FileType.FOLDER;
				folderIdByFolder.set(node.data, id);
				await this.projectFilesConnector.createProjectFile(fullFile);
			} else {
				const file = await new Promise<File>((resolve) => {
					(node.data as FileSystemFileEntry).file((fileInner) => resolve(fileInner));
				});
				if (file.size === 0) {
					return;
				}
				if (!file.name.includes('.')) {
					this.notificationService.show(BasicSnackbarComponent, {
						componentTypes: {
							description: this.translateService.instant(
								'files-section.messages.file-upload.no-extension-error',
								{ fileName: file.name },
							),
							icon: 'error',
							type: 'warn',
						},
						level: 1,
						timeout: 5000,
					});
					return;
				}
				fullFile.name = this.baseFileService.getFilenameWithLowercaseExtension(fullFile.name);
				fullFile.size = file.size;
				fullFile.type = this.fileUploadService.getFileType(node.data.name);
				const parameters: ProjectFileUploadParameters = {
					id,
					projectId: template.projectId,
					file,
					fileName: id,
					context: FileContext.PROJECT,
					folderId: fullFile.folderId,
				};
				/**
				 * We are not awaiting the upload here, because we want to queue all files in parallel.
				 */
				uploads.push(
					this.fileUploadService
						.uploadFile(parameters)
						.then(() => this.projectFilesConnector.createProjectFile(fullFile)),
				);
			}
		});
		await Promise.all(uploads);
	}
}
