/**
 * This function wraps the legacy readAsArrayBuffer method into a promise returning
 * method. This util is a stop gap to be used instad of Blob.arrayBuffer since that
 * feature is not available in all common browsers
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer
 *
 * @param blob
 */
export function readFileAsync(blob: Blob): Promise<ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result as ArrayBuffer);
		};

		reader.onerror = reject;

		reader.readAsArrayBuffer(blob);
	});
}
