import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedSliderDialogData } from '../../entities/shared-slider-dialog.data';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { setNavbarStateAction, setSidebarStateAction } from '@store/actions/app.actions';
import { StepCompletedEvent } from '../shared-slider-dialog/shared-slider-dialog.component';

@Component({
	selector: 'app-shared-slider',
	templateUrl: './shared-slider.component.html',
	styleUrls: ['./shared-slider.component.scss'],
})
export class SharedSliderComponent implements OnInit, OnDestroy {
	@Input() slides: SharedSliderDialogData;
	@Input() sliderCanBeCanceled = true;

	@Output() changes = new EventEmitter<Boolean[]>();
	@Output() stepCompleted = new EventEmitter<StepCompletedEvent>();
	@Output() complete = new EventEmitter<unknown[]>();
	@Output() close = new EventEmitter<void>();

	constructor(private readonly store: Store<AppState>) {}

	/**
	 * Making sure this shared slider always runs in the fullscreen mode
	 */
	ngOnInit(): void {
		this.store.dispatch(setSidebarStateAction({ state: false }));
		this.store.dispatch(setNavbarStateAction({ state: false }));
	}

	ngOnDestroy(): void {
		this.store.dispatch(setSidebarStateAction({ state: true }));
		this.store.dispatch(setNavbarStateAction({ state: true }));
	}

	onChanges(event: boolean[]): void {
		this.changes.emit(event);
	}

	onStepCompleted(event: StepCompletedEvent): void {
		this.stepCompleted.emit(event);
	}

	onComplete(event: unknown[]): void {
		this.complete.emit(event);
	}

	onClose(): void {
		this.close.emit();
	}
}
