<div appEventStopPropagation class="p-4">
	<h1>{{ 'project.sort.title' | translate }}</h1>
	<div class="d-flex my-3">
		<ng-container [ngTemplateOutlet]="sortDirection" [ngTemplateOutletContext]="{ $implicit: OrderByType.ASC }"></ng-container>
		<ng-container [ngTemplateOutlet]="sortDirection" [ngTemplateOutletContext]="{ $implicit: OrderByType.DESC }"></ng-container>
	</div>
	<form [formGroup]="sortFormGroup">
		<mat-radio-group formControlName="sortBy" class="d-flex flex-column">
			<mat-radio-button *ngFor="let sortByType of SortByTypeKeys" [value]="sortByType">{{
				translationKeysBySortType[sortByType] | translate
			}}</mat-radio-button>
		</mat-radio-group>

		<mat-divider class="mb-3 mt-2"></mat-divider>
		<div (click)="openGroupingPaywallIfNoRights()">
			<h1 [class.disabled-heading]="!(userHasGroupingProfileLimit$ | async)">{{ 'project.group.title' | translate }}</h1>
			<mat-radio-group class="d-flex flex-column" formControlName="groupBy">
				<mat-radio-button *ngFor="let groupByType of GroupByOptionKeys" [value]="groupByType">{{
					translationKeysByGroupingType[groupByType] | translate
				}}</mat-radio-button>
			</mat-radio-group>
		</div>
	</form>
</div>
<app-paywall-cta *ngIf="!(userHasGroupingProfileLimit$ | async)" (click)="openGroupingPaywallIfNoRights()">{{
	'projectList_filters_paywallButton' | translate
}}</app-paywall-cta>

<ng-template #sortDirection let-sortDirection>
	<div
		[ngClass]="{ 'color-primary': activeSortDirection === sortDirection }"
		class="d-flex align-items-center mr-3 pointer"
		(click)="setSortDirection(sortDirection)"
	>
		<mat-icon class="mr-1" [svgIcon]="getSortDirectionIconName(sortDirection)"></mat-icon>
		<span>{{ getSortDirectionString(sortDirection) | translate }}</span>
	</div>
</ng-template>
