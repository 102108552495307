import { Action, createReducer, on } from '@ngrx/store';
import * as fromTradeOptions from '../actions/trade-options.actions';
import { TradeOptionsState } from '../state/trade-options.state';

const initialState: TradeOptionsState = { options: undefined };

const reducer = createReducer(
	initialState,
	on(fromTradeOptions.setTradeOptionsActions, (_state, payload) => {
		return { options: payload.options };
	}),
);

// tslint:disable-next-line:typedef
export function tradeOptionsReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
