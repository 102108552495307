import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Observable } from 'rxjs';
import { isProfile, Profile } from 'domain-entities';

export const PROFILE_REQUIRED_FIELDS: any = [
	'name',
	'lastname',
	'email',
	'company',
	'toc.version',
	'toc.status',
	'toc.setAt',
];

@Injectable({
	providedIn: 'root',
})
export class ProfileConnector {
	static readonly COLLECTION_NAME = 'profiles';

	constructor(private readonly connector: FirestoreConnector) {}

	getProfileById(id: string): Observable<Profile> {
		return this.connector.watchDocument<Profile>(
			ProfileConnector.COLLECTION_NAME + '/' + id,
			isProfile,
		);
	}

	async checkProfileExists(id: string): Promise<boolean> {
		try {
			await this.connector.getDocument<Profile>(
				ProfileConnector.COLLECTION_NAME + '/' + id,
				isProfile,
			);
			return true;
		} catch (e) {
			return false;
		}
	}

	createProfile(uuid: string, profile: Profile): Promise<void> {
		return this.connector.create(ProfileConnector.COLLECTION_NAME, uuid, profile, isProfile);
	}

	updateProfile(uuid: string, profile: Partial<Profile>): Promise<void> {
		return this.connector.updateDocumentPartial<Profile>(
			ProfileConnector.COLLECTION_NAME,
			uuid,
			profile,
			PROFILE_REQUIRED_FIELDS,
		);
	}

	async updateProfileTransactional(
		uuid: string,
		updateFunction: (oldProject: Profile) => Partial<Profile> | undefined,
	): Promise<void> {
		await this.connector.updateDocumentTransactional<Profile>(
			ProfileConnector.COLLECTION_NAME,
			uuid,
			updateFunction,
			isProfile,
		);
	}
}
