import { createAction, props } from '@ngrx/store';
import { Project } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';

export const updateActiveProjectsAction = createAction(
	'[Project] update active Projects',
	props<{ changes: EntityChanges<Project> }>(),
);

export const updateArchivedProjectsAction = createAction(
	'[Project] update archived Projects',
	props<{ changes: EntityChanges<Project> }>(),
);

export const updateDeletedProjectsAction = createAction(
	'[Project] update deleted Projects',
	props<{ changes: EntityChanges<Project> }>(),
);

export const setSelectedFolderAction = createAction(
	'[Project] set selected folder',
	props<{ folderId: string | undefined }>(),
);

export const projectsDeletedAction = createAction(
	'[Project] delete Project',
	props<{ projects: string[] }>(),
);

export const clearProjectsStateAction = createAction('[Project] clear projects state');

export const initActiveAndArchivedProjectsAction = createAction(
	'[Project] init loading active and archived projects of user',
);

export const initArchivedProjectsAction = createAction(
	'[Project] init loading archived projects of user',
);

export const initDeletedProjectsAction = createAction(
	'[Project] init loading deleted projects of user',
);

export const setActiveProjectsSearchTerm = createAction(
	'[Project] Set search term for active projects',
	props<{ activeProjectsSearchTerm: string }>(),
);

export const setArchivedProjectsSearchTerm = createAction(
	'[Project] Set search term for archived projects',
	props<{ archivedProjectsSearchTerm: string }>(),
);

export const initSelectedProjectsAction = createAction('[Project] Init selected projects');
