import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '@injectables/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-user-deactivated-dialog',
	templateUrl: './user-deactivated-dialog.component.html',
	styleUrls: ['./user-deactivated-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UserDeactivatedDialogComponent {
	private isOwner$ = this.store.select(isOwnerOfCompany);
	bodyOneText$ = this.isOwner$.pipe(
		map((isOwner) =>
			this.translate.instant(
				isOwner
					? 'toc.dialog.userDeactivated.owner.bodyOneTxt'
					: 'toc.dialog.userDeactivated.nonOwner.bodyOneTxt',
			),
		),
	);

	bodyTwoText$ = this.isOwner$.pipe(
		map((isOwner) =>
			this.translate.instant(
				isOwner
					? 'toc.dialog.userDeactivated.owner.bodyTwoTxt'
					: 'toc.dialog.userDeactivated.nonOwner.bodyTwoTxt',
			),
		),
	);

	constructor(
		private readonly dialogRef: MatDialogRef<UserDeactivatedDialogComponent>,
		private readonly authService: AuthService,
		private readonly store: Store<AppState>,
		private readonly translate: TranslateService,
	) {}

	async clickHandler(): Promise<void> {
		await this.authService.signOut();
		this.dialogRef.close();
	}
}
