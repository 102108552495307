import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProjectsSelectionListComponent } from './projects-selection-list.component';
import { SharedProjectsModule } from '../../../features/projects/shared-projects.module';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { IsNilPipeModule } from '../../pipes/is-nil/is-nil-pipe.module';
import { ProjectAddressPipeModule } from '../../pipes/project-address/project-address-pipe.module';

@NgModule({
	declarations: [ProjectsSelectionListComponent],
	imports: [
		SharedModule,
		MatDialogModule,
		MatButtonModule,
		MatInputModule,
		MatIconModule,
		ScrollingModule,
		SharedProjectsModule,
		MatIconSizeModule,
		MatCheckboxModule,
		MatRadioModule,
		IsNilPipeModule,
		ProjectAddressPipeModule,
	],
	exports: [ProjectsSelectionListComponent],
})
export class ProjectsSelectionListModule {}
