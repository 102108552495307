import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
	checkEmailVerificationStatusAction,
	initAuthChangeSubscriptionAction,
	resendVerificationAction,
	sendEmailVerificationLink,
	setUserRegistrationType,
	updateCurrentProfileAction,
	updateUserStateAction,
} from '../actions/auth.actions';
import { filter, map, pairwise, skip, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { combineLatest, of } from 'rxjs';
import { AppState } from '../state/app.state';
import { AuthLifecycle, isLoggedInState, UserRegistrationType } from '../state/auth.state';
import { ActivatedRoute, Router } from '@angular/router';
import { clearCompanyAndLimitsAction, reloadCompanyAction } from '../actions/company.actions';
import { AuthService } from '@injectables/services/auth/auth.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { clearProjectStatuses, clearWorkTypesAction } from '../actions/company-settings.actions';
import { loadTrailSubscriptionAction } from '../actions/subscription.actions';
import {
	selectAuthLifecycle,
	selectProfile,
	selectUserRegistrationType,
} from '../selectors/app.selectors';
import { updateLastActiveAction } from '../actions/profile.actions';
import { clearProjectsStateAction } from '../actions/project.actions';
import { clearExports } from '../actions/export.actions';
import moment from 'moment';
import { clearCompanyProjects } from '../actions/company-projects.actions';
import { clearInvitationLink, clearInvitations } from '../actions/invitations.actions';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { EXAMPLE_PROJECT_NAMES } from '@injectables/services/project/project.service';
import { clearProfileLimits } from '../actions/profile-limits.actions';
import { clearProjectFilesAction } from '../actions/project-files.actions';
import { clearUserStateAction } from '../actions/external-chat.actions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppInitStatus } from '../state/app-init.state';
import { setAppInitState } from '../actions/app-init.actions';
import { TrackingService } from '@injectables/services/tracking.service';
import { clearProjectUnreadsState } from '@store/actions/project-unreads.actions';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';

@Injectable()
export class AuthEffects implements OnInitEffects {
	logoutWhenAuthChangeWithoutProfile$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(initAuthChangeSubscriptionAction),
				switchMapTo(
					this.afAuth.onAuthStateChanged(async (user) => {
						if (user) {
							const [userRegistrationState, profile] = await combineLatest([
								this.store.select(selectUserRegistrationType),
								this.profileService.getProfile(user.uid),
							])
								.pipe(take(1))
								.toPromise();

							if (userRegistrationState === UserRegistrationType.UNAUTHENTICATED && !profile) {
								await this.afAuth.signOut();
							}
						}
					}),
				),
			),
		{ dispatch: false },
	);

	setRegistrationType$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(initAuthChangeSubscriptionAction),
				switchMapTo(this.afAuth.authState),
				tap((authState) => {
					if (!authState) {
						this.store.dispatch(setAppInitState({ state: AppInitStatus.BEFORE }));
					}
				}),
				filter((authState) => Boolean(authState?.uid)),
				switchMap((authState) =>
					this.profileService.getProfile(authState?.uid).pipe(
						filter(Boolean),
						take(1),
						map(() => authState),
					),
				),
				tap(async (authState) => {
					/**
					 * Should fire initially to make sure everything is hidden under the feature flag
					 */
					this.store.dispatch(setAppInitState({ state: AppInitStatus.IN_PROGRESS }));

					let registrationType: UserRegistrationType = UserRegistrationType.UNAUTHENTICATED;
					let appInitState = AppInitStatus.BEFORE;
					if (authState) {
						const isFreshRegistration = await this.isNewRegistration();
						registrationType = isFreshRegistration
							? UserRegistrationType.FRESH
							: UserRegistrationType.EXISTING;
						appInitState = AppInitStatus.IN_PROGRESS;
					}

					this.store.dispatch(setAppInitState({ state: appInitState }));
					this.store.dispatch(setUserRegistrationType({ registrationType }));
				}),
			),
		{ dispatch: false },
	);

	initAuthChangeSubscriptionAction$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(initAuthChangeSubscriptionAction),
				switchMap(() => {
					return this.authService.fireBaseAuthState;
				}),
				skip(1), // skip at app initialisation for the first time to make sure not redirect when it is already logged in
				tap(async (authState) => {
					if (await this.isSplashScreenEnabled()) {
						return;
					}

					let navigateUrl = '/';

					if (authState) {
						const isFreshRegistration = await this.isNewRegistration();
						const redirectUrl = this.routerState?.snapshot?.queryParams['redirectUrl'];

						navigateUrl = redirectUrl;

						if (!!this.remoteConfigService.getValue('feature_company_init')) {
							if (isFreshRegistration) {
								navigateUrl = '/company-init';
							}
						}

						if (navigateUrl) {
							await this.router.navigateByUrl(navigateUrl);
						} else {
							await this.projectHelperService.goToHome(
								isFreshRegistration ? EXAMPLE_PROJECT_NAMES[0] : undefined,
							);
						}
					}
				}),
			),
		{ dispatch: false },
	);

	sendEmailVerificationLink = createEffect(
		() =>
			this.actions$.pipe(
				ofType(sendEmailVerificationLink),
				tap(async () => {
					await this.store
						.select(selectProfile)
						.pipe(
							filter((user) => !!user),
							take(1),
						)
						.toPromise();
					await this.authService.sendEmailVerificationLink();
				}),
			),
		{ dispatch: false },
	);

	updateUserStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateUserStateAction),
			switchMap((action) => {
				const userId = action.userId;
				const blockUnverifiedUser = this.remoteConfigService.getValue(
					'feature_block_unverified_user',
				);
				if (!userId) {
					return of({ user: undefined, userId: undefined, lifeCycle: AuthLifecycle.LOGGED_OUT });
				}

				if (blockUnverifiedUser && action.emailVerified === false) {
					return this.profileService.getProfile(userId).pipe(
						map((profile) => {
							return {
								user: profile,
								userId: userId,
								lifeCycle: AuthLifecycle.UNVERIFIED_LOGGED_IN,
							};
						}),
					);
				}
				return this.profileService.getProfile(userId).pipe(
					map((profile) => {
						return { user: profile, userId: userId, lifeCycle: AuthLifecycle.LOGGED_IN };
					}),
				);
			}),
			tap(() => {
				this.store.dispatch(reloadCompanyAction());
				this.store.dispatch(loadTrailSubscriptionAction());
			}),
			map((userInfo) => {
				return updateCurrentProfileAction(userInfo);
			}),
		),
	);

	logOutUserEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(initAuthChangeSubscriptionAction),
				switchMapTo(this.store.select(selectAuthLifecycle)),
				pairwise(),
				/** A log out event is defined as the app state moving from a logged in lifecycle state to
				 * a logged out lifecycle state
				 */
				filter(
					([previousLifecycleState, currentLifecycleState]) =>
						isLoggedInState(previousLifecycleState) &&
						currentLifecycleState === AuthLifecycle.LOGGED_OUT,
				),
				tap(async () => {
					const userRegistrationType = await this.store
						.select(selectUserRegistrationType)
						.pipe(take(1))
						.toPromise();

					[
						clearProjectsStateAction,
						clearExports,
						clearCompanyProjects,
						clearProjectStatuses,
						clearWorkTypesAction,
						clearProfileLimits,
						clearProjectFilesAction,
						clearUserStateAction,
						clearInvitations,
						clearCompanyAndLimitsAction,
						clearProjectUnreadsState,
					].forEach((action) => this.store.dispatch(action()));

					this.notificationSnackbarService.hideAll();

					if (
						[UserRegistrationType.EXISTING, UserRegistrationType.FRESH].includes(
							userRegistrationType,
						)
					) {
						this.store.dispatch(clearInvitationLink());
						await this.router.navigate(['/']);
					}

					await this.trackingService.removeCurrentUser();
				}),
			),
		{ dispatch: false },
	);

	logInEffect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateUserStateAction),
				filter((state) => !!state.userId),
				map(() => updateLastActiveAction()),
			),
		{ dispatch: true },
	);

	resendVerficationEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(resendVerificationAction),
				tap(() => {
					this.authService.resendVerification();
				}),
			),
		{ dispatch: false },
	);

	checkVerficationStatusEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(checkEmailVerificationStatusAction),
				tap(() => {
					this.authService.reloadUser();
				}),
			),
		{ dispatch: false },
	);

	constructor(
		private readonly afAuth: AngularFireAuth,
		private readonly authService: AuthService,
		private readonly profileService: ProfileService,
		private readonly store: Store<AppState>,
		private readonly actions$: Actions,
		private readonly router: Router,
		private readonly remoteConfigService: RemoteConfig,
		private readonly routerState: ActivatedRoute,
		private readonly projectHelperService: ProjectHelperService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly trackingService: TrackingService,
	) {}

	private async isNewRegistration(): Promise<boolean> {
		/**
		 * In order to detect a situation in which a profile was freshly created we look at the profiles
		 * tocDateAgreed (which is set to the current time during profile creation) and check whether it was set
		 * less than a minute ago. This is a heuristic but should work quite reliably.
		 */
		const profile = await this.store
			.select(selectProfile)
			.pipe(
				filter((prof) => !!prof),
				take(1),
			)
			.toPromise();
		const now = moment();
		const agreed = moment.unix(profile.tocDateAgreed);
		return agreed.isValid() && moment.duration(now.diff(agreed)).asSeconds() < 30;
	}

	private async isSplashScreenEnabled(): Promise<boolean> {
		return !!(await this.remoteConfigService.getValueAsync('feature_splash_screen'));
	}

	ngrxOnInitEffects(): Action {
		return initAuthChangeSubscriptionAction();
	}
}
