<div *ngIf="isLoaded()" class="font-weight-light" [class.d-none]="isUserAuthenticated$ | async">
	<app-banner></app-banner>
	<div class="register-screen align-items-center pt-2 pt-lg-5 mb-5 px-3 d-flex justify-content-end">
		<div class="flex-grow-1 d-md-none">
			<img alt="Logo" src="assets/images/icons/craftnote_back_orange.svg" />
		</div>
		<p class="mr-3 m-0">{{ 'login.account' | translate }}</p>
		<button color="accent" mat-stroked-button (click)="goToRegister()" data-cy="home-page-register">
			{{ 'login.register' | translate }}
		</button>
		<br />
	</div>
	<div class="col-12 col-lg-6 offset-lg-6 login-view authentication-viewer-outer">
		<div class="authentication-center-middle">
			<div class="vertical-center-inner">
				<h1 class="h3 text-center mb-4 font-weight-bold">
					{{ 'login.' + humanizedDayGreeting | translate }}
				</h1>
				<div
					*ngIf="invitationLink$ | async as link"
					[innerHTML]="'invitations.login-hint-text' | translate: { companyName: link?.companyName }"
					class="mat-body-1 mb-3 p-3 hint-text border-0 text-break"
				></div>
				<form #loginForm="ngForm" (ngSubmit)="login()" class="form pt-3" role="form">
					<div class="form-group mb-4">
						<mat-form-field class="w-100">
							<input
								[(ngModel)]="username"
								email
								matInput
								name="email"
								[pattern]="emailPattern"
								placeholder="{{ 'login.email' | translate }}"
								(keyup)="trimEmail()"
								required
								type="text"
							/>
							<mat-icon matPrefix>mail_outline</mat-icon>
							<mat-error
								*ngIf="loginForm.controls.email?.dirty && loginForm.controls.email?.invalid && !loginForm.controls.email?.errors?.required"
							>
								{{ 'error.email.valid' | translate }}
							</mat-error>
							<mat-error *ngIf="loginForm.controls.email?.errors?.required">
								{{ 'error.required' | translate }}
							</mat-error>
						</mat-form-field>
					</div>
					<div class="form-group mb-3">
						<mat-form-field class="w-100">
							<input
								[(ngModel)]="password"
								matInput
								name="password"
								[class.ngInvalid]="loginForm.controls.password?.errors"
								placeholder="{{ 'login.password' | translate }}"
								[type]="isPasswordVisibility ? 'password' : 'text'"
								required
							/>
							<mat-icon matPrefix svgIcon="custom-lock"></mat-icon>
							<mat-icon (click)="isPasswordVisibility = !isPasswordVisibility" appEventStopPropagation class="pointer" matSuffix>
								{{ isPasswordVisibility ? 'visibility' : 'visibility_off' }}
							</mat-icon>

							<mat-error *ngIf="loginForm.controls.password?.errors?.required">
								{{ 'error.required' | translate }}
							</mat-error>
						</mat-form-field>
					</div>

					<div class="from-group row">
						<div class="col-12 text-right">
							<a class="align-middle" routerLink="password-forgotten">
								<span class="color-primary font-weight-bold">{{ 'login.forgot-password' | translate }}</span>
							</a>
						</div>
					</div>

					<br />

					<div class="form-group row d-flex align-items-center justify-content-md-center">
						<div class="col-6 text-center login-button-container">
							<button mat-flat-button color="accent" [disabled]="loginForm.form.invalid" class="w-100" type="submit">
								{{ 'login.button' | translate }}
							</button>
						</div>
					</div>
				</form>

				<div class="or-divider text-center">
					<span>{{ 'login.or' | translate }}</span>
				</div>

				<div class="login-buttons text-center d-flex align-items-center flex-column">
					<button
						(click)="authService.loginWithSocialProvider(SignInProvider.FACEBOOK)"
						mat-flat-button
						class="icon-button provider-button facebook-login"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="facebook-brand"></mat-icon>
						<span>{{ 'login.btn-facebook' | translate }}</span>
					</button>
					<button
						(click)="authService.loginWithSocialProvider(SignInProvider.GOOGLE)"
						mat-flat-button
						class="icon-button google-login provider-button"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="google-brand"></mat-icon>
						<span>{{ 'login.btn-google' | translate }}</span>
					</button>
					<button
						(click)="authService.loginWithSocialProvider(SignInProvider.APPLE)"
						mat-flat-button
						class="icon-button apple-login provider-button"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="apple-brand"></mat-icon>
						<span>{{ 'login.btn-apple' | translate }}</span>
					</button>

					<div class="row justify-content-between badges-container">
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="madeInGermany"></app-trust-badge>
						</div>
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="dsgvo-fill"></app-trust-badge>
						</div>
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="refund"></app-trust-badge>
						</div>
					</div>

					<div class="from-group mt-5 mb-5 d-flex justify-content-center terms-and-conditions">
						<a (click)="linkService.openLinkInNewTab(linkService.imprintLink)" class="mr-3" href="#">
							<span>{{ 'settings.imprint' | translate }}</span>
						</a>
						<a (click)="linkService.openLinkInNewTab(linkService.tocLink)" class="mr-3" href="#">
							<span>{{ 'toc.agb' | translate }}</span>
						</a>
						<a (click)="linkService.openLinkInNewTab(linkService.legalNoticeLink)" href="#">
							<span>{{ 'toc.datenschutz' | translate }}</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
