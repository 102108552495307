import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Subscription } from 'domain-entities';
import { SubscriptionsState } from '../state/subscriptions.state';
import { StoreLoadingStatus } from '../entities/store-loading-status';
import { setSubscriptionEntities } from '../actions/subscription.actions';
import { updateEntityStore } from './functions/update-entity-state.functions';

const adapter = createEntityAdapter<Subscription>();
const initialState: SubscriptionsState = adapter.getInitialState({
	loadingStatus: StoreLoadingStatus.INITIAL,
});

const reducer = createReducer(
	initialState,
	on(setSubscriptionEntities, (state, { changes }) => {
		return updateEntityStore<Subscription>(adapter, state, changes);
	}),
);

// tslint:disable-next-line:typedef
export function subscriptionsReducer(state: SubscriptionsState | undefined, action: Action) {
	return reducer(state, action);
}
