<ng-template [ngIf]="(active$ | async) !== null">
	<div [ngSwitch]="active$ | async" class="w-100 d-flex align-items-center banner pl-3">
		<mat-icon class="mr-3">error_outline</mat-icon>
		<span *ngSwitchCase="WarningBannerType.COMPATIBILITY">
			{{ 'browser.supportAndCompatibility.error' | translate }}
			<a
				href="https://hilfe.mycraftnote.de/hc/de/articles/4410168187154-Welchen-Browser-empfehlen-wir-f%C3%BCr-die-Nutzung-der-Web-App-"
				target="_blank"
				rel="noopener"
				class="ml-1 skip-a-styles text-decoration-underline font-weight-bold"
				>{{ 'browser.supportAndCompatibility.error.moreText' | translate }}</a
			>
		</span>
		<span *ngSwitchCase="WarningBannerType.CONFIGURATION">{{ 'browser.configuration.error' | translate }}</span>
	</div>
</ng-template>
