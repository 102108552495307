import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { EntityChanges } from '@craftnote/shared-utils';
import { isSubscription, Subscription } from 'domain-entities';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionsConnector {
	static readonly COLLECTION_NAME = 'subscriptions';

	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	loadSubscriptions(companyId: string): Observable<EntityChanges<Subscription>> {
		const condition: DataQueryCondition<Subscription> = {
			field: 'companyId',
			operator: '==',
			value: companyId,
		};
		return this.firestoreConnector.watchDocumentsChanges<Subscription>(
			SubscriptionsConnector.COLLECTION_NAME,
			[condition],
			isSubscription,
		);
	}
}
