import { Injectable } from '@angular/core';
import { CompanyService } from '@injectables/services/company/company.service';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as companyActions from '../actions/company.actions';
import { filter, map, switchMap, switchMapTo } from 'rxjs/operators';
import { selectCompanyId } from '../selectors/app.selectors';
import { of } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { CompanyEmployee } from 'domain-entities';
import { retryOnError } from '@craftnote/shared-utils';

@Injectable()
export class CompanyEffects {
	constructor(
		private actions$: Actions,
		private companyService: CompanyService,
		private store: Store<AppState>,
	) {}

	updateCompanyByProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(companyActions.reloadCompanyAction),
			switchMap(() =>
				this.store.select(selectCompanyId).pipe(
					switchMap((companyId) => {
						if (!companyId) {
							return of(undefined);
						}
						return this.companyService.getCompanyById(companyId).pipe(retryOnError());
					}),
				),
			),
			map((company) => companyActions.setCompanyStateAction({ company })),
		),
	);

	updateCompanyLimitsByProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(companyActions.reloadCompanyAction),
			switchMap(() => this.store.select(selectCompanyId).pipe(filter<string>(Boolean))),
			switchMap((companyId) =>
				this.companyService.getCompanyLimits(companyId).pipe(retryOnError()),
			),
			map((companyLimits) => companyActions.setCompanyLimitsActions({ companyLimits })),
		),
	);

	updateCompany$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(companyActions.updateCompanyAction),
				switchMap((action) => {
					return this.companyService.updateCompanyPartial(action.companyId, action.company);
				}),
			),
		{ dispatch: false },
	);

	updateCompanyMembers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(companyActions.initWatchCompanyMembers),
			switchMapTo(this.store.select(selectCompanyId)),
			switchMap((companyId) => {
				return this.companyService.getCompanyEmployees(companyId);
			}),
			map((changes: EntityChanges<CompanyEmployee>) => {
				return companyActions.updateCompanyMembers({ changes });
			}),
		),
	);

	ngrxOnInitEffects(): Action {
		return companyActions.initWatchCompanyMembers();
	}
}
