import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Export } from 'domain-entities';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-export-snackbar',
	templateUrl: './export-snackbar.component.html',
	styleUrls: ['./export-snackbar.component.scss'],
})
export class ExportSnackbarComponent {
	constructor(private readonly translationService: TranslateService) {}

	@Input() hasRunningExports: boolean;

	@Input() hasFailedExports: boolean;

	@Input() runningExports: Export[] = [];

	@Input() failedExports: Export[] = [];

	@Output() complete = new EventEmitter<void>();

	@Output() openDetails = new EventEmitter<void>();

	get hasOnlyFailed(): boolean {
		return !!this.hasFailedExports && !this.hasRunningExports;
	}

	get exportText(): string {
		let text = '';
		if (this.hasRunningExports) {
			text += this.translationService.instant('exportToast.info', {
				numberOfRunningExports: this.runningExports.length,
			});
		}
		if (this.hasFailedExports) {
			if (this.hasRunningExports) {
				text += '; ';
			}
			text += this.translationService.instant('exportToast.failed', {
				numberOfFailedExports: this.failedExports.length,
			});
		}
		return text;
	}
}
