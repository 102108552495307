import { Injectable } from '@angular/core';
import { PROJECT_EVENTS } from '@shared/models/project.type';
import { Router } from '@angular/router';
import { ProjectService } from '@injectables/services/project/project.service';
import { ARCHIVE_ROUTE_SEGMENT, PROJECTS_ROUTE_SEGMENT } from '@constants/routing.constants';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class ProjectHelperService {
	constructor(
		private router: Router,
		private store: Store,
		private localStorageService: LocalStorageService,
		private readonly projectService: ProjectService,
	) {}

	isInPath(path: string): boolean {
		const regEx = new RegExp(`${path}`);
		return !!this.router.url.split('/').find((urlPath) => regEx.test(urlPath));
	}

	setLastOpenedProject(projectId: string): void {
		if (this.isInPath(PROJECT_EVENTS.ARCHIVE)) {
			this.localStorageService.set('projectArchived', projectId);
		} else if (this.isInPath(PROJECT_EVENTS.PROJECT)) {
			this.localStorageService.set('project', projectId);
		}
	}

	getLastOpenedProject(workType?: 'projects' | 'archive'): Promise<string> {
		if (workType === 'projects' || this.isInPath(PROJECT_EVENTS.PROJECT)) {
			return this.localStorageService.get('project');
		} else if (workType === 'archive' || this.isInPath(PROJECT_EVENTS.ARCHIVE)) {
			return this.localStorageService.get('projectArchived');
		}
	}

	async goToHome(projectName?: string, archive?: boolean): Promise<void> {
		const projectId = await this.projectService.getIdOfStandardProject(projectName, archive);
		this.store.dispatch(setSelectedFolderAction({ folderId: null }));
		await this.router.navigate([
			archive ? ARCHIVE_ROUTE_SEGMENT : PROJECTS_ROUTE_SEGMENT,
			projectId || '',
		]);
	}
}
