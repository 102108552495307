<section class="w-100 tracked-times-projects-section h-100 position-relative">
	<app-tracked-times-list [class.d-none]="trackedTimesAddEditPanelVisible"></app-tracked-times-list>

	<app-tracked-time-add-edit-panel
		#trackedTimesAddEditPanelRef
		(closePanel)="openTrackedTimesListView()"
		[class.d-none]="!trackedTimesAddEditPanelVisible"
		[showProjectRedirection]="false"
		[showAssigneePaywall]="false"
	>
	</app-tracked-time-add-edit-panel>

	<button
		[class.d-none]="trackedTimesAddEditPanelVisible"
		(click)="openTrackedTimesCreateView()"
		class="add-tracked-times-button"
		color="accent"
		mat-fab
		data-cy="add-time-tracking"
	>
		<mat-icon>add</mat-icon>
	</button>
</section>
