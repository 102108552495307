<app-project-list-nudge *ngIf="projectsWithFailedUploads.length > 0" (closeNudge)="closeNudge()" icon="warning_amber">
	<div class="flex-grow-1 min-width-0 project-nudge-content">
		<p class="font-weight-bold">{{ 'failed-uploads-nudge.title' | translate }}</p>
		<div *ngFor="let failedUpload of projectsWithFailedUploads">
			<a [routerLink]="'/projects/' + failedUpload.id + '/files'" class="text-decoration-underline d-block text-truncate">{{
				failedUpload.name
			}}</a>
		</div>
	</div>
</app-project-list-nudge>
