import { Injectable } from '@angular/core';
import { TasksConnector } from '../connectors/tasks/tasks.connector';
import { merge, Observable, of } from 'rxjs';
import { EntityChanges, watchEntitiesWithCache } from '@craftnote/shared-utils';
import { Task } from 'domain-entities';
import { TasksDeletedConnector } from '../connectors/tasks/tasks-deleted.connector';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyTasksService {
	constructor(
		private readonly tasksConnector: TasksConnector,
		private readonly tasksDeletedConnector: TasksDeletedConnector,
	) {}

	getCompanyTasks(companyId: string): Observable<EntityChanges<Task>> {
		if (!companyId) {
			return of({ changeType: 'remove', entities: [] });
		}
		return watchEntitiesWithCache(
			this.buildEntityChanges(companyId),
			'companyTasks',
			companyId,
			'companyId',
			'lastEditedTimestamp',
		);
	}

	buildEntityChanges(companyId): (cutoffTime: number) => Observable<EntityChanges<Task>> {
		return (cutoffTime: number) => {
			const additionsAndUpdates$ = this.tasksConnector.watchCompanyTasksEntityChanges(
				companyId,
				cutoffTime,
			);
			const removals$ = this.tasksDeletedConnector
				.watchDeletedCompanyTasksEntityChanges(companyId, cutoffTime)
				.pipe(map((removal) => ({ ...removal, changeType: 'deleted' as 'deleted' })));

			return merge(additionsAndUpdates$, removals$);
		};
	}
}
