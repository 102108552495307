import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class DatePickerService extends NativeDateAdapter {
	getFirstDayOfWeek(): number {
		return window.navigator.language
			? moment.localeData(window.navigator.language).firstDayOfWeek()
			: 1;
	}
}
