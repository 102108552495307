import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { BasicDropdownComponent } from './basic-dropdown/basic-dropdown.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ChipsDropdownComponent } from './chips-dropdown/chips-dropdown.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

const COMPONENTS = [ChipsDropdownComponent, BasicDropdownComponent];

@NgModule({
	declarations: [COMPONENTS],
	imports: [
		SharedModule,
		MatInputModule,
		MatSelectModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		MatTabsModule,
		FormsModule,
		MatChipsModule,
		ReactiveFormsModule,
	],
	exports: [COMPONENTS],
})
export class DropdownsModule {}
