import { createSelector } from '@ngrx/store';
import { selectWorkRouteState } from '@store/selectors/route.selectors';
import {
	selectActiveProjectsWithParents,
	selectArchivedProjectsWithParents,
} from '@store/selectors/projects.selectors';

export const selectProjectAreaProjects = createSelector(
	selectWorkRouteState,
	selectActiveProjectsWithParents,
	selectArchivedProjectsWithParents,
	(routeState, active, archived) => {
		// The active projects are treated as the default
		if (routeState.workRoute !== 'archive') {
			return active;
		} else {
			return archived;
		}
	},
);
