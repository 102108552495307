import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { NotificationPermission } from '@injectables/services/browser-notification.service';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectNotificationPermission } from '@store/selectors/browser.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { notificationPermissionChangedAction } from '@store/actions/browser.actions';
import { ofType } from '@ngrx/effects';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import moment from 'moment/moment';

/**
 * User will only land over here if notification permission is not granted
 */

@Component({
	selector: 'app-notification-token-permission',
	templateUrl: './notification-token-permission.component.html',
	styleUrls: ['./notification-token-permission.component.scss'],
})
export class NotificationTokenPermissionComponent implements OnDestroy {
	@Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
	NotificationPermission = NotificationPermission;
	currentBrowserNotificationPermission$ = this.store.select(selectNotificationPermission);
	private destroy$: Subject<boolean> = new Subject();

	constructor(
		private readonly store: Store<AppState>,
		private readonly localStorageService: LocalStorageService,
		private readonly actionsSubject: ActionsSubject,
	) {
		this.listenNotificationPermissionChange();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	private listenNotificationPermissionChange(): void {
		this.actionsSubject
			.pipe(
				ofType(notificationPermissionChangedAction),
				filter((changed) => changed.permission !== NotificationPermission.DEFAULT),
				takeUntil(this.destroy$),
			)
			.subscribe(() => this.completed.emit(true));
	}

	async onClickRemindMeLater(): Promise<void> {
		await this.localStorageService.set('notificationReminderTimestamp', moment().unix());
		this.completed.emit(true);
	}
}
