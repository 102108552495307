import { createAction, props } from '@ngrx/store';
import { Company, CompanyEmployee, CompanyLimit } from 'domain-entities';
import { CompanyChangeStatus } from '../state/CompanyState';
import { EntityChanges } from '@craftnote/shared-utils';

export const initWatchCompanyMembers = createAction('[Company] Init Watch Company Members');

export const reloadCompanyAction = createAction('[Company] Reload company');

export const updateCompanyAction = createAction(
	'[Company] Update company',
	props<{ companyId: string; company: Partial<Company> }>(),
);

export const updateCompanyMembers = createAction(
	'[Company] Update company members',
	props<{ changes: EntityChanges<CompanyEmployee> }>(),
);

export const setCompanyStateAction = createAction(
	'[Company] Set companyState',
	props<{ company: Company | undefined }>(),
);

export const setCompanyLimitsActions = createAction(
	'[Company] Set companyLimits',
	props<{ companyLimits: CompanyLimit | undefined }>(),
);

export const clearCompanyAndLimitsAction = createAction('[Company] Clear companyLimits');

export const setCompanyChangeStatusAction = createAction(
	'[Company] Set company change status',
	props<{ companyChangeStatus: CompanyChangeStatus }>(),
);
