import { Injectable } from '@angular/core';
import { Invitation, Member } from 'domain-entities';
import {
	InvitationRequiredOnly,
	InvitationsConnector,
} from '@shared/firebase/connectors/firestore/collections/invitations/invitations.connector';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { combineLatest, firstValueFrom, noop, Observable } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { InvitationDialogService } from '@injectables/services/invitation-dialog.service';
import { selectInvitationLink } from '@store/selectors/invitations.selectors';
import { filter } from 'rxjs/operators';
import { clearInvitationLink } from '@store/actions/invitations.actions';
import {
	selectCompanyId,
	selectUserEmail,
	selectUserId,
	selectUserRegistrationType,
} from '@store/selectors/app.selectors';
import { TranslateService } from '@ngx-translate/core';
import { createCompanyInvitationByCompanyIdAndMember } from '@shared/functions/invitations/invitations.functions';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	CompanymembersInvitationCreatedEventBuilder,
	CompanymembersInvitationRemindCreatedEventBuilder,
	CompanymembersMemberAddedEventBuilder,
} from '@generated/events/CompanymembersEvents.generated';
import { InvitationLink } from '@store/state/invitations.state';
import { UserRegistrationType } from '@store/state/auth.state';
import { CompanyService } from '../company/company.service';
import { AlertService } from '@injectables/services/alert/alert.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AddEditCompanyEmployeeContext } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.component';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { selectIsTrialSubscription } from '@store/selectors/subscription.selectors';

const InvitationsErrorTranslationKeyMapper = {
	validation_failed: 'companyInvitations.error.validationFailed',
	not_found: 'companyInvitations.error.notFound',
	illegal_state_change: 'companyInvitations.error.illegalStateChange',
	invitation_expired: 'companyInvitations.error.invitationExpired',
	unable_to_leave_company: 'companyInvitations.error.unableToLeaveTheCompany',
	invitation_user_matches_creator: 'companyInvitations.error.matchesTheCreator',
	invalid_access: 'companyInvitations.error.invalidAccess',
};

export type AddCompanyMemberImprovementRemoteConfig =
	| 'inviteUserProjectCreationFlow'
	| 'inviteUserRegistrationFlow'
	| 'existingFlow';

@Injectable({
	providedIn: 'root',
})
export class InvitationService {
	constructor(
		private readonly invitationsConnector: InvitationsConnector,
		private readonly functions: AngularFireFunctions,
		private readonly store: Store<AppState>,
		private readonly invitationDialogService: InvitationDialogService,
		private readonly translate: TranslateService,
		private readonly notificationService: NotificationSnackbarService,
		private readonly trackingService: TrackingService,
		private readonly companyService: CompanyService,
		private readonly alertService: AlertService,
		private readonly router: Router,
		private readonly location: Location,
		private readonly remoteConfig: RemoteConfig,
	) {}

	getCompanyMemberImprovementRemoteConfig(): AddCompanyMemberImprovementRemoteConfig {
		return this.remoteConfig.getConfig(
			'add_company_member_improvement',
		) as AddCompanyMemberImprovementRemoteConfig;
	}

	async create(
		invite: InvitationRequiredOnly,
		context: AddEditCompanyEmployeeContext = 'company-members',
	): Promise<void> {
		const isTrialSubscription = await firstValueFrom(this.store.select(selectIsTrialSubscription));
		await this.invitationsConnector.createInvitation(invite);
		await this.trackingService.trackEvent(
			new CompanymembersInvitationCreatedEventBuilder({
				role: invite.context.memberRole.toLowerCase(),
				context,
				experiment: 'add-company-member-improvement',
				experimentAssignment: this.getCompanyMemberImprovementRemoteConfig(),
				isTrialSubscription,
			}),
		);
	}

	async delete(id: string): Promise<void> {
		await this.invitationsConnector.deleteInvitation(id);
	}

	getInvitationById(inviteId: string): Promise<Invitation> {
		return this.invitationsConnector.getInvitationById(inviteId);
	}

	async changeInvitationState(
		id: string,
		state: 'open' | 'read' | 'accepted' | 'declined',
	): Promise<void> {
		try {
			await firstValueFrom(
				this.functions.httpsCallable('setInvitation')({
					id,
					state,
				}),
			);
		} catch (e) {
			this.handleInvitationError(e);
		}
	}

	async remindInvitation(id: string): Promise<void> {
		try {
			await firstValueFrom(
				this.functions.httpsCallable('remindInvitation')({
					id,
				}),
			);
		} catch (error) {
			this.handleInvitationError(error);
		}
	}

	private handleInvitationError(error: Error): void {
		const matchedString = error?.message?.match(/\[(.*?)\]/);
		let errorTranslationKey = 'companyInvitations.error.default';
		if (matchedString && InvitationsErrorTranslationKeyMapper[matchedString[1]]) {
			errorTranslationKey = InvitationsErrorTranslationKeyMapper[matchedString[1]];
		}
		this.alertService.showAlert(this.translate.instant(errorTranslationKey), {
			duration: 6000,
			oKButtonTranslationKey: 'companyInvitations.error.okBtn',
		});
	}

	watchChanges(companyId: string): Observable<EntityChanges<Invitation>> {
		return this.invitationsConnector.watch(companyId);
	}

	async acceptAndGetNewCompanyId(): Promise<string | undefined> {
		const invitationLink = await firstValueFrom(this.store.select(selectInvitationLink));

		try {
			if (!invitationLink) {
				return;
			}

			if (await this.invitationDialogService.isInvitationPreConditionFailed(invitationLink)) {
				this.store.dispatch(clearInvitationLink());
				return;
			}

			const { invitationId } = invitationLink;
			const { inviteeEmail, resourceId, context } = await this.getInvitationById(invitationId);
			const emailId = await firstValueFrom(this.store.select(selectUserEmail));

			if (inviteeEmail.toLocaleLowerCase() !== emailId.toLocaleLowerCase()) {
				await this.changeInvitationState(invitationId, 'accepted');
			}

			this.store.dispatch(clearInvitationLink());
			await this.trackMemberAcceptedEvent(context.memberRole);
			return resourceId;
		} catch (e) {
			console.error(e);
		}
	}

	async trackMemberAcceptedEvent(role: string): Promise<void> {
		await this.trackingService.trackEvent(
			new CompanymembersMemberAddedEventBuilder({
				role: role?.toLowerCase(),
			}),
		);
	}

	async handleRemindInvitation(
		invitationId: string,
		inProgressHandler = noop,
		isFrom: AddEditCompanyEmployeeContext,
	): Promise<void> {
		inProgressHandler();
		const isTrialSubscription = await firstValueFrom(this.store.select(selectIsTrialSubscription));
		await this.remindInvitation(invitationId);
		this.showNotification('invitations.remind.success');

		await this.trackingService.trackEvent(
			new CompanymembersInvitationRemindCreatedEventBuilder({
				context: isFrom,
				experiment: 'add-company-member-improvement',
				experimentAssignment: this.getCompanyMemberImprovementRemoteConfig(),
				isTrialSubscription,
			}),
		);
	}

	async handleResendInvitation(member: Member, inProgressHandler = noop): Promise<void> {
		inProgressHandler();

		const [companyId, userId] = await firstValueFrom(
			combineLatest([this.store.select(selectCompanyId), this.store.select(selectUserId)]).pipe(
				filter((ids) => ids.every(Boolean)),
			),
		);
		const newInvitation = createCompanyInvitationByCompanyIdAndMember(companyId, userId, member);

		await this.create(newInvitation);
		this.showNotification('invitations.resend.success');
	}

	showNotification(messageKey: string): void {
		this.notificationService.show(BasicSnackbarComponent, {
			componentTypes: {
				description: this.translate.instant(messageKey),
				icon: 'done',
			},
			level: 1,
			timeout: 5000,
		});
	}

	async handleInvitationsByLink(): Promise<void> {
		const [userEmail, invitationLink, registrationType] = await firstValueFrom(
			combineLatest([
				this.store.select(selectUserEmail),
				this.store.select(selectInvitationLink),
				this.store.select(selectUserRegistrationType),
			]),
		);

		const openInvitation = await firstValueFrom(
			this.invitationsConnector.getOpenInvitationOfUser(userEmail),
		);

		if (
			registrationType !== UserRegistrationType.EXISTING ||
			(!invitationLink && !openInvitation)
		) {
			return;
		}

		let inviteLink = invitationLink;

		if (!inviteLink && openInvitation.resourceId) {
			const company = await firstValueFrom(
				this.companyService.getCompanyById(openInvitation?.resourceId),
			);
			inviteLink = {
				invitationId: openInvitation.id,
				companyName: company.name,
				expirationDate: openInvitation.expirationDate,
			};
		}

		await this.handleCompanyInvitation(inviteLink);
	}

	private async handleCompanyInvitation(invitationLink: InvitationLink): Promise<void> {
		if (await this.invitationDialogService.isInvitationPreConditionFailed(invitationLink)) {
			if (this.location.path().includes('invitations')) {
				await this.router.navigate(['/']);
			}
			return;
		}

		const invitation = await this.getInvitationById(invitationLink.invitationId);

		if (invitation.state === 'open') {
			await this.changeInvitationState(invitationLink.invitationId, 'read');
		}

		const result = await this.invitationDialogService.handleCompanyInvitationDialog(
			invitationLink.companyName,
		);

		this.store.dispatch(clearInvitationLink());

		await this.changeInvitationState(invitationLink.invitationId, result ? 'accepted' : 'declined');

		if (!result) {
			return;
		}

		return this.trackAcceptEvent(invitation.context.memberRole);
	}

	private async trackAcceptEvent(role: string): Promise<void> {
		await this.trackingService.trackEvent(
			new CompanymembersMemberAddedEventBuilder({
				role: role?.toLowerCase(),
			}),
		);
	}
}
