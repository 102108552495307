<div #messageElement>
	<div class="clearfix"></div>
	<div
		*ngIf="
			pdfExportActive &&
			!chatMessageService.isOwnMessage(message) &&
			checkMessageType(message, MessageType.TEXT, MessageType.IMAGE, MessageType.VIDEO, MessageType.DOCUMENT, MessageType.AUDIO)
		"
		class="pdf-checkbox-wrap left-side"
	>
		<mat-checkbox (change)="onSelectToExport($event, message)" [checked]="exportList.includes(message.id)"></mat-checkbox>
	</div>

	<div
		*ngIf="
			!message.deleted &&
			checkMessageType(message, MessageType.TEXT, MessageType.IMAGE, MessageType.AUDIO, MessageType.VIDEO, MessageType.DOCUMENT)
		"
		[ngClass]="chatMessageService.getAuthorClass(message, pdfExportActive)"
		class="d-flex justify-content-between align-items-center"
	>
		<span *ngIf="!chatMessageService.isOwnMessage(message)">{{ message.author + ' - ' }}</span>

		<img
			(click)="goToTagging.emit(message)"
			*ngIf="(userHasTaggingLimit$ | async) && message.tags && !message.deleted && message.tags.length"
			alt="tag"
			class="tag-img ml-1 mr-1 pointer"
			data-cy="chat-tag-icon"
			src="assets/images/icons/ic_tag.png"
			width="24"
		/>
		<img
			(click)="goToTagging.emit(message)"
			*ngIf="(userHasTaggingLimit$ | async) && !message.deleted && (!message.tags || !message.tags.length)"
			alt="tag"
			class="tag-img ml-1 mr-1 pointer"
			data-cy="chat-tag-icon"
			src="assets/images/icons/ic_outlined_tag.png"
			width="24"
		/>

		<span *ngIf="!message.deleted" class="mr-1" data-cy="chat-message-time"> {{ getTime(message) }}</span>
		<mat-icon (click)="replyToMessage(message)" *ngIf="!message.deleted" class="pointer" data-cy="chat-reply-icon"> reply</mat-icon>
		<mat-icon [matMenuTriggerFor]="messageMenu" class="pointer" data-cy="chat-message-menu"> expand_more</mat-icon>

		<mat-menu #messageMenu="matMenu">
			<button (click)="replyToMessage(message)" data-cy="chat-reply-message" mat-menu-item>
				{{ 'chat.message.reply' | translate }}
			</button>
			<!--	FIXME:: Quite inefficient implementation of *if-->
			<button (click)="deleteMessageApproval(message)" *ngIf="userCanDeleteMessage(message)" data-cy="chat-delete-message" mat-menu-item>
				{{ 'chat.message.delete' | translate }}
			</button>
			<button
				(click)="shareMessageToFile.emit(message)"
				*ngIf="message.messageType !== MessageType.TEXT && message.messageType !== MessageType.STATUS"
				data-cy="chat-share-to-files"
				mat-menu-item
			>
				{{ 'chat.message.share' | translate }}
			</button>
			<button
				(click)="goToTagging.emit(message)"
				*ngIf="(userHasTaggingLimit$ | async) && canEditTag(message)"
				data-cy="chat-tag-message"
				mat-menu-item
			>
				{{ 'chat.message.tag' | translate }}
			</button>
			<button (click)="download(message)" *ngIf="message.messageType !== MessageType.TEXT" data-cy="chat-download-message" mat-menu-item>
				{{ 'chat.message.download' | translate }}
			</button>
		</mat-menu>
	</div>

	<div
		*ngIf="
			pdfExportActive &&
			chatMessageService.isOwnMessage(message) &&
			checkMessageType(message, MessageType.TEXT, MessageType.IMAGE, MessageType.VIDEO, MessageType.DOCUMENT, MessageType.AUDIO)
		"
		class="pdf-checkbox-wrap"
	>
		<mat-checkbox (change)="onSelectToExport($event, message)" [checked]="exportList.includes(message.id)"></mat-checkbox>
	</div>

	<div [ngClass]="chatMessageService.getMessageClass(message, pdfExportActive)">
		<div
			*ngIf="
				!message.deleted &&
				message.content !== MessageType.NOTSET &&
				checkMessageType(message, MessageType.IMAGE, MessageType.AUDIO, MessageType.DOCUMENT, MessageType.VIDEO)
			"
		>
			{{ getFile.emit(message) }}
		</div>

		<div *ngIf="message.deleted" style="display: flex">
			<img alt="delete-message" src="assets/images/icons/deletedMessage.svg" style="vertical-align: middle" />
			<span data-cy="chat-delete-info-message">{{
				'chat.message.deleted' | translate: chatMessageService.getTranslateValues(message.deletedTimestamp)
			}}</span>
		</div>

		<div *ngIf="message.messageType === MessageType.STATUS && !message.deleted" style="cursor: pointer">
			<app-project-notes [projectId]="project.id">
				<span>
					<img alt="notes" src="assets/images/icons/note.svg" />
					<span data-cy="chat-notes-info-message">{{
						'chat.info.updated'
							| translate
								: chatMessageService.getInfoTranslateValues(
										message.timestamp,
										message.author,
										chatMessageService.getCroppedStatusMessage(message.content)
								  )
					}}</span>
				</span>
			</app-project-notes>
		</div>

		<div *ngIf="message.messageType === MessageType.TASK_CREATED && !message.deleted">
			<span>
				<img alt="tasks" src="assets/images/icons/task.svg" />
				<span>{{
					'chat.task.created'
						| translate
							: chatMessageService.getInfoTranslateValues(
									message.timestamp,
									message.author,
									chatMessageService.getCroppedStatusMessage(message.content)
							  )
				}}</span>
			</span>
		</div>

		<div *ngIf="message.messageType === MessageType.TASK_CHANGED && !message.deleted">
			<span>
				<img alt="tasks" src="assets/images/icons/task.svg" />
				<span>{{
					'chat.task.updated'
						| translate
							: chatMessageService.getInfoTranslateValues(
									message.timestamp,
									message.author,
									chatMessageService.getCroppedStatusMessage(message.content)
							  )
				}}</span>
			</span>
		</div>

		<div *ngIf="message.messageType === MessageType.TASK_COMPLETED && !message.deleted">
			<span>
				<img alt="tasks" src="assets/images/icons/task.svg" />
				<span>{{
					'chat.task.completed'
						| translate
							: chatMessageService.getInfoTranslateValues(
									message.timestamp,
									message.author,
									chatMessageService.getCroppedStatusMessage(message.content)
							  )
				}}</span>
			</span>
		</div>

		<div (click)="goToParentMessage(message)" *ngIf="!message.deleted && message.parent?.id" class="reply-message pointer">
			{{ getFile.emit(message.parent) }}
			<app-reply-message [file]="files[message.parent.id]" [replyMessage]="message.parent">
				<div *ngIf="userId$ | async as userId" class="author-name">
					<ng-container *ngIf="message.parent?.authorId === userId">{{ 'chat.reply.you' | translate }} </ng-container>
					<ng-container *ngIf="message.parent?.authorId !== userId"
						>{{ authorOfReplyMessage$ | async | memberFullName: 'email':false:('chat.reply.not-available' | translate) }}
					</ng-container>
				</div>
			</app-reply-message>
		</div>

		<div
			*ngIf="
				!message.deleted &&
				checkMessageType(message, MessageType.TEXT, MessageType.IMAGE, MessageType.AUDIO, MessageType.VIDEO, MessageType.DOCUMENT)
			"
			[ngClass]="{ 'no-reply': !message.parent?.id }"
			class="chat-message"
		>
			<div *ngIf="message.messageType === MessageType.TEXT">
				<div [innerHTML]="message.content | linkify" class="message-text-content mr-3"></div>
				<div *ngIf="chatMessageService.isOwnMessage(message)" class="text-sent">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!messageSend && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-pending"
							data-cy="chat-message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="messageSend && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							data-cy="chat-message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="messageSend && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-all"
							data-cy="chat-message-sent"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="messageSend && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-sent-by-all"
							data-cy="chat-message-read"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>
			<div *ngIf="message.messageType === MessageType.IMAGE" class="d-flex flex-column">
				<div
					(click)="showImage.emit(message)"
					[style.background-image]="getBackgroundImageStyleUrl(file)"
					class="pointer thumb"
					data-cy="chat-image-message"
				>
					<ng-container *ngIf="!file && (isPreviewAvailable | async)">
						<div class="progress-percentage">{{ getFileUploadPercentage(message.id) | async }}</div>
						<mat-spinner [ngClass]="chatMessageService.getCircleClass(message)" color="accent"></mat-spinner>
					</ng-container>

					<ng-container *ngIf="!(isPreviewAvailable | async)">
						<div class="thumb__no align-items-center">
							<span>{{ 'chat.message.pdf.no-thumb' | translate }}</span>
						</div>
					</ng-container>
				</div>
				<div *ngIf="chatMessageService.isOwnMessage(message)" class="video-sent text-right">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!file && !!getFileUploadPercentage(message.id)"
							alt="message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-to-all"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-send-by-all"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>

			<div
				(click)="showVideo.emit(message)"
				*ngIf="message.messageType === MessageType.VIDEO"
				class="pointer video-message d-flex flex-column"
			>
				<div [style.background-image]="getBackgroundImageStyleUrl(file)" class="thumb" data-cy="chat-video-message">
					<ng-container *ngIf="!file && (isPreviewAvailable | async)">
						<div class="progress-percentage">{{ getFileUploadPercentage(message.id) | async }}</div>
						<mat-spinner [ngClass]="chatMessageService.getCircleClass(message)" color="accent"></mat-spinner>
					</ng-container>

					<ng-container *ngIf="!(isPreviewAvailable | async)">
						<div class="thumb__no align-items-center">
							<span>{{ 'chat.message.pdf.no-thumb' | translate }}</span>
						</div>
					</ng-container>
				</div>
				<div class="video">
					<em *ngIf="file" class="material-icons play-button">play_circle_outline</em>
				</div>
				<div *ngIf="chatMessageService.isOwnMessage(message)" class="video-sent text-right">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!file && !!getFileUploadPercentage(message.id)"
							alt="message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-to-all"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-send-by-all"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>

			<div
				(click)="showPdf.emit(message)"
				*ngIf="message.messageType === MessageType.DOCUMENT && chatMessageService.getFileType(message.content) === 'pdf'"
				[style.background-image]="getBackgroundImageStyleUrl(file)"
				class="document pointer thumb pdf-holder"
			>
				<ng-container *ngIf="!file">
					<div class="thumb__no">
						<span>{{ 'chat.message.pdf.no-thumb' | translate }}</span>
					</div>
				</ng-container>
			</div>

			<div
				*ngIf="
					message.messageType === MessageType.DOCUMENT && !message.deleted && chatMessageService.getFileType(message.content) === 'pdf'
				"
				class="document-footer"
				data-cy="chat-pdf-message"
				title="{{ getFileName(message) }}"
			>
				<div class="mr-3 overflow-hidden">
					<span class="pdf-icon-text"> PDF </span>
					<span class="pdf-file-name">
						{{ getFileName(message) }}
					</span>
				</div>
				<div *ngIf="chatMessageService.isOwnMessage(message)" class="doc-sent">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!file && !!getFileUploadPercentage(message.id)"
							alt="message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-to-all"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-send-by-all"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>
			<div
				(click)="download(message)"
				*ngIf="
					message.messageType === MessageType.DOCUMENT && !message.deleted && chatMessageService.getFileType(message.content) !== 'pdf'
				"
				class="thumb document pointer"
			>
				<ng-container *ngIf="!file && (isPreviewAvailable | async)">
					<div class="progress-percentage">{{ getFileUploadPercentage(message.id) | async }}</div>
					<mat-spinner [ngClass]="chatMessageService.getCircleClass(message)" color="accent"></mat-spinner>
				</ng-container>

				<ng-container *ngIf="!(isPreviewAvailable | async)">
					<div class="thumb__no align-items-center">
						<span>{{ 'chat.message.pdf.no-thumb' | translate }}</span>
					</div>
				</ng-container>

				<div *ngIf="chatMessageService.isOwnMessage(message)" class="audio-sent">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!isDocumentProcessed(message) && !!getFileUploadPercentage(message.id)"
							alt="message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="isDocumentProcessed(message) && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="isDocumentProcessed(message) && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-to-all"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="isDocumentProcessed(message) && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-send-by-all"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>

			<div *ngIf="message.messageType === MessageType.AUDIO" class="mr-3">
				<ng-template [ngIf]="!file">
					<div class="progress-percentage">{{ getFileUploadPercentage(message.id) | async }}</div>
					<mat-spinner [ngClass]="chatMessageService.getCircleClass(message)" color="accent"></mat-spinner>
				</ng-template>
				<vg-player *ngIf="file && message.content !== MessageType.NOTSET" class="audio-player">
					<vg-controls>
						<vg-play-pause></vg-play-pause>

						<vg-time-display vgFormat="mm:ss" vgProperty="current"></vg-time-display>
						<vg-time-display vgFormat="mm:ss" vgProperty="total"></vg-time-display>

						<vg-scrub-bar>
							<vg-scrub-bar-current-time></vg-scrub-bar-current-time>
							<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
						</vg-scrub-bar>
					</vg-controls>

					<audio #media [vgMedia]="media" id="myAudio" preload="auto">
						<source src="{{ file }}" type="audio/aac" />
					</audio>
				</vg-player>

				<div *ngIf="chatMessageService.isOwnMessage(message)" class="audio-sent">
					<ng-container *ngIf="messageState$ | async as messageState">
						<img
							*ngIf="!file && !!getFileUploadPercentage(message.id)"
							alt="message-pending"
							src="assets/images/icons/chat/message_pending.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.DEFAULT"
							alt="message-delivered"
							src="assets/images/icons/chat/message_delivered.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.PROCESSED"
							alt="message-send-to-all"
							src="assets/images/icons/chat/message_sent_to_all.svg"
						/>
						<img
							*ngIf="file && messageState === MESSAGE_STATE.READ_BY_ALL_USERS"
							alt="message-send-by-all"
							src="assets/images/icons/chat/message_seen_by_all.svg"
						/>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="!message.deleted && showTagsMessage(message)?.length" [ngClass]="chatMessageService.getTagClass(message)" class="tags">
		<ng-container *ngFor="let tag of showTagsMessage(message)">
			<div class="tag__title" data-cy="message-tag">
				{{ tag }}
			</div>
		</ng-container>
	</div>
</div>
