import { Component } from '@angular/core';
import { BrowserDetectionService } from '@injectables/services/browser-detection.service';

@Component({
	selector: 'app-notification-token-permission-denied',
	templateUrl: './notification-token-permission-denied.component.html',
	styleUrls: ['./notification-token-permission-denied.component.scss'],
})
export class NotificationTokenPermissionDeniedComponent {
	constructor(readonly browserDetectionService: BrowserDetectionService) {}

	get isSupportedBrowser(): boolean {
		return ['chrome', 'firefox', 'edge-chromium', 'edge'].includes(
			this.browserDetectionService.getBrowserName(),
		);
	}
}
