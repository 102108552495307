import { Action, createReducer, on } from '@ngrx/store';
import { DeviceState } from '../state/device.state';
import { setCurrentDevice } from '../actions/device.actions';

const initialState: DeviceState = {
	currentDevice: null,
};

const reducer = createReducer(
	initialState,
	on(setCurrentDevice, (state, { device }) => {
		return {
			...state,
			currentDevice: device,
		};
	}),
);

// tslint:disable-next-line:typedef
export function deviceReducer(state: DeviceState, action: Action) {
	return reducer(state, action);
}
