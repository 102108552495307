<section class="task-edit-files-section d-flex flex-column h-100 position-relative">
	<div class="flex-scroll">
		<div class="p-3 flex-scroll__container w-100">
			<div class="d-flex align-items-center mb-3">
				<button (click)="openTaskEditDetails()" class="mr-3" mat-icon-button>
					<mat-icon class="back-btn-icon">arrow_back</mat-icon>
				</button>

				<div class="m-0 d-inline-flex mat-headline font-weight-bold">
					{{ 'task-edit-attachments.title' | translate }}
				</div>
			</div>

			<ul class="list-unstyled flex-scroll__container__scrollable-content pb-5">
				<li
					class="pb-2 border-bottom d-flex align-items-center"
					[class]="first ? 'pb-2' : 'py-2'"
					*ngFor="let taskEditFilesUI of taskEditFilesUI$ | async; let first = first"
				>
					<div
						class="file-icon-container d-flex pointer flex-grow-1"
						(click)="taskEditFilesUI.isPreviewFileType ? viewFile(taskEditFilesUI.taskFile.id) : downloadFile(taskEditFilesUI.taskFile)"
					>
						<div class="file-icon mr-2">
							<div
								*ngIf="taskEditFilesUI.isPreviewFileType"
								class="file-icon-preview"
								[style.background-image]="'url(' + (taskEditFilesUI.previewURL | async) + ')'"
							></div>
							<div class="file-icon-no-preview" *ngIf="!taskEditFilesUI.isPreviewFileType">
								{{ taskEditFilesUI.fileExt }}
							</div>
						</div>
						<div class="file-icon-info flex-grow-1">
							<div class="font-weight-bold file-icon-name">
								{{ taskEditFilesUI.taskFile.name }}
							</div>
							<small class="text-muted">
								{{ taskEditFilesUI.taskFile.creationTimestamp | unixToMillis | date: 'dd.MM.yyyy' }}
								- {{ taskEditFilesUI.taskFile.size | bytes }}
							</small>
						</div>
					</div>

					<button mat-icon-button (click)="deleteTaskFile(taskEditFilesUI.taskFile.id)">
						<mat-icon>delete_outline</mat-icon>
					</button>
				</li>
			</ul>
		</div>

		<button mat-fab color="accent" (click)="fileInput.click()" class="add-task-file-button">
			<mat-icon>add</mat-icon>
			<input #fileInput (change)="addTaskFile($event)" id="task-file" multiple type="file" hidden />
		</button>
	</div>
</section>
