import { Action, combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ProfileLimit } from 'domain-entities';
import * as fromProfileLimitsActions from '../actions/profile-limits.actions';
import * as fromProfileLimitsStates from '../state/profile-limits.state';

const currentUserProfileLimitsInitialValue: fromProfileLimitsStates.CurrentUserProfileLimitsState =
	{
		limits: null,
		loaded: false,
		loading: false,
	};

export const companyMembersProfileLimitsEntityAdapter: EntityAdapter<ProfileLimit> =
	createEntityAdapter<ProfileLimit>({
		selectId: (profileLimit) => profileLimit.profileId,
		sortComparer: (firstProfileLimit, secondProfileLimit) =>
			firstProfileLimit.profileId.localeCompare(secondProfileLimit.profileId),
	});

const companyMembersProfileLimitsInitialValue: fromProfileLimitsStates.CompanyMembersProfileLimitsState =
	companyMembersProfileLimitsEntityAdapter.getInitialState({
		loaded: false,
		loading: false,
	});

const profileLimitsInitialValue: fromProfileLimitsStates.ProfileLimitsState = {
	currentUserProfileLimits: currentUserProfileLimitsInitialValue,
	companyMembersProfileLimits: companyMembersProfileLimitsInitialValue,
};

const currentUserProfileLimitsReducerCreator =
	createReducer<fromProfileLimitsStates.CurrentUserProfileLimitsState>(
		currentUserProfileLimitsInitialValue,
		on(fromProfileLimitsActions.subscribeCurrentUserProfileLimits, (state) => {
			return { ...state, loading: true };
		}),
		on(fromProfileLimitsActions.updateCurrentUserProfileLimitsSuccess, (state, { payload }) => {
			return { ...state, limits: payload, loading: false, loaded: true };
		}),
		on(fromProfileLimitsActions.updateCurrentUserProfileLimitsFail, (state) => {
			return { ...state, loaded: false, loading: false };
		}),
		on(fromProfileLimitsActions.clearProfileLimits, () => {
			return { loaded: false, loading: false, limits: null };
		}),
	);

const companyMembersProfileLimitsReducerCreator =
	createReducer<fromProfileLimitsStates.CompanyMembersProfileLimitsState>(
		companyMembersProfileLimitsInitialValue,
		on(fromProfileLimitsActions.loadCompanyMembersProfileLimits, (state) => {
			return { ...state, loading: true };
		}),
		on(fromProfileLimitsActions.loadCompanyMembersProfileLimitsSuccess, (state, { payload }) => {
			return companyMembersProfileLimitsEntityAdapter.setAll(payload, {
				...state,
				loaded: true,
				loading: false,
			});
		}),
		on(fromProfileLimitsActions.clearProfileLimits, (state) => {
			return companyMembersProfileLimitsEntityAdapter.removeAll({
				...state,
				loaded: false,
				loading: false,
			});
		}),
	);

function companyMembersProfileLimitsReducer(
	state: fromProfileLimitsStates.CompanyMembersProfileLimitsState,
	action: Action,
): fromProfileLimitsStates.CompanyMembersProfileLimitsState {
	return companyMembersProfileLimitsReducerCreator(state, action);
}

function currentUserLimitsReducer(
	state: fromProfileLimitsStates.CurrentUserProfileLimitsState,
	action: Action,
): fromProfileLimitsStates.CurrentUserProfileLimitsState {
	return currentUserProfileLimitsReducerCreator(state, action);
}

export const profileLimitsReducer = combineReducers<fromProfileLimitsStates.ProfileLimitsState>(
	{
		currentUserProfileLimits: currentUserLimitsReducer,
		companyMembersProfileLimits: companyMembersProfileLimitsReducer,
	},
	profileLimitsInitialValue,
);
