import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProfile, selectUserId } from '@store/selectors/app.selectors';
import moment from 'moment';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { updateProfileToc } from '@shared/firebase/profile/profile-update.functions';
import { LinkService } from '@injectables/services/link/link.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { TocService } from '@injectables/services/toc.service';
import { from } from 'rxjs';

@Component({
	selector: 'app-toc',
	templateUrl: './toc.component.html',
	styleUrls: ['./toc.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class TocComponent {
	userName$ = this.store.select(selectProfile).pipe(map((profile) => profile.name));
	deadLine$ = from(this.tocService.getDueDate().then((date) => date.format('DD.MM.yyyy')));
	tocForm = this.formBuilder.group({
		status: [null, Validators.required],
	});

	tocDeclineForm = this.formBuilder.group({
		aaInfoDeleted: [false, Validators.requiredTrue],
		mcAccountsDeleted: [false, Validators.requiredTrue],
		mdmcDataDeleted: [false, Validators.requiredTrue],
		cannotBeUndone: [false, Validators.requiredTrue],
		discussWithOwners: [false, Validators.requiredTrue],
	});

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly confirmationService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly store: Store<AppState>,
		private readonly dialogRef: MatDialogRef<TocComponent>,
		private readonly profileService: ProfileService,
		public readonly linkService: LinkService,
		private readonly remoteConfig: RemoteConfig,
		private readonly tocService: TocService,
	) {}

	get tocStatus(): 'accepted' | 'declined' | undefined {
		return this.tocForm.value.status;
	}

	get isValidToc(): boolean {
		if (!this.tocStatus) {
			return false;
		}

		if (this.tocStatus === 'accepted') {
			return true;
		}

		return this.tocDeclineForm.valid;
	}

	acceptedHandler(): void {
		this.tocDeclineForm.reset();
	}

	async formSubmitHandler(): Promise<void> {
		if (this.tocStatus === 'declined') {
			const isDeclined = await this.confirmationService
				.open({
					title: this.translateService.instant('toc.dialog.decline.title'),
					primaryButtonText: this.translateService.instant('toc.dialog.decline.primaryBtnText'),
					secondaryButtonText: this.translateService.instant('toc.dialog.decline.secondaryBtnText'),
					primaryButtonColor: 'warn',
					showCrossBtn: false,
				})
				.afterClosed()
				.pipe(take(1))
				.toPromise();

			if (!isDeclined) {
				return;
			}
		}

		const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const version = Number(await this.remoteConfig.getValueAsync('version_current_toc'));

		await this.profileService.updateProfileTransactional(
			userId,
			updateProfileToc(this.tocStatus, moment().unix(), version),
		);
		this.dialogRef.close();
	}
}
