import { Action, createReducer, on } from '@ngrx/store';
import * as fromSubscription from '../actions/subscription.actions';
import { SubscriptionState } from '../state/SubscriptionState';

const initialState: SubscriptionState = {
	trialSubscription: undefined,
	products: [],
	checkoutInProgress: false,
};

const reducer = createReducer(
	initialState,
	on(fromSubscription.setTrialSubscriptionAction, (state, payload) => {
		return { ...state, trialSubscription: payload.trialSubscription };
	}),
	on(fromSubscription.setCheckoutProgressAction, (state, payload) => {
		return { ...state, checkoutInProgress: payload.checkoutInProgress };
	}),
);

// tslint:disable-next-line:typedef
export function subscriptionReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
