import { NgModule } from '@angular/core';
import { ButtonLoaderModule } from '@craftnote/material-theme';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { AddEditCompanyEmployeeComponent } from './add-edit-company-employee.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

const COMPONENT = [AddEditCompanyEmployeeComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [
		SharedModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatIconModule,
		ButtonLoaderModule,
		MatFormFieldModule,
		NgxTrimDirectiveModule,
		MatInputModule,
		MatButtonModule,
	],
	exports: [COMPONENT],
})
export class AddEditCompanyEmployeeModule {}
