import { NgModule } from '@angular/core';
import { MinutesFromDatePipe } from './minutes-from-date.pipe';

const PIPE = [MinutesFromDatePipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class MinutesFromDatePipeModule {}
