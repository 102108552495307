import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { FeaturesModule, appInitFactory } from '@modules/features/features.module';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATE_CONFIG } from '@shared/constants/translation';
import { registerLocales } from '@shared/location/locale.info';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocales(navigator.language);

@NgModule({
	declarations: [AppComponent],
	imports: [
		FeaturesModule,
		SharedModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot(TRANSLATE_CONFIG),
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: appInitFactory, deps: [AppInitService], multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
