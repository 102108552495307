import { Injectable } from '@angular/core';
import { TrackedTime } from 'domain-entities';
import { TrackedTimeConnector } from '@shared/firebase/connectors/firestore/collections/tracked-time/tracked-time.connector';
import { firstValueFrom } from 'rxjs';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { chunk, uniq } from 'lodash';
import { Store } from '@ngrx/store';
import { selectProjectById } from '@store/selectors/projects.selectors';
import { selectCompanyId, selectUserId } from '@store/selectors/app.selectors';
import { selectCurrentUserFromCompanyMembers } from '@store/selectors/company.selectors';
import { isCompanyAdmin } from '@shared/functions/company/company.functions';

@Injectable()
export class TrackedTimeAddEditFormService {
	constructor(
		private readonly trackedTimeConnector: TrackedTimeConnector,
		private readonly store: Store,
	) {}

	async createTrackedTimes(trackedTimes: TrackedTime[]): Promise<boolean> {
		if (await this.isAnyTrackedTimeOverlappedByExisting(trackedTimes)) {
			return false;
		}
		await Promise.all(
			trackedTimes.map((time) => this.trackedTimeConnector.createTrackedTime(time)),
		);
		return true;
	}

	async updateTrackedTime(
		trackedTime: TrackedTime,
		options: { preventOverlap: boolean } = { preventOverlap: true },
	): Promise<boolean> {
		if (
			options.preventOverlap &&
			(await this.isAnyTrackedTimeOverlappedByExisting([trackedTime]))
		) {
			return false;
		}

		await this.trackedTimeConnector.updateTrackedTime(trackedTime);
		return true;
	}

	private async isAnyTrackedTimeOverlappedByExisting(
		trackedTimes: TrackedTime[],
	): Promise<boolean> {
		const project = await firstValueFrom(
			this.store.select(selectProjectById(trackedTimes[0].projectId)),
		);

		if (!project || trackedTimes?.length === 0) {
			return false;
		}

		const startTimes = uniq(trackedTimes.map((time) => time.startTime));
		const endTimes = uniq(trackedTimes.map((time) => time.endTime));
		if (startTimes.length + endTimes.length > 2) {
			throw new Error(
				'Tracked Times: Can not create tracked times with varying start or end times',
			);
		}

		/**
		 * Trying to create multiple tracked times with the same interval for the same user
		 * is always an overlap.
		 */
		let userIds = uniq(trackedTimes.map((time) => time.userId));
		if (userIds.length < trackedTimes.length) {
			return true;
		}

		const currentUserId = await firstValueFrom(this.store.select(selectUserId));
		const companyId = await firstValueFrom(this.store.select(selectCompanyId));
		const companyRoleOfCurrentUser = await firstValueFrom(
			this.store.select(selectCurrentUserFromCompanyMembers),
		);
		const trackedTimeIds = trackedTimes.map((time) => time.id);

		/**
		 * We only check for overlaps for the current user unless the following conditions are met:
		 * - The tracked times' project belongs to the current users company
		 * - The current user is a company admin of her/his company
		 */
		if (!project || project.company !== companyId || !isCompanyAdmin(companyRoleOfCurrentUser)) {
			userIds = userIds.filter((userId) => userId === currentUserId);
		}

		const chunks = chunk(userIds, 10);
		for (const userIdsChunk of chunks) {
			const query: DataQueryCondition<TrackedTime>[] = [
				{ field: 'companyId', operator: '==', value: project.company },
				{ field: 'state', operator: '==', value: 'stopped' },
				{ field: 'endTime', operator: '>', value: trackedTimes[0].startTime },
				{ field: 'userId', operator: 'in', value: userIdsChunk },
			];
			const candidates = await firstValueFrom(
				this.trackedTimeConnector.getTrackedTimeByQuery(query),
			);
			if (
				candidates.filter(
					(candidate) =>
						candidate.startTime < trackedTimes[0].endTime && !trackedTimeIds.includes(candidate.id),
				).length > 0
			) {
				return true;
			}
		}
		return false;
	}
}
