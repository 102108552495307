<section class="task-time-edit-panel-section pt-3 h-100">
	<app-task-time-edit-form
		(close)="closePanel.emit()"
		*ngIf="taskTime"
		[taskName]="taskName"
		[taskProjectId]="taskProjectId"
		[taskTime]="taskTime"
	>
	</app-task-time-edit-form>
</section>
