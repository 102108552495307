import { Injectable } from '@angular/core';
import { TaskTimeConnector } from '@shared/firebase/connectors/firestore/collections/task-time/task-time.connector';
import { TaskTime } from 'domain-entities';

@Injectable({ providedIn: 'root' })
export class TaskEditWorkingHoursUpdateService {
	constructor(public readonly taskTimeConnector: TaskTimeConnector) {}

	removeTaskTime(id: string): Promise<void> {
		return this.taskTimeConnector.removeTaskTime(id);
	}

	updateTaskTime(taskTime: TaskTime): Promise<void> {
		return this.taskTimeConnector.updateTaskTime(taskTime);
	}
}
