import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

/**
 * This guard will redirect old project or archive routes like
 * /project?id=<projectId>
 *
 * to new routes like
 *
 * /project/<projectId>
 */
@Injectable({ providedIn: 'root' })
export class ProjectRouteGuard {
	constructor(private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const prefix = route.url[0].path; // projects or archive or project
		// Redirect all 'project' old-style routes to their new-style equivalent
		const projectIdQueryParam = route.queryParams['id'];
		if (projectIdQueryParam) {
			return this.router.createUrlTree([prefix, projectIdQueryParam]);
		}
		return true;
	}
}
