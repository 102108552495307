import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { selectUserRole } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { MemberRole } from 'domain-entities';
import { Subject } from 'rxjs';

@Directive({
	selector: '[appMemberRole]',
})
export class MemberRoleDirective implements OnDestroy {
	@Input('appMemberRole')
	set appMemberRole(memberRole: MemberRole[]) {
		this.updateView(memberRole);
	}

	currentUserRole$ = this.store.select(selectUserRole);
	destroy$: Subject<boolean> = new Subject();

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	constructor(
		private store: Store,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {
		this.currentUserRole$
			.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe(() => this.updateView());
	}

	private async updateView(memberRole: MemberRole[] = []): Promise<void> {
		const currentUserRole = await this.currentUserRole$.pipe(take(1)).toPromise();
		this.viewContainerRef.clear();
		if (memberRole.includes(currentUserRole)) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
			return;
		}
	}
}
