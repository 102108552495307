import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-basic-snackbar',
	templateUrl: './basic-snackbar.component.html',
	styleUrls: ['./basic-snackbar.component.scss'],
})
export class BasicSnackbarComponent {
	@Input() type: 'success' | 'primary' | 'warn' = 'success';
	@Input() description;
	@Input() icon;
	@Input() minWidth = 260;

	typeClassMap = {
		success: 'bg-success',
		primary: 'bg-primary',
		warn: 'bg-danger',
	};
}
