import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-hint-banner',
	templateUrl: './hint-banner.component.html',
	styleUrls: ['./hint-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintBannerComponent {
	@Output() closeButtonClicked = new EventEmitter<void>();
}
