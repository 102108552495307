import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { TrackedTimeWorkTypeButtonService } from '../services/tracked-time-work-type-button.service';
import {
	isEmployeeOfCompany,
	isOwnerOrSupervisorOfCompany,
	selectCompanyId,
} from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ProjectStatusService } from '@injectables/services/project-status/project-status.service';
import { v4 as uuid } from 'uuid';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	TimetrackingWorktypeCreatedEventBuilder,
	TimetrackingWorktypeDeletedEventBuilder,
} from '@generated/events/TimetrackingEvents.generated';
import { WorkType } from 'domain-entities';

@Component({
	selector: 'app-work-type-list-panel',
	templateUrl: './work-type-list-panel.component.html',
	styleUrls: ['./work-type-list-panel.component.scss'],
})
export class WorkTypeListPanelComponent {
	@Output() closeWorkTypeListPanel = new EventEmitter<void>();
	@Input() fallbackWorkType: WorkType = null;
	editWorkTimeVisible = false;
	readonly isEmployee$ = this.store.select(isEmployeeOfCompany);
	readonly isOwnerOrSupervisorOfCompany$ = this.store.select(isOwnerOrSupervisorOfCompany);
	workTypeList$ = this.trackedTimesWorkTypeButtonService.workTypeList$.pipe(
		map((workTypeList) => {
			if (this.fallbackWorkType && !workTypeList.find((wt) => wt.id === this.fallbackWorkType.id)) {
				workTypeList.push(this.fallbackWorkType);
			}
			return workTypeList;
		}),
	);

	constructor(
		@Optional() readonly trackedTimesWorkTypeButtonService: TrackedTimeWorkTypeButtonService,
		private readonly store: Store<AppState>,
		private readonly projectStatusService: ProjectStatusService,
		private readonly trackingService: TrackingService,
	) {
		if (!trackedTimesWorkTypeButtonService) {
			throw new Error('Please inject TrackedTimeWorkTypeButtonService in parent component');
		}
	}

	openEditWorkTime(): void {
		this.editWorkTimeVisible = true;
	}

	closeEditWorkTime(): void {
		this.editWorkTimeVisible = false;
	}

	closePanel(): void {
		this.closeWorkTypeListPanel.emit();
	}

	async removeWorkType(id: string): Promise<void> {
		const companyId = await this.store.select(selectCompanyId).pipe(take(1)).toPromise();
		const workTypes = await this.projectStatusService
			.getWorkTypes(companyId)
			.pipe(take(1))
			.toPromise();
		await this.projectStatusService.setWorkTypes(
			companyId,
			workTypes.map((workType) => {
				if (workType.id === id) {
					workType.deleted = true;
				}
				return workType;
			}),
		);
		await this.trackingService.trackEvent(
			new TimetrackingWorktypeDeletedEventBuilder({ projectId: '' }),
		);
	}

	async selectNewWorkType(value: string): Promise<void> {
		this.trackedTimesWorkTypeButtonService.setSelectedWorkTypeId(value);
		this.closePanel();
	}

	async addNewWorkType(inputRef: HTMLInputElement): Promise<void> {
		const companyId = await this.store.select(selectCompanyId).pipe(take(1)).toPromise();
		const workTypes = await this.projectStatusService
			.getWorkTypes(companyId)
			.pipe(take(1))
			.toPromise();
		workTypes.push({
			id: uuid(),
			name: inputRef.value,
			deleted: false,
			editable: true,
		});
		inputRef.value = '';
		await this.projectStatusService.setWorkTypes(companyId, workTypes);
		await this.trackingService.trackEvent(
			new TimetrackingWorktypeCreatedEventBuilder({ projectId: '' }),
		);
	}
}
