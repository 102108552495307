import { NgModule } from '@angular/core';
import { ManageCompanyEmployeesComponent } from './manage-company-employees.component';
import { MatIconModule } from '@angular/material/icon';
import { LineModule } from '@modules/shared/components/line/line.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ConfirmButtonModule } from '@modules/shared/components/confirm-button/confirm-button.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule } from '@angular/forms';

const COMPONENT = [ManageCompanyEmployeesComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [
		SharedModule,
		MatIconModule,
		MatButtonModule,
		FormsModule,
		LineModule,
		MatButtonToggleModule,
		ConfirmButtonModule,
		MatProgressSpinnerModule,
	],
	exports: [COMPONENT],
})
export class ManageCompanyEmployeesModule {}
