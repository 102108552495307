import { selectAuthState, selectUserId } from '../selectors/app.selectors';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { select } from '@ngrx/store';

export const selectLoggedInAuthState = pipe(
	select(selectAuthState),
	filter((authState) => !!authState.userId),
);

export const selectLoggedInUser = pipe(select(selectUserId), filter<string>(Boolean));
