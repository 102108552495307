import { NgModule } from '@angular/core';
import { VerifyAddressDialogComponent } from '@modules/shared/dialog/components/verify-address-dialog-component/verify-address-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	declarations: [VerifyAddressDialogComponent],
	exports: [VerifyAddressDialogComponent],
	imports: [SharedModule, MatDialogModule],
})
export class VerifyAddressDialogComponentModule {}
