import { Action, createReducer, on } from '@ngrx/store';
import * as companyActions from '../actions/company.actions';
import { CompanyChangeStatus, CompanyState } from '../state/CompanyState';
import { StoreLoadingStatus } from '../entities/store-loading-status';
import { createEntityAdapter } from '@ngrx/entity';
import { updateEntityStore } from './functions/update-entity-state.functions';
import { EntityStore } from '../entities/entity-store';
import { CompanyEmployee } from 'domain-entities';

const companyMembersAdapter = createEntityAdapter<CompanyEmployee>();

const initialCompanyMemberState: EntityStore<CompanyEmployee> = {
	...companyMembersAdapter.getInitialState(),
	loadingStatus: StoreLoadingStatus.INITIAL,
};

const initialState: CompanyState = {
	company: undefined,
	companyEmployees: initialCompanyMemberState,
	companyChangeStatus: CompanyChangeStatus.CLEAN,
	companyLimits: undefined,
	loadingStatus: StoreLoadingStatus.INITIAL,
};

const companyReducerCreator = createReducer(
	initialState,
	on(companyActions.setCompanyStateAction, (state, { company }) => {
		return {
			...state,
			company,
			loadingStatus: !company ? StoreLoadingStatus.INITIAL : StoreLoadingStatus.LOADED,
		};
	}),
	on(companyActions.updateCompanyMembers, (state, { changes }) => {
		return {
			...state,
			companyEmployees: updateEntityStore(companyMembersAdapter, state.companyEmployees, changes),
		};
	}),
	on(companyActions.setCompanyLimitsActions, (state, { companyLimits }) => {
		return {
			...state,
			companyLimits,
		};
	}),
	on(companyActions.setCompanyChangeStatusAction, (state, payload) => {
		return { ...state, companyChangeStatus: payload.companyChangeStatus };
	}),
	on(companyActions.clearCompanyAndLimitsAction, (state) => {
		return {
			...state,
			company: undefined,
			companyMembers: companyMembersAdapter.getInitialState(),
			companyLimits: undefined,
			loadingStatus: StoreLoadingStatus.INITIAL,
		};
	}),
);

// tslint:disable-next-line:typedef
export function companyReducer(state: CompanyState, action: Action) {
	return companyReducerCreator(state, action);
}
