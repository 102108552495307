import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/legacy-radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ButtonLoaderModule, FullScreenHeightModule } from '@craftnote/material-theme';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { environment } from '@env/environment';
import { DatePickerService } from '@injectables/services/date-picker.service';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { GeoLocationService } from '@injectables/services/geo-location/geo-location.service';
import { NotificationCenterModule } from '@modules/shared/components/notification-center/notification-center.module';
import { PaywallHeaderDialogModule } from '@modules/shared/components/paywall-header-dialog/paywall-header-dialog.module';
import { VerifyAddressDialogComponentModule } from '@modules/shared/dialog/components/verify-address-dialog-component/verify-address-dialog-component.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpConfigInterceptors } from '@shared/firebase/httpconfig.interceptors';
import { MobileComponent } from '@shared/mobile/mobile.component';
import { AppStoreModule } from '@store/app-store.module';
import { WorkModule } from '@work/work.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AppInitService } from '../../app-init.service';
import { AppFirebaseModule } from '../app-firebase/app-firebase.module';
import { AppInitModule } from '../shared/components/app-init/app-init.module';
import { CustomWebModule } from '../shared/components/custom-web-component/custom-web.module';
import { GlobalSearchDialogModule } from '../shared/components/global-search-dialog/global-search-dialog.module';
import { NotificationSnackbarModule } from '../shared/components/notification-snackbar/notification-snackbar.module';
import { ThemeTogglerModule } from '../shared/components/theme-toggler/theme-toggler.module';
import { DialogModule } from '../shared/dialog/dialog.module';
import { FeatureFlagModule } from '../shared/directives/feature-flag.module';
import { HasLimitModule } from '../shared/directives/has-limit.module';
import { MatIconSizeModule } from '../shared/directives/mat-icon-size.module';
import { UnixToMillisPipeModule } from '../shared/pipes/unix-to-millis/unix-to-millis-pipe.module';
import { StripeModule } from '../shared/stripe';
import { ChatModule } from './chat/chat.module';
import { FeaturesRoutingModule } from './features-routing.module';
import { FeaturesComponent } from './features.component';
import { BrowserErrorBannerComponent } from './root/components/browser-compatibility-banner/browser-error-banner.component';
import { DebugDialogComponent } from './root/components/navbar/debug-dialog/debug-dialog.component';
import { NavbarComponent } from './root/components/navbar/navbar.component';
import { SidebarComponent } from './root/components/sidebar/sidebar.component';
import { TocDeclinedBannerComponent } from './root/components/toc-declined-banner/toc-declined-banner.component';
import { TrialBannerComponent } from './root/components/trial-banner/trial-banner.component';

export function appInitFactory(appInitService: AppInitService) {
	return (): Promise<void> => appInitService.init();
}

@NgModule({
	declarations: [
		FeaturesComponent,
		NavbarComponent,
		TrialBannerComponent,
		SidebarComponent,
		BrowserErrorBannerComponent,
		TocDeclinedBannerComponent,
		DebugDialogComponent,
		MobileComponent,
	],
	imports: [
		FeaturesRoutingModule,
		CustomWebModule, // FIXME: Create individual modules for each microfrontend and lazy load
		StripeModule.init(environment.stripe.public_key), // FIXME: Cant be moved for unknown reason
		AppFirebaseModule, // FIXME: Cant be moved for unknown reason
		WorkModule,
		HttpClientModule,
		MatToolbarModule,
		DialogModule, // FIXME:: This needs to be cleaned of all Supscription Wizard Code and then split
		!environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
		ChatModule,
		LayoutModule,
		AppStoreModule,
		NotificationSnackbarModule,
		AppInitModule,
		ButtonLoaderModule,
		ThemeTogglerModule,
		FullScreenHeightModule,
		NgxJsonViewerModule,
		FeatureFlagModule,
		MatIconSizeModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		GlobalSearchDialogModule,
		HasLimitModule,
		UnixToMillisPipeModule,
		SharedModule,
		VerifyAddressDialogComponentModule,
		PaywallHeaderDialogModule,
		NotificationCenterModule,
	],
	providers: [
		AppInitService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptors, multi: true },
		{ provide: DateAdapter, useClass: DatePickerService },
		{ provide: ErrorHandler, useClass: ErrorHandlerService },
		{
			provide: MAT_RADIO_DEFAULT_OPTIONS,
			useValue: { color: 'primary' },
		},
		{
			provide: LOCALE_ID,
			useFactory: (locationService: GeoLocationService) => locationService.locale,
			deps: [GeoLocationService],
		},
		{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
		DatePipe,
	],
	exports: [FeaturesComponent],
})
export class FeaturesModule {}
