import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'dayDifference',
})
export class DayDifferencePipe implements PipeTransform {
	private dayText = this.translate.instant('dayDifference.day');
	private daysText = this.translate.instant('dayDifference.days');

	constructor(private readonly translate: TranslateService) {}

	transform(startTime: number = 0, endTime: number = 0): string | null {
		const difference = moment.unix(endTime).get('date') - moment.unix(startTime).get('date');
		return difference <= 0
			? null
			: difference === 1
			? `(+${difference} ${this.dayText})`
			: `(+${difference} ${this.daysText})`;
	}
}
