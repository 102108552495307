import { Component } from '@angular/core';
import { RotateAnimation } from '@shared/animations/rotate-animation';
import { ShrinkOutAnimation } from '@shared/animations/shrink-out-animation';
import { MemberRole, Project, ProjectType } from 'domain-entities';
import {
	ProjectPrefillConfirmDialogData,
	ProjectPrefillDialogComponent,
} from '@work/project/project-prefill-dialog/project-prefill-dialog.component';
import {
	ProjectCreateDialogComponent,
	ProjectCreationDialogData,
} from '@work/project/project-create-dialog/project-create-dialog.component';
import { first, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ProjectAreaStore } from '../../store/project-area.store';
import { selectIsCurrentUserHasProjectInvitationsRemaining } from '@store/selectors/profile-limits.selectors';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';
import { TranslateService } from '@ngx-translate/core';
import { NoInvitationsRemainingService } from '@injectables/services/no-invitations-remaining.service';

@Component({
	selector: 'app-create-project',
	templateUrl: './create-project.component.html',
	styleUrls: ['./create-project.component.scss'],
	animations: [RotateAnimation, ShrinkOutAnimation],
})
export class CreateProjectComponent {
	createProjectOverlayOpen = false;
	MemberRole = MemberRole;
	openFolder$ = this.projectAreaStore.openFolder$;
	isOwner$ = this.store.select(isOwnerOfCompany);

	constructor(
		private readonly dialog: MatDialog,
		private readonly projectAreaStore: ProjectAreaStore,
		private readonly store: Store<AppState>,
		private readonly router: Router,
		private readonly noInvitationsRemainingService: NoInvitationsRemainingService,
		private readonly translateService: TranslateService,
	) {}

	toggleActionMenu(): void {
		this.createProjectOverlayOpen = !this.createProjectOverlayOpen;
	}

	onOverlayClick(): void {
		this.createProjectOverlayOpen = false;
	}

	async openProjectFolderCreationDialog(): Promise<void> {
		await this.createProjectInNewWay(ProjectType.FOLDER);
	}

	async openProjectCreationDialog(): Promise<void> {
		this.createProjectOverlayOpen = false;
		const isCurrentUserHasProjectInvitationsRemaining = await firstValueFrom(
			this.store.select(selectIsCurrentUserHasProjectInvitationsRemaining()),
		);

		if (!isCurrentUserHasProjectInvitationsRemaining) {
			this.noInvitationsRemainingService.openNoProjectInvitationsRemainingPaywallDialog(
				'project-creation',
				{
					title: this.translateService.instant('create-project.no-project-remaining.title'),
					message: this.translateService.instant('create-project.no-project-remaining.message'),
					messageList: [
						this.translateService.instant('create-project.no-project-remaining.message-1'),
						this.translateService.instant('create-project.no-project-remaining.message-2'),
						this.translateService.instant('create-project.no-project-remaining.message-3'),
					],
				},
			);
			return;
		}

		await this.createProjectInNewWay(ProjectType.PROJECT);
	}

	private openNewProjectCreationDialog(
		projectType: ProjectType = ProjectType.PROJECT,
		prefillOptions?: ProjectPrefillConfirmDialogData,
		parentProject?: Project,
	): Promise<string | boolean> {
		this.createProjectOverlayOpen = false;
		const dialogData: ProjectCreationDialogData = { projectType, prefillOptions, parentProject };
		return this.dialog
			.open(ProjectCreateDialogComponent, {
				minWidth: '600px',
				autoFocus: true,
				disableClose: true,
				data: { ...dialogData },
			})
			.afterClosed()
			.toPromise();
	}

	private async createProjectInNewWay(projectType: ProjectType): Promise<void> {
		const parentProject = await this.openFolder$.pipe(take(1)).toPromise();
		let prefilledOptions: ProjectPrefillConfirmDialogData | undefined;

		if (parentProject) {
			prefilledOptions = await this.dialog
				.open(ProjectPrefillDialogComponent, {
					width: '50%',
				})
				.afterClosed()
				.pipe(first())
				.toPromise();

			if (!prefilledOptions) {
				return;
			}
		}
		const projectId = await this.openNewProjectCreationDialog(
			projectType,
			prefilledOptions,
			parentProject,
		);

		if (!projectId) {
			return;
		}

		const urlParams = ['/project', projectId];

		urlParams.push('details');

		await this.router.navigate(urlParams);
	}
}
