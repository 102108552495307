import { NgModule } from '@angular/core';
import { LinkifyPipe } from './linkify.pipe';

const PIPE = [LinkifyPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class LinkifyPipeModule {}
