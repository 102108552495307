import { NgModule } from '@angular/core';
import { PreventDefaultDirective } from './prevent-default.directive';

const DIRECTIVE = [PreventDefaultDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class PreventDefaultModule {}
