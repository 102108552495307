import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
	BillingDetails,
	CouponResponse,
	SubscriptionService,
} from '@injectables/services/subscription/subscription.service';
import { Plan, PlanPaymentInterval, Product, ProductType, Subscription } from 'domain-entities';
import { filter, map, mergeMap, startWith, take, withLatestFrom } from 'rxjs/operators';
import { SubscribedEmployeesLicences } from '@modules/features/subscription/products/manage-company-employess/model/manage-company-employee.model';
import {
	SubscriptionChange,
	SubscriptionChangeWithExistingSubscription,
} from '@modules/features/subscription/products/model/SubscriptionChange';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TrackingService } from '../tracking.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { TranslateService } from '@ngx-translate/core';
import {
	selectProductsByType,
	selectProductsTypeSubscription,
} from '@store/selectors/products.selectors';
import { shareReplayOne } from '@craftnote/shared-utils';
import { SubscriptionChangeDirection } from '@modules/features/subscription/products/model/SubscriptionChangeDirection';
import { SubscriptionChangeType } from '@modules/features/subscription/products/model/SubscriptionChangeType';
import {
	PaymentIntervalChange,
	ProductChange,
} from '@modules/features/subscription/products/product-confirm/model/ProductChange';
import { EmployeeChange } from '@modules/features/subscription/products/manage-company-employess/model/EmployeeChange';
import {
	UpgradesSubscriptionDowngradedEventBuilder,
	UpgradesSubscriptionUpgradedEventBuilder,
} from '@generated/events/UpgradesEvents.generated';
import { SubscriptionChangeStatus } from '@modules/features/subscription/products/model/SubscriptionChangeStatus';
import {
	getChangeDirection,
	getChangeDirectionBySubscriptionLicenses,
	getChangeDirectionBySubscriptionLicensesWithUnchanged,
	getChangeDirectionWithUnchanged,
	getCurrentPlan,
	getIntervalEquivalentPlan,
	getNextPlan,
	getPlanByPaymentInterval,
	getProductByGrade,
	getProductBySubscription,
	getProductEquivalentPlan,
} from '@modules/features/subscription/products/shared/shared.functions';

@Injectable({
	providedIn: 'root',
})
export class ProductChangeService {
	private _productGrade$ = new Subject<number>();
	private subscribedEmployees$ = new Subject<SubscribedEmployeesLicences>();

	private toggledPaymentInterval$ = new BehaviorSubject<PlanPaymentInterval>(
		PlanPaymentInterval.MONTHLY,
	);
	private productChanges = new Subject<SubscriptionChange>();
	private products$: Observable<Product[]>;
	private subscription$: Observable<Subscription | undefined>;
	private product$: Observable<Product>;
	private billingDetails$: Observable<BillingDetails | undefined>;

	constructor(
		private readonly confirmService: ConfirmDialogService,
		private readonly subscriptionService: SubscriptionService,
		private readonly trackingService: TrackingService,
		private readonly store: Store<AppState>,
		private readonly translate: TranslateService,
	) {
		this.initProducts();
		this.initSubscription();
		this.initData();
		this.initBillingDetails();
		this.initNewSubscription();
		this.initProductChanges();
		this.initPaymentIntervalChanges();
		this.initEmployeeChanges();
	}

	initProducts(): void {
		const subscriptionProducts$ = this.store.select(selectProductsTypeSubscription);
		const trialProducts$ = this.store.select(selectProductsByType, { type: ProductType.TRIAL });

		this.products$ = combineLatest([subscriptionProducts$, trialProducts$]).pipe(
			map(([subscriptionProducts, trialProducts]) => [...subscriptionProducts, ...trialProducts]),
		);
	}

	initSubscription(): void {
		this.subscription$ = this.subscriptionService
			.getActiveSubscription()
			.pipe(startWith(null), shareReplayOne());
	}

	initBillingDetails(): void {
		this.billingDetails$ = combineLatest([this.subscription$, this.product$]).pipe(
			filter(([subscription]) => !!subscription),
			mergeMap(([subscription, product]) => {
				if (product.type === ProductType.TRIAL) {
					return of(undefined);
				}
				return this.subscriptionService.getBillingInformation(subscription.id);
			}),
			shareReplayOne(),
		);
	}

	initData(): void {
		this.product$ = combineLatest([this.products$, this.subscription$]).pipe(
			map((params) => {
				const [products, subscription] = params;
				return getProductBySubscription(products, subscription);
			}),
			shareReplayOne(),
		);
	}

	/**
	 * The following init functions will listen for new scheduling events and
	 * create new open Subscription Changes accordingly
	 *
	 */

	initNewSubscription(): void {
		const productState$ = combineLatest([
			this.product$,
			this.subscription$,
			this.products$,
			this.toggledPaymentInterval$,
		]);
		this._productGrade$
			.pipe(
				withLatestFrom(productState$),
				map(([grade, state]) => {
					const [oldProduct, subscription, allProducts, togglePaymentInterval] = state;
					if (subscription && oldProduct.type !== ProductType.TRIAL) {
						return;
					}
					const newProduct = getProductByGrade(allProducts, grade);
					const changeDirection = SubscriptionChangeDirection.UPGRADE;
					const newPlan = getPlanByPaymentInterval(newProduct, togglePaymentInterval);
					const changeType = SubscriptionChangeType.NEW_SUBSCRIPTION;
					return {
						from: {
							product: oldProduct,
							plan: null,
						},
						to: {
							product: newProduct,
							plan: newPlan,
						},
						changeType,
						changeDirection,
						status: SubscriptionChangeStatus.OPEN,
					};
				}),
				filter((change) => !!change),
			)
			.subscribe((change) => this.productChanges.next(change));
	}

	initPaymentIntervalChanges(): void {
		const state$ = combineLatest([
			this.subscription$,
			this.products$,
			this.billingDetails$,
			this.product$,
		]);
		this.toggledPaymentInterval$
			.pipe(withLatestFrom(state$))
			.subscribe(async ([newPaymentInterval, state]) => {
				const [subscription, allProducts, billingDetails, product] = state;
				if (!subscription || !billingDetails || product.type === ProductType.TRIAL) {
					return;
				}

				const currentPlan = getCurrentPlan(allProducts, subscription);
				let basePlan = currentPlan;
				let newPlan = getProductEquivalentPlan(allProducts, basePlan, newPaymentInterval);
				let changeDirection;
				if (
					!subscription.nextState ||
					subscription.nextState.planId === subscription.currentState.planId
				) {
					changeDirection = getChangeDirectionWithUnchanged(basePlan.grade, newPlan.grade);
				} else {
					changeDirection = getChangeDirection(basePlan.grade, newPlan.grade);
				}

				if (changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
					basePlan = getNextPlan(allProducts, subscription);
				}
				newPlan = getProductEquivalentPlan(allProducts, basePlan, newPaymentInterval);
				let coupon: { response: CouponResponse; ignoreValid: boolean };
				if (currentPlan.stripeId === newPlan.stripeId) {
					const response = await this.getCouponResponse(newPlan);
					if (response) {
						coupon = { response, ignoreValid: true };
					}
				}
				const subscriptionChange: PaymentIntervalChange = {
					subscription,
					newPaymentInterval,
					changeDirection,
					allProducts,
					coupon,
					billingDetails,
					to: {
						plan: newPlan,
					},
					changeType: SubscriptionChangeType.PAYMENT_INTERVAL,
					status: SubscriptionChangeStatus.OPEN,
				};

				this.productChanges.next(subscriptionChange);
			});
	}

	initProductChanges(): void {
		const productState$ = combineLatest([
			this.product$,
			this.subscription$,
			this.products$,
			this.billingDetails$,
		]);
		this._productGrade$
			.pipe(
				withLatestFrom(productState$),
				mergeMap(async ([productGrade, state]) => {
					const [oldProduct, subscription, allProducts, billingDetails] = state;
					if (
						!subscription ||
						!billingDetails ||
						this._productGrade$ === undefined ||
						oldProduct.type === ProductType.TRIAL
					) {
						return;
					}
					const oldPlan = getCurrentPlan(allProducts, subscription);
					let basePlan = oldPlan;
					const changeDirection = getChangeDirection(oldPlan.grade, productGrade);

					if (changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
						if (!subscription.nextState) {
							return;
						}
						basePlan = getNextPlan(allProducts, subscription);
					}
					const newPlan = getIntervalEquivalentPlan(allProducts, productGrade, basePlan);
					const newProduct = getProductByGrade(allProducts, productGrade);
					const changeType = SubscriptionChangeType.PRODUCT_CHANGE;
					const result: ProductChange = {
						from: {
							product: oldProduct,
							plan: oldPlan,
						},
						to: {
							product: newProduct,
							plan: newPlan,
						},
						subscription,
						changeDirection,
						changeType,
						allProducts,
						coupon: undefined,
						billingDetails,
						status: SubscriptionChangeStatus.OPEN,
					};
					return result;
				}),
				filter((change) => !!change),
			)
			.subscribe((change) => this.productChanges.next(change));
	}

	initEmployeeChanges(): void {
		const state$ = combineLatest([
			this.subscribedEmployees$,
			this.subscription$,
			this.products$,
			this.billingDetails$,
		]);
		state$.subscribe(async (params) => {
			const [changes, subscription, allProducts, billingDetails] = params;
			if (!subscription || !billingDetails) {
				return;
			}

			const subscribedEmployeesLicences: SubscribedEmployeesLicences = {
				employees: subscription.currentState.employees,
				quantity: subscription.currentState.quantity,
			};

			let changeDirection;
			if (
				!subscription.nextState ||
				subscription.currentState.quantity === subscription.nextState.quantity
			) {
				changeDirection = getChangeDirectionBySubscriptionLicensesWithUnchanged(
					subscribedEmployeesLicences,
					changes,
				);
			} else {
				changeDirection = getChangeDirectionBySubscriptionLicenses(
					subscribedEmployeesLicences,
					changes,
				);
			}

			let nextPlan = getCurrentPlan(allProducts, subscription);
			if (changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
				nextPlan = getNextPlan(allProducts, subscription);
			}
			const couponResponse = await this.getCouponResponse(nextPlan);
			let coupon: { response: CouponResponse; ignoreValid: boolean };
			if (couponResponse) {
				coupon = { response: couponResponse, ignoreValid: true };
			}
			const employeeChange: EmployeeChange = {
				subscription,
				changeDirection,
				allProducts,
				coupon,
				billingDetails,
				to: {
					plan: nextPlan,
				},
				employees: changes.employees,
				quantity: changes.quantity,
				status: SubscriptionChangeStatus.OPEN,
				changeType: SubscriptionChangeType.EMPLOYEES_CHANGE,
			};
			this.productChanges.next(employeeChange);
		});
	}

	async confirmSubscriptionChange(productChange: SubscriptionChange): Promise<void> {
		const processingChanges: SubscriptionChange = {
			...productChange,
			status: SubscriptionChangeStatus.PROCESSING,
		};
		const confirmedChanges: SubscriptionChange = {
			...productChange,
			status: SubscriptionChangeStatus.CONFIRMED,
		};

		// In case of a new subscription the communication with the backend is handled by the wizard
		// no more action is required here.
		if (productChange.changeType === SubscriptionChangeType.NEW_SUBSCRIPTION) {
			this.productChanges.next(confirmedChanges);
			return;
		}

		if (productChange.changeDirection === SubscriptionChangeDirection.UNCHANGED) {
			this.productChanges.next(confirmedChanges);
			return;
		}

		this.productChanges.next(processingChanges);
		if (productChange.changeType === SubscriptionChangeType.PAYMENT_INTERVAL) {
			await this.confirmPaymentIntervalChange(productChange as PaymentIntervalChange);
		}

		if (productChange.changeType === SubscriptionChangeType.PRODUCT_CHANGE) {
			await this.confirmProductChange(productChange as ProductChange);
		}

		if (productChange.changeType === SubscriptionChangeType.EMPLOYEES_CHANGE) {
			await this.confirmEmployeesChange(productChange as EmployeeChange);
		}

		this.trackProductChange(confirmedChanges);
		this.productChanges.next(confirmedChanges);
		return;
	}

	getProductChanges(): Observable<SubscriptionChange> {
		return this.productChanges.asObservable();
	}

	scheduleProductChange(grade: number): void {
		this._productGrade$.next(grade);
	}

	scheduleEmployeeChange(subscribedEmployees: SubscribedEmployeesLicences): void {
		this.subscribedEmployees$.next(subscribedEmployees);
	}

	updatePaymentInterval(paymentInterval: PlanPaymentInterval): void {
		this.toggledPaymentInterval$.next(paymentInterval);
	}

	private async confirmPaymentIntervalChange(
		paymentIntervalChange: PaymentIntervalChange,
	): Promise<void> {
		if (paymentIntervalChange.changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
			if (!paymentIntervalChange.subscription.nextState) {
				return;
			}
			if (
				paymentIntervalChange.subscription.nextState.planId ===
				paymentIntervalChange.to.plan.stripeId
			) {
				return;
			}
		}
		const contextWithNewProduct = {
			subscriptionId: paymentIntervalChange.subscription.id,
			planId: paymentIntervalChange.to.plan.stripeId,
		};
		await this.subscriptionService.updateSubscription(contextWithNewProduct);
	}

	private async confirmProductChange(productChange: ProductChange): Promise<void> {
		const contextWithNewProduct = {
			subscriptionId: productChange.subscription.id,
			planId: null,
		};

		if (productChange.to.product.grade === 0) {
			await this.subscriptionService.cancelSubscription(contextWithNewProduct);
			this.productChanges.next(productChange);
			return;
		}
		contextWithNewProduct.planId = productChange.to.plan.stripeId;
		await this.subscriptionService.updateSubscription(contextWithNewProduct);
		this.productChanges.next(productChange);
	}

	private async confirmEmployeesChange(employeesChange: EmployeeChange): Promise<void> {
		if (!employeesChange.to.plan) {
			this.confirmService.open({
				title: this.translate.instant('subscription.licensesNumber.title'),
				message: this.translate.instant('subscription.employees.reduce-licences-denied'),
				primaryButtonText: this.translate.instant(
					'subscription.employees.reduce-licences-denied.dialog.close',
				),
				showSecondaryButton: false,
			});
			return;
		}

		const contextWithNewProduct = {
			subscriptionId: employeesChange.subscription.id,
			employees: employeesChange.employees,
			quantity: employeesChange.quantity,
		};
		await this.subscriptionService.changeQuantity(contextWithNewProduct);
	}

	private async getCouponResponse(plan: Plan): Promise<CouponResponse | undefined> {
		const subscription = await this.subscription$.pipe(take(1)).toPromise();
		if (!plan) {
			return undefined;
		}
		if (!subscription.appliedCoupon) {
			return undefined;
		}
		const coupon = await this.subscriptionService.getOrLoadCouponResponse(plan.stripeId);
		if (!coupon) {
			return undefined;
		}
		return coupon;
	}

	private async trackProductChange(change: SubscriptionChange): Promise<void> {
		const planId = change.to.plan ? change.to.plan.stripeId : undefined;
		let numberOfUsers;
		if (change.changeType === SubscriptionChangeType.EMPLOYEES_CHANGE) {
			numberOfUsers = (change as EmployeeChange).quantity;
		} else if (change.changeType === SubscriptionChangeType.PRODUCT_CHANGE) {
			numberOfUsers = (change as SubscriptionChangeWithExistingSubscription).subscription
				.currentState.quantity;
		}
		const payload = { numberOfUsers };
		if (planId) {
			payload['planId'] = planId;
		}

		if (change.changeDirection === SubscriptionChangeDirection.UPGRADE) {
			await this.trackingService.trackEvent(new UpgradesSubscriptionUpgradedEventBuilder({}));
		} else if (change.changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
			await this.trackingService.trackEvent(new UpgradesSubscriptionDowngradedEventBuilder({}));
		}
	}
}
