<div *ngIf="!isHidden" class="d-flex justify-content-between align-items-center mr-2">
	<mat-form-field class="w-100 flex-grow-1 pr-3">
		<input
			[formControl]="formHelper.searchControl"
			matInput
			name="search"
			data-cy="project-search-box"
			placeholder="{{ 'project.search.main' | translate }}"
			type="text"
		/>
		<span matSuffix>
			<em
				(click)="this.formHelper.searchControl.setValue(''); updateVisibleProjects()"
				class="material-icons pointer"
				data-cy="project-search-clear-button"
				>{{ formHelper.isSearchActive ? 'close' : 'search' }}</em
			>
		</span>
	</mat-form-field>
	<div class="d-flex pb-3">
		<app-multi-menu [menuConfig]="config" (onOpenDisabledDialog)="fireProjectFilterpaywallEvent()" class="mx-2"></app-multi-menu>
		<mat-icon #matMenuTrigger="matMenuTrigger" [mat-menu-trigger-for]="sortMenu" svgIcon="custom-sort" class="pointer"></mat-icon>
		<mat-menu #sortMenu>
			<app-project-sort
				[isMenuOpen]="isSortMenuOpen"
				[formControl]="sortControl"
				(openGroupingPaywall)="this.openGroupingPaywall()"
			></app-project-sort>
		</mat-menu>
	</div>
</div>
<div class="pb-2" *ngIf="formHelper.anyNonSearchFilterActive">
	<mat-chip-list>
		<mat-chip>
			{{ filterNumberText | translate: { number: formHelper.getNumberOfActiveNonSearchFilters } }}
			<button matChipRemove>
				<mat-icon (click)="resetAllNonSearchFilters()" data-cy="project-search-component-reset-all-filters">cancel </mat-icon>
			</button>
		</mat-chip>
	</mat-chip-list>
</div>

<ng-template #drop>
	<app-multi-select-panel
		[config]="statusIdsConfig$ | async"
		[formControl]="formHelper.statusControl"
		appEventStopPropagation
	></app-multi-select-panel>
</ng-template>

<ng-template #folder>
	<app-multi-select-panel
		[config]="foldersConfig"
		[formControl]="formHelper.folderControl"
		appEventStopPropagation
	></app-multi-select-panel>
</ng-template>

<ng-template let-dialogRef="dialogRef" #startDate>
	<app-date-range-dialog
		[textConfig]="startDateTextConfig"
		[timeRange]="formHelper.startDateControl"
		(close)="dialogRef.close()"
		(set)="onStartDateFilterSet($event, dialogRef)"
	></app-date-range-dialog>
</ng-template>

<ng-template let-dialogRef="dialogRef" #endDate>
	<app-date-range-dialog
		[textConfig]="endDateTextConfig"
		[timeRange]="formHelper.endDateControl"
		(close)="dialogRef.close()"
		(set)="onEndDateFilterSet($event, dialogRef)"
	></app-date-range-dialog>
</ng-template>

<ng-template #employee>
	<app-multi-select-panel
		appEventStopPropagation
		[formControl]="formHelper.employeesControl"
		[config]="employeesConfig$ | async"
	></app-multi-select-panel>
</ng-template>

<ng-template let-dialogRef="dialogRef" #filterPaywall>
	<app-grouping-paywall feature="filters" [dialogRef]="dialogRef"></app-grouping-paywall>
</ng-template>

<ng-template let-dialogRef="dialogRef" #groupingPaywall>
	<app-grouping-paywall feature="grouping" [dialogRef]="dialogRef"></app-grouping-paywall>
</ng-template>
