import { Action, createReducer, on } from '@ngrx/store';
import { updateApplicationVersionState } from '../actions/application-version.actions';
import { ApplicationVersionState } from '../state/application-version.state';

const initialState: ApplicationVersionState = null;

const applicationVersionReducer = createReducer(
	initialState,
	on(updateApplicationVersionState, (_state, payload) => ({
		...payload.appVersion,
	})),
);

// tslint:disable-next-line:typedef
export function appVersionReducer(state: any | undefined, action: Action) {
	return applicationVersionReducer(state, action);
}
