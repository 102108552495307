<div
	[class.grid-view]="gridView"
	[ngStyle]="filePath?.thumbnail && { 'background-image': 'url(' + filePath?.thumbnail + ')' }"
	class="files-section-icon d-flex justify-content-center align-items-center"
>
	<ng-container *ngIf="!filePath?.thumbnail">
		<span *ngIf="hasValidExtension; else noFileExtension">{{ file.name | fileExtension | slice: 0:4 }}</span>
		<ng-template #noFileExtension>
			<mat-icon>question_mark</mat-icon>
		</ng-template>
	</ng-container>
</div>
