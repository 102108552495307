<div class="mat-typography position-relative">
	<div mat-dialog-title class="d-flex align-items-start justify-content-between m-0 mat-display-1 text-break">
		<span>{{ config.title }}</span>

		<button class="close-icon" mat-icon-button [mat-dialog-close]="false" *ngIf="config.showCrossBtn">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content *ngIf="config.message">
		<p class="message text-break" [innerHTML]="config.message"></p>
		<p *ngIf="config.contentHint; &quot;text-break&quot;" class="hint-message p-2" [innerHTML]="config.contentHint"></p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button
			*ngIf="config.showTertiaryButton"
			mat-stroked-button
			(click)="onClose(config.tertiaryButtonValue)"
			[color]="config.tertiaryButtonColor"
			data-cy="confirmation-dialog-tertiary-button"
		>
			{{ config.tertiaryButtonText }}
		</button>
		<button
			*ngIf="config.showSecondaryButton"
			mat-stroked-button
			(click)="onClose(config.secondaryButtonValue)"
			[color]="config.secondaryButtonColor"
			data-cy="confirmation-dialog-secondary-button"
		>
			{{ config.secondaryButtonText }}
		</button>
		<button
			mat-flat-button
			[loading]="actionInProgress"
			[color]="config.primaryButtonColor"
			(click)="onClose(config.primaryButtonValue)"
			cdkFocusInitial
			data-cy="confirmation-dialog-primary-button"
		>
			{{ config.primaryButtonText }}
		</button>
	</mat-dialog-actions>
	<div *ngIf="config.showCheckbox" class="d-flex justify-content-end mt-3">
		<mat-checkbox [(ngModel)]="checkboxValue" labelPosition="before">{{ config.checkboxLabel }}</mat-checkbox>
	</div>
</div>
