import { Pipe, PipeTransform } from '@angular/core';
import { isValid } from 'date-fns';

@Pipe({
	name: 'hoursFromDate',
})
export class HoursFromDatePipe implements PipeTransform {
	transform(date: Date): number | string | null {
		return isValid(date) ? date.getHours() : '--';
	}
}
