import { Injectable } from '@angular/core';
import { Message, MessageType } from 'domain-entities';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import * as fromMessageSelectors from '@modules/features/chat/store/selectors/message.selectors';
import {
	selectAllDocuments,
	selectAllImages,
	selectAllMediaMessages,
	selectAllVideos,
} from '@modules/features/chat/store/selectors/message.selectors';
import { filter, first, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PopupDialogService } from '@injectables/services/popup-dialog.service';

@Injectable({
	providedIn: 'root',
})
export class ChatDialogService {
	constructor(
		private readonly store: Store<AppState>,
		private readonly popupDialogService: PopupDialogService,
	) {}

	open(data: any): void {
		if (data.id == null || data.type == null) {
			return;
		}

		this.initMediaMessagesFromStore(data);
	}

	async initMediaMessagesFromStore(data: any): Promise<void> {
		const selectSearchOptions = await this.store
			.select(fromMessageSelectors.selectSearchOptions)
			.pipe(first())
			.toPromise();
		let messages: Message[];

		if (Object.keys(selectSearchOptions).length) {
			const messagesFilterType = selectSearchOptions.type;
			let mediaFileTypeSelector = [];

			if (!messagesFilterType || messagesFilterType.includes(MessageType.IMAGE)) {
				mediaFileTypeSelector = mediaFileTypeSelector.concat(this.store.select(selectAllImages));
			}
			if (!messagesFilterType || messagesFilterType.includes(MessageType.VIDEO)) {
				mediaFileTypeSelector = mediaFileTypeSelector.concat(this.store.select(selectAllVideos));
			}
			if (!messagesFilterType || messagesFilterType.includes(MessageType.DOCUMENT)) {
				mediaFileTypeSelector = mediaFileTypeSelector.concat(this.store.select(selectAllDocuments));
			}

			messages = await combineLatest(mediaFileTypeSelector)
				.pipe(
					first(),
					map((mediaFiles: Message[]) => this.filterChatMessages(mediaFiles.flat(), data.type)),
				)
				.toPromise();
			if (selectSearchOptions.text) {
				messages = messages.filter((message) =>
					message.tags.find((tag) =>
						tag.toLowerCase().includes(selectSearchOptions.text.toLowerCase()),
					),
				);
			}
		} else {
			messages = await this.store
				.select(selectAllMediaMessages)
				.pipe(
					filter((msgs) => !!msgs.length),
					first(),
					map((msgs) => this.filterChatMessages(msgs, data.type)),
				)
				.toPromise();
		}

		const position = messages.findIndex((message) => message.id === data.currentMessage.id);
		this.popupDialogService.openPopupDialog(messages, position, false);
	}

	private filterChatMessages = (messages: Message[], type: MessageType): Message[] => {
		return messages
			.filter((message) => {
				if (message.deleted) {
					return false;
				}
				if ([MessageType.IMAGE, MessageType.VIDEO].includes(type)) {
					return [MessageType.IMAGE, MessageType.VIDEO].includes(message.messageType);
				} else {
					return MessageType.DOCUMENT === message.messageType;
				}
			})
			.sort((a, b) => a.timestamp - b.timestamp);
	};
}
