import { Component, Input } from '@angular/core';
import { Project } from 'domain-entities';
import { ProjectType } from '@shared/models/project.type';

@Component({
	selector: 'app-project-indicator',
	templateUrl: './project-indicator.component.html',
	styleUrls: ['./project-indicator.component.scss'],
})
export class ProjectIndicatorComponent {
	@Input() project: Project;

	ProjectType = ProjectType;

	getTagClass(tag): string {
		switch (tag) {
			case 'COLOR1':
				return 'tag-red-border-color tag-red';
			case 'COLOR2':
				return 'tag-orange-border-color tag-orange';
			case 'COLOR3':
				return 'tag-yellow-border-color tag-yellow';
			case 'COLOR4':
				return 'tag-green-border-color tag-green';
			case 'COLOR5':
				return 'tag-purple-border-color tag-purple';
			case 'COLOR6':
				return 'tag-blue-border-color tag-blue';
			case 'COLOR7':
				return 'tag-teal-border-color tag-teal';
			case 'COLOR8':
				return 'tag-pink-border-color tag-pink';
			case 'COLOR9':
				return 'tag-dirty-green-border-color tag-dirty-green';
			case 'COLOR10':
				return 'tag-royal-blue-border-color tag-royal-blue';
		}
	}

	getFolderIndicatorColor(tag): string {
		switch (tag) {
			case 'COLOR1':
				return 'tag-red-border';
			case 'COLOR2':
				return 'tag-orange-border';
			case 'COLOR3':
				return 'tag-yellow-border';
			case 'COLOR4':
				return 'tag-green-border';
			case 'COLOR5':
				return 'tag-purple-border';
			case 'COLOR6':
				return 'tag-blue-border';
			case 'COLOR7':
				return 'tag-teal-border';
			case 'COLOR8':
				return 'tag-pink-border';
			case 'COLOR9':
				return 'tag-dirty-green-border';
			case 'COLOR10':
				return 'tag-royal-blue-border';
		}
	}
}
