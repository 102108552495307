import { Injectable } from '@angular/core';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import firebase from 'firebase/compat/app';
import { PerformanceTrace, PerformanceTraceConstants } from '@shared/constants/performace-trace';
import { EntityChanges } from '@craftnote/shared-utils';
import { OmitErrors } from '@decorators/error.decorators';

export const performanceTrace = trace;

@Injectable({
	providedIn: 'root',
})
export class PerformanceTraceService {
	private traces: { [id: string]: firebase.performance.Trace } = {};

	constructor(private readonly performance: AngularFirePerformance) {}

	@OmitErrors({
		isAsync: true,
	})
	async start(name: string, id?: string): Promise<string> {
		const keyOfTrace = id || name;

		/**
		 * There is no point to try restarting a trace
		 */
		if (this.traces[keyOfTrace]) {
			return;
		}

		const newTrace = await this.performance.trace(name);
		this.traces[keyOfTrace] = newTrace;
		newTrace.start();
		return keyOfTrace;
	}

	@OmitErrors()
	stop(keyOfTrace: string, metric?: { name: string; count: number }): void {
		const currentTrace = this.traces[keyOfTrace];

		if (!currentTrace) {
			return;
		}

		if (metric) {
			currentTrace.putAttribute(metric.name, String(metric.count));
			currentTrace.putMetric(metric.name, metric.count);
		}

		currentTrace.stop();
		delete this.traces[keyOfTrace];
	}

	logTraceWhenEntitiesCreated = <T>(
		entityState: EntityChanges<T>,
		traceName: PerformanceTrace,
		metric?: { name: PerformanceTraceConstants; count: number },
	) => {
		if (entityState.changeType === 'created') {
			this.stop(traceName, metric);
		}
	};
}
