<div>
	<h2 class="text-center" mat-dialog-title style="margin: 0">
		{{ !isFile ? ('explorer.approve-dialog.title' | translate) : ('explorer.approve-dialog.title-file' | translate) }}
	</h2>
	<span class="text-center d-block">{{ 'explorer.approve-dialog.content' | translate }}</span>
	<mat-dialog-actions>
		<button [mat-dialog-close]="true" mat-button>
			{{ 'project.archive_dialog.approve' | translate }}
		</button>
		<button [mat-dialog-close]="false" mat-button>
			{{ 'project.archive_dialog.cancel' | translate }}
		</button>
	</mat-dialog-actions>
</div>
