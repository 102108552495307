import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { setDefaultOptions } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

const isLocaleEnglish = (locale) => {
	const languageCode = locale.split('-')[0];
	return languageCode.toLowerCase() === 'en';
};

export const registerLocales = (locale: string = 'en-US') => {
	const isEnglish = isLocaleEnglish(locale);
	registerLocaleData(isEnglish ? localeEn : localeDe);
	setDefaultOptions({ locale: isEnglish ? enUS : de });
};
