import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { PaywallDialogConfig } from './paywall-dialog.types';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectTheme } from '@store/selectors/app.selectors';
import { AppTheme } from '@store/reducers/app.reducer';
import { map } from 'rxjs';

@Component({
	selector: 'app-paywall-dialog',
	templateUrl: './paywall-dialog.component.html',
	styleUrls: ['./paywall-dialog.component.scss'],
})
export class PaywallDialogComponent {
	paywallImageSource$ = this.store
		.select(selectTheme)
		.pipe(
			map((theme) =>
				theme === AppTheme.LIGHT
					? 'assets/images/no-project-remaining-paywall.png'
					: 'assets/images/no-project-remaining-paywall-dark.png',
			),
		);

	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly config: PaywallDialogConfig,
		private readonly dialogRef: MatDialogRef<PaywallDialogComponent>,
		private readonly store: Store<AppState>,
	) {}

	async onClose(value: any): Promise<void> {
		this.dialogRef.close(value);
	}
}
