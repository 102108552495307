import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { ProjectFile } from '@shared/models/project-file.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class DragAndDropManagerService {
	private dragDropFiles$ = new Subject<CdkDragDrop<Array<ProjectFile>>>();
	private list = new BehaviorSubject<string[]>([]);

	onListChange(): Observable<string[]> {
		return this.list.asObservable();
	}

	register(id: string, ignoreDestinationItems?: string[]): void {
		if (!id || this.list.value.includes(id) || ignoreDestinationItems?.includes(id)) {
			return;
		}
		this.list.next([...this.list.value, id]);
	}

	unregister(id: string): void {
		this.list.next(this.list.value.filter((fileId) => fileId !== id));
	}

	getDragDropFiles(): Observable<CdkDragDrop<ProjectFile[]>> {
		return this.dragDropFiles$.asObservable();
	}

	setDragDropFiles(dragDropFiles: CdkDragDrop<ProjectFile[]>): void {
		this.dragDropFiles$.next(dragDropFiles);
	}
}
