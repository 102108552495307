import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { WorkRouteState } from '../entities/router/work-route.types';
import { AuthState } from '../state/auth.state';

const routerState = createFeatureSelector<RouterReducerState>('router');
export const selectAuthState = createFeatureSelector<AuthState>('authState');

/**
 * This selector returns:
 * undefined if there the current route is not a work route (project or archive)
 * null if the current route is a work route but no project id is set
 * a project id if the current route is a work route AND a project is selected
 */

export const selectWorkRouteState = createSelector(routerState, (router) => {
	if (!router) {
		return undefined;
	}

	const workRoute = router.state.root.children[0]?.url[0]?.path as 'projects' | 'archive';
	if (!['projects', 'archive'].includes(workRoute)) {
		return undefined;
	}
	const currentProject = router.state.root.children[0]?.children[0]?.params['projectId'] || null;
	const rightSideMenu =
		router.state.root.children[0]?.children[0]?.children[0]?.url[0]?.path || null;
	return { workRoute, currentProject, rightSideMenu, routerState: router } as WorkRouteState;
});

export const selectActiveProject = createSelector(selectWorkRouteState, (workRouteState) => {
	return workRouteState?.currentProject;
});

export const selectRightSideMenu = createSelector(selectWorkRouteState, (workRouteState) => {
	return workRouteState?.rightSideMenu;
});

export const selectIsProjectsRoute = createSelector(
	selectWorkRouteState,
	(workRouteState) => workRouteState?.workRoute === 'projects',
);

export const selectIsProjectArchiveRoute = createSelector(
	selectWorkRouteState,
	(workRouteState) => workRouteState?.workRoute === 'archive',
);
