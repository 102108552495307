<nav class="navbar justify-content-between navbar-expand-lg mycrafty-navbar" *ngIf="authenticated || publicRoute">
	<div class="d-flex">
		<img
			(click)="goToHome()"
			[attr.src]="(theme$ | async) === Theme.LIGHT ? 'assets/images/logo_dark.svg' : 'assets/images/logo.svg'"
			class="navbar-brand ml-3 pointer mr-2"
			width="176"
			alt="logo"
		/>
		<a
			href=""
			class="color-primary mat-headline align-self-end mb-2 d-none d-md-flex"
			(click)="onClickPaywallHeaderButton()"
			*ngIf="isPaywallHeaderEnabled && (isCurrentUserBasic$ | async)"
		>
			{{ 'navbar.update.startPro' | translate }}
		</a>
	</div>
	<div *ngIf="authenticated" class="row mr-1 mr-md-4 d-sm-none">
		<div (click)="logout()" class="pointer color-primary d-flex align-items-center navbar__logout">
			<span><mat-icon class="mr-2" svgIcon="logout"></mat-icon></span>
			<span>{{ 'settings.logout' | translate }}</span>
		</div>
	</div>

	<div *ngIf="!(isPdfViewerRoute$ | async)" class="row mr-1 mr-md-4 d-none d-md-flex">
		<ng-template [ngIf]="authenticated" [ngIfElse]="guest">
			<div *ngIf="isLatestVersionAvailable$ | async" class="app-version mr-3 d-flex align-items-center">
				{{ 'navbar.update.infoText' | translate }}&nbsp;-&nbsp;
				<span (click)="updateVersion()" class="pointer">{{ 'navbar.update.buttonText' | translate }}</span>
			</div>
			<span *ngIf="isPaywallHeaderEnabled && (isCurrentUserBasic$ | async)" class="mr-4">
				<span class="mr-2 d-none d-lg-inline-flex">{{ 'navbar.update.boostProductivity' | translate }}</span>
				<button mat-flat-button color="accent" (click)="openPaywallHeaderDialog()">
					{{ 'navbar.update.explorePro' | translate }}
				</button>
			</span>
			<app-theme-toggler *ngIf="!isPaywallHeaderEnabled"></app-theme-toggler>
			<div class="mr-2 pr-2 border-right" *ngIf="!environmentRef.production">
				<app-debug-dialog #debugDialogRef></app-debug-dialog>
				<button mat-icon-button class="mr-2" (click)="debugDialogRef.open()">
					<mat-icon [size]="30">bug_report</mat-icon>
				</button>
			</div>

			<app-global-search-dialog #globalSearchDialogRef></app-global-search-dialog>
			<ng-container *appFeatureFlag="'feature_global_search'">
				<button mat-icon-button class="mr-2" (click)="globalSearchDialogRef.openDialog()">
					<mat-icon [size]="30">search</mat-icon>
				</button>
			</ng-container>

			<button mat-icon-button class="mr-2 align-items-center" id="loopedin">
				<div class="d-flex">
					<mat-icon fontSet="material-icons-outlined" [size]="30">tips_and_updates</mat-icon>
				</div>
			</button>
			<button mat-icon-button routerLink="settings" data-cy="nav-header-settings">
				<mat-icon class="material-icons-outlined" [size]="30">settings</mat-icon>
			</button>
		</ng-template>
	</div>
</nav>

<ng-template #guest>
	<div class="register">
		<ng-template [ngIf]="deviceType === DEVICE_TYPE.OTHER" [ngIfThen]="registerOther"></ng-template>
		<ng-template [ngIf]="deviceType === DEVICE_TYPE.ANDROID" [ngIfThen]="registerAndroid"></ng-template>
		<ng-template [ngIf]="deviceType === DEVICE_TYPE.IOS" [ngIfThen]="registerIos"></ng-template>
	</div>
</ng-template>

<ng-template #registerOther>
	<button mat-flat-button color="accent" (click)="router.navigate(['/register'])">
		{{ 'navbar.register' | translate }}
	</button>
</ng-template>
<ng-template #registerAndroid>
	<a href="https://play.google.com/store/apps/details?id=de.mycrafty.mycrafty" target="_blank" rel="noopener">
		<img alt="Play store" src="assets/images/icons/download-play-store.svg" width="120px" />
	</a>
</ng-template>
<ng-template #registerIos>
	<a href="https://itunes.apple.com/de/app/mycrafty-handwerker-app/id1327810465?mt=8" target="_blank" rel="noopener">
		<img alt="App store" src="assets/images/icons/download-app-store.svg" width="120px" />
	</a>
</ng-template>
