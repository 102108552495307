<div>
	<div class="mt-4 col-1 float-left">
		<div class="row justify-content-center">
			<a (click)="goBack()" class="col-2 mt-1" href="">
				<em class="material-icons" data-cy="billing-address-back-btn">arrow_back</em>
			</a>
		</div>
	</div>
	<div class="billing-address-headline p-4">{{ 'project.title_billing_address' | translate }}</div>
</div>
<div class="container form-group mt-2">
	<form [formGroup]="billingFormGroup" (ngSubmit)="save()" class="form">
		<div class="row">
			<mat-form-field class="col-12 mt-3">
				<input trim="blur" matInput formControlName="companyName" placeholder="{{ 'project.billingName' | translate }}" />
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col-12 mt-3">
				<input trim="blur" matInput formControlName="companyEmail" placeholder="{{ 'project.billingEmail' | translate }}" />
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col-12 mt-3">
				<input
					trim="blur"
					matInput
					formControlName="companyAddressStreet"
					data-cy="billing-address-street"
					placeholder="{{ 'project.billingStreet' | translate }}"
				/>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col-4 mt-3">
				<input
					trim="blur"
					matInput
					formControlName="companyAddressZipCode"
					data-cy="billing-address-zipcode"
					placeholder="{{ 'project.billingZipcode' | translate }}"
				/>
			</mat-form-field>
			<mat-form-field class="col-8 mt-3">
				<input
					trim="blur"
					matInput
					formControlName="companyAddressCity"
					data-cy="billing-address-city"
					placeholder="{{ 'project.billingCity' | translate }}"
				/>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field class="col-12 mt-3">
				<input
					trim="blur"
					matInput
					formControlName="companyAddressCountry"
					data-cy="billing-address-country"
					placeholder="{{ 'project.billingCountry' | translate }}"
				/>
			</mat-form-field>
		</div>
		<div class="row" *ngIf="modifiable">
			<div class="col-12 col-md-12">
				<div class="row">
					<div class="col-12 mt-4 btn-wrap">
						<button
							type="submit"
							class="float-right"
							color="accent"
							data-cy="billing-address-save-btn"
							mat-flat-button
							[disabled]="billingFormGroup.invalid"
						>
							{{ 'project.confirm' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
