import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'strokeArray',
})
export class StrokeArrayPipe implements PipeTransform {
	transform(array: number[] = []): string {
		return array.reduce((a, b) => `${a} ${b}`, '').trim();
	}
}
