<section class="project-creation-dialog">
	<div class="d-flex mb-3 mt-2">
		<div>
			<h1 *ngIf="(currentUserProjectInvitationsRemaining$ | async) !== POSITIVE_INFINITY">
				{{
					'project-create-dialog.project-remaining'
						| translate: { currentUserProjectInvitationsRemaining: currentUserProjectInvitationsRemaining$ | async }
				}}
				<a
					[tabIndex]="-1"
					color="accent"
					href="https://hilfe.mycraftnote.de/hc/de/articles/360003231211--Wie-lege-ich-ein-Projekt-an-"
					mat-button
					target="_blank"
				>
					{{ 'project-create-dialog.more-info' | translate }}
				</a>
			</h1>
			<div class="mb-0 mat-display-1" data-cy="create-project-folder-dialog">
				<span>{{ dialogTitle | translate }}</span>
			</div>
		</div>
		<mat-icon (click)="closeModal()" class="pointer ml-auto" data-cy="create-dialog-close">close</mat-icon>
	</div>
	<form
	(ngSubmit)="projectForm.valid && !isSavingInProgress && onSubmitForm(); isSavingInProgress = projectForm.valid && true"
	[class.d-none]="inviteUserViewVisible"
	[formGroup]="projectForm">
		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>
				<span *ngIf="data.projectType === ProjectType.FOLDER">{{ 'project.create-dialog.project-folder-label' | translate }}</span>
				<span *ngIf="data.projectType === ProjectType.PROJECT">{{ 'project.create-dialog.project-label' | translate }}</span>
			</mat-label>
			<input data-cy="project-form-name" formControlName="name" matInput trim="blur" />
			<mat-error *ngIf="projectForm.controls.name?.errors?.required">
				{{ 'error.required' | translate }}
			</mat-error>
			<mat-hint>
				<span *ngIf="data.projectType === ProjectType.FOLDER" [innerHTML]="'project.create-dialog.project-folder-hint' | translate"></span>
				<span *ngIf="data.projectType === ProjectType.PROJECT" [innerHTML]="'project.create-dialog.project-hint' | translate"></span>
			</mat-hint>
		</mat-form-field>
		<mat-dialog-content *ngIf="companyMembers$ | async as companyMembers" class="mt-2">
			<div class="my-3">
				<div class="mb-2 mat-display-1">
					{{ 'project.create-dialog.invited-members.title' | translate }}
				</div>

				<p class="text-muted">
					{{ 'project.create-dialog.invited-members.subtitle' | translate }}
				</p>

				<div class="invited-members__container overflow-auto rounded-small border">
					<div *ngIf="companyMembers.length > 0" class="sticky-top bg-white p-2">
						<form>
							<mat-form-field appearance="fill" class="w-100 form-field-outline px-1" floatLabel="always">
								<input
									[formControl]="searchForm"
									data-cy="project-form-member-search"
									matInput
									placeholder="{{ 'project.create-dialog.search' | translate }}"
								/>
								<div class="d-flex" matSuffix>
									<button
										(click)="onResetSearchForm()"
										*ngIf="searchForm.value?.searchText?.length > 0"
										data-cy="project-form-member-clear"
										mat-icon-button
										type="reset"
									>
										<mat-icon>clear</mat-icon>
									</button>
									<button data-cy="project-form-member-search" mat-icon-button>
										<mat-icon color="secondary">search</mat-icon>
									</button>
								</div>
							</mat-form-field>
						</form>
						
						<div class="d-flex align-items-center mt-4">
							<mat-checkbox
								(change)="toggleAllMembers($event.checked)"
								[checked]="allMembersSelected"
								[indeterminate]="someMembersSelected"
								data-cy="project-form-add-all-members"
							>
							</mat-checkbox>
							<span class="ml-2">{{ 'project.create-dialog.addAll' | translate }}</span>
						</div>
					</div>

					<ng-container *ngIf="filteredCompanyMembers$ | async as filteredMembers">
						<ng-container *ngVar="invitationsEntity$ | async as invitationsEntity">
							<ng-container *ngFor="let memberWithSelection of filteredMembers">
								<ng-container *ngIf="selectIsMemberHasProjectInvitationsRemaining$(memberWithSelection) | async">
									<div
										*ngVar="memberWithSelection.member as member"
										class="invited-member my-1 px-2 d-flex align-items-center border-bottom"
										data-cy="create-project-member-card"
									>
										<ng-container *ngIf="member?.inviteState === InviteState.ACCEPTED">
											<mat-checkbox
													(change)="toggleMemberSelection(member)"
													[checked]="memberWithSelection.selected"
													data-cy="project-form-member-checkbox"
												></mat-checkbox>
										</ng-container>
										<mt-named-icon [maxChars]="2" [name]="member | memberFullName: 'email'" class="my-1 ml-2">
											<div *ngIf="member?.inviteState === InviteState.INVITED" class="icon-before-right icon-before color-primary">
												<mat-icon [size]="12" class="align-top">help_outline</mat-icon>
											</div>
											<div *ngIf="member?.inviteState === InviteState.DECLINED" class="icon-before-right icon-before">
												<mat-icon [size]="12" class="align-top transparent">error_outline</mat-icon>
											</div>
										</mt-named-icon>
										<div class="m-2 flex-grow-1">
											<div [attr.data-cy]="member.name" class="mat-body-2">{{ member | memberFullName: 'email' }}</div>
											<div [attr.data-cy]="member.role" class="invited-member__role mat-small text-muted">
												{{ member.role | translate | titlecase }}
											</div>
										</div>
										<ng-container *ngIf="member?.inviteState === InviteState.INVITED">
											<button
												(click)="remindInvitation(invitationsEntity && invitationsEntity[member.email], $event, member.email)"
												[loading]="buttonsIdIsLoading[member.email]"
												class="mat-button-small font-weight-bold"
												color="accent"
												mat-stroked-button
												type="button"
											>
												{{ 'project.create-dialog.remind-invite-btn' | translate }}
											</button>
										</ng-container>
										<ng-container *ngIf="member?.inviteState === InviteState.DECLINED">
											<button
												(click)="resendInvitation(member, $event, member.email)"
												[loading]="buttonsIdIsLoading[member.email]"
												class="mat-button-small font-weight-bold"
												color="accent"
												mat-stroked-button
												type="button"
											>
												{{ 'project.create-dialog.resend-invite-btn' | translate }}
											</button>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>

							<div>
								<div class="mb-2 mt-4 no-project-remaining-paywall">
									<h1 class="px-2">{{ 'project-create-dialog.no-project-remaining.list-title' | translate }}</h1>
									<button (click)="openNoProjectInvitationsRemainingPaywallDialog($event)" color="accent" mat-button type="button">
										{{ 'project-create-dialog.no-project-remaining.list-learn-more' | translate }}
									</button>
								</div>

								<ng-container *ngFor="let memberWithSelection of filteredMembers">
									<ng-container *ngIf="!(selectIsMemberHasProjectInvitationsRemaining$(memberWithSelection) | async)">
										<div
											*ngVar="memberWithSelection.member as member"
											class="invited-member my-1 px-2 d-flex align-items-center border-bottom"
											data-cy="create-project-member-card"
											>
											<mat-checkbox
												[checked]="false"
												[disabled]="true"
												data-cy="project-form-member-checkbox"
											></mat-checkbox>
											<mt-named-icon [maxChars]="2" [name]="member | memberFullName: 'email'" class="my-1 ml-2">
												<div *ngIf="member?.inviteState === InviteState.INVITED" class="icon-before-right icon-before color-primary">
													<mat-icon [size]="12" class="align-top">help_outline</mat-icon>
												</div>
												<div *ngIf="member?.inviteState === InviteState.DECLINED" class="icon-before-right icon-before">
													<mat-icon [size]="12" class="align-top transparent">error_outline</mat-icon>
												</div>
											</mt-named-icon>
											<div class="m-2 flex-grow-1">
												<div [attr.data-cy]="member.name" class="mat-body-2">{{ member | memberFullName: 'email' }}</div>
												<div [attr.data-cy]="member.role" class="invited-member__role mat-small text-muted">
													{{ member.role | translate | titlecase }}
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</div>
						</ng-container>
						<div *ngIf="!companyMembers.length || filteredMembers.length === 0" class="empty-state text-muted">
							<ng-container *ngIf="!companyMembers.length">
								<img alt="members-icon" src="assets/images/members.svg" width="45" />
								<p>{{ 'project.create-dialog.no-members-text' | translate }}</p>
							</ng-container>

							<ng-container *ngIf="companyMembers.length && filteredMembers.length === 0">
								<p>{{ 'project.create-dialog.search-results.failed' | translate }}</p>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="d-flex w-100 justify-content-end">
				<button (click)="updateInviteUserView(true, $event)" *ngIf="isOwner$ | async" mat-stroked-button type="button">
					{{ 'project.create-dialog.invite-employee-btn' | translate }}
				</button>
				<button [loading]="isSavingInProgress" color="accent" data-cy="project-save-btn" mat-flat-button type="submit">
					<span *ngIf="data.projectType === ProjectType.FOLDER">{{ 'project.create-dialog.create-project-folder-btn' | translate }}</span>
					<span *ngIf="data.projectType === ProjectType.PROJECT">{{ 'project.create-dialog.create-project-btn' | translate }}</span>
				</button>
			</div>
		</mat-dialog-actions>
	</form>

	<app-add-edit-company-employee
		(afterInvitationSubmit)="updateInviteUserView(false, $event)"
		(backButtonPressed)="updateInviteUserView(false, $event)"
		*ngIf="inviteUserViewVisible"
		[backButtonTextKey]="
			data.projectType === ProjectType.PROJECT ? 'companyMembers.invite.dialog.back-btn' : 'companyMembers.invite.dialog.folder-back-btn'
		"
		[operation]="CompanyMemberOperation.ADD"
		[showBackButton]="true"
		[style.maxWidth]="'1020px'"
		class="d-block"
		context="project-creation"
	>
	</app-add-edit-company-employee>
</section>