import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { TaskEditWorkingHoursComponent } from './task-edit-working-hours/task-edit-working-hours.component';
import { TaskEditDetailsComponent } from './task-edit-details/task-edit-details.component';
import { TaskEditFilesComponent } from './task-edit-files/task-edit-files.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { TaskEditWorkingHoursUpdateComponent } from './task-edit-working-hours-update/task-edit-working-hours-update.component';
import { ConfirmDialogModule } from '@craftnote/material-theme';
import { TaskEditCommentsComponent } from './task-edit-comments/task-edit-comments.component';
import { TaskEditFormComponent } from './task-edit-form/task-edit-form.component';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { UnixToMillisPipeModule } from '../../pipes/unix-to-millis/unix-to-millis-pipe.module';
import { BytesPipeModule } from '../../pipes/bytes/bytes-pipe.module';
import { DayDifferencePipeModule } from '../../pipes/day-difference/day-difference-pipe.module';
import { MemberFullNamePipeModule } from '../../pipes/member-full-name/member-full-name-pipe.module';

const COMPONENTS = [
	TaskEditWorkingHoursComponent,
	TaskEditDetailsComponent,
	TaskEditFilesComponent,
	TaskEditWorkingHoursUpdateComponent,
	TaskEditCommentsComponent,
	TaskEditFormComponent,
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [
		SharedModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatDatetimepickerModule,
		ConfirmDialogModule,
		TimePickerModule,
		MatIconModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		NgxTrimDirectiveModule,
		MatCheckboxModule,
		MatSelectModule,
		UnixToMillisPipeModule,
		BytesPipeModule,
		DayDifferencePipeModule,
		MemberFullNamePipeModule,
		FormsModule,
	],
	exports: [COMPONENTS],
})
export class TaskEditSharedModule {}
