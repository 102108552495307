import { createEntityAdapter } from '@ngrx/entity';
import { Task } from 'domain-entities';
import { createReducer, on } from '@ngrx/store';
import * as fromTasksActions from '../actions/tasks.actions';
import { updateEntityStore } from '@store/reducers/functions/update-entity-state.functions';
import { EntityStore } from '@store/entities/entity-store';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

export const adapter = createEntityAdapter<Task>();
const initialState: EntityStore<Task> = adapter.getInitialState({
	loadingStatus: StoreLoadingStatus.INITIAL,
});

const reducer = createReducer(
	initialState,
	on(fromTasksActions.setCompanyTasks, (state, { changes }) => {
		return updateEntityStore<Task>(adapter, state, changes);
	}),
);

export const companyTasksAdapterSelectors = adapter.getSelectors();

export function companyTasksReducer(state, action): EntityStore<Task> {
	return reducer(state, action);
}
