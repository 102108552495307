import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { isUnread, Unread } from 'domain-entities';
import { Observable } from 'rxjs';
import {
	buildFirestoreCollectionPath,
	buildFirestoreDocumentPath,
} from '@utils/functions/firestore/firestore-path.functions';
import { ProfileConnector } from '@shared/firebase/connectors/firestore/collections/profiles/profile.connector';

@Injectable({ providedIn: 'root' })
export class ProjectUnreadsConnector {
	static COLLECTION_NAME = 'unreads';

	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	watchProjectUnreads(userId: string): Observable<Unread> {
		return this.firestoreConnector.watchDocument<Unread>(
			this.buildDeviceDocumentPath(userId),
			isUnread,
		);
	}

	removeProjectUnread(value: string, userId: string): void {
		this.firestoreConnector.updateDocumentArrayField(
			this.buildDeviceDocumentPath(userId),
			'items',
			value,
			'remove',
		);
	}

	async addProjectUnread(value: string, userId: string): Promise<void> {
		const path = this.buildDeviceDocumentPath(userId);
		const document = await this.firestoreConnector.getDocument<Unread>(path, () => true);
		if (!document) {
			await this.firestoreConnector.create(
				this.buildCollectionPath(userId),
				'projects',
				{
					items: [value],
				},
				isUnread,
			);
		} else {
			await this.firestoreConnector.updateDocumentArrayField(path, 'items', value, 'add');
		}
	}

	private buildDeviceDocumentPath(userId: string): string {
		return buildFirestoreDocumentPath([
			{ collectionName: ProfileConnector.COLLECTION_NAME, documentId: userId },
			{ collectionName: ProjectUnreadsConnector.COLLECTION_NAME, documentId: 'projects' },
		]);
	}
	private buildCollectionPath(userId: string): string {
		return buildFirestoreCollectionPath(
			[{ collectionName: ProfileConnector.COLLECTION_NAME, documentId: userId }],
			ProjectUnreadsConnector.COLLECTION_NAME,
		);
	}
}
