export class Tree<T> {
	readonly root: Node<T>;
	constructor() {
		this.root = new Node<T>(null, null, this);
	}

	async traverseDepthFirst(callback: (node: Node<T>) => Promise<void>): Promise<void> {
		const stack: Node<T>[] = [this.root];
		while (stack.length) {
			const node = stack.pop();
			if (node) {
				await callback(node);
				stack.push(...node.children);
			}
		}
	}

	addByPath(
		path: string,
		data: T,
		intermediateConstructor: (path: string) => T,
		pathComparator: (path: string, node: T) => boolean,
		ignoreLastPartOfPath = false,
	): void {
		const pathPartsWithoutRoot = path.split('/').slice(0, ignoreLastPartOfPath ? -1 : undefined);
		let currentNode = this.root;
		for (const pathPart of pathPartsWithoutRoot) {
			const existingChild = currentNode.children.find((child) =>
				pathComparator(pathPart, child.data),
			);
			if (existingChild) {
				currentNode = existingChild;
			} else {
				currentNode = currentNode.addChild(intermediateConstructor(pathPart));
			}
		}
		currentNode.addChild(data);
	}
}

export class Node<T> {
	readonly children: Node<T>[] = [];
	constructor(readonly data: T, readonly parent: Node<T> | null, readonly tree: Tree<T>) {}
	addChild(child: T): Node<T> {
		const node = new Node<T>(child, this, this.tree);
		this.children.push(node);
		return node;
	}
}
