import { Injectable } from '@angular/core';
import { EventParams } from '@globalTypes/interfaces/event';
import { TrackingConnector } from '@globalTypes/interfaces/tracking-connector';
import { IterableService } from '@injectables/services/iterable.service';

@Injectable({
	providedIn: 'root',
})
export class IterableAnalyticsConnector implements TrackingConnector {
	constructor(private readonly iterableService: IterableService) {}

	async setUser(email: string): Promise<void> {
		await this.iterableService.setUserWithEmail(email);
	}

	async trackEvent(event: string, payload: EventParams): Promise<void> {
		await this.iterableService.trackEvent(event, payload);
	}

	async removeUser(): Promise<void> {
		await this.iterableService.removeUser();
	}
}
