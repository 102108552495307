import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCompanyProjectsLoaded } from '@store/selectors/company-projects.selectors';
import { filter, first } from 'rxjs/operators';
import { subscribeCompanyProjects } from '@store/actions/company-projects.actions';

@Injectable({
	providedIn: 'root',
})
export class CompanyProjectsLoadedResolver {
	constructor(private readonly store: Store) {}

	resolve(): Observable<boolean> | Promise<boolean> | boolean {
		this.store.dispatch(subscribeCompanyProjects());
		return this.store.select(selectCompanyProjectsLoaded).pipe(filter<boolean>(Boolean), first());
	}
}
