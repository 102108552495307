import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';

export interface CompanyRolloutPhase {
	phase: OptimusStripeRolloutPhase | undefined;
}

export enum OptimusStripeRolloutPhase {
	DISABLED = 'DISABLED',
	MANAGEMENT = 'MANAGEMENT',
}

@Injectable({
	providedIn: 'root',
})
export class OptimusStripeConnector {
	static COLLECTION_NAME = 'optimusStripe';

	constructor(private readonly connector: FirestoreConnector) {}

	async getRolloutPhase(companyId: string): Promise<OptimusStripeRolloutPhase | undefined> {
		if (!companyId) {
			return undefined;
		}
		const path = `${OptimusStripeConnector.COLLECTION_NAME}/${companyId}`;
		try {
			const companyRolloutPhase = await this.connector.getDocument<CompanyRolloutPhase>(
				path,
				(doc) =>
					doc?.phase === OptimusStripeRolloutPhase.DISABLED ||
					doc?.phase === OptimusStripeRolloutPhase.MANAGEMENT,
			);
			console.log('OPTIMUS STRIPE: Found RolloutPhase', companyRolloutPhase);
			if (!companyRolloutPhase) {
				return undefined;
			}
			return companyRolloutPhase?.phase;
		} catch (e) {
			console.log('OPTIMUS STRIPE: No CompanyRolloutPhase found', e);
			return undefined;
		}
	}

	async updateOrCreateRolloutPhase(
		companyId: string,
		companyRolloutPhase: CompanyRolloutPhase,
	): Promise<void> {
		// TODO check for new purchases and disallow to disable the phase
		// get the environment variable for the purchaseService Path
		const path = `${OptimusStripeConnector.COLLECTION_NAME}`;
		try {
			await this.connector.updateDocumentPartial<CompanyRolloutPhase>(
				path,
				companyId,
				companyRolloutPhase,
				['phase'],
			);
			console.log('OPTIMUS STRIPE: Updated RolloutPhase', companyId, companyRolloutPhase);
		} catch (_) {
			try {
				await this.connector.create(
					path,
					companyId,
					companyRolloutPhase,
					(doc) =>
						doc?.phase === OptimusStripeRolloutPhase.DISABLED ||
						doc?.phase === OptimusStripeRolloutPhase.MANAGEMENT,
				);
				console.log('OPTIMUS STRIPE: Created RolloutPhase', companyId, companyRolloutPhase);
			} catch (e) {
				console.log('OPTIMUS STRIPE: Failed to update or create RolloutPhase');
			}
		}
	}
}
