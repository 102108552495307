import { Pipe, PipeTransform } from '@angular/core';
import { InviteState, Member, Project } from 'domain-entities';
import {
	getAcceptedMembersOfProject,
	getMembersOfProject,
} from '@shared/functions/project/project.functions';

@Pipe({
	name: 'projectMembers',
})
export class ProjectMembersPipe implements PipeTransform {
	transform(value: unknown, inviteState: string = null): Member[] {
		if (inviteState === InviteState.ACCEPTED) {
			return getAcceptedMembersOfProject(value as Project);
		}

		return getMembersOfProject(value as Project);
	}
}
