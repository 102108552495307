import {
	ConfirmDialogComponent,
	ConfirmDialogConfig,
	ConfirmDialogService,
} from '@craftnote/material-theme';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { intersection, keys } from 'lodash';
import { take } from 'rxjs/operators';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface InterpolationValuesModel {
	[keys: string]: { [keys: string]: string };
}

const TRANSLATABLE_KEYS: (keyof ConfirmDialogConfig)[] = [
	'message',
	'title',
	'primaryButtonText',
	'secondaryButtonText',
	'tertiaryButtonText',
	'contentHint',
	'alertMessage',
	'checkboxLabel',
];

@Injectable({
	providedIn: 'root',
})
export class ConfirmationDialogTranslatedService {
	constructor(
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {}

	async open(
		config: ConfirmDialogConfig,
		interpolationValues?: InterpolationValuesModel,
	): Promise<MatDialogRef<ConfirmDialogComponent>> {
		const keysToTranslate = intersection(keys(config), TRANSLATABLE_KEYS);
		const configWithTranslations = {};

		for (const key of keysToTranslate) {
			configWithTranslations[key] = await this.translateService
				.get(config[key], interpolationValues && interpolationValues[key])
				.pipe(take(1))
				.toPromise();
		}

		const configResult = { ...config, ...configWithTranslations };

		return this.confirmDialogService.open(configResult);
	}
}
