import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as projectFilesActions from '../actions/project-files.actions';
import { File } from 'domain-entities';
import { ProjectFilesState } from '../state/project-files.state';

export interface ProjectFilePath {
	id: string;
	thumbnail?: string;
	src?: string;
	isLoaded: boolean;
}

const fileEntityAdapter = createEntityAdapter<File>();
const filePathEntityAdapter = createEntityAdapter<ProjectFilePath>();

const initialState: ProjectFilesState = {
	files: fileEntityAdapter.getInitialState(),
	filePaths: filePathEntityAdapter.getInitialState(),
};

const reducer = createReducer(
	initialState,
	on(projectFilesActions.addProjectFilesAction, (state, { files }) => {
		const filesWithRemoved = fileEntityAdapter.removeMany(
			files.map((file) => file.id),
			state.files,
		);
		const filesWithReAdded = fileEntityAdapter.addMany(files, filesWithRemoved);
		return {
			...state,
			files: filesWithReAdded,
		};
	}),
	on(projectFilesActions.deleteProjectFilesAction, (state, { files }) => {
		return {
			...state,
			files: fileEntityAdapter.removeMany(files, state.files),
		};
	}),
	on(projectFilesActions.clearProjectFilesAction, (state) => {
		return {
			...state,
			files: fileEntityAdapter.removeAll(state.files),
		};
	}),
	on(projectFilesActions.addProjectFilePathAction, (state, { filePath }) => {
		return {
			...state,
			filePaths: filePathEntityAdapter.upsertOne(filePath, state.filePaths),
		};
	}),
	on(projectFilesActions.deleteProjectFilePathAction, (state, { filePath }) => {
		return {
			...state,
			filePaths: filePathEntityAdapter.removeOne(filePath, state.filePaths),
		};
	}),
	on(projectFilesActions.clearProjectFilesAction, (state) => {
		return {
			...state,
			filePaths: filePathEntityAdapter.removeAll(state.filePaths),
		};
	}),
	on(projectFilesActions.setLoadingFilePathAction, (state, { filePath }) => {
		return {
			...state,
			filePaths: filePathEntityAdapter.upsertOne(filePath, state.filePaths),
		};
	}),
);

export const projectFilesSelectors = fileEntityAdapter.getSelectors();
export const projectFilePathSelectors = filePathEntityAdapter.getSelectors();

// tslint:disable-next-line:typedef
export function projectFilesReducer(state: ProjectFilesState | undefined, action: Action) {
	return reducer(state, action);
}
