import { NgModule } from '@angular/core';
import { ProjectMembersPipe } from './project-members.pipe';

const PIPE = [ProjectMembersPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class ProjectMembersPipeModule {}
