import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@injectables/services/auth/auth.service';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { CompanyService } from '@injectables/services/company/company.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { SubscriptionService } from '@injectables/services/subscription/subscription.service';
import {
	InviteState,
	Member,
	MemberRole,
	Product,
	Subscription,
	SubscriptionEmployeeType,
} from 'domain-entities';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
	SubscribedEmployee,
	SubscribedEmployeesLicences,
	SubscribedEmployeeUI,
} from './model/manage-company-employee.model';
import { countBy, flattenDeep, sortBy } from 'lodash';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { ProductChangeService } from '@injectables/services/product-change/product-change.service';
import { EmployeeChange } from './model/EmployeeChange';
import { SubscriptionChangeStatus } from '../model/SubscriptionChangeStatus';
import { getCostForSubscriptionChange, getVatRateForCountry } from '../shared/shared.functions';
import { SubscriptionChangeTranslationService } from '@injectables/services/subscription-change-translation/subscription-change-translation.service';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionChangeDirection } from '../model/SubscriptionChangeDirection';
import moment from 'moment';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProductsTypeSubscription } from '@store/selectors/products.selectors';

@Component({
	selector: 'app-manage-company-employees',
	templateUrl: './manage-company-employees.component.html',
	styleUrls: ['./manage-company-employees.component.scss'],
})
export class ManageCompanyEmployeesComponent implements OnInit, OnDestroy {
	private readonly destroyer$ = new Subject();
	// Plan should be passed from input, in payment subscription wizard flow
	@Input() selectedPlanId: string;
	@Input() subscribedEmployees: SubscribedEmployeesLicences;
	@Input() fromSubscriptionOverview = false;
	@Output() confirmSelection = new EventEmitter<SubscribedEmployeesLicences>();
	@Output() back = new EventEmitter<Event>();
	MemberRole = MemberRole;
	SubscriptionEmployeeType = SubscriptionEmployeeType;
	subscribedPlanName: string;
	costText: string;
	dateChangeText: string;
	locked = false;
	loading = true;
	noOfLicencesSelectedFormControl: UntypedFormControl = new UntypedFormControl(null, [
		Validators.required,
		Validators.min(1),
		this.noOfLicencesRequiredValidator.bind(this),
	]);

	constructor(
		private readonly authService: AuthService,
		private readonly profileService: ProfileService,
		private readonly companyService: CompanyService,
		private readonly subscriptionService: SubscriptionService,
		private readonly subscriptionTranslateService: SubscriptionChangeTranslationService,
		private readonly productChangeService: ProductChangeService,
		private readonly remoteConfig: RemoteConfig,
		private readonly translate: TranslateService,
		private readonly confirmService: ConfirmDialogService,
		private readonly store: Store<AppState>,
	) {}

	_employeeChanges: EmployeeChange;

	get employeeChanges(): EmployeeChange {
		return this._employeeChanges;
	}

	@Input() set employeeChanges(employeeChanges: EmployeeChange) {
		if (!employeeChanges) {
			return;
		}
		if (employeeChanges.status === SubscriptionChangeStatus.PROCESSING) {
			this.locked = true;
			return;
		} else if (employeeChanges.status === SubscriptionChangeStatus.CONFIRMED) {
			this.locked = false;
			return;
		}
		this._employeeChanges = employeeChanges;
		this.getCostText();
		this.getChangeDateText();
	}

	get noOfLicencesSelected(): number {
		return this.noOfLicencesSelectedFormControl.value;
	}

	get numberOfUnassignedLicenses(): number {
		return this.noOfLicencesSelected - (this.employeeSubscriptionCount.paid || 0);
	}

	_employeeSubscriptions: SubscribedEmployeeUI[] = [];

	get employeeSubscriptions(): SubscribedEmployeeUI[] {
		return this._employeeSubscriptions;
	}

	set employeeSubscriptions(employeeSubscriptions: SubscribedEmployeeUI[]) {
		this._employeeSubscriptions = employeeSubscriptions;
	}

	get employeeSubscriptionCount(): { [idx: string]: number } {
		return countBy(this.employeeSubscriptions, (employee) =>
			employee.type === SubscriptionEmployeeType.PAID ? 'paid' : 'free',
		);
	}

	get minimumPaidUsersForBasicAvailability(): number {
		return this.remoteConfig.getValue('config_subscription_min_paid_employees');
	}

	getEmployeeSubscriptions(): Observable<SubscribedEmployeeUI[]> {
		return combineLatest([
			this.getCompanyMembers(),
			this.store.select(selectProductsTypeSubscription),
			this.subscriptionService.getCompanySubscriptions(),
		]).pipe(
			takeUntil(this.destroyer$),
			map(([companyMembers, products, subscriptions]) => {
				const companySubscription = this.getCompanySubscription(products, subscriptions);
				const currentPlanId = this.selectedPlanId || companySubscription.currentState.planId;
				const selectedPlan = this.getSelectedPlan(products, currentPlanId);
				const subscribedEmployees = this.subscribedEmployees
					? this.subscribedEmployees.employees
					: companySubscription.currentState.employees || [];
				const noOfLicencesSelected = this.subscribedEmployees
					? this.subscribedEmployees.quantity
					: companySubscription.currentState.quantity || 1;
				this.subscribedPlanName = selectedPlan.shortName;
				this.noOfLicencesSelectedFormControl.setValue(noOfLicencesSelected);
				const employeeData = companyMembers.map((member) =>
					this.companyMemberMapper(member, subscribedEmployees, selectedPlan.shortName),
				);
				return sortBy(employeeData, ['name']);
			}),
		);
	}

	scheduleSelectedEmployeesChange(): void {
		if (this.noOfLicencesSelectedFormControl.invalid) {
			return;
		}
		const subscribedLicences: SubscribedEmployeesLicences = {
			employees: this.getMappedSubscribedEmployees(),
			quantity: this.noOfLicencesSelected,
		};
		this.productChangeService.scheduleEmployeeChange(subscribedLicences);
	}

	onMatButtonToggleChange(): void {
		const currentNoOfLicenses = this.noOfLicencesSelectedFormControl.value;
		if (currentNoOfLicenses < this.employeeSubscriptionCount.paid) {
			this.setNumberOfLicensesToNecessaryMinimum();
		}
		this.noOfLicencesSelectedFormControl.updateValueAndValidity();
		this.scheduleSelectedEmployeesChange();
	}

	async confirmSelectedEmployees(): Promise<void> {
		if (this.employeeChanges?.quantity > this.employeeChanges?.subscription.currentState.quantity) {
			const response = await this.confirmService
				.open({
					title: this.translate.instant('settings.submit-licence.title'),
					message: this.translate.instant('settings.submit-licence.message'),
					primaryButtonText: this.translate.instant('settings.submit-licence.yes'),
					secondaryButtonText: this.translate.instant('settings.submit-licence.no'),
				})
				.afterClosed()
				.toPromise();

			if (!response) {
				return;
			}
		}

		this.destroyer$.next(null); // Destroying the subscription to not to change the UI after confirmation
		if (this.employeeChanges) {
			await this.productChangeService.confirmSubscriptionChange(this.employeeChanges);
		}
		const subscribedLicences: SubscribedEmployeesLicences = {
			employees: this.getMappedSubscribedEmployees(),
			quantity: this.noOfLicencesSelected,
		};
		this.confirmSelection.emit(subscribedLicences);
	}

	getMappedSubscribedEmployees(): SubscribedEmployee[] {
		return this.employeeSubscriptions.map((employee) => {
			return {
				profileId: employee.profileId,
				type: employee.type,
			};
		});
	}

	getCostText(): string {
		if (!this.employeeChanges) {
			return '';
		}
		const referenceDate =
			this.employeeChanges.changeDirection === SubscriptionChangeDirection.DOWNGRADE
				? this.employeeChanges.subscription.renewalDate
				: moment().unix();
		const vatRate = getVatRateForCountry(
			this.employeeChanges.billingDetails.billing.address.country,
			this.employeeChanges.to.plan,
			referenceDate,
		);
		const cost = getCostForSubscriptionChange(this.employeeChanges, vatRate);
		this.costText = this.subscriptionTranslateService.getCostChangeText(
			this.employeeChanges,
			cost,
			vatRate,
		);
	}

	getChangeDateText(): string {
		if (!this.employeeChanges) {
			return '';
		}
		this.dateChangeText = this.subscriptionTranslateService.getDateChangeText(this.employeeChanges);
	}

	ngOnInit(): void {
		this.getEmployeeSubscriptions().subscribe((subscribedEmployees) => {
			this.employeeSubscriptions = subscribedEmployees;
			this.noOfLicencesSelectedFormControl.updateValueAndValidity();
			this.scheduleSelectedEmployeesChange();
			this.loading = false;
		});
	}

	ngOnDestroy(): void {
		this.destroyer$.next(null);
		this.destroyer$.complete();
	}

	noOfLicencesRequiredValidator(control: AbstractControl): { [key: string]: string } | null {
		let error = null;
		if (
			this.employeeSubscriptionCount.free &&
			control.value < this.minimumPaidUsersForBasicAvailability
		) {
			error = this.translate.instant('subscription.employees.errorText', {
				noOfUsers: this.minimumPaidUsersForBasicAvailability,
				selectedPlan: this.subscribedPlanName,
			});
		}
		if (this.employeeSubscriptionCount.paid > control.value) {
			error = this.translate.instant('subscription.licences.lessLicencesErrorText', {
				employeeCount: this.employeeSubscriptionCount.paid,
				selectedPlan: this.subscribedPlanName,
			});
		}
		return error
			? {
					licencesInvalid: error,
			  }
			: null;
	}

	decrementNoOfLicences(): void {
		const currentNoOfLicenses = this.noOfLicencesSelectedFormControl.value;
		if (currentNoOfLicenses <= 1) {
			return;
		}
		this.noOfLicencesSelectedFormControl.setValue(currentNoOfLicenses - 1);
		this.scheduleSelectedEmployeesChange();
	}

	incrementNoOfLicences(): void {
		const currentNoOfLicenses = this.noOfLicencesSelectedFormControl.value;
		this.noOfLicencesSelectedFormControl.setValue(currentNoOfLicenses + 1);
		this.scheduleSelectedEmployeesChange();
	}

	setNumberOfLicensesToNecessaryMinimum(): void {
		const minimumNecessaryNumberOfLicenses = this.employeeSubscriptionCount.paid;
		this.noOfLicencesSelectedFormControl.setValue(minimumNecessaryNumberOfLicenses);
	}

	private filterCompanyMember(member: Member): boolean {
		return member.id && member.inviteState === InviteState.ACCEPTED;
	}

	private flatProductsWithPlan(products: Product[]): string[] {
		return flattenDeep(
			products.map((product) => product.plans.map((plan) => plan.stripeId)),
		) as string[];
	}

	private getSelectedPlan(products: Product[], planId: string): Product {
		return products.find((product) => !!product.plans.find((plan) => plan.stripeId === planId));
	}

	private getCompanySubscription(products: Product[], subscriptions: Subscription[]): Subscription {
		const flatPlans = this.flatProductsWithPlan(products);
		return subscriptions.find((sub) => {
			return sub.currentState.planId === flatPlans.find((plan) => plan === sub.currentState.planId);
		});
	}

	private companyMemberMapper(
		member: Member,
		subscribedEmployees: SubscribedEmployee[],
		planName: string,
	): SubscribedEmployeeUI {
		const employeeSubscription = subscribedEmployees.find(
			(employee) => employee.profileId === member.id,
		);
		return {
			profileId: member.id,
			name: `${member.lastname}, ${member.name}`,
			type: (employeeSubscription && employeeSubscription.type) || SubscriptionEmployeeType.FREE,
			role: member.role,
			planName,
		};
	}

	private getCompanyMembers(): Observable<Member[]> {
		return this.authService.$firebaseUser.pipe(
			mergeMap((authUser) => this.profileService.getProfile(authUser.uid)),
			mergeMap((userProfile) => this.companyService.getCompany(userProfile.company)),
			map((company) => company.members.filter(this.filterCompanyMember)),
		);
	}
}
