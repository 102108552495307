import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as fromProjectFiles from '../actions/project-files.actions';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ProjectFilesService } from '@injectables/services/project-files/project-files.service';
import { PerformanceTraceService } from '@injectables/services/performance-trace.service';
import { PerformanceTrace, PerformanceTraceConstants } from '@shared/constants/performace-trace';

@Injectable()
export class ProjectFilesEffects {
	initProjectFilesStateChanges$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProjectFiles.initProjectFilesStateChangesAction),
			filter((action) => !!action.projectId),
			tap(() => {
				this.performanceService.start(PerformanceTrace.COLLECTION_FILES_LOADED);
			}),
			tap((_) => this.store.dispatch(fromProjectFiles.clearProjectFilesAction())),
			switchMap((action) =>
				this.projectFilesService.watchProjectFileEntityChanges(action.projectId),
			),
			tap((entityChanges) =>
				this.performanceService.logTraceWhenEntitiesCreated(
					entityChanges,
					PerformanceTrace.COLLECTION_FILES_LOADED,
					{
						name: PerformanceTraceConstants.FILE_COUNT,
						count: entityChanges.entities.length,
					},
				),
			),
			map((projectFilesEntities) => {
				if (
					projectFilesEntities.changeType === 'created' ||
					projectFilesEntities.changeType === 'updated'
				) {
					return fromProjectFiles.addProjectFilesAction({ files: projectFilesEntities.entities });
				} else if (projectFilesEntities.changeType === 'deleted') {
					return fromProjectFiles.deleteProjectFilesAction({
						files: projectFilesEntities.entities.map((file) => file.id),
					});
				}
			}),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly projectFilesService: ProjectFilesService,
		private readonly performanceService: PerformanceTraceService,
	) {}
}
