<div class="col col-lg-5 banner">
	<div class="logo-container-wrapper">
		<div class="logo-container">
			<div class="logo"></div>
			<div class="claim d-flex">
				<mat-icon svgIcon="banner-support"></mat-icon>
				<div>
					<h3 class="fontfamily-archive">
						{{ 'banner.claim1' | translate }}
						<span class="chip text-white">{{ 'banner.noCharge' | translate }}</span>
					</h3>
					<p class="text-color">{{ 'banner.claim1_support' | translate }}</p>
				</div>
			</div>
			<div class="claim d-flex">
				<mat-icon svgIcon="banner-timer"></mat-icon>
				<div>
					<h3 class="fontfamily-archive">{{ 'banner.claim2' | translate }}</h3>
					<p class="text-color">{{ 'banner.claim2_support' | translate }}</p>
				</div>
			</div>
			<div class="claim d-flex">
				<mat-icon svgIcon="banner-security"></mat-icon>
				<div>
					<h3 class="fontfamily-archive">{{ 'banner.claim3' | translate }}</h3>
					<p class="text-color">{{ 'banner.claim3_support' | translate }}</p>
				</div>
			</div>
			<div class="claim d-flex">
				<mat-icon svgIcon="banner-devices"></mat-icon>
				<div>
					<h3 class="fontfamily-archive">{{ 'banner.claim4' | translate }}</h3>
					<p class="text-color">{{ 'banner.claim4_support' | translate }}</p>
				</div>
			</div>
			<div class="mt-4 pt-5 h5 text-white banner-info">
				{{ 'banner.info' | translate }}
			</div>
			<div class="mt-4">
				<a href="https://itunes.apple.com/de/app/mycrafty-handwerker-app/id1327810465?mt=8" target="_blank" rel="noopener">
					<img alt="App store" class="mr-4" src="assets/images/icons/download-app-store.svg" width="150" />
				</a>
				<a href="https://play.google.com/store/apps/details?id=de.mycrafty.mycrafty" target="_blank" rel="noopener">
					<img alt="Play store" class="mr-3" src="assets/images/icons/download-play-store.svg" width="150" />
				</a>
			</div>

			<div class="text-white mt-5">{{ 'banner.questionText' | translate }}</div>
			<div class="claim">
				<a class="text-primary" href="https://mycrafty.zendesk.com" target="_blank" rel="noopener">
					{{ 'banner.button' | translate }}
				</a>
			</div>
		</div>
	</div>
</div>
