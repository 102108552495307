<div *ngIf="filesOperation">
	<ng-container *ngIf="selectedFiles.length === 1; else multipleSelectedFiles">
		<div class="font-weight-normal text-muted">
			<ng-container *ngIf="filesOperation === 'copy-with-in-project'">
				{{ 'files-section.header.copy-single-file' | translate }}
			</ng-container>
			<ng-container *ngIf="filesOperation === 'move'">
				{{ 'files-section.header.move-single-file' | translate }}
			</ng-container>
		</div>
		<div class="mt-2 d-flex align-items-center file">
			<app-files-section-icon [file]="selectedFiles[0]" *ngIf="selectedFiles[0].fileType !== FileType.FOLDER"></app-files-section-icon>
			<mat-icon *ngIf="selectedFiles[0].fileType === FileType.FOLDER" class="folder-icon"> folder</mat-icon>
			<div class="flex-grow-1 overflow-hidden ml-2 file__info">
				<div class="text-truncate font-weight-bold">{{ selectedFiles[0]?.name }}</div>
				<div class="file__size">
					<span *ngIf="selectedFiles[0].createdTimestamp"> {{ selectedFiles[0].createdTimestamp | readableDate }} - </span>
					<span>
						<ng-container *ngIf="selectedFiles[0].fileType !== FileType.FOLDER">
							{{ selectedFiles[0].fileSize | bytes }}
						</ng-container>
						<ng-container *ngIf="selectedFiles[0].fileType === FileType.FOLDER">
							{{ selectedFiles[0].noOfFiles }} {{ 'files-section.files' | translate }}
						</ng-container>
					</span>
				</div>
			</div>
		</div>
	</ng-container>
	<div class="mt-4 mb-2 d-flex">
		<div class="font-weight-normal text-muted">{{ 'files-section.header.to' | translate }}</div>
		<div class="flex-grow-1 d-flex align-items-center justify-content-end">
			<a (click)="addNewFolder($event)" class="color-primary d-flex align-items-center font-weight-normal" href="">
				<mat-icon class="mr-2" svgIcon="files-new-folder"></mat-icon>
				<span>{{ 'files-section.header.new-folder' | translate }}</span>
			</a>
		</div>
	</div>
</div>

<ng-template #multipleSelectedFiles>
	<div>
		<ng-container *ngIf="filesOperation === 'copy-with-in-project'">
			{{ 'files-section.header.copy-multiple-files' | translate: { fileLength: selectedFiles?.length } }}
		</ng-container>
		<ng-container *ngIf="filesOperation === 'move'">
			{{ 'files-section.header.move-multiple-files' | translate: { fileLength: selectedFiles?.length } }}
		</ng-container>
	</div>
</ng-template>
