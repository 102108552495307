import { createAction, props } from '@ngrx/store';

export const setChatUploadPercentageAction = createAction(
	'[File Upload] set file upload percentage',
	props<{ id: string; percentage: number }>(),
);

export const setProjectUploadPercentageAction = createAction(
	'[File Upload] set project upload percentage',
	props<{
		id: string;
		percentage: number;
	}>(),
);

export const createProjectFileUpload = createAction(
	'[File Upload] create project file upload',
	props<{
		id: string;
		projectId: string;
		fileName: string;
		folderId?: string;
	}>(),
);
export const clearPercentageAction = createAction(
	'[File Upload] clear percentage',
	props<{ id: string }>(),
);

export const setUploadFailedAction = createAction(
	'[File Upload] set upload failed',
	props<{ id: string }>(),
);

export const cancelUploadAction = createAction(
	'[File Upload] cancel upload',
	props<{ id: string }>(),
);

export const resumeUploadAction = createAction(
	'[File Upload] resume upload',
	props<{ id: string }>(),
);

export const hideAllFailedUploadsFromProjectListAction = createAction(
	'[File Upload] hide all failed uploads from project list',
);

export const dismissFailedUploadsAction = createAction('[File Upload] dismiss failed uploads');
