import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsSelectionDialogComponent } from './projects-selection-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
	declarations: [ProjectsSelectionDialogComponent],
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatInputModule,
		MatIconModule,
		ScrollingModule,
	],
	exports: [ProjectsSelectionDialogComponent],
})
export class ProjectsSelectionDialogModule {}
