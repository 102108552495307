import { NgModule } from '@angular/core';
import { DayDifferencePipe } from './day-difference.pipe';

const PIPE = [DayDifferencePipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class DayDifferencePipeModule {}
