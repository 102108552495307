<section *ngIf="project.id" class="project-notes-dialog">
	<div class="d-flex align-items-center mb-3">
		<h1 class="m-0 text-capitalize">
			<ng-container>
				{{
					'global_project-or-folder_note'
						| translate
							: {
									projectType: project.projectType === projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
							  }
				}}
			</ng-container>
		</h1>
		<em class="material-icons ml-auto pointer" (click)="closeModal()" data-cy="project-save-close">close</em>
	</div>

	<form [formGroup]="addProjectNotesForm" (ngSubmit)="onSubmitForm(noteContent)">
		<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline">
			<mat-label>
				{{
					'global_project-or-folder_note'
						| translate
							: {
									projectType: project.projectType === projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
							  }
				}}
			</mat-label>
			<textarea
				trim="blur"
				matInput
				formControlName="noteContent"
				[readonly]="data.isEmployee"
				cdkTextareaAutosize
				cdkAutosizeMinRows="5"
				cdkAutosizeMaxRows="20"
				data-cy="project-note-content"
			></textarea>
			<mat-error *ngIf="addProjectNotesForm.controls.noteContent?.errors?.required">
				{{ 'error.required' | translate }}
			</mat-error>
		</mat-form-field>

		<p *ngIf="project.noteTimestamp && project.noteAuthor" class="d-flex align-items-center mb-4">
			<mat-icon svgIcon="project-note-last-updated" class="mr-2 note-updated-icon"></mat-icon>
			<span data-cy="project-note-log"
				>{{ 'project-notes.last-updated' | translate }}:
				<strong>{{ project.noteTimestamp | unixToMillis | date: 'short' }} - {{ project.noteAuthor }}</strong></span
			>
		</p>

		<div class="text-center" [class.d-none]="data.isEmployee">
			<button
				color="accent"
				class="px-5"
				mat-flat-button
				[disabled]="addProjectNotesForm.invalid"
				type="submit"
				data-cy="project-note-save"
			>
				{{ 'information.save' | translate }}
			</button>
		</div>
	</form>
</section>
