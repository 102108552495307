import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Output,
	ViewChild,
} from '@angular/core';
import { ProjectMemberDrawerSecondaryViewOptions } from '../project-member-drawer/project-member-drawer.component';
import { selectCompanyMembers } from '@store/selectors/app.selectors';
import { combineLatest, Observable } from 'rxjs';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { first, map, pluck, switchMap } from 'rxjs/operators';
import { selectProject } from '@store/selectors/projects.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	AbstractControl,
	AsyncValidatorFn,
	UntypedFormBuilder,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { EMAIL_PATTERN } from '@constants/validators.constants';
import { ProjectMemberService } from '@injectables/services/project-member/project-member.service';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { MemberRole, ProjectType } from 'domain-entities';
import { shareReplayOne } from '@craftnote/shared-utils';

@Component({
	selector: 'app-project-external-member',
	templateUrl: './project-external-member.component.html',
	styleUrls: ['./project-external-member.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectExternalMemberComponent implements AfterViewInit {
	@Output() openView = new EventEmitter<ProjectMemberDrawerSecondaryViewOptions>();
	@ViewChild('ExternalMemberInput') matInput: ElementRef;
	currentProject$ = this.store.select(selectActiveProject).pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
		shareReplayOne(),
	);
	currentProjectMembers$ = this.currentProject$.pipe(
		map((project) => project?.members || {}),
		shareReplayOne(),
	);
	projectName$ = this.currentProject$.pipe(pluck('name'));
	companyMembers$ = this.store.select(selectCompanyMembers);
	companyAndProjectMembersEmails$ = combineLatest([
		this.currentProjectMembers$,
		this.companyMembers$,
	]).pipe(
		map(([projectMembers = {}, companyMembers = {}]) => {
			return [Object.keys(projectMembers), Object.keys(companyMembers)];
		}),
		shareReplayOne(),
	);

	externalMemberForm = this.fb.group({
		email: [
			'',
			[Validators.required, Validators.pattern(EMAIL_PATTERN)],
			this.memberEmailValidation(),
		],
	});

	isLoading = false;

	constructor(
		private readonly store: Store<AppState>,
		private readonly fb: UntypedFormBuilder,
		private readonly projectMemberService: ProjectMemberService,
		private readonly notificationService: NotificationSnackbarService,
		private readonly translate: TranslateService,
	) {}

	ngAfterViewInit(): void {
		this.focusEmployeeInviteInput(350);
	}

	async addExternalMember(): Promise<void> {
		const project = await this.currentProject$.pipe(first()).toPromise();
		const projectOrFolderKey =
			project?.projectType === ProjectType.FOLDER ? 'global_folder' : 'global_project';
		await this.projectMemberService.addMember(
			{ email: this.memberEmail, role: MemberRole.EXTERNAL },
			project,
			() => {
				this.isLoading = true;
			},
		);
		this.notificationService.show(BasicSnackbarComponent, {
			componentTypes: {
				description: this.translate.instant('projectOrFolderMembers.external.invite.success', {
					projectType: this.translate.instant(projectOrFolderKey),
				}),
				icon: 'done',
			},
			level: 1,
		});
		this.externalMemberForm.reset();
		this.isLoading = false;
		this.focusEmployeeInviteInput();
	}

	get memberEmail(): string {
		return this.externalMemberForm?.value?.email;
	}

	resetMemberForm(): void {
		this.externalMemberForm.patchValue({ email: '' });
	}

	openProjectListView(): void {
		this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.INITIAL);
	}

	memberEmailValidation(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return this.companyAndProjectMembersEmails$.pipe(
				first(),
				map(([projectMembersEmails, companyMemberEmails]) => {
					const email = control.value?.toLowerCase();

					if (projectMembersEmails.includes(email)) {
						return { projectMemberExists: true };
					}

					if (companyMemberEmails.includes(email)) {
						return { companyMemberExists: true };
					}

					return null;
				}),
			);
		};
	}

	private focusEmployeeInviteInput(duration: number = 0): void {
		setTimeout(() => {
			this.matInput.nativeElement.focus();
		}, duration);
	}
}
