import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: 'mat-icon[size], mat-icon[height][width], img',
})
export class MatIconSizeDirective implements AfterViewInit {
	// TODO:: We have to add type like 16|24|32, so that we can have specific size icons
	@Input() size = null;
	@Input() width = null;
	@Input() height = null;
	private readonly ele: HTMLDivElement;

	constructor(readonly eleRef: ElementRef) {
		this.ele = eleRef.nativeElement;
	}

	ngAfterViewInit(): void {
		if (this.size) {
			this.ele.style.height = `${this.size}px`;
			this.ele.style.width = `${this.size}px`;
			this.ele.style.fontSize = `${this.size}px`;
			this.ele.style.lineHeight = `${this.size}px`;
			return;
		}
		this.ele.style.height = `${this.height}px`;
		this.ele.style.width = `${this.width}px`;
	}
}
