import { Action, createReducer, on } from '@ngrx/store';
import { setUserRegistrationType, updateCurrentProfileAction } from '../actions/auth.actions';
import { AuthLifecycle, AuthState, UserRegistrationType } from '../state/auth.state';
import { StoreLoadingStatus } from '../entities/store-loading-status';

const initialState: AuthState = {
	user: undefined,
	userId: undefined,
	lifeCycle: AuthLifecycle.PENDING,
	registrationType: UserRegistrationType.UNAUTHENTICATED,
	loadingStatus: StoreLoadingStatus.INITIAL,
};

const authReducerCreator = createReducer(
	initialState,
	on(updateCurrentProfileAction, (state, { user, userId, lifeCycle }) => ({
		...state,
		user,
		userId,
		lifeCycle,
		loadingStatus: user && userId ? StoreLoadingStatus.LOADED : StoreLoadingStatus.INITIAL,
	})),
	on(setUserRegistrationType, (state, { registrationType }) => {
		return {
			...state,
			registrationType,
		};
	}),
);

// tslint:disable-next-line:typedef
export function authReducer(state: any | undefined, action: Action) {
	return authReducerCreator(state, action);
}
