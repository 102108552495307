import {
	CountryCode,
	Plan,
	PlanPaymentInterval,
	Product,
	Subscription,
	SubscriptionEmployeeType,
} from 'domain-entities';
import { SubscriptionChangeDirection } from '../model/SubscriptionChangeDirection';
import { SubscribedEmployeesLicences } from '../manage-company-employess/model/manage-company-employee.model';
import { SubscriptionChangeWithExistingSubscription } from '../model/SubscriptionChange';
import { CouponResponse } from '@injectables/services/subscription/subscription.service';
import { DEFAULT_CURRENCY, GERMAN_VAT_RATES } from '@shared/constants/subscription';
import { SubscriptionChangeType } from '../model/SubscriptionChangeType';
import { EmployeeChange } from '../manage-company-employess/model/EmployeeChange';
import moment from 'moment';
import { groupBy, maxBy, values } from 'lodash';

export const getProductForPlan: (products: Product[], planId: string) => Product = (
	products,
	planId,
): Product => {
	let product = products.find((prod) => prod.plans.some((plan) => plan.stripeId === planId));
	if (!product) {
		product = getLowestGradeProduct(products);
	}
	return product;
};

export const getProductForCurrentPlan: (
	products: Product[],
	subscription: Subscription,
) => Product = (products, subscription): Product => {
	return getProductForPlan(products, subscription.currentState.planId);
};

export const getProductForNextPlan: (
	products: Product[],
	subscription: Subscription,
) => Product | undefined = (products, subscription): Product => {
	if (!subscription.nextState) {
		return undefined;
	}
	return getProductForPlan(products, subscription.nextState.planId);
};

export const getProductByGrade: (products: Product[], grade: number) => Product = (
	products,
	grade,
): Product => {
	return products.find((prod) => prod.grade === grade);
};

export const getLowestGradeProduct: (products: Product[]) => Product = (prods): Product => {
	return [...prods].sort((a, b) => a.grade - b.grade)[0];
};

export const getPlanById: (products: Product[], planId: string) => Plan = (
	products,
	planId,
): Plan => {
	const allPlans = [];
	products.filter((prod) => prod.plans).forEach((prod) => allPlans.push(...prod.plans));
	return allPlans.find((plan) => plan.stripeId === planId);
};

export const getCurrentPlan: (products: Product[], subscription: Subscription) => Plan = (
	products,
	subscription,
): Plan => {
	return getPlanById(products, subscription.currentState.planId);
};

export const getNextPlan: (products: Product[], subscription: Subscription) => Plan = (
	products,
	subscription,
): Plan | undefined => {
	if (!subscription.nextState) {
		return undefined;
	}
	return getPlanById(products, subscription.nextState.planId);
};

export const getPlanByPaymentInterval: (
	product: Product,
	planPaymentInterval: PlanPaymentInterval,
) => Plan = (product, planPaymentInterval): Plan => {
	return getLatestPlansForProduct(product).find(
		(plan) => plan.paymentInterval === planPaymentInterval,
	);
};

export const getIntervalEquivalentPlan: (
	allProducts: Product[],
	newProductGrade: number,
	basePlan: Plan,
) => Plan | undefined = (
	allProducts: Product[],
	newProductGrade,
	basePlan: Plan,
): Plan | undefined => {
	if (!newProductGrade) {
		return undefined;
	}
	const newProduct = getProductByGrade(allProducts, newProductGrade);
	const relevantNewPlans = getLatestPlansForProduct(newProduct);
	return relevantNewPlans.find(
		(planInner) => planInner.paymentInterval === basePlan.paymentInterval,
	);
};

export const getProductEquivalentPlan: (
	allProducts: Product[],
	oldPlan: Plan,
	newInterval: PlanPaymentInterval,
) => Plan | undefined = (
	allProducts: Product[],
	oldPlan: Plan,
	newInterval: PlanPaymentInterval,
): Plan | undefined => {
	if (!oldPlan) {
		return undefined;
	}
	const product = getProductForPlan(allProducts, oldPlan.stripeId);
	const relevantNewPlans = getLatestPlansForProduct(product);

	return relevantNewPlans.find((planInner) => planInner.paymentInterval === newInterval);
};

export const getProductBySubscription: (
	products: Product[],
	subscription: Subscription,
) => Product = (products, subscription): Product => {
	if (!subscription) {
		return getLowestGradeProduct(products);
	}
	return getProductForCurrentPlan(products, subscription);
};

export const getChangeDirection: (
	oldGrade: number,
	newGrade: number,
) => SubscriptionChangeDirection = (oldGrade, newGrade): SubscriptionChangeDirection => {
	const diff = newGrade - oldGrade;

	if (diff < 0) {
		return SubscriptionChangeDirection.DOWNGRADE;
	} else {
		return SubscriptionChangeDirection.UPGRADE;
	}
};

export const getChangeDirectionWithUnchanged: (
	oldGrade: number,
	newGrade: number,
) => SubscriptionChangeDirection = (oldGrade, newGrade): SubscriptionChangeDirection => {
	const diff = newGrade - oldGrade;

	if (diff < 0) {
		return SubscriptionChangeDirection.DOWNGRADE;
	} else if (diff > 0) {
		return SubscriptionChangeDirection.UPGRADE;
	} else {
		return SubscriptionChangeDirection.UNCHANGED;
	}
};

export const getChangeDirectionBySubscriptionLicensesWithUnchanged = (
	oldSubLicence: SubscribedEmployeesLicences,
	newSubLicence: SubscribedEmployeesLicences,
): SubscriptionChangeDirection => {
	if (oldSubLicence.quantity < newSubLicence.quantity) {
		return SubscriptionChangeDirection.UPGRADE;
	} else if (oldSubLicence.quantity > newSubLicence.quantity) {
		return SubscriptionChangeDirection.DOWNGRADE;
	}

	const oldEmployeesSorted = (oldSubLicence.employees || [])
		.filter((employee) => employee.type === SubscriptionEmployeeType.PAID)
		.sort((a, b) => a.profileId.localeCompare(b.profileId));
	const newEmployeesSorted = (newSubLicence.employees || [])
		.filter((employee) => employee.type === SubscriptionEmployeeType.PAID)
		.sort((a, b) => a.profileId.localeCompare(b.profileId));

	if (oldEmployeesSorted.length !== newEmployeesSorted.length) {
		return SubscriptionChangeDirection.UPGRADE;
	}

	for (let i = 0; i < newEmployeesSorted.length; i++) {
		if (newEmployeesSorted[i].profileId !== oldEmployeesSorted[i].profileId) {
			return SubscriptionChangeDirection.UPGRADE;
		}
	}
	return SubscriptionChangeDirection.UNCHANGED;
};

export const getChangeDirectionBySubscriptionLicenses = (
	oldSubLicence: SubscribedEmployeesLicences,
	newSubLicence: SubscribedEmployeesLicences,
): SubscriptionChangeDirection => {
	if (oldSubLicence.quantity > newSubLicence.quantity) {
		return SubscriptionChangeDirection.DOWNGRADE;
	} else {
		return SubscriptionChangeDirection.UPGRADE;
	}
};

export const getCostForSubscriptionChange: (
	subscriptionChange: SubscriptionChangeWithExistingSubscription,
	vatRate?: number,
) => number = (
	subscriptionChange: SubscriptionChangeWithExistingSubscription,
	vatRate?: number,
): number => {
	const relevantNextPlan = subscriptionChange.to.plan;
	const relevantCoupon = subscriptionChange.coupon;

	let numberOfPaidUsers = subscriptionChange.subscription.currentState.quantity;
	if (subscriptionChange.changeDirection === SubscriptionChangeDirection.DOWNGRADE) {
		const nextState = subscriptionChange.subscription.nextState;
		numberOfPaidUsers = (nextState && nextState.quantity) || 0;
	}

	if (subscriptionChange.changeType === SubscriptionChangeType.EMPLOYEES_CHANGE) {
		numberOfPaidUsers = (subscriptionChange as EmployeeChange).quantity;
	}

	return getCost(relevantNextPlan, numberOfPaidUsers, vatRate, relevantCoupon);
};

export const getCouponSavings: (plan: Plan, numberOfUsers, coupon: CouponResponse) => number = (
	plan: Plan,
	numberOfUsers,
	coupon: CouponResponse,
): number => {
	const totalCostWithoutCoupon = getCost(plan, numberOfUsers, null);
	const totalCostWithCoupon = getCost(plan, numberOfUsers, null, {
		response: coupon,
		ignoreValid: false,
	});
	return totalCostWithoutCoupon - totalCostWithCoupon;
};

export const getCost: (
	plan: Plan | undefined,
	numberOfUsers: number,
	vatRate?: number,
	coupon?: { response: CouponResponse; ignoreValid: boolean },
) => number = (
	plan: Plan,
	numberOfUsers: number,
	vatRate?: number,
	coupon?: { response: CouponResponse; ignoreValid: boolean },
): number => {
	if (!plan) {
		return 0;
	}
	let percentageOff = 0;
	let amountOff = 0;

	if (coupon && (coupon.ignoreValid === true || coupon.response.valid)) {
		percentageOff = coupon.response.percentOff || 0;
		if (coupon.response.amountOff) {
			amountOff = coupon.response.amountOff.value;
		}
	}

	// The applied order of amount off and percentage off is irrelevant since only one can be
	// set per coupon
	const costWithoutRefund = getCostOfUsers(plan, numberOfUsers);
	const costWithAmountRefund = costWithoutRefund - amountOff;
	const costWithAllRefunds = costWithAmountRefund * (1 - percentageOff / 100);

	const totalCost = vatRate ? costWithAllRefunds * (1 + vatRate / 100) : costWithAllRefunds;
	return totalCost > 0 ? totalCost : 0;
};

const getCostOfUsers: (plan: Plan, numberOfUsers: number) => number = (
	plan: Plan,
	numberOfUsers: number,
): number => {
	return numberOfUsers * plan.costPerUser[DEFAULT_CURRENCY];
};

export const getOverallCostPerUser: (cost: number, numberOfUsers: number) => number = (
	cost: number,
	numberOfUsers: number,
): number => {
	return cost / numberOfUsers;
};

export const getVatRateForCountry: (
	countryCode: string,
	plan: Plan,
	referenceStartDate: number,
) => number | undefined = (
	country: string,
	plan: Plan,
	referenceStartDate: number,
): number | undefined => {
	if (!plan || country !== CountryCode.DE) {
		return undefined;
	}

	const paymentInterval = plan.paymentInterval;
	const durationUnit = paymentInterval === PlanPaymentInterval.MONTHLY ? 'months' : 'years';
	const endDate = moment.unix(referenceStartDate).add(1, durationUnit);
	const applicableRate = GERMAN_VAT_RATES.find((rate) => {
		if (rate.start && rate.start.isAfter(endDate)) {
			return false;
		}
		if (rate.end && rate.end.isBefore(endDate)) {
			return false;
		}
		return true;
	});
	return applicableRate.value;
};

function getLatestPlansForProduct(product: Product): Plan[] {
	const now = moment().unix();
	const grouped = groupBy(product.plans, 'grade');
	const vals = values(grouped).map((gradeGroup) =>
		maxBy(
			gradeGroup.filter((plan) => (plan.validFrom ?? 0) < now),
			(plan) => plan.validFrom ?? 0,
		),
	);
	return vals;
}
