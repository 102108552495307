<section class="project-add-edit-contact-form-section p-3">
	<div class="d-flex align-items-center mb-3">
		<div class="d-flex align-items-center">
			<button class="mr-3" mat-icon-button (click)="closeForm()">
				<mat-icon class="close-btn-icon">clear</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				<ng-container *ngIf="isAdding">{{ 'project-add-edit-contact-form.heading.add' | translate }}</ng-container>
				<ng-container *ngIf="isEditing">{{ 'project-add-edit-contact-form.heading.edit' | translate }}</ng-container>
			</div>
		</div>
	</div>

	<form [formGroup]="projectContactForm" (ngSubmit)="updateContact(projectContactForm.value)">
		<mat-form-field class="w-100">
			<input formControlName="name" appAutofocus type="text" matInput placeholder="Name" data-cy="contact-detail-name" />
			<mat-error *ngIf="projectContactForm.controls.name.errors?.required">
				{{ 'error.required' | translate }}
			</mat-error>
		</mat-form-field>

		<div formArrayName="emails">
			<div *ngFor="let emailControl of projectContactEmailsControls.controls; let i = index; let last = last">
				<mat-form-field class="w-100">
					<input
						[formControlName]="i"
						appAutofocus
						trim="blur"
						type="text"
						matInput
						[placeholder]="'project-add-edit-contact-form.input-email' | translate"
						data-cy="contact-detail-email"
					/>
					<div matSuffix class="d-flex">
						<button mat-icon-button (click)="addEmailControl()" *ngIf="last" data-cy="contact-add-email-circle" appEventStopPropagation>
							<mat-icon [size]="24">add_circle</mat-icon>
						</button>
						<button
							mat-icon-button
							(click)="removeEmailControl(i)"
							*ngIf="!last"
							data-cy="contact-remove-email-circle"
							appEventStopPropagation
						>
							<mat-icon [size]="24">remove_circle</mat-icon>
						</button>
					</div>
					<mat-error>
						{{ 'project-add-edit-contact-form.input-email-invalid' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div formArrayName="phones">
			<div *ngFor="let phoneControl of projectContactPhonesControls.controls; let i = index; let last = last">
				<mat-form-field class="w-100">
					<input
						[formControlName]="i"
						appAutofocus
						trim="blur"
						type="text"
						matInput
						[placeholder]="'project-add-edit-contact-form.input-phone' | translate"
						data-cy="contact-detail-phone"
					/>
					<div matSuffix class="d-flex">
						<button mat-icon-button (click)="addPhoneControl()" *ngIf="last" data-cy="contact-add-phone-circle" appEventStopPropagation>
							<mat-icon [size]="24">add_circle</mat-icon>
						</button>
						<button
							mat-icon-button
							(click)="removePhoneControl(i)"
							*ngIf="!last"
							data-cy="contact-remove-phone-circle"
							appEventStopPropagation
						>
							<mat-icon [size]="24">remove_circle</mat-icon>
						</button>
					</div>
					<mat-error>
						{{ 'project-add-edit-contact-form.input-phone-invalid' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<button [disabled]="projectContactForm.invalid" class="w-100" color="accent" data-cy="contact-detail-save" mat-flat-button>
			{{ (isAdding ? 'project-add-edit-contact-form.cta.add' : 'project-add-edit-contact-form.cta.edit') | translate }}
		</button>
	</form>
</section>
