import { Component, EventEmitter, Output } from '@angular/core';
import { Export } from 'domain-entities';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { isFailed } from '@shared/firebase/export/export.functions';

@Component({
	selector: 'app-export-dialog',
	templateUrl: './export-dialog.component.html',
	styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
	@Output() cancelExport = new EventEmitter<string>();

	isFailed = isFailed;
	data: { exports: Export[] };

	get exports(): Export[] {
		return this.data?.exports || [];
	}

	constructor(private readonly ref: MatDialogRef<ExportDialogComponent>) {}

	onClose(): void {
		this.ref.close();
	}
}
