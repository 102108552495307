import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-dummy-menu',
	templateUrl: './dummy-menu.component.html',
	styleUrls: ['./dummy-menu.component.scss'],
})
export class DummyMenuComponent {
	projectId: string;

	constructor(readonly activatedRoute: ActivatedRoute) {
		this.projectId = activatedRoute.snapshot.params['projectId'];
	}
}
