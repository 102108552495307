<section class="notification-token-permission-default-section d-flex flex-column align-items-center">
	<div class="mat-display-3 mb-4">
		{{ 'notification-token-permission.default.heading' | translate }}
	</div>

	<div class="mat-headline mb-4 font-weight-normal">
		{{ 'notification-token-permission.default.subheading' | translate }}
	</div>

	<ul class="list-unstyled mb-4">
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.default.point1' | translate }}
			</div>
		</li>
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.default.point2' | translate }}
			</div>
		</li>
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.default.point3' | translate }}
			</div>
		</li>
	</ul>

	<button mat-flat-button color="accent" (click)="openNativeNotificationPermissionPopup()">
		{{ 'notification-token-permission.default.cta' | translate }}
	</button>
</section>
