import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({
	standalone: true,
	name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
	transform(value: number): string {
		return formatDistanceToNow(value, { addSuffix: true });
	}
}
