import { Pipe, PipeTransform } from '@angular/core';
import { BaseFileService } from '@injectables/services/base-file.service';

@Pipe({
	name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
	constructor(private readonly baseFileService: BaseFileService) {}

	transform(fileName: string, transform: boolean = true): string {
		return this.baseFileService.getFileExtension(fileName, transform);
	}
}
