import { NgModule } from '@angular/core';
import { IsNilPipe } from './is-nil.pipe';

const PIPE = [IsNilPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class IsNilPipeModule {}
