import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllFileSectionUploadOfProject } from '@store/selectors/file-upload.selectors';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { cancelUploadAction, dismissFailedUploadsAction } from '@store/actions/file-upload.actions';
import { asyncScheduler, BehaviorSubject, combineLatest, throttleTime } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { selectFilesInFilesSection } from '@store/selectors/project-files.selectors';
import { ProjectFile } from '@shared/models/project-file.model';
import { last, orderBy } from 'lodash';

@Component({
	selector: 'app-files-section-upload-list',
	templateUrl: './files-section-upload-list.component.html',
	styleUrls: ['./files-section-upload-list.component.scss'],
})
export class FilesSectionUploadListComponent implements OnChanges {
	@Input() currentFolder: string;
	@Input() viewOption: 'grid' | 'list' = 'grid';
	@Output() openFilePicker = new EventEmitter<void>();

	private store = inject(Store);

	private currentFolder$$ = new BehaviorSubject<string>(null);

	private filesInFileSection$ = this.store
		.select(selectFilesInFilesSection)
		.pipe(map((files) => new Map(files.map((file) => [file.id, file]))));

	private readonly fileUploads$ = this.store.select(selectActiveProject).pipe(
		filter(Boolean),
		switchMap((projectId) => this.store.select(selectAllFileSectionUploadOfProject(projectId))),
		throttleTime(500, asyncScheduler, { leading: true, trailing: true }),
	);

	private readonly failedFileUploads$ = this.fileUploads$.pipe(
		map((fileUploads) => fileUploads.filter((fileUpload) => fileUpload.status === 'error')),
	);

	readonly fileUploadElements$ = combineLatest([
		this.fileUploads$,
		this.currentFolder$$,
		this.filesInFileSection$,
	]).pipe(
		map(([uploads, currentFolder, files]) => {
			const visibileElements = uploads.filter((fileUpload) =>
				this.isFileInCurrentFolder(fileUpload.folderId, files, currentFolder),
			);
			return orderBy(visibileElements, (element) => (element.status === 'error' ? 0 : 1));
		}),
	);

	readonly hasFailedUploadElements$ = this.failedFileUploads$.pipe(
		map((fileUploads) => fileUploads.length > 0),
	);

	pathOfAllErroredFiles$ = combineLatest([this.failedFileUploads$, this.filesInFileSection$]).pipe(
		map(([fileUploads, files]) => {
			return fileUploads.reduce((acc, fileUpload) => {
				const filePath = this.buildFilePath(fileUpload.folderId, files);
				return acc.set(fileUpload.id, {
					folderName: last(filePath)?.name,
					fullPath: this.buildFilePath(fileUpload.folderId, files)
						.map((file) => file.name)
						.join(' / '),
				});
			}, new Map<string, { folderName: string; fullPath: string }>());
		}),
	);
	cancelUpload(id: string): void {
		this.store.dispatch(cancelUploadAction({ id }));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentFolder) {
			this.currentFolder$$.next(changes.currentFolder.currentValue);
		}
	}

	onDismiss(): void {
		this.store.dispatch(dismissFailedUploadsAction());
	}

	onRetry(): void {
		this.onDismiss();
		this.openFilePicker.emit();
	}

	private isFileInCurrentFolder(
		parentId: string,
		filesById: Map<string, ProjectFile>,
		currentFolder: string,
	): boolean {
		const filePath = this.buildFilePath(parentId, filesById);
		return !!(!currentFolder || filePath.find((file) => file.id === currentFolder));
	}

	private buildFilePath(
		parentId: string,
		filesById: Map<string, ProjectFile>,
		visitedNodes = new Set<string>(),
	): ProjectFile[] {
		// Prevent infinite loops
		if (visitedNodes.has(parentId)) {
			return [];
		}
		const parentFolder = filesById.get(parentId);
		if (parentFolder) {
			visitedNodes.add(parentId);
			return [...this.buildFilePath(parentFolder.parentId, filesById, visitedNodes), parentFolder];
		}
		return [];
	}
}
