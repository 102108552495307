<section class="task-edit-working-hours-section d-flex flex-column mh-100">
	<div class="p-3">
		<div class="d-flex align-items-center mb-3">
			<button (click)="openTaskEditDetails()" class="mr-3" mat-icon-button>
				<mat-icon class="back-btn-icon">arrow_back</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				{{ 'sidebar.time-tracking' | translate }}
			</div>
		</div>

		<form (submit)="createNewTaskTime()" [formGroup]="addWorkingHoursForm">
			<div class="d-flex flex-wrap">
				<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" floatLabel="always" color="accent">
					<mat-label>{{ 'dashboard.timeTracking.filters.startDate' | translate }}</mat-label>
					<input [matDatepicker]="startDatePickerRef" formControlName="startDate" matInput readonly (click)="startDatePickerRef.open()" />
					<mat-datepicker-toggle [for]="startDatePickerRef" matSuffix></mat-datepicker-toggle>
					<mat-datepicker color="accent" #startDatePickerRef></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
					<mat-label>{{ 'task-edit-working-hours.start-time' | translate }}</mat-label>
					<app-time-picker [max]="endDateTime" formControlName="startTime"></app-time-picker>
				</mat-form-field>
			</div>

			<div class="d-flex flex-wrap">
				<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" floatLabel="always" color="accent">
					<mat-label>{{ 'dashboard.timeTracking.filters.endDate' | translate }}</mat-label>
					<input [matDatepicker]="endDatePickerRef" formControlName="endDate" matInput readonly (click)="endDatePickerRef.open()" />
					<mat-datepicker-toggle [for]="endDatePickerRef" matSuffix></mat-datepicker-toggle>
					<mat-datepicker color="accent" #endDatePickerRef></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
					<mat-label>{{ 'task-edit-working-hours.end-time' | translate }}</mat-label>
					<app-time-picker formControlName="endTime"></app-time-picker>
				</mat-form-field>
			</div>

			<button [disabled]="addWorkingHoursFormInvalid" class="w-100" color="accent" mat-flat-button type="submit">
				{{ 'task.add_entry' | translate }}
			</button>
		</form>
	</div>

	<ul class="working-hours-list list-unstyled m-0 flex-grow-1 overflow-auto">
		<li *ngFor="let workingHour of workingHours$ | async">
			<div class="working-hours-date font-weight-bold px-3 py-1">
				{{ workingHour.startTime | unixToMillis | date: 'EEEE, d.MM.y' }}
			</div>
			<div class="d-flex p-3 pointer" (click)="openWorkingHoursUpdateEmitter.emit(workingHour)">
				<div>
					{{ workingHour.startTime | unixToMillis | date: 'H:mm' }}
					- {{ workingHour.endTime | unixToMillis | date: 'H:mm' }}
					{{ workingHour.startTime | dayDifference: workingHour.endTime }}
				</div>
				<div class="ml-auto">{{ formatSeconds(workingHour.endTime - workingHour.startTime) }}</div>
			</div>
		</li>
	</ul>

	<div *ngIf="workingHoursTotalTime" class="px-3 py-1 text-white working-hours-total d-flex">
		<div class="font-weight-bold">{{ 'task.total' | translate }}</div>
		<div class="ml-auto">{{ workingHoursTotalTime }}</div>
	</div>
</section>
