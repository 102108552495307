import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { COMPANY_FILE_TYPE, CompanyTemplate } from '@shared/models/company-template.model';
import { FileType, Message, MessageType, TaskFile } from 'domain-entities';
import { CompanyTemplatesService } from './company-templates.service';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { FILE_TYPE, FileDocument } from '@craftnote/shared-utils';
import { PublicFile } from '@injectables/services/public-folders/public-folders.service';
import { Store } from '@ngrx/store';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { TaskFileService } from '@injectables/services/task-file/task-file.service';
import { ChatService } from './chat/chat.service';
import { firstValueFrom } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { PopupDialogFile } from '@modules/shared/dialog/components/popup-dialog/popup-dialog.component';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

type PopupDialogNonMessageFile = CompanyTemplate | FileDocument | PublicFile | TaskFile;
type PopupDialogInputFile = PopupDialogNonMessageFile | Message;

interface PopupDialogServiceDocumentDetails {
	accessor: () => Promise<string>;
	urlMap: { [keys: string]: string };
}

@Injectable({ providedIn: 'root' })
export class PopupDialogService {
	constructor(
		private readonly matDialog: MatDialog,
		private readonly fileService: FileExplorerService,
		private readonly companyTemplatesService: CompanyTemplatesService,
		private readonly taskFileService: TaskFileService,
		private readonly chatService: ChatService,
		private readonly store: Store,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly translateService: TranslateService,
		private readonly errorHandler: ErrorHandlerService,
	) {}

	async openPopupDialog(
		files: PopupDialogInputFile[],
		position: number,
		isPdfEditable: boolean,
	): Promise<void> {
		const projectId = await firstValueFrom(this.store.select(selectActiveProject));

		const popupDialogFiles = this.buildPopupDialogFiles(files);
		const popupDialogFilesWithProjectId = popupDialogFiles.map((file) => {
			return { ...file, urlMap: { ...file.urlMap, projectId } };
		});

		const chunk = await import(
			'@modules/shared/dialog/components/popup-dialog/popup-dialog.component'
		);
		const dialogComponent = Object.values(chunk)[1] as ComponentType<unknown>;

		if (!popupDialogFilesWithProjectId || position < 0) {
			this.notificationSnackbarService.show(BasicSnackbarComponent, {
				componentTypes: {
					description: this.translateService.instant('error.generic'),
					icon: 'error',
					type: 'warn',
				},
				level: 1,
				timeout: 3000,
			});
			this.errorHandler.handleError(
				new Error(
					`No files to display or invalid position provided. number of files: ${popupDialogFilesWithProjectId}, position: ${position}`,
				),
			);
		}

		this.matDialog.open(dialogComponent, {
			height: '100%',
			width: '100%',
			maxWidth: '100%',
			data: {
				files: popupDialogFilesWithProjectId,
				position: position,
				isPdfEditable: isPdfEditable,
			},
		});
	}

	private buildPopupDialogFiles(files: PopupDialogInputFile[]): PopupDialogFile[] {
		return files.map((currentFile) => {
			const editorUrl = `pdf-viewer`;
			const { accessor, urlMap } = this.getFileAccessor(currentFile);
			const { size, name, type } = this.getFileDetails(currentFile);
			return {
				size,
				name,
				type,
				fileUrlAccessor: accessor,
				editorUrl,
				urlMap,
			};
		});
	}

	private getFileAccessor(file: PopupDialogInputFile): PopupDialogServiceDocumentDetails {
		const agnosticFile = file as any;
		if (agnosticFile.fileUrl) {
			return this.buildPublicFileAccessor(file as PublicFile);
		} else if (agnosticFile.companyId) {
			return this.buildCompanyTemplateFileAccessor(file as CompanyTemplate);
		} else if (agnosticFile.taskId) {
			return this.buildTaskFileAccessor(file as TaskFile);
		} else if (agnosticFile.authorId) {
			return this.buildMessageFileAccessor(file as Message);
		} else {
			return this.buildProjectFileAccessor(file as FileDocument);
		}
	}

	private getFileDetails(file: PopupDialogInputFile): {
		size: number;
		name: string;
		type: FileType;
	} {
		const agnosticFile = file as any;
		if (agnosticFile.authorId) {
			const message = file as Message;
			return {
				size: message.fileSize,
				name: message.fileName ?? message.content,
				type: this.mapFileType(message.messageType),
			};
		} else {
			const nonMessageFile = file as PopupDialogNonMessageFile;
			return {
				size: +nonMessageFile.size,
				name: nonMessageFile.name,
				type: this.mapFileType(nonMessageFile.type),
			};
		}
	}

	mapFileType(fileType: FileType | COMPANY_FILE_TYPE | FILE_TYPE | MessageType): FileType {
		for (const type in FileType) {
			if (fileType === type) {
				return fileType as FileType;
			}
		}
		return FileType.DOCUMENT;
	}

	private buildCompanyTemplateFileAccessor(
		template: CompanyTemplate,
	): PopupDialogServiceDocumentDetails {
		return {
			accessor: () => {
				return this.companyTemplatesService.getFile(template.companyId, template, false);
			},
			urlMap: { fileId: template.id, companyId: template.companyId },
		};
	}

	private buildProjectFileAccessor(file: FileDocument): PopupDialogServiceDocumentDetails {
		return {
			accessor: () => {
				return this.fileService.getFile(file.projectId, file, false);
			},
			urlMap: { fileId: file.id },
		};
	}

	private buildPublicFileAccessor(file: PublicFile): PopupDialogServiceDocumentDetails {
		return {
			accessor: () => {
				return Promise.resolve(file.fileUrl);
			},
			urlMap: {},
		};
	}

	private buildTaskFileAccessor(file: TaskFile): PopupDialogServiceDocumentDetails {
		return {
			accessor: () => {
				return firstValueFrom(this.taskFileService.getTaskFileUrl(file));
			},
			urlMap: {},
		};
	}

	private buildMessageFileAccessor(file: Message): PopupDialogServiceDocumentDetails {
		return {
			accessor: () => {
				return this.chatService.getFile(file.projectId, file, null, false);
			},
			urlMap: {},
		};
	}
}
