<div class="container-fluid h-100 manage-employee-subscriptions wiz-card">
	<div *ngIf="!fromSubscriptionOverview" class="row justify-content-start no-gutters">
		<div class="col-auto pointer">
			<mat-icon (click)="back.emit($event)" aria-hidden="false">arrow_back</mat-icon>
		</div>
		<div class="col-auto ml-3">
			<p class="step-header">{{ 'subscription.employees.editEmployees' | translate }} :</p>
		</div>
	</div>
	<ng-template *ngIf="!loading; then editEmployees; else spinner"></ng-template>
</div>

<ng-template #editEmployees>
	<div class="row w-100 non-selectable">
		<div [class.error]="noOfLicencesSelectedFormControl.invalid" class="col-4">
			<mat-icon *ngIf="noOfLicencesSelectedFormControl.invalid" class="align-middle mr-2">error</mat-icon>
			<span class="align-middle licence-text"
				>{{ 'subscription.employees.licences' | translate }} : {{ noOfLicencesSelectedFormControl.value }}</span
			>
			<mat-icon (click)="decrementNoOfLicences()" class="align-middle ml-3 pointer action--decrement">remove_circle </mat-icon>
			<mat-icon (click)="incrementNoOfLicences()" class="align-middle pointer action--increment">add_circle</mat-icon>
		</div>
		<div class="col-8">
			<span class="badge badge-pill badge-primary mr-3">
				{{ subscribedPlanName }}
				<span class="badge badge-pill badge-light">{{ employeeSubscriptionCount.paid || 0 }}</span>
			</span>
			<span *ngIf="numberOfUnassignedLicenses > 0" class="badge badge-pill badge-secondary mr-3">
				{{ 'subscription.licences.unassignedLicences' | translate }}
				<span class="badge badge-pill badge-light">{{ numberOfUnassignedLicenses }}</span>
			</span>
		</div>
	</div>
	<div class="row text-center w-100 mt-4 h-100">
		<div class="col-12">
			<app-dialog-line>
				<div class="col-4">{{ 'subscription.employees.name' | translate }}</div>
				<div class="col-4">{{ 'subscription.employees.role' | translate }}</div>
				<div class="col-4">{{ 'subscription.employees.licence' | translate }}</div>
			</app-dialog-line>
			<div class="row no-gutters employee-container">
				<div class="col">
					<app-dialog-line *ngFor="let employee of employeeSubscriptions; let i = index">
						<div class="col-4">{{ employee.name }}</div>
						<div class="col-4">{{ employee.role | translate }}</div>
						<div class="col-4">
							<mat-button-toggle-group (change)="onMatButtonToggleChange()" [(ngModel)]="employee.type">
								<mat-button-toggle [value]="SubscriptionEmployeeType.PAID">{{ subscribedPlanName }}</mat-button-toggle>
								<mat-button-toggle [value]="SubscriptionEmployeeType.FREE"> Basic</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</app-dialog-line>
				</div>
			</div>
		</div>
	</div>
	<div class="row manage-employees-footer">
		<div *ngIf="noOfLicencesSelectedFormControl.invalid" class="col-12 text-right">
			<div class="error-text">
				{{ noOfLicencesSelectedFormControl.hasError('licencesInvalid') ? noOfLicencesSelectedFormControl.errors['licencesInvalid'] : '' }}
			</div>
		</div>
	</div>
	<div *ngIf="noOfLicencesSelectedFormControl.valid">{{ costText }}</div>
	<div *ngIf="noOfLicencesSelectedFormControl.valid">{{ dateChangeText }}</div>
	<app-confirm-button (confirmed)="confirmSelectedEmployees()" [disabled]="noOfLicencesSelectedFormControl.invalid" [locked]="locked">
	</app-confirm-button>
</ng-template>

<ng-template #spinner>
	<div class="row justify-content-center">
		<mat-spinner color="accent" diameter="30"></mat-spinner>
	</div>
</ng-template>
