import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromProfileLimitsActions from '../actions/profile-limits.actions';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { selectUserId } from '../selectors/app.selectors';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';

@Injectable()
export class CurrentUserProfileLimitsEffect implements OnInitEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly profileLimitsService: ProfileLimitsService,
		private readonly store: Store,
	) {}

	currentUserProfileLimits$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProfileLimitsActions.subscribeCurrentUserProfileLimits),
			exhaustMap(() =>
				this.store.select(selectUserId).pipe(
					filter((userId) => !!userId),
					switchMap((userId) =>
						this.profileLimitsService.getProfileLimitsById(userId).pipe(
							map((resp) =>
								fromProfileLimitsActions.updateCurrentUserProfileLimitsSuccess({ payload: resp }),
							),
							catchError((error) =>
								of(fromProfileLimitsActions.updateCurrentUserProfileLimitsFail(error)),
							),
						),
					),
				),
			),
		),
	);

	ngrxOnInitEffects(): Action {
		return fromProfileLimitsActions.subscribeCurrentUserProfileLimits();
	}
}
