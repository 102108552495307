import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { MemberRole } from 'domain-entities';
import { selectUserRole } from '@store/selectors/app.selectors';

@Directive({
	selector: '[appCompanyMemberRole]',
})
export class CompanyMemberRoleDirective implements OnInit, OnDestroy {
	@Input('appCompanyMemberRole') roles: MemberRole[] = [];

	private destroy$ = new Subject();

	constructor(
		private readonly store: Store,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this.store.select(selectUserRole).subscribe((role) => {
			if (this.roles.includes(role)) {
				this.viewContainerRef.createEmbeddedView(this.templateRef);
			} else {
				this.viewContainerRef.clear();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
