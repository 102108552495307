import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG,
	MatLegacySelect as MatSelect,
} from '@angular/material/legacy-select';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, flatten, groupBy, isEqual, noop, uniqueId, values } from 'lodash';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { startWith } from 'rxjs/operators';

/**
 * Explanation for selected fields:
 *
 * background:
 * If a value is passed the dropdown trigger's background color is set to the passed value.
 *
 * suppressValue:
 * If set to true the dropdown trigger's text will not reflect the selected option(s) but instead
 * Keep its initial text.
 *
 */
export interface DropdownConfig {
	placeholder?: string;
	searchPlaceholder?: string;
	search?: boolean;
	multiple?: boolean;
	icon?: string;
	minWidth?: string;
	minHeight?: string;
	iconFontSet?: string;
	fontColor?: string;
	background?: string;
	suppressValue?: boolean;
	buttonTextClasses?: string[];
	onDisabledClick?: Function;
	groupIds?: string[];
}

export interface DropdownOptionValue {
	value: string;
	id?: number | string;
	disabled?: boolean;
	onClick?: Function;
	optionClass?: string;
	tooltip?: string;
	group?: string;

	[key: string]: any;
}

const DROPDOWN_DEFAULT_CONFIG: DropdownConfig = {
	placeholder: 'Select Value',
	searchPlaceholder: 'Search Value',
	search: false,
	multiple: false,
	icon: null,
	minWidth: 'initial',
	minHeight: 'initial',
	iconFontSet: null,
	buttonTextClasses: ['mat-caption'],
};

@Component({
	selector: 'app-basic-dropdown',
	templateUrl: './basic-dropdown.component.html',
	styleUrls: ['./basic-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { overlayPanelClass: ['basic-dropdown-overlay-panel'] },
		},
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BasicDropdownComponent),
			multi: true,
		},
	],
})
export class BasicDropdownComponent implements OnChanges, ControlValueAccessor {
	@Input() optionValues: DropdownOptionValue[] = [];
	@Input() disabled;
	@Input() config: DropdownConfig = {};
	@ViewChild(MatSelect) dropdownRef: MatSelect;

	searchControl = new UntypedFormControl('');

	selectedGroup$$ = new BehaviorSubject<string>(null);

	groupedOptionValues$$ = new BehaviorSubject<{ [groups: string]: DropdownOptionValue[] }>({});
	groupNames$ = new BehaviorSubject<string[]>([]);
	visibileSelectedGroup$ = combineLatest([this.selectedGroup$$, this.groupNames$]).pipe(
		map(([selectedGroup, groupNames]) => {
			if (groupNames.length && !groupNames.includes(selectedGroup)) {
				return first(groupNames);
			}
			return selectedGroup;
		}),
	);

	hasGroups$ = this.groupNames$.pipe(map((names) => names.length > 0));

	isDropdownDisabled = false;
	isPanelOpen = false;

	displayedOptions$ = combineLatest([
		this.searchControl.valueChanges.pipe(startWith('')),
		this.groupedOptionValues$$,
		this.visibileSelectedGroup$,
	]).pipe(
		map(([, groupedOptionValues, selectedGroup]) => {
			const searchValue = this.searchControl.value as string;
			const group = groupedOptionValues[selectedGroup] || [];
			return flatten(values(groupedOptionValues)).map((value) => {
				return {
					visible:
						!this.config.groupIds ||
						(group.includes(value) &&
							(!searchValue.length ||
								value.value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)),
					value: value,
				};
			});
		}),
	);

	private onChange: Function = noop;
	get OPTION_DEFAULT_VALUE(): DropdownOptionValue {
		return {
			value: null,
			id: uniqueId('dropdownOptionValue_'),
			disabled: false,
			onClick: null,
			optionClass: '',
			tooltip: null,
		};
	}

	get currentSelectedValueText(): string {
		if (!this._value || this.config.suppressValue) {
			return this.config.placeholder;
		}

		if (this.config.multiple) {
			const _value: DropdownOptionValue[] = this._value;

			if (_value.length === 0) {
				return this.config.placeholder;
			}

			return _value.length === 1
				? _value[0].value
				: `${_value[0].value} & ${_value.length - 1} more`;
		}

		return this._value.value;
	}

	get isValueSelected(): boolean {
		return (this.value?.value || this.value?.length > 0) && !this.config.suppressValue;
	}

	private _value;

	@Input()
	set value(val) {
		this._value = val;
		this.onChange(val);
		this.changeDetection.detectChanges();
	}

	get value(): any {
		return this._value;
	}

	@Output()
	valueChange = new EventEmitter<DropdownOptionValue>();

	constructor(private readonly changeDetection: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.config !== undefined) {
			if (changes.config?.currentValue) {
				this.config = { ...DROPDOWN_DEFAULT_CONFIG, ...this.config };
			} else {
				this.config = { ...DROPDOWN_DEFAULT_CONFIG };
			}
			this.groupNames$.next(changes.config.currentValue.groupIds || []);
			if (!this.selectedGroup$$.value) {
				this.selectedGroup$$.next(first(changes.config.currentValue.groupIds));
			}
		}

		if (changes.optionValues?.currentValue) {
			const optionValues = [
				...(this.optionValues || []).map((value) => ({ ...this.OPTION_DEFAULT_VALUE, ...value })),
			];
			const groupedOptionValues = groupBy(optionValues, (value) => value.group || null);
			this.groupedOptionValues$$.next(groupedOptionValues);
		}
	}

	panelStateChanged(newValue: boolean): void {
		this.isPanelOpen = newValue;
	}

	onClickDropdownButton(): void {
		this.dropdownRef.open();
	}

	clearSearchInput(): void {
		this.searchControl.setValue('');
	}

	clearSelection(): void {
		this.value = null;
	}

	compareWith(
		option1: DropdownOptionValue | DropdownOptionValue[],
		option2: DropdownOptionValue | DropdownOptionValue[],
	): boolean {
		return isEqual(option1, option2);
	}

	writeValue(value: DropdownOptionValue | DropdownOptionValue[]): void {
		if (value) {
			if (Array.isArray(value)) {
				this.value = value.map((val) => {
					return { ...this.OPTION_DEFAULT_VALUE, ...val };
				});
			} else {
				this.value = { ...this.OPTION_DEFAULT_VALUE, ...value };
			}
			return;
		}

		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(_fn: any): void {}

	setDisabledState(isDisabled: boolean): void {
		this.isDropdownDisabled = isDisabled || false;
	}
}
