import { NgModule } from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProjectsSelectionFilterComponent } from './projects-selection-filter.component';
import { ProjectsSelectionDialogModule } from '@craftnote/material-theme';
import { ProjectsSelectionListModule } from '../projects-selection-list/projects-selection-list.module';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	declarations: [ProjectsSelectionFilterComponent],
	imports: [
		ScrollingModule,
		ProjectsSelectionDialogModule,
		ProjectsSelectionListModule,
		SharedModule,
	],
	exports: [ProjectsSelectionFilterComponent],
})
export class ProjectsSelectionFilterModule {}
