<section class="task-time-edit-form-section px-3 h-100 flex-scroll">
	<div class="flex-scroll__container">
		<div *ngIf="isComponentReady" class="d-flex align-items-center mb-3">
			<div class="d-flex align-items-center">
				<button (click)="onCancel()" class="mr-3" mat-icon-button>
					<mat-icon class="close-btn-icon">clear</mat-icon>
				</button>

				<div class="m-0 d-inline-flex mat-headline font-weight-bold">
					<span>{{ 'task-time-edit-form.heading-edit' | translate }}</span>
				</div>
			</div>

			<div class="ml-auto d-flex align-items-center">
				<button (click)="deleteTaskTime(taskTimeUpdated)" class="mr-3" mat-icon-button>
					<mat-icon>delete_outline</mat-icon>
				</button>

				<button (click)="submitForm(taskTimeUpdated)" [disabled]="!isFormValid" cdkfocusinitial color="accent" mat-flat-button>
					<span>{{ 'task-time-edit-form.submit-cta-edit' | translate }}</span>
				</button>
			</div>
		</div>

		<div class="flex-scroll__container__scrollable-content">
			<div class="d-flex mb-3 flex-wrap">
				<span>{{ 'task-time-edit-form.duration' | translate }}</span>
				<span *ngIf="isComponentReady" class="font-weight-bold ml-auto">
					<ng-container *ngIf="isDateTimeValid">
						{{ startEndDuration.hr }} {{ 'task-time-edit-form.duration-hrs' | translate }}
						{{ startEndDuration.min }}
						{{ 'task-time-edit-form.mins' | translate }}
					</ng-container>
					<ng-container *ngIf="!isDateTimeValid">
						<span class="text-primary">
							{{ 'time-tracking-add-edit-form.invalid-duration' | translate }}
						</span>
					</ng-container>
				</span>
			</div>

			<form [formGroup]="taskTimeForm">
				<mat-form-field appearance="outline" class="w-100 form-field-outline mt-2" color="accent" floatLabel="always">
					<mat-label>{{ 'task-time-edit-form.task-name' | translate }}</mat-label>
					<input formControlName="taskName" matInput />
				</mat-form-field>

				<div class="mb-3 d-flex justify-content-end">
					<a
						[routerLink]="['/projects', projectId, 'tasks', taskTime.taskId, 'edit']"
						class="d-inline-flex font-weight-bold nmt-2 text-primary"
						href=""
						rel="noopener"
						target="_blank"
					>
						<mat-icon [size]="17" class="mr-1">open_in_new</mat-icon>
						{{ 'task-time-edit-form.open-new-tab' | translate }}
					</a>
				</div>

				<div class="d-flex">
					<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" color="accent" floatLabel="always">
						<mat-label>{{ 'task-time-edit-form.start-date' | translate }}</mat-label>
						<input
							(click)="startDatePickerRef.open()"
							(dateChange)="onChangeStartDate($event)"
							[matDatepicker]="startDatePickerRef"
							formControlName="startDate"
							matInput
							readonly
						/>
						<mat-datepicker-toggle [for]="startDatePickerRef" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #startDatePickerRef color="accent"></mat-datepicker>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
						<mat-label>{{ 'task-time-edit-form.start-time' | translate }}</mat-label>
						<app-time-picker [max]="endDateTime" formControlName="startTime"></app-time-picker>
					</mat-form-field>
				</div>

				<div class="d-flex">
					<mat-form-field appearance="outline" class="form-field-outline mr-3 flex-grow-1" color="accent" floatLabel="always">
						<mat-label>{{ 'task-time-edit-form.end-date' | translate }}</mat-label>
						<input
							(click)="endDatePickerRef.open()"
							(dateChange)="onChangeEndDate($event)"
							[matDatepicker]="endDatePickerRef"
							formControlName="endDate"
							matInput
							readonly
						/>
						<mat-datepicker-toggle [for]="endDatePickerRef" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #endDatePickerRef color="accent"></mat-datepicker>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-field-outline time-picker" floatLabel="always">
						<mat-label>{{ 'task-time-edit-form.end-time' | translate }}</mat-label>
						<app-time-picker formControlName="endTime"></app-time-picker>
					</mat-form-field>
				</div>

				<app-time-tracking-assignee-input
					[disabled]="true"
					[fallBackAssignees]="fallBackAssignees$ | async"
					[projectMembers]="projectMembers$ | async"
					[required]="true"
					class="d-block"
					formControlName="assignee"
				>
				</app-time-tracking-assignee-input>

				<div class="task-time-select-project-button-container disabled">
					<div class="align-items-center d-flex">
						<img alt="" class="icon-image d-inline-flex mr-3 img-fluid" src="assets/images/icons/time-tracking/project.svg" />
						<span>{{ 'task-time-edit-form.project' | translate }}</span>
					</div>
					<div *ngVar="projects$ | async as projects" class="align-items-center d-flex ml-auto pointer">
						<span class="font-weight-bold">{{ projects[projectId]?.name }}</span>
						<button class="task-time-select-project-button" mat-icon-button>
							<mat-icon [size]="25" color="primary">chevron_right</mat-icon>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</section>
