<div class="d-flex">
	<mat-icon (click)="dialogRef.close()" class="ml-auto pointer">close</mat-icon>
</div>
<ch-promotional-content orientation="row">
	<div class="content-heading pt-5 mat-headline">{{ 'projectList_' + feature + '_paywall_header' | translate }}</div>
	<img
		*ngIf="feature === 'filters'"
		src="assets/images/illustration-paywall.png"
		class="p-5 d-block filter-paywall__image"
		alt="paywall-illustration"
	/>

	<div *ngIf="feature=== 'grouping'" #lottiContainer id="image"></div>
	<ul class="content-list">
		<li>{{ 'projectList_' + feature + '_paywall_point1' | translate }}</li>
		<li>{{ 'projectList_' + feature + '_paywall_point2' | translate }}</li>
		<li>{{ 'projectList_' + feature + '_paywall_point3' | translate }}</li>
	</ul>
	<div *ngIf="isOwner$ | async" class="action-buttons">
		<button
			(click)="dialogRef.close()"
			[routerLink]="'/settings/subscription' + (subscriptionRoute | async)"
			class="pointer mt-4"
			mat-flat-button
			color="accent"
		>
			{{ 'projectList_filters_paywall_cta' | translate }}
		</button>
	</div>

	<div *ngIf="isSupervisor$ | async" class="action-buttons">
		<button
			(click)="goToPricingTable()"
			class="pointer mt-4"
			mat-flat-button
			color="accent"
		>
			{{ 'projectList_grouping_paywallButton' | translate }}
		</button>
	</div>
</ch-promotional-content>
