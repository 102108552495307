import { NgModule } from '@angular/core';
import { ColorPickerComponent } from './color-picker.component';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

const COMPONENT = [ColorPickerComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule, MatButtonModule],
	exports: [COMPONENT],
})
export class ColorPickerModule {}
