<div class="d-flex justify-content-center align-items-center vh-100 vw-100">
	<app-pulsing-craftnote-icon
		*ngIf="
			currentStep === AppInitStep.LOAD_PROFILE_AND_COMPANY ||
			currentStep === AppInitStep.LOAD_PROJECTS ||
			currentStep === AppInitStep.ACCEPT_COMPANY_INVITATION ||
			currentStep === AppInitStep.LOAD_EXAMPLE_PROJECT
		"
	>
	</app-pulsing-craftnote-icon>
	<app-company-initialization
		class="h-100 w-100"
		(completed)="onCompanyInitEvent($event)"
		*ngIf="currentStep === AppInitStep.COMPANY_INITIALIZATION"
	></app-company-initialization>
	<app-firefox-change-settings
		class="h-100 w-100"
		(completed)="onSettingsCompleted()"
		*ngIf="currentStep === AppInitStep.ENABLE_PUSH_NOTIFICATION_SETTINGS"
	></app-firefox-change-settings>
	<app-notification-token-permission
		class="h-100 w-100 overflow-y-auto"
		(completed)="onNotificationTokenCompleted()"
		*ngIf="currentStep === AppInitStep.REQUEST_FOR_NOTIFICATION_TOKEN"
	></app-notification-token-permission>
</div>
