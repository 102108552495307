import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
	Currency,
	Product,
	ProductType,
	Subscription,
	SubscriptionEmployeeType,
} from 'domain-entities';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AlertService } from '@injectables/services/alert/alert.service';
import { CouponResults } from '@modules/features/subscription/products/manage-company-employess/model/CouponResults';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { getProductForCurrentPlan } from '@modules/features/subscription/products/shared/shared.functions';
import { selectProfile } from '@store/selectors/app.selectors';
import { selectProducts } from '@store/selectors/products.selectors';
import { StripeService } from '@modules/shared/stripe';
import { EntityChanges } from '@craftnote/shared-utils';
import { SubscriptionsConnector } from '@shared/firebase/connectors/firestore/collections/subscriptions/subscriptions.connector';
import type {
	SetupIntent,
	StripeCardElement,
	StripeError,
	StripeIbanElement,
} from '@stripe/stripe-js';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { shareReplayOne } from '@craftnote/shared-utils';
import { environment } from '@env/environment';
import { COLLECTION_SUBSCRIPTIONS } from '@shared/constants/firebase';

enum SubscriptionActions {
	CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',
	CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
	CHANGE_PLAN = 'CHANGE_PLAN',
	CHANGE_QUANTITY = 'CHANGE_QUANTITY',
	CHANGE_BILLING = 'CHANGE_BILLING',
	CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD',
}

export interface SubscriptionContext {
	planId: string;
	quantity: number;
	billing: BillingInformation;
	paymentMethodId?: string;
	subscriptionId: string;
	employees: Array<{
		profileId: string;
		type: SubscriptionEmployeeType;
	}>;
	couponId?: string;
}

export interface BillingInformation {
	company: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address: {
		line1: string;
		line2?: string;
		zip: string;
		city: string;
		country: string;
	};
	taxId: string;
}

export interface BillingDetails {
	billing: BillingInformation;
	paymentMethod?: PaymentMethodType;
}

interface SubscriptionRequest {
	action: string;
	context: any;
}

export interface SubscriptionSetupRequest {
	paymentMethodType: PaymentMethodType;
}

export enum PaymentMethodType {
	CARD = 'card',
	IDEAL = 'ideal',
	SEPA_DEBIT = 'sepa_debit',
}

export interface SetupIntentConfirmationPayload {
	payment_method: {
		sepa_debit?: StripeIbanElement;
		card?: StripeCardElement;
		billing_details: {
			name: string;
			email: string;
		};
	};
}

export interface SubscriptionSetupResult {
	clientSecret: string;
}

export interface CouponRequest {
	couponId: string;
	planId: string;
}

export interface CouponResponse {
	valid: boolean;
	percentOff?: number;
	amountOff?: {
		currency: Currency;
		value: number;
	};
	validForever: boolean;
}

export interface SubscriptionCreationResult {
	data?: {
		status: 'SUCCESS' | 'REQUIRES_ACTION';
		clientSecret: string;
	};
}

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	public stripe: any = Stripe(environment.stripe.public_key, { apiVersion: '2020-08-27' });

	private readonly subscriptions$: Observable<Subscription[]>;
	private couponResult$: BehaviorSubject<CouponResults> = new BehaviorSubject<CouponResults>({});

	constructor(
		private functions: AngularFireFunctions,
		private firestore: AngularFirestore,
		private alertService: AlertService,
		private store: Store<AppState>,
		private stripeService: StripeService,
		private readonly subscriptionConnector: SubscriptionsConnector,
		private readonly errorHandlerService: ErrorHandlerService,
	) {
		this.subscriptions$ = this.getCurrentUserSubscriptions();
	}

	private getCurrentUserSubscriptions(): Observable<Subscription[]> {
		return this.store.select(selectProfile).pipe(
			switchMap((profile) => {
				if (!(profile && profile.company)) {
					return of([]);
				}
				return this.firestore
					.collection<Subscription>(COLLECTION_SUBSCRIPTIONS, (ref) =>
						ref.where('companyId', '==', profile.company),
					)
					.valueChanges();
			}),
			shareReplayOne(),
		);
	}

	public async createSetupIntent(
		paymentMethodType: PaymentMethodType,
	): Promise<SubscriptionSetupResult> {
		const payload: SubscriptionSetupRequest = {
			paymentMethodType: paymentMethodType,
		};
		let result: SubscriptionSetupResult;
		try {
			result = await this.functions
				.httpsCallable<SubscriptionSetupRequest>('setupSubscription')(payload)
				.pipe(take(1))
				.toPromise();
		} catch (error) {
			this.alertService.showAlert(error.message);
			return;
		}
		return result;
	}

	public async confirmSetupIntent(
		clientSecret: string,
		confirmationPayload: SetupIntentConfirmationPayload,
	): Promise<string | undefined> {
		let setupIntentResponse: { setupIntent?: SetupIntent; error?: StripeError };
		try {
			if (confirmationPayload.payment_method.card) {
				setupIntentResponse = await this.stripeService.confirmCardSetup(clientSecret, {
					payment_method: {
						card: confirmationPayload.payment_method.card,
						billing_details: confirmationPayload.payment_method.billing_details,
					},
				});
			} else if (confirmationPayload.payment_method.sepa_debit) {
				setupIntentResponse = await this.stripeService.confirmSepaDebitSetup(clientSecret, {
					payment_method: {
						sepa_debit: confirmationPayload.payment_method.sepa_debit,
						billing_details: confirmationPayload.payment_method.billing_details,
					},
				});
			}
		} catch (error) {
			this.alertService.showAlert(error.message);
			this.errorHandlerService.handleError(
				new Error(`${error.code} - ${error.decline_code} : ${error.message}`),
			);
			return;
		}
		if (setupIntentResponse.error) {
			this.alertService.showAlert(setupIntentResponse.error.message);
			this.errorHandlerService.handleError(
				new Error(
					`${setupIntentResponse.error.code} - ${setupIntentResponse.error.decline_code} : ${setupIntentResponse.error.message}`,
				),
			);
			return;
		}
		return setupIntentResponse.setupIntent.payment_method as string;
	}

	async confirmPayment(clientSecret: string): Promise<void> {
		await this.stripeService.confirmCardPayment(clientSecret);
	}

	public async createSubscription(
		subscriptionContext: Omit<SubscriptionContext, 'subscriptionId'>,
	): Promise<SubscriptionCreationResult | null> {
		const subscriptionRequest: SubscriptionRequest = {
			action: SubscriptionActions.CREATE_SUBSCRIPTION,
			context: subscriptionContext,
		};
		try {
			const result = await this.functions
				.httpsCallable<SubscriptionRequest>('setSubscription')(subscriptionRequest)
				.toPromise();
			return result;
		} catch (error) {
			return null;
		}
	}

	public async updateSubscription(
		subscriptionContext: Pick<SubscriptionContext, 'subscriptionId' | 'planId'>,
	): Promise<void> {
		const subscriptionRequest: SubscriptionRequest = {
			action: SubscriptionActions.CHANGE_PLAN,
			context: subscriptionContext,
		};
		try {
			await this.functions
				.httpsCallable<SubscriptionRequest>('setSubscription')(subscriptionRequest)
				.toPromise();
		} catch (error) {
			this.alertService.showAlert('error.unknown');
		}
	}

	public async changeQuantity(
		subscriptionContext: Pick<SubscriptionContext, 'subscriptionId' | 'employees' | 'quantity'>,
	): Promise<boolean> {
		const subscriptionRequest: SubscriptionRequest = {
			action: SubscriptionActions.CHANGE_QUANTITY,
			context: subscriptionContext,
		};
		try {
			await this.functions
				.httpsCallable<SubscriptionRequest>('setSubscription')(subscriptionRequest)
				.toPromise();
			return true;
		} catch (error) {
			this.alertService.showAlert('error.unknown');
			return false;
		}
	}

	public async cancelSubscription(
		subscriptionContext: Pick<SubscriptionContext, 'subscriptionId' | 'planId'>,
	): Promise<void> {
		const subscriptionRequest: SubscriptionRequest = {
			action: SubscriptionActions.CANCEL_SUBSCRIPTION,
			context: subscriptionContext,
		};
		try {
			await this.functions
				.httpsCallable<SubscriptionRequest>('setSubscription')(subscriptionRequest)
				.toPromise();
		} catch (error) {
			this.alertService.showAlert('error.unknown');
		}
	}

	public getActiveSubscription(): Observable<Subscription | undefined> {
		return this.subscriptions$.pipe(
			map((subscriptions) =>
				subscriptions && subscriptions.length ? subscriptions[0] : undefined,
			),
		);
	}

	public async validateCoupon(coupon: string, planId: string): Promise<CouponResponse> {
		const request: CouponRequest = {
			couponId: coupon,
			planId,
		};
		try {
			const validationResult = await this.functions
				.httpsCallable<CouponRequest>('retrieveSubscriptionCoupon')(request)
				.toPromise();
			return validationResult;
		} catch (error) {
			return undefined;
		}
	}

	public async undoPlanChange(subscription: Subscription): Promise<void> {
		const context: Pick<SubscriptionContext, 'subscriptionId' | 'planId'> = {
			subscriptionId: subscription.id,
			planId: subscription.currentState.planId,
		};
		const undoRequest: SubscriptionRequest = {
			action: SubscriptionActions.CHANGE_PLAN,
			context: context,
		};
		try {
			await this.functions
				.httpsCallable<SubscriptionRequest>('setSubscription')(undoRequest)
				.toPromise();
		} catch (error) {
			this.alertService.showAlert('error.unknown');
		}
	}

	public async getBillingInformation(subscriptionId: string): Promise<BillingDetails | undefined> {
		try {
			const res = await this.functions
				.httpsCallable('retrieveSubscriptionDetails')({ subscriptionId })
				.pipe(take(1))
				.toPromise();
			return res;
		} catch (error) {
			return undefined;
		}
	}

	public getCompanySubscriptions(): Observable<Subscription[]> {
		return this.subscriptions$;
	}

	async getOrLoadCouponResponse(planId: string): Promise<CouponResponse> {
		const currentCouponResults = await this.couponResult$.pipe(take(1)).toPromise();
		if (currentCouponResults[planId]) {
			return currentCouponResults[planId];
		}
		const productSubscription$ = this.getActiveSubscription();
		const subscription = await productSubscription$
			.pipe(
				filter((subscriptionInner) => !!subscriptionInner),
				take(1),
			)
			.toPromise();
		if (!subscription.appliedCoupon) {
			return { valid: false, validForever: false };
		}
		const newCouponResponse = await this.validateCoupon(subscription.appliedCoupon, planId);
		const newCouponResults = { ...currentCouponResults };
		newCouponResults[planId] = newCouponResponse;
		this.couponResult$.next(newCouponResults);
		return newCouponResponse;
	}

	getTrialSubscription(): Observable<Subscription | null> {
		const subscriptions$ = this.subscriptions$;
		const products$ = this.store.select(selectProducts);
		return combineLatest([subscriptions$, products$]).pipe(
			map(([subscriptions, products]) => {
				if (!products?.length) {
					return null;
				}
				return subscriptions.find(
					(subscription) =>
						getProductForCurrentPlan(products, subscription).type === ProductType.TRIAL,
				);
			}),
		);
	}

	getActiveSubscriptionAndProduct(): Observable<[Subscription, Product] | null> {
		return this.getActiveSubscription().pipe(
			withLatestFrom(this.store.select(selectProducts)),
			distinctUntilChanged(),
			map(([subscription, products]) => {
				return [subscription, subscription && getProductForCurrentPlan(products, subscription)];
			}),
		);
	}

	getCurrentSubscriptionProductType(): Observable<ProductType | null> {
		return this.getActiveSubscription().pipe(
			withLatestFrom(this.store.select(selectProducts)),
			map(([subscription, products]) => {
				if (!subscription) {
					return null;
				}
				return getProductForCurrentPlan(products, subscription).type;
			}),
		);
	}

	getCurrentSubscription(): Observable<Subscription | undefined> {
		return this.getCurrentUserSubscriptions().pipe(
			map((subscriptions) =>
				subscriptions && subscriptions.length ? subscriptions[0] : undefined,
			),
		);
	}

	async getCustomerPortalUrl(): Promise<string> {
		const result = await this.functions.httpsCallable('customerPortal')(null).toPromise();
		return result['customerPortalUrl'] as string;
	}

	getSubscriptionEntities(companyId: string): Observable<EntityChanges<Subscription>> {
		return this.subscriptionConnector.loadSubscriptions(companyId);
	}
}
