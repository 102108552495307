import { Project, ProjectType } from 'domain-entities';
import { createKeyMap } from '@shared/functions/utils/object.functions';
import { isNil } from 'lodash';
import { TimeRange } from '@globalTypes/interfaces/time-range.types';
import moment from 'moment';

export enum ProjectFilter {
	STATUS,
	START_DATE,
	END_DATE,
	EMPLOYEE,
	FOLDER,
}

export interface ProjectFilterByStatusConfig {
	filterType: ProjectFilter.STATUS;
	statusIds: (string | null)[]; // A value of null indicates the inclusion of projects without a status;
}

export interface ProjectFilterByFolderConfig {
	filterType: ProjectFilter.FOLDER;
	folderIds: string[];
}

export interface ProjectFilterByEmployeeConfig {
	projectListType: 'active' | 'archive';
	filterType: ProjectFilter.EMPLOYEE;
	employeeIds: string[] | null; // A value of null indicates the inclusion of projects without a status;
}

export interface ProjectFilterByStartDateConfig {
	filterType: ProjectFilter.START_DATE;
	timeRange: TimeRange;
}

export interface ProjectFilterByEndDateConfig {
	filterType: ProjectFilter.END_DATE;
	timeRange: TimeRange;
}

export type ProjectFilterConfig =
	| ProjectFilterByStatusConfig
	| ProjectFilterByFolderConfig
	| ProjectFilterByStartDateConfig
	| ProjectFilterByEndDateConfig
	| ProjectFilterByEmployeeConfig;

export function filter(projects: Project[], config: ProjectFilterConfig): Project[] {
	let filterFunction: (project: Project) => boolean;
	switch (config.filterType) {
		case ProjectFilter.STATUS:
			filterFunction = filterByStatusBuilder(config);
			break;
		case ProjectFilter.FOLDER:
			filterFunction = filterByFolderBuilder(config);
			break;
		case ProjectFilter.START_DATE:
		case ProjectFilter.END_DATE:
			filterFunction = filterByDate(config);
			break;
		case ProjectFilter.EMPLOYEE:
			filterFunction = filterByEmployeeBuilder(config);
			break;
	}
	return projects.filter(filterFunction);
}

function filterByStatusBuilder(config: ProjectFilterByStatusConfig): (project: Project) => boolean {
	const statusIdsMap = createKeyMap<string>((statusId) => statusId, config.statusIds);
	return (project) =>
		project.projectType === ProjectType.PROJECT &&
		(!!statusIdsMap[project.statusId] ||
			(statusIdsMap['null'] === null && isNil(project.statusId)));
}

function filterByFolderBuilder(config: ProjectFilterByFolderConfig): (project: Project) => boolean {
	return (project) =>
		project.projectType === ProjectType.PROJECT && config.folderIds.includes(project.parentProject);
}

function filterByDate(
	config: ProjectFilterByStartDateConfig | ProjectFilterByEndDateConfig,
): (project: Project) => boolean {
	const field: keyof Project =
		config.filterType === ProjectFilter.START_DATE ? 'startDate' : 'endDate';

	if (!config.timeRange) {
		return (project) => isNil(project[field]);
	}
	// a range with open start and end contains everything (including missing values)
	if (!config.timeRange.start && !config.timeRange.end) {
		return () => true;
	}

	// We define a missing start value as infinitely early and missing end value as infinitely late
	const startAsUnix = config.timeRange.start
		? moment(config.timeRange.start).unix()
		: Number.MIN_SAFE_INTEGER;
	const endAsUnix = config.timeRange.end
		? moment(config.timeRange.end).unix()
		: Number.MAX_SAFE_INTEGER;

	return (project) => {
		let fieldValue = project[field];
		/**
		 * If the value is missing we set it to the fitting extreme so that it is
		 * included when and only when the fitting part of the range is missing
		 */
		if (!fieldValue) {
			fieldValue =
				config.filterType === ProjectFilter.START_DATE
					? Number.MIN_SAFE_INTEGER
					: Number.MAX_SAFE_INTEGER;
		}
		return fieldValue >= startAsUnix && fieldValue <= endAsUnix;
	};
}

function filterByEmployeeBuilder(
	config: ProjectFilterByEmployeeConfig,
): (project: Project) => boolean {
	if (config.employeeIds.length === 0) {
		return () => true;
	}
	const employeeIds = createKeyMap<string>((statusId) => statusId, config.employeeIds);

	if (config.projectListType === 'active') {
		return (project) => project.membersActive?.some((member) => employeeIds[member]);
	} else {
		return (project) => project.membersArchived?.some((member) => employeeIds[member]);
	}
}
