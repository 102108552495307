import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { sum } from 'lodash';

@Directive({
	selector: '[appShowMoreItems]',
	exportAs: 'appShowMoreItems',
})
export class ShowMoreItemsDirective implements AfterContentInit {
	@Input() appShowMoreItems = null;
	itemsHidden;

	constructor(private host: ElementRef) {}

	get maxItemsToShow(): number {
		return this.appShowMoreItems ?? 0;
	}

	get childElements(): any[] {
		return Array.from(this.host.nativeElement.children);
	}

	get childElementsExceedsMaxItemsToShow(): boolean {
		return this.childElements.length > this.maxItemsToShow;
	}

	hideItems(): void {
		this.itemsHidden = true;
		this.host.nativeElement.style.overflow = 'hidden';
		this.host.nativeElement.style.transition = 'max-height 0.5s ease-in-out';
		this.host.nativeElement.style.maxHeight =
			sum(this.childElements.slice(0, this.maxItemsToShow).map((child) => child.clientHeight)) +
			'px';
	}

	showItems(): void {
		this.itemsHidden = false;
		this.host.nativeElement.style.maxHeight =
			sum(this.childElements.map((child) => child.clientHeight)) + 'px';
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			if (this.childElementsExceedsMaxItemsToShow && this.appShowMoreItems !== null) {
				this.hideItems();
			}
		});
	}
}
