import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ch-promotional-content',
	templateUrl: './promotional-content.component.html',
	styleUrls: ['./promotional-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionalContentComponent implements OnInit {
	@Input() orientation: 'row' | 'column' = 'row';

	constructor() {}

	ngOnInit(): void {}
}
