<div class="d-flex flex-column h-100 project-area">
	<div class="p-2">
		<app-project-search
			[isArchived]="isArchive$ | async"
			(editProjectsChange)="filteredProjects.set($event)"
			[projects]="projects$ | async"
			(filterActive)="isFilterActive = $event"
			(groupBy)="groupBy($event)"
		></app-project-search>
	</div>
	<app-project-item-wrapper
		(click)="onProjectClick(project)"
		(backClicked)="onProjectClick(project, 'back')"
		*ngIf="openFolder$ | async as project"
		[projectId]="project.id"
		[context]="ProjectContext.ACTIVE"
		[isOpenFolderCard]="true"
		[selected]="project.id === (selectedProjectId$ | async)"
	></app-project-item-wrapper>

	<div class="flex-grow-1">
		<app-projects-list
			(projectClick)="onProjectClick($event)"
			[showParentHint]="(isFilterActive || groupByOption() !== GroupByOption.NONE) && !(openFolder$ | async)"
			[storeState]="projectListStoreData$ | async"
			[context]="context$ | async"
		></app-projects-list>
		<app-create-project *ngIf="(context$ | async) == 'active'"></app-create-project>
	</div>
</div>
