import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, Subject } from 'rxjs';
import { AlertService } from '@injectables/services/alert/alert.service';
import {
	COLLECTION_COMPANIES,
	COLLECTION_COMPANY_LIMITS,
	ERROR,
} from '@shared//constants/firebase';
import {
	Company as dCompany,
	Company as CompanyEntity,
	CompanyEmployee,
	CompanyLimit,
	isCompany,
	Member,
} from 'domain-entities';
import { Company } from '@shared//models/company.model';
import { map } from 'rxjs/operators';
import { CompanyConnector } from '@shared/firebase/connectors/firestore/collections/company/company.connector';
import { EntityChanges } from '@craftnote/shared-utils';
import { shareReplayOne } from '@craftnote/shared-utils';
import { FirestoreConnector } from '@craftnote/shared-injectables';

@Injectable({
	providedIn: 'root',
})
export class CompanyService implements OnDestroy {
	private destroy$ = new Subject();

	constructor(
		private readonly afStore: AngularFirestore,
		private readonly alertService: AlertService,
		private readonly firestoreConnector: FirestoreConnector,
		private readonly companyConnector: CompanyConnector,
	) {}

	getCompany(id: string): Observable<Company> {
		return this.afStore
			.collection<Company | dCompany>(COLLECTION_COMPANIES)
			.doc(id)
			.valueChanges()
			.pipe(map((json) => new Company().deserialize(json)));
	}

	getCompanyById(companyId: string): Observable<CompanyEntity> {
		return this.afStore
			.collection<CompanyEntity>(COLLECTION_COMPANIES)
			.doc<CompanyEntity>(companyId)
			.valueChanges()
			.pipe(shareReplayOne());
	}

	public async updateCompanyPartial(
		companyId: string,
		company: Partial<CompanyEntity>,
	): Promise<void> {
		return this.afStore
			.collection<CompanyEntity>(COLLECTION_COMPANIES)
			.doc(companyId)
			.update(company)
			.then(() => {
				this.alertService.showAlert('settings.company.update');
			})
			.catch((error) => {
				this.alertService.showAlert(ERROR + error.code);
			});
	}

	public async updateCompanyTransactional(
		projectId: string,
		updateFunction: (oldProject: CompanyEntity) => Partial<CompanyEntity> | undefined,
		withMessage?: string,
	): Promise<void> {
		await this.firestoreConnector.updateDocumentTransactional<CompanyEntity>(
			COLLECTION_COMPANIES,
			projectId,
			updateFunction,
			isCompany,
		);
		if (withMessage) {
			this.alertService.showAlert(withMessage);
		}
	}

	public updateCompanyMembers(
		companyId: string,
		members: { [keys: string]: Member },
	): Promise<void> {
		return this.afStore
			.collection(COLLECTION_COMPANIES)
			.doc<dCompany>(companyId)
			.update({ members })
			.catch((error) => {
				this.alertService.showAlert(ERROR + error.code);
			});
	}

	public getCompanyLimits(companyId: string): Observable<CompanyLimit> {
		return this.afStore
			.collection(COLLECTION_COMPANY_LIMITS)
			.doc<CompanyLimit>(companyId)
			.valueChanges();
	}

	public getCompanyEmployees(companyId: string): Observable<EntityChanges<CompanyEmployee>> {
		return this.companyConnector.watchCompanyMembers(companyId);
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	upsertCompanyEmployee(
		companyId: string,
		userId: string,
		updateFunction: (oldCompanyEmployee: CompanyEmployee | undefined) => CompanyEmployee,
	): Promise<void> {
		return this.companyConnector.upsertCompanyEmployee(companyId, userId, updateFunction);
	}
}
