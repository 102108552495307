import { createAction, props } from '@ngrx/store';
import { File } from 'domain-entities';
import { ProjectFilePath } from '../reducers/project-files.reducer';
import { ProjectFile } from '@shared/models/project-file.model';

export const initProjectFilesStateChangesAction = createAction(
	'[Project Files] Init project files state changes',
	props<{ projectId: string }>(),
);

export const addProjectFilesAction = createAction(
	'[Project Files] Add project files',
	props<{ files: File[] }>(),
);

export const deleteProjectFilesAction = createAction(
	'[Project Files] Delete project files',
	props<{ files: string[] }>(),
);

export const clearProjectFilesAction = createAction(
	'[Project Files] Clear project files and its paths',
);

export const addProjectFilePathAction = createAction(
	'[Project Files] Add project file paths',
	props<{ filePath: ProjectFilePath }>(),
);

export const deleteProjectFilePathAction = createAction(
	'[Project Files] Delete project file paths',
	props<{ filePath: string }>(),
);

export const loadProjectFilePathAction = createAction(
	'[Project Files] Load project files path',
	props<{ file: ProjectFile }>(),
);

export const setLoadingFilePathAction = createAction(
	'[Project Files] set loading state of filesPath',
	props<{ filePath: ProjectFilePath }>(),
);
