<ng-container *ngIf="trackedTime.creationTime > (auditLogCutoffTime$ | async)">
	<div *ngIf="creatorName$ | async" class="pb-2">
		{{
			'time-tracking.log.created-by'
				| translate
					: {
							creatorName: creatorName$ | async,
							creationTime: trackedTime.creationTime | unixToMillis | date: 'short'
					  }
		}}
	</div>
	<ng-container *ngVar="lastChange$ | async as lastChange">
		<ng-container *ngIf="lastChange">
			<div class="pb-2">{{ 'time-tracking.log.changes-title' | translate }}:</div>

			<div class="mat-small">
				{{
					'time-tracking.log.changes-header'
						| translate
							: {
									changedAtDate: lastChange.changedAt | unixToMillis | date: 'shortDate',
									changedAtTime: lastChange.changedAt | unixToMillis | date: 'shortTime',
									changedBy: lastChange.changedBy
							  }
				}}:
			</div>

			<ul>
				<li class="mat-small">{{ lastChange.changes }}</li>
			</ul>
		</ng-container>
	</ng-container>

	<button (click)="onOpenChangeLog()" *ngIf="(numberOfChanges$ | async) > 1" mat-button color="accent">
		{{ 'time-tracking.log.more-changes' | translate: { numberOfChanges: (numberOfChanges$ | async) - 1 } }}
	</button>
</ng-container>
