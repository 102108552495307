<div [ngSwitch]="project.projectType" class="wrapper align-self-center">
	<ng-container *ngSwitchCase="ProjectType.FOLDER" [ngTemplateOutlet]="projectTypeFolder"></ng-container>
	<ng-container *ngSwitchCase="ProjectType.PROJECT" [ngTemplateOutlet]="projectTypeProject"></ng-container>
	<ng-container *ngSwitchDefault [ngTemplateOutlet]="projectTypeProject"></ng-container>
</div>

<ng-template #projectTypeFolder>
	<div class="folder-wrapper">
		<h1 class="folder-text btn-white-text">{{ project?.name.charAt(0).toUpperCase() }}</h1>
		<div [ngClass]="getFolderIndicatorColor(project?.colorTag)" class="folder-color"></div>
		<img class="folder-img" src="assets/images/icons/folder.png" alt="folder" />
	</div>
</ng-template>

<ng-template #projectTypeProject>
	<app-project-status-indicator
		[colorTagClass]="getTagClass(project?.colorTag)"
		[name]="project.name"
		[statusId]="project.statusId"
	></app-project-status-indicator>
</ng-template>
