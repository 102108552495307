import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from 'domain-entities';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '@constants/validators.constants';
import { ContactUI } from '../project-contacts-list.component';
import { cloneDeep } from 'lodash';

@Component({
	selector: 'app-project-add-edit-contact-form',
	templateUrl: './project-add-edit-contact-form.component.html',
	styleUrls: ['./project-add-edit-contact-form.component.scss'],
})
export class ProjectAddEditContactFormComponent implements OnInit {
	@Input() contactUI: ContactUI = null;
	@Output() close = new EventEmitter<void>();
	@Output() update = new EventEmitter<ContactUI>();
	projectContactEmailControl = this.fb.control('', [Validators.pattern(EMAIL_PATTERN)]);
	projectContactPhoneControl = this.fb.control('', [Validators.pattern(PHONE_NUMBER_PATTERN)]);
	projectContactForm = this.fb.group({
		name: ['', [Validators.required]],
		emails: this.fb.array([]),
		phones: this.fb.array([]),
	});

	get isEditing(): boolean {
		return !!this.contactUI?.contact;
	}

	get isAdding(): boolean {
		return !this.isEditing;
	}

	get projectContactEmailsControls(): UntypedFormArray {
		return this.projectContactForm.get('emails') as UntypedFormArray;
	}

	get projectContactPhonesControls(): UntypedFormArray {
		return this.projectContactForm.get('phones') as UntypedFormArray;
	}

	constructor(private readonly fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		if (this.isEditing) {
			if (this.contactUI?.contact?.emails) {
				this.contactUI?.contact.emails.forEach((_) => this.addEmailControl());
			} else {
				this.addEmailControl();
			}

			if (this.contactUI?.contact?.phones) {
				this.contactUI.contact.phones.forEach((_) => this.addPhoneControl());
			} else {
				this.addPhoneControl();
			}
			this.projectContactForm.patchValue(this.contactUI.contact);
		} else {
			this.addEmailControl();
			this.addPhoneControl();
		}
	}

	closeForm(): void {
		this.close.emit();
	}

	addEmailControl(): void {
		this.projectContactEmailsControls.push(cloneDeep(this.projectContactEmailControl));
	}

	addPhoneControl(): void {
		this.projectContactPhonesControls.push(cloneDeep(this.projectContactPhoneControl));
	}

	removeEmailControl(index: number): void {
		this.projectContactEmailsControls.removeAt(index);
	}

	removePhoneControl(index: number): void {
		this.projectContactPhonesControls.removeAt(index);
	}

	updateContact(newContact: Contact): void {
		newContact.emails = newContact.emails.filter(Boolean); // Removing empty entries
		newContact.phones = newContact.phones.filter(Boolean); // Removing empty entries

		const contact = { name: newContact.name };
		if (newContact.emails.length) {
			contact['emails'] = newContact.emails;
		}

		if (newContact.phones.length) {
			contact['phones'] = newContact.phones;
		}

		this.update.emit({ index: this.contactUI?.index, contact });
		this.closeForm();
	}
}
