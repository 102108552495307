import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as projectUnreadsActions from '@store/actions/project-unreads.actions';
import { StoreLoadingStatus } from '../entities/store-loading-status';
import { ProjectUnreadsState } from '@store/state/project-unreads.state';
import { keyBy } from 'lodash';

const adapter = createEntityAdapter<string>();
export const initialState: ProjectUnreadsState = adapter.getInitialState({
	loadingStatus: StoreLoadingStatus.INITIAL,
	keepCurrentProjectUnread: false,
});

const reducer = createReducer(
	initialState,
	on(projectUnreadsActions.updateProjectUnreads, (state, { projectUnreads }) => ({
		...state,
		loadingStatus: StoreLoadingStatus.LOADED,
		ids: projectUnreads,
		entities: keyBy(projectUnreads),
	})),

	on(projectUnreadsActions.clearProjectUnreadsState, (state) => {
		return adapter.removeAll(state);
	}),
	on(projectUnreadsActions.setKeepCurrentProjectUnread, (state) => {
		return { ...state, keepCurrentProjectUnread: true };
	}),
	on(projectUnreadsActions.clearKeepCurrentProjectUnread, (state) => {
		return { ...state, keepCurrentProjectUnread: false };
	}),
);

export const projectUnreadsSelectors = adapter.getSelectors();

// tslint:disable-next-line:typedef
export function projectUnreadsReducer(state: ProjectUnreadsState | undefined, action: Action) {
	return reducer(state, action);
}
