<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR4')"
	[ngClass]="getTagClass('COLOR4')"
	class="mr-2 mt-2 tag-green-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR1')"
	[ngClass]="getTagClass('COLOR1')"
	class="mr-2 mt-2 tag-red-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR2')"
	[ngClass]="getTagClass('COLOR2')"
	class="mr-2 mt-2 tag-orange-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR3')"
	[ngClass]="getTagClass('COLOR3')"
	class="mr-2 mt-2 tag-yellow-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR5')"
	[ngClass]="getTagClass('COLOR5')"
	class="mr-2 mt-2 tag-purple-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR6')"
	[ngClass]="getTagClass('COLOR6')"
	class="mr-2 mt-2 tag-blue-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR7')"
	[ngClass]="getTagClass('COLOR7')"
	class="mr-2 mt-2 tag-teal-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR8')"
	[ngClass]="getTagClass('COLOR8')"
	class="mr-2 mt-2 tag-pink-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR9')"
	[ngClass]="getTagClass('COLOR9')"
	class="mr-2 mt-2 tag-dirty-green-border color-tag"
></button>
<button
	mat-mini-fab
	type="button"
	[disabled]="!isOwnerOrSupervisor && project.id"
	(click)="changeActiveTag('COLOR10')"
	[ngClass]="getTagClass('COLOR10')"
	class="mr-2 mt-2 tag-royal-blue-border color-tag"
></button>
