import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { CompanyMemberRoleDirective } from './company-member-role.directive';

@NgModule({
	declarations: [CompanyMemberRoleDirective],
	imports: [SharedModule],
	exports: [CompanyMemberRoleDirective],
})
export class CompanyMemberRoleDirectiveModule {}
