<section class="notification-token-permission-denied-section d-flex flex-column align-items-center">
	<div class="mat-display-3 mb-4 text-center" [innerHTML]="'notification-token-permission.denied.heading' | translate"></div>

	<div class="mat-headline mb-4 font-weight-normal">
		<ng-container *ngIf="isSupportedBrowser">{{
			'notification-token-permission.denied.browser-supported.subheading' | translate
		}}</ng-container>
		<ng-container *ngIf="!isSupportedBrowser">{{
			'notification-token-permission.denied.browser-not-supported.subheading' | translate
		}}</ng-container>
	</div>

	<ul class="list-unstyled mb-5">
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.denied.point1' | translate }}
			</div>
		</li>
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.denied.point2' | translate }}
			</div>
		</li>
		<li class="orange-tick">
			<div class="font-weight-bold">
				{{ 'notification-token-permission.denied.point3' | translate }}
			</div>
		</li>
	</ul>

	<ng-container *ngIf="isSupportedBrowser">
		<div class="w-25 mb-5">
			<mat-divider></mat-divider>
		</div>

		<div class="mat-display-3 mb-4">
			{{ 'notification-token-permission.denied.how-to.heading' | translate }}
		</div>

		<div [ngSwitch]="browserDetectionService.getBrowserName()" class="instructions-container">
			<ng-container *ngSwitchCase="'chrome'">
				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.chrome.step1' | translate }}
				</div>

				<img alt="chrome-step-1" src="assets/images/notification-token-request/chrome-00.png" class="img-fluid mb-5" />

				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.chrome.step2' | translate }}
				</div>

				<img alt="chrome-step-2" src="assets/images/notification-token-request/chrome-01.png" class="img-fluid mb-5" />
			</ng-container>

			<ng-container *ngSwitchCase="'firefox'">
				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.firefox.step1' | translate }}
				</div>

				<img alt="ff-step-1" src="assets/images/notification-token-request/ff-00.png" class="img-fluid mb-5" />

				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.firefox.step2' | translate }}
				</div>

				<img alt="ff-step-2" src="assets/images/notification-token-request/ff-01.png" class="img-fluid mb-5" />

				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.firefox.step3' | translate }}
				</div>

				<img alt="ff-step-3" src="assets/images/notification-token-request/ff-02.png" class="img-fluid mb-5" />
			</ng-container>

			<ng-container *ngSwitchCase="'edge'">
				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.edge.step1' | translate }}
				</div>

				<img alt="edge-step-1" src="assets/images/notification-token-request/edge-00.png" class="img-fluid mb-5" />

				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.edge.step2' | translate }}
				</div>

				<img alt="edge-step-2" src="assets/images/notification-token-request/edge-01.png" class="img-fluid mb-5" />
			</ng-container>

			<ng-container *ngSwitchCase="'edge-chromium'">
				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.edge.step1' | translate }}
				</div>

				<img alt="edge-chromium-step-1" src="assets/images/notification-token-request/edge-00.png" class="img-fluid mb-5" />

				<div class="mat-body-2 mb-3">
					{{ 'notification-token-permission.denied.how-to.edge.step2' | translate }}
				</div>

				<img alt="edge-chromium-step-2" src="assets/images/notification-token-request/edge-01.png" class="img-fluid mb-5" />
			</ng-container>
		</div>
	</ng-container>
</section>
