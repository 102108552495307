import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

const LOADER = `
<span class="d-none button-loader">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23px" height="23px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="50" cy="50" fill="none" stroke="currentColor" stroke-width="10" r="40" stroke-dasharray="141.37166941154067 49.12388980384689">
    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
  </circle>
  </svg>
</span>
`;

@Directive({
	selector:
		// tslint:disable-next-line:directive-selector
		'[mat-button][loading], [mat-flat-button][loading], [mat-raised-button][loading], [mat-stroked-button][loading]',
})
export class ButtonLoaderDirective implements AfterViewInit {
	@HostBinding('class.loading') get loadingClass() {
		return this._loading;
	}

	_loading = false;
	@Input() set loading(newLoadingValue: boolean) {
		this._loading = newLoadingValue;
	}

	get loading(): boolean {
		return this._loading;
	}

	constructor(private readonly elmRef: ElementRef) {}

	ngAfterViewInit(): void {
		this.elmRef.nativeElement.insertAdjacentHTML('beforeend', LOADER);
	}
}
