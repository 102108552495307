<mat-dialog-content style="overflow: unset">
	<div class="container" style="margin-top: 15px">
		<form [formGroup]="employeeFormGroup">
			<em class="material-icons float-left" style="cursor: pointer" (click)="closeDialog()">close</em>
			<h1 class="text-center">{{ getTitle() | translate }}</h1>
			<hr />
			<div class="form-group row">
				<mat-form-field class="col-6">
					<input formControlName="name" type="text" matInput [placeholder]="'employee.name' | translate" />
					<em *ngIf="!isHimself() && !isNewMember()" matSuffix style="height: 24px; width: 24px" class="material-icons">lock</em>
					<mat-error *ngIf="employeeFormGroup.controls.name.errors">
						{{ 'error.characters' | translate }}
					</mat-error>
				</mat-form-field>
				<mat-form-field class="col-6">
					<input formControlName="lastname" type="text" matInput [placeholder]="'employee.lastname' | translate" />
					<em *ngIf="!isHimself() && !isNewMember()" matSuffix style="height: 24px; width: 24px" class="material-icons">lock</em>
					<mat-error *ngIf="employeeFormGroup.controls.lastname.errors?.pattern">
						{{ 'error.characters' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group row">
				<mat-form-field class="col-6">
					<input
						formControlName="email"
						type="email"
						matInput
						[placeholder]="'employee.email' | translate"
						trim
						[pattern]="emailPattern"
						email
					/>
					<em *ngIf="!isNewMember()" matSuffix style="height: 24px; width: 24px" class="material-icons">lock</em>
					<mat-error *ngIf="employeeFormGroup.controls.email.errors?.required">
						{{ 'error.required' | translate }}
					</mat-error>
					<mat-error *ngIf="employeeFormGroup.controls.email?.dirty && employeeFormGroup.controls.email?.invalid">
						{{ 'error.email.valid' | translate }}
					</mat-error>
				</mat-form-field>
				<mat-form-field class="col-6">
					<input type="text" matInput formControlName="jobTitle" [placeholder]="'employee.job' | translate" />
					<em
						*ngIf="!isOwner() || employeeFormGroup.controls.jobTitle.disabled"
						matSuffix
						style="height: 24px; width: 24px"
						class="material-icons"
						>lock</em
					>
				</mat-form-field>
			</div>

			<div class="form-group row">
				<mat-form-field class="col-6">
					<input formControlName="mobile" type="text" matInput [placeholder]="'employee.phone' | translate" />
					<em
						*ngIf="(!isHimself() && !isNewMember() && !isOwner()) || employeeFormGroup.controls.mobile.disabled"
						matSuffix
						style="height: 24px; width: 24px"
						class="material-icons"
						>lock</em
					>
				</mat-form-field>
				<mat-form-field *ngIf="hasAccepted() && isOwner()" class="col-6">
					<input formControlName="employeeId" type="text" matInput [placeholder]="'employee.externalId' | translate" trim />
				</mat-form-field>
			</div>
			<div class="row">
				<div class="col-6"></div>
				<div class="col-6">
					<label
						><strong>{{ 'employee.roles' | translate }}</strong></label
					>
					<br />
					<div
						class="my-auto btn-group mt-2 mb-4 pointer"
						data-toggle="buttons"
						[class.no-events]="isNewInvitationsFeatureEnabled && member?.inviteState === InviteState.INVITED"
					>
						<label class="btn btn-segmented theme-item pointer" (click)="setSelected('OWNER')" [ngClass]="getSelected('OWNER')">
							{{ 'OWNER' | translate }}
							<input style="display: none" type="radio" name="options" id="owner" autocomplete="off" />
						</label>
						<label class="btn btn-segmented theme-item pointer" (click)="setSelected('SUPERVISOR')" [ngClass]="getSelected('SUPERVISOR')">
							{{ 'SUPERVISOR' | translate }}
							<input style="display: none" type="radio" name="options" id="supervisor" autocomplete="off" />
						</label>
						<label class="btn btn-segmented theme-item pointer" (click)="setSelected('EMPLOYEE')" [ngClass]="getSelected('EMPLOYEE')">
							{{ 'EMPLOYEE' | translate }}
							<input style="display: none" type="radio" name="options" id="employee" autocomplete="off" />
						</label>
					</div>
					<em
						*ngIf="!canChangeRole() || (isNewInvitationsFeatureEnabled && member?.inviteState === InviteState.INVITED)"
						matSuffix
						style="height: 24px; width: 24px"
						class="material-icons"
						>lock</em
					>

					<div *ngIf="isSelected('OWNER')">
						<p class="mt-2">
							<strong>{{ 'employee.description.owner.title' | translate }}</strong>
						</p>
						<ul>
							<li>{{ 'employee.description.owner.one' | translate }}</li>
							<li>{{ 'employee.description.owner.two' | translate }}</li>
							<li>{{ 'employee.description.owner.three' | translate }}</li>
							<li>{{ 'employee.description.owner.four' | translate }}</li>
							<li>{{ 'employee.description.owner.five' | translate }}</li>
							<li>{{ 'employee.description.owner.six' | translate }}</li>
						</ul>
					</div>

					<div *ngIf="isSelected('SUPERVISOR')">
						<p class="mt-2">
							<strong>{{ 'employee.description.supervisor.title' | translate }}</strong>
						</p>
						<ul>
							<li>{{ 'employee.description.supervisor.one' | translate }}</li>
							<li>{{ 'employee.description.supervisor.two' | translate }}</li>
							<li>{{ 'employee.description.supervisor.three' | translate }}</li>
							<li>{{ 'employee.description.supervisor.four' | translate }}</li>
						</ul>
					</div>

					<div *ngIf="isSelected('EMPLOYEE')">
						<p class="mt-2">
							<strong>{{ 'employee.description.employee.title' | translate }}</strong>
						</p>
						<ul>
							<li>{{ 'employee.description.employee.one' | translate }}</li>
							<li>{{ 'employee.description.employee.two' | translate }}</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="mt-5 form-group row justify-content-center">
				<button
					type="submit"
					class="col-sm-8 col-md-5 col-lg-4 btn-white-text"
					color="accent"
					mat-flat-button
					[disabled]="employeeFormGroup.invalid || (isNewInvitationsFeatureEnabled && member?.inviteState === InviteState.INVITED)"
					*ngIf="isOwner() || isHimself()"
					(click)="save()"
				>
					{{ getSaveTitle() | translate }}
				</button>
			</div>
		</form>
	</div>
</mat-dialog-content>
