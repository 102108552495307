import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogConfig } from './confirm-dialg.types';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
	checkboxValue = false;
	actionInProgress = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly config: ConfirmDialogConfig,
		private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
	) {}

	async onClose(value: any): Promise<void> {
		if (this.actionInProgress) {
			return;
		}
		if (this.config.actionPerformedBeforeClosing) {
			this.actionInProgress = true;
			try {
				await this.config.actionPerformedBeforeClosing(value);
			} catch (error) {
				this.dialogRef.close(this.config.actionErrorValue);
				return;
			}
		}
		let result: any;
		if (this.config.showCheckbox) {
			result = {
				buttonValue: value,
				checkboxValue: this.checkboxValue,
			};
		} else {
			result = value;
		}
		this.dialogRef.close(result);
	}
}
