import { CompanyFile, File, FileType, Project } from 'domain-entities';
import moment from 'moment';
import { ProjectFile, ProjectFileCategory } from '@shared/models/project-file.model';
import {
	isUserInternalOrExternalProjectEmployee,
	isUserProjectAdmin,
} from '@shared/functions/project/project.functions';
import { is48HoursPassed } from '@shared/shared.utils';

export function transformCompanyFilesToProjectFiles(
	companyFiles: CompanyFile[],
): Array<ProjectFile> {
	const mappedCompanyTemplates: { [id: string]: Partial<ProjectFile> } = {
		'company-folder': {
			name: 'Vorlagen',
			noOfFiles: 0,
			fileCategory: 'company-file',
			fileType: FileType.FOLDER,
			parentId: null,
			id: 'company-folder',
		},
	};

	companyFiles.forEach((companyFile) => {
		const noOfFiles = mappedCompanyTemplates[companyFile.id]
			? mappedCompanyTemplates[companyFile.id].noOfFiles
			: 0;

		if (companyFile.folderId) {
			let noOfFilesInFolder = mappedCompanyTemplates[companyFile.folderId]
				? mappedCompanyTemplates[companyFile.folderId].noOfFiles
				: 0;

			mappedCompanyTemplates[companyFile.folderId] = {
				...mappedCompanyTemplates[companyFile.folderId],
				noOfFiles: ++noOfFilesInFolder,
			};
		} else {
			++mappedCompanyTemplates['company-folder'].noOfFiles;
		}

		mappedCompanyTemplates[companyFile.id] = {
			...mappedCompanyTemplates[companyFile.id],
			name: companyFile.name,
			id: companyFile.id,
			fileCategory: 'company-file',
			fileType: companyFile.type,
			fileSize: companyFile.size,
			createdTimestamp: companyFile.creationTimestamp,
			noOfFiles: noOfFiles,
			parentId: companyFile.folderId || 'company-folder',
			base: companyFile,
		};
	});

	return Object.values(mappedCompanyTemplates) as Array<ProjectFile>;
}

export function transformFilesToProjectFiles(
	files: File[],
	currentProject: Project,
	userId: string,
): Array<ProjectFile> {
	const mappedFiles: { [id: string]: Partial<ProjectFile> } = {};
	if (!files) {
		return [];
	}
	files.forEach((file) => {
		const noOfFiles = mappedFiles[file.id] ? mappedFiles[file.id].noOfFiles : 0;

		if (file.folderId) {
			let noOfFilesInFolder = mappedFiles[file.folderId] ? mappedFiles[file.folderId].noOfFiles : 0;

			mappedFiles[file.folderId] = {
				...mappedFiles[file.folderId],
				noOfFiles: ++noOfFilesInFolder,
			};
		}

		mappedFiles[file.id] = {
			...mappedFiles[file.id],
			name: file.name,
			id: file.id,
			fileCategory: getFileCategory(file),
			fileType: file.type,
			fileSize: file.size,
			createdTimestamp: file.creationTimestamp,
			noOfFiles: noOfFiles,
			parentId: file.folderId || null,
			creatorId: file.creatorId,
			lastModifiedTimestamp: file.lastModifiedTimestamp,
			meta: file.meta,
			permissions: file.permissions,
			share: file.share,
			canDelete: canUserDeleteFile(userId, currentProject, file),
			base: file,
		};
	});

	const mappedFilesList = Object.values(mappedFiles) as Array<ProjectFile>;

	/**
	 * If there are files inside of folders that the user does not have permissions to,
	 * then an entry for that folder is created but never populated. This results in stump object causing issues further down.
	 * Therefore, we filter them out.
	 */
	return mappedFilesList.filter((file) => !!file.id);
}

function canUserDeleteFile(userId: string, currentProject: Project, file: File): boolean {
	if (isUserProjectAdmin(userId, currentProject, 'id')) {
		return true;
	}

	// Employee can delete their own files within 48 hours of creation
	return (
		file.creatorId === userId &&
		isUserInternalOrExternalProjectEmployee(userId, currentProject, 'id') &&
		!is48HoursPassed(file.creationTimestamp)
	);
}

function getFileCategory(file: File): ProjectFileCategory {
	if (!file.share) {
		return 'project-file';
	}

	return moment.unix(file.share.expirationTimestamp).diff(moment(), 'days') >= 0
		? 'shared-file'
		: 'project-file';
}
