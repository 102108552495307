import { createAction, props } from '@ngrx/store';

export const initBrowserMonitoringAction = createAction('[Browser] Init browser monitoring');

export const setCanIndexDbBeMutatedAction = createAction(
	'[Browser] Set can IndexDb be Mutated',
	props<{ indexDbCanBeMutated: boolean }>(),
);

export const setNotificationPermissionAction = createAction(
	'[Browser] Set notification permission',
	props<{ permission: NotificationPermission }>(),
);

export const notificationPermissionChangedAction = createAction(
	'[Browser] Notification permission changed',
	props<{ permission: NotificationPermission }>(),
);
