<section class="tracked-time-work-type-button-section">
	<button (click)="openWorkTypeList.emit()" [disabled]="disabled" class="work-type-selection-button w-100 h-100" mat-icon-button>
		<div class="d-flex w-100">
			<div class="align-items-center d-flex">
				<img alt="" class="icon-image d-inline-flex mr-3 img-fluid" src="assets/images/icons/time-tracking/worktype.svg" />
				<span class="font-weight-normal">{{ 'time-tracking-work-type-button.work-type' | translate }}</span>
			</div>
			<div
				*ngVar="trackedTimeWorkTypeButtonService.workTypeEntities$ | async as workTypeEntities"
				class="align-items-center d-flex ml-auto pointer"
			>
				<span *ngIf="trackedTimeWorkTypeButtonService.getSelectedWorkTypeId()" class="font-weight-bold">
					{{ workTypeEntities[trackedTimeWorkTypeButtonService.getSelectedWorkTypeId()]?.name || fallbackWorkTypeName }}
				</span>

				<mat-icon [size]="25" data-cy="work-type-time-entry">chevron_right</mat-icon>
			</div>
		</div>
	</button>
</section>
<mat-error class="work-type-error" *ngIf="isFormValidated && isInvalid" data-cy="work-type-missing-error">{{
	'time-tracking-work-type-button.work-type-error' | translate
}}</mat-error>
