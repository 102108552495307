import { createAction, props } from '@ngrx/store';
import { ExternalChatUserState } from 'domain-entities';

export const loadUserStateAction = createAction('[External Chat] Load user states');

export const setUserStateAction = createAction(
	'[External Chat] Set user states',
	props<{ userState: ExternalChatUserState }>(),
);

export const deleteUserStateAction = createAction('[External Chat] Delete user states');

export const clearUserStateAction = createAction('[External Chat] Clear user states');
