import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import * as fromCompanyProjectsState from '../state/company-projects.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromCompanyProjectsActions from '../actions/company-projects.actions';
import { Project } from 'domain-entities';

export const companyProjectsEntityAdapter: EntityAdapter<Project> = createEntityAdapter<Project>();

const companyProjectsInitialValue: fromCompanyProjectsState.CompanyProjectsState =
	companyProjectsEntityAdapter.getInitialState({
		loaded: false,
	});

const companyProjectsReducerCreator = createReducer<fromCompanyProjectsState.CompanyProjectsState>(
	companyProjectsInitialValue,
	on(fromCompanyProjectsActions.companyProjectsChanged, (state, { payload: projects }) => {
		if (projects.changeType === 'created') {
			state = companyProjectsEntityAdapter.upsertMany(projects.entities, state);
		}
		if (projects.changeType === 'deleted') {
			const idsToRemove = projects.entities.map((p) => p.id);
			state = companyProjectsEntityAdapter.removeMany(idsToRemove, state);
		}
		if (projects.changeType === 'updated') {
			const updates = projects.entities.map((project) => ({ id: project.id, changes: project }));
			state = companyProjectsEntityAdapter.updateMany(updates, state);
		}
		return { ...state, loaded: true };
	}),
	on(fromCompanyProjectsActions.subscribeCompanyProjectsFailed, (state) => {
		console.log('Add Error Logging here');
		return { ...state };
	}),
	on(fromCompanyProjectsActions.clearCompanyProjects, (state) => {
		state = companyProjectsEntityAdapter.removeAll(state);
		return { ...state, loaded: false };
	}),
);

export function companyProjectsReducer(
	state: fromCompanyProjectsState.CompanyProjectsState,
	action: Action,
): fromCompanyProjectsState.CompanyProjectsState {
	return companyProjectsReducerCreator(state, action);
}
