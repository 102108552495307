import { Injectable } from '@angular/core';
import { TaskComment } from 'domain-entities';
import { TaskCommentsConnector } from '@shared/firebase/connectors/firestore/collections/task-comments/task-comments.connector';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaskEditCommentsService {
	constructor(public readonly taskCommentsConnector: TaskCommentsConnector) {}

	getTaskComments(taskId: string): Observable<TaskComment[]> {
		return this.taskCommentsConnector.getTaskComments(taskId);
	}

	createTaskComment(taskComment: TaskComment): Promise<void> {
		return this.taskCommentsConnector.createTaskComment(taskComment);
	}
}
