import { Component, EventEmitter, Output } from '@angular/core';
import { TrackedTimesChangesStore } from '@modules/shared/components/time-tracking-add-edit/store/tracked-times-changes.store';
import { groupBy, keys, orderBy, reverse } from 'lodash';
import { map } from 'rxjs';
import { fromUnixTime, startOfDay } from 'date-fns';
import { TrackedTimeChange } from '@injectables/connectors/audit-log.connector';

@Component({
	selector: 'app-tracked-time-all-changes',
	templateUrl: './tracked-time-all-changes.component.html',
	styleUrls: ['./tracked-time-all-changes.component.css'],
})
export class TrackedTimeAllChangesComponent {
	@Output() closePanel = new EventEmitter();
	changes$ = this.store.allChanges$.pipe(
		map((changes) => {
			const grouped = groupBy(reverse([...changes]), (change) =>
				startOfDay(fromUnixTime(change.changedAt)).getTime(),
			);
			const nestedChanges: TrackedTimeChange[][] = [];
			for (const groupKey of orderBy(keys(grouped), null, 'desc')) {
				nestedChanges.push(grouped[groupKey]);
			}
			return nestedChanges;
		}),
	);

	constructor(private readonly store: TrackedTimesChangesStore) {}
}
