<section class="theme-toggler-section d-flex">
	<div class="my-auto btn-group mr-4 pointer" data-toggle="buttons">
		<label
			class="btn btn-secondary theme-item pointer"
			(click)="setSelected(Theme.DARK)"
			[ngClass]="(theme$ | async) === Theme.DARK ? 'active background-active' : 'background-inactive'"
		>
			<input class="d-none" type="radio" name="options" autocomplete="off" />{{ 'settings.theme.dark' | translate }}
		</label>
		<label
			class="btn btn-secondary theme-item pointer"
			(click)="setSelected(Theme.LIGHT)"
			[ngClass]="(theme$ | async) === Theme.LIGHT ? 'active background-active' : 'background-inactive'"
		>
			<input class="d-none" type="radio" name="options" autocomplete="off" />{{ 'settings.theme.light' | translate }}
		</label>
	</div>
</section>
