import { Injectable } from '@angular/core';
import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Invitation, isInvitation } from 'domain-entities';
import { firstValueFrom, Observable, of } from 'rxjs';
import { clearEntities, EntityChanges } from '@craftnote/shared-utils';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

export type InvitationRequiredOnly = Pick<
	Invitation,
	'resourceType' | 'resourceId' | 'inviteeEmail' | 'expirationDate' | 'context'
>;

const INVITATION_REQUIRED_FIELDS: RequiredKey<Invitation>[] = [
	'id',
	'resourceType',
	'resourceId',
	'creationDate',
	'creatorId',
	'inviteeEmail',
	'state',
];

@Injectable({
	providedIn: 'root',
})
export class InvitationsConnector {
	private static COLLECTION_NAME = 'invitations';

	constructor(
		private readonly connector: FirestoreConnector,
		private fireFunctions: AngularFireFunctions,
	) {}

	getOpenInvitationOfUser(userEmail: string): Observable<Invitation> {
		const condition: DataQueryCondition<Invitation>[] = [
			{
				field: 'resourceType',
				operator: '==',
				value: 'company',
			},
			{
				field: 'state',
				operator: 'in',
				value: ['open', 'read'],
			},
			{
				field: 'inviteeEmail',
				operator: '==',
				value: userEmail,
			},
		];

		return this.connector
			.watchDocuments<Invitation>(InvitationsConnector.COLLECTION_NAME, condition, isInvitation)
			.pipe(map((invitations) => invitations[0] || null));
	}

	async createInvitation(invite: InvitationRequiredOnly): Promise<void> {
		const invitationWithResourceType: InvitationRequiredOnly = {
			...invite,
		};
		await firstValueFrom(
			this.fireFunctions.httpsCallable('createInvitation')(invitationWithResourceType),
		);
	}

	async deleteInvitation(id: string): Promise<void> {
		await this.connector.deleteDocument(InvitationsConnector.COLLECTION_NAME, id);
	}

	async updateDocumentPartial(id: string, invitation: Partial<Invitation>): Promise<void> {
		await this.connector.updateDocumentPartial<Invitation>(
			InvitationsConnector.COLLECTION_NAME,
			id,
			invitation,
			INVITATION_REQUIRED_FIELDS,
		);
	}

	getInvitationById(id: string): Promise<Invitation> {
		return this.connector.getDocument<Invitation>(
			InvitationsConnector.COLLECTION_NAME + '/' + id,
			isInvitation,
		);
	}

	watch(companyId: string): Observable<EntityChanges<Invitation>> {
		if (!companyId) {
			return of(clearEntities);
		}

		const condition: DataQueryCondition<Invitation>[] = [
			{
				field: 'resourceId',
				operator: '==',
				value: companyId,
			},
			{
				field: 'resourceType',
				operator: '==',
				value: 'company',
			},
			{
				field: 'state',
				operator: 'in',
				value: ['open', 'read', 'declined'],
			},
		];

		return this.connector.watchDocumentsChanges<Invitation>(
			InvitationsConnector.COLLECTION_NAME,
			condition,
			isInvitation,
		);
	}
}
