import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { TaskFileService } from '@injectables/services/task-file/task-file.service';
import { TaskFile } from 'domain-entities';
import { BaseFileService } from '@injectables/services/base-file.service';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { BasicSnackbarComponent } from '../../notification-snackbar/basic-snackbar/basic-snackbar.component';
import { TrackingService } from '@injectables/services/tracking.service';
import { TasksFileCreatedEventBuilder } from '@generated/events/TasksEvents.generated';
import { PopupDialogService } from '@injectables/services/popup-dialog.service';
import { shareReplayOne } from '@craftnote/shared-utils';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';

export interface TaskEditFilesUI {
	taskFile: TaskFile;
	isPreviewFileType: boolean;
	fileExt: string;
	previewURL: Observable<string>;
}

@Component({
	selector: 'app-task-edit-files',
	templateUrl: './task-edit-files.component.html',
	styleUrls: ['./task-edit-files.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditFilesComponent implements OnChanges {
	@Input() taskId: string = null;
	@Input() projectId: string = null;
	@Output('openTaskEditDetails') openTaskEditDetailsEmitter = new EventEmitter();
	taskId$ = new BehaviorSubject(null);
	taskEditFilesUI$: Observable<TaskEditFilesUI[]> = this.taskId$.pipe(
		filter<string>(Boolean),
		switchMap((id) => this.taskFileService.getTaskFiles(id)),
		delay(1500), // Its a hack, else if image is uploaded then previewURL does not comes with token. So we have to wait for token
		map((taskFiles) =>
			taskFiles.map((taskFile) => {
				const taskFileUI: TaskEditFilesUI = {
					taskFile,
					isPreviewFileType: this.baseFileService.isPreviewFileType(taskFile),
					fileExt: this.baseFileService.getFileExtension(taskFile.name),
					previewURL: this.taskFileService
						.getTaskFileUrl(taskFile, true)
						.pipe(catchError((_) => of('/assets/images/icons/corrupted-file.png'))),
				};
				return taskFileUI;
			}),
		),
		tap((taskEditFilesUI) => {
			this.taskEditFilesUI = taskEditFilesUI;
		}),
		shareReplayOne(),
	);
	taskEditFilesUI: TaskEditFilesUI[] = [];

	constructor(
		private readonly taskFileService: TaskFileService,
		private readonly baseFileService: BaseFileService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly trackingService: TrackingService,
		private readonly popupDialogService: PopupDialogService,
	) {}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.taskId?.currentValue) {
			this.taskId$.next(changes.taskId.currentValue);
		}
	}

	openTaskEditDetails(): void {
		this.openTaskEditDetailsEmitter.emit();
	}

	async deleteTaskFile(id: string): Promise<void> {
		const canDelete = await this.confirmDialogService
			.open({
				title: this.translateService.instant('task-edit-attachments.delete-file-dialog.title'),
				message: this.translateService.instant('task-edit-attachments.delete-file-dialog.message'),
				primaryButtonText: this.translateService.instant(
					'task-edit-attachments.delete-file-dialog.yes',
				),
				secondaryButtonText: this.translateService.instant(
					'task-edit-attachments.delete-file-dialog.no',
				),
				primaryButtonColor: 'warn',
				showCrossBtn: false,
			})
			.afterClosed()
			.toPromise();
		if (canDelete) {
			await this.taskFileService.deleteTaskFile(id);
			this.notificationSnackbarService.show(BasicSnackbarComponent, {
				componentTypes: {
					description: this.translateService.instant('files-section.messages.delete.success'),
					icon: 'done',
				},
				level: 1,
			});
		}
	}

	viewFile(id: string): void {
		const entities: TaskFile[] = this.taskEditFilesUI
			.filter((taskEditFileUI) => taskEditFileUI.isPreviewFileType)
			.map((taskEditFileUI) => taskEditFileUI.taskFile);
		const position = entities.findIndex((taskFile) => taskFile.id === id);
		this.popupDialogService.openPopupDialog(entities, position, false);
	}

	downloadFile(taskFile: TaskFile): void {
		this.taskFileService.getDownloadUrl(taskFile).then((result) => {
			this.baseFileService.downloadFile(result, taskFile.name);
		});
	}

	async addTaskFile(event: Event): Promise<void> {
		for (const file of (event.target as any).files) {
			const id = uuid();
			const timestamp = moment().unix();
			const taskFile: TaskFile = {
				id: id,
				projectId: this.projectId,
				creationTimestamp: timestamp,
				lastModifiedTimestamp: timestamp,
				name: this.baseFileService.getFilenameWithLowercaseExtension(file.name),
				size: file.size,
				taskId: this.taskId,
				type: this.taskFileService.getFileType(file.name),
			};

			await this.taskFileService.createTaskFile(taskFile, file);
			await this.trackingService.trackEvent(
				new TasksFileCreatedEventBuilder({
					projectId: this.projectId,
					source: 'storage',
					mimeType: file.type,
				}),
			);
		}
		(event.target as any).value = '';
	}
}
