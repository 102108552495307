<div class="project-dialog">
	<div class="d-flex justify-content-between pt-3 project-dialog__header">
		<div *ngIf="selectedFiles.length === 1" class="flex-grow-1">
			<div class="project-dialog__title mb-2" data-cy="forward-other-chat-header">
				{{ 'files-section.dialog.copy-to-other-folder.send-single-copy' | translate }}
			</div>
			<div class="d-flex align-items-center">
				<app-files-section-icon [file]="selectedFiles[0]" class="mr-2"></app-files-section-icon>
				<div class="flex-grow-1 font-weight-bold project-dialog__file-info">
					<div class="text-truncate">
						{{ selectedFiles[0].name }}
					</div>
					<div class="project-dialog__file-size">
						<span *ngIf="selectedFiles[0].createdTimestamp"> {{ selectedFiles[0].createdTimestamp | readableDate }} - </span>
						<span>{{ selectedFiles[0].fileSize | bytes }}</span>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="selectedFiles.length > 1" class="font-weight-bold">
			{{ 'files-section.dialog.copy-to-other-folder.send-multiple-copy' | translate: { fileLength: selectedFiles.length } }}
		</div>
		<mat-icon (click)="closeWithoutSaving()" *ngIf="!isLoading" class="pointer">close</mat-icon>
	</div>
	<div class="font-weight-bold my-2">
		{{ 'files-section.dialog.copy-to-other-folder.to' | translate }}
	</div>
	<mat-dialog-content>
		<div *ngIf="projectDialogFilter$ | async as projectsFilter" class="project-dialog__content-container">
			<div>
				<mat-form-field class="w-100">
					<mat-label>{{ 'dashboard.timeTracking.filters.search' | translate }}</mat-label>
					<input #projectsSearch (keyup)="onProjectSearchChange(projectsSearch.value)" autocomplete="off" matInput type="text" />
					<mat-icon matSuffix data-cy="search-project-copy-dialog">search</mat-icon>
				</mat-form-field>
			</div>
			<div class="project-items-container">
				<a
					(click)="goBack($event)"
					*ngIf="selectedParentProject$ | async as selectedParentProject"
					class="text-truncate d-flex align-items-center pointer mb-2"
					href=""
				>
					<mat-icon class="mr-2">arrow_back</mat-icon>
					<div class="text-truncate">
						{{ selectedParentProject.name }}
					</div>
				</a>
				<div *ngFor="let companyProject of projects$ | async" class="w-100 project-items__item">
					<app-copy-to-project-item
						(folderSelected)="onFolderSelected(companyProject.id)"
						(selected)="onProjectSelected(companyProject.id)"
						[isSelected]="companyProject.id === projectsFilter.selectedProject"
						[project]="companyProject"
						[showParent]="!!projectsSearch.value && !projectsFilter.selectedParentProjectId"
						data-cy="selected-destination-project"
					>
					</app-copy-to-project-item>
				</div>

				<div *ngIf="(projects$ | async)?.length === 0">
					{{ 'dashboard.timeTracking.filters.resultsNotFound' | translate }}
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<div class="d-flex justify-content-center w-100 my-2">
			<button
				(click)="copyToSelectedProject()"
				[disabled]="!(projectDialogFilter$ | async)?.selectedProject || isLoading"
				color="accent"
				mat-flat-button
				data-cy="forward-confirm-button"
			>
				<span *ngIf="!isLoading; else loading">
					{{ 'files-section.dialog.copy-to-other-folder.confirm' | translate }}
				</span>
			</button>
		</div>
	</mat-dialog-actions>
</div>

<ng-template #loading>
	<div class="spinner-container">
		<mat-spinner color="accent" [diameter]="26"></mat-spinner>
	</div>
</ng-template>
