import { createEntityAdapter, IdSelector } from '@ngrx/entity';
import { ProfileTracker } from 'domain-entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromProfileTracker from '../actions/profile-tracker.actions';
import { updateEntityState } from './functions/update-entity-state.functions';
import { StoreLoadingStatus } from '../entities/store-loading-status';
import { ProfileTrackerState } from '../state/profile-tracker.state';

const PRIMARY_KEY_FIELD = 'type';
const idSelector: IdSelector<ProfileTracker> = (profileTracker) =>
	profileTracker[PRIMARY_KEY_FIELD];
const adapter = createEntityAdapter<ProfileTracker>({ selectId: idSelector });
export const initialState: ProfileTrackerState = adapter.getInitialState({
	loadingStatus: StoreLoadingStatus.INITIAL,
});

const reducer = createReducer(
	initialState,
	on(fromProfileTracker.updateTrackerState, (state, payload) => {
		return {
			...updateEntityState<ProfileTracker>(adapter, state, payload.changes, PRIMARY_KEY_FIELD),
			loadingStatus: StoreLoadingStatus.LOADED,
		};
	}),

	on(fromProfileTracker.clearProfileTrackerState, (state) => {
		return adapter.removeAll(state);
	}),
);

export const profileTrackerSelectors = adapter.getSelectors();

// tslint:disable-next-line:typedef
export function profileTrackerReducer(state: ProfileTrackerState | undefined, action: Action) {
	return reducer(state, action);
}
