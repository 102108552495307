import { createAction, props } from '@ngrx/store';
import { EntityChanges } from '@craftnote/shared-utils';
import { ProfileTracker } from 'domain-entities';

export const loadTrackerState = createAction('[Profile Tracker] Load Tracker State');

export const updateTrackerState = createAction(
	'[Profile Tracker] Update Tracker State',
	props<{ changes: EntityChanges<ProfileTracker> }>(),
);

export const clearProfileTrackerState = createAction('[Profile Tracker] Clear Tracker State');
