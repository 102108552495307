import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import {
	initLoadSubscriptionEntities,
	loadTrailSubscriptionAction,
	setSubscriptionEntities,
	setTrialSubscriptionAction,
} from '../actions/subscription.actions';
import { SubscriptionService } from '@injectables/services/subscription/subscription.service';
import { combineLatest, of } from 'rxjs';
import { selectCompanyId, selectUserId } from '../selectors/app.selectors';
import { SubscriptionsConnector } from '@shared/firebase/connectors/firestore/collections/subscriptions/subscriptions.connector';
import { EntityChanges } from '@craftnote/shared-utils';
import { Subscription } from 'domain-entities';

@Injectable()
export class SubscriptionEffects implements OnInitEffects {
	constructor(
		private readonly store: Store<AppState>,
		private readonly actions$: Actions,
		private readonly subscriptionService: SubscriptionService,
		private readonly subscriptionsConnector: SubscriptionsConnector,
	) {}

	loadTrailSubscription = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTrailSubscriptionAction),
			switchMap(() => {
				return this.subscriptionService.getTrialSubscription().pipe(
					map((trialSubscription) => {
						if (trialSubscription) {
							return setTrialSubscriptionAction({ trialSubscription });
						} else {
							return setTrialSubscriptionAction({ trialSubscription: null });
						}
					}),
				);
			}),
		),
	);

	loadSubscriptionsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(initLoadSubscriptionEntities),
			switchMapTo(
				combineLatest([this.store.select(selectUserId), this.store.select(selectCompanyId)]),
			),
			switchMap(([userId, companyId]) => {
				if (!(userId && companyId)) {
					return of<EntityChanges<Subscription>>(null);
				}
				return this.subscriptionsConnector.loadSubscriptions(companyId);
			}),
			map((changes) => {
				if (!changes) {
					return setSubscriptionEntities({ changes: { entities: [], changeType: 'remove' } });
				}
				return setSubscriptionEntities({ changes });
			}),
		),
	);

	ngrxOnInitEffects(): Action {
		return initLoadSubscriptionEntities();
	}
}
