import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-approve-dialog',
	templateUrl: './approve-dialog.component.html',
	styleUrls: ['./approve-dialog.component.scss'],
})
export class ApproveDialogComponent {
	isFile: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
		this.isFile = this.data.isFile;
	}
}
