<div class="subscription-overview-element">
	<ng-template [ngIf]="hasRightHandContent" [ngIfElse]="directContent">
		<div>
			<ng-content select="app-dialog-line-left"></ng-content>
		</div>
		<div class="subscription-overview-right-side wiz-label-color">
			<ng-container *ngTemplateOutlet="rightHandContent"></ng-container>
		</div>
	</ng-template>
</div>
<ng-template #rightHandContent>
	<ng-content select="app-dialog-line-right"></ng-content>
</ng-template>
<ng-template #directContent>
	<ng-content></ng-content>
</ng-template>
