import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotificationType, ResourceType } from 'domain-entities';
import { EMPTY, from, Observable, switchMap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export interface Notification {
	id: string;
	timestamp: number;
	type: NotificationType;
	resourceType: ResourceType;
	resourceName: string;
	resourcePath?: string;
	title: string;
	body: string;
	isUnread: boolean;
}

export interface NotificationContinuation {
	startAfter?: string;
	limit?: number;
}

export interface NotificationFilter {
	time?: {
		since?: number;
		until?: number;
	};
	resourceTypes?: ResourceType[];
	unread?: boolean;
}

interface ListNotificationRequestData {
	locale: string;
	continuation?: NotificationContinuation;
	filter?: NotificationFilter;
}

export interface ListNotificationResponseData {
	data: Notification[];
	startAfter: string;
	counts: {
		unread: number;
	};
}

interface MarkNotificationRequestData {
	filter?: {
		time?: {
			since?: number;
			until?: number;
		};
		resourceTypes?: ResourceType[];
	};
	notificationIds?: string[];
	markAs: 'read';
}

interface MarkNotificationResponseData {
	success: boolean;
}

@Injectable({ providedIn: 'root' })
export class NotificationsConnector {
	constructor(
		private readonly angularFireFunctions: AngularFireFunctions,
		private readonly angularFireAuth: AngularFireAuth,
		@Inject(LOCALE_ID) private readonly locale: string,
	) {}

	private async checkIfTokenIsValid(): Promise<boolean> {
		try {
			const currentUser = await this.angularFireAuth.currentUser;
			const token = await currentUser.getIdToken();
			return !!token;
		} catch (error) {
			console.error('Notification Connector: Token is not valid', error);
			return false;
		}
	}

	getNotifications(
		continuation: NotificationContinuation = {},
		filter: NotificationFilter = {},
	): Observable<ListNotificationResponseData> {
		return from(this.checkIfTokenIsValid()).pipe(
			switchMap((isTokenValid) => {
				if (!isTokenValid) {
					return EMPTY;
				}
				return this.angularFireFunctions.httpsCallable<
					ListNotificationRequestData,
					ListNotificationResponseData
				>('listNotifications')({
					locale: this.locale,
					...{ continuation, filter },
				});
			}),
		);
	}

	markNotificationsAsRead(
		request: MarkNotificationRequestData,
	): Observable<MarkNotificationResponseData> {
		return this.angularFireFunctions.httpsCallable<
			MarkNotificationRequestData,
			MarkNotificationResponseData
		>('markNotifications')(request);
	}
}
