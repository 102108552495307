import { Observable } from 'rxjs';
import { TradeOption } from 'domain-entities';
import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';

@Injectable({
	providedIn: 'root',
})
export class TradeOptionsService {
	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	getAllTradeOptions(): Observable<TradeOption[]> {
		return this.firestoreConnector.watchDocuments<TradeOption>('tradeOptions', []);
	}
}
