<ng-template #debugDialogRef>
	<div *ngFor="let field of copyFields$ | async" class="d-flex align-items-center pb-3">
		<span class="pr-1">{{ field.label }}:</span>
		<span>
			<a class="text-decoration-underline" *ngIf="field.linkTarget; else valueSpan" [href]="field.linkTarget" target="_blank">{{
				field.value
			}}</a>
			<ng-template #valueSpan>
				<span [ngClass]="{ 'truncate-token': field.label === 'JWT Token' }">
					{{ field.value }}
				</span>
			</ng-template>
		</span>
		<span (click)="copyToClipboard(field.value)" class="copy-icon pl-2"><mat-icon>content_copy</mat-icon> </span>
	</div>
	<div>
		<ngx-json-viewer [expanded]="false" [json]="store | async"></ngx-json-viewer>
	</div>
</ng-template>
