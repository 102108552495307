import { EntityState, EntityStateAdapter } from '@ngrx/entity/src/models';
import { EntityChanges } from '@craftnote/shared-utils';
import { EntityStore } from '@store/entities/entity-store';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

export function updateEntityState<T>(
	adapter: EntityStateAdapter<T>,
	state: EntityState<T>,
	changes: EntityChanges<T>,
	key = 'id',
): EntityState<T> {
	if (changes.changeType === 'deleted' || changes.changeType === 'updated') {
		state = adapter.removeMany(
			changes.entities.map((entity) => entity[key]),
			state,
		);
	}
	if (changes.changeType === 'updated' || changes.changeType === 'created') {
		state = adapter.addMany(changes.entities, state);
	}
	if (changes.changeType === 'set') {
		state = adapter.setAll(changes.entities, state);
	}
	if (changes.changeType === 'remove') {
		state = adapter.removeAll(state);
	}
	return state;
}

export function updateEntityStore<T>(
	adapter: EntityStateAdapter<T>,
	storeState: EntityStore<T>,
	changes: EntityChanges<T>,
	key = 'id',
): EntityStore<T> {
	const state = {
		...storeState,
		loadingStatus:
			changes.changeType === 'remove' ? StoreLoadingStatus.LOADING : StoreLoadingStatus.LOADED,
	};
	return updateEntityState<T>(adapter, state, changes, key) as EntityStore<T>;
}
