import { Directive, HostBinding, Input, OnChanges } from '@angular/core';

// FIXME:: I think we can use [style.d-none]
@Directive({
	selector: '[appShowConditional]',
})
export class ShowConditionalDirective implements OnChanges {
	private originalDisplay: string;
	@Input() appShowConditional: boolean;
	@HostBinding('style.display')
	display;

	ngOnChanges(): void {
		if (!this.originalDisplay) {
			this.originalDisplay = this.display || 'revert';
		}
		if (!this.appShowConditional) {
			this.display = 'none';
		} else {
			this.display = this.originalDisplay;
		}
	}
}
