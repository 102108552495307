import { Component, Input } from '@angular/core';
import { Message, MessageType } from 'domain-entities';
import { ChatMessageService } from '@injectables/services/chat/chat-message.service';
import { FileDocument } from '@craftnote/shared-utils';

@Component({
	selector: 'app-reply-message',
	templateUrl: './reply-message.component.html',
	styleUrls: ['./reply-message.component.scss'],
})
export class ReplyMessageComponent {
	MessageType = MessageType;

	@Input()
	replyMessage: Message;

	@Input()
	file: FileDocument;

	constructor(public chatMessageService: ChatMessageService) {}
}
