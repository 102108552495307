import { Injectable } from '@angular/core';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { PaywallDialogConfig } from './paywall-dialog.types';
import { PaywallDialogComponent } from '@modules/shared/components/paywall-dialog/paywall-dialog.component';

@Injectable()
export class PaywallDialogService {
	private paywallDialogDefaultConfig: PaywallDialogConfig = {
		title: '',
		message: '',
		messageList: [],
		primaryButtonText: 'Yes',
		secondaryButtonText: 'No',
		primaryButtonColor: 'primary',
		secondaryButtonColor: undefined,
		primaryButtonValue: true,
		secondaryButtonValue: false,
		showCrossBtn: true,
		showSecondaryButton: false,
		showPrimaryButton: true,
	};

	constructor(private readonly dialog: MatDialog) {}

	open(paywallDialogConfig?: PaywallDialogConfig): MatDialogRef<PaywallDialogComponent> {
		const matDialogConfig: MatDialogConfig<PaywallDialogConfig> = {
			disableClose: paywallDialogConfig?.disableClose ?? false,
			hasBackdrop: true,
			autoFocus: false,
			maxWidth: '58vw',
			maxHeight: '80vh',
			data: { ...this.paywallDialogDefaultConfig, ...paywallDialogConfig },
		};
		return this.dialog.open(PaywallDialogComponent, matDialogConfig);
	}
}
