import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { LoaderComponent } from './loader.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
	declarations: [LoaderComponent],
	imports: [SharedModule, MatProgressSpinnerModule],
	exports: [LoaderComponent],
})
export class LoaderModule {}
