import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	distinctUntilChanged,
	map,
	pluck,
	skip,
	startWith,
	switchMap,
	takeUntil,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProjectById } from '@store/selectors/projects.selectors';
import { InviteState, Member, MemberRole } from 'domain-entities';
import { selectCompany, selectUserId } from '@store/selectors/app.selectors';
import { ShrinkOutAnimation } from '@shared/animations/shrink-out-animation';
import { RotateAnimation } from '@shared/animations/rotate-animation';
import { fadeInOutAnimation } from '@shared/animations/fade-in-out-animation';
import {
	isAcceptedInternalMember,
	isAcceptedMember,
	isExternalMember,
	isProjectAdmin,
	isUserProjectAdmin,
} from '@shared/functions/project/project.functions';
import { ProjectMemberDrawerSecondaryViewOptions } from '../project-member-drawer/project-member-drawer.component';
import { ProjectMemberService } from '@injectables/services/project-member/project-member.service';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { getMemberFullName } from '@craftnote/shared-utils';
import { TranslateService } from '@ngx-translate/core';
import { shareReplayOne } from '@craftnote/shared-utils';
import { selectActiveProject, selectIsProjectArchiveRoute } from '@store/selectors/route.selectors';

export enum ProjectMemberFilterBy {
	ALL = 'all',
	INTERNAL = 'internal',
	EXTERNAL = 'external',
}

@Component({
	selector: 'app-project-member-list',
	templateUrl: './project-member-list.component.html',
	styleUrls: ['./project-member-list.component.scss'],
	animations: [ShrinkOutAnimation, RotateAnimation, fadeInOutAnimation()],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMemberListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('projectMembersSelectionListRef') projectMembersSelectionList: MatSelectionList;
	projectMembersSelectionList$$: Subject<MatSelectionList> = new Subject();
	@Output() openView = new EventEmitter<ProjectMemberDrawerSecondaryViewOptions>();
	@Output() openMemberView = new EventEmitter<Member>();

	MemberRole = MemberRole;

	createButtonsActive = false;
	destroy$ = new Subject();
	projectMemberFilter$$ = new BehaviorSubject(ProjectMemberFilterBy.ALL);
	projectId$ = this.activatedRoute.params.pipe(pluck('projectId'), shareReplayOne());

	companyName$ = this.store.select(selectCompany).pipe(pluck('name'));

	project$ = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProjectById(projectId))),
	);
	currentProject$ = this.store.select(selectActiveProject).pipe(
		switchMap((projectId) => this.store.select(selectProjectById(projectId))),
		shareReplayOne(),
	);
	projectName$ = this.currentProject$.pipe(pluck('name'));
	projectMembers$ = this.project$.pipe(
		map((project) =>
			this.projectMemberService.getOrderByName(Object.values(project?.members || {})),
		),
		shareReplayOne(),
	);
	private emitProjectMembersSelectionChange$$ = new Subject();

	private projectMembersSelectionListSource$: Observable<MatSelectionList> =
		this.projectMembersSelectionList$$.pipe(
			switchMap((projectMembersSelectionList) =>
				combineLatest([
					projectMembersSelectionList?.selectionChange.pipe(startWith(undefined)),
					this.emitProjectMembersSelectionChange$$.pipe(startWith(undefined)),
				]).pipe(
					skip(1),
					map((_) => projectMembersSelectionList),
				),
			),
			shareReplayOne(),
		);

	selectedMembers$: Observable<Member[]> = this.projectMembersSelectionListSource$.pipe(
		map((source) => source.selectedOptions.selected.map((option) => option.value)),
	);

	isAllProjectMembersChecked$ = this.projectMembersSelectionListSource$.pipe(
		map(
			(source) =>
				source.selectedOptions.selected.length === source.options.length && !!source.options.length,
		),
	);

	filteredProjectMembers$ = combineLatest([
		this.projectMembers$,
		this.projectMemberFilter$$.pipe(distinctUntilChanged()),
	]).pipe(
		map(([members, memberFilter]) => {
			if (memberFilter === ProjectMemberFilterBy.ALL) {
				return members.filter(isAcceptedMember);
			}
			if (memberFilter === ProjectMemberFilterBy.INTERNAL) {
				return members.filter(isAcceptedInternalMember);
			}
			if (memberFilter === ProjectMemberFilterBy.EXTERNAL) {
				return members.filter(isExternalMember);
			}
		}),
		shareReplayOne(),
	);

	pendingInviteMembers$ = combineLatest([
		this.projectMembers$,
		this.projectMemberFilter$$.pipe(distinctUntilChanged()),
	]).pipe(
		map(([members, memberFilter]) => {
			if (memberFilter === ProjectMemberFilterBy.INTERNAL) {
				return [];
			}
			return members.filter((member) => member.inviteState === InviteState.INVITED);
		}),
		shareReplayOne(),
	);

	currentLoggedInUserId$ = this.store.select(selectUserId);

	isUserProjectAdmin$ = combineLatest([this.projectMembers$, this.currentLoggedInUserId$]).pipe(
		map(([members, userId]) => {
			const projectMembers = Object.values(members || {});
			const projectMember = projectMembers.find((member) => member?.id === userId);
			return isProjectAdmin(projectMember);
		}),
		shareReplayOne(),
	);

	showAddMemberOption$ = combineLatest([
		this.project$,
		this.currentLoggedInUserId$,
		this.store.select(selectIsProjectArchiveRoute),
	]).pipe(
		map(
			([project, userId, isArchiveRoute]) =>
				!isArchiveRoute && isUserProjectAdmin(userId, project, 'id'),
		),
	);
	isEmptyContainer$ = combineLatest([
		this.filteredProjectMembers$,
		this.currentLoggedInUserId$,
		this.projectMemberFilter$$,
		this.pendingInviteMembers$,
	]).pipe(
		map(([projectMembers, userId, projectFilter, pendingInvitations]) => {
			const showEmptyScreen =
				projectMembers.length === 0 ||
				(projectMembers.length <= 1 && projectMembers[0]?.id === userId);

			if (projectFilter !== ProjectMemberFilterBy.INTERNAL && pendingInvitations.length > 0) {
				return false;
			}

			return showEmptyScreen;
		}),
	);

	showMemberPlaceholder$ = combineLatest([
		this.filteredProjectMembers$,
		this.pendingInviteMembers$,
		this.showAddMemberOption$,
	]).pipe(
		map(([projectMembers, pendingMembers, isOwnerOrSupervisor]) => {
			return projectMembers.length + pendingMembers.length > 2 && isOwnerOrSupervisor;
		}),
	);

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly store: Store<AppState>,
		private readonly projectMemberService: ProjectMemberService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {}

	ngOnInit(): void {
		this.initProjectSubscription();
	}

	ngAfterViewInit(): void {
		this.projectMembersSelectionList$$.next(this.projectMembersSelectionList);
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
	}

	isMemberRemovable$(id: string): Observable<boolean> {
		return combineLatest([this.isUserProjectAdmin$, this.currentLoggedInUserId$]).pipe(
			map(([userIsProjectAdmin, userId]) => userIsProjectAdmin && userId !== id),
		);
	}

	async removeProjectMembers(): Promise<void> {
		const selectedMembers = await firstValueFrom(this.selectedMembers$);
		let message = '';
		if (selectedMembers.length === 1) {
			message =
				getMemberFullName(selectedMembers[0]) +
				this.translateService.instant('projectMembers.deleteMembersDialog.single-member-text');
		} else {
			message =
				this.translateService.instant('projectMembers.deleteMembersDialog.multi-member-text') +
				`<br>${selectedMembers.map((m) => `<br>${getMemberFullName(m)}`)}`;
		}

		const deleteMembers = await firstValueFrom(
			this.confirmDialogService
				.open({
					title: this.translateService.instant('projectMembers.deleteMembersDialog.title'),
					message,
					primaryButtonColor: 'warn',
					showCrossBtn: false,
					showSecondaryButton: true,
					primaryButtonText: this.translateService.instant(
						'projectMembers.deleteMembersDialog.remove',
					),
					secondaryButtonText: this.translateService.instant(
						'projectMembers.deleteMembersDialog.cancel',
					),
					primaryButtonValue: true,
					secondaryButtonValue: false,
				})
				.afterClosed(),
		);

		if (deleteMembers) {
			const projectId = await firstValueFrom(this.projectId$);
			await this.projectMemberService.removeMembers(selectedMembers, projectId);
			await this.toggleAllProjectMembersSelection({ checked: false });
		}
	}

	async toggleAllProjectMembersSelection({ checked }): Promise<void> {
		if (checked) {
			this.projectMembersSelectionList?.selectAll();
		} else {
			this.projectMembersSelectionList?.deselectAll();
		}
		this.emitProjectMembersSelectionChange$$.next(null);
	}

	onMemberSelectionChangeCompareWith(memberA, memberB): boolean {
		return memberA.id === memberB.id;
	}

	initProjectSubscription(): void {
		this.projectId$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
			this.toggleAllProjectMembersSelection({ checked: false });
			this.projectMemberFilter$$.next(ProjectMemberFilterBy.ALL);
			this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.INITIAL);
		});
	}

	toggleActionMenu(): void {
		this.createButtonsActive = !this.createButtonsActive;
	}

	openInternalMemberView(): void {
		this.toggleActionMenu();
		this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.INTERNAL);
	}

	openExternalMemberView(): void {
		this.toggleActionMenu();
		this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.EXTERNAL);
	}

	openMemberDetails(member: Member, event): void {
		if (event.target.className.includes('checkbox')) {
			return;
		}
		event.stopPropagation();
		this.openMemberView.emit(member);
	}
}
