<div class="toc-confirmation">
	<form [formGroup]="tocForm" (ngSubmit)="agreeToc()">
		<h1 mat-dialog-title class="mat-display-1" [innerHTML]="'toc.confirmationDialog.title' | translate"></h1>
		<div mat-dialog-content>
			<mat-checkbox formControlName="state" class="my-3">
				<span
					class="text-muted"
					[innerHTML]="
						'toc.confirmationDialog.body' | translate: { tocLink: linkService.tocLink, legalNoticeLink: linkService.legalNoticeLink }
					"
				></span>
			</mat-checkbox>
		</div>
		<div mat-dialog-actions class="justify-content-end">
			<button mat-stroked-button [mat-dialog-close]="false">
				{{ 'toc.confirmationDialog.close' | translate }}
			</button>
			<button mat-flat-button color="accent" [disabled]="tocForm.invalid" type="submit">
				{{ 'toc.confirmationDialog.confirm' | translate }}
			</button>
		</div>
	</form>
</div>
