import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { ShowConditionalDirective } from '@modules/shared/directives/show-conditional/show-conditional.directive';

@NgModule({
	declarations: [ShowConditionalDirective],
	imports: [SharedModule],
	exports: [ShowConditionalDirective],
})
export class ShowConditionalModule {}
