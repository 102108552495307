import { createEntityAdapter } from '@ngrx/entity';
import { Project } from 'domain-entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromProject from '../../actions/project.actions';
import { ProjectsState } from '../../state/projects.state';
import { updateEntityStore } from '../functions/update-entity-state.functions';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

const activeProjectsAdapter = createEntityAdapter<Project>();
const archivedProjectsAdapter = createEntityAdapter<Project>();
const deletedProjectsAdapter = createEntityAdapter<Project>();

const initialProjectState: ProjectsState = {
	openedFolder: undefined,
	active: activeProjectsAdapter.getInitialState({
		loadingStatus: StoreLoadingStatus.INITIAL,
	}),
	archived: archivedProjectsAdapter.getInitialState({
		loadingStatus: StoreLoadingStatus.INITIAL,
	}),
	deleted: deletedProjectsAdapter.getInitialState({
		loadingStatus: StoreLoadingStatus.INITIAL,
	}),
	activeProjectsSearchTerm: undefined,
	archivedProjectsSearchTerm: undefined,
};

const reducer = createReducer(
	initialProjectState,
	on(fromProject.updateActiveProjectsAction, (state, { changes }) => {
		return {
			...state,
			active: updateEntityStore<Project>(activeProjectsAdapter, state.active, changes),
		};
	}),
	on(fromProject.updateArchivedProjectsAction, (state, { changes }) => {
		return {
			...state,
			archived: updateEntityStore<Project>(archivedProjectsAdapter, state.archived, changes),
		};
	}),
	on(fromProject.updateDeletedProjectsAction, (state, { changes }) => {
		return {
			...state,
			deleted: updateEntityStore<Project>(deletedProjectsAdapter, state.deleted, changes),
		};
	}),
	on(fromProject.setActiveProjectsSearchTerm, (state, { activeProjectsSearchTerm }) => {
		return { ...state, activeProjectsSearchTerm };
	}),
	on(fromProject.setArchivedProjectsSearchTerm, (state, { archivedProjectsSearchTerm }) => {
		return { ...state, archivedProjectsSearchTerm };
	}),
	on(fromProject.setSelectedFolderAction, (state, { folderId }) => {
		return { ...state, openedFolder: folderId || undefined };
	}),
);

export const activeProjectsSelectors = activeProjectsAdapter.getSelectors();
export const archivedProjectsSelectors = archivedProjectsAdapter.getSelectors();
export const deletedProjectsSelectors = deletedProjectsAdapter.getSelectors();

// tslint:disable-next-line:typedef
export function projectReducer(state: ProjectsState | undefined, action: Action) {
	return reducer(state, action);
}
