import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { buildFirestoreDocumentPath } from '@utils/functions/firestore/firestore-path.functions';
import { CompanyMetaData, isCompanyMetaData } from 'domain-entities';
import { Observable } from 'rxjs';
import { CompanyConnector } from '@shared/firebase/connectors/firestore/collections/company/company.connector';

@Injectable({
	providedIn: 'root',
})
export class CompanyMetadataConnector {
	private static COLLECTION_NAME = 'metaData';

	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	getCompanyMetadata(companyId: string): Observable<CompanyMetaData> {
		const companyMetadataPath = buildFirestoreDocumentPath([
			{ collectionName: CompanyConnector.COLLECTION_NAME, documentId: companyId },
			{ collectionName: CompanyMetadataConnector.COLLECTION_NAME, documentId: 'default' },
		]);

		return this.firestoreConnector.watchDocument(companyMetadataPath, isCompanyMetaData);
	}
}
