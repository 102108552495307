import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProjects from '../reducers/project/projects.reducer';
import { Member, Project, Project as ProjectEntity } from 'domain-entities';

import {
	selectAuthState,
	selectCompanyMembersAcceptedEntities,
	selectUserEmail,
} from './app.selectors';
import { ProjectsState } from '../state/projects.state';
import {
	getAcceptedMembersOfProject,
	isUserOwnerOfProject,
} from '@shared/functions/project/project.functions';
import { EntityState } from '@ngrx/entity';
import { groupBy, intersection, keyBy, without } from 'lodash';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');

export const selectActiveState = createSelector(selectProjectsState, (state) => {
	return state.active;
});
export const selectActiveProjectEntity = createSelector(
	selectProjectsState,
	selectActiveProject,
	(state, activeProject) => {
		return getProjectFromProjectState(state, activeProject);
	},
);

export const selectArchivedState = createSelector(selectProjectsState, (state) => {
	return state.archived;
});

export const selectSelectedFolderId = createSelector(selectProjectsState, (state) => {
	return state.openedFolder;
});

export const selectActiveProjectLoadingState = createSelector(
	selectActiveState,
	(state) => state.loadingStatus,
);

export const selectArchivedProjectLoadingState = createSelector(
	selectArchivedState,
	(state) => state.loadingStatus,
);

export const selectActiveProjectsLoaded = createSelector(
	selectActiveProjectLoadingState,
	(activeLoadingState) => activeLoadingState === StoreLoadingStatus.LOADED,
);

export const selectArchivedProjectsLoaded = createSelector(
	selectArchivedProjectLoadingState,
	(archivedLoadingState) => archivedLoadingState === StoreLoadingStatus.LOADED,
);

export const selectAllProjectsLoaded = createSelector(
	selectActiveProjectsLoaded,
	selectArchivedProjectsLoaded,
	(activeLoadingState, archivedLoadingState) => activeLoadingState && archivedLoadingState,
);

export const selectOpenedFolder = createSelector(
	selectProjectsState,
	selectSelectedFolderId,
	getProjectFromProjectState,
);

export const selectAllActiveProjects = createSelector(selectProjectsState, (state) =>
	fromProjects.activeProjectsSelectors.selectAll(state.active),
);

export const selectAllActiveProjectsEntities = createSelector(selectProjectsState, (state) =>
	fromProjects.activeProjectsSelectors.selectEntities(state.active),
);

export const selectAllArchivedProjectsEntities = createSelector(selectProjectsState, (state) =>
	fromProjects.archivedProjectsSelectors.selectEntities(state.archived),
);

export const selectProjectLocationByProjectId = createSelector(
	selectAllActiveProjectsEntities,
	selectAllArchivedProjectsEntities,
	(activeProjects, archivedProjects, selectedProjectIds) => {
		const projectLocationByProjectId: { [id: string]: 'projects' | 'archive' } = {};
		for (const selectedProjectId of selectedProjectIds) {
			if (activeProjects[selectedProjectId]) {
				projectLocationByProjectId[selectedProjectId] = 'projects';
			} else if (archivedProjects[selectedProjectId]) {
				projectLocationByProjectId[selectedProjectId] = 'archive';
			}
		}

		return projectLocationByProjectId;
	},
);

export const selectAllArchivedProjects = createSelector(selectProjectsState, (state) =>
	fromProjects.archivedProjectsSelectors.selectAll(state.archived),
);

export const selectAllProjects = createSelector(
	selectAllActiveProjects,
	selectAllArchivedProjects,
	(activeProjects, archivedProjects) => {
		return [...activeProjects, ...archivedProjects];
	},
);

export const selectProjectsByParentId = createSelector(selectAllProjects, (projects, { id }) => {
	if (!id) {
		return [];
	}

	return projects.filter((project) => project.parentProject === id);
});

export const selectAllProjectsEntities = createSelector(selectAllProjects, (allProjects) =>
	keyBy(allProjects, 'id'),
);

export const selectProjectById = (projectId: string) =>
	createSelector(selectProjectsState, (state) => {
		return getProjectFromProjectState(state, projectId);
	});

/**
 * @deprecated This selector is using props which will not be supported by future versions of ngrx. Use selectProjectById instead.
 */
export const selectProject = createSelector(
	selectProjectsState,
	(state, props: { projectId: string }) => {
		return getProjectFromProjectState(state, props.projectId);
	},
);

export const selectProjectFromActive = createSelector(
	selectProjectsState,
	(state, props: { projectId: string }) => {
		return selectProjectByIdFromEntityState(state.active, props.projectId);
	},
);

export const selectProjectFromArchived = createSelector(
	selectProjectsState,
	(state, props: { projectId: string }) => {
		return selectProjectByIdFromEntityState(state.archived, props.projectId);
	},
);

export const selectProjectAcceptedMembersEntities = createSelector(
	selectProjectsState,
	(state, props: { projectId: string }) => {
		const project = getProjectFromProjectState(state, props.projectId);
		return keyBy(getAcceptedMembersOfProject(project), 'id');
	},
);

export const selectProjects = createSelector(selectProjectsState, (state, props) => {
	return props.projectIds.map((id) => selectDomainEntityProjectById(state, id));
});

export const selectDomainEntityProject = createSelector(selectProjectsState, (state, props) => {
	return selectDomainEntityProjectById(state, props.projectId);
});

export const selectParentProject = createSelector(
	selectProjectsState,
	(state, props: { projectId: string }) => {
		const project = getProjectFromProjectState(state, props.projectId);
		if (!project) {
			return undefined;
		}
		return getProjectFromProjectState(state, project.parentProject);
	},
);

export const selectDeletedProjects = createSelector(selectProjectsState, (state): string[] => {
	return fromProjects.deletedProjectsSelectors.selectIds(state.deleted) as string[];
});

export const selectDeletedProject = createSelector(
	selectProjectsState,
	(state: { deleted: EntityState<Project> }, props: { projectId: string }) => {
		return fromProjects.deletedProjectsSelectors.selectEntities(state.deleted)[props.projectId];
	},
);

export const selectIsProjectMemberOwner = createSelector(
	selectProject,
	selectUserEmail,
	(project, email) => {
		return isUserOwnerOfProject(email, project, 'email');
	},
);

export const selectAuthorOfMessage = createSelector(
	selectProjectsState,
	selectCompanyMembersAcceptedEntities,
	(projectState, companyMembers, props: { authorId: string; projectId: string }) => {
		const project = getProjectFromProjectState(projectState, props.projectId);
		const members: Member[] = Object.values({ ...companyMembers, ...(project?.members || {}) });
		const author = members.find((member) => member?.id === props?.authorId);
		return author || null;
	},
);

export const selectCurrentUserAsMemberInCurrentProject = createSelector(
	selectProjectsState,
	selectAuthState,
	selectActiveProject,
	(projectState, authState, activeProject) => {
		return getProjectFromProjectState(projectState, activeProject)?.members[authState.user?.email];
	},
);

export const selectCurrentUserMemberRoleInCurrentProject = createSelector(
	selectCurrentUserAsMemberInCurrentProject,
	(member) => {
		return member?.role;
	},
);

export function getProjectFromProjectState(
	state: ProjectsState,
	projectId: string,
): ProjectEntity | undefined {
	if (!projectId || !state) {
		return undefined;
	}
	const active = selectProjectByIdFromEntityState(state.active, projectId);
	const archived = selectProjectByIdFromEntityState(state.archived, projectId);
	return active || archived;
}

export function selectProjectByIdFromEntityState(
	state: EntityState<Project>,
	projectId: string,
): ProjectEntity | undefined {
	if (!projectId || !state) {
		return undefined;
	}
	return state.entities[projectId];
}

export function selectArchivedProjectById(
	state: ProjectsState,
	projectId: string,
): ProjectEntity | undefined {
	if (!projectId || !state) {
		return undefined;
	}
	return fromProjects.archivedProjectsSelectors.selectEntities(state.archived)[projectId];
}

function selectDomainEntityProjectById(
	state: ProjectsState,
	projectId: string,
): ProjectEntity | undefined {
	if (!projectId) {
		return undefined;
	}
	const active = fromProjects.activeProjectsSelectors.selectEntities(state.active)[projectId];
	const archived = fromProjects.archivedProjectsSelectors.selectEntities(state.archived)[projectId];
	const deleted = fromProjects.archivedProjectsSelectors.selectEntities(state.deleted)[projectId];

	const project = active || archived || deleted;
	if (!project) {
		return undefined;
	}
	return project;
}

export const selectActiveProjectsWithParents = createSelector(
	selectActiveState,
	selectArchivedState,
	(activeState, archivedState) => {
		return selectProjectsWithAdditionalFolders(activeState, archivedState);
	},
);

export const selectArchivedProjectsWithParents = createSelector(
	selectArchivedState,
	selectActiveState,
	(archivedState, activeState) => {
		return selectProjectsWithAdditionalFolders(archivedState, activeState);
	},
);

export const selectActiveProjectsSearchTerm = createSelector(
	selectProjectsState,
	(state) => state.activeProjectsSearchTerm,
);

export const selectArchivedProjectsSearchTerm = createSelector(
	selectProjectsState,
	(state) => state.archivedProjectsSearchTerm,
);

export const selectChildrenOfActiveFolderEntity = createSelector(
	selectAllActiveProjects,
	(projects) => groupBy(projects, (project) => project.parentProject),
);

export const selectChildrenOfActiveFolder = (folderId: string) =>
	createSelector(selectChildrenOfActiveFolderEntity, (folders) => folders[folderId]);

function selectProjectsWithAdditionalFolders(
	projectsSource: EntityState<Project>,
	projectsExtended: EntityState<Project>,
): Project[] {
	const sourceProjects = Object.values(projectsSource.entities);

	// All the folder ids of projects within folders
	let folderIds = sourceProjects.reduce((parentProjectIds, project) => {
		if (project.parentProject) {
			parentProjectIds.push(project.parentProject);
		}
		return parentProjectIds;
	}, [] as string[]);

	// Remove those folders which are already part of the original set of projects
	folderIds = without<string>(folderIds, ...(projectsSource.ids as string[]));

	const relevantAdditionalFolderIds = intersection<string>(
		folderIds,
		projectsExtended.ids as string[],
	);
	const relevantAdditionalFolders = relevantAdditionalFolderIds.map(
		(folderId) => projectsExtended.entities[folderId],
	);

	return [...sourceProjects, ...relevantAdditionalFolders];
}
