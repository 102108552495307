import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
	collection as collectionModular,
	CollectionReference as CollectionReferenceModular,
	Firestore,
	onSnapshot,
} from '@angular/fire/firestore';

import { Injectable } from '@angular/core';
import {
	DataQueryCondition,
	DocumentChanges,
	EntityChanges,
	trimObjectStringValues,
} from '@craftnote/shared-utils';
import { catchError, filter, map, share, startWith, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { DocumentCreationFailedError } from './errors/document-creation-failed.error';
import { DocumentUpdateFailedError } from './errors/document-update-failed.error';
import {
	chunk,
	cloneDeep,
	flatMap,
	get,
	isArray,
	isNil,
	isObject,
	isUndefined,
	noop,
	omitBy,
	pickBy,
} from 'lodash';
import { DocumentSchemaFailedError } from './errors/document-schema-failed.error';
import { buildFirestoreQueryWithRef, buildModularFirestoreQuery } from './firestore.functions';
import firebase from 'firebase/compat/app';
import FieldValue = firebase.firestore.FieldValue;

export type RequiredKey<T> = {
	[K in keyof T]: {} extends { [P in K]: T[K] } ? never : K;
}[keyof T] &
	string;

@Injectable({
	providedIn: 'root',
})
export class FirestoreConnector {
	constructor(
		private readonly angularFirestore: AngularFirestore,
		private readonly firestore: Firestore,
	) {}

	async create(
		path: string,
		id: string,
		entity: object,
		schemaValidator: (entity) => boolean,
	): Promise<void> {
		entity = this.deleteUndefinedFieldsFromObject(entity);
		if (!schemaValidator(entity)) {
			throw new DocumentCreationFailedError(id, path);
		}
		const entityWithTrimmedStrings = trimObjectStringValues(entity);

		return this.angularFirestore.collection(path).doc(id).set(entityWithTrimmedStrings).then();
	}

	async updateDocumentPartial<T>(
		path: string,
		id: string,
		document: Partial<T>,
		requiredDocumentKeys: RequiredKey<T>[],
	): Promise<void> {
		// Break if required field have nil value
		this.breakIfRequiredFieldIsNil<T>(document, requiredDocumentKeys);
		const documentWithTrimmedStrings = trimObjectStringValues(document);
		const documentWithUndefinedSetToDeleted = this.setUndefinedFieldsToDeleted(
			documentWithTrimmedStrings,
		);

		return this.angularFirestore
			.collection<T>(path)
			.doc(id)
			.update(documentWithUndefinedSetToDeleted);
	}

	public async updateDocumentArrayField<T>(
		path: string,
		field: keyof T & string,
		value: string | number,
		action: 'add' | 'remove',
	): Promise<void> {
		const updateObj = {};
		updateObj[field as string] =
			action === 'add' ? FieldValue.arrayUnion(value) : FieldValue.arrayRemove(value);
		return this.angularFirestore.doc<T>(path).update(updateObj);
	}

	upsertTransactional<T>(
		path: string,
		id: string,
		updateFunction: (oldEntity: T) => T,
		schemaValidator: (entity) => boolean,
	): Promise<void> {
		return this.angularFirestore.firestore.runTransaction(async (transaction) => {
			const docPath = `${path}/${id}`;
			const doc = this.angularFirestore.doc(docPath);
			let oldEntity: T;
			try {
				oldEntity = (await transaction.get(doc.ref)).data() as T;
			} catch (error) {}

			let upsertedDocument = updateFunction(oldEntity);
			upsertedDocument = this.deleteUndefinedFieldsFromObject(upsertedDocument);
			if (!schemaValidator(upsertedDocument)) {
				throw new DocumentCreationFailedError(id, path);
			}
			transaction.set(doc.ref, upsertedDocument);
		});
	}

	updateDocumentTransactional<T>(
		path: string,
		entityId: string,
		updateFunction: (oldEntity: T) => Partial<T> | undefined,
		schemaValidation: (oldEntity: T) => boolean,
	): Promise<void> {
		return this.angularFirestore.firestore.runTransaction(async (transaction) => {
			const doc = this.angularFirestore.collection(path).doc(entityId);
			const oldEntity = (await transaction.get(doc.ref)).data() as T;
			if (!schemaValidation(oldEntity)) {
				throw new DocumentUpdateFailedError(entityId, path);
			}
			const updateObject = updateFunction(oldEntity);
			if (!updateObject) {
				return;
			}

			const objectWithTrimmedStrings = trimObjectStringValues(updateObject);

			transaction.update(doc.ref, objectWithTrimmedStrings);
		});
	}

	getDocument<T>(path: string, schemaValidator: (entity: T) => boolean): Promise<T> {
		return this.angularFirestore
			.doc(path)
			.get()
			.pipe(
				map((document) => {
					const data = document.data() as T;
					if (!schemaValidator(data)) {
						throw new DocumentSchemaFailedError(path, '');
					}
					return data;
				}),
			)
			.toPromise();
	}

	watchDocumentChanges<T>(path: string): Observable<DocumentChanges<T>> {
		return this.angularFirestore
			.doc<T>(path)
			.snapshotChanges()
			.pipe(
				map((snapshot) => {
					if (snapshot.payload?.exists) {
						return {
							changeType: 'updated',
							payload: snapshot.payload.data() as T,
						};
					}
					return { changeType: 'deleted' };
				}),
			);
	}

	watchDocument<T>(path: string, validator?: (any) => boolean): Observable<T> {
		const document = this.angularFirestore.doc<T>(path);
		const changes = document.valueChanges();
		if (!validator) {
			return changes;
		}
		return changes.pipe(filter((doc) => validator(doc)));
	}

	getDocuments<T>(path: string, validator?: (any) => boolean): Promise<T[]> {
		let documents$ = this.angularFirestore
			.collection(path)
			.valueChanges()
			.pipe(take(1)) as Observable<T[]>;
		if (!!validator) {
			documents$ = documents$.pipe(map((products) => products.filter(validator)));
		}
		return documents$.toPromise();
	}

	watchDocuments<T>(
		path: string,
		conditions: DataQueryCondition<T>[],
		validator?: (any) => boolean,
	): Observable<T[]> {
		const collection = this.angularFirestore.collection<T>(path, (ref) => {
			return buildFirestoreQueryWithRef(ref, conditions);
		});
		const changes = collection.valueChanges();
		if (!validator) {
			return changes;
		}
		return changes.pipe(map((entries) => entries.filter(validator)));
	}

	watchDocumentsChanges<T>(
		path: string,
		conditions: DataQueryCondition<T>[],
		validator?: (any) => boolean,
	): Observable<EntityChanges<T>> {
		const collection = this.angularFirestore.collection(path, (ref) =>
			buildFirestoreQueryWithRef(ref, conditions, 1),
		);
		const collectionIsEmpty$ = collection.get().pipe(
			take(1),
			map((data) => data.size === 0),
			catchError(() => of(true)),
		);

		const query = buildModularFirestoreQuery<T>(
			collectionModular(this.firestore, path) as CollectionReferenceModular<T>,
			conditions,
		);

		const changes$$ = new Subject<EntityChanges<T>>();

		let unsubscribeCallback = noop;
		const subscriptionFactory = () => {
			unsubscribeCallback = onSnapshot(query, (snapshot) => {
				const docChanges = snapshot.docChanges();
				const createEntities: T[] = [];
				const deleteEntities: T[] = [];
				const updateEntities: T[] = [];

				docChanges.forEach((change) => {
					const entity = change.doc.data() as T;
					const isValid = validator ? validator(entity) : true;

					// Immediately remove invalid entities from the state
					if (!isValid) {
						deleteEntities.push(entity);
						return;
					}

					switch (change.type) {
						case 'added':
							createEntities.push(entity);
							break;
						case 'removed':
							deleteEntities.push(entity);
							break;
						case 'modified':
							updateEntities.push(entity);
							break;
					}
				});
				const entityChanges: EntityChanges<T>[] = [];
				if (createEntities.length) {
					entityChanges.push({ changeType: 'created', entities: createEntities });
				}
				if (deleteEntities.length) {
					entityChanges.push({ changeType: 'deleted', entities: deleteEntities });
				}
				if (updateEntities.length) {
					entityChanges.push({ changeType: 'updated', entities: updateEntities });
				}
				entityChanges.forEach((c) => changes$$.next(c));
			});
		};

		const sharedChanges$ = changes$$.pipe(
			share({
				connector: () => {
					subscriptionFactory();
					return new Subject<EntityChanges<T>>();
				},
				resetOnRefCountZero: () => {
					unsubscribeCallback();
					return new Subject<EntityChanges<T>>();
				},
			}),
		);

		return collectionIsEmpty$.pipe(
			switchMap((isInitiallyEmpty) => {
				if (isInitiallyEmpty) {
					const emptyStateChanges = { changeType: 'created', entities: [] } as EntityChanges<T>;
					return sharedChanges$.pipe(startWith(emptyStateChanges));
				}
				return sharedChanges$;
			}),
		);
	}

	watchDocumentsBatches<T extends { companyId?: string }>(
		collectionName: string,
		ids: string[],
		companyId: string,
		idField: keyof T | 'id' = 'id',
	): Observable<T[]> {
		if (!ids.length) {
			return of([]);
		}
		const idChunks = chunk(ids, 10);
		const observables: Observable<T[]>[] = [];
		const companyCondition: DataQueryCondition<T> = {
			field: 'companyId',
			operator: '==',
			value: companyId,
		};
		for (const idChunk of idChunks) {
			const condition: DataQueryCondition<T> = {
				field: idField as keyof T & string,
				operator: 'in',
				value: idChunk,
			};
			const obs = this.watchDocuments<T>(collectionName, [companyCondition, condition]);
			observables.push(obs);
		}
		return combineLatest(observables).pipe(map((projectsChunks) => flatMap(projectsChunks)));
	}

	deleteDocument(path: string, entityId: string): Promise<void> {
		return this.angularFirestore.collection(path).doc(entityId).delete();
	}

	private setUndefinedFieldsToDeleted(object: any): any {
		const copied = { ...object };
		Object.entries(copied).forEach((entry) => {
			if (entry[1] === undefined) {
				copied[entry[0]] = FieldValue.delete();
			}
		});
		return copied;
	}

	private deleteUndefinedFieldsFromObject(object: any): any {
		const copied = cloneDeep(object);
		return this.deleteUndefinedFieldsRecursively(copied);
	}

	private deleteUndefinedFieldsRecursively(object: object | unknown[]): object {
		if (isArray(object)) {
			object = (object as unknown[]).filter((val) => !isUndefined(val));
		} else {
			object = omitBy(object, isUndefined);
		}
		for (const [key, value] of Object.entries(pickBy(object, isObject)) || []) {
			object[key] = this.deleteUndefinedFieldsRecursively(value);
		}
		return object;
	}

	private breakIfRequiredFieldIsNil<T>(
		document: Partial<T>,
		requiredDocumentKeys: RequiredKey<T>[],
	): void {
		requiredDocumentKeys.forEach((requiredDocumentKey) => {
			if (requiredDocumentKey in document && isNil(get(document, requiredDocumentKey, undefined))) {
				throw new Error(
					`Required document key:${requiredDocumentKey} cannot be null or undefined. Document: ${JSON.stringify(
						document,
					)}`,
				);
			}
		});
	}
}
