import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Member, TaskComment } from 'domain-entities';
import { v4 as uuid } from 'uuid';
import { Store } from '@ngrx/store';
import { selectUserId } from '@store/selectors/app.selectors';
import moment from 'moment';
import { TaskEditCommentsService } from '@injectables/services/task-edit-comments/task-edit-comments.service';
import { TrackingService } from '@injectables/services/tracking.service';
import { TasksCommentCreatedEventBuilder } from '@generated/events/TasksEvents.generated';

interface TaskEditCommentsUI {
	comment: string;
	createdTimestamp: number;
	member: Member;
}

@Component({
	selector: 'app-task-edit-comments',
	templateUrl: './task-edit-comments.component.html',
	styleUrls: ['./task-edit-comments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditCommentsComponent implements OnChanges {
	@Input() taskId: string = null;
	@Input() projectMembers: { [email: string]: Member } = {};
	@Input() projectId: string;
	@Output('openTaskEditDetails') openTaskEditDetailsEmitter = new EventEmitter();
	taskId$: ReplaySubject<string> = new ReplaySubject();
	projectMembers$: Subject<Member[]> = new ReplaySubject(1);
	taskCommentsUI$: Observable<TaskEditCommentsUI[]> = this.getTaskCommentsUI();
	newCommentControl: UntypedFormControl = this.formBuilder.control('', [Validators.required]);

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly taskEditCommentsService: TaskEditCommentsService,
		private readonly store: Store,
		private readonly trackingService: TrackingService,
	) {}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.taskId?.currentValue) {
			this.taskId$.next(changes.taskId.currentValue);
		}
		if (changes.projectMembers?.currentValue) {
			this.projectMembers$.next(Object.values(changes.projectMembers.currentValue));
		}
	}

	async createNewComment(content: string): Promise<void> {
		const taskId = this.taskId;
		const userId = await this.store.select(selectUserId).pipe(first()).toPromise();
		await this.taskEditCommentsService.createTaskComment({
			id: uuid(),
			userId,
			taskId,
			createdTimestamp: moment().unix(),
			content,
		});
		await this.trackingService.trackEvent(
			new TasksCommentCreatedEventBuilder({
				projectId: this.projectId,
			}),
		);
		this.newCommentControl.reset();
	}

	openTaskEditDetails(): void {
		this.openTaskEditDetailsEmitter.emit();
	}

	getTaskCommentsUI(): Observable<TaskEditCommentsUI[]> {
		return combineLatest([this.taskId$, this.projectMembers$]).pipe(
			switchMap(([taskId, projectMembers]) =>
				this.taskEditCommentsService.getTaskComments(taskId).pipe(
					map((comments: TaskComment[]) =>
						comments.map((taskComment) => {
							let member: Member = null;
							if (taskComment) {
								member = projectMembers.find(
									(projectMember) => projectMember.id === taskComment.userId,
								);
							}

							return {
								comment: taskComment.content,
								createdTimestamp: taskComment.createdTimestamp,
								member,
							};
						}),
					),
					map((taskComments) =>
						taskComments.sort((a, b) => b.createdTimestamp - a.createdTimestamp),
					),
				),
			),
		);
	}
}
