<ng-template #paywallHeaderDialogRef>
	<div mat-dialog-title>
		<div class="d-flex align-items-center justify-content-between mat-display-1 m-0">
			<span>{{ 'paywall-header-dialog.title' | translate }}</span>

			<button [mat-dialog-close]="false" mat-icon-button>
				<mat-icon>clear</mat-icon>
			</button>
		</div>

		<div class="mat-title font-weight-normal text-muted m-0">
			{{ 'paywall-header-dialog.sub-heading' | translate }}
		</div>
	</div>

	<mat-dialog-content>
		<section class="d-flex flex-column flex-nowrap schedule-demo-dialog-section">
			<div class="border rounded mb-3 d-flex justify-content-center align-items-center p-4">
				<video class="player-video" controls autoplay [muted]="true">
					<source *ngIf="videoSource" [src]="videoSource" />
				</video>
			</div>
			<div
				class="mat-title font-weight-normal text-muted m-0"
				[innerHTML]="'paywall-header-dialog.footer-text.with-mailto' | translate"
			></div>
		</section>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<a
			mat-stroked-button
			class="mr-2"
			(click)="onClickBookDemo()"
			target="_blank"
			rel="noopener"
			href="https://termin.craftnote.de/sign-up.php?utm_source=inapp_indicator&utm_medium=nav-cta-button&utm_campaign=kostenlos-testen"
		>
			{{ 'paywall-header-dialog.btn-book-demo' | translate }}
		</a>
		<button mat-flat-button color="accent" (click)="onClickUpgrades()" *ngIf="isOwner$ | async">
			{{ 'paywall-header-dialog.btn-upgrades' | translate }}
		</button>
	</mat-dialog-actions>
</ng-template>
