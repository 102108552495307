import { NgModule } from '@angular/core';
import { MessageDatePipe } from './message-date.pipe';

const PIPE = [MessageDatePipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class MessageDatePipeModule {}
