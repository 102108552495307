import { Action, createReducer, on } from '@ngrx/store';
import { ProductsState } from '@store/state/products.state';
import * as fromProductActions from '@store/actions/products.actions';

export const productsFeatureKey = 'productsState';

const initialState = [];

const reducer = createReducer(
	initialState,
	on(fromProductActions.setProducts, (_state, payload) => {
		return [...payload.products];
	}),
);

// tslint:disable-next-line:typedef
export function productsReducer(state: ProductsState | undefined, action: Action) {
	return reducer(state, action);
}
