import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isTaskTime, TaskTime } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';
import { TASKS_TIMES } from '@shared/constants/firebase';
import { DataQueryCondition } from '@craftnote/shared-utils';

export interface TaskTimeConnectorFilters {
	assigneeIds?: string[];
	startDate?: number;
}

const TASK_TIME_REQUIRED_KEYS: RequiredKey<TaskTime>[] = ['id', 'taskId', 'startTime', 'endTime'];

@Injectable({
	providedIn: 'root',
})
export class TaskTimeConnector {
	constructor(private readonly connector: FirestoreConnector) {}

	watchTasksTimesOfTask(taskId: string): Observable<EntityChanges<TaskTime>> {
		return this.connector.watchDocumentsChanges(TASKS_TIMES, [
			{
				field: 'taskId',
				operator: '==',
				value: taskId,
			},
		]);
	}

	watchTasksTimesOfCompany(
		companyId: string,
		filters: TaskTimeConnectorFilters,
	): Observable<TaskTime[]> {
		const conditions: DataQueryCondition<TaskTime>[] = [];
		conditions.push({ field: 'companyId', operator: '==', value: companyId });

		if (filters.assigneeIds?.length <= 10) {
			conditions.push({ field: 'userId', operator: 'in', value: filters.assigneeIds });
		}
		if (filters.startDate) {
			conditions.push({ field: 'startTime', operator: '>', value: filters.startDate });
		}

		return this.connector.watchDocuments(TASKS_TIMES, conditions, isTaskTime);
	}

	createTaskTime(taskTime: TaskTime): Promise<void> {
		return this.connector.create(TASKS_TIMES, taskTime.id, taskTime, isTaskTime);
	}

	removeTaskTime(id: string): Promise<void> {
		return this.connector.deleteDocument(TASKS_TIMES, id);
	}

	updateTaskTime(taskTime: Partial<TaskTime>): Promise<void> {
		return this.connector.updateDocumentPartial<TaskTime>(
			TASKS_TIMES,
			taskTime.id,
			taskTime,
			TASK_TIME_REQUIRED_KEYS,
		);
	}
}
