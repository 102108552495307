export interface FirestorePathSegment {
	collectionName: string;
	documentId: string;
}

export function buildFirestoreDocumentPath(segments: FirestorePathSegment[]): string {
	return segments.reduce(
		(acc, segment) => `${acc}${acc && '/'}${segment.collectionName}/${segment.documentId}`,
		'',
	);
}

export function buildFirestoreCollectionPath(
	segments: FirestorePathSegment[],
	collectionName: string,
): string {
	const documentPart = segments.reduce(
		(acc, segment) => `${acc}${acc && '/'}${segment.collectionName}/${segment.documentId}`,
		'',
	);
	return `${documentPart}/${collectionName}`;
}
