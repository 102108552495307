import { Component, Input } from '@angular/core';
import { SharedSliderDialogToggleOption } from '../../../../entities/shared-slider-dialog.data';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-shared-slider-input-toggle',
	templateUrl: './shared-slider-input-toggle.component.html',
	styleUrls: ['./shared-slider-input-toggle.component.scss'],
})
export class SharedSliderInputToggleComponent {
	@Input() toggles: SharedSliderDialogToggleOption[];
	@Input() control: UntypedFormControl;

	selectedIndex: number;

	onSelection(index: number): void {
		this.control.setValue(index);
	}
}
