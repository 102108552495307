import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { OnResizeDirective } from './directives/on-resize.directive';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SharedModule } from '../../shared.module';

@NgModule({
	declarations: [BreadcrumbsComponent, OnResizeDirective],
	imports: [MatMenuModule, SharedModule, MatTooltipModule],
	exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
