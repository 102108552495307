import { Routes } from '@angular/router';
import { MobileComponent } from './mobile.component';
import { MobileGuard } from '@injectables/guards/mobile/mobile.guard';

export const mobileRoute: Routes = [
	{
		path: 'mobile',
		component: MobileComponent,
		data: {
			title: 'mobile.title',
		},
		pathMatch: 'full',
		canActivate: [MobileGuard],
	},
];
