import { Component } from '@angular/core';
import { timer } from 'rxjs';

const HINT_TIME_PROJECTS = 5000;
const HINT_TIME_FIREWALL = 20000;

@Component({
	selector: 'app-pulsing-craftnote-icon',
	templateUrl: './pulsing-craftnote-icon.component.html',
	styleUrls: ['./pulsing-craftnote-icon.component.scss'],
})
export class PulsingCraftnoteIconComponent {
	shouldShowProjectsHint = false;
	shouldShowFirewallHint = false;

	constructor() {
		timer(HINT_TIME_PROJECTS).subscribe(() => (this.shouldShowProjectsHint = true));
		timer(HINT_TIME_FIREWALL).subscribe(() => (this.shouldShowFirewallHint = true));
	}
}
