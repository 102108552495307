import { Injectable } from '@angular/core';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { selectUserId } from '@store/selectors/app.selectors';
import { ProfileTracker } from 'domain-entities';
import { Observable } from 'rxjs';
import {
	clearProfileTrackerState,
	updateTrackerState,
} from '@store/actions/profile-tracker.actions';
import {
	selectProfileTrackerByType,
	selectProfileTrackerLoadingStatus,
} from '@store/selectors/profile-tracker.selectors';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { ProfileTrackerConnector } from '@shared/firebase/connectors/profile-tracker/profile-tracker-connector.service';
import { EntityChanges } from '@craftnote/shared-utils';
import { LocalStorageService } from '@injectables/services/local-storage.service';

export interface TrackerInput {
	utm_source: string | undefined;
	utm_campaign: string | undefined;
	utm_medium: string | undefined;
	utm_content: string | undefined;
	utm_term: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class ProfileTrackerService {
	constructor(
		private readonly trackerConnector: ProfileTrackerConnector,
		private readonly localStorageService: LocalStorageService,
		private readonly store: Store<AppState>,
	) {}

	async setRegisterTracker(): Promise<void> {
		const marketingTracker = this.getMarketingProfileTracker();
		const invitationTracker = this.getInvitationTracker();

		if ([marketingTracker, invitationTracker].every((tracker) => !tracker)) {
			return;
		}

		const userId = await this.store
			.select(selectUserId)
			.pipe(filter<string>(Boolean), take(1))
			.toPromise();
		await this.store
			.select(selectProfileTrackerLoadingStatus)
			.pipe(
				filter((status) => status === StoreLoadingStatus.LOADED),
				take(1),
			)
			.toPromise();
		const existingTracker = await this.store
			.select(selectProfileTrackerByType('register'))
			.pipe(take(1))
			.toPromise();

		if (existingTracker && !invitationTracker) {
			return;
		}

		const tracker: ProfileTracker = invitationTracker || marketingTracker;

		await this.trackerConnector.createTracker(userId, tracker);

		if (invitationTracker) {
			this.clearInvitationTracker();
		}
	}

	setTrackerToLocalStorage(parameters: TrackerInput): void {
		if (!this.isTrackerInputValid(parameters)) {
			return;
		}
		const trackerName = this.constructTrackerName(parameters);
		this.localStorageService.set('profileTracker', trackerName);
	}

	watchTrackers(userId: string): Observable<EntityChanges<ProfileTracker>> {
		return this.trackerConnector.watchTrackers(userId);
	}

	updateTrackerState(trackerState: EntityChanges<ProfileTracker>): void {
		this.store.dispatch(updateTrackerState({ changes: trackerState }));
	}

	clearTrackerState(): void {
		this.store.dispatch(clearProfileTrackerState());
	}

	private isTrackerInputValid(trackerInput: TrackerInput): boolean {
		return !!trackerInput.utm_source;
	}

	private constructTrackerName(parameters: TrackerInput): string {
		const source = parameters.utm_source || 'null';
		const campaign = parameters.utm_campaign || 'null';
		const medium = parameters.utm_medium || 'null';
		const content = parameters.utm_content || 'null';
		const term = parameters.utm_term || 'null';

		return [source, campaign, medium, content, term].join('::');
	}

	async setInvitationTrackerToLocalStorage(): Promise<void> {
		this.localStorageService.set('invitationTracker', 'company-invitation');
	}

	private clearInvitationTracker(): void {
		this.localStorageService.set('invitationTracker', null);
	}

	getInvitationTracker(): ProfileTracker | null {
		return this.getRegistrationTracker('invitationTracker', 'transactional');
	}

	getMarketingProfileTracker(): ProfileTracker | null {
		return this.getRegistrationTracker('profileTracker', 'web');
	}

	private getRegistrationTracker(
		tracker: 'profileTracker' | 'invitationTracker',
		trackerProvider: 'adjust' | 'web' | 'transactional',
	): ProfileTracker | null {
		const trackerName = this.localStorageService.getSync(tracker);

		if (!trackerName) {
			return null;
		}

		return {
			name: trackerName,
			provider: trackerProvider,
			type: 'register',
		};
	}
}
