import { NgModule } from '@angular/core';
import { GlobalSearchDialogComponent } from './global-search-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchDialogService } from './global-search-dialog.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ShowMoreItemsModule } from '../../directives/show-more-items.module';
import { ProjectAddressPipeModule } from '../../pipes/project-address/project-address-pipe.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { UnixToMillisPipeModule } from '@modules/shared/pipes/unix-to-millis/unix-to-millis-pipe.module';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	imports: [
		MatDialogModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatIconSizeModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
		ShowMoreItemsModule,
		ProjectAddressPipeModule,
		SharedModule,
		NgxTrimDirectiveModule,
		UnixToMillisPipeModule,
	],
	declarations: [GlobalSearchDialogComponent],
	exports: [GlobalSearchDialogComponent],
	providers: [GlobalSearchDialogService],
})
export class GlobalSearchDialogModule {}
