import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Member, Task, TaskTime } from 'domain-entities';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, map, pluck, take } from 'rxjs/operators';
import { keyBy } from 'lodash';
import { TaskUI } from '@modules/features/tasks-dashboard/tasks-dashboard.component';
import { selectCompanyMembers } from '@store/selectors/app.selectors';
import { selectProject } from '@store/selectors/projects.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskEditWorkingHoursUpdateComponent } from '@modules/shared/components/task-edit/task-edit-working-hours-update/task-edit-working-hours-update.component';
import { TaskFilterBy } from '../../project-tasks-list/project-tasks-list.component';
import { TasksService } from '@injectables/services/tasks/tasks.service';

type ProjectTaskEditMainWrapperClass =
	| 'secondary-view-active'
	| 'working-hours-active'
	| 'task-edit-files-active'
	| 'tertiary-view-active'
	| 'working-hours-update-active'
	| 'task-comments-active';

@Component({
	selector: 'app-project-task-edit',
	templateUrl: './project-task-edit.component.html',
	styleUrls: ['./project-task-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTaskEditComponent implements OnInit {
	@ViewChild(TaskEditWorkingHoursUpdateComponent)
	taskEditWorkingHoursUpdateComponent: TaskEditWorkingHoursUpdateComponent;
	private taskUI$$: Subject<TaskUI> = new ReplaySubject(1);
	taskUI$: Observable<TaskUI> = this.taskUI$$.asObservable();
	mainWrapperClasses: ProjectTaskEditMainWrapperClass[] = [];
	taskIsInvalid = false;

	constructor(
		private readonly cdr: ChangeDetectorRef,
		private readonly store: Store,
		private readonly activatedRoute: ActivatedRoute,
		private readonly tasksService: TasksService,
		private readonly router: Router,
	) {}

	async ngOnInit(): Promise<void> {
		const taskId = this.activatedRoute.snapshot.params['taskId'];
		if (taskId) {
			let task;
			try {
				task = await this.tasksService.getTaskById(taskId).pipe(take(1)).toPromise();
				this.taskIsInvalid = false;
			} catch (e) {
				this.taskIsInvalid = true;
			}
			if (task) {
				await this.initTaskUI(task);
			}
			this.cdr.detectChanges();
		}
	}

	async initTaskUI(task: Task): Promise<void> {
		const project = await this.store
			.select(selectProject, { projectId: task.projectId })
			.pipe(first())
			.toPromise();
		if (!project) {
			this.taskIsInvalid = true;
			return;
		}
		let assigneeEntity = null;
		if (task.assigneeId) {
			assigneeEntity = await this.getAssigneeById(task.assigneeId).pipe(first()).toPromise();
		}

		const taskUI: TaskUI = {
			task,
			project,
			assigneeEntity,
		};

		this.taskUI$$.next(taskUI);
	}

	getAssigneeById(id: string): Observable<Member> {
		return this.store.select(selectCompanyMembers).pipe(
			map((companyMembers) => keyBy(companyMembers, 'id')),
			pluck(id),
		);
	}

	async openTaskList(): Promise<void> {
		const taskUI: TaskUI = await this.taskUI$.pipe(first()).toPromise();
		await this.router.navigate(['projects', taskUI.project.id, 'tasks'], {
			queryParams: {
				filter:
					this.activatedRoute.snapshot.queryParamMap.get('filter') === TaskFilterBy.COMPLETED
						? TaskFilterBy.COMPLETED
						: TaskFilterBy.OPEN,
			},
		});
	}

	async cancelEdit(): Promise<void> {
		await this.openTaskList();
	}

	async deleteTask(): Promise<void> {
		await this.openTaskList();
	}

	openWorkingHours(): void {
		this.mainWrapperClasses = [];
		this.mainWrapperClasses.push('secondary-view-active', 'working-hours-active');
	}

	openTaskFiles(): void {
		this.mainWrapperClasses = [];
		this.mainWrapperClasses.push('secondary-view-active', 'task-edit-files-active');
	}

	openTaskComments(): void {
		this.mainWrapperClasses = [];
		this.mainWrapperClasses.push('secondary-view-active', 'task-comments-active');
	}

	openWorkingHoursUpdate(workingHour: TaskTime): void {
		this.mainWrapperClasses = [];
		this.mainWrapperClasses.push('tertiary-view-active', 'working-hours-update-active');
		this.taskEditWorkingHoursUpdateComponent.openTaskEditWorkingHoursUpdate(workingHour);
	}

	openTaskEditDetails(): void {
		this.mainWrapperClasses = [];
	}
}
