import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { ExportDialogComponent } from './export-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
	declarations: [ExportDialogComponent],
	imports: [SharedModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule],
})
export class ExportDialogModule {}
