import { Routes } from '@angular/router';
import { PasswordForgottenComponent } from './password-forgotten.component';

export const passwordForgottenRoute: Routes = [
	{
		path: '',
		component: PasswordForgottenComponent,
		pathMatch: 'full',
	},
];
