import { Injectable } from '@angular/core';
import { clearEntities } from '@craftnote/shared-utils';
import { CompanyTemplatesService } from '@injectables/services/company-templates.service';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { PerformanceTraceService } from '@injectables/services/performance-trace.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PerformanceTrace, PerformanceTraceConstants } from '@shared/constants/performace-trace';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap, startWith, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import * as fromProjectFilesActions from '../actions/project-files.actions';
import * as fromProjectsActions from '../actions/project.actions';
import { initActiveAndArchivedProjectsAction } from '../actions/project.actions';
import { selectCompanyId, selectUserId } from '../selectors/app.selectors';
import { selectProjectFilePathById } from '../selectors/project-files.selectors';
import { selectActiveProject } from '../selectors/route.selectors';
import { AppState } from '../state/app.state';

@Injectable()
export class ProjectsEffects implements OnInitEffects {
	loadActiveAndArchivedProjects = createEffect(() =>
		this.actions$.pipe(
			ofType(initActiveAndArchivedProjectsAction),
			switchMapTo(this.store.select(selectUserId)),
			tap((userId) => {
				if (!userId) {
					return;
				}

				this.performanceTraceService.start(PerformanceTrace.COLLECTION_PROJECTS);
			}),
			switchMap((userId) => {
				const activeChanges = this.projectService.initWatchActiveProjects(userId).pipe(
					tap((entityState) =>
						this.performanceTraceService.logTraceWhenEntitiesCreated(
							entityState,
							PerformanceTrace.COLLECTION_PROJECTS,
							{
								name: PerformanceTraceConstants.PROJECT_COUNT,
								count: entityState.entities.length,
							},
						),
					),
					startWith(clearEntities),
				);
				return combineLatest([activeChanges]).pipe(
					map(([active]) => ({
						active,
					})),
				);
			}),
			switchMap((entityChanges) => [
				fromProjectsActions.updateActiveProjectsAction({ changes: entityChanges.active }),
			]),
		),
	);

	initLoadArchivedProjects$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProjectsActions.initArchivedProjectsAction),
			take(1),
			switchMapTo(this.store.select(selectUserId)),
			switchMap((userId) => {
				return userId ? this.projectService.initWatchArchivedProjects(userId) : of(clearEntities);
			}),
			map((changes) => fromProjectsActions.updateArchivedProjectsAction({ changes })),
		),
	);

	loadProjectFilePaths$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromProjectFilesActions.loadProjectFilePathAction),
				mergeMap((action) => {
					return combineLatest([
						this.store.select(selectCompanyId),
						this.store.select(selectActiveProject),
						this.store.select(selectProjectFilePathById, { id: action.file.id }),
						of(action.file),
					]).pipe(take(1));
				}),
				tap(async ([companyId, projectId, filePath, file]) => {
					if (filePath) {
						return;
					}

					this.store.dispatch(
						fromProjectFilesActions.setLoadingFilePathAction({
							filePath: { id: file.id, isLoaded: false },
						}),
					);

					let thumbnail;

					if (file.fileCategory === 'company-file') {
						thumbnail = await this.companyTemplateService.getFile(companyId, file.base, true);
					} else {
						thumbnail = await this.fileService.getFile(projectId, file.base, true);
					}

					this.store.dispatch(
						fromProjectFilesActions.addProjectFilePathAction({
							filePath: { id: file.id, thumbnail, isLoaded: true },
						}),
					);
				}),
			),
		{ dispatch: false },
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly fileService: FileExplorerService,
		private readonly companyTemplateService: CompanyTemplatesService,
		private readonly projectService: ProjectService,
		private readonly performanceTraceService: PerformanceTraceService,
	) {}

	ngrxOnInitEffects(): Action {
		return fromProjectsActions.initActiveAndArchivedProjectsAction();
	}
}
