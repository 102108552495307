import {
	Directive,
	ElementRef,
	EventEmitter,
	Inject,
	NgZone,
	OnDestroy,
	Output,
} from '@angular/core';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { WINDOW } from '@craftnote/shared-utils';

export interface BreadcrumbsResizedEvent {
	height: number;
	width: number;
	fontSize: number;
}

@Directive({
	selector: '[appOnResize]',
})
export class OnResizeDirective implements OnDestroy {
	@Output() onResized = new EventEmitter<BreadcrumbsResizedEvent>();

	private resizeSensor;

	constructor(
		private readonly eleRef: ElementRef,
		private readonly ngZone: NgZone,
		@Inject(WINDOW) private readonly windowRef: Window,
	) {
		this.ngZone.run(this.initRenderer.bind(this));
	}

	initRenderer(): void {
		this.resizeSensor = new ResizeSensor(
			this.eleRef.nativeElement,
			this.fireResizeEvent.bind(this),
		);
	}

	fireResizeEvent(event: BreadcrumbsResizedEvent): void {
		const fontSize = parseFloat(
			this.windowRef
				.getComputedStyle(this.eleRef.nativeElement, null)
				.getPropertyValue('font-size'),
		);
		this.onResized.emit({ ...event, fontSize });
	}

	ngOnDestroy(): void {
		this.resizeSensor.detach();
	}
}
