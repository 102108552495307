import { DatePipe, DOCUMENT } from '@angular/common';
import {
	Component,
	ElementRef,
	Inject,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
	BehaviorSubject,
	combineLatest,
	firstValueFrom,
	Observable,
	of,
	Subject,
	Subscription,
	throttleTime,
} from 'rxjs';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	distinctUntilKeyChanged,
	filter,
	first,
	map,
	mergeMap,
	pairwise,
	skipWhile,
	startWith,
	switchMap,
	take,
	takeUntil,
	takeWhile,
} from 'rxjs/operators';
import { ShrinkOutAnimation } from '@shared/animations/shrink-out-animation';
import { JPG, PDF } from '@shared/constants/upload.types';
import { EmptyStateEnum } from '@modules/shared/components/empty-state/empty-state.enum';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ChatMessageService } from '@injectables/services/chat/chat-message.service';
import { ChatService } from '@injectables/services/chat/chat.service';
import { CompanyService } from '@injectables/services/company/company.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { ProjectType } from '@shared/models/project.type';
import { Search } from '@shared/models/search.model';
import { Task } from '@shared/models/task.model';
import { FileExplorerSortHelper } from '../file-explorer/file-explorer-sort-helper';
import { ChatDialogService } from '@injectables/services/chat-dialog/chat-dialog.service';
import { Company } from '@shared/models/company.model';
import { Message, MessageType, Profile, Project } from 'domain-entities';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { Store } from '@ngrx/store';
import * as fromMessageActions from '@modules/features/chat/store/actions/message.actions';
import {
	clearInputCache,
	updateInputCache,
} from '@modules/features/chat/store/actions/message.actions';
import * as fromMessageSelectors from '@modules/features/chat/store/selectors/message.selectors';
import {
	selectedParentMessage,
	selectInputCacheOfProject,
	selectLastVisibleMessage,
	selectMessageById,
	selectMessagesUIState,
	selectReplyMessage,
} from '@modules/features/chat/store/selectors/message.selectors';
import { skipWhenTrue } from '@craftnote/shared-utils';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AppState } from '@store/state/app.state';
import { updateLastActiveFactory } from '@shared/firebase/project/project-update.functions';
import { selectCompanyId, selectTheme, selectUserId } from '@store/selectors/app.selectors';
import { CreationExport, ExportService } from '@injectables/export/export.service';
import {
	isInternalProjectAdmin,
	isUserOwnerOrSupervisorOfProject,
} from '@shared/functions/project/project.functions';
import { selectActiveProject, selectRightSideMenu } from '@store/selectors/route.selectors';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
	selectCurrentUserAsMemberInCurrentProject,
	selectProject,
} from '@store/selectors/projects.selectors';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	ChatTagmenuOpenedEventBuilder,
	ChatTextCreatedEventBuilder,
} from '@generated/events/ChatEvents.generated';
import { AppTheme } from '@store/reducers/app.reducer';
import { delay as lodashDelay, has, isEqual, noop, unset } from 'lodash';
import { checkMessageType } from './utils/utils';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { MessageService } from '@injectables/services/message/message.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { TitleService } from '@injectables/services/title.service';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	providers: [FileExplorerSortHelper],
	animations: [ShrinkOutAnimation],
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('messageContainer') messageContainer: ElementRef;
	@ViewChild('chatMessageInput') chatMessageInput: ElementRef;
	@ViewChild('emojiMenu') emojiMenu: MatMenuTrigger;
	@ViewChild('autosize') autosize: CdkTextareaAutosize;

	private setScrollTop$ = new BehaviorSubject<number>(200);
	public isPdfExportEnabled$: Observable<boolean> = this.store.select(
		fromMessageSelectors.messagePdfExportEnabled,
	);
	private laseMessageId$: Observable<string | number> = this.store.select(
		fromMessageSelectors.selectLastMessageId,
	);
	private messageDates$: Observable<{ [id: string]: string }> = this.store.select(
		fromMessageSelectors.messageDates,
	);
	private profile: Profile;
	private messagesNotSend = {};
	private filesLoaded = [];
	private projectSubscription: Subscription;
	private destroy$: Subject<boolean> = new Subject();
	private skipNextScroll = false;
	private routeSub: Subscription;
	private hasEarlierMessages$: Observable<boolean> = this.store.select(
		fromMessageSelectors.messagesHasEarlier,
	);

	profileLimitKey = ProfileLimitKey;
	project: Project;
	tagsVisible = false;
	tagsActiveMessage: Message;
	activeTask: Task;
	messageDates = [];
	files = [];
	content: string;
	search: Search = {};
	isFolder = true;
	company: Observable<Company>;
	MessageType = MessageType;
	pdfExportActive = false;
	exportAll = false;
	exportList = [];
	docExportType = 'pdf';
	type = EmptyStateEnum.PROJECT_CHAT;
	replyMessage$ = this.store.select(selectReplyMessage);
	messageToFile: Message;
	eventShareMessage: boolean;
	chatMessages$: Observable<Message[]>;
	previewAvailable: { [key: string]: boolean } = {};
	isLoading$: Observable<boolean> = this.store.select(fromMessageSelectors.messagesLoading);
	isSearchedOrFiltered$: Observable<boolean> = this.store.select(
		fromMessageSelectors.messagesSearchEnabled,
	);
	isFilterEnabled$: Observable<boolean> = this.store.select(
		fromMessageSelectors.messagesFilterEnabled,
	);
	selectedParentMessage$: Observable<string> = this.store
		.select(selectedParentMessage)
		.pipe(delay(500));
	emojiTranslatedKeys = this.getTranslatedKey('emoji');
	getChatContentClass$: Observable<string> = combineLatest([
		this.isFilterEnabled$,
		this.isPdfExportEnabled$,
	]).pipe(
		map(([isFilterEnabled, isPdfExportEnabled]) =>
			isPdfExportEnabled && isFilterEnabled
				? 'chat-content-height-search-export'
				: isFilterEnabled
				? 'chat-content-height-search'
				: isPdfExportEnabled
				? 'chat-content-height-export'
				: 'chat-content-height-normal',
		),
	);

	getSearchText$: Observable<string> = this.store
		.select(fromMessageSelectors.selectSearchOptions)
		.pipe(map((searchOptions) => this.getSearchType(searchOptions)));

	isDarkTheme$ = this.store.select(selectTheme).pipe(map((theme) => theme === AppTheme.DARK));

	userCanExportChat$: Observable<boolean>;

	private currentProjectName: string;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly projectService: ProjectService,
		private readonly authService: AuthService,
		private readonly profileService: ProfileService,
		private readonly chatService: ChatService,
		private readonly chatMessageService: ChatMessageService,
		private readonly messageService: MessageService,
		private readonly dialog: ChatDialogService,
		private readonly datePipe: DatePipe,
		private readonly translateService: TranslateService,
		private readonly localStorageService: LocalStorageService,
		private readonly companyService: CompanyService,
		private readonly database: AngularFireDatabase,
		private readonly store: Store<AppState>,
		private readonly exportService: ExportService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly router: Router,
		@Inject(DOCUMENT) private readonly documentRef: Document,
		private _ngZone: NgZone,
		private readonly trackingService: TrackingService,
		private titleService: TitleService
	) {
		this.initProject();
		this.subscribeToProjectUpdates();
		this.initTaggingState();
		this.initUserCanExportChat();
		this.subscribeToQueryParam('chatMessage', (messageId) =>
			lodashDelay(this.scrollToMessageId.bind(this, messageId), 1000, 'later'),
		);
	}

	ngOnInit(): void {
		this.store
			.select(fromMessageSelectors.messagesFilterEnabled)
			.pipe(takeUntil(this.destroy$))
			.subscribe((isEnabled) => {
				if (isEnabled) {
					this.activeTask = null;
				}
			});
		this.store
			.select(fromMessageSelectors.messagePdfExportEnabled)
			.pipe(takeUntil(this.destroy$))
			.subscribe((isEnabled) => {
				this.pdfExportActive = isEnabled;
			});

		this.setScrollTop$
			.pipe(
				takeUntil(this.destroy$),
				skipWhile(() => this.skipNextScroll),
				debounceTime(150),
			)
			.subscribe((scrollTop) => {
				if (!this.messageContainer) {
					return;
				}

				this.messageContainer.nativeElement.scrollTop = scrollTop;
			});

		const isNotificationsVisible = this.localStorageService.getSync('isShowStatusMessages');
		this.chatMessages$ =
			isNotificationsVisible !== null && isNotificationsVisible === 'hide'
				? this.store.select(fromMessageSelectors.selectMessagesWithoutTasks)
				: this.store.select(fromMessageSelectors.selectAllVisibleMessages);

		this.loadProfileAndCompany();
		if (this.routeSub) {
			this.routeSub.unsubscribe();
		}

		this.routeSub = this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(noop);

		this.initParentMessageScroll();
		this.initLastActiveChanges();
	}

	ngOnDestroy(): void {
		if (this.routeSub) {
			this.routeSub.unsubscribe();
		}
		this.destroy$.next(null);
		this.destroy$.complete();

		this.store.dispatch(fromMessageActions.ClearMessageState());

		this.titleService.resetTitle();
	}

	ngOnChanges(): void {
		this.triggerResize();
	}

	private getTranslatedKey(key: string): Object | string {
		const translatedValue = this.translateService.instant(key);

		switch (typeof translatedValue) {
			case 'string':
				return translatedValue;
			case 'function':
				return translatedValue.call();
			case 'object':
				const translatedObj = {};
				Object.keys(translatedValue).forEach(
					(objKey) => (translatedObj[objKey] = this.getTranslatedKey(`${key}.${objKey}`)),
				);
				return translatedObj;
		}
	}

	private setFocusToChatMessage(startPosition = 0): void {
		setTimeout(() => {
			this.setCursorPosition(this.chatMessageInput.nativeElement, startPosition);
		}, 0);
	}

	private getSearchType(searchOptions: Search): string {
		let search: string;
		if (searchOptions.from && !searchOptions.to) {
			search = this.datePipe.transform(searchOptions.from, 'dd.MM.yyyy');
		} else if (searchOptions.to && !searchOptions.from) {
			search = this.datePipe.transform(searchOptions.to, 'dd.MM.yyyy');
		} else if (searchOptions.from && searchOptions.to) {
			search =
				this.datePipe.transform(searchOptions.from, 'dd.MM.yyyy') +
				' - ' +
				this.datePipe.transform(searchOptions.to, 'dd.MM.yyyy');
		}

		if (searchOptions.text) {
			if (search) {
				search += ' | ';
			} else {
				search = '';
			}

			search += `'${searchOptions.text}'`;
		}

		if (searchOptions.type) {
			if (search) {
				search += ' | ';
			} else {
				search = '';
			}
			for (const type of searchOptions.type) {
				search += type + ' | ';
			}

			search = search.slice(0, -2);
		}

		if (searchOptions.member) {
			if (search) {
				search += ' | ';
			} else {
				search = '';
			}

			search += searchOptions.member.name + ' ' + searchOptions.member.lastname;
		}

		return search;
	}

	onPaste(event: ClipboardEvent): void {
		const items = Array.from(event.clipboardData.items);
		const dataTransfer = new DataTransfer();
		items
			.filter(({ kind }) => kind === 'file')
			.forEach((item) => dataTransfer.items.add(item.getAsFile()));
		if (dataTransfer.files.length) {
			event.preventDefault();
			this.chatService.addToQueue(dataTransfer.files, this.profile, this.project.id);
		}
	}

	private initParentMessageScroll(): void {
		this.store
			.select(selectedParentMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((selectedMessageId) => !!selectedMessageId),
			)
			.subscribe((selectedMessageId) => {
				setTimeout(() => {
					this.skipNextScroll = true;
					this.messageContainer.nativeElement.children.namedItem(selectedMessageId).scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
					this.store.dispatch(fromMessageActions.clearSelectedParentMessageAction());
				});
			});
	}

	private initTaggingState(): void {
		this.store
			.select(selectRightSideMenu)
			.pipe(takeUntil(this.destroy$), filter<string>(Boolean))
			.subscribe(() => this.onCloseTagsDialog());
	}

	private loadProfileAndCompany(): void {
		const profile$ = this.profileService.getProfile(this.authService.currentUserId()).pipe(take(1));
		profile$.subscribe((profile) => (this.profile = profile));

		this.company = profile$.pipe(
			switchMap((profile: Profile) => this.companyService.getCompany(profile.company)),
			take(1),
		);
	}

	private initProject(): void {
		this.store
			.select(selectActiveProject)
			.pipe(takeUntil(this.destroy$))
			.subscribe((projectId) => {
				this.loadProject(projectId);
			});
	}

	private subscribeToProjectUpdates(): void {
		this.store
			.select(selectActiveProject)
			.pipe(
				switchMap((projectId) => this.store.select(selectProject, { projectId })),
				takeUntil(this.destroy$),
				startWith(null),
				pairwise(),
			)
			.subscribe(async ([projectPrevious, projectCurrent]) => {
				if (!projectCurrent) {
					return;
				}
				this.project = projectCurrent;
				if (projectCurrent.id !== projectPrevious?.id) {
					this.content = await firstValueFrom(
						this.store.select(selectInputCacheOfProject(this.project.id)),
					);
				}
			});
	}

	private loadProject(projectId: string): void {
		if (!projectId) {
			this.resetProjectState();
			return;
		}

		this.store.dispatch(fromMessageActions.ClearMessageState());

		this.projectSubscription = this.projectService
			.getProject(projectId)
			.pipe(
				filter((project) => !!project),
				take(1),
			)
			.subscribe((project) => {
				if (this.projectSubscription) {
					this.projectSubscription.unsubscribe();
				}

				this.search = {};
				this.setupProject(project);
				this.updateProjectTitle(project.name);
				this.subscribeToProjectChanges(projectId);
			});
	}

	private setupProject(project: Project): void {
		this.project = project;
		this.isFolder = this.project.projectType === ProjectType.FOLDER;
		if (!this.isFolder) {
			this.loadMessages();
		}
	}

	private updateProjectTitle(projectName: string): void {
		if (this.currentProjectName !== projectName) {
			this.currentProjectName = projectName;
			this.titleService.setProjectTitle(projectName);
		}
	}

	private subscribeToProjectChanges(projectId: string): void {
		this.store
			.select(selectProject, { projectId })
			.pipe(
				filter(Boolean),
				takeUntil(this.destroy$),
				distinctUntilChanged((prev, curr) => prev.name === curr.name)
			)
			.subscribe((project) => {
				this.project = project;
				this.updateProjectTitle(project.name);
			});
	}

	private resetProjectState(): void {
		this.project = null;
		this.isFolder = false;
		this.store.dispatch(setSelectedFolderAction({ folderId: null }));
		this.titleService.resetTitle();
		this.currentProjectName = null;
		this.tagsVisible = false;
		this.activeTask = null;
		this.store.dispatch(fromMessageActions.PdfExportDisable());
		this.exportAll = false;
		this.exportList = [];
		this.messageDates = [];
		this.messageToFile = null;
		this.eventShareMessage = false;
	}

	private loadMessages(): void {
		this.store.dispatch(fromMessageActions.LoadMessagesByProject({ projectId: this.project.id }));
	}

	sendMessage(type, event?: Event): void {
		event?.preventDefault();
		this.store.dispatch(clearInputCache({ projectId: this.project.id }));
		if (this.content == null || this.content.trim().length === 0) {
			this.content = null;
			return;
		}

		this.projectService
			.getProject(this.project.id)
			.pipe(take(1))
			.subscribe(async () => {
				const message = this.content.trim();
				this.content = null;
				const newMessageId = this.database.createPushId();
				const newMessage: Message = {
					id: newMessageId,
					projectId: this.project.id,
					timestamp: Math.floor(new Date().getTime() / 1000),
					authorId: this.authService.currentUserId(),
					author: this.profile.name + ' ' + this.profile.lastname,
					messageType: type,
					content: message,
				};

				const replyMessage = await this.replyMessage$.pipe(take(1)).toPromise();

				if (replyMessage) {
					const { id, messageType, authorId, content, fileName, duration = 0 } = replyMessage;
					newMessage.parent = { id, messageType, authorId, content, duration };
					if (fileName) {
						newMessage.parent.fileName = fileName;
					}
				}

				this.messagesNotSend[newMessage.timestamp] = true;

				this.chatService.sendMessage(this.project.id, newMessage).then(async () => {
					delete this.messagesNotSend[newMessage.timestamp];
					this.clearReplyMessage();
					return this.trackingService.trackEvent(
						new ChatTextCreatedEventBuilder({
							projectId: this.project.id,
						}),
					);
				});
			});
	}

	isOwnerOrSupervisor(): boolean {
		return isUserOwnerOrSupervisorOfProject(this.authService.currentUserId(), this.project);
	}

	fileEvent(event): void {
		this.chatService.addToQueue(event.target.files, this.profile, this.project.id);
		event.target.value = '';
	}

	getFile(message: Message): void {
		if (this.filesLoaded[message.id] || message.messageType === MessageType.TEXT) {
			return;
		}

		this.filesLoaded[message.id] = true;

		if (
			message.messageType === MessageType.DOCUMENT &&
			this.chatMessageService.getFileType(message.content) !== PDF
		) {
			this.previewAvailable[message.id] = false;
			return;
		}

		let thumbnail = false;
		let jpg = false;

		if (message.messageType === MessageType.VIDEO) {
			thumbnail = true;
			jpg = true;
		}

		if (message.messageType === MessageType.IMAGE) {
			thumbnail = true;
		}

		if (
			message.messageType === MessageType.DOCUMENT &&
			this.chatMessageService.getFileType(message.content) === PDF
		) {
			thumbnail = true;
		}

		this.chatService
			.getFile(
				this.project.id,
				message,
				jpg ? JPG : this.chatMessageService.getFileType(message.content),
				thumbnail,
			)
			.then((result) => {
				if (!result) {
					unset(this.files, message.id);
					this.previewAvailable[message.id] = false;
					return;
				}
				this.previewAvailable[message.id] = true;
				this.files[message.id] = result;
			})
			.catch(() => {
				this.previewAvailable[message.id] = false;
				unset(this.files, message.id);
			});
	}

	async goToTagging(message): Promise<void> {
		await this.router.navigate(['.'], {
			relativeTo: this.activatedRoute.children[0],
		});

		this.tagsVisible = true;
		this.tagsActiveMessage = message;
		this.activeTask = null;

		return this.trackingService.trackEvent(
			new ChatTagmenuOpenedEventBuilder({
				projectId: this.project.id,
			}),
		);
	}

	checkPreviewAvailablity(message: Message): Observable<boolean> {
		const isPreviewAvailable = !has(this.previewAvailable, message.id)
			? true
			: this.previewAvailable[message.id];

		return of(isPreviewAvailable);
	}

	onCloseTagsDialog(): void {
		this.tagsVisible = false;
	}

	showFile(message: Message): void {
		this.dialog.open({
			id: this.project.id,
			type: message.messageType,
			currentMessage: message,
		});
	}

	scrollEvent(event): void {
		if (event.isReachingTop && !event.isWindowEvent && !this.pdfExportActive) {
			this.hasEarlierMessages$
				.pipe(
					takeWhile((hasEarlier) => hasEarlier),
					skipWhenTrue(this.isSearchedOrFiltered$),
					mergeMap(() => this.laseMessageId$.pipe(take(1))),
					skipWhile((messageId) => !messageId),
				)
				.subscribe((lastMessageId) => {
					this.store.dispatch(
						fromMessageActions.LoadBatchMessagesByProject({
							projectId: this.project.id,
							lastMessageId: lastMessageId as string,
						}),
					);

					if (this.skipNextScroll) {
						this.skipNextScroll = false;
						return;
					}

					this.setScrollTop$.next(200);
				});
		}
	}

	onFilesChange(files: FileList): void {
		this.chatService.addToQueue(files, this.profile, this.project.id);
	}

	async goToPDFExportToggle(): Promise<void> {
		const messageUIState = await this.store.select(selectMessagesUIState).pipe(first()).toPromise();
		this.store.dispatch(fromMessageActions.TogglePdfExport());
		if (!messageUIState.isPdfExportEnabled) {
			this.store.dispatch(fromMessageActions.LoadAllMessages());
		}
		this.exportList = [];
	}

	async selectAll(checked: boolean): Promise<void> {
		this.exportList = [];

		if (!checked) {
			return;
		}

		const chatMessages = await this.chatMessages$.pipe(take(1)).toPromise();

		chatMessages.forEach((message) => {
			if (this.canBeExported(message)) {
				this.exportList.push(message.id);
			}
		});
	}

	getNumberOfMessages(): { count: number } {
		return { count: this.exportList.length };
	}

	async exportToPdf(): Promise<void> {
		const exportFormat =
			this.docExportType === 'pdf'
				? 'application/pdf'
				: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

		const exportEntity: CreationExport = {
			format: exportFormat,
			resourceId: this.project.id,
			resourceType: 'project',
			filter: { messageIds: [...this.exportList] },
		};

		await this.exportService.createExport(exportEntity);
	}

	docTypeChange($event): void {
		this.docExportType = $event.value;
	}

	async shareMessageToFile(message: Message): Promise<void> {
		this.messageToFile = message;
		this.eventShareMessage = true;
		await this.router.navigate(['./files'], {
			relativeTo: this.activatedRoute.children[0],
		});

		this.store.dispatch(fromMessageActions.setSelectChatMessageToShareAction({ message }));
	}

	isTextMessageSend(message: Message): boolean {
		return !this.messagesNotSend[message.timestamp];
	}

	clearSearchAndFilter(): void {
		this.search = {};
		this.store.dispatch(fromMessageActions.ClearSearchAndFilter());
		this.loadMessages();
	}

	canBeExported(message: Message): boolean {
		return (
			checkMessageType(
				message,
				MessageType.TEXT,
				MessageType.IMAGE,
				MessageType.DOCUMENT,
				MessageType.VIDEO,
				MessageType.AUDIO,
			) && !message.deleted
		);
	}

	getMessageDate$(message: Message): Observable<string> {
		return this.messageDates$.pipe(
			take(1),
			map((dates) => dates[message.id]),
		);
	}

	/**
	 * Event doesn't have type in library, So had to mark it as any
	 */
	onEmojiSelect(event: any): void {
		let positionOfSelectionStart = 0;
		if (!this.content) {
			this.content = event.emoji.native;
		} else {
			positionOfSelectionStart = this.chatMessageInput.nativeElement.selectionStart;
			const charLength =
				this.chatMessageInput.nativeElement.selectionEnd -
				this.chatMessageInput.nativeElement.selectionStart;
			const message = this.content.split('');

			message.splice(positionOfSelectionStart, charLength, event.emoji.native);
			this.content = message.join('');
		}

		positionOfSelectionStart += event.emoji.native.length; // Cursor after placing the emoji
		this.emojiMenu.closeMenu();
		this.setFocusToChatMessage(positionOfSelectionStart);
	}

	replyToMessage(message: Message): void {
		this.store.dispatch(fromMessageActions.SetReplyMessage({ message }));
		this.setFocusToChatMessage(this.content?.length);
	}

	clearReplyMessage(): void {
		this.store.dispatch(fromMessageActions.ClearReplyMessage());
	}

	goToParentMessage(message: Message): void {
		this.store.dispatch(fromMessageActions.selectParentMessageAction({ message }));
	}

	chatMessageTrackBy(_index, message: Message): string {
		return message.id;
	}

	updateInputCache(): void {
		this.store.dispatch(updateInputCache({ projectId: this.project.id, text: this.content }));
	}

	private initLastActiveChanges(): void {
		const lastMessage$ = this.store.select(selectLastVisibleMessage).pipe(
			filter((message) => !!message),
			distinctUntilKeyChanged('id'),
			debounceTime(1000),
		);
		const currentUserId$ = this.store.select(selectUserId);
		const currentProjectId$ = this.store.select(selectActiveProject);

		combineLatest([lastMessage$, currentUserId$, currentProjectId$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([lastMessage, currentUserId, currentProjectId]) => {
				if (lastMessage.projectId !== currentProjectId) {
					return;
				}
				const updateFunction = updateLastActiveFactory(currentUserId);
				void this.projectService.updateProjectTransactional(lastMessage.projectId, updateFunction);
			});
	}

	private setCursorPosition(element: HTMLFormElement, position: number): void {
		element.setSelectionRange(position, position);
		element.focus();
	}

	triggerResize(): void {
		// Wait for changes to be applied, then trigger textarea resize.
		this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
	}

	private initUserCanExportChat(): void {
		const activeProject$ = this.store
			.select(selectActiveProject)
			.pipe(mergeMap((projectId) => this.store.select(selectProject, { projectId })));
		const isOwnerOrInternalSupervisorOfProject$ = this.store
			.select(selectCurrentUserAsMemberInCurrentProject)
			.pipe(map(isInternalProjectAdmin));
		const companyId$ = this.store.select(selectCompanyId);
		this.userCanExportChat$ = combineLatest([
			activeProject$,
			companyId$,
			isOwnerOrInternalSupervisorOfProject$,
		]).pipe(
			map(
				([activeProject, companyId, isOwnerOrInternalSupervisorOfProject]) =>
					isOwnerOrInternalSupervisorOfProject &&
					!!activeProject &&
					activeProject.company === companyId,
			),
		);
	}

	private async scrollToMessageId(startFromMessageId: string): Promise<void> {
		const projectId = await firstValueFrom(this.store.select(selectActiveProject));
		const isChatMessageExists = await this.messageService.isChatMessageExists(
			projectId,
			startFromMessageId,
		);

		if (!isChatMessageExists) {
			this.confirmDialogService.open({
				title: this.translateService.instant('chat.chat-message-not found'),
				primaryButtonColor: 'accent',
				showSecondaryButton: false,
				showCrossBtn: false,
				primaryButtonText: this.translateService.instant('button.close'),
			});
			return;
		}
		this.store.dispatch(fromMessageActions.ClearMessages());
		this.store.dispatch(
			fromMessageActions.LoadBatchMessagesByProject({
				projectId: projectId,
				lastMessageId: null,
				startFromMessageId,
			}),
		);
		await firstValueFrom(
			this.store.select(selectMessageById(startFromMessageId)).pipe(filter(Boolean)),
		);

		this.skipNextScroll = true;

		const startFromMessageDocumentId = `#${startFromMessageId}`;
		await this.waitForElement(startFromMessageDocumentId);
		this.messageContainer.nativeElement.querySelector(startFromMessageDocumentId).scrollIntoView();
		this.messageContainer.nativeElement
			.querySelector(startFromMessageDocumentId)
			.classList.add('highlight');
		this.router.navigate([], {
			queryParams: {
				chatMessage: null,
			},
			queryParamsHandling: 'merge',
		});
	}

	private waitForElement(selector): Promise<any> {
		return new Promise((resolve) => {
			if (this.documentRef.querySelector(selector)) {
				resolve(document.querySelector(selector));
			}

			const observer = new MutationObserver(() => {
				if (this.documentRef.querySelector(selector)) {
					resolve(this.documentRef.querySelector(selector));
					observer.disconnect();
				}
			});

			observer.observe(this.documentRef.body, {
				childList: true,
				subtree: true,
			});
		});
	}

	private async subscribeToQueryParam(query: string, callBack: Function): Promise<void> {
		this.isLoading$
			.pipe(
				filter(Boolean),
				switchMap((_) => this.activatedRoute.queryParams),
				map((queryParams) => queryParams[query]),
				distinctUntilChanged(isEqual),
				throttleTime(1000, undefined, { trailing: true, leading: true }),
				filter(Boolean),
				takeUntil(this.destroy$),
			)
			.subscribe(async (...params) => callBack.call(this, ...params));
	}
}