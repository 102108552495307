<section class="project-member-details h-100 overflow-auto p-3">
	<div class="project-member-details__header mb-4">
		<mat-icon class="pointer" (click)="openProjectListView()" data-cy="member-detail-to-overview">arrow_back</mat-icon>
	</div>
	<div class="project-member-details__body">
		<div class="p-4 text-center">
			<mat-icon class="mb-2 account-avatar" svgIcon="account-avatar"></mat-icon>
			<div class="mat-body-2 mb-2">{{ member | memberFullName: null:true:'-' | titlecase }}</div>
			<span class="mat-small craftnote-label" data-cy="craftnote-member-label">
				<ng-container *ngIf="isInternalMember">{{ 'projectMembers.member.internal' | translate }}</ng-container>
				<ng-container *ngIf="isExternalMember || isPendingExternalMember">{{ 'projectMembers.member.external' | translate }}</ng-container>
			</span>
		</div>
		<div class="d-flex align-items-center py-4 border-bottom">
			<div class="mat-body-1">{{ 'projectMembers.member.company' | translate }}</div>
			<div class="mat-body-2 text-right flex-grow-1 text-truncate" data-cy="member-company-name">
				{{ member?.company | isNil: '-' }}
			</div>
		</div>
		<div class="d-flex align-items-center py-4 border-bottom">
			<div class="mat-body-1">{{ 'projectMembers.member.role' | translate }}</div>
			<div class="mat-body-2 text-right flex-grow-1 text-truncate" data-cy="member-company-role">
				<ng-container *ngIf="isProjectAdmin || isPendingProjectAdmin; else employee">
					{{
						'projectOrFolderMembers.member.role.admin'
							| translate
								: {
										projectType: project.projectType == projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
								  }
					}}
				</ng-container>
				<ng-template #employee>
					{{ 'projectMembers.member.role.member' | translate }}
				</ng-template>
			</div>
			<ng-container [ngTemplateOutlet]="roleMenuTemplate" *ngIf="isRoleEditable$ | async"></ng-container>
		</div>
		<div class="d-flex align-items-center py-4 border-bottom">
			<div class="mat-body-1">{{ 'projectMembers.member.email' | translate }}</div>
			<div class="mat-body-2 text-right flex-grow-1 text-truncate" data-cy="member-detail-email">{{ member?.email }}</div>
		</div>
		<div class="d-flex align-items-center py-4">
			<div class="mat-body-1">{{ 'projectMembers.member.mobile' | translate }}</div>
			<div class="mat-body-2 text-right flex-grow-1 text-truncate" data-cy="member-detail-phone">
				{{ member?.mobile | isNil: '-' }}
			</div>
		</div>
		<div
			class="d-flex align-items-center p-3 mb-3 rounded-small background-btn"
			[class.pointer]="isUserProjectAdmin$ | async"
			(click)="changeFunction()"
			data-cy="member-detail-function"
		>
			<div class="mat-body-1">
				<ng-container *ngIf="isUserProjectAdmin$ | async; else editFunction">
					{{ 'projectMembers.member.edit-function' | translate }}
				</ng-container>
				<ng-template #editFunction>
					{{ 'projectMembers.member.function' | translate }}
				</ng-template>
			</div>
			<div class="mat-body-2 text-right flex-grow-1 ml-2 text-truncate">
				{{ member?.function | isNil: '-' }}
			</div>
			<mat-icon class="ml-2 pointer" *ngIf="isUserProjectAdmin$ | async">chevron_right</mat-icon>
		</div>
		<div
			class="d-flex align-items-center p-3 mb-3 rounded-small background-btn"
			[class.pointer]="isUserProjectAdmin$ | async"
			(click)="changeNote()"
			data-cy="member-detail-note"
		>
			<div class="mat-body-1">
				<ng-container *ngIf="isUserProjectAdmin$ | async; else editNote">
					{{ 'projectMembers.member.edit-note' | translate }}
				</ng-container>
				<ng-template #editNote>
					{{ 'projectMembers.member.note' | translate }}
				</ng-template>
			</div>
			<div class="mat-body-2 text-right flex-grow-1 ml-2 text-truncate">
				{{ member?.note | isNil: '-' }}
			</div>
			<mat-icon class="ml-2 pointer" *ngIf="isUserProjectAdmin$ | async">chevron_right</mat-icon>
		</div>
		<button
			mat-flat-button
			color="warn"
			[loading]="isLoading"
			*ngIf="isMemberDeletable$ | async"
			(click)="removeMember()"
			class="w-100 mt-3"
			data-cy="member-detail-remove"
		>
			{{
				'projectOrFolderMembers.member.remove-btn'
					| translate
						: { projectType: project.projectType == projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate) }
			}}
		</button>
	</div>
</section>

<ng-template #roleMenuTemplate>
	<mat-icon class="ml-2 pointer color-primary" [matMenuTriggerFor]="roleMenu" data-cy="external-role-change">edit </mat-icon>
	<mat-menu #roleMenu="matMenu" class="craftnote-menu">
		<button mat-menu-item (click)="changeRole(MemberRole.EXTERNALSUPERVISOR)" data-cy="external-role-change-supervisor">
			{{
				'projectOrFolderMembers.member.role.admin'
					| translate
						: {
								projectType: project.projectType == projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
						  }
			}}
		</button>
		<button mat-menu-item (click)="changeRole(MemberRole.EXTERNAL)" data-cy="external-role-change-employee">
			{{ 'projectMembers.member.role.member' | translate }}
		</button>
	</mat-menu>
</ng-template>
