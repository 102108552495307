<section
	[ngClass]="[orientation === 'row' ? 'justify-content-center flex-row' : 'align-items-center flex-column', 'orientation-' + orientation]"
	class="promotional-content"
>
	<div class="px-2 mb-4">
		<ng-content select="img, #image"></ng-content>
	</div>

	<div class="px-2 promotional-content__text-container" data-cy="promotional-content">
		<h6 class="mat-display-1 font-weight-bold mb-4" data-cy="promotional-text">
			<ng-content select=".content-heading"></ng-content>
		</h6>

		<div class="promotional-content__text-list">
			<ng-content select=".content-list"></ng-content>
		</div>

		<div>
			<ng-content select=".action-buttons"></ng-content>
		</div>
	</div>
</section>
