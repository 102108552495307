import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProjectStatus } from '@store/selectors/app.selectors';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
	selector: 'app-project-status-indicator',
	templateUrl: './project-status-indicator.component.html',
	styleUrls: ['./project-status-indicator.component.scss'],
})
export class ProjectStatusIndicatorComponent implements OnChanges {
	@Input() statusId: string;
	@Input() name: string;
	@Input() colorTagClass: string;

	radius = 55;
	strokeWidth = 5;
	strokeDashGap = 6;
	circumferenceOfCircle = 2 * Math.PI * this.radius;
	statusId$ = new BehaviorSubject<string>(null);

	numberOfStatuses$ = this.store
		.select(selectProjectStatus)
		.pipe(map((projectStatus) => projectStatus.length));

	activatedStatusIndex$ = combineLatest([
		this.store.select(selectProjectStatus),
		this.statusId$.asObservable().pipe(distinctUntilChanged()),
	]).pipe(
		map(
			([projectStatus, statusId]) =>
				projectStatus.findIndex((status) => status.id === statusId) || 0,
		),
	);

	strokeDasharray$ = this.numberOfStatuses$.pipe(
		map((numberOfStatuses) => {
			if (numberOfStatuses === 1) {
				return [this.circumferenceOfCircle, 0];
			}

			const singleStrokeWidth = (numberOfStatuses * this.strokeDashGap) / numberOfStatuses;
			return [
				this.circumferenceOfCircle / numberOfStatuses - singleStrokeWidth,
				this.strokeDashGap,
			];
		}),
	);
	activateStrokeDashArray$ = combineLatest([
		this.strokeDasharray$,
		this.activatedStatusIndex$,
	]).pipe(
		map(([strokeDashArray, activateIndex]) => {
			if (activateIndex === -1) {
				return undefined;
			}

			let strokeArray = [];
			for (let i = 0; i <= activateIndex; i++) {
				strokeArray = [...strokeArray, ...strokeDashArray];
			}
			const remainingOffset =
				this.circumferenceOfCircle -
				(strokeDashArray[0] + this.strokeDashGap) * (activateIndex + 1);
			strokeArray[strokeArray.length - 1] = remainingOffset + this.strokeDashGap * 2;
			return strokeArray;
		}),
	);

	constructor(private readonly store: Store<AppState>) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.statusId) {
			this.statusId$.next(changes.statusId.currentValue);
		}
	}
}
