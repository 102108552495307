import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { isNumber } from 'lodash';

@Pipe({
	name: 'unixToMillis',
})
export class UnixToMillisPipe implements PipeTransform {
	transform(timeStamp: number): number | null {
		return isNumber(timeStamp) ? moment.unix(timeStamp).valueOf() : null;
	}
}
