import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ActivityStateService {
	private readonly INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes
	private readonly HIDDEN_TAB_TIMEOUT = 5 * 60 * 1000; // 5 minutes
	private destroy$ = new Subject<void>();

	private isActive$ = new BehaviorSubject<boolean>(true);
	private isVisible$ = new BehaviorSubject<boolean>(true);

	constructor(@Inject(DOCUMENT) private readonly document: Document) {
		this.initVisibilityTracking();
		this.initActivityTracking();
	}

	public get isActiveAndVisible$(): Observable<boolean> {
		return merge(this.isActive$, this.isVisible$).pipe(
			map(() => this.isActive$.value && this.isVisible$.value),
			distinctUntilChanged(),
		);
	}

	public destroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private initVisibilityTracking(): void {
		// Initial state
		this.isVisible$.next(!this.document.hidden);

		fromEvent(this.document, 'visibilitychange')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				if (this.document.hidden) {
					timer(this.HIDDEN_TAB_TIMEOUT)
						.pipe(takeUntil(merge(fromEvent(this.document, 'visibilitychange'), this.destroy$)))
						.subscribe(() => {
							if (this.document.hidden) {
								this.isVisible$.next(false);
							}
						});
				} else {
					this.isVisible$.next(true);
				}
			});
	}

	private initActivityTracking(): void {
		const events = ['mousedown', 'mousemove', 'scroll'];
		const userActivity$ = merge(...events.map((evt) => fromEvent(this.document, evt)));

		userActivity$
			.pipe(
				debounceTime(1000),
				switchMap(() => {
					this.isActive$.next(true);
					return timer(this.INACTIVITY_TIMEOUT);
				}),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.isActive$.next(false);
			});

		// Start initial inactivity timer
		timer(this.INACTIVITY_TIMEOUT)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.isActive$.next(false);
			});
	}
}
