import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { filter, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { ProfileTrackerService } from '@injectables/profile-tracker/profile-tracker.service';
import { Injectable } from '@angular/core';
import { AppState } from '../state/app.state';
import { Action, Store } from '@ngrx/store';
import { selectUserId } from '../selectors/app.selectors';
import { of } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { ProfileTracker } from 'domain-entities';
import { setUserRegistrationType } from '../actions/auth.actions';
import { loadTrackerState } from '../actions/profile-tracker.actions';
import { UserRegistrationType } from '../state/auth.state';

@Injectable()
export class ProfileTrackerEffects implements OnInitEffects {
	updateLocalStorageTrackers$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setUserRegistrationType),
				filter(({ registrationType }) => registrationType === UserRegistrationType.FRESH),
				tap(async (_) => {
					return this.trackersService.setRegisterTracker();
				}),
			),
		{ dispatch: false },
	);

	constructor(
		private readonly actions$: Actions,
		private readonly trackersService: ProfileTrackerService,
		private readonly store: Store<AppState>,
	) {}

	watchTrackersEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadTrackerState),
				switchMapTo(this.store.select(selectUserId)),
				switchMap((userId) => {
					this.trackersService.clearTrackerState();
					if (!userId) {
						return of(null);
					}
					return this.trackersService.watchTrackers(userId);
				}),
				filter<EntityChanges<ProfileTracker>>(Boolean),
				tap((changes) => {
					this.trackersService.updateTrackerState(changes);
				}),
			),
		{ dispatch: false },
	);

	ngrxOnInitEffects(): Action {
		return loadTrackerState();
	}
}
