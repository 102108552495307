import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { PaywallDialogComponent } from './paywall-dialog.component';
import { PaywallDialogService } from './paywall-dialog.service';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	declarations: [PaywallDialogComponent],
	imports: [SharedModule, MatDialogModule, MatButtonModule, MatIconModule, FormsModule],
	exports: [],
	providers: [PaywallDialogService],
})
export class PaywallDialogModule {}
