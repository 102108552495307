<section class="project-external-member d-flex flex-column h-100">
	<div class="p-3 d-flex flex-column flex-grow-1 overflow-auto">
		<div class="project-external-member__header">
			<div class="d-flex align-items-center">
				<mat-icon class="pointer" (click)="openProjectListView()" data-cy="external-to-overview">arrow_back</mat-icon>
				<div class="mat-body-2 mx-3 text-center flex-grow-1" data-cy="external-members-area">
					{{ 'projectMembers.external.header' | translate }}
				</div>
			</div>
		</div>
		<div class="project-external-member__body flex-grow-1 overflow-auto">
			<div class="my-3">
				<div class="mat-body-2 mb-2">{{ 'projectMembers.external.title' | translate }}</div>
				<div class="mat-body-1 mb-2 project-external-member__subtitle">
					{{ 'projectMembers.external.subtitle' | translate: { projectName: (projectName$ | async) } }}
				</div>
				<form [formGroup]="externalMemberForm" class="text-center" (submit)="addExternalMember()">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>{{ 'projectMembers.external.email' | translate }}</mat-label>
						<input
							trim="blur"
							matInput
							formControlName="email"
							data-cy="external-email-input"
							autocomplete="off"
							#ExternalMemberInput
							autofocus
						/>
						<div matSuffix class="d-flex">
							<button mat-icon-button *ngIf="memberEmail?.length > 0" (click)="resetMemberForm()" type="reset">
								<mat-icon>clear</mat-icon>
							</button>
						</div>
						<mat-error *ngIf="externalMemberForm.controls['email'].dirty && externalMemberForm.controls['email'].errors">
							<span *ngIf="externalMemberForm.controls['email'].errors?.required">
								{{ 'projectMembers.external.errors.email.required' | translate }}
							</span>
							<span *ngIf="externalMemberForm.controls['email'].errors?.pattern">
								{{ 'projectMembers.external.errors.email.pattern' | translate }}
							</span>
							<span *ngIf="externalMemberForm.controls['email'].errors?.projectMemberExists">
								{{ 'projectMembers.external.errors.email.project-member-exists' | translate }}
							</span>
							<span *ngIf="externalMemberForm.controls['email'].errors?.companyMemberExists">
								{{ 'projectMembers.external.errors.email.company-member-exists' | translate }}
							</span>
						</mat-error>
					</mat-form-field>
					<button
						mat-flat-button
						color="accent"
						type="submit"
						[disabled]="externalMemberForm.invalid || isLoading"
						[loading]="isLoading"
						class="my-1"
						data-cy="invite-external-button"
					>
						{{ 'projectMembers.external.invite-btn' | translate }}
					</button>
				</form>
			</div>
		</div>
	</div>
</section>
