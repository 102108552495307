<section class="project-internal-member">
	<div class="project-internal-member__content px-2 py-2">
		<div class="project-internal-member__header">
			<div class="d-flex align-items-center"> 
				<mat-icon class="pointer" (click)="openProjectListView()" data-cy="back-internal-members-overview">arrow_back</mat-icon>
				<div class="mat-body-2 mx-3 flex-grow-1 text-center" data-cy="internal-members-area">
					{{ 'projectMembers.internal.header' | translate }}
				</div>
			</div>
			<div class="my-3">
				<form [formGroup]="memberSearchForm" class="flex-grow-1 d-flex align-items-center chat-search-form">
					<mat-form-field appearance="fill" class="flex-grow-1">
						<input
							matInput
							formControlName="text"
							data-cy="internal-member-searchbox"
							[placeholder]="'projectMembers.internal.search' | translate"
							autocomplete="off"
						/>
						<div matSuffix class="d-flex">
							<button mat-icon-button *ngIf="searchText?.length > 0" (click)="onResetChatSearchForm()" type="reset">
								<mat-icon data-cy="internal-members-search-clear">clear</mat-icon>
							</button>
							<button mat-icon-button type="submit">
								<mat-icon data-cy="internal-members-search">search</mat-icon>
							</button>
						</div>
					</mat-form-field>
				</form>
				<div class="my-2 search-hint" [innerHTML]="'projectMembers.internal.hint' | translate: { companyName: (companyName$ | async) }"></div>
			</div>
		</div>

		<mat-checkbox *ngIf="(currentProject$ | async)?.projectType === ProjectType.FOLDER" 
			[(ngModel)]="folderAndChildren" 
			class="mb-2">
			{{ 'project-internal-member.apply-to-all-checkbox' | translate }}
		</mat-checkbox>

		<div class="project-internal-member__body">
			<div class="position-sticky top-0 bg-white z-index-1 pb-2" *ngIf="filteredProjectMembers$ | async as projectMembers">
				<mat-checkbox
					*ngIf="projectMembers.length > 0"
					[checked]="isAllProjectMembersChecked$ | async"
					(change)="toggleAllProjectMembersSelection($event)"
				>
					{{ 'projectMembers.selectAllCheckbox' | translate }}
				</mat-checkbox>
			</div>

			<mat-selection-list 
				#projectMembersSelectionList 
				[compareWith]="onMemberSelectionChangeCompareWith"
				class="selection-list-project-members"
				[class.show-all-checkboxes]="(selectedMembers$ | async)?.length">
				<ng-container *ngIf="filteredProjectMembers$ | async as projectMembers">
					<mat-list-option *ngFor="let member of projectMembers" [value]="member.entity" 
						class="border-bottom"
						[disabled]="!(selectIsMemberHasProjectInvitationsRemaining$(member) | async)"
						(click)="onMemberClick($event, member)">
						<div class="d-flex align-items-center">
							<mt-named-icon [maxChars]="2" [name]="member.entity | memberFullName: 'email'" class="my-2 mr-2">
							</mt-named-icon>
							<div class="flex-grow-1">
								<div class="mat-body-2">{{ member.entity | memberFullName: 'email' }}</div>
								<div class="mat-small function-text">{{ member.entity.jobTitle || '-' }}</div>
							</div>
						</div>
					</mat-list-option>
				</ng-container>
			</mat-selection-list>

			<div *ngIf="(isEmptyContainer$ | async) && searchText.length <= 0" class="project-internal-member__empty-container flex-grow-1 d-flex align-items-center justify-content-center text-center p-4">
				<div>
					<mat-icon class="mb-2 empty-folder-icon" svgIcon="empty-folder"></mat-icon>
					<div class="empty-text">
						{{ 'projectMembers.internal.emptyText' | translate }}
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="project-members-selection-action-buttons"
		[class.show-action-buttons]="(selectedMembers$ | async)?.length">
		<button mat-stroked-button (click)="toggleAllProjectMembersSelection({ checked: false })">
			{{ 'projectMembers.selectAllCheckbox.cancel' | translate }}
		</button>
		<button mat-flat-button color="accent" (click)="addProjectMembers()">
			{{ 'projectMembers.member.status.add' | translate }}
		</button>
	</div>

	<div class="project-internal-member__footer px-2 pb-3" *ngIf="(isOwner$ | async) && !(selectedMembers$ | async)?.length">
		<app-project-internal-invite-member></app-project-internal-invite-member>
	</div>

	<app-loader *ngIf="isLoading || (isInvitationsLoading$ | async)"></app-loader>
</section>
