import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';

export enum NotificationPermission {
	DEFAULT = 'default',
	DENIED = 'denied',
	GRANTED = 'granted',
}

@Injectable({
	providedIn: 'root',
})
export class BrowserNotificationService {
	constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

	getNotificationPermission(): NotificationPermission {
		try {
			return this.windowRef['Notification'].permission;
		} catch (e) {
			return undefined;
		}
	}

	isNotificationPermission(permission: NotificationPermission): boolean {
		return this.getNotificationPermission() === permission;
	}
}
