import { DarkOverlayComponent } from './dark-overlay.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [DarkOverlayComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule],
	exports: [COMPONENT],
})
export class DarkOverlayModule {}
