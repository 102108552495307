import { NgModule } from '@angular/core';
import { WizardStepperComponent } from './wizard-stepper.component';
import { WizardStepComponent } from './wizard-step/wizard-step.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [WizardStepperComponent, WizardStepComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [MatIconModule, SharedModule],
	exports: [COMPONENT],
})
export class WizardStepperModule {}
