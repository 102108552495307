import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class DateService {
	constructor(private datePipe: DatePipe, @Inject(LOCALE_ID) private readonly localeId: string) {}

	getDate(timestamp, format) {
		const date = new Date().setTime(timestamp * 1000);
		return this.datePipe.transform(date, format);
	}

	getShortDate(timestamp: number) {
		const date = moment.unix(timestamp).valueOf();
		return this.datePipe.transform(date, undefined, undefined, this.localeId);
	}
}
