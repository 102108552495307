export const FILE_TYPES = [
	'audio/*',
	'.pdf',
	'.doc',
	'.docx',
	'.csv',
	'.xlsm',
	'.xlsx',
	'video/*',
	'.txt',
	'image/*',
	'.zip',
	'.rtv',
	'.pages',
	'.odt',
	'.ods',
	'.xls',
	'.key',
	'.numbers',
	'dxf',
	'.ppt',
	'.pptx',
	'.move',
	'.mpeg',
	'.avi',
	'.wmv',
	'.3gp',
];
