import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { Routes } from '@angular/router';
import { redirectLoggedInToDefault } from '@injectables/services/route-guard-functions';
import { LoginComponent } from './login.component';

export const loginRoute: Routes = [
	{
		path: '',
		component: LoginComponent,
		data: {
			title: 'login.title',
			authGuardPipe: redirectLoggedInToDefault,
		},
		pathMatch: 'full',
		canActivate: [AngularFireAuthGuard],
	},
];
