<div class="d-flex flex-column">
	<ng-container [ngTemplateOutlet]="header"></ng-container>
	<ng-container [ngTemplateOutlet]="body"></ng-container>
	<ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>

<ng-template #header>
	<div class="d-flex">
		<mat-icon (click)="close.emit()" class="ml-auto pointer">clear</mat-icon>
	</div>
</ng-template>
<ng-template #body>
	<div class="dialog">
		<p class="font-weight-bold">{{ textConfig.displayHint | translate }}</p>
		<p class="text-muted mb-4">{{ textConfig.displaySubHint | translate }}</p>
		<div class="w-100 d-flex flex-wrap date-range-shortcut">
			<ng-container
				*ngFor="let shortcut of DateRangeShortcuts | keyvalue: compareShortCuts"
				[ngTemplateOutlet]="dateRangeShortcut"
				[ngTemplateOutletContext]="{ $implicit: shortcut.value }"
			></ng-container>
		</div>

		<p class="pt-5 font-weight-bold">{{ textConfig.rangeHint | translate }}</p>

		<mat-form-field appearance="outline">
			<mat-label>{{ getStartDatePlaceholderKey() | translate }}</mat-label>
			<input [max]="this.endControl.value" matInput [matDatepicker]="startPicker" [formControl]="startControl" />
			<mat-datepicker-toggle matSuffix [for]="startPicker">
				<mat-icon matDatepickerToggleIcon color="accent" data-cy="chat-filter-from">expand_more</mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker #startPicker color="accent"></mat-datepicker>
		</mat-form-field>

		<mat-form-field class="pl-2" appearance="outline">
			<mat-label>{{ getEndDatePlaceholderKey() | translate }}</mat-label>
			<input [min]="this.startControl.value" matInput [matDatepicker]="endPicker" [formControl]="endControl" />
			<mat-datepicker-toggle matSuffix [for]="endPicker">
				<mat-icon matDatepickerToggleIcon color="accent" data-cy="chat-filter-to">expand_more</mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker #endPicker color="accent"></mat-datepicker>
		</mat-form-field>
	</div>
</ng-template>

<ng-template class="d-flex" #footer>
	<div class="d-flex pt-2">
		<button (click)="reset()" class="ml-auto mr-2" mat-stroked-button data-cy="date-range-reset-button">
			{{ 'projectList_dateFilter_clear' | translate }}
		</button>
		<button [disabled]="formGroup.invalid" (click)="apply()" mat-flat-button color="accent" data-cy="date-range-confirm-button">
			{{ 'projectList_dateFilter_confirm' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #dateRangeShortcut let-shortcut>
	<button
		*ngIf="!shortcut.includes('hidden')"
		mat-raised-button
		[color]="getPrefixColor(shortcut)"
		(click)="applyDateRangeShortcut(shortcut)"
		[attr.data-cy]="shortcut"
	>
		{{ shortcut.substring(3) | translate }}
	</button>
</ng-template>
