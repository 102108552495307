import { Injectable } from '@angular/core';
import { selectAllWorkTypeEntities } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class TrackedTimeWorkTypeButtonService {
	readonly workTypeEntities$ = this.store.select(selectAllWorkTypeEntities);
	readonly workTypeList$ = this.workTypeEntities$.pipe(
		map((workTypes) => Object.values(workTypes).filter((workType) => !workType.deleted)),
	);
	private selectedWorkTypeId: string;
	private readonly selectedWorkTypeId$$ = new Subject<string>();
	readonly selectedWorkTypeIdChanges$ = this.selectedWorkTypeId$$.asObservable();

	constructor(private readonly store: Store<AppState>) {}

	getSelectedWorkTypeId(): string {
		return this.selectedWorkTypeId;
	}

	setSelectedWorkTypeId(id: string): void {
		this.selectedWorkTypeId = id;
		this.selectedWorkTypeId$$.next(id);
	}
}
