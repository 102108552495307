import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Transaction } from '@sentry/angular-ivy';
import { environment } from '@env/environment';

export enum SentryTransactions {
	CHECKOUT = 'checkout',
}

interface SentryTransaction {
	[id: string]: Transaction;
}

Sentry.init({
	dsn: 'https://d1d61cf1b65a4e26a072e9ee61b98820@sentry.io/1770400',
	integrations: [new Sentry.BrowserTracing()],
	release: environment.version,
	environment: environment.name,
	sampleRate: 0.2,
	tracesSampler: (context) => {
		if (context.transactionContext.sampled) {
			return 1;
		}
		return 0;
	},
});

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
	private transactions: SentryTransaction = {};

	handleError(err: any): void {
		Sentry.captureException(err.originalError || err);
		console.error(err);
	}

	startTransaction(name: SentryTransactions, isRequired: boolean = false): void {
		try {
			if (this.transactions[name]) {
				return;
			}
			this.transactions[name] = Sentry.startTransaction({
				name,
				sampled: isRequired,
			});
		} catch (e) {}
	}

	stopTransaction(name: SentryTransactions): void {
		try {
			this.transactions[name]?.finish();
			delete this.transactions[name];
		} catch (e) {}
	}
}
