import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskFile } from 'domain-entities';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { TASK_FILES } from '@shared/constants/firebase';
import { EQUALS } from '@shared/constants/expression';

@Injectable({
	providedIn: 'root',
})
export class TaskFilesConnector {
	constructor(private readonly connector: FirestoreConnector) {}

	getTaskFiles(id: string): Observable<TaskFile[]> {
		const condition: DataQueryCondition<TaskFile> = {
			field: 'taskId',
			operator: EQUALS,
			value: id,
		};

		return this.connector.watchDocuments(TASK_FILES, [condition]);
	}
}
