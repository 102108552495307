import { Component, Input } from '@angular/core';
import { EmptyStateEnum } from './empty-state.enum';

@Component({
	selector: 'app-empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
	EMPTY_STATE = EmptyStateEnum;
	@Input() type: EmptyStateEnum;
}
