import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { EffectsModule } from '@ngrx/effects';
import { MessageEffects } from './store/effects/message.effects';
import { StoreModule } from '@ngrx/store';
import { MessageReducer, messagesFeatureKey } from './store/reducers/message.reducer';
import { MessageService } from '@injectables/services/message/message.service';
import { ReplyMessageComponent } from './components/reply-message/reply-message.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
	declarations: [ReplyMessageComponent],
	providers: [MessageService],
	imports: [
		SharedModule,
		StoreModule.forFeature(messagesFeatureKey, MessageReducer),
		EffectsModule.forFeature([MessageEffects]),
		MatIconModule,
		MatProgressSpinnerModule,
	],
	exports: [ReplyMessageComponent],
})
export class ChatModule {}
