import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { firstValueFrom, Subject } from 'rxjs';
import { TrackedTimeAddEditPanelComponent } from '@modules/shared/components/time-tracking-add-edit/components/tracked-time-add-edit-panel/tracked-time-add-edit-panel.component';
import { TrackedTimesProjectsService } from '@modules/shared/components/time-tracking-add-edit/components/tracked-times-projects/tracked-times-projects.service';
import { selectWorkRouteState } from '@store/selectors/route.selectors';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-tracked-times-projects',
	templateUrl: './tracked-times-projects.component.html',
	styleUrls: ['./tracked-times-projects.component.scss'],
	viewProviders: [TrackedTimesProjectsService],
})
export class TrackedTimesProjectsComponent implements OnDestroy, AfterViewInit {
	@ViewChild('trackedTimesAddEditPanelRef')
	trackedTimesAddEditPanelRef: TrackedTimeAddEditPanelComponent;
	trackedTimesAddEditPanelVisible = false;
	private readonly destroy$ = new Subject();

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly trackedTimesProjectsService: TrackedTimesProjectsService,
		private readonly router: Router,
		private readonly store: Store,
	) {}

	ngAfterViewInit(): void {
		this.initIsTrackedTimesPathSubscription('create', async () => this.addTrackedTimesEntry());
		this.initIsTrackedTimesPathSubscription('edit', async () => this.editTrackedTimesEntry());
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	enableTrackedTimesAddEditPanel(): void {
		setTimeout(() => {
			this.trackedTimesAddEditPanelVisible = true;
		});
	}

	disableTrackedTimesAddEditPanel(): void {
		setTimeout(() => {
			this.trackedTimesAddEditPanelVisible = false;
		});
	}

	async openTrackedTimesListView(): Promise<void> {
		const projectId = this.activatedRoute.snapshot.params['projectId'];
		const filterQueryParam = this.activatedRoute.snapshot.queryParamMap.get('filter');
		const workRoute = (await firstValueFrom(this.store.select(selectWorkRouteState))).workRoute;
		await this.router.navigate(
			[workRoute, projectId, 'time-tracking'],
			filterQueryParam
				? {
						queryParams: {
							filter: filterQueryParam,
						},
				  }
				: {},
		);
		this.disableTrackedTimesAddEditPanel();
	}

	async openTrackedTimesCreateView(): Promise<void> {
		const filterQueryParam = this.activatedRoute.snapshot.queryParamMap.get('filter');
		await this.router.navigate(['create'], {
			relativeTo: this.activatedRoute,
			queryParams: filterQueryParam ? { filter: filterQueryParam } : {},
		});
	}

	async editTrackedTimesEntry(): Promise<void> {
		this.enableTrackedTimesAddEditPanel();
		const trackedTimeId = this.activatedRoute.snapshot.params['trackedTimeId'];
		const trackedTime = await this.trackedTimesProjectsService
			.getTrackedTimeById(trackedTimeId)
			.pipe(take(1))
			.toPromise();
		await this.trackedTimesAddEditPanelRef.init(trackedTime.projectId, trackedTime);
		return null;
	}

	async addTrackedTimesEntry(): Promise<void> {
		const projectId = this.activatedRoute.snapshot.params['projectId'];
		await this.trackedTimesAddEditPanelRef.init(projectId);
		this.enableTrackedTimesAddEditPanel();
		return null;
	}

	private initIsTrackedTimesPathSubscription(path: string, callback: Function): void {
		this.activatedRoute.url
			.pipe(
				takeUntil(this.destroy$),
				filter((urlSegment) => urlSegment.map((segment) => segment.path).indexOf(path) > -1),
			)
			.subscribe(async (_) => callback());
	}
}
