import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isOwnerOrSupervisorOfCompany } from '@store/selectors/app.selectors';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { selectExternalChatUserUnreadCount } from '@store/selectors/external-chat.selector';
import { ScheduleDemoService } from '@injectables/services/schedule-demo/schedule-demo.service';
import { UpgradesScheduledemoPageSelectedEventBuilder } from '@generated/events/UpgradesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { selectActiveProject, selectIsProjectsRoute } from '@store/selectors/route.selectors';
import { selectProjectUnreadsOfActiveProjectsEntities } from '@store/selectors/project-unreads.selectors';
import { last } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { NotificationCenterPollingService } from '@injectables/services/notification-center-polling.service';
import { TitleService } from '@injectables/services/title.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [NotificationCenterPollingService],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Output() openNotificationCenter = new EventEmitter<void>();
	destroy$ = new Subject<void>();

	isNotificationCenterOpen$ = this.activatedRoute.fragment.pipe(
		map((fragment) => fragment === 'notification-center'),
	);

	projectUnreadCount$ = combineLatest([
		this.store.select(selectProjectUnreadsOfActiveProjectsEntities),
		this.store.select(selectActiveProject).pipe(distinctUntilChanged()),
	]).pipe(
		map(
			// Filtering out the selected project to avoid flicker
			([unreads, selectedProjectId]) =>
				Object.values(unreads).filter((path: string) => last(path.split('/')) !== selectedProjectId)
					.length,
		),
	);

	externalChatUnreadCount$ = this.store.select(selectExternalChatUserUnreadCount);

	isOwnerOrSupervisor$ = this.store.select(isOwnerOrSupervisorOfCompany);

	isProjectsRouteActive$ = this.store.select(selectIsProjectsRoute);

	constructor(
		private readonly store: Store<AppState>,
		private readonly projectHelperService: ProjectHelperService,
		public readonly scheduleDemoService: ScheduleDemoService,
		private readonly tracking: TrackingService,
		private readonly activatedRoute: ActivatedRoute,
		readonly notificationCenterPollingService: NotificationCenterPollingService,
		private readonly titleService: TitleService,
	) {}

	ngOnInit(): void {
		this.initTitleSubscription();

	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	goToHome(): void {
		this.projectHelperService.goToHome();
	}

	async externalChatOnClick(): Promise<void> {}

	async trackScheduledDemoEvent(): Promise<void> {
		const params = await this.scheduleDemoService.getEventParams();
		await this.tracking.trackEvent(new UpgradesScheduledemoPageSelectedEventBuilder(params));
	}

		private initTitleSubscription(): void {
		combineLatest([this.externalChatUnreadCount$, this.projectUnreadCount$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([externalChatCount, projectCount]) => {
				const hasNotification = externalChatCount + projectCount > 0;
				this.titleService.setNotification(hasNotification);
			});
		}
}
