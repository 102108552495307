import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FeaturesComponent } from '@modules/features/features.component';

const ROUTES: Routes = [{ path: '', component: FeaturesComponent }];

@NgModule({
	imports: [RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
