import { combineLatest, concat, from, Observable, of } from 'rxjs';
import { EntityChanges, indexedDb, shareReplayOne } from '@craftnote/shared-utils';
import { maxBy } from 'lodash';
import { map, switchMap, take, tap } from 'rxjs/operators';

type KeyWithValuesOfType<T, V> = {
	[K in keyof T]: T[K] extends V ? K : never;
};

export function watchEntitiesWithCache<T extends { id: string }>(
	watchFunction: (cutoffTime: number) => Observable<EntityChanges<T>>,
	cacheTable: string,
	batchIdentifier: string,
	batchField: keyof T,
	cutoffTimeField: keyof KeyWithValuesOfType<T, number>,
): Observable<EntityChanges<T>> {
	return from(getCachedEntities<T>(cacheTable, batchField, batchIdentifier)).pipe(
		switchMap((cachedEntities) =>
			combineLatest([
				of(cachedEntities),
				getTimeGuaranteedToBeInThePastWithBuffer(cachedEntities, cutoffTimeField),
			]),
		),
		switchMap(([entitiesFromCache, cutoffTime]) => {
			const changesFromDatabase$ = watchFunction(cutoffTime).pipe(
				tap((changes) => updateCache(cacheTable, changes)),
				shareReplayOne(),
			);
			const additionsAndRemovalsHaveEmitted$ = changesFromDatabase$.pipe(
				take(1),
				map(() => true),
			);
			const additionChangeFromCache: EntityChanges<T> = {
				changeType: 'created',
				entities: entitiesFromCache,
			};

			return additionsAndRemovalsHaveEmitted$.pipe(
				switchMap(() => concat(of(additionChangeFromCache), changesFromDatabase$)),
			);
		}),
	);
}

async function getCachedEntities<T>(
	tableIdentifier: string,
	batchField: keyof T,
	bachIdentifier: string,
): Promise<T[]> {
	return indexedDb[tableIdentifier]
		.filter((entity) => entity[batchField] === bachIdentifier)
		.toArray();
}

async function getTimeGuaranteedToBeInThePastWithBuffer<
	T,
	U extends keyof KeyWithValuesOfType<T, number>,
>(cachedEntities: T[], cutoffTimeField: U): Promise<number> {
	const latestCachedEntity = maxBy(cachedEntities, (entity) => entity[cutoffTimeField]);
	const latestKnownTimestamp = latestCachedEntity ? latestCachedEntity[cutoffTimeField] : null;
	if (typeof latestKnownTimestamp !== 'number') {
		return 0;
	}
	return Math.max(latestKnownTimestamp - 10, 0);
}
function updateCache<T extends { id: string }>(
	tableIdentifier: string,
	entityChanges: EntityChanges<T>,
): void {
	if (entityChanges.changeType === 'deleted') {
		indexedDb[tableIdentifier].bulkDelete(entityChanges.entities.map((entity) => entity.id));
	} else {
		indexedDb[tableIdentifier].bulkPut(entityChanges.entities);
	}
}
