import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface ProjectInviteVerificationResponse {
	success: boolean;
	message?: string;
	code?: string;
	projectName?: string;
	projectId?: string;
}

@Injectable()
export class ProjectInviteService {
	constructor(private readonly http: HttpClient, private readonly router: Router) {}

	sendInviteVerificationLink(inviteId: string): Observable<string> {
		if (!inviteId) {
			return of('project-invite.error_link_not_found');
		}

		return this.http
			.post<ProjectInviteVerificationResponse>(`${environment.baseUrl}joinProjectWithLink`, {
				inviteId,
			})
			.pipe(
				map(this.handleInviteVerification.bind(this)),
				filter(Boolean),
				catchError(this.handleErrors.bind(this)),
			);
	}

	handleInviteVerification(resp: ProjectInviteVerificationResponse): string {
		if (resp.success) {
			this.router.navigate(['/project', resp.projectId]);
			return null;
		} else {
			throw resp;
		}
	}

	private handleErrors({ error }: HttpErrorResponse): Observable<string> {
		switch (error?.code) {
			case 'error_link_not_found':
				return of('project-invite.error_link_not_found');
			case 'error_already_used':
				return of('project-invite.error_already_used');
			case 'error_expired':
				return of('project-invite.error_expired');
			case 'error_project_not_found':
				return of('project-invite.error_project_not_found');
			case 'error_already_member':
				this.router.navigate(['/project']);
				break;
			default:
				console.error(
					'Project Invite: Response error code is not recognized. Response: ' +
						JSON.stringify(error),
				);
				return of('project-invite.unknown_error');
		}
	}
}
