import { NgModule } from '@angular/core';
import { HasLimitsDirective } from './has-limits.directive';

const DIRECTIVE = [HasLimitsDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class HasLimitModule {}
