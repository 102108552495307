<section class="company-employees-role-dialog-section">
	<div mat-dialog-title class="d-flex justify-content-center m-0 mat-display-2 position-relative">
		<span>{{ 'company-employees-role-dialog.heading' | translate }}</span>

		<button class="close-icon" mat-icon-button tabindex="-1" [mat-dialog-close]="false">
			<mat-icon>clear</mat-icon>
		</button>
	</div>

	<mat-dialog-content>
		<p class="hint-text p-3 rounded text-center mb-5">
			<span class="font-weight-bold">Info:</span> {{ 'company-employees-role-dialog.info' | translate }}
		</p>

		<div class="d-flex">
			<div class="flex-1" *ngFor="let description of rolesDescription; let last = last" [class.pr-3]="!last">
				<div class="mb-3">
					<span
						class="px-2 py-1 rounded-pill"
						[style]="{
							color: description.heading.color,
							backgroundColor: description.heading.backgroundColor
						}"
						>{{ description.heading.text }}</span
					>
				</div>
				<ul class="pl-3 rights-list">
					<li class="mb-2" *ngFor="let right of description.rights">{{ right }}</li>
				</ul>
			</div>
		</div>
	</mat-dialog-content>
</section>
