import { NgModule } from '@angular/core';
import { BannerComponent } from './banner.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared.module';

const COMPONENT = [BannerComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [MatIconModule, SharedModule],
	exports: [COMPONENT],
})
export class BannerModule {}
