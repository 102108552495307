import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AuthService } from '@injectables/services/auth/auth.service';
import { AlertService } from '@injectables/services/alert/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Company, CompanyEmployee, InviteState, Member, MemberRole } from 'domain-entities';
import {
	addMemberToCompanyFactory,
	updateCompanyMemberFactory,
	upsertCompanyEmployee,
} from '@shared/firebase/company/company-update.functions';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectCompany, selectUserId } from '@store/selectors/app.selectors';
import {
	getNumberOfOwnersInCompany,
	isUserOwnerOfCompany,
} from '@shared/functions/company/company.functions';
import { CompanyService } from '@injectables/services/company/company.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { createCompanyInvitationByCompanyIdAndMember } from '@shared/functions/invitations/invitations.functions';
import { EMAIL_PATTERN, NAME_PATTERN } from '@constants/validators.constants';
import { selectCompanyEmployee } from '@store/selectors/company.selectors';
import { CompanymembersInvitationCreatedEventBuilder } from '@generated/events/CompanymembersEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';

@Component({
	selector: 'app-add-company-employee',
	templateUrl: './add-company-employee-dialog.component.html',
	styleUrls: ['../../../../features/settings/settings.component.scss'],
})
export class AddCompanyEmployeeDialogComponent implements OnInit, OnDestroy {
	member: Member;
	companyEmployee: CompanyEmployee | undefined;
	company: Company;
	role: MemberRole;
	employeeFormGroup: UntypedFormGroup;
	emailPattern = EMAIL_PATTERN;
	InviteState = InviteState;

	destroy$ = new Subject();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public readonly dialogRef: MatDialogRef<AddCompanyEmployeeDialogComponent>,
		private readonly authService: AuthService,
		private readonly alertService: AlertService,
		private readonly companyService: CompanyService,
		private readonly store: Store<AppState>,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly remoteConfig: RemoteConfig,
		private readonly invitationService: InvitationService,
		private readonly trackingService: TrackingService,
	) {
		this.buildEmployeeForm();
	}

	get isNewInvitationsFeatureEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_company_invitation');
	}

	ngOnInit(): void {
		this.loadCompany();
		this.loadCompanyEmployee();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	private buildEmployeeForm(): void {
		this.employeeFormGroup = this.formBuilder.group({
			name: ['', [Validators.pattern(NAME_PATTERN)]],
			lastname: ['', [Validators.pattern(NAME_PATTERN)]],
			email: ['', [Validators.required, Validators.email]],
			mobile: [''],
			jobTitle: [''],
			employeeId: [''],
		});
	}

	loadCompany(): void {
		this.disableUnmodifiableControls();
		this.store
			.select(selectCompany)
			.pipe(
				filter((company) => !!company),
				takeUntil(this.destroy$),
			)
			.subscribe((company) => {
				this.company = company;
				this.loadCompanyMember();
			});
	}

	loadCompanyMember(): void {
		if (this.isNewMember()) {
			this.role = MemberRole.EMPLOYEE;
		} else {
			this.member = this.company.members[this.data.member];
			this.role = this.member.role as MemberRole;
			this.setFormValuesFromObject();
		}
		this.disableUnmodifiableControls();
	}

	loadCompanyEmployee(): void {
		this.store
			.select(selectCompanyEmployee, { employeeId: this.data.memberId })
			.pipe(takeUntil(this.destroy$), filter<CompanyEmployee>(Boolean))
			.subscribe((employee) => {
				this.employeeFormGroup.controls['employeeId'].setValue(employee.employeeNumber);
			});
	}

	private setFormValuesFromObject(): void {
		const controls = this.employeeFormGroup.controls;
		controls['name'].setValue(this.member.name);
		controls['lastname'].setValue(this.member.lastname);
		controls['email'].setValue(this.member.email);
		controls['mobile'].setValue(this.member.mobile || '');
		controls['jobTitle'].setValue(this.member.jobTitle || '');
	}

	canChangeRole(): boolean {
		return (!this.isLastOwner() && this.isOwner()) || !this.hasAccepted() || this.isNewMember();
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	isNewMember(): boolean {
		return !this.data || !this.data.member;
	}

	isLastOwner(): boolean {
		if (!this.company || this.member?.role !== MemberRole.OWNER) {
			return false;
		}

		return getNumberOfOwnersInCompany(this.company) === 1;
	}

	setSelected(role: string): void {
		if (!this.canChangeRole()) {
			return;
		}

		this.role = role as MemberRole;
	}

	getSelected(role: string): string {
		return role === this.role ? 'active background-active' : 'background-inactive';
	}

	isSelected(role: string): boolean {
		return role === this.role;
	}

	isOwner(): boolean {
		return this.company && isUserOwnerOfCompany(this.authService.currentUser().email, this.company);
	}

	isHimself(): boolean {
		return this.member?.id === this.authService.currentUserId();
	}

	getTitle(): string {
		if (!this.isOwner()) {
			return 'employee.title-show';
		} else if (this.isNewMember()) {
			return 'employee.title-new';
		} else {
			return 'employee.title-edit';
		}
	}

	getSaveTitle(): string {
		if (this.isNewMember()) {
			return 'employee.invite';
		} else {
			return 'employee.save';
		}
	}

	hasAccepted(): boolean {
		return this.member?.inviteState === InviteState.ACCEPTED;
	}

	private formToMember(): void {
		const controls = this.employeeFormGroup.controls;
		this.member = {
			name: controls['name'].value,
			lastname: controls['lastname'].value,
			email: controls['email'].value,
			mobile: controls['mobile'].value,
			jobTitle: controls['jobTitle'].value,
			role: this.role,
		};
	}

	async save(): Promise<void> {
		this.formToMember();
		if (this.isNewMember()) {
			if (this.company?.members[this.member.email.toLowerCase()]) {
				this.alertService.showAlert('employee.duplicated-email');
				return;
			}

			this.isNewInvitationsFeatureEnabled
				? await this.inviteMemberToCompanyInNewWay()
				: await this.inviteMemberToCompanyInOldWay();
		} else {
			const messageKey = 'employee.edit-success';
			await this.companyService.updateCompanyTransactional(
				this.company.id,
				updateCompanyMemberFactory(this.member.email, this.member),
				messageKey,
			);
			await this.updateCompanyEmployee();
		}
		this.closeDialog();
	}

	private disableUnmodifiableControls(): void {
		const controls = this.employeeFormGroup.controls;
		Object.values(controls).forEach((control) => control.enable());
		if (this.isNewInvitationsFeatureEnabled && this.member?.inviteState === InviteState.INVITED) {
			['name', 'lastname', 'mobile', 'email', 'jobTitle'].forEach((control) =>
				controls[control]?.disable(),
			);
			return;
		}

		if (!this.isNewMember()) {
			if (!this.isHimself()) {
				controls['name'].disable();
				controls['lastname'].disable();
			}
			if (!this.isOwner()) {
				controls['mobile'].disable();
			}
		}
		if (!this.isNewMember()) {
			controls['email'].disable();
		}
		if (!this.isOwner()) {
			controls['jobTitle'].disable();
		}
	}

	/**
	 * Old way is to just write into the company's document
	 */
	private async inviteMemberToCompanyInOldWay(): Promise<void> {
		const messageKey = 'employee.add-success';

		this.member.inviteState = InviteState.INVITED;
		this.member.role = this.role;

		await this.trackingService.trackEvent(
			new CompanymembersInvitationCreatedEventBuilder({
				role: this.member.role.toLowerCase(),
				context: 'company-members',
			}),
		);

		await this.companyService.updateCompanyTransactional(
			this.company.id,
			addMemberToCompanyFactory(this.member),
			messageKey,
		);
	}

	private async inviteMemberToCompanyInNewWay(): Promise<void> {
		const inviteeId = await this.store.select(selectUserId).pipe(first()).toPromise();
		const invitation = createCompanyInvitationByCompanyIdAndMember(
			this.company.id,
			inviteeId,
			this.member,
		);

		await this.invitationService.create(invitation, 'company-members');
		this.alertService.showAlert('employee.add-success');
	}

	private updateCompanyEmployee(): Promise<void> {
		if (!this.isOwner()) {
			return;
		}
		const employeeNumber = this.employeeFormGroup.controls['employeeId'].value;
		const userId = this.data.memberId;
		const updateFunction = upsertCompanyEmployee(userId, employeeNumber);

		return this.companyService.upsertCompanyEmployee(this.company.id, userId, updateFunction);
	}
}
