import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
	initBrowserMonitoringAction,
	notificationPermissionChangedAction,
	setCanIndexDbBeMutatedAction,
	setNotificationPermissionAction,
} from '../actions/browser.actions';
import { distinctUntilChanged, map, skip, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { BrowserService } from '@injectables/services/browser.service';
import {
	BrowserNotificationService,
	NotificationPermission,
} from '@injectables/services/browser-notification.service';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../state/app.state';
import { LocalStorageService } from '@injectables/services/local-storage.service';

/**
 * This group of effects monitors the browser and it's settings
 **/

@Injectable()
export class BrowserEffects implements OnInitEffects {
	constructor(
		private readonly actions: Actions,
		private readonly store: Store<AppState>,
		private readonly browserService: BrowserService,
		private readonly browserNotificationService: BrowserNotificationService,
		private readonly localStorageService: LocalStorageService,
	) {}

	notificationChange$$ = new BehaviorSubject<NotificationPermission>(
		this.browserNotificationService.getNotificationPermission(),
	);

	checkForIndexDbMutability = createEffect(() =>
		this.actions.pipe(
			ofType(initBrowserMonitoringAction),
			switchMap(async (_) => {
				const indexDbCanBeMutated = await this.browserService.canMutateIndexDb();
				return setCanIndexDbBeMutatedAction({ indexDbCanBeMutated });
			}),
		),
	);

	checkNotificationPermission = createEffect(() =>
		this.actions.pipe(
			ofType(initBrowserMonitoringAction),
			switchMap(async (_) => {
				if (this.browserService.isPermissionAPISupported()) {
					return await navigator.permissions.query({ name: 'notifications' });
				} else {
					this.store.dispatch(
						setNotificationPermissionAction({ permission: NotificationPermission.GRANTED }),
					);
					throw new Error('PERMISSION_API_NOT_SUPPORTED');
				}
			}),
			tap((permission) => (permission.onchange = this.onNotificationChange.bind(this))),
			switchMapTo(this.notificationChange$$),
			distinctUntilChanged(),
			map((permission: NotificationPermission) => setNotificationPermissionAction({ permission })),
		),
	);

	notificationPermissionChanged = createEffect(() =>
		this.actions.pipe(
			ofType(setNotificationPermissionAction),
			skip(1),
			map((permission) => notificationPermissionChangedAction(permission)),
		),
	);

	private async onNotificationChange({ target }: any): Promise<void> {
		let permission: NotificationPermission;
		// Mapping PermissionStatus to NotificationPermission
		if (target.state === 'denied') {
			permission = NotificationPermission.DENIED;
		} else if (target.state === 'granted') {
			permission = NotificationPermission.GRANTED;
		} else {
			permission = NotificationPermission.DEFAULT;
		}

		if (permission === NotificationPermission.GRANTED) {
			await this.localStorageService.remove('notificationReminderTimestamp');
		}

		this.notificationChange$$.next(permission);
	}

	ngrxOnInitEffects(): Action {
		return initBrowserMonitoringAction();
	}
}
