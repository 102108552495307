import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EMAIL_PATTERN, NAME_PATTERN } from '@constants/validators.constants';
import { MemberRole } from 'domain-entities';
import { CompanyEmployeesRoleDialogComponent } from '@modules/shared/components/add-bulk-company-employees/company-employees-role-dialog/company-employees-role-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG } from '@angular/material/legacy-select';
import { combineLatest } from 'rxjs';
import { selectCompanyId, selectUserId } from '@store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import { createCompanyInvitationByCompanyIdAndMember } from '@shared/functions/invitations/invitations.functions';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';

interface InviteUserForm {
	email: string;
	memberRole: MemberRole;
	jobTitle: string;
}

@Component({
	selector: 'app-add-bulk-company-employees',
	templateUrl: './add-bulk-company-employees.component.html',
	styleUrls: ['./add-bulk-company-employees.component.scss'],
	providers: [
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { overlayPanelClass: ['mat-select-overlay-panel'] },
		},
	],
})
export class AddBulkCompanyEmployeesComponent {
	// We are passing the number of users being invited after submit
	@Output() afterInvitationSubmit = new EventEmitter<number>();
	@Output() isFormValid = new EventEmitter<boolean>();
	@ViewChild('scrollContainer') scrollContainerRef: ElementRef;
	inviteUserForms: UntypedFormGroup[] = Array.from({ length: 3 }).map(() =>
		this.getNewInviteUserForm(),
	);
	MemberRole = MemberRole;

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly dialog: MatDialog,
		private readonly invitationService: InvitationService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly translateService: TranslateService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly store: Store<AppState>,
	) {}

	get isSubmitButtonEnabled(): boolean {
		const isAnyRowValid = this.inviteUserForms.some((inviteUserForm) => inviteUserForm.valid);

		const isAnyRowInvalidAndTouched = this.inviteUserForms.some(
			(inviteUserForm) => inviteUserForm.invalid && inviteUserForm.touched,
		);
		const isFormListValid =
			!this.formHasDuplicateEmails && !isAnyRowInvalidAndTouched && isAnyRowValid;
		this.isFormValid.emit(isFormListValid);
		return isFormListValid;
	}

	get formHasDuplicateEmails(): boolean {
		const emailsList = this.inviteUserForms
			.filter((form) => form.valid)
			.map((form) => form.value.email);
		return new Set(emailsList).size !== emailsList.length;
	}

	private getNewInviteUserForm(): UntypedFormGroup {
		return this.fb.group({
			email: [null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
			memberRole: [MemberRole.EMPLOYEE, [Validators.required]],
			jobTitle: [null, Validators.pattern(NAME_PATTERN)],
		});
	}

	getFormControlError(index: number, controlName: string): ValidationErrors {
		return this.inviteUserForms[index] && this.inviteUserForms[index].get(controlName)?.errors;
	}

	addEmptyInviteUserForm(): void {
		this.inviteUserForms.push(this.getNewInviteUserForm());
		setTimeout(() => {
			const el = this.scrollContainerRef.nativeElement;
			el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
		}, 0);
	}

	async removeInviteUserForm(formIndexToRemove: number): Promise<void> {
		if (
			this.inviteUserForms[formIndexToRemove].valid &&
			(await this.confirmDialogService
				.open({
					title: this.translateService.instant('add-bulk-company-employees.delete-row'),
					primaryButtonText: this.translateService.instant(
						'add-bulk-company-employees.delete-row-delete',
					),
					secondaryButtonText: this.translateService.instant(
						'add-bulk-company-employees.delete-cancel-row',
					),
					primaryButtonValue: false,
					secondaryButtonValue: true,
					primaryButtonColor: 'warn',
					showCrossBtn: false,
				})
				.afterClosed()
				.pipe(take(1))
				.toPromise())
		) {
			return;
		}
		this.inviteUserForms.splice(formIndexToRemove, 1);
	}

	openRolesDialog(): void {
		this.dialog.open(CompanyEmployeesRoleDialogComponent, {
			width: '80vw',
			maxHeight: '80vh',
		});
	}

	async onPressSubmitButton(): Promise<void> {
		await this.addInvitations();
		this.afterInvitationSubmit.next(this.inviteUserForms.filter((form) => form.valid).length);
	}

	private async addInvitations(): Promise<void> {
		const [inviteeId, companyId] = await combineLatest([
			this.store.select(selectUserId),
			this.store.select(selectCompanyId),
		])
			.pipe(take(1))
			.toPromise();

		const inviteResponses = await Promise.all(
			this.inviteUserForms
				.filter((form) => form.valid)
				.map(async (form) => {
					const formValue: InviteUserForm = form.value;
					const invitation = createCompanyInvitationByCompanyIdAndMember(companyId, inviteeId, {
						email: formValue.email,
						role: formValue.memberRole,
						jobTitle: formValue.jobTitle,
					});
					try {
						await this.invitationService.create(invitation, 'onboarding');
						return Promise.resolve(true);
					} catch (e) {
						return Promise.resolve(false);
					}
				}),
		);

		if (inviteResponses.every(Boolean)) {
			this.notificationSnackbarService.show(BasicSnackbarComponent, {
				componentTypes: {
					description: this.translateService.instant(
						'add-bulk-company-employees.success-notification',
					),
					icon: 'done',
				},
				level: 1,
			});
		} else {
			this.notificationSnackbarService.show(BasicSnackbarComponent, {
				componentTypes: {
					type: 'warn',
					description: this.translateService.instant(
						'add-bulk-company-employees.fail-notification',
					),
					icon: 'report_problem',
				},
				level: 1,
			});
		}
	}
}
