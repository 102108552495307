<section class="chat-header-section d-flex align-items-center py-2 px-4 flex-wrap border-bottom" *ngIf="project">
	<div class="font-weight-bold m-0 mat-display-2">{{ project?.name }}</div>
	<app-project-notes [projectId]="project?.id">
		<button mat-button color="accent" class="text-capitalize" data-cy="chat-header-notes">
			<ng-container>
				{{
					'global_project-or-folder_note'
						| translate
							: {
									projectType: project.projectType === projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
							  }
				}}
			</ng-container>
		</button>
	</app-project-notes>

	<div class="m-auto mr-lg-0 ml-lg-auto d-flex align-items-center">
		<a
			href=""
			*ngFor="let button of buttons"
			class="header-btn"
			[style.width.px]="button.width"
			[class.d-none]="!button.isVisible()"
			(click)="toggleButton(button, $event)"
			[ngClass]="{ active: button.active }"
		>
			<div class="header-btn__icon">
				<img [src]="'assets/images/icons/chat-header/' + button.icon + '.svg'" alt="chat-header-button" />
				<img class="active" [src]="'assets/images/icons/chat-header/' + button.icon + '-active.svg'" alt="chat-header-button-active" />
			</div>
			<p class="m-0 text-capitalize header-btn__text" [attr.data-cy]="'chat-header-' + button.id">{{ button.text | translate }}</p>
		</a>
	</div>
</section>
