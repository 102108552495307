import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';
import { Message, Project, Task } from 'domain-entities';
import { get, isNil, omitBy } from 'lodash';
import { map } from 'rxjs/operators';

type GlobalSearchResponseError = 'somethingWentWrong' | 'searchTermIsLessThanThreeCharacters';

export interface GlobalSearchResponseResult {
	bestHits?: GlobalSearchBestHits;
	hits?: GlobalSearchHits;
	total?: number;
}

export interface GlobalSearchResponse {
	result?: GlobalSearchResponseResult;
	error?: GlobalSearchResponseError;
}

export interface GlobalSearchTask
	extends Pick<
		Task,
		| 'assignee'
		| 'companyId'
		| 'createdTimestamp'
		| 'deadlineTimestamp'
		| 'description'
		| 'done'
		| 'id'
		| 'projectId'
		| 'title'
	> {
	highlight: { [key in keyof GlobalSearchTask]: string[] };
	score: number;
	type: 'task';
}

export interface GlobalSearchProject
	extends Pick<
		Project,
		| 'colorTag'
		| 'creationDate'
		| 'id'
		| 'name'
		| 'orderNumber'
		| 'projectType'
		| 'statusId'
		| 'street'
		| 'billingCity'
		| 'billingCountry'
		| 'billingEmail'
		| 'billingName'
		| 'billingStreet'
		| 'billingZipcode'
		| 'city'
		| 'contacts'
		| 'country'
		| 'zipcode'
		| 'startDate'
		| 'endDate'
	> {
	archived: boolean;
	highlight: { [key in keyof GlobalSearchProject]: string[] };
	score: number;
	type: 'project';
}

export interface GlobalSearchChatMessage
	extends Pick<
		Message,
		'author' | 'authorId' | 'content' | 'id' | 'messageType' | 'projectId' | 'timestamp'
	> {
	type: 'chatMessage';
}

export type GlobalSearchItem = GlobalSearchProject | GlobalSearchTask | GlobalSearchChatMessage;
export type GlobalSearchBestHits = Array<GlobalSearchItem>;
export type GlobalSearchLastInteraction = GlobalSearchBestHits;

export interface GlobalSearchHits {
	projects?: GlobalSearchProject[];
	folders?: GlobalSearchProject[];
	tasks?: GlobalSearchTask[];
	chatmessages?: GlobalSearchChatMessage[];
}

export enum GlobalSearchFilterValue {
	PROJECTS_ALL = '&filters=type;project&filters=projectType;project',
	PROJECTS_ACTIVE = '&filters=type;project&filters=projectType;project&filters=archived;false',
	PROJECTS_ARCHIVED = '&filters=type;project&filters=projectType;project&filters=archived;true',
	FOLDERS_ALL = '&filters=type;project&filters=projectType;folder',
	FOLDERS_ACTIVE = '&filters=type;project&filters=projectType;folder&filters=archived;false',
	FOLDERS_ARCHIVED = '&filters=type;project&filters=projectType;folder&filters=archived;true',
	TASKS_ALL = '&filters=type;task',
	TASKS_OPEN = '&filters=type;task&filters=done;false',
	TASKS_CLOSED = '&filters=type;task&filters=done;true',
	MESSAGES = '&filters=type;chatmessage',
}

@Injectable()
export class GlobalSearchDialogService {
	constructor(
		private readonly http: HttpClient,
		private readonly errorHandler: ErrorHandlerService,
		@Inject(ENVIRONMENT) public readonly environmentRef: Environment,
	) {}

	async getSearchResults(
		term: string,
		selectedFilter: GlobalSearchFilterValue,
	): Promise<GlobalSearchResponse> {
		try {
			if (term.length < 3) {
				return Promise.resolve({ error: 'searchTermIsLessThanThreeCharacters' });
			}

			return await this.http
				.get<GlobalSearchResponse>(
					`${this.environmentRef.globalSearchElasticSearchUrl}?term=${term}${selectedFilter ?? ''}`,
				)
				.pipe(map(this.getSearchResultsNormalize))
				.toPromise();
		} catch (error) {
			this.errorHandler.handleError(error);
			return { error: 'somethingWentWrong' };
		}
	}

	// This function will be removed in next release
	private getSearchResultsNormalize({ result }: GlobalSearchResponse): GlobalSearchResponse {
		if (!result) {
			return { error: 'somethingWentWrong' };
		}
		// Possible types in the bestHits from backend
		const requiredResultTypes = ['project', 'task', 'chatMessage'];
		result.bestHits = result.bestHits.filter(({ type }) => requiredResultTypes.includes(type));

		result.hits = omitBy(
			{
				projects: result.hits?.projects ?? null,
				tasks: result.hits?.tasks ?? null,
				folders: result.hits?.folders ?? null,
				chatmessages: result.hits?.chatmessages ?? null,
			},
			isNil,
		);
		result.total =
			get(result, 'hits.folders.length', 0) +
			get(result, 'hits.tasks.length', 0) +
			get(result, 'hits.chatmessages.length', 0) +
			get(result, 'hits.projects.length', 0);
		return { result };
	}
}
