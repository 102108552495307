import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PaywallHeaderDialogService } from '@injectables/services/paywall-header-dialog/paywall-header-dialog.service';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { map, take } from 'rxjs/operators';
import { currentUserProfileLimitsSelector } from '@store/selectors/profile-limits.selectors';
import { SubscriptionService } from '@injectables/services/subscription/subscription.service';
import {
	UpgradesPaywallheaderdialogClosedEventBuilder,
	UpgradesPaywallheaderdialogDmSelectedEventBuilder,
	UpgradesPaywallheaderdialogUpSelectedEventBuilder,
} from '@generated/events/UpgradesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { environment } from '@env/environment';
import { FirebaseStorageConnector } from '@shared/firebase/connectors/storage/firebase-storage.connector';

@Component({
	selector: 'app-paywall-header-dialog',
	templateUrl: './paywall-header-dialog.component.html',
	styleUrls: ['./paywall-header-dialog.component.scss'],
})
export class PaywallHeaderDialogComponent implements OnInit {
	@ViewChild('paywallHeaderDialogRef') paywallHeaderDialogRef: TemplateRef<any>;
	isOwner$ = this.store.select(isOwnerOfCompany);
	videoSource: string;
	private dialogRef;
	private isCurrentUserBasic$ = this.store
		.select(currentUserProfileLimitsSelector)
		.pipe(
			map(
				(currentUserProfileLimits) => currentUserProfileLimits.limits?.taskTimeDashboard === false,
			),
		);

	constructor(
		private readonly dialog: MatDialog,
		private readonly store: Store<AppState>,
		private readonly router: Router,
		private readonly trackingService: TrackingService,
		private readonly subscriptionService: SubscriptionService,
		private readonly paywallHeaderDialogService: PaywallHeaderDialogService,
		private readonly connector: FirebaseStorageConnector,
	) {}

	ngOnInit(): void {
		this.paywallHeaderDialogService.registerComponent(this);
	}

	async open(): Promise<void> {
		const fileName = 'cn-pro-functions.webm';
		this.videoSource = await this.connector.getDownloadUrl(
			fileName,
			environment.publicAssetsBucket,
		);
		this.dialogRef = this.dialog.open(this.paywallHeaderDialogRef, {
			autoFocus: false,
			disableClose: true,
		});
		await this.dialogRef.afterClosed().pipe(take(1)).toPromise();
		await this.trackingService.trackEvent(new UpgradesPaywallheaderdialogClosedEventBuilder({}));
		await this.router.navigate([]);
	}

	async onClickUpgrades(): Promise<void> {
		await this.trackingService.trackEvent(
			new UpgradesPaywallheaderdialogUpSelectedEventBuilder({}),
		);
		const isCurrentUserBasic = await this.isCurrentUserBasic$.pipe(take(1)).toPromise();
		const subscription = await this.subscriptionService
			.getActiveSubscription()
			.pipe(take(1))
			.toPromise();
		const nextNavigation =
			!!subscription && isCurrentUserBasic
				? 'settings/subscription'
				: 'settings/subscription/products';
		await this.router.navigateByUrl(nextNavigation);
		this.dialogRef.close();
	}

	async onClickBookDemo(): Promise<void> {
		await this.trackingService.trackEvent(
			new UpgradesPaywallheaderdialogDmSelectedEventBuilder({}),
		);
	}
}
