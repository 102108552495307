import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { firstValueFrom } from 'rxjs';
import {
	OptimusStripeConnector,
	OptimusStripeRolloutPhase,
} from '@shared/firebase/connectors/firestore/collections/optimus-stripe/optimus-stripe.connector';

@Component({
	selector: 'app-optimus-stripe-activation',
	template: '',
	styles: [],
})
export class OptimusStripeActivationComponent {
	constructor(
		private route: ActivatedRoute,
		private readonly connector: OptimusStripeConnector,
		private readonly store: Store<AppState>,
		private readonly router: Router,
	) {
		void this.storeAndRedirect();
	}

	async storeAndRedirect(): Promise<void> {
		const params = await firstValueFrom(this.route.queryParams);
		const phaseToActivate = params['phase'];
		if (!phaseToActivate) {
			console.log('OPTIMUS STRIPE: No phase to activate');
			await this.router.navigate(['/settings/subscription/products']);
			return;
		}
		console.log('OPTIMUS STRIPE: Phase to activate', phaseToActivate);
		if (
			phaseToActivate !== OptimusStripeRolloutPhase.MANAGEMENT &&
			phaseToActivate !== OptimusStripeRolloutPhase.DISABLED
		) {
			console.log('OPTIMUS STRIPE: Invalid phase to activate');
			await this.router.navigate(['/settings/subscription/products']);
			return;
		}

		const companyId$ = this.store.select(selectCompanyId);
		const companyId = await firstValueFrom(companyId$);

		const phase = await this.connector.getRolloutPhase(companyId);
		if (phase === phaseToActivate) {
			console.log(`OPTIMUS STRIPE: Phase ${phaseToActivate} already activated`);
			await this.router.navigate(['/settings/subscription/products']);
			return;
		}

		await this.connector.updateOrCreateRolloutPhase(companyId, { phase: phaseToActivate });
		await this.router.navigate(['/settings/subscription/products']);
	}
}
