import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const RotateAnimation = trigger('rotate', [
	state(
		'in',
		style({
			transform: 'rotate(45deg)',
		}),
	),
	state(
		'out',
		style({
			transform: 'rotate(0deg)',
		}),
	),
	transition('in => out', [
		group([
			animate(
				300,
				style({
					transform: 'rotate(0deg)',
				}),
			),
		]),
	]),
	transition('out => in', [
		group([
			animate(
				300,
				style({
					transform: 'rotate(45deg)',
				}),
			),
		]),
	]),
]);
