import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { EventParams } from '@globalTypes/interfaces/event';

import { TrackingConnector } from '@globalTypes/interfaces/tracking-connector';
import {
	ConsentManagementService,
	DataProcessingService,
} from '@injectables/services/consent-management/consent-management.service';
import firebase from 'firebase/compat/app';

export interface GoogleAnalyticsPurchaseEventParameters {
	value?: firebase.analytics.EventParams['value'];
	currency?: firebase.analytics.EventParams['currency'];
	transaction_id: firebase.analytics.EventParams['transaction_id'];
	tax?: firebase.analytics.EventParams['tax'];
	shipping?: firebase.analytics.EventParams['shipping'];
	items?: firebase.analytics.EventParams['items'];
	coupon?: firebase.analytics.EventParams['coupon'];
	affiliation?: firebase.analytics.EventParams['affiliation'];
}

@Injectable({
	providedIn: 'root',
})
export class FirebaseGoogleAnalyticsConnector implements TrackingConnector {
	constructor(
		private readonly afAnalytics: AngularFireAnalytics,
		private readonly consentService: ConsentManagementService,
	) {
		this.watchGoogleAnalyticsConsent();
	}

	async setUser(userId: string): Promise<void> {
		try {
			await this.afAnalytics.setUserId(userId);
		} catch (e) {
			throw new Error('Failed to set userId of google analytics');
		}
	}

	async trackEvent(eventName: string, payload: EventParams): Promise<void> {
		if (!eventName) {
			throw new Error('Event name is not available for logging');
		}

		try {
			await this.afAnalytics.logEvent(eventName, payload);
		} catch (e) {
			throw new Error(`Failed to log an event ${eventName}`);
		}
	}

	async trackGoogleAnalyticsPurchaseEvent(
		parameters: GoogleAnalyticsPurchaseEventParameters,
	): Promise<void> {
		await firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, parameters);
	}

	private watchGoogleAnalyticsConsent(): void {
		this.consentService
			.watchServiceConsent(DataProcessingService.GOOGLE_ANALYTICS)
			.subscribe((consent) => {
				if (consent) {
					void this.afAnalytics.setAnalyticsCollectionEnabled(true);
				} else {
					void this.afAnalytics.setAnalyticsCollectionEnabled(false);
				}
			});
	}
}
