import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { filter, first, takeUntil } from 'rxjs/operators';
import { JPG, PDF } from '@shared/constants/upload.types';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ChatMessageService } from '@injectables/services/chat/chat-message.service';
import { ChatService } from '@injectables/services/chat/chat.service';
import { CompanyService } from '@injectables/services/company/company.service';
import { Company, Message, MessageType } from 'domain-entities';
import { isUserOwnerOrSupervisorOfProject } from '@shared/functions/project/project.functions';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectCompany, selectCompanyId } from '@store/selectors/app.selectors';
import { removeTagFromCompanyFunction } from '@shared/firebase/company/company-update.functions';
import { SuggestedTag } from './entities/suggested-tag.type';
import { Subject } from 'rxjs';
import { TrackingService } from '@injectables/services/tracking.service';
import { ChatTagAddedEventBuilder } from '@generated/events/ChatEvents.generated';
import { noop } from 'lodash';
import { checkMessageType } from '../utils/utils';

@Component({
	selector: 'app-tagging-action-dialog',
	templateUrl: './tagging.component.html',
	styleUrls: ['./tagging.component.scss'],
})
export class TaggingDialogComponent implements OnInit, OnChanges {
	@Input() project: any;
	@Input() company: any;
	@Input() message: any;
	@Output() close: EventEmitter<any> = new EventEmitter();

	text: string;
	files = [];
	filesLoaded = [];
	tags = [];
	suggestedTags: SuggestedTag[] = [];
	suggestedTagsList: any[] = [];
	MessageType = MessageType;
	newTagText = '';
	data: any = {};
	checkMessageType = checkMessageType;

	private destroy$ = new Subject();

	constructor(
		private chatService: ChatService,
		private authService: AuthService,
		public chatMessageService: ChatMessageService,
		private companyService: CompanyService,
		private store: Store<AppState>,
		private readonly trackingService: TrackingService,
	) {}

	ngOnInit(): void {
		this.tags = (this.message && this.message.tags) || [];
		this.loadSuggestedTags();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.message) {
			this.tags = (this.message && this.message.tags) || [];
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	isOwnerOrSupervisor(): boolean {
		return isUserOwnerOrSupervisorOfProject(this.authService.currentUserId(), this.project, 'id');
	}

	loadTags(): void {
		this.chatService
			.getMessageTags(this.project.id, this.message.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((tags) => {
				this.tags = tags;
			});
	}

	filterTags(): void {
		this.suggestedTags = this.suggestedTagsList.filter((suggestedTag) => {
			return (
				suggestedTag.name.toLowerCase().indexOf(this.newTagText.toLowerCase()) !== -1 &&
				!this.tags.includes(suggestedTag.name)
			);
		});
	}

	loadSuggestedTags(): void {
		const company$ = this.store
			.select(selectCompany)
			.pipe(filter<Company>(Boolean), takeUntil(this.destroy$));
		company$.subscribe((company: Company) => {
			const arr: any[] = [];
			for (const key in company.tags) {
				if (company.tags.hasOwnProperty(key)) {
					arr.push({ name: key, count: company.tags[key] });
				}
			}
			this.suggestedTagsList = arr
				.sort((a, b) => (a.count < b.count ? 1 : -1))
				.filter((item) => !this.tags.includes(item.name));
			this.suggestedTags = arr
				.sort((a, b) => (a.count < b.count ? 1 : -1))
				.filter((item) => !this.tags.includes(item.name));
		}, noop);
	}

	closeDialog(): void {
		this.close.emit();
	}

	getFile(message: Message): void {
		if (this.filesLoaded[message.id] || message.messageType === MessageType.TEXT) {
			return;
		}

		this.filesLoaded[message.id] = true;

		if (
			message.messageType === MessageType.DOCUMENT &&
			this.chatMessageService.getFileType(message.content) === PDF
		) {
			return;
		}

		let thumbnail = false;
		let jpg = false;

		if (message.messageType === MessageType.IMAGE) {
			thumbnail = true;
		}

		if (message.messageType === MessageType.VIDEO) {
			thumbnail = true;
			jpg = true;
		}

		this.chatService
			.getFile(
				this.project.id,
				message,
				jpg ? JPG : this.chatMessageService.getFileType(message.content),
				thumbnail,
			)
			.then((result) => {
				if (!result) {
					return;
				}
				this.files[message.id] = result;
			})
			.catch();
	}

	async addTag(e): Promise<void> {
		e.preventDefault();
		if (!this.newTagText || this.tags.indexOf(this.newTagText) !== -1) {
			return;
		}
		await this.chatService.addTagToMessage(this.project.id, this.message.id, this.newTagText);
		this.loadTags();
		this.newTagText = '';
		this.filterTags();

		return this.trackTagEvent();
	}

	removeTag(index): void {
		const tags = this.tags.filter((_item, i) => index !== i);
		this.chatService.updateTagsToMessage(this.project.id, this.message.id, tags).then(() => {
			this.loadTags();
			this.loadSuggestedTags();
		});
	}

	async addSuggestedTag(tag): Promise<void> {
		if (this.tags.indexOf(tag) !== -1) {
			return;
		}

		await this.chatService.addTagToMessage(this.project.id, this.message.id, tag.name);
		this.loadTags();

		return this.trackTagEvent();
	}

	private async trackTagEvent(): Promise<void> {
		return this.trackingService.trackEvent(
			new ChatTagAddedEventBuilder({
				projectId: this.project.id,
				type: this.message?.messageType.toString().toLowerCase(),
			}),
		);
	}

	async removeSuggestedTag(e, tag: SuggestedTag): Promise<void> {
		e.preventDefault();
		e.stopPropagation();
		const companyId = await this.store
			.select(selectCompanyId)
			.pipe(filter<string>(Boolean), first())
			.toPromise();
		const updateFunction = removeTagFromCompanyFunction(tag.name);
		return this.companyService.updateCompanyTransactional(companyId, updateFunction);
	}

	isMessageAuthor(message: Message): boolean {
		const user = this.authService.currentUser();
		return user && message.authorId === user.uid;
	}

	canEditTag(msg: Message): boolean {
		if (this.isMessageAuthor(msg)) {
			return true;
		}

		if (this.project.company !== this.company.id) {
			return false;
		}

		return !this.company.isEmployee(this.authService.currentUserId());
	}
}
