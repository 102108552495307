import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { ScrollDirective } from './scroll.directive';

@NgModule({
	imports: [SharedModule],
	declarations: [ScrollDirective],
	exports: [ScrollDirective],
})
export class ScrollEventModule {}
