<div>
	<mat-form-field appearance="fill" class="search-input rounded px-2 pt-2">
		<input #search matInput autocomplete="off" [(ngModel)]="searchInputValue" />
		<div matSuffix>
			<button mat-icon-button [class.d-none]="searchInputValue?.length">
				<mat-icon>search</mat-icon>
			</button>
		</div>
	</mat-form-field>
	<mat-selection-list [multiple]="true" #list>
		<ng-container *ngFor="let option of options">
			<mat-list-option
				[appShowConditional]="showItem(option)"
				[ngClass]="{ 'list-option-second-hint': option.secondHintValue }"
				checkboxPosition="before"
				[value]="option.value"
				class="multi-select-list-option"
			>
				<p class="text-truncate display-value">{{ option.displayValue }}</p>
				<p *ngIf="option.secondHintValue" class="text-truncate text-muted second-hint-value">{{ option.secondHintValue }}</p>
				<p *ngIf="option.hintValue" class="text-truncate text-muted hint-value">{{ option.hintValue }}</p>
			</mat-list-option>
		</ng-container>
	</mat-selection-list>
	<mat-divider></mat-divider>
	<button
		(click)="reset()"
		[disabled]="!list.selectedOptions.selected.length"
		class="pl-3 mb-1 pt-2 display-3 pointer font-weight-bold text-decoration-underline reset-button"
		data-cy="multi-select-panel-reset"
	>
		{{ 'multiSelectPanel_actions_reset' | translate }}
	</button>
</div>
