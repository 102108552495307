import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import {
	isOwnerOrSupervisorOfCompany,
	selectCompanyMembers,
	selectCompanySize,
	selectCompanySizeHumanize,
	selectCompanyTrade,
	selectProfile,
	selectUserMobile,
} from '@store/selectors/app.selectors';
import { filter, map, take } from 'rxjs/operators';
import { CompanySize } from '@shared/models/company-size.type';
import { selectIsTrialSubscription } from '@store/selectors/subscription.selectors';
import { companySizeGreaterThan9 } from '@shared/functions/company/company.functions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';

declare var Calendly: any;

@Injectable({
	providedIn: 'root',
})
export class ScheduleDemoService {
	private scheduleDemoEnabled$ = combineLatest([
		// INFO:: On develop if you skip the company size then the below stream will not emit data, means no demo
		this.store.select(selectCompanySize).pipe(filter<CompanySize>(Boolean)), // FIXME:: Company size is emitting first event as nil
		this.store.select<boolean>(selectIsTrialSubscription),
		this.store.select<boolean>(isOwnerOrSupervisorOfCompany),
	]).pipe(
		map(([companySize, isTrialSubscription, isOwnerOrSupervisor]) => {
			const featureBookDemosConfigEnabled = !!this.remoteConfig.getValue('feature_book_demos');
			return (
				featureBookDemosConfigEnabled &&
				isTrialSubscription &&
				companySizeGreaterThan9(companySize) &&
				isOwnerOrSupervisor
			);
		}),
	);

	scheduleDemoSidebarEnabled$ = this.scheduleDemoEnabled$.pipe(
		map((scheduleDemoEnabled) => scheduleDemoEnabled),
	);

	constructor(
		private readonly store: Store<AppState>,
		private readonly remoteConfig: RemoteConfig,
		private readonly memberFullNamePipe: MemberFullNamePipe,
	) {}

	async getEventParams(): Promise<{
		demoType: string;
		totalCompanyMembers: number;
	}> {
		const totalCompanyMembers = await this.store
			.select(selectCompanyMembers)
			.pipe(take(1))
			.toPromise();
		return {
			demoType: 'sidebar',
			totalCompanyMembers: Object.keys(totalCompanyMembers).length,
		};
	}

	async openCalendly(): Promise<void> {
		const user = await this.store.select(selectProfile).pipe(take(1)).toPromise();
		const trade = await this.store
			.select(selectCompanyTrade)
			.pipe(filter<string>(Boolean), take(1))
			.toPromise();
		const mobile = await this.store.select(selectUserMobile).pipe(take(1)).toPromise(); // Optional
		const companySize = await this.store
			.select(selectCompanySizeHumanize)
			.pipe(take(1))
			.toPromise();

		Calendly.showPopupWidget(
			'https://calendly.com/craftnote/kostenfrei_online-demo_app',
			'PopupText',
			{
				prefill: {
					email: user.email,
					name: this.memberFullNamePipe.transform(user),
					customAnswers: {
						a1: trade,
						a2: companySize,
						a3: mobile,
					},
				},
			},
		);
	}
}
