import { Member } from 'domain-entities';
import { isNil, omitBy } from 'lodash';
import { InvitationRequiredOnly } from '@shared/firebase/connectors/firestore/collections/invitations/invitations.connector';

export function createCompanyInvitationByCompanyIdAndMember(
	companyId: string,
	_userId: string,
	member: Member,
): InvitationRequiredOnly {
	return {
		resourceId: companyId,
		resourceType: 'company',
		inviteeEmail: member.email.toLowerCase(),
		context: omitBy(
			{
				memberRole: member.role,
				jobTitle: member.jobTitle,
				mobile: member.mobile,
			},
			(val) => val === '' || isNil(val),
		),
	};
}

export function createProjectInvitationByProjectIdAndMember(
	projectId: string,
	member: Member,
	addToFolder = false,
): InvitationRequiredOnly {
	return {
		resourceId: projectId,
		resourceType: 'project',
		inviteeEmail: member.email.toLowerCase(),
		context: {
			folderInvite: addToFolder,
		},
	};
}
