import { Profile } from 'domain-entities';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

export enum AuthLifecycle {
	PENDING,
	LOGGED_IN,
	LOGGED_OUT,
	UNVERIFIED_LOGGED_IN,
}

export type LoggedInStates = AuthLifecycle.LOGGED_IN | AuthLifecycle.UNVERIFIED_LOGGED_IN;

export function isLoggedInState(state: AuthLifecycle): state is LoggedInStates {
	return [AuthLifecycle.LOGGED_IN, AuthLifecycle.UNVERIFIED_LOGGED_IN].includes(state);
}

export enum UserRegistrationType {
	FRESH = 'fresh',
	EXISTING = 'existing',
	UNAUTHENTICATED = 'unauthenticated',
	IN_PROGRESS = 'in-progress',
}

export interface AuthState {
	user: Profile | undefined;
	userId: string | undefined;
	lifeCycle: AuthLifecycle;
	registrationType: UserRegistrationType;
	loadingStatus: StoreLoadingStatus;
}
