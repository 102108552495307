import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-project-list-nudge',
	templateUrl: './project-list-nudge.component.html',
	styleUrls: ['./project-list-nudge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListNudgeComponent {
	@Output() closeNudge = new EventEmitter<void>();
	@Input() icon: string;
}
