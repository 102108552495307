import { createAction, props } from '@ngrx/store';
import { Subscription } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';

export const setTrialSubscriptionAction = createAction(
	'[Subscription] Set Trial Subscription',
	props<{ trialSubscription: Subscription }>(),
);

export const setCheckoutProgressAction = createAction(
	'[Subscription] Set Checkout Progress',
	props<{ checkoutInProgress: boolean }>(),
);

export const loadTrailSubscriptionAction = createAction('[Subscription] Load Trial Subscription');

export const setSubscriptionEntities = createAction(
	'[Subscriptions] Set Subscriptions',
	props<{ changes: EntityChanges<Subscription> }>(),
);

export const initLoadSubscriptionEntities = createAction(
	'[Subscriptions] Init Load Subscription Entities',
);
