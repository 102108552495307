import { Component } from '@angular/core';
import { NotificationTokenService } from '@injectables/services/notification-token/notification-token.service';

@Component({
	selector: 'app-notification-token-permission-default',
	templateUrl: './notification-token-permission-default.component.html',
})
export class NotificationTokenPermissionDefaultComponent {
	constructor(private readonly notificationTokenService: NotificationTokenService) {}

	async openNativeNotificationPermissionPopup(): Promise<void> {
		await this.notificationTokenService.requestNotificationToken();
	}
}
