import { NgModule } from '@angular/core';
import { MemberRoleDirective } from './member-role.directive';

const DIRECTIVE = [MemberRoleDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class MemberRoleModule {}
