import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EventStopPropagationModule, NgVarModule, PreventDefaultModule } from './directives';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [],
	exports: [
		CommonModule,
		TranslateModule,
		PreventDefaultModule,
		EventStopPropagationModule,
		NgVarModule,
		FormsModule,
	],
	providers: [],
})
export class SharedModule {}
