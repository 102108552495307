<div *ngIf="slides" class="d-flex flex-column h-100 w-100">
	<div *ngIf="sliderCanBeCanceled" class="cn-company-init-close d-flex p-4 font-weight-light w-100 justify-content-end">
		<div (click)="onClose()" class="d-flex align-items-center pointer">
			<mat-icon class="cn-company-init-close_icon mr-2">close</mat-icon>
			<div>{{ 'company-init.actions.close' | translate }}</div>
		</div>
	</div>
	<div class="d-flex flex-grow-1 justify-content-center align-items-center">
		<app-shared-slider-dialog
			(changes)="onChanges($event)"
			(stepCompleted)="onStepCompleted($event)"
			(values)="onComplete($event)"
			[slides]="slides"
		>
		</app-shared-slider-dialog>
	</div>
</div>
