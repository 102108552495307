import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'mt-named-icon',
	templateUrl: './named-icon.component.html',
	styleUrls: ['./named-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NamedIconComponent implements OnChanges {
	@Input() maxChars = 1;
	@Input() name = '-';
	@Input() color = 'rgba(0, 0, 0, 0.04)';
	@Input() notification = false;

	charToDisplay = '-';

	ngOnChanges(): void {
		this.calcCharToDisplay();
	}

	private calcCharToDisplay(): void {
		if (this.name === '-') {
			return;
		}

		let name = '';

		this.name
			.trim()
			.split(' ')
			.forEach((string, index) => {
				if (index >= this.maxChars) {
					return;
				}
				name = `${name}${string.substr(0, 1)}`;
			});

		this.charToDisplay = name;
	}
}
