<section class="company-members-dialog">
	<div class="d-flex align-items-center mb-3">
		<div class="mb-0 mat-display-1" data-cy="invite-company-member-modal">
			<span *ngIf="data.operation === CompanyMemberOperation.ADD">
				{{ 'companyMembers.invite.dialog.title.addEmployee' | translate }}
			</span>
			<span *ngIf="data.operation === CompanyMemberOperation.EDIT">
				{{ 'companyMembers.invite.dialog.title.editEmployee' | translate }}
			</span>
		</div>
		<mat-icon (click)="closeDialog()" class="pointer ml-auto" data-cy="close-invitation-modal">close</mat-icon>
	</div>
	<app-add-edit-company-employee
		[member]="data.member"
		[context]="data.context"
		[operation]="data.operation"
		(afterInvitationSubmit)="closeDialog()"
	>
	</app-add-edit-company-employee>
</section>
