import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
	name: 'isNil',
})
export class IsNilPipe implements PipeTransform {
	transform(value, text: string = ''): string {
		return isNil(value) || !value ? text : value;
	}
}
