import { Profile } from 'domain-entities';
import { unset } from 'lodash';

export function updateProfileToc(
	status: 'accepted' | 'declined',
	dateAgreed: number,
	version: number,
): (oldProfile: Profile) => Partial<Profile> | undefined {
	return (oldProfile: Profile) => {
		if (oldProfile?.toc?.version > version) {
			return undefined;
		}
		const partialProfile = {
			toc: {
				version,
				setAt: dateAgreed,
				status,
			},
		};

		if (status === 'declined') {
			return partialProfile;
		}

		return {
			...partialProfile,
			tocDateAgreed: dateAgreed,
			tocVersionAgreed: version,
		};
	};
}

export function removeNotificationToken(token: string): (oldProfile: Profile) => Partial<Profile> {
	return function (oldProfile: Profile): Partial<Profile> {
		const newProfile: Partial<Profile> = {};
		newProfile.notificationTokens = oldProfile.notificationTokens
			? { ...oldProfile.notificationTokens }
			: {};
		unset(newProfile.notificationTokens, token);
		return newProfile;
	};
}
