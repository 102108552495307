import { filter, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { ActivationStart, Router } from '@angular/router';
import {
	ProfileTrackerService,
	TrackerInput,
} from '@injectables/profile-tracker/profile-tracker.service';
import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { ClickIdInput, ClickIdService } from '@injectables/services/click-id.service';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectActiveProject, selectIsProjectArchiveRoute } from '@store/selectors/route.selectors';
import { NEVER } from 'rxjs';
import { selectProjectById } from '@store/selectors/projects.selectors';

@Injectable()
export class NavigationEffects {
	constructor(
		private readonly router: Router,
		private readonly trackersService: ProfileTrackerService,
		private readonly clickIdService: ClickIdService,
		private readonly projectHelperService: ProjectHelperService,
		private readonly store: Store<AppState>,
	) {}

	updateLocalStorageTrackers$ = createEffect(
		() =>
			this.router.events.pipe(
				filter((event) => event instanceof ActivationStart),
				take(1),
				tap((event) => {
					const activationStartEvent = event as ActivationStart;
					this.trackersService.setTrackerToLocalStorage(
						activationStartEvent.snapshot.queryParams as TrackerInput,
					);
					this.clickIdService.setClickIdsToLocalStorage(
						activationStartEvent.snapshot.queryParams as ClickIdInput,
					);
				}),
			),
		{ dispatch: false },
	);

	/**
	 * This effect is used to make sure that when an activated project that used to be available becomes unavailable
	 * we are closing the project and redirect to the default project
	 *
	 */
	closeUnavailableProjects$ = createEffect(
		() =>
			this.store.select(selectActiveProject).pipe(
				switchMap((projectId) => {
					if (!projectId) {
						return NEVER;
					}
					return this.store.select(selectProjectById(projectId)).pipe(
						skipWhile((project) => !project),
						filter((project) => !project),
						switchMap(() => this.store.select(selectIsProjectArchiveRoute)),
					);
				}),
				tap((isArchive) => {
					void this.projectHelperService.goToHome(null, isArchive);
				}),
			),
		{ dispatch: false },
	);
}
