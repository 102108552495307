export enum PasswordStrength {
	LOW = 'low',
	MEDIUM = 'medium',
	STRONG = 'strong',
	INSUFFICIENT = 'insufficient',
}

export function checkPasswordStrength(password: string): PasswordStrength {
	if (!password || password.length < 8) {
		return PasswordStrength.INSUFFICIENT;
	}

	let strength = 0;

	if (/[0-9]/.test(password)) {
		strength++;
	}
	if (/[a-z]/.test(password)) {
		strength++;
	}
	if (/[A-Z]/.test(password)) {
		strength++;
	}

	switch (strength) {
		case 3:
			return PasswordStrength.STRONG;
		case 2:
			return PasswordStrength.MEDIUM;
		default:
			return PasswordStrength.LOW;
	}
}
