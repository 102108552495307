import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import {
	buildFirestoreCollectionPath,
	buildFirestoreDocumentPath,
} from '@utils/functions/firestore/firestore-path.functions';
import { ProfileConnector } from '@shared/firebase/connectors/firestore/collections/profiles/profile.connector';
import { Device, isDevice } from 'domain-entities';
import { Observable } from 'rxjs';

const DEVICE_REQUIRED_FIELDS: RequiredKey<Device>[] = [
	'id',
	'platform',
	'name',
	'notificationPermissionEnabled',
	'creationDate',
];

@Injectable({ providedIn: 'root' })
export class DevicesConnector {
	static readonly COLLECTION_NAME = 'devices';

	constructor(private readonly firestoreConnector: FirestoreConnector) {}

	async getDevice(userId: string, deviceId: string): Promise<Device> {
		const path = this.buildDeviceDocumentPath(userId, deviceId);
		try {
			return await this.firestoreConnector.getDocument<Device>(path, isDevice);
		} catch (error) {
			return null;
		}
	}

	watchDevice(userId: string, deviceId: string): Observable<Device> {
		const path = this.buildDeviceDocumentPath(userId, deviceId);
		return this.firestoreConnector.watchDocument<Device>(path, isDevice);
	}

	createDevice(userId: string, device: Device): Promise<void> {
		const path = this.buildDeviceCollectionPath(userId);
		return this.firestoreConnector.create(path, device.id, device, isDevice);
	}

	async updateDevicePartial(
		userId: string,
		deviceId: string,
		updateObject: Partial<Device>,
	): Promise<void> {
		await this.firestoreConnector.updateDocumentPartial<Device>(
			`${ProfileConnector.COLLECTION_NAME}/${userId}/${DevicesConnector.COLLECTION_NAME}`,
			deviceId,
			updateObject,
			DEVICE_REQUIRED_FIELDS,
		);
	}

	async updateChannel(
		userId: string,
		deviceId: string,
		channelKey: string,
		action: 'add' | 'remove',
	): Promise<void> {
		const path = this.buildDeviceDocumentPath(userId, deviceId);

		await this.firestoreConnector.updateDocumentArrayField<Device>(
			path,
			'disabledNotificationChannels' as any,
			channelKey,
			action,
		);
	}

	private buildDeviceCollectionPath(userId: string): string {
		return buildFirestoreCollectionPath(
			[{ collectionName: ProfileConnector.COLLECTION_NAME, documentId: userId }],
			DevicesConnector.COLLECTION_NAME,
		);
	}

	private buildDeviceDocumentPath(userId: string, deviceId): string {
		return buildFirestoreDocumentPath([
			{ collectionName: ProfileConnector.COLLECTION_NAME, documentId: userId },
			{ collectionName: DevicesConnector.COLLECTION_NAME, documentId: deviceId },
		]);
	}
}
