<div class="d-flex h-100 flex-column">
	<app-settings-page-header backNavigationRoute="/settings">{{ 'settings.archive' | translate }}</app-settings-page-header>
	<div class="d-flex flex-grow-1 overflow-hidden">
		<app-projects-area></app-projects-area>
		<ng-container *ngIf="projectIdFound$ | async">
			<div class="d-flex flex-grow-1">
				<div class="flex-grow-1 d-flex flex-column">
					<section>
						<app-chat-header (activeButton)="handleMenuButton($event)"></app-chat-header>
					</section>
					<div class="d-flex w-100 flex-grow-1 overflow-auto">
						<div class="app-chat-container d-flex flex-grow-1">
							<app-chat [menuButton]="menuButton$ | async" class="app-chat flex-grow-1"></app-chat>
						</div>
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
