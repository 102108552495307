import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-mobile',
	templateUrl: './mobile.component.html',
	styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit {
	android = false;
	ios = false;

	ngOnInit(): void {
		if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			this.ios = true;
			this.android = false;
		} else if (/Android/i.test(navigator.userAgent)) {
			this.android = true;
			this.ios = false;
		} else {
			this.android = true;
			this.ios = true;
		}
	}
}
