import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { GroupByOption } from '@work/project-search/types/project-sort.types';
import { Project } from 'domain-entities';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { difference, flatten, groupBy as lodashGroupBy, keys } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ProjectType } from '@shared/models/project.type';
import { getUnixTime } from 'date-fns';
import { LocalStorageService } from '@injectables/services/local-storage.service';

const FOLDERS_GROUP_SIGNIFIER = 'FOLDERS_GROUP';
const NO_STATUS_SIGNIFIER = 'NO_STATUS_GROUP';
const MINIMUM_NUMBER_OF_PROJECTS = 10;

interface ProjectListGroupItem {
	type: 'group';
	groupText: string;
	groupId: string;
	collapsed: boolean;
}

interface ProjectListProjectItem {
	type: 'project';
	project: Project;
	isLastItemInList: boolean;
}

interface ProjectListArchivationNudgeItem {
	type: 'archivation-nudge';
}

export interface ProjectListFailedUploadNudgeItem {
	type: 'failed-upload-nudge';
	projects: unknown[];
}

export interface ProjectListStoreUpstreamData {
	projects: Project[];
	archivableProjects: Project[];
	projectsWithFailedUploads: Project[];
	projectStatus: ProjectStatus[];
	groupBy: GroupByOption;
	canShowArchivationNudge: boolean;
	canShowFailedUploadsNudge: boolean;
}

export type ProjectListItem =
	| ProjectListProjectItem
	| ProjectListGroupItem
	| ProjectListArchivationNudgeItem
	| ProjectListFailedUploadNudgeItem;

interface ProjectListState extends ProjectListStoreUpstreamData {
	collapsedGroups: string[];
	nudgeMuteTime: number | undefined;
}

const defaultState: ProjectListState = {
	groupBy: GroupByOption.NONE,
	projects: [],
	projectStatus: [],
	collapsedGroups: [],
	archivableProjects: [],
	projectsWithFailedUploads: [],
	canShowArchivationNudge: true,
	canShowFailedUploadsNudge: true,
	nudgeMuteTime: undefined,
};

@Injectable()
export class ProjectListStore extends ComponentStore<ProjectListState> {
	private isCollapsedGroupsLoaded = false;
	readonly setUpstreamData = this.updater<ProjectListStoreUpstreamData>((state, upstreamData) => ({
		...state,
		...upstreamData,
	}));

	readonly setNudgeMuteTime = this.updater<number>((state, nudgeMuteTime) => {
		return { ...state, nudgeMuteTime };
	});

	readonly toggleCollapse = this.updater<string>((state, collapsedId) => {
		const newCollapsedGroups = state.collapsedGroups.includes(collapsedId)
			? state.collapsedGroups.filter((id) => id !== collapsedId)
			: [...state.collapsedGroups, collapsedId];

		this.saveCollapsedGroups(newCollapsedGroups);
		return {
			...state,
			collapsedGroups: newCollapsedGroups,
		};
	});

	readonly archivableProjects$ = this.select((state) => state.archivableProjects);

	projectListItems$ = this.select(
		({
			projects,
			groupBy,
			projectStatus,
			collapsedGroups,
			archivableProjects,
			nudgeMuteTime,
			projectsWithFailedUploads,
			canShowArchivationNudge,
			canShowFailedUploadsNudge,
		}) => {
			let items: ProjectListItem[];
			if (groupBy === GroupByOption.STATUS) {
				const groupTextBuilder = this.statusGroupItemBuilderFactory(projectStatus, collapsedGroups);
				items = this.buildProjectItems(
					'statusId',
					projectStatus.map((statusInner) => statusInner.id),
					groupTextBuilder,
					projects,
					collapsedGroups,
				);
			} else {
				items = this.buildProjectTypeListItems(projects);
			}

			// Don't show any nudges in archive or open folder view
			if (canShowArchivationNudge && this.getShowNudge(nudgeMuteTime, archivableProjects)) {
				items = [{ type: 'archivation-nudge' } as ProjectListArchivationNudgeItem, ...items];
			}

			if (canShowFailedUploadsNudge && projectsWithFailedUploads.length > 0) {
				items = [
					{
						type: 'failed-upload-nudge',
						projects: projectsWithFailedUploads,
					} as ProjectListFailedUploadNudgeItem,
					...items,
				];
			}
			return items;
		},
	);

	constructor(
		private readonly translateService: TranslateService,
		private readonly localStorageService: LocalStorageService,
	) {
		super(defaultState);
		this.loadCollapsedGroups();
		void this.setShowNudge();
	}

	async loadCollapsedGroups(): Promise<void> {
		if (!this.isCollapsedGroupsLoaded) {
			const collapsedGroups =
				(await this.localStorageService.get('projectListCollapsedGroups')) || [];
			this.patchState({ collapsedGroups });
			this.isCollapsedGroupsLoaded = true;
		}
	}

	private buildProjectItems(
		groupByKey: keyof Project,
		identifiers: string[],
		groupTextBuilder: (
			identifier: string,
			projects: Project[],
			collapsedIdentifiers: string[],
			fixedGroupNameKey?: string,
		) => ProjectListGroupItem,
		projects: Project[],
		collapsedGroupsIdentifiers: string[],
	): ProjectListItem[] {
		const items: ProjectListItem[] = [];

		// First group contains all folders if there are any
		const groupedByType = lodashGroupBy(
			projects,
			(project) => project.projectType || ProjectType.PROJECT,
		);
		const folders = groupedByType[ProjectType.FOLDER] || [];
		if (folders.length) {
			items.push(groupTextBuilder(FOLDERS_GROUP_SIGNIFIER, folders, collapsedGroupsIdentifiers));
			if (!collapsedGroupsIdentifiers.includes(FOLDERS_GROUP_SIGNIFIER)) {
				items.push(...this.buildProjectTypeListItems(folders));
			}
		}

		// Next comes one special container group for all items which do not fit any group definition
		const groupedProjects = lodashGroupBy(groupedByType[ProjectType.PROJECT] || [], groupByKey);

		const notIncludedIdentifiers = difference(keys(groupedProjects), identifiers);
		const allUnidentified = flatten(
			notIncludedIdentifiers.map((identifier) => groupedProjects[identifier]),
		);
		if (allUnidentified.length) {
			items.push(
				groupTextBuilder(NO_STATUS_SIGNIFIER, allUnidentified, collapsedGroupsIdentifiers),
			);
			if (!collapsedGroupsIdentifiers.includes(NO_STATUS_SIGNIFIER)) {
				items.push(...this.buildProjectTypeListItems(allUnidentified));
			}
		}

		// Lastly come the groups for each requested group definition
		for (const identifier of identifiers) {
			const groupItems = groupedProjects[identifier] ?? [];
			items.push(groupTextBuilder(identifier, groupItems, collapsedGroupsIdentifiers));
			if (!collapsedGroupsIdentifiers.includes(identifier)) {
				items.push(...this.buildProjectTypeListItems(groupItems));
			}
		}

		return items;
	}

	private buildProjectTypeListItems(projects: Project[]): ProjectListProjectItem[] {
		return projects.map((project, index) => ({
			type: 'project',
			project,
			isLastItemInList: index === projects.length - 1,
		}));
	}

	private statusGroupItemBuilderFactory(
		statusList: ProjectStatus[],
		collapsedIdentifiers: string[],
	): (id: string, projects: Project[], collapsedIdentifiers: string[]) => ProjectListGroupItem {
		return (statusId: string, projects: Project[]) => {
			if (statusId === FOLDERS_GROUP_SIGNIFIER) {
				return {
					type: 'group',
					groupText: `${this.translateService.instant('projects-list.groups.status.folders')} (${
						projects.length
					})`,
					groupId: FOLDERS_GROUP_SIGNIFIER,
					collapsed: collapsedIdentifiers.includes(FOLDERS_GROUP_SIGNIFIER),
				};
			} else if (statusId === NO_STATUS_SIGNIFIER) {
				return {
					type: 'group',
					groupText: `${this.translateService.instant('projects-list.groups.status.unknown')} (${
						projects.length
					})`,
					groupId: NO_STATUS_SIGNIFIER,
					collapsed: collapsedIdentifiers.includes(NO_STATUS_SIGNIFIER),
				};
			}

			const status = statusList.find((statusInner) => statusInner.id === statusId);
			return {
				type: 'group',
				groupText: `${status?.name} (${projects.length})`,
				groupId: statusId,
				collapsed: collapsedIdentifiers.includes(statusId),
			};
		};
	}

	private async setShowNudge(): Promise<void> {
		const nudgeMuteTime = await this.localStorageService.get('projectArchivationNudge');
		this.setState((state) => ({ ...state, nudgeMuteTime }));
	}

	private getShowNudge(nudgeTime: number, relevantProjects: Project[]): boolean {
		if (nudgeTime === null) {
			return false;
		}
		const isNudgeUnmuted = !nudgeTime || nudgeTime < getUnixTime(new Date());
		const isNumberOfArchivableProjectsSufficient =
			relevantProjects.length >= MINIMUM_NUMBER_OF_PROJECTS;

		return isNudgeUnmuted && isNumberOfArchivableProjectsSufficient;
	}

	private saveCollapsedGroups(collapsedGroups: string[]): void {
		const filteredGroups = collapsedGroups.filter((group) => typeof group === 'string');
		void this.localStorageService.set('projectListCollapsedGroups', filteredGroups);
	}
}
