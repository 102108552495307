export interface TrackedTimeAddEditFormPauseValue {
	start: Date;
	duration: number;
	breaks: TrackedTimeAddEditFormPauseBreak[];
}

export interface TrackedTimeAddEditFormPauseBreak {
	start: Date;
	end: Date;
}

export enum TrackedTimeAddEditErrors {
	INVALID_PAUSE_START = 'time-tracking-add-edit-form.errors.invalid_pause_start',
	PAUSE_ENDS_AFTER_END = 'time-tracking-add-edit-form.errors.pause_end_after_end',
	ENTRY_TIME_MORE_GREATER_24_HOURS = 'time-tracking-add-edit-form.errors.entry_length_greater_24_hours',
	ENTRY_START_AFTER_END = 'time-tracking-add-edit-form.errors.entry_starts_after_end',
	INVALID_BREAK_TIME = 'time-tracking-add-edit-form.errors.invalid_break_time',
	BREAK_STARTS_BEFORE_ENTRY = 'time-tracking-add-edit-form.errors.break_starts_before_entry',
	BREAK_ENDS_AFTER_ENTRY = 'time-tracking-add-edit-form.errors.break_ends_after_entry',
	BREAK_START_AFTER_END = 'time-tracking-add-edit-form.errors.break_start_after_end',
	BREAK_OUTSIDE_ENTRY = 'time-tracking-add-edit-form.errors.break_outside_entry',
	OVERLAPPING_BREAKS = 'time-tracking-add-edit-form.errors.overlapping_breaks',
}

export interface TrackedTimeAddEditForm {
	startDate: Date;
	startTime: Date;
	endDate: Date;
	endTime: Date;
	pause: { start: Date; duration: number, breaks: TrackedTimeAddEditFormPauseBreak[] };
	workTypeId: string;
	assigneeIds: string[];
	projectId: string;
	comment: string;
	breaks?: { start: Date; end: Date }[];
}
