import { NgModule } from '@angular/core';
import { ProjectNotesComponent } from './project-notes.component';
import { ProjectNotesDialogComponent } from './project-notes-modal/project-notes-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { UnixToMillisPipeModule } from '../../pipes/unix-to-millis/unix-to-millis-pipe.module';

@NgModule({
	imports: [
		ReactiveFormsModule,
		MatFormFieldModule,
		NgxTrimDirectiveModule,
		TextFieldModule,
		MatInputModule,
		SharedModule,
		MatButtonModule,
		MatIconModule,
		UnixToMillisPipeModule,
	],
	declarations: [ProjectNotesComponent, ProjectNotesDialogComponent],
	exports: [ProjectNotesDialogComponent, ProjectNotesComponent],
})
export class ProjectNotesModule {}
