import { isProject, Project } from 'domain-entities';
import { clearEntities, DataQueryCondition, EntityChanges } from '@craftnote/shared-utils';
import { combineLatest, from, Observable, of, switchMap } from 'rxjs';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { capitalize, chunk, flatMap, isNil } from 'lodash';
import { map } from 'rxjs/operators';
import { ProjectRestConnector } from '@shared/firebase/connectors/firestore/collections/project/project-rest.connector';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';

@Injectable({
	providedIn: 'root',
})
export class ProjectConnector {
	private static COLLECTION_NAME = 'projects';

	constructor(
		private readonly restConnector: ProjectRestConnector,
		private readonly connector: FirestoreConnector,
		private readonly remoteConfig: RemoteConfig,
	) {}

	createProject(project: Project): Promise<void> {
		return this.connector.create(ProjectConnector.COLLECTION_NAME, project.id, project, isProject);
	}

	updateProjectTransactional(
		projectId: string,
		updateFunction: (oldEntity: Project) => Partial<Project> | undefined,
	): Promise<void> {
		return this.connector.updateDocumentTransactional(
			ProjectConnector.COLLECTION_NAME,
			projectId,
			updateFunction,
			isProject,
		);
	}

	watchCompanyProjects(
		companyId: string,
		changedAfter?: number,
	): Observable<EntityChanges<Project>> {
		const conditions: DataQueryCondition<Project>[] = [
			{
				field: 'company',
				operator: '==',
				value: companyId,
			},
		];

		if (!isNil(changedAfter)) {
			conditions.push({
				field: 'lastEditedDate',
				operator: '>=',
				value: changedAfter,
			});
		}
		return this.connector.watchDocumentsChanges<Project>(
			ProjectConnector.COLLECTION_NAME,
			conditions,
		);
	}

	watchScopedProjects(
		userId: string,
		scope: 'active' | 'archived',
	): Observable<EntityChanges<Project>> {
		if (!userId) {
			return of(clearEntities);
		}

		const remoteConfig = this.remoteConfig.getBooleanAsync('feature_use_bff_in_client');

		return from(remoteConfig).pipe(
			switchMap((config) => {
				if (config) {
					console.log('Using REST API for active projects');
					return this.restConnector.watchProjectChanges(userId, scope);
				} else {
					const condition: DataQueryCondition<Project> = {
						field: `members${capitalize(scope)}` as keyof Project,
						operator: 'array-contains',
						value: userId,
					};
					return this.connector.watchDocumentsChanges<Project>(
						ProjectConnector.COLLECTION_NAME,
						[condition],
						isProject,
					);
				}
			}),
		);
	}

	watchActiveProjects(userId: string): Observable<EntityChanges<Project>> {
		return this.watchScopedProjects(userId, 'active');
	}

	watchArchivedProjects(userId: string): Observable<EntityChanges<Project>> {
		return this.watchScopedProjects(userId, 'archived');
	}

	watchProjects(companyId: string, projects: string[]): Observable<Project[]> {
		const projectChunks = chunk(projects, 10);
		const observables: Observable<Project[]>[] = [];
		if (!projects.length) {
			return of([]);
		}
		const companyIdCondition: DataQueryCondition<Project> = {
			field: 'company',
			operator: '==',
			value: companyId,
		};

		for (const projectChunk of projectChunks) {
			const condition: DataQueryCondition<Project> = {
				field: 'id',
				operator: 'in',
				value: projectChunk,
			};
			const obs = this.connector.watchDocuments<Project>(ProjectConnector.COLLECTION_NAME, [
				condition,
				companyIdCondition,
			]);
			observables.push(obs);
		}
		return combineLatest(observables).pipe(map((projectsChunks) => flatMap(projectsChunks)));
	}

	getProject(projectId: string): Promise<Project | undefined> {
		return this.connector.getDocument<Project>(
			`${ProjectConnector.COLLECTION_NAME}/${projectId}`,
			isProject,
		);
	}
}
