import { NgModule } from '@angular/core';
import { CompanyInitializationComponent } from './company-initialization.component';
import { CompanyInitializationRoutingModule } from './company-initialization.routing.module';
import { SharedSliderDialogModule } from '../../shared/components/shared-slider-dialog/shared-slider-dialog.module';
import { AddEditCompanyEmployeeModule } from '../../shared/components/add-edit-company-employee/add-edit-company-employee.module';
import { AddBulkCompanyEmployeesModule } from '../../shared/components/add-bulk-company-employees/add-bulk-company-employees.module';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
	declarations: [CompanyInitializationComponent],
	imports: [
		CompanyInitializationRoutingModule,
		SharedSliderDialogModule,
		AddEditCompanyEmployeeModule,
		AddBulkCompanyEmployeesModule,
		SharedModule,
		MatButtonModule,
	],
	exports: [CompanyInitializationRoutingModule, CompanyInitializationComponent],
})
export class CompanyInitializationModule {}
