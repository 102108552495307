import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { PulsingCraftnoteIconComponent } from './pulsing-craftnote-icon.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [PulsingCraftnoteIconComponent],
	imports: [SharedModule, MatIconModule],
	exports: [PulsingCraftnoteIconComponent],
})
export class PulsingCraftnoteIconModule {}
