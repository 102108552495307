import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
	SharedSliderDialogFormSlide,
	SharedSliderDialogSideType,
	SharedDialogElement,
} from '../../entities/shared-slider-dialog.data';

@Component({
	selector: 'app-shared-slider-dialog-slide',
	templateUrl: './shared-slider-dialog-slide.component.html',
	styleUrls: ['./shared-slider-dialog-slide.component.scss'],
})
export class SharedSliderDialogSlideComponent {
	@Input() slide: SharedDialogElement | SharedSliderDialogFormSlide;
	@Input() control: UntypedFormControl | UntypedFormGroup;

	@Input() index = 0;
	@Input() totalNumberOfSlides = 0;

	@Output() next = new EventEmitter<void>();
	@Output() value = new EventEmitter<string>();

	SharedSliderDialogSideType = SharedSliderDialogSideType;

	onNext(): void {
		if (!this.control.valid) {
			return;
		}
		this.next.emit();
	}
}
