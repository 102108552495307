import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskTime } from 'domain-entities';
import { first, scan, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { selectUserFullName, selectUserId } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { EntityChanges } from '@craftnote/shared-utils';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@injectables/services/tracking.service';
import { TasksTimeCreatedEventBuilder } from '@generated/events/TasksEvents.generated';
import { TasksDashboardService } from '@injectables/services/tasks-dashboard/tasks-dashboard.service';

// Note:: Working Hours and Task Times are same thing

interface WorkingHoursForm {
	startDate: Date;
	startTime: Date;
	endDate: Date;
	endTime: Date;
}

@Component({
	selector: 'app-task-edit-working-hours',
	templateUrl: './task-edit-working-hours.component.html',
	styleUrls: ['./task-edit-working-hours.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditWorkingHoursComponent implements OnChanges, OnInit, OnDestroy {
	@Input() taskId: string = null;
	@Input() projectId: string;
	@Output('openTaskEditDetails') openTaskEditDetailsEmitter = new EventEmitter();
	@Output('openWorkingHoursUpdate') openWorkingHoursUpdateEmitter = new EventEmitter();
	addWorkingHoursForm: UntypedFormGroup = this.fb.group({
		startDate: [[Validators.required]],
		startTime: [[Validators.required]],
		endDate: [[Validators.required]],
		endTime: [[Validators.required]],
	});
	destroy$: Subject<boolean> = new Subject();
	taskId$: ReplaySubject<string> = new ReplaySubject();
	workingHoursTotalTime: string;
	workingHours$: Observable<TaskTime[]> = this.taskId$.pipe(
		switchMap((taskId) =>
			this.tasksDashboardService.getTaskTimesById(taskId).pipe(
				scan((totalWorkingHours: TaskTime[], taskTimesEntityChanges: EntityChanges<TaskTime>) => {
					if (taskTimesEntityChanges.changeType === 'created') {
						totalWorkingHours.push(...taskTimesEntityChanges.entities);
					}
					if (taskTimesEntityChanges.changeType === 'updated') {
						taskTimesEntityChanges.entities.forEach((updatedTaskTime) => {
							const taskTimeIndexToReplace = totalWorkingHours.findIndex(
								(taskTime) => taskTime.id === updatedTaskTime.id,
							);
							totalWorkingHours[taskTimeIndexToReplace] = updatedTaskTime;
						});
					}
					if (taskTimesEntityChanges.changeType === 'deleted') {
						taskTimesEntityChanges.entities.forEach((updatedTaskTime) => {
							const taskTimeIndexToReplace = totalWorkingHours.findIndex(
								(taskTime) => taskTime.id === updatedTaskTime.id,
							);
							totalWorkingHours.splice(taskTimeIndexToReplace, 1);
						});
					}
					return totalWorkingHours;
				}, []),
				tap((workingHours) => {
					this.workingHoursTotalTime = this.formatSeconds(
						this.calculateTotalWorkingHours(workingHours),
					);
				}),
			),
		),
	);

	get addWorkingHoursFormInitialValue(): WorkingHoursForm {
		return {
			startDate: moment().toDate(),
			startTime: moment('09', 'HH').toDate(),
			endDate: moment().toDate(),
			endTime: moment('18', 'HH').toDate(),
		};
	}

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly tasksDashboardService: TasksDashboardService,
		private readonly store: Store,
		private readonly translate: TranslateService,
		private readonly trackingService: TrackingService,
	) {}

	ngOnInit(): void {
		this.addWorkingHoursForm.setValue(this.addWorkingHoursFormInitialValue);
		this.addWorkingHoursForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.addWorkingHoursForm.patchValue(
				{
					startDate: this.startDateTime,
					startTime: this.startDateTime,
					endDate: this.endDateTime,
					endTime: this.endDateTime,
				},
				{ emitEvent: false },
			);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	formatSeconds(seconds: number): string {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);

		let formattedTime = '';
		if (hours > 0) {
			const postFix =
				hours === 1
					? this.translate.instant('dashboard.timeTracking.hour')
					: this.translate.instant('dashboard.timeTracking.hours');
			formattedTime = `${hours} ${postFix}`;
		}
		if (minutes > 0) {
			const postFix =
				minutes === 1
					? this.translate.instant('dashboard.timeTracking.minute')
					: this.translate.instant('dashboard.timeTracking.minutes');
			formattedTime = ` ${formattedTime} ${minutes} ${postFix}`;
		}
		return formattedTime;
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.taskId?.currentValue) {
			this.taskId$.next(changes.taskId.currentValue);
		}
	}

	openTaskEditDetails(): void {
		this.addWorkingHoursForm.reset(this.addWorkingHoursFormInitialValue);
		this.openTaskEditDetailsEmitter.emit();
	}

	get addWorkingHoursFormInvalid(): boolean {
		return this.addWorkingHoursForm.invalid || moment(this.startDateTime).isAfter(this.endDateTime);
	}

	get startDateTime(): Date {
		const workingHoursFormValue = this.addWorkingHoursForm.value;
		const startDate: Moment = moment(workingHoursFormValue.startDate || new Date());
		const startTime: Date = moment(workingHoursFormValue.startTime || new Date()).toDate();
		const startDateTime = startDate
			.set({
				hour: startTime.getHours(),
				minute: startTime.getMinutes(),
				second: 0,
			})
			.toDate();
		return startDateTime;
	}

	get endDateTime(): Date {
		const workingHoursFormValue = this.addWorkingHoursForm.value;
		const endDate: Moment = moment(workingHoursFormValue.endDate || new Date());
		const endTime: Date = moment(workingHoursFormValue.endTime || new Date()).toDate();
		const endDateTime = endDate
			.set({
				hour: endTime.getHours(),
				minute: endTime.getMinutes(),
				second: 0,
			})
			.toDate();
		return endDateTime;
	}

	async createNewTaskTime(): Promise<void> {
		const userId = await this.store.select(selectUserId).pipe(first()).toPromise();
		const userName = await this.store.select(selectUserFullName).pipe(first()).toPromise();
		await this.tasksDashboardService.createTaskTime({
			id: uuid(),
			taskId: this.taskId,
			startTime: moment(this.startDateTime).unix(),
			endTime: moment(this.endDateTime).unix(),
			userId,
			userName,
		});
		this.addWorkingHoursForm.reset(this.addWorkingHoursFormInitialValue);
		await this.trackingService.trackEvent(
			new TasksTimeCreatedEventBuilder({
				projectId: this.projectId,
			}),
		);
	}

	// Note: Returns seconds
	private calculateTotalWorkingHours(workingHours: TaskTime[]): number {
		if (workingHours.length === 0) {
			return null;
		}
		return workingHours.reduce(
			(totalTime, taskTime) => totalTime + (taskTime.endTime - taskTime.startTime),
			0,
		);
	}
}
