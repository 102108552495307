import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MatPaginatorIntImpl extends MatPaginatorIntl {
	constructor(private translateService: TranslateService) {
		super();
		this.getTranslations();
	}

	getTranslations(): void {
		this.translateService
			.get(['paginator.items_per_page', 'paginator.next_page', 'paginator.previous_page'])
			.subscribe((translation) => {
				this.itemsPerPageLabel = translation['paginator.items_per_page'];
				this.nextPageLabel = translation['paginator.next_page'];
				this.previousPageLabel = translation['paginator.previous_page'];
				this.changes.next();
			});
	}

	getRangeLabel: (page: number, pageSize: number, length: number) => string = (
		page,
		pageSize,
		length,
	) => {
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex =
			startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return this.translateService.instant('paginator.range', {
			startIndex: startIndex + 1,
			endIndex,
			length,
		});
	};
}
