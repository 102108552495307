<div class="reply-message w-100">
	<ng-content></ng-content>
	<div [ngSwitch]="replyMessage.messageType" class="message-body no-events">
		<ng-container *ngSwitchCase="MessageType.AUDIO" [ngTemplateOutlet]="messageTypeAudio"></ng-container>
		<ng-container *ngSwitchCase="MessageType.VIDEO" [ngTemplateOutlet]="messageTypeVideo"></ng-container>
		<ng-container *ngSwitchCase="MessageType.IMAGE" [ngTemplateOutlet]="messageTypeImage"></ng-container>
		<ng-container *ngSwitchCase="MessageType.DOCUMENT" [ngTemplateOutlet]="messageTypeDocument"></ng-container>
		<ng-container *ngSwitchCase="MessageType.TEXT" [ngTemplateOutlet]="messageTypeText"></ng-container>
		<ng-container *ngSwitchDefault [ngTemplateOutlet]="messageTypeNotSet"></ng-container>
	</div>
</div>

<ng-template #messageTypeText>
	<div>{{ replyMessage.content }}</div>
</ng-template>

<ng-template #messageTypeAudio>
	<vg-player *ngIf="file; else loading" class="audio-player">
		<vg-controls class="no-events">
			<vg-play-pause></vg-play-pause>

			<vg-time-display vgFormat="mm:ss" vgProperty="current"></vg-time-display>
			<vg-time-display vgFormat="mm:ss" vgProperty="total"></vg-time-display>

			<vg-scrub-bar>
				<vg-scrub-bar-current-time></vg-scrub-bar-current-time>
				<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
			</vg-scrub-bar>
		</vg-controls>

		<audio #media [vgMedia]="media" id="myAudio" preload="auto">
			<source [attr.src]="file" type="audio/aac" />
		</audio>
	</vg-player>
</ng-template>

<ng-template #messageTypeVideo>
	<div class="video">
		<div class="thumb d-flex justify-content-center">
			<img *ngIf="file; else loading" [alt]="file.name" [attr.src]="file" />
			<mat-icon class="align-self-center video-play-button">play_circle_outline</mat-icon>
		</div>
	</div>
</ng-template>

<ng-template #messageTypeImage>
	<div class="thumb d-flex justify-content-center">
		<img *ngIf="file; else loading" [alt]="file.name" [attr.src]="file" />
	</div>
</ng-template>

<ng-template #messageTypeDocument>
	<div class="message-document">
		<div
			*ngIf="chatMessageService.getFileType(replyMessage.content) === 'pdf'; else messageTypeNotSet"
			class="thumb d-flex justify-content-center"
		>
			<img *ngIf="file; else loading" [alt]="file.name" [attr.src]="file" />
			<div class="align-self-end d-flex position-absolute w-100 align-items-center message__footer">
				<div class="m-1 badge badge-light">
					{{ chatMessageService.getFileType(replyMessage.content) }}
				</div>
				<div class="text-truncate mr-1">{{ replyMessage.content }}</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #messageTypeNotSet>
	<div class="message-notset d-flex">
		<div class="d-flex align-items-center justify-content-center message-extension">
			<div class="m-1">{{ chatMessageService.getFileType(replyMessage.content) }}</div>
		</div>
		<div class="align-self-center">{{ replyMessage.content }}</div>
	</div>
</ng-template>

<ng-template #loading>
	<mat-spinner color="accent" [diameter]="26" class="align-self-center"></mat-spinner>
</ng-template>
