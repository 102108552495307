<div class="add-project-button z-index-2">
	<div *ngIf="createProjectOverlayOpen && !(openFolder$ | async)" [@shrinkOut] class="position-absolute second">
		<span class="fab-text text-white">{{ 'folder.title_create' | translate }}</span>
		<button (click)="openProjectFolderCreationDialog()" class="ml-3" data-cy="create-folder-btn" color mat-mini-fab>
			<mat-icon>folder</mat-icon>
		</button>
	</div>
	<div *ngIf="createProjectOverlayOpen" [@shrinkOut] class="position-absolute first">
		<span class="fab-text text-white">{{ 'project.title_create' | translate }}</span>
		<button (click)="openProjectCreationDialog()" color="primary" data-cy="create-project-btn" class="ml-3" mat-mini-fab>
			<mat-icon>add</mat-icon>
		</button>
	</div>
	<button
		*appCompanyMemberRole="[MemberRole.OWNER, MemberRole.SUPERVISOR]"
		(click)="toggleActionMenu()"
		class="ml-2"
		color="accent"
		id="actionButton"
		mat-fab
	>
		<mat-icon [@rotate]="createProjectOverlayOpen" id="actionButtonIcon" data-cy="create-project-folder">add</mat-icon>
	</button>
</div>
<app-dark-overlay (click)="onOverlayClick()" [visible]="createProjectOverlayOpen" [zIndex]="10000000"></app-dark-overlay>
