import {
	Component,
	ElementRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { isNil, noop } from 'lodash';
import { filter, map, startWith, switchMapTo, take } from 'rxjs/operators';
import { Member } from 'domain-entities';
import {
	MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
	MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import {
	companyMembersProfileLimitsLoadedSelector,
	selectEntitiesCompanyMembersProfileLimits,
} from '@store/selectors/profile-limits.selectors';
import { getMemberFullName, WINDOW } from '@craftnote/shared-utils';
import { BasicUserSubscriptionDialogComponent } from '@modules/features/dashboard/components/basic-user-subscription-dialog/basic-user-subscription-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SubscriptionService } from '@injectables/services/subscription/subscription.service';

@Component({
	selector: 'app-time-tracking-assignee-input',
	templateUrl: './time-tracking-assignee-input.component.html',
	styleUrls: ['./time-tracking-assignee-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TimeTrackingAssigneeInputComponent,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: TimeTrackingAssigneeInputComponent,
			multi: true,
		},
	],
})
export class TimeTrackingAssigneeInputComponent
	implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
	@ViewChild('assigneeInputRef') assigneeInputRef: ElementRef<HTMLInputElement>;
	@ViewChild('assigneeChipsListRef') assigneeChipsListRef: MatChipList;
	@ViewChild(MatAutocompleteTrigger) assigneeAutoTrigger: MatAutocompleteTrigger;
	@Input() fallBackAssignees: { [id: string]: { name: string } } = {};
	@Input() projectMembers: { [id: string]: Member } = {};
	@Input() showAssigneePaywall = true;
	filteredAssignees$: Observable<string[]>;
	// Assignee Ids
	assignees: string[] = [];
	assigneeInputControl = new UntypedFormControl('');
	destroy$ = new Subject();
	companyMembersIsBasic$: Observable<{ [id: string]: boolean }> = this.store
		.select(companyMembersProfileLimitsLoadedSelector)
		.pipe(
			filter(Boolean),
			switchMapTo(this.store.select(selectEntitiesCompanyMembersProfileLimits)),
			map((limits) => {
				const result = {};
				Object.keys(limits).forEach((userId) => {
					result[userId] = limits[userId]['taskTimeDashboard'] === false;
				});
				return result;
			}),
		);
	private projectMembers$ = new Subject<{ [id: string]: Member }>();
	private touched = false;

	constructor(
		private readonly store: Store<AppState>,
		@Inject(WINDOW) private readonly windowRef: Window,
		public readonly dialog: MatDialog,
		private readonly router: Router,
		private readonly subscriptionService: SubscriptionService,
	) {}

	private _required = false;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: boolean) {
		this._required = coerceBooleanProperty(value);
	}

	private _disabled = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
	}

	@Input()
	get value(): string[] | null {
		return this.assignees;
	}

	set value(assignees: string[] | null) {
		this.assignees = Array.isArray(assignees) ? assignees : [];
	}

	get isNotValid(): boolean {
		return this.required && this.assignees?.length === 0;
	}

	onChange = (_: any) => noop;

	onTouched = () => noop;

	ngOnInit(): void {
		this.filteredAssignees$ = combineLatest([
			this.assigneeInputControl.valueChanges.pipe(startWith(<string>'')),
			this.projectMembers$, // Waiting for the project members to arrive
		]).pipe(
			map(([assigneeName]) =>
				assigneeName
					? this.assigneeAutocompleteFilter(assigneeName)
					: Object.keys(this.projectMembers),
			),
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.projectMembers?.currentValue) {
			this.projectMembers$.next(changes.projectMembers?.currentValue);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	writeValue(assignees: string[] | null): void {
		this.value = assignees;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	markAsTouched(): void {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	removeAssigneeFromForm(assigneeId: string): void {
		const indexToRemove = this.assignees.indexOf(assigneeId);
		this.assignees.splice(indexToRemove, 1);
		this.onChange(this.assignees);
	}

	isAssigneeSelected(assignee: string): boolean {
		return this.assignees.indexOf(assignee) > -1;
	}

	assigneeSelected(event: MatAutocompleteSelectedEvent): void {
		this.windowRef.requestAnimationFrame(() => {
			if (this.assigneeAutoTrigger) {
				this.assigneeAutoTrigger.openPanel();
			}
		});

		const assigneeId = event.option.value;
		this.markAsTouched();
		if (this.isAssigneeSelected(assigneeId)) {
			this.removeAssigneeFromForm(assigneeId);
		} else {
			this.assignees.push(assigneeId);
		}
		this.assigneeInputControl.setValue('');
		this.assigneeInputRef.nativeElement.value = '';
		this.onChange(this.assignees);
	}

	validate(): ValidationErrors | null {
		if (this.assigneeChipsListRef) {
			this.assigneeChipsListRef['errorState'] = this.isNotValid;
		}
		if (this.isNotValid) {
			return {
				required: true,
			};
		}
		return null;
	}

	getAssigneeFullName(assigneeId: string): string {
		const memberFullName = getMemberFullName(
			this.projectMembers[assigneeId],
			this.fallBackAssignees ? this.fallBackAssignees[assigneeId]?.name : '',
		);
		return memberFullName ?? '';
	}

	async onClickAutocompleteAssignee(isBasic: boolean): Promise<void> {
		if (isBasic) {
			await this.showBasicSubscriptionDialog();
		}
	}

	private async routeToPaymentPage(): Promise<void> {
		const [, product] = await this.subscriptionService
			.getActiveSubscriptionAndProduct()
			.pipe(take(1))
			.toPromise();
		if (isNil(product) || product.grade === 0) {
			await this.router.navigate(['/settings/subscription/products']);
		} else {
			await this.router.navigate(['/settings/subscription']);
		}
	}

	private async showBasicSubscriptionDialog(): Promise<void> {
		this.assigneeAutoTrigger.closePanel();

		const doRedirection: boolean = await this.dialog
			.open(BasicUserSubscriptionDialogComponent, {
				data: {
					heading: 'dashboard.timeTracking.basic-user-subscription-dialog.heading',
					points: [
						'dashboard.timeTracking.basic-user-subscription-dialog.point-1',
						'dashboard.timeTracking.basic-user-subscription-dialog.point-2',
						'dashboard.timeTracking.basic-user-subscription-dialog.point-3',
						'dashboard.timeTracking.basic-user-subscription-dialog.point-4',
					],
					upgradeButtonText:
						'dashboard.timeTracking.basic-user-subscription-dialog.upgrade-btn-text',
				},
				maxWidth: '70vw',
				autoFocus: false,
			})
			.afterClosed()
			.toPromise();

		if (doRedirection) {
			await this.routeToPaymentPage();
		}
	}

	private assigneeAutocompleteFilter(value: string): string[] {
		const assigneeName = value.toLowerCase();
		return Object.values(this.projectMembers)
			.filter((member) => `${member.name} ${member.lastname}`.toLowerCase().includes(assigneeName))
			.map((member) => member.id);
	}
}
