import { FileType } from 'domain-entities';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, from, Observable, of } from 'rxjs';
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import { BaseFileService } from '@injectables/services/base-file.service';
import {
	catchError,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	share,
	startWith,
	switchMap,
	takeUntil,
} from 'rxjs/operators';
import { AppState } from '@store/state/app.state';
import { Action, Store } from '@ngrx/store';
import { ThumbnailService } from '@injectables/services/thumbnail/thumbnail.service';
import { environment } from '@env/environment';
import {
	clearPercentageAction,
	createProjectFileUpload,
	resumeUploadAction,
	setChatUploadPercentageAction,
	setProjectUploadPercentageAction,
	setUploadFailedAction,
} from '@store/actions/file-upload.actions';
import { THUMB } from '@shared/constants/thumbnail';
import { AAC, JPEG, JPG, MP3, MP4, PNG } from '@shared/constants/upload.types';
import { selectQueuedUploads, selectUploadState } from '@store/selectors/file-upload.selectors';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { isNil } from 'lodash';

const ALLOWED_LOGO_TYPES = ['jpg', 'jpeg', 'png'];

export interface CompanyTemplateUploadParameters extends FileUploadParameters {
	companyId: string;
	context: FileContext.COMPANY_TEMPLATES;
}

export interface TaskFileUploadParameters extends FileUploadParameters {
	projectId: string;
	context: FileContext.TASKS;
}

export interface ProjectFileUploadParameters extends FileUploadParameters {
	projectId: string;
	context: FileContext.PROJECT;
	folderId?: string;
}

export interface ChatFileUploadParameters extends FileUploadParameters {
	projectId: string;
	context: FileContext.CHAT;
}

interface FileUploadParameters {
	id: string;
	file: File; // The file to be uploaded
	fileName: string; // The desired out-facing name stored in the database
	context: FileContext;
	fromTemplate?: boolean; // Set to true if the file was created out of a template
}

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	private companyBucket: string;

	constructor(
		private readonly storage: AngularFireStorage,
		private readonly baseFileService: BaseFileService,
		private readonly thumbnailService: ThumbnailService,
		private readonly store: Store<AppState>,
		private readonly remoteConfig: RemoteConfig,
	) {
		this.companyBucket = environment.company_data_bucket;
		this.enforceSingleUploadQueue();
		void this.setRetryTimeFromConfig();
	}

	public async uploadFile(params: FileUploadParameters): Promise<void> {
		const uploadJob$ = this.uploadFileByType(params).pipe(share());
		uploadJob$.subscribe((percentage) => {
			let action: Action;
			if (params.context === FileContext.CHAT) {
				action = setChatUploadPercentageAction({ id: params.id, percentage });
			} else if (params.context === FileContext.PROJECT) {
				action = setProjectUploadPercentageAction({
					id: params.id,
					percentage,
				});
			}
			this.store.dispatch(action);
		});
		return uploadJob$
			.toPromise()
			.then((_) => this.store.dispatch(clearPercentageAction({ id: params.id })))
			.then();
	}

	private uploadFileByType(params: FileUploadParameters): Observable<number> {
		let thumbnail$: Promise<File> | undefined;
		const fileType = this.getFileType(params.file.name);
		switch (fileType) {
			case FileType.IMAGE:
				thumbnail$ = this.thumbnailService.createImageThumbnail(params.file);
				break;
			case FileType.VIDEO:
				thumbnail$ = this.thumbnailService.createVideoThumbnail(params.file);
				break;
			case FileType.DOCUMENT:
				thumbnail$ = this.thumbnailService.createDocumentThumbnail(params.file);
				break;
		}

		if (!thumbnail$) {
			return this.uploadFiles(params);
		}

		return from(thumbnail$).pipe(
			catchError(() => of(null)),
			switchMap((thumbnail) => {
				if (!thumbnail) {
					return this.uploadFiles(params);
				}
				return this.uploadFiles(params, thumbnail);
			}),
			startWith(0),
		);
	}

	private uploadFiles(params: FileUploadParameters, thumbnail?: File): Observable<number> {
		let uploadJobs: Observable<number>[] = [];
		const files: File[] = [params.file];
		uploadJobs.push(
			this.uploadFileToStorage(
				params.file,
				params,
				this.getFullFileName(params.file, params.fileName),
			),
		);

		if (thumbnail) {
			const filename = `${params.fileName}${THUMB}.${JPG}`;
			uploadJobs.push(this.uploadFileToStorage(thumbnail, params, filename));
			files.push(thumbnail);
		}
		let jobIndex = 0;
		uploadJobs = uploadJobs.map((job) => job.pipe(finalize(() => jobIndex++)));

		return concat(...uploadJobs).pipe(
			map((percentage) => this.calculateTotalPercentage(files, jobIndex, percentage)),
			startWith(0),
		);
	}

	private uploadFileToStorage(
		file: File,
		params: FileUploadParameters,
		fullName: string,
	): Observable<number> {
		const location = this.getLocationForFileContext(params);
		const fileRef = `${location}/${fullName}`;
		const task = this.storage.ref(fileRef).put(file, {
			contentDisposition: `inline; filename="${params.file.name}"`,
			customMetadata: { wasTemplate: String(!!params.fromTemplate) },
		});
		/**
		 * We are enforcing an upload queue for project files.
		 * To do this all file section uploads are initially paused and then resumed as soon as no other file is uploading.
		 */
		if (params.context === FileContext.PROJECT) {
			const projectFileUploadParams = params as ProjectFileUploadParameters;
			this.store.dispatch(
				createProjectFileUpload({
					id: projectFileUploadParams.id,
					fileName: projectFileUploadParams.file.name,
					folderId: projectFileUploadParams.folderId,
					projectId: projectFileUploadParams.projectId,
				}),
			);
			task.pause();
		}

		const completed$ = from(task.task.then());
		let cancelled = false;

		this.store
			.select(selectUploadState(params.id))
			.pipe(distinctUntilChanged(), filter(Boolean), takeUntil(completed$))
			.subscribe((status) => {
				if (status === 'cancellation-requested') {
					cancelled = true;
					this.store.dispatch(clearPercentageAction({ id: params.id }));
					task.cancel();
				} else if (status === 'ongoing') {
					task.resume();
				}
			});

		task.catch(() => {
			if (!cancelled) {
				this.store.dispatch(setUploadFailedAction({ id: params.id }));
			}
		});

		return task.percentageChanges().pipe(takeUntil(completed$));
	}

	private calculateTotalPercentage(
		files: File[],
		currentIndex: number,
		currentPercentage: number,
	): number {
		const totalBytes = files.map((file) => file.size).reduce((a, b) => a + b, 0);
		let completedBytes = files
			.slice(0, currentIndex)
			.map((file) => file.size)
			.reduce((a, b) => a + b, 0);
		completedBytes += files[currentIndex].size * (currentPercentage / 100);

		if (totalBytes === 0) {
			return 100;
		}
		return Math.floor((completedBytes / totalBytes) * 100);
	}

	public async uploadCompanyLogo(companyId: string, file: File): Promise<string> {
		const type = file.name
			.split('.')
			.map((part) => part.toLowerCase())
			.filter((value) => ALLOWED_LOGO_TYPES.includes(value))
			.reduce((_a, b) => b);
		if (!type) {
			throw Error('company has wrong or no type');
		}
		const id = uuid();
		const name = `${id}.${type}`;
		const path = `${companyId}/logo/${name}`;
		await this.storage.storage.app.storage(this.companyBucket).ref(path).put(file);
		return name;
	}

	getFullFileName(file: File, fileNameWithoutExtension: string): string {
		const type = file.name
			.split('.')
			.reduce((_a, b) => b, undefined)
			.toLowerCase();
		if (!type) {
			return fileNameWithoutExtension;
		}
		return `${fileNameWithoutExtension}.${type}`;
	}

	uploadPublicFile(file: File): { name: string; percentage$: Observable<number> } {
		const name = this.getFullFileName(file, uuid());
		const task = this.storage.storage.app
			.storage(environment.public_folders_bucket_name)
			.ref(name)
			.put(file);
		const percentage$ = new BehaviorSubject<number>(0);
		task.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) =>
			percentage$.next((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
		);

		task.then(() => percentage$.complete());
		return { name, percentage$ };
	}

	private getLocationForFileContext(params: FileUploadParameters): string {
		switch (params.context) {
			case FileContext.TASKS:
				return `${(params as TaskFileUploadParameters).projectId}/taskFiles`;

			case FileContext.PROJECT:
				return `${(params as ProjectFileUploadParameters).projectId}/files`;

			case FileContext.COMPANY_TEMPLATES:
				return `companyFiles/${(params as CompanyTemplateUploadParameters).companyId}`;

			case FileContext.CHAT:
				return `${(params as ProjectFileUploadParameters).projectId}`;
		}
	}

	getFileType(name: string): FileType {
		const fileType = this.baseFileService.getFileExtension(name);

		switch (fileType) {
			case JPG:
			case JPEG:
			case PNG:
				return FileType.IMAGE;
			case AAC:
			case MP3:
				return FileType.AUDIO;
			case MP4:
				return FileType.VIDEO;
			default:
				return FileType.DOCUMENT;
		}
	}

	private async setRetryTimeFromConfig(): Promise<void> {
		const value = this.remoteConfig.getValue('firebase_storage_retry_time');
		if (!isNil(value)) {
			this.storage.storage.setMaxUploadRetryTime(value);
		}
	}

	private enforceSingleUploadQueue(): void {
		this.store.select(selectQueuedUploads).subscribe((uploads) => {
			if (uploads.length === 0) {
				return;
			}
			const uploadOngoing = uploads.filter((upload) => upload.status === 'ongoing');
			if (uploadOngoing.length === 0) {
				this.store.dispatch(resumeUploadAction({ id: uploads[0].id }));
				return;
			}
		});
	}
}
