import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
	ProjectListFailedUploadNudgeItem,
	ProjectListStore,
} from '@modules/shared/components/projects-area/components/projects-list/store/project-list.store';
import { Injectable } from '@angular/core';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { getProjectHeight } from '@shared/functions/project/project.functions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProjectStatusEntities } from '@store/selectors/app.selectors';

@Injectable()
export class ProjectsListScrollStrategy implements VirtualScrollStrategy {
	scrolledIndexChange: Observable<number>;

	private viewport: CdkVirtualScrollViewport = null;
	private folderHintActive$$ = new BehaviorSubject(false);
	private mutedProjects$$ = new BehaviorSubject<{ [p: string]: boolean }>({});

	private items$$ = new BehaviorSubject<number[]>([]);
	private destroy$ = new Subject();

	private resized$ = new Subject<void>();
	private resizeObserver = new ResizeObserver(() => this.resized$.next());

	constructor(
		private readonly projectListStore: ProjectListStore,
		private readonly store: Store<AppState>,
	) {
		// The virtual scroller is sometimes not taking resizing into account properly leading to the project list looking half empty
		// We are manually observing resizing and ask the viewport to recheck its size
		this.resized$.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(() => {
			this.viewport.checkViewportSize();
			this.render();
		});
		combineLatest([
			this.projectListStore.projectListItems$,
			this.folderHintActive$$,
			this.store.select(selectProjectStatusEntities),
			this.mutedProjects$$,
		])
			.pipe(
				map(([items, folderHintActive, statusEntities, mutedProjects]) => {
					return items.map((item) => {
						if (item.type === 'project') {
							const muted = mutedProjects[item.project.id];
							return getProjectHeight(item.project, folderHintActive, muted, statusEntities);
						} else if (item.type === 'archivation-nudge') {
							return this.calculateHeightOfArchivationNudge();
						} else if (item.type === 'failed-upload-nudge') {
							return this.calculateHeightOfFailedUploadsNudge(item);
						} else {
							// Height of a group item
							return 22;
						}
					});
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((items) => this.items$$.next(items));
	}

	private render(): void {
		const heights = this.items$$.value;
		if (heights.length === 0) {
			return;
		}

		const heightOfViewPort = this.viewport.getViewportSize();
		const top = this.viewport.measureScrollOffset();

		let startIndex: number, endIndex: number, heightToStartElement: number;
		let sum = 0;

		for (let i = 0; i < heights.length; i++) {
			if (startIndex === undefined) {
				heightToStartElement = sum;
			}
			sum += heights[i];
			if (startIndex === undefined) {
				if (sum > top) {
					startIndex = i;
				}
			}
			if (sum > top + heightOfViewPort) {
				endIndex = i;
				break;
			}
		}

		if (endIndex === undefined) {
			endIndex = heights.length;
		}

		this.viewport.setRenderedRange({ start: startIndex, end: endIndex + 1 });
		this.viewport.setRenderedContentOffset(heightToStartElement);
	}

	attach(viewport: CdkVirtualScrollViewport): void {
		this.viewport = viewport;
		this.resizeObserver.disconnect();
		this.resizeObserver.observe(viewport.elementRef.nativeElement);

		this.items$$
			.pipe(
				map((items) => items.reduce((acc, item) => acc + item, 0)),
				takeUntil(this.destroy$),
			)
			.subscribe((height) => {
				this.viewport.setTotalContentSize(height);
				this.render();
			});
	}

	detach(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	onContentScrolled(): void {
		this.render();
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	scrollToIndex(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onContentRendered(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	updateRenderedRange(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onDataLengthChanged(): void {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onRenderedOffsetChanged(): void {}

	setShowParentHint(showFolderHint: boolean): void {
		this.folderHintActive$$.next(showFolderHint);
	}

	setMutedProjects(mutedProjects: { [p: string]: boolean }): void {
		this.mutedProjects$$.next(mutedProjects);
	}

	private calculateHeightOfArchivationNudge(): number {
		return 105;
	}

	private calculateHeightOfFailedUploadsNudge(item: ProjectListFailedUploadNudgeItem): number {
		/**
		 * A failed upload nudge has a minimum height of 105px, with the third project line 11 px are added and
		 * every subsequent line adds 18px
		 */
		let height = 105;
		if (item.projects.length > 2) {
			height += 11;
		}
		height += Math.max(item.projects.length - 3, 0) * 18;
		return height;
	}
}
