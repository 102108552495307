import { NgModule } from '@angular/core';
import { ConfirmButtonComponent } from './confirm-button.component';
import { ButtonLoaderModule } from '@craftnote/material-theme';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

const COMPONENT = [ConfirmButtonComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule, MatButtonModule, ButtonLoaderModule],
	exports: [COMPONENT],
})
export class ConfirmButtonModule {}
