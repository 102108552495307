<div class="w-100 flex-grow-1 d-flex justify-content-center align-items-center">
	<form #formDirective="ngForm" [formGroup]="formGroup">
		<div *ngFor="let slide of slides; let i = index" @blockInitialRenderAnimation>
			<div *ngIf="i === currentSlideIndex" @enterExitLeft class="d-flex flex-column align-items-center">
				<app-shared-slider-dialog-slide
					(next)="onNext(formDirective)"
					[control]="formGroup.controls[i]"
					[index]="i"
					[slide]="slide"
					[totalNumberOfSlides]="slides.length"
				>
				</app-shared-slider-dialog-slide>
			</div>
		</div>
	</form>
</div>
