<app-shared-slider
	(close)="close()"
	(complete)="onComplete($event)"
	(stepCompleted)="onStepCompleted($event)"
	*ngIf="data"
	[slides]="data"
	[sliderCanBeCanceled]="!(hideCancelCompanyInit$ | async)"
></app-shared-slider>

<ng-template #addEditCompanyEmployeeTemplate let-onNext>
	<app-add-bulk-company-employees
		(afterInvitationSubmit)="onRegistrationFlowNextButtonPressed(onNext, 'invited', true, $event)"
		(isFormValid)="isBulkInviteFormValid($event)"
	>
	</app-add-bulk-company-employees>

	<div class="mt-2">
		<button
			mat-button
			(click)="onRegistrationFlowNextButtonPressed(onNext, 'skipped', true, 0)"
			data-cy="register-invite-employee-skip-button"
		>
			{{ 'company-initialization.add-edit-company-employee.skip-btn-text' | translate }}
		</button>
	</div>
</ng-template>
