import { AppState } from '@store/state/app.state';
import { createSelector } from '@ngrx/store';
import { values } from 'lodash';
import { selectProducts } from '@store/selectors/products.selectors';
import { ProductType, Subscription, SubscriptionEmployeeType } from 'domain-entities';
import { selectUserId } from './app.selectors';
import { getProductForCurrentPlan } from '@modules/features/subscription/products/shared/shared.functions';

function filterSubscriptionEntities(
	entities: Subscription[],
	subscriptionProducts,
): Subscription[] {
	return entities.filter((subscription) =>
		subscriptionProducts.some((product) =>
			product.plans.some((plan) => plan.stripeId === subscription.currentState.planId),
		),
	);
}

export const selectSubscriptionState = (state: AppState) => state.subscriptionsState;

export const selectSubscriptionsLoadingStatus = createSelector(
	selectSubscriptionState,
	(state) => state.loadingStatus,
);

export const selectSubscriptionEntities = createSelector(selectSubscriptionState, (state) =>
	values<Subscription>(state.entities),
);

export const selectSubscriptionsOfTypeSubscription = createSelector(
	selectSubscriptionEntities,
	selectProducts,
	(entities, products) => {
		const subscriptionProducts = products.filter(
			(product) => product.type === ProductType.SUBSCRIPTION,
		);

		return filterSubscriptionEntities(entities, subscriptionProducts);
	},
);

export const selectCompanyHasSubscriptionOfTypeSubscription = createSelector(
	selectSubscriptionsOfTypeSubscription,
	(subscriptions) => {
		return subscriptions.length > 0;
	},
);

export const selectSubscriptionOfTypeTrial = createSelector(
	selectSubscriptionEntities,
	selectProducts,
	(entities, products) => {
		const subscriptionProducts = products.filter((product) => product.type === ProductType.TRIAL);

		return filterSubscriptionEntities(entities, subscriptionProducts);
	},
);

export const selectSubscriptionTypeCurrentUser = createSelector(
	selectUserId,
	selectSubscriptionOfTypeTrial,
	selectSubscriptionsOfTypeSubscription,
	(userId, trialSubscription, subscriptions) => {
		if (trialSubscription.length) {
			return SubscriptionEmployeeType.PAID;
		}

		if (!subscriptions.length) {
			return SubscriptionEmployeeType.FREE;
		}
		const currentEmployee = subscriptions[0].currentState.employees?.find(
			(employee) => employee.profileId === userId,
		);

		if (!currentEmployee) {
			return SubscriptionEmployeeType.FREE;
		}

		return currentEmployee.type;
	},
);

export const isPaidSubscriptionOfCurrentUser = createSelector(
	selectSubscriptionTypeCurrentUser,
	(subscriptionEmployeeType) => subscriptionEmployeeType === SubscriptionEmployeeType.PAID,
);

export const selectSubscriptionQuantity = createSelector(
	selectSubscriptionsOfTypeSubscription,
	(subscriptions) => {
		if (!subscriptions.length) {
			return 0;
		}
		return subscriptions[0].quantity;
	},
);

export const selectProductOfCurrentSubscription = createSelector(
	selectProducts,
	selectSubscriptionsOfTypeSubscription,
	(product, subscriptions) => {
		if (!subscriptions.length) {
			return null;
		}
		const subscriptionProduct = getProductForCurrentPlan(product, subscriptions[0]);
		if (subscriptionProduct.shortName === 'Pro') {
			return 'PRO';
		} else {
			return 'PREMIUM';
		}
	},
);
