<div
	#componentContainer
	*ngIf="isCompatibleWithCustomComponents; else compatibilityError"
	class="component-container justify-content-center"
>
	<div class="m-4 text-center align-self-center" *ngIf="showLoader">
		<mat-spinner [diameter]="26"></mat-spinner>
	</div>
</div>

<ng-template #compatibilityError>
	<app-browser-compatibility-error></app-browser-compatibility-error>
</ng-template>
