<div class="nudge-wrapper p-3 mat-background-accent-300 pointer d-flex">
	<div class="icon-wrapper position-relative flex-shrink-0">
		<div class="icon-wrapper_halo position-absolute"></div>
		<mat-icon class="flex-shrink-0 icon-wrapper_archive-icon position-absolute" fontSet="material-icons-outlined">{{ icon }}</mat-icon>
	</div>
	<div class="flex-shrink-1 flex-grow-1 min-width-0">
		<ng-content select=".project-nudge-content"></ng-content>
	</div>
	<mat-icon appEventStopPropagation class="flex-shrink-0" (click)="closeNudge.emit()">close</mat-icon>
</div>
<mat-divider></mat-divider>
