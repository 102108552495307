import { Serializable } from './serializable';
import { Permissions } from 'domain-entities';

export class FileDocument implements Serializable<FileDocument> {
	constructor(
		public id?: string,
		public name?: string,
		public creatorId?: string,
		public projectId?: string,
		public folderId?: string,
		public size?: string,
		public type?: FILE_TYPE,
		public creationTimestamp?: number,
		public lastModifiedTimestamp?: number,
		public meta?: {
			autoTags?: string[];
		},
		public permissions?: Permissions,
		public share?: {
			token: string;
			expirationTimestamp: number;
		},
	) {}

	deserialize(input: any): FileDocument {
		this.id = input.id;
		this.name = input.name;
		this.creatorId = input.creatorId;
		this.projectId = input.projectId;
		this.folderId = input.folderId;
		this.size = input.size;
		this.type = input.type;
		this.creationTimestamp = input.creationTimestamp;
		this.lastModifiedTimestamp = input.lastModifiedTimestamp;
		this.meta = input.meta;
		this.permissions = input.permissions;
		this.share = input.share;
		return this;
	}
}

export enum FILE_TYPE {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	AUDIO = 'AUDIO',
	DOCUMENT = 'DOCUMENT',
	FOLDER = 'FOLDER',
}
