import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ProjectFile } from '@shared/models/project-file.model';
import { FileType } from 'domain-entities';

@Component({
	selector: 'app-delete-project-files-confirmation-dialog',
	templateUrl: './delete-project-files-confirmation-dialog.component.html',
	styleUrls: ['./delete-project-files-confirmation-dialog.component.scss'],
})
export class DeleteProjectFilesConfirmationDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<DeleteProjectFilesConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			selectedFiles: { file: ProjectFile; fileCount: number; subFolderCount?: number }[];
		},
	) {}

	get folders() {
		return this.data.selectedFiles.filter(
			(selectedFile) => selectedFile.file.fileType === 'FOLDER',
		);
	}

	get files() {
		return this.data.selectedFiles
			.filter((selectedFile) => selectedFile.file.fileType !== FileType.FOLDER)
			.map((selectedFile) => ({
				...selectedFile,
				file: {
					...selectedFile.file,
					name: this.truncateFilename(selectedFile.file.name),
				},
			}));
	}

	truncateFilename(filename: string): string {
		if (filename.length > 50) {
			return `${filename.substring(0, 50)}...`;
		}
		return filename;
	}
}
