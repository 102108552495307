<div class="ch-dialog">
	<div class="text-right">
		<mat-icon class="pointer" data-cy="close-rename-dialog" mat-dialog-close>close</mat-icon>
	</div>
	<mat-dialog-content>
		<mat-form-field appearance="outline" class="w-100 form-field-outline" floatLabel="always">
			<mat-label>{{ 'files-section.dialog.re-name.label' | translate }}</mat-label>
			<input
				trim="blur"
				[(ngModel)]="name"
				matInput
				data-cy="rename-folder-input"
				placeholder="{{ 'files-section.dialog.re-name.label' | translate }}"
			/>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions class="justify-content-center">
		<button [disabled]="!name" [mat-dialog-close]="name" color="accent" data-cy="rename-folder-confirm" mat-flat-button>
			{{ 'files-section.dialog.re-name.confirm-btn' | translate }}
		</button>
	</mat-dialog-actions>
</div>
