import { NgModule } from '@angular/core';
import { ProjectIndicatorComponent } from './components/project-indicator/project-indicator.component';
import { ProjectStatusIndicatorComponent } from './components/project-status-indicator/project-status-indicator.component';
import { StrokeArrayPipe } from './pipes/stroke-array.pipe';
import { ProjectFolderHintComponent } from '@work/project/project-folder-hint/project-folder-hint.component';
import { MatIconSizeModule } from '../../shared/directives/mat-icon-size.module';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { IsNilPipeModule } from '../../shared/pipes/is-nil/is-nil-pipe.module';
import { ProjectAddressPipeModule } from '../../shared/pipes/project-address/project-address-pipe.module';

@NgModule({
	declarations: [
		ProjectFolderHintComponent,
		ProjectIndicatorComponent,
		ProjectStatusIndicatorComponent,
		StrokeArrayPipe,
	],
	imports: [
		MatIconSizeModule,
		SharedModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		IsNilPipeModule,
		ProjectAddressPipeModule,
	],
	exports: [ProjectIndicatorComponent, ProjectFolderHintComponent],
})
export class SharedProjectsModule {}
