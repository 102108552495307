import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, delay, filter, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	selectCompanyId,
	selectUserEmail,
	selectUserRegistrationType,
} from '../selectors/app.selectors';
import { AppState } from '../state/app.state';
import { setAppInitState } from '../actions/app-init.actions';
import { AppInitStatus } from '../state/app-init.state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TocComponent } from '@modules/shared/dialog/components/toc/toc.component';
import { TocService } from '@injectables/services/toc.service';
import { combineLatest, from, of, timer } from 'rxjs';
import { UserRegistrationType } from '../state/auth.state';
import { Company } from 'domain-entities';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { CompanyService } from '@injectables/services/company/company.service';
import { isOwner } from '@shared/functions/project/project.functions';
import moment from 'moment';
import { UserDeactivatedDialogComponent } from '@modules/shared/dialog/components/user-deactivated-dialog/user-deactivated-dialog.component';
import { AppInitService } from '../../app-init.service';
import { TocDeclinedDialogComponent } from '@modules/shared/dialog/components/toc-declined-dialog/toc-declined-dialog.component';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Injectable()
export class AppInitEffects {
	appInitTocInitEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setAppInitState),
				filter((action) => action.state === AppInitStatus.DONE),
				switchMapTo(
					this.store.select(selectCompanyId).pipe(
						filter<string>(Boolean),
						debounceTime(3000), // TOC should open after 3 seconds of successful Login
					),
				),
				switchMap((companyId) =>
					combineLatest([
						this.store.select(selectUserEmail),
						this.companyService.getCompanyById(companyId),
						this.store.select(selectUserRegistrationType),
					]).pipe(
						filter((args) => args.every(Boolean)),
						take(1),
					),
				),
				tap(async ([userEmail, company, registrationType]) => {
					const isCompanyOwner = isOwner(company.members[userEmail]);
					await this.handleCompanyToc(company, registrationType, isCompanyOwner);
					await this.invitationService.handleInvitationsByLink();
				}),
			),
		{ dispatch: false },
	);

	constructor(
		private readonly store: Store<AppState>,
		private readonly actions$: Actions,
		private readonly dialog: MatDialog,
		private readonly tocService: TocService,
		private readonly invitationService: InvitationService,
		private readonly companyService: CompanyService,
		private readonly appInitService: AppInitService,
		private readonly localStorageService: LocalStorageService,
	) {}

	private async handleCompanyToc(
		company: Company,
		registrationType: UserRegistrationType,
		isOwner: boolean,
	): Promise<void> {
		const [isTocEnabled, isBeyondDueDate] = await Promise.all([
			this.tocService.isFeatureEnabled(),
			this.tocService.isBeyondDueDate(),
		]);

		/**
		 * The toc dialog should open when the following criteria meats
		 * 1. User should be existing not the one who newly created (There is no way that new user is not accepted the toc)
		 * 2. Only for the Owners
		 * 3. The new TOC feature is enabled
		 * 4. The companies without any toc status
		 * 5. Show only before the due date
		 */
		if (
			!isTocEnabled ||
			company.toc?.status ||
			!isOwner ||
			isBeyondDueDate ||
			registrationType !== UserRegistrationType.EXISTING
		) {
			return;
		}

		await this.dialog
			.open(TocComponent, {
				autoFocus: false,
				disableClose: true,
				width: 'min(100%, 600px)',
				maxHeight: '100vh',
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();
	}

	private async showDeclinedPopup(): Promise<void> {
		const [isTocEnabled, isBeyondDeadline, tocStatus, isMobile, dialogDeclineDate] =
			await Promise.all([
				this.tocService.isFeatureEnabled(),
				this.tocService.isBeyondDueDate(),
				this.tocService.getCompanyStatus(),
				this.appInitService.getMobilePlatform().pipe(take(1)).toPromise(),
				this.localStorageService.get('firefoxTocDeclineLastVisit'),
			]);

		if (
			isTocEnabled &&
			!isBeyondDeadline &&
			tocStatus === 'declined' &&
			isMobile &&
			(!dialogDeclineDate || moment.unix(dialogDeclineDate).diff(moment(), 'days') >= 1)
		) {
			this.dialog.open(TocDeclinedDialogComponent, {
				autoFocus: false,
				disableClose: true,
				width: 'min(100%, 600px)',
			});
		}
	}

	appInitTocDeclineInitEffect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setAppInitState),
				filter((action) => action.state === AppInitStatus.DONE),
				tap(() => {
					void this.showDeclinedPopup();
				}),
			),
		{ dispatch: false },
	);

	appDeadlineTimeout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setAppInitState),
				filter((action) => action.state === AppInitStatus.DONE),
				switchMapTo(from(this.getTocDeadlineDifference())),
				switchMap((timeLeft) => {
					if (timeLeft) {
						// converting seconds into milliseconds for timer
						const dueTime = timeLeft * 1000;
						/**
						 * Open bug for the timer especially with the large values https://github.com/ReactiveX/rxjs/issues/3015
						 */
						return timer(0).pipe(delay(dueTime));
					} else {
						return of(null);
					}
				}),
				tap(async (time) => {
					const isCompanyAccepted = await this.tocService.isCompanyAccepted();
					if (time === null || isCompanyAccepted) {
						return;
					}

					this.dialog.open(UserDeactivatedDialogComponent, {
						autoFocus: false,
						disableClose: true,
						width: 'min(100%, 600px)',
					});
				}),
			),
		{ dispatch: false },
	);

	private async getTocDeadlineDifference(): Promise<number | null> {
		const [isTocEnabled, dueDate, isCompanyAccepted] = await Promise.all([
			this.tocService.isFeatureEnabled(),
			this.tocService.getDueDate(),
			this.tocService.isCompanyAccepted(),
		]);

		if (isCompanyAccepted || !isTocEnabled) {
			return null;
		}

		const now = moment().unix(),
			dueDateUnix = dueDate.unix();
		return now < dueDateUnix ? dueDateUnix - now : null;
	}
}
