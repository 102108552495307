import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { BrowserDetectionService } from '@injectables/services/browser-detection.service';
import { selectIndexDbCanBeMutated } from '@store/selectors/browser.selector';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

enum WarningBannerType {
	COMPATIBILITY,
	CONFIGURATION,
}

@Component({
	selector: 'app-browser-error-banner',
	templateUrl: './browser-error-banner.component.html',
	styleUrls: ['./browser-error-banner.component.scss'],
})
export class BrowserErrorBannerComponent implements OnInit, OnDestroy {
	WarningBannerType = WarningBannerType;

	@Output() active = new EventEmitter<boolean>();

	compatibilityActive = !this.browserDetectionService.isCompatibleWithCustomElements();
	immutableIndexDbActive$ = this.store.select(selectIndexDbCanBeMutated).pipe(
		filter((val) => val !== undefined),
		map((val) => !val),
	);

	active$: Observable<WarningBannerType> = null;

	private destroy$ = new Subject();

	constructor(
		private readonly store: Store<AppState>,
		private readonly browserDetectionService: BrowserDetectionService,
	) {
		this.initWarningType();
	}

	ngOnInit(): void {
		this.initActive();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	initWarningType(): void {
		// Compatibility warning takes highest precedence
		if (this.compatibilityActive) {
			this.active$ = of(WarningBannerType.COMPATIBILITY);
			return;
		}
		/// Configuration problem takes second highest priority
		this.active$ = this.immutableIndexDbActive$.pipe(
			map((active) => (active ? WarningBannerType.CONFIGURATION : null)),
		);
	}

	initActive(): void {
		this.active$.pipe(takeUntil(this.destroy$)).subscribe((type) => {
			this.active.emit(type !== null);
		});
	}
}
