import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as projectUnreadsActions from '@store/actions/project-unreads.actions';
import { setKeepCurrentProjectUnread } from '@store/actions/project-unreads.actions';
import { ProjectUnreadsService } from '@injectables/services/project-unreads.service';
import {
	selectKeepActiveProjectUnread,
	selectProjectUnreadByProjectId,
} from '@store/selectors/project-unreads.selectors';
import { firstValueFrom, of } from 'rxjs';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { selectProjectById } from '@store/selectors/projects.selectors';

@Injectable()
export class ProjectUnreadsEffects implements OnInitEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly projectUnreadsService: ProjectUnreadsService,
	) {}

	loadProjectUnreads$ = createEffect(() =>
		this.actions$.pipe(
			ofType(projectUnreadsActions.watchProjectUnreads),
			switchMap((_) => this.projectUnreadsService.watchProjectUnreads()),
			map((items) => projectUnreadsActions.updateProjectUnreads({ projectUnreads: items })),
		),
	);

	removeProjectUnread$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(projectUnreadsActions.removeProjectUnread),
				map(({ project }) => project),
				filter(Boolean),
				tap((project) => this.projectUnreadsService.removeProjectUnread(project)),
			),
		{ dispatch: false },
	);

	listenProjectUnreadFromSelectedProject$ = createEffect(
		() =>
			this.store.select(selectActiveProject).pipe(
				switchMap((project) => this.store.select(selectProjectById(project))),
				switchMap((project) => {
					if (!project) {
						return of(null);
					}
					return this.store.select(selectProjectUnreadByProjectId(project.id)).pipe(
						filter(Boolean),
						tap(async () => {
							if (!(await firstValueFrom(this.store.select(selectKeepActiveProjectUnread)))) {
								void this.projectUnreadsService.removeProjectUnread(project);
							}
						}),
					);
				}),
			),
		{ dispatch: false },
	);

	setProjectUnread$ = createEffect(() =>
		this.actions$.pipe(
			ofType(projectUnreadsActions.setProjectAsUnread),
			tap(({ project }) => this.projectUnreadsService.markProjectAsUnread(project)),
			switchMap(({ project }) =>
				this.store.select(selectActiveProject).pipe(
					take(1),
					map((activeProject) => activeProject === project.id),
				),
			),
			filter(Boolean),
			map(() => setKeepCurrentProjectUnread()),
		),
	);

	clearKeepCurrentProjectUnread$ = createEffect(() =>
		this.store.select(selectActiveProject).pipe(
			distinctUntilChanged(),
			map(() => projectUnreadsActions.clearKeepCurrentProjectUnread()),
		),
	);

	ngrxOnInitEffects(): Action {
		return projectUnreadsActions.watchProjectUnreads();
	}
}
