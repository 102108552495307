import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Project } from 'domain-entities';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProjectType } from '@shared/models/project.type';

@Component({
	selector: 'app-project-notes-modal',
	templateUrl: './project-notes-dialog.component.html',
	styleUrls: ['./project-notes-dialog.component.scss'],
})
export class ProjectNotesDialogComponent implements OnInit {
	addProjectNotesForm: UntypedFormGroup = this.fb.group({
		noteContent: [''],
	});
	project: Project = this.data.project;
	projectType = ProjectType;
	initialNoteContent = '';

	constructor(
		private readonly fb: UntypedFormBuilder,
		public readonly dialogRef: MatDialogRef<ProjectNotesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public readonly data: { project: Project; isEmployee: boolean },
		private readonly confirmationService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {}

	ngOnInit(): void {
		this.initialNoteContent = this.project.noteContent || '';
		this.addProjectNotesForm.setValue({
			noteContent: this.initialNoteContent,
		});
		if (!this.data.isEmployee) {
			this.addProjectNotesForm.controls['noteContent'].setValidators([Validators.required]);
		}
	}

	get noteContent(): string {
		return this.addProjectNotesForm.value && this.addProjectNotesForm.value.noteContent;
	}

	async closeModal(): Promise<void> {
		if (this.noteContent !== this.initialNoteContent) {
			const ref = this.confirmationService.open({
				primaryButtonText: this.translateService.instant('confirmation-ui.discard.primary-default'),
				primaryButtonColor: 'warn',
				secondaryButtonText: this.translateService.instant(
					'confirmation-ui.discard.secondary-default',
				),
				title: this.translateService.instant('confirmation-ui.discard.title'),
				message: this.translateService.instant('confirmation-ui.discard.message'),
			});
			if ((await ref.afterClosed().pipe(take(1)).toPromise()) === false) {
				return;
			}
		}
		this.dialogRef.close(null);
	}

	onSubmitForm(noteContent: string): void {
		this.dialogRef.close(noteContent);
	}
}
