import { NgModule } from '@angular/core';
import { ShowMoreItemsDirective } from './show-more-items.directive';

const DIRECTIVE = [ShowMoreItemsDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class ShowMoreItemsModule {}
