<cdk-virtual-scroll-viewport class="h-100 w-100">
	<ng-container *cdkVirtualFor="let item of groupedProjects$ | async" [ngSwitch]="item.type" class="w-100">
		<ng-container
			*ngSwitchCase="'failed-upload-nudge'"
			[ngTemplateOutlet]="uploadFailedNudge"
			[ngTemplateOutletContext]="{ $implicit: item.projects }"
		></ng-container>
		<ng-container *ngSwitchCase="'archivation-nudge'" [ngTemplateOutlet]="projectArchivationNudge"></ng-container>
		<ng-container *ngSwitchCase="'group'" [ngTemplateOutlet]="group" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
		<ng-container
			*ngSwitchCase="'project'"
			[ngTemplateOutlet]="project"
			[ngTemplateOutletContext]="{ $implicit: item.project, isLastItemInList: item.isLastItemInList }"
		></ng-container>
	</ng-container>
	<div class="floating-plus-button-space"></div>
</cdk-virtual-scroll-viewport>

<ng-template #project let-project let-isLastItem="isLastItemInList">
	<app-project-item-wrapper
		(click)="onProjectClick(project)"
		(menuOpened)="onMenuOpen($event)"
		[projectId]="project.id"
		[selected]="project.id === (selectedProjectId$ | async)"
		[showParentHint]="showParentHint"
		[context]="context"
		[lastOpenedTimestamp]="getLastEditedTimestampOfProject(project.id) | async"
		[isLastItem]="isLastItem"
		[isChatMessagesMuted]="(isChatMessagesMuted$ | async)[project.id]"
		[pauseProject$]="menuOpened$$"
	></app-project-item-wrapper>
</ng-template>

<ng-template #group let-item>
	<div
		(click)="toggleCollapse(item.groupId)"
		class="d-flex justify-content-between align-items-center px-2 pointer card mat-background-grey-400"
	>
		<h1 class="m-0 text-truncate">{{ item.groupText }}</h1>
		<mat-icon>{{ item.collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
	</div>
</ng-template>

<ng-template #projectArchivationNudge>
	<app-project-archivation-nudge></app-project-archivation-nudge>
</ng-template>

<ng-template #uploadFailedNudge let-projects>
	<app-failed-uploads-nudge [projectsWithFailedUploads]="projects"></app-failed-uploads-nudge>
</ng-template>
