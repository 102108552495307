import { Directive, HostListener } from '@angular/core';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: "a[href='']:not([target])",
})
export class PreventDefaultDirective {
	@HostListener('click', ['$event']) onClick(event: Event): void {
		event.preventDefault();
	}
}
