import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ProjectMemberDrawerSecondaryViewOptions } from '@work/project-members/components/project-member-drawer/project-member-drawer.component';
import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectActiveProject } from '@store/selectors/route.selectors';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	first,
	map,
	pluck,
	skip,
	startWith,
	switchMap,
	switchMapTo,
	take,
} from 'rxjs/operators';
import { selectChildrenOfActiveFolder, selectProject } from '@store/selectors/projects.selectors';
import {
	isOwnerOfCompany,
	selectCompany,
	selectCompanyMembers,
	selectUserId,
} from '@store/selectors/app.selectors';
import { ProjectMemberService } from '@injectables/services/project-member/project-member.service';
import {
	selectInvitations,
	selectInvitationsLoadingState,
} from '@store/selectors/invitations.selectors';
import { subscribeToInvitationsChanges } from '@store/actions/invitations.actions';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { mapToInternalMember } from '@work/project-members/functions/project-member.functions';
import { Member, Project, ProjectType } from 'domain-entities';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import {
	InternalMember,
	InternalMemberInviteStatus,
} from '@work/project-members/entities/project-member';
import { orderBy } from 'lodash';
import { InvitationDialogService } from '@injectables/services/invitation-dialog.service';
import { shareReplayOne } from '@craftnote/shared-utils';
import { selectMemberProjectInvitationsRemaining } from '@store/selectors/profile-limits.selectors';
import { TranslateService } from '@ngx-translate/core';
import { NoInvitationsRemainingService } from '@injectables/services/no-invitations-remaining.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
	selector: 'app-project-internal-member',
	templateUrl: './project-internal-member.component.html',
	styleUrls: ['./project-internal-member.component.scss'],
})
export class ProjectInternalMemberComponent implements AfterViewInit {
	private emitProjectMembersSelectionChange$$ = new Subject();
	@Output() openView = new EventEmitter<ProjectMemberDrawerSecondaryViewOptions>();
	@ViewChild('projectMembersSelectionList') projectMembersSelectionList: MatSelectionList;
	projectMembersSelectionList$$: Subject<MatSelectionList> = new Subject();
	private projectMembersSelectionListSource$: Observable<MatSelectionList> =
		this.projectMembersSelectionList$$.pipe(
			switchMap((projectMembersSelectionList) =>
				combineLatest([
					projectMembersSelectionList?.selectionChange.pipe(startWith(undefined)),
					this.emitProjectMembersSelectionChange$$.pipe(startWith(undefined)),
				]).pipe(
					skip(1),
					map((_) => projectMembersSelectionList),
				),
			),
			shareReplayOne(),
		);
	ProjectType = ProjectType;
	memberSearchForm = this.fb.group({
		text: [''],
	});
	folderAndChildren = true;
	destroy$ = new Subject();
	currentLoggedInUserId$ = this.store.select(selectUserId);
	currentProject$: Observable<Project> = this.store.select(selectActiveProject).pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
		shareReplayOne(),
	);
	companyName$ = this.store.select(selectCompany).pipe(pluck('name'));
	companyMembers$ = this.store.select(selectCompanyMembers);
	isInvitationsLoading$ = this.store
		.select(selectInvitationsLoadingState)
		.pipe(map((state) => state !== StoreLoadingStatus.LOADED));
	invitations$ = this.store.select(selectInvitationsLoadingState).pipe(
		filter((state) => state === StoreLoadingStatus.LOADED),
		switchMapTo(this.store.select(selectInvitations)),
	);
	projectMembers$ = this.currentProject$.pipe(
		map((project) => Object.values(project?.members || {})),
	);
	mappedProjects$ = combineLatest([
		this.projectMembers$,
		this.companyMembers$,
		this.invitations$,
		this.currentLoggedInUserId$,
	]).pipe(
		map(([projectMembers, companyMembersMap, invitations, userId]) => {
			const companyMembers = this.projectMemberService.getOrderByName(
				Object.values(companyMembersMap || {}),
			);

			// Filter out members that are already in the project and yourself
			const existingMemberIds = new Set(projectMembers.map((m) => m.id));
			const availableMembers = companyMembers.filter(
				(member) => !existingMemberIds.has(member.id) && member.id !== userId,
			);

			return orderBy(
				availableMembers.map((member) =>
					mapToInternalMember(member, invitations, projectMembers, userId),
				),
				['invitedStatus'],
				['asc'],
			);
		}),
	);
	filteredProjectMembers$: Observable<InternalMember[]> = combineLatest([
		this.mappedProjects$,
		this.memberSearchForm.valueChanges.pipe(
			debounceTime(100),
			distinctUntilChanged(),
			startWith(0),
		),
	]).pipe(
		map(([updatedMembers, searchFormValue]) => {
			if (!searchFormValue?.text) {
				return updatedMembers;
			}

			return updatedMembers.filter((member) =>
				this.projectMemberService.searchFilterCondition(member.entity, searchFormValue?.text),
			);
		}),
		shareReplayOne(),
	);
	isEmptyContainer$ = combineLatest([
		this.filteredProjectMembers$,
		this.currentLoggedInUserId$,
	]).pipe(
		map(([projectMembers, userId]) => {
			return (
				!projectMembers?.length ||
				(projectMembers.length <= 1 && userId === projectMembers[0]?.entity?.id)
			);
		}),
	);
	isOwner$ = this.store.select(isOwnerOfCompany);
	isLoading = false;
	selectedMembers$: Observable<Member[]> = this.projectMembersSelectionListSource$.pipe(
		map((source) => source.selectedOptions.selected.map((option) => option.value)),
	);
	isAllProjectMembersChecked$ = this.projectMembersSelectionListSource$.pipe(
		map(
			(source) =>
				source.selectedOptions.selected.length === source.options.length && !!source.options.length,
		),
	);

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly store: Store<AppState>,
		private readonly projectMemberService: ProjectMemberService,
		private readonly invitationService: InvitationService,
		private readonly invitationDialogService: InvitationDialogService,
		private readonly noInvitationsRemainingService: NoInvitationsRemainingService,
		private readonly translateService: TranslateService,
	) {
		this.store.dispatch(subscribeToInvitationsChanges());
	}

	get searchText(): string {
		return this.memberSearchForm.value?.text;
	}

	selectIsMemberHasProjectInvitationsRemaining$ = (member: InternalMember) =>
		combineLatest([
			this.store.select(selectMemberProjectInvitationsRemaining(member.entity.id)),
			this.currentProject$,
			this.currentProject$.pipe(
				switchMap((project) => this.store.select(selectChildrenOfActiveFolder(project.id))),
			),
		]).pipe(
			map(([memberProjectInvitationsRemaining, currentProject, childrenOfActiveFolder]) => {
				if (!this.folderAndChildren && currentProject.projectType === ProjectType.FOLDER) {
					return true;
				}
				let remainingInvitationsRequired = 1;
				/**
				 * When inviting a member to all childen of a folder then the required amount of invitations
				 * is equal to the number of children minus the number of children the member is alread invited into
				 */
				if (currentProject.projectType === ProjectType.FOLDER && childrenOfActiveFolder) {
					remainingInvitationsRequired = childrenOfActiveFolder.filter(
						(project) => !project.membersActive.includes(member.entity.id),
					).length;
				}
				return (
					memberProjectInvitationsRemaining >= remainingInvitationsRequired ||
					member.invitedStatus !== InternalMemberInviteStatus.ADD
				);
			}),
		);

	ngAfterViewInit(): void {
		this.projectMembersSelectionList$$.next(this.projectMembersSelectionList);
	}

	async toggleAllProjectMembersSelection({ checked }): Promise<void> {
		if (checked) {
			this.projectMembersSelectionList?.selectAll();
		} else {
			this.projectMembersSelectionList?.deselectAll();
		}
		this.emitProjectMembersSelectionChange$$.next(null);
	}

	onMemberSelectionChangeCompareWith(memberA, memberB): boolean {
		return memberA.id === memberB.id;
	}

	async addProjectMembers(): Promise<void> {
		const selectedMembers = await firstValueFrom(this.selectedMembers$);
		const project = await firstValueFrom(this.currentProject$);

		for (const member of selectedMembers) {
			await this.projectMemberService.addMember(
				member,
				project,
				this.showLoader,
				this.folderAndChildren ? 'folderAndChildren' : 'folderOnly',
			);
		}
		this.hideLoader();
		await this.toggleAllProjectMembersSelection({ checked: false });

		// Navigate back to project list view after all members are added
		this.openProjectListView();
	}

	openMemberDetails(event: MouseEvent, _member: Member): void {
		if ((event.target as HTMLElement).className.includes('checkbox')) {
			return;
		}
		event.stopPropagation();
		// Emit member details event if needed
	}

	async removeMember(member: Member): Promise<void> {
		const project = await this.currentProject$.pipe(take(1)).toPromise();

		if (!project) {
			return;
		}

		await this.projectMemberService.removeMember(
			member,
			project.id,
			this.showLoader,
			this.folderAndChildren ? 'folderAndChildren' : 'folderOnly',
		);
		this.hideLoader();
	}

	async addMember(member: Member): Promise<void> {
		const project = await this.currentProject$.pipe(first()).toPromise();
		await this.projectMemberService.addMember(
			member,
			project,
			this.showLoader,
			this.folderAndChildren ? 'folderAndChildren' : 'folderOnly',
		);
		this.hideLoader();
	}

	async remindInvitation(invitationId: string): Promise<void> {
		const result = await this.invitationDialogService.handleRemindInvitationDialog();

		if (!result) {
			return;
		}

		await this.invitationService.handleRemindInvitation(
			invitationId,
			this.showLoader,
			'project-members',
		);
		this.hideLoader();
	}

	async resendInvitation(member: Member): Promise<void> {
		const result = await this.invitationDialogService.handleResendInvitationDialog();

		if (!result) {
			return;
		}
		await this.invitationService.handleResendInvitation(member, this.showLoader);
		this.hideLoader();
	}

	onResetChatSearchForm(): void {
		this.memberSearchForm.reset();
	}

	openProjectListView(): void {
		this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.INITIAL);
	}

	async openNoProjectInvitationsRemainingPaywallDialog(): Promise<void> {
		const isOwner = await firstValueFrom(this.isOwner$);
		this.noInvitationsRemainingService.openNoProjectInvitationsRemainingPaywallDialog(
			'project-members',
			{
				title: this.translateService.instant('project-internal-member.no-project-remaining.title'),
				message: isOwner
					? this.translateService.instant(
							'project-internal-member.no-project-remaining.owner.message',
					  )
					: this.translateService.instant(
							'project-internal-member.no-project-remaining.supervisor.message',
					  ),
				messageList: [
					this.translateService.instant('project-internal-member.no-project-remaining.message-1'),
					this.translateService.instant('project-internal-member.no-project-remaining.message-2'),
					this.translateService.instant('project-internal-member.no-project-remaining.message-3'),
				],
			},
		);
	}

	async onMemberClick(event: MouseEvent, _member: InternalMember): Promise<void> {
		// Check if click was on a disabled checkbox
		const target = event.target as HTMLElement;
		if (target.closest('.mat-list-option')?.classList.contains('mat-list-option-disabled')) {
			event.preventDefault();
			event.stopPropagation();
			await this.openNoProjectInvitationsRemainingPaywallDialog();
		}
	}

	private showLoader = () => {
		this.isLoading = true;
	};

	private hideLoader = () => {
		this.isLoading = false;
	};
}
