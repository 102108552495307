import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-company-employees-role-dialog',
	templateUrl: './company-employees-role-dialog.component.html',
	styleUrls: ['./company-employees-role-dialog.component.scss'],
})
export class CompanyEmployeesRoleDialogComponent {
	constructor(private readonly translateService: TranslateService) {}

	rolesDescription = [
		{
			heading: {
				text: this.translateService.instant('global_roles_employee'),
				color: '#065F46',
				backgroundColor: '#D1FAE5',
			},
			rights: [
				this.translateService.instant('company-employees-role-dialog.employee-right-1'),
				this.translateService.instant('company-employees-role-dialog.employee-right-2'),
				this.translateService.instant('company-employees-role-dialog.employee-right-3'),
				this.translateService.instant('company-employees-role-dialog.employee-right-4'),
			],
		},
		{
			heading: {
				text: this.translateService.instant('global_roles_supervisor'),
				color: '#991B1B',
				backgroundColor: '#FEE2E2',
			},
			rights: [
				this.translateService.instant('company-employees-role-dialog.supervisor-right-1'),
				this.translateService.instant('company-employees-role-dialog.supervisor-right-2'),
				this.translateService.instant('company-employees-role-dialog.supervisor-right-3'),
				this.translateService.instant('company-employees-role-dialog.supervisor-right-4'),
			],
		},
		{
			heading: {
				text: this.translateService.instant('global_roles_owner'),
				color: '#3730A3',
				backgroundColor: '#E0E7FF',
			},
			rights: [
				this.translateService.instant('company-employees-role-dialog.owner-right-1'),
				this.translateService.instant('company-employees-role-dialog.owner-right-2'),
				this.translateService.instant('company-employees-role-dialog.owner-right-3'),
				this.translateService.instant('company-employees-role-dialog.owner-right-4'),
				this.translateService.instant('company-employees-role-dialog.owner-right-5'),
				this.translateService.instant('company-employees-role-dialog.owner-right-6'),
				this.translateService.instant('company-employees-role-dialog.owner-right-7'),
			],
		},
	];
}
