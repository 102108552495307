import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { PaywallHeaderDialogService } from '@injectables/services/paywall-header-dialog/paywall-header-dialog.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { currentUserProfileLimitsSelector } from '../selectors/profile-limits.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { RouterNavigatedAction } from '@ngrx/router-store/src/actions';
import { AppInitService } from '../../app-init.service';

@Injectable()
export class PaywallHeaderDialogEffects {
	constructor(
		private readonly actions: Actions,
		private readonly remoteConfig: RemoteConfig,
		private readonly paywallHeaderDialogService: PaywallHeaderDialogService,
		private readonly appInitService: AppInitService,
		private readonly store: Store<AppState>,
	) {}

	private isCurrentUserBasic$ = this.store
		.select(currentUserProfileLimitsSelector)
		.pipe(
			map(
				(currentUserProfileLimits) => currentUserProfileLimits.limits?.taskTimeDashboard === false,
			),
		);

	private get isPaywallHeaderEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_paywall_header');
	}

	listenRouteFragmentChange = createEffect(
		() =>
			this.actions.pipe(
				ofType(ROUTER_NAVIGATED),
				filter(
					(action: RouterNavigatedAction) =>
						action.payload.routerState.root.fragment === 'explore-pro' &&
						this.isPaywallHeaderEnabled,
				),
				tap(async (_) => {
					const isCurrentUserBasic = await this.isCurrentUserBasic$.pipe(take(1)).toPromise();
					const isMobile = await this.appInitService.getMobilePlatform().pipe(take(1)).toPromise();
					if (isCurrentUserBasic && !isMobile) {
						await this.paywallHeaderDialogService.show();
					}
				}),
			),
		{ dispatch: false },
	);
}
