import { NgModule } from '@angular/core';
import { BrowserCompatibilityErrorComponent } from './browser-compatibility-error.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@craftnote/shared-angular-modules';

const COMPONENT = [BrowserCompatibilityErrorComponent];

@NgModule({
	declarations: [COMPONENT],
	imports: [SharedModule, MatIconModule],
	exports: [COMPONENT],
})
export class BrowserCompatibilityErrorModule {}
