import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

const SUBTLE_ICON_CLASS = 'mat-icon-subtle';
@Directive({
	selector: '[mtMatIconDisabled]',
})
export class MatIconDisabledDirective {
	@Input('mtMatIconDisabled') disabled = true;
	@HostBinding('class.' + SUBTLE_ICON_CLASS) subtleClass = false;

	constructor(private readonly elementRef: ElementRef, private readonly renderer2: Renderer2) {}

	ngOnChanges(): void {
		this.subtleClass = this.disabled;

		const gTag = this.elementRef.nativeElement.querySelector('g');
		const pathTags = this.elementRef.nativeElement.querySelectorAll('path');

		if (gTag) {
			if (this.disabled) {
				this.renderer2.addClass(gTag, SUBTLE_ICON_CLASS);
			} else {
				this.renderer2.removeClass(gTag, SUBTLE_ICON_CLASS);
			}
		}
		for (const pathTag of pathTags) {
			if (this.disabled) {
				this.renderer2.addClass(pathTag, SUBTLE_ICON_CLASS);
			} else {
				this.renderer2.removeClass(pathTag, SUBTLE_ICON_CLASS);
			}
		}
	}
}
