import { Component } from '@angular/core';
import { AppTheme } from '@store/reducers/app.reducer';
import { ThemeService } from '@injectables/services/theme.service';
import { Observable } from 'rxjs';
import { selectTheme } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';

@Component({
	selector: 'app-theme-toggler',
	templateUrl: './theme-toggler.component.html',
	styleUrls: ['./theme-toggler.component.scss'],
})
export class ThemeTogglerComponent {
	theme$: Observable<AppTheme> = this.store.select(selectTheme);
	Theme = AppTheme;

	constructor(
		private readonly themeService: ThemeService,
		private readonly store: Store<AppState>,
	) {}

	setSelected(position: AppTheme): void {
		this.themeService.setTheme(position);
	}
}
