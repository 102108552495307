import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-shared-slider-input-toggle-option',
	templateUrl: './shared-slider-input-toggle-option.component.html',
	styleUrls: ['./shared-slider-input-toggle-option.component.scss'],
})
export class SharedSliderInputToggleOptionComponent {
	@Input() source: string;
	@Input() caption: string;
	@Input('selected') selectionState: boolean;

	@Output() selected = new EventEmitter<void>();

	onChange(): void {
		this.selected.emit();
	}
}
