<div class="toc-decline-dialog mat-typography">
	<div class="mb-3">
		<span class="craftnote-label mat-body-2 dark bold invert-color">
			{{ 'toc.dialog.label' | translate }}
		</span>
	</div>
	<h1 mat-dialog-title class="mat-display-1">
		{{ 'toc.declineDialog.title' | translate: { deadline: deadline$ | async } }}
	</h1>
	<p
		class="my-4 text-muted"
		[innerHTML]="'toc.declineDialog.body' | translate: { tocLink: linkService.tocLink, deadline: deadline$ | async }"
	></p>
	<div mat-dialog-actions class="justify-content-end flex-column">
		<button mat-flat-button color="accent" (click)="acceptToc()" class="w-100 mb-2">
			{{ 'toc.declineDialog.accept' | translate }}
		</button>
		<button mat-stroked-button (click)="closeDialog()" class="w-100 ml-0">
			{{ 'toc.declineDialog.confirm' | translate }}
		</button>
	</div>
</div>
