import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import {
	selectCompany,
	selectCompanyLoadingStatus,
	selectProfileLoadingStatus,
	selectUserRole,
} from '@store/selectors/app.selectors';
import { catchError, filter, first, map, take, timeout } from 'rxjs/operators';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import {
	selectActiveProjectLoadingState,
	selectAllActiveProjects,
} from '@store/selectors/projects.selectors';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { EXAMPLE_PROJECT_NAMES } from '@injectables/services/project/project.service';
import { BrowserService } from '@injectables/services/browser.service';
import { BrowserDetectionService } from '@injectables/services/browser-detection.service';
import moment from 'moment';
import { TocService } from '@injectables/services/toc.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { MemberRole } from 'domain-entities';

@Injectable()
export class AppInitStepperService {
	constructor(
		private readonly store: Store<AppState>,
		private readonly remoteConfig: RemoteConfig,
		private readonly invitationService: InvitationService,
		private readonly browserService: BrowserService,
		private readonly browserDetectionService: BrowserDetectionService,
		private readonly localStorageService: LocalStorageService,
		private readonly tocService: TocService,
	) {}

	async isProfileAndCompanyLoaded(): Promise<void> {
		await firstValueFrom(
			combineLatest([
				this.store.select(selectProfileLoadingStatus),
				this.store.select(selectCompanyLoadingStatus),
			]).pipe(
				map((loadingStates) => {
					return loadingStates.every((status) => status === StoreLoadingStatus.LOADED);
				}),
				filter(Boolean),
			),
		);
	}

	async isProjectsLoaded(): Promise<void> {
		await combineLatest([this.store.select(selectActiveProjectLoadingState)])
			.pipe(
				filter((statuses) => statuses.every((status) => status === StoreLoadingStatus.LOADED)),
				take(1),
			)
			.toPromise();
	}

	async getCompanyIdOfAcceptedInvitation(): Promise<string | undefined> {
		const isCompanyInvitationsEnabled = !!(await this.remoteConfig.getValueAsync(
			'feature_company_invitation',
		));

		if (!isCompanyInvitationsEnabled) {
			return;
		}

		return this.invitationService.acceptAndGetNewCompanyId();
	}

	async isExampleProjectLoaded(): Promise<void> {
		await this.store
			.select(selectAllActiveProjects)
			.pipe(
				filter(
					(activeProjects) =>
						!!activeProjects.find((project) => project.name === EXAMPLE_PROJECT_NAMES[0]),
				),
				timeout(20000),
				catchError((_) => of(true)),
				first(),
			)
			.toPromise();
	}

	async isCompanyInitShouldEnabled(newCompanyId: string | undefined): Promise<boolean> {
		if (!(await this.remoteConfig.getValueAsync('feature_company_init'))) {
			return false;
		}

		const company = await firstValueFrom(
			this.store
				.select(selectCompany)
				.pipe(filter((innerCompany) => !newCompanyId || innerCompany.id === newCompanyId)),
		);

		const userRole = await firstValueFrom(this.store.select(selectUserRole));

		if (userRole !== MemberRole.OWNER) {
			return false;
		}

		/**
		 * Company Trade and company Size are taken as indicators, that the init has been completed.
		 * This is only a heuristic and not a foolproof determination.
		 * E.g A user could also cancel the company init and set these two fields later in the settings
		 * In that case the company init would not be shown anymore even though and has never actually been completed.
		 */
		return !company.trade && !company.additionalInformation?.size;
	}

	async isPushNotificationsScreenEnabled(): Promise<boolean> {
		const isNotificationsEnabled = await this.browserService.isPushNotificationsEnabled();
		const isWorkersEnabled = await this.browserService.isServiceWorkersEnabled();
		const lastFirefoxSetting = await this.localStorageService.get('firefoxBrowserSettingsVisit');

		return (
			this.browserDetectionService.isFirefoxBrowser() &&
			isNotificationsEnabled &&
			!isWorkersEnabled &&
			(!lastFirefoxSetting || moment().diff(moment.unix(lastFirefoxSetting), 'months') >= 1)
		);
	}

	async isTocDeclinedByCompany(): Promise<boolean> {
		const [isTocEnabled, isBeyondDueDate, isCompanyAccepted] = await Promise.all([
			this.tocService.isFeatureEnabled(),
			this.tocService.isBeyondDueDate(),
			this.tocService.isCompanyAccepted(),
		]);
		return isTocEnabled && isBeyondDueDate && !isCompanyAccepted;
	}
}
