import { Injectable } from '@angular/core';
import { FileType, TaskFile } from 'domain-entities';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AAC, JPEG, JPG, MP3, MP4, PNG } from '@shared/constants/upload.types';
import { EQUALS } from '@shared/constants/expression';
import {
	FileUploadService,
	TaskFileUploadParameters,
} from '@injectables/services/file-upload/file-upload.service';
import { THUMB } from '@shared/constants/thumbnail';
import { Observable } from 'rxjs';
import { BaseFileService } from '@injectables/services/base-file.service';
import { TASK_FILES } from '@shared/constants/firebase';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';

@Injectable({
	providedIn: 'root',
})
export class TaskFileService {
	COLLECTION = 'taskFiles';

	constructor(
		private storage: AngularFireStorage,
		private store: AngularFirestore,
		private fileUploadService: FileUploadService,
		private baseFileService: BaseFileService,
	) {}

	async createTaskFile(taskFile: TaskFile, file): Promise<void> {
		await this.store.collection(this.COLLECTION).doc(taskFile.id).set(taskFile);

		const params: TaskFileUploadParameters = {
			id: taskFile.id,
			file,
			fileName: taskFile.id,
			projectId: taskFile.projectId,
			context: FileContext.TASKS,
		};
		await this.fileUploadService.uploadFile(params);
	}

	getTaskFiles(id: string): Observable<TaskFile[]> {
		return this.store
			.collection<TaskFile>(this.COLLECTION, (ref) =>
				ref.where('taskId', EQUALS, id).orderBy('creationTimestamp', 'desc'),
			)
			.valueChanges();
	}

	deleteTaskFile(fileId: string): Promise<void> {
		return this.store.collection(this.COLLECTION).doc(fileId).delete();
	}

	getTaskFileUrl(taskFile: TaskFile, thumbnail = false): Observable<string> {
		const fileType = thumbnail ? JPG : this.baseFileService.getFileExtension(taskFile.name, false);
		const name = thumbnail ? taskFile.id + THUMB : taskFile.id;

		return this.storage.ref(`${taskFile.projectId}/taskFiles/${name}.${fileType}`).getDownloadURL();
	}

	getFileType(name): FileType {
		const fileType = this.baseFileService.getFileExtension(name);

		switch (fileType) {
			case JPG:
			case JPEG:
			case PNG:
				return FileType.IMAGE;
			case AAC:
			case MP3:
				return FileType.AUDIO;
			case MP4:
				return FileType.VIDEO;
			default:
				return FileType.DOCUMENT;
		}
	}

	getDownloadUrl(taskFile: TaskFile): Promise<string> {
		const fileExt = this.baseFileService.getFileExtOrName(taskFile, true);
		const storage = this.storage.storage.ref();
		const content = `${taskFile.projectId}${TASK_FILES}${taskFile.id}.${fileExt}`;

		return storage
			.child(content)
			.getDownloadURL()
			.then((url) => {
				return url;
			})
			.catch(() => {
				return null;
			});
	}
}
