<ng-container *ngIf="hasFailedUploadElements$ | async" [ngTemplateOutlet]="retry"></ng-container>
<div [class.file-list-grid]="viewOption === 'grid'">
	<ng-container
		[ngTemplateOutlet]="viewOption === 'grid' ? grid : list"
		*ngFor="let upload of fileUploadElements$ | async; let first = first"
		[ngTemplateOutletContext]="{ $implicit: upload, first: first, paths: pathOfAllErroredFiles$ | async }"
		class="d-flex justify-content-between align-items-center"
	>
	</ng-container>
</div>

<ng-template let-upload let-paths="paths" #grid>
	<div class="file-list-grid__item m-2">
		<div class="file-list-grid__fill flex-grow-1 d-flex justify-content-end p-2 mat-background-grey-400">
			<mat-icon *ngIf="upload.status === 'error'" class="failure__color">error_outline</mat-icon>
		</div>
		<div [class.failure__background_and_color]="upload.status === 'error'" class="p-2 file-list-grid__content">
			<div *ngIf="upload.status !== 'error'" class="d-flex align-items-center">
				<div class="flex-grow-1">
					<ng-container [ngTemplateOutlet]="progress" [ngTemplateOutletContext]="{ $implicit: upload.percentage }"></ng-container>
				</div>
				<mat-icon (mouseup)="cancelUpload(upload.id)" class="pointer">highlight_off</mat-icon>
			</div>
			<div class="pb-1">{{ upload.fileName }}</div>
			<div *ngIf="upload.status === 'error'" class="info">
				<p>{{ 'global_error' | translate }}</p>
				<p *ngIf="paths?.get(upload.id)?.folderName" [matTooltip]="paths?.get(upload.id)?.fullPath">
					{{ paths?.get(upload.id)?.folderName }}
				</p>
			</div>
		</div>
	</div>
</ng-template>

<ng-template let-upload let-first="first" let-paths="paths" #list>
	<mat-divider *ngIf="first"></mat-divider>
	<div class="d-flex justify-content-between align-items-center p-3" [class.failure__background_and_color]="upload.status === 'error'">
		<div class="d-flex flex-column justify-content-center flex-grow-1 pr-2">
			<h1 class="m-0 font-weight-normal mb-2">{{ upload.fileName }}</h1>
			<div *ngIf="upload.status === 'error'" class="info">
				{{ 'file-section-upload-list.errors.can-not-be-uploaded' | translate }}
				<p *ngIf="paths?.get(upload.id)?.folderName" [matTooltip]="paths?.get(upload.id)?.fullPath" class="pt-2 font-weight-bold">
					{{ paths?.get(upload.id)?.folderName }}
				</p>
			</div>
			<ng-container
				*ngIf="upload.status !== 'error'"
				[ngTemplateOutlet]="progress"
				[ngTemplateOutletContext]="{ $implicit: upload.percentage }"
			></ng-container>
		</div>
		<div *ngIf="upload.status === 'error'" class="d-flex align-items-center">
			<span class="info pr-1">{{ 'global_error' | translate }}</span>
			<mat-icon>error_outline</mat-icon>
		</div>
		<div *ngIf="upload.status !== 'error'" class="d-flex align-items-center">
			<span class="info pr-1">{{ upload.percentage }}%</span>
			<mat-icon (mouseup)="cancelUpload(upload.id)" class="pointer">highlight_off</mat-icon>
		</div>
	</div>
	<mat-divider></mat-divider>
</ng-template>
<ng-template let-percentage #progress>
	<div class="w-100 pr-1 pb-2">
		<mat-progress-bar class="project-status-progress-bar" color="accent" mode="determinate" [value]="percentage"></mat-progress-bar>
	</div>
</ng-template>

<ng-template #retry>
	<div class="pb-2 p-3 failure__background_and_color">
		<p class="info" [innerHTML]="'file-section-upload-list.retry.title' | translate"></p>
		<button (click)="onDismiss()" mat-button>{{ 'global_cancel' | translate }}</button>
		<button (click)="onRetry()" mat-button>{{ 'file-section-upload-list.retry.retry' | translate }}</button>
	</div>
</ng-template>
