import { Injectable } from '@angular/core';
import { loggedIn } from '@angular/fire/compat/auth-guard';
import {
	ActivatedRouteSnapshot,
	NavigationExtras,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { pipe, timer } from 'rxjs';
import { concatMap, filter, first, map, take } from 'rxjs/operators';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectAppInitStatus } from '@store/selectors/app-init.selectors';
import { AppInitStatus } from '@store/state/app-init.state';

@Injectable({
	providedIn: 'root',
})
export class CraftnoteAuthGuard {
	constructor(
		private readonly afAuth: AngularFireAuth,
		private readonly router: Router,
		private readonly remoteConfig: RemoteConfig,
		private readonly store: Store<AppState>,
	) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Promise<boolean | UrlTree> {
		const authPipeFactory = next.data?.authGuardPipe || (() => loggedIn);
		const activatedUrl = await this.afAuth.user
			.pipe(
				take(1),
				authPipeFactory(next, state),
				concatMap((params) => timer(0).pipe(map(() => params))),
				map(([canActivate, navigationExtras]) => {
					if (typeof canActivate === 'boolean') {
						return canActivate;
					} else {
						return this.router.createUrlTree(canActivate, navigationExtras || {});
					}
				}),
			)
			.toPromise();

		const currentUser = await this.afAuth.user.pipe(take(1)).toPromise();

		if (!currentUser) {
			return activatedUrl;
		}

		const isSplashScreenEnabled = await this.isSplashScreenEnabled();
		if (isSplashScreenEnabled) {
			await this.store
				.select(selectAppInitStatus)
				.pipe(
					filter((state) => state === AppInitStatus.DONE),
					first(),
				)
				.toPromise();
		}

		return activatedUrl;
	}

	private async isSplashScreenEnabled(): Promise<boolean> {
		return !!(await this.remoteConfig.getValueAsync('feature_splash_screen'));
	}
}

export const redirectUnauthorizedTo = (redirect: any[], navigationExtras: NavigationExtras = {}) =>
	pipe(
		loggedIn,
		map((isLoggedIn) => [isLoggedIn || redirect, navigationExtras]),
	);
export const redirectLoggedInTo = (redirect: any[], navigationExtras: NavigationExtras = {}) =>
	pipe(
		loggedIn,
		map((isLoggedIn) => [(isLoggedIn && redirect) || true, navigationExtras]),
	);
