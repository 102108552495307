import { Injectable } from '@angular/core';
import { AlertService } from '@injectables/services/alert/alert.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { COLLECTION_INVITES, ERROR } from '@shared//constants/firebase';
import { Member, Project } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class InviteService {
	constructor(private afStore: AngularFirestore, private alertService: AlertService) {}

	async saveInvites(members: Member[], project: Project): Promise<void> {
		const batch = this.afStore.firestore.batch();

		for (const member of members) {
			const ref = this.afStore.firestore.collection(COLLECTION_INVITES).doc(member.email);
			batch.set(
				ref,
				{
					[project.id]: {
						emailSent: false,
						projectName: project.name,
						timestamp: project.creationDate,
						projectId: project.id,
					},
				},
				{ merge: true },
			);
		}

		return batch.commit().catch((error) => {
			// Suppressing an error when error.id is undefined, this will be changed when we change the schema of invites
			if (!error.id) {
				return;
			}
			this.alertService.showAlert(ERROR + error.id);
		});
	}

	async saveInvitesMultiple(email: string, projects: Project[]): Promise<void> {
		const ref = this.afStore.firestore.collection(COLLECTION_INVITES).doc(email);
		const objToSave = {};

		projects.forEach((project: Project) => {
			objToSave[project.id] = {
				emailSent: false,
				projectName: project.name,
				timestamp: project.creationDate,
				projectId: project.id,
			};
		});

		return ref.set(JSON.parse(JSON.stringify(objToSave)), { merge: true }).catch((error) => {
			this.alertService.showAlert(ERROR + error.code);
		});
	}
}
