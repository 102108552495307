<div appDnd (dataTransfer)="uploadFiles($event)" class="h-100 d-flex flex-column" (click)="cancelSelection()" appEventStopPropagation>
	<div [ngClass]="{ restricted: !(isDragAndDropEnabled$ | async) }" class="files-section__drop-area p-3">
		<div class="d-flex flex-column align-items-center justify-content-center h-100">
			<ng-container *ngIf="isDragAndDropEnabled$ | async">
				<mat-icon class="mb-2">content_copy</mat-icon>
				<strong>{{ 'files-section.drop-zone.message' | translate }}</strong>
			</ng-container>
			<ng-container *ngIf="!(isDragAndDropEnabled$ | async)">
				<mat-icon class="mb-2 invert-color" svgIcon="drag-drop-restricted"></mat-icon>
				<strong class="px-2">{{ 'files-section.drop-zone.restricted-message' | translate }}</strong>
			</ng-container>
		</div>
	</div>
	<div class="overflow-auto flex-grow-1">
		<app-files-section-upload-list
			(openFilePicker)="uploadInput.click()"
			[currentFolder]="currentSelectedFolder$ | async"
			[viewOption]="viewOption"
		></app-files-section-upload-list>
		<div
			class="templates-promotional-content h-100"
			appOverflowClass="promotional-content-overflows"
			[@fadeInOutAnimation]
			*ngIf="templatesPromotionalContentVisible$ | async"
		>
			<ch-promotional-content orientation="column">
				<img class="img-fluid" src="assets/images/project-templates-promotional-illustration.svg" alt="template-illustration" />

				<div class="content-heading text-center">
					{{ 'files-section.templates.promotional-banner.title' | translate }}
				</div>

				<ul class="content-list">
					<li>{{ 'files-section.templates.promotional-banner.point-1' | translate }}</li>
					<li>{{ 'files-section.templates.promotional-banner.point-2' | translate }}</li>
					<li>{{ 'files-section.templates.promotional-banner.point-3' | translate }}</li>
					<li class="green-tick" [innerHTML]="'files-section.templates.promotional-banner.point-4' | translate"></li>
					<li class="green-tick" [innerHTML]="'files-section.templates.promotional-banner.point-5' | translate"></li>
				</ul>

				<div class="action-buttons">
					<button (click)="onClickTemplatesPromotionalContentHideButton()" class="w-100" color="accent" mat-flat-button>
						{{ 'files-section.templates.promotional-banner.close-btn-text' | translate }}
					</button>
				</div>
			</ch-promotional-content>
		</div>
		<div
			[class.d-none]="templatesPromotionalContentVisible$ | async"
			class="files-section-container position-relative h-100 font-weight-light"
		>
			<div class="d-flex flex-column files-section__files-content h-100">
				<ng-container *ngIf="!(currentFileSelectionOperation$ | async)" [ngTemplateOutlet]="fileSectionHeader"></ng-container>
				<app-files-section-copy-or-move-header
					(addFolder)="openNewFolderDialog()"
					*ngIf="currentFileSelectionOperation$ | async"
					[filesOperation]="currentFileSelectionOperation$ | async"
					[selectedFiles]="filesToCopyOrMove"
				></app-files-section-copy-or-move-header>
				<ng-container *ngIf="currentSelectedFolder$ | async" [ngTemplateOutlet]="fileSectionBreadcrumbs"></ng-container>
				<ng-container [ngTemplateOutlet]="fileSectionList"></ng-container>
			</div>
			<ng-container
				*ngIf="!selectedFilesLength && !(currentFileSelectionOperation$ | async) && (isUploadFilesMenuEnabled$ | async)"
				[ngTemplateOutlet]="uploadFilesMenu"
			></ng-container>
		</div>

		<ng-template #fileSectionHeader>
			<div class="files-section__header d-flex">
				<div class="w-50 text-left">
					<span>{{ 'files-section.header.sort-by' | translate }} </span>
					<span [mat-menu-trigger-for]="sortMenu" class="files-section__action-item" appEventStopPropagation>
						{{ 'files-section.header.sort.' + (sortOptions$ | async).sortType + '.' + (sortOptions$ | async).sortOrder | translate }}
					</span>
				</div>
				<div class="w-50 text-right">
					<span>{{ 'files-section.header.view-option' | translate }} </span>
					<span (click)="setViewOption('list')" appEventStopPropagation *ngIf="viewOption === 'grid'" class="files-section__action-item">
						{{ 'files-section.header.list' | translate }}
					</span>
					<span (click)="setViewOption('grid')" appEventStopPropagation *ngIf="viewOption === 'list'" class="files-section__action-item">
						{{ 'files-section.header.tile' | translate }}
					</span>
				</div>
			</div>

			<mat-menu #sortMenu="matMenu" class="craftnote-menu">
				<button (click)="setSort('name', 'asc')" mat-menu-item>
					{{ 'files-section.header.sort.name.asc' | translate }}
				</button>
				<button (click)="setSort('name', 'desc')" mat-menu-item>
					{{ 'files-section.header.sort.name.desc' | translate }}
				</button>
				<button (click)="setSort('createdTimestamp', 'asc')" mat-menu-item>
					{{ 'files-section.header.sort.createdTimestamp.asc' | translate }}
				</button>
				<button (click)="setSort('createdTimestamp', 'desc')" mat-menu-item>
					{{ 'files-section.header.sort.createdTimestamp.desc' | translate }}
				</button>
			</mat-menu>
		</ng-template>

		<ng-template #fileSectionBreadcrumbs>
			<div class="files-section__breadcrumbs-container d-flex align-items-center">
				<div (click)="goBack()" class="files-section__breadcrumbs-back-button">
					<mat-icon data-cy="files-back-arrow">arrow_back</mat-icon>
				</div>
				<div (mouseup)="moveFilesOnDrop(null)" (click)="goToRoot()" class="root-icon" [ngClass]="{ breadcrumbs__dragging: isDragStarted }">
					<mat-icon>home</mat-icon>
				</div>
				<div class="files-section__breadcrumbs-separator">/</div>
				<div class="files-section__breadcrumbs" data-cy="files-bread-crumbs">
					<app-breadcrumbs
						[breadCrumbs]="folderBreadcrumbs"
						[isDragAndDrop]="true"
						[isDragStarted]="isDragStarted"
						(clicked)="openFolder($event)"
						(onDropItem)="moveFilesOnDrop($event)"
					></app-breadcrumbs>
				</div>
			</div>
			<div class="breabcrumbs-hr"></div>
		</ng-template>

		<ng-template #fileSectionList>
			<app-hint-banner
				*ngIf="templatesHintVisible$ | async"
				(closeButtonClicked)="onClickTemplatesHintHideButton()"
				data-cy="template-hint-banner"
			>
				<div>
					<span [innerHTML]="'files-section.templates.hint.text' | translate" data-cy="template-hint-text"></span>
					<br />
					<strong>
						<a
							href=""
							(click)="ctaClickHandler()"
							[routerLink]="['/settings/company-templates']"
							class="text-primary"
							data-cy="template-management-link"
						>
							{{ 'files-section.templates.hint.cta' | translate }}
						</a>
					</strong>
				</div>
			</app-hint-banner>

			<app-files-section-files-list
				cdkScrollable
				class="flex-grow-1 padding-bottom"
				(selectedFolder)="selectFolder($event)"
				(selectedFile)="toggleFileSelection($event)"
				(openFile)="openFile($event)"
				(copyFileToOtherProject)="copyFilesToOtherProject($event)"
				(copyFileWithInTheProject)="copyOrMoveWithInTheProject('copy-with-in-project', $event)"
				(deleteFile)="deleteSelectedFilesAndFolders($event)"
				(download)="download($event)"
				(moveFile)="copyOrMoveWithInTheProject('move', $event)"
				(dropFile)="moveFilesOnDrop($event)"
				(dragStartedEnded)="dragStartedEnded($event)"
				(copyFileToChat)="copyToChat($event)"
				(copyFileToChatOtherProject)="copyToChatInOtherProject($event)"
				[selectProjectFiles$]="selectProjectFiles$"
				[selectedProjectId]="selectedProjectId$ | async"
				[viewOption]="viewOption"
				[selectedFiles]="selectedFiles"
				[filesOperation]="currentFileSelectionOperation$ | async"
				[isOneOfFolderSelected]="isOneOfFolderSelected"
				[allFilesInSection]="allProjectFilesFolders"
			></app-files-section-files-list>
		</ng-template>

		<ng-template #uploadFilesMenu>
			<div class="files-section__upload-menu d-flex flex-column align-items-end font-weight-normal">
				<ng-template [ngIf]="createButtonsActive" class="expand-explorer-panel">
					<div [@shrinkOut] class="files-section__upload-button">
						<span class="fab-text text-white">{{ 'files-section.menu.add-folder' | translate }}</span>
						<button (click)="openNewFolderDialog()" class="ml-3" color mat-mini-fab>
							<mat-icon data-cy="files-add-folder" fontSet="material-icons-outlined">create_new_folder</mat-icon>
						</button>
					</div>
					<div [@shrinkOut] class="files-section__upload-button">
						<span class="fab-text text-white">{{ 'files-section.menu.upload-files' | translate }}</span>
						<button (click)="uploadInput.click()" class="ml-3" color mat-mini-fab>
							<mat-icon>upload_file</mat-icon>
						</button>
					</div>
					<div [@shrinkOut] class="files-section__upload-button">
						<span class="fab-text text-white">{{ 'files-section.menu.upload-folder' | translate }}</span>
						<button (click)="folderUploadInput.click()" class="ml-3" color mat-mini-fab>
							<mat-icon>drive_folder_upload</mat-icon>
						</button>
					</div>
				</ng-template>

				<button (click)="toggleActionMenu()" class="ml-2" color="accent" id="trigger-btn" mat-fab>
					<mat-icon
						[@rotate]="createButtonsActive ? 'in' : 'out'"
						class="position-relative"
						id="trigger-btn-icon"
						data-cy="add-files-folders"
						>add
					</mat-icon>
				</button>
			</div>
		</ng-template>

		<ng-template #multiSelection>
			<div
				class="files-section__multi-selection d-flex align-items-center flex-wrap justify-content-between py-2 px-1"
				appEventStopPropagation
			>
				<button mat-flat-button (click)="cancelSelection()" class="pointer multi-selection-action-btn">
					{{ 'files-section.multi-select.cancel' | translate }}
				</button>
				<button
					mat-flat-button
					(click)="selectAll()"
					class="text-right pointer color-primary multi-selection-action-btn"
					[disabled]="selectedFilesLength === (projectFilesFolders$ | async).length"
				>
					{{ 'files-section.multi-select.select-all' | translate }}
				</button>
			</div>
		</ng-template>

		<ng-template #copyOrMoveFiles>
			<div class="files-section__multi-selection d-flex align-items-center flex-wrap justify-content-between py-2 px-1">
				<button mat-flat-button (click)="confirmCopyOrMove(false)" class="pointer multi-selection-action-btn" data-cy="files-move-cancel">
					{{ 'files-section.multi-select.cancel' | translate }}
				</button>
				<button
					mat-flat-button
					(click)="confirmCopyOrMove(true)"
					class="text-right pointer color-primary multi-selection-action-btn"
					data-cy="files-copy-here"
				>
					<ng-container *ngIf="(currentFileSelectionOperation$ | async) === 'copy-with-in-project'">
						{{ 'files-section.multi-select.paste' | translate }}
					</ng-container>
					<ng-container *ngIf="(currentFileSelectionOperation$ | async) === 'move'">
						{{ 'files-section.multi-select.move' | translate }}
					</ng-container>
					<ng-container *ngIf="(currentFileSelectionOperation$ | async) === 'copy-chat-file'">
						{{ 'files-section.multi-select.copy-chat-file' | translate }}
					</ng-container>
				</button>
			</div>
		</ng-template>

		<app-dark-overlay (click)="toggleActionMenu()" [visible]="createButtonsActive" [zIndex]="500"></app-dark-overlay>
	</div>
	<ng-container *ngIf="selectedFilesLength && !filesToCopyOrMove.length" [ngTemplateOutlet]="multiSelection"></ng-container>
	<ng-container *ngIf="currentFileSelectionOperation$ | async" [ngTemplateOutlet]="copyOrMoveFiles"></ng-container>
</div>
<form #uploadForm>
	<input
		#folderUploadInput
		(input)="uploadFiles($event.target.files)"
		[accept]="ACCEPTED_FILE_TYPES"
		class="d-none"
		webkitdirectory
		directory
		multiple
		type="file"
	/>
	<input #uploadInput (input)="uploadFiles($event.target.files)" [accept]="ACCEPTED_FILE_TYPES" class="d-none" multiple type="file" />
</form>
