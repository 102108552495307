import { Serializable } from '@craftnote/shared-utils';

export class CompanyTemplate implements Serializable<CompanyTemplate> {
	constructor(
		public id?: string,
		public name?: string,
		public companyId?: string,
		public creationTimestamp?: number,
		public folderId?: string,
		public size?: number,
		public type?: COMPANY_FILE_TYPE,
	) {}

	deserialize(input: any): CompanyTemplate {
		this.id = input.id;
		this.name = input.name;
		this.companyId = input.companyId;
		this.creationTimestamp = input.creationTimestamp;
		this.folderId = input.folderId;
		this.type = input.type;
		this.size = input.size;

		return this;
	}
}

export enum COMPANY_FILE_TYPE {
	FOLDER = 'FOLDER',
	DOCUMENT = 'DOCUMENT',
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	AUDIO = 'AUDIO',
}
