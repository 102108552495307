<div class="position-relative">
	<div mat-dialog-title class="d-flex flex-column align-items-start justify-content-between m-0 text-break">
		<h1>{{ config.title }}</h1>

		<button class="close-icon" mat-icon-button [mat-dialog-close]="null" *ngIf="config.showCrossBtn">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content *ngIf="config.message || config.messageList.length">
		<div class="d-flex">
			<div>
				<h1 *ngIf="config.message" class="mb-5">{{ config.message }}</h1>
				<ul class="list-unstyled mb-5" *ngIf="config.messageList.length">
					<li class="orange-tick" *ngFor="let messageListItem of config.messageList; let last = last" [class.mb-3]="!last">
						{{ messageListItem }}
					</li>
				</ul>

				<div>
					<button
						[class.mr-3]="config.showSecondaryButton"
						*ngIf="config.showPrimaryButton"
						mat-button
						[color]="config.primaryButtonColor"
						(click)="onClose(config.primaryButtonValue)"
						cdkFocusInitial
					>
						{{ config.primaryButtonText }}
					</button>

					<button
						[class.ml-3]="config.showPrimaryButton"
						*ngIf="config.showSecondaryButton"
						mat-flat-button
						(click)="onClose(config.secondaryButtonValue)"
						[color]="config.secondaryButtonColor"
					>
						{{ config.secondaryButtonText }}
					</button>
				</div>
			</div>
			<div class="paywall-image">
				<img class="img-fluid" [attr.src]="paywallImageSource$ | async" alt="paywall-illustration" />
			</div>
		</div>
	</mat-dialog-content>
</div>
