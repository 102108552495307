<ng-container *ngIf="menuConfig?.matIcon; else textButton">
	<div class="d-flex">
		<mat-icon [attr.data-cy]="selectorString(0)" [matMenuTriggerFor]="menu" class="pointer">{{ menuConfig?.triggerName }}</mat-icon>
		<mat-icon [ngClass]="{ 'active-icon-badge__hidden': !menuConfig?.showActive }" class="active-icon-badge">
			fiber_manual_record
		</mat-icon>
	</div>
</ng-container>
<ng-template #textButton>
	<button [attr.data-cy]="selectorString(0)" mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="menuConfig">
		{{ menuConfig?.triggerName }}
	</button>
</ng-template>

<mat-menu [backdropClass]="menuConfig?.disableMenu ? 'ch-disable-mat-menu' : ''" #menu>
	<div (click)="openDisabledDialog()">
		<ng-container *ngFor="let option of menuConfig?.options || []; let i = index">
			<ng-container *ngIf="option.options; else leaf">
				<app-multi-menu *ngIf="option.options" [menuConfig]="option" [level]="level + 1"></app-multi-menu>
			</ng-container>
			<ng-template #leaf>
				<button
					*ngIf="option.openInModal"
					[attr.data-cy]="selectorString(i, true)"
					mat-menu-item
					(click)="openDialog(option)"
					[ngClass]="{ 'menu-item__disabled': menuConfig?.disableMenu }"
				>
					<mat-icon *ngIf="option.iconName || option.customIconName" [svgIcon]="option?.customIconName!">{{ option?.iconName }}</mat-icon>
					{{ option.triggerName }}
					<mat-icon class="active-badge" [ngClass]="{ 'active-badge__hidden': !option.showActive }"> fiber_manual_record </mat-icon>
				</button>
				<button
					*ngIf="!option.openInModal"
					[attr.data-cy]="selectorString(i, true)"
					mat-menu-item
					[mat-menu-trigger-for]="menuContent"
					[matMenuTriggerData]="{ $implicit: option.ref }"
					[ngClass]="{ 'menu-item__disabled': menuConfig?.disableMenu }"
				>
					<mat-icon *ngIf="option.iconName || option.customIconName" [svgIcon]="option?.customIconName!">{{ option?.iconName }}</mat-icon>
					{{ option.triggerName }}
					<mat-icon class="active-badge" [ngClass]="{ 'active-badge__hidden': !option.showActive }"> fiber_manual_record </mat-icon>
				</button>
			</ng-template>
		</ng-container>
		<div
			*ngIf="menuConfig?.disableMenu"
			class="w-100 h-100 d-flex align-items-center justify-content-center p-2 font-weight-bold pointer disabled-action-button"
		>
			{{ menuConfig?.disableMenuText }}
		</div>
	</div>
</mat-menu>

<mat-menu #menuContent>
	<ng-template matMenuContent let-ref>
		<ng-container [ngTemplateOutlet]="ref"></ng-container>
	</ng-template>
</mat-menu>
