import { Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
	ControlValueAccessor,
	FormArray,
	NG_VALUE_ACCESSOR,
	UntypedFormBuilder,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { differenceInSeconds, isSameDay, set } from 'date-fns';
import { formatTimeToHHMM } from '@shared/shared.utils';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';

const MAX_VALUE = 32400;

interface PauseButtonDataType {
	start: Date;
	duration: number;
	breaks: {
		start: Date;
		end: Date;
	}[];
}

@Component({
	selector: 'app-tracked-time-pause-button',
	templateUrl: './tracked-time-pause-button.component.html',
	styleUrls: ['./tracked-time-pause-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => TrackedTimePauseButtonComponent),
		},
	],
})
export class TrackedTimePauseButtonComponent
	implements ControlValueAccessor, OnInit, OnDestroy, Validator
{
	private destroy$ = new Subject();
	private showAddBreakButtonFeatureFlag: boolean;
	breaks: { start: Date; end: Date }[] = [];
	timeRangeControl = this.formBuilder.group({
		start: [null],
		duration: [0],
		breaks: this.formBuilder.array([]),
	});

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private remoteConfig: RemoteConfig,
	) {
		this.showAddBreakButtonFeatureFlag = this.remoteConfig.getBoolean(
			'feature_time_tracking_multiple_breaks',
		);
	}

	private _required = false;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: boolean) {
		this._required = coerceBooleanProperty(value);
	}

	private _disabled = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
	}

	get duration(): number {
		return this.timeRangeControl.controls['duration'].value;
	}

	get maxValueReached(): boolean {
		return this.timeRangeControl.value.duration >= MAX_VALUE;
	}

	get showAddBreakButton(): boolean {
		return this.showAddBreakButtonFeatureFlag || this.breaks.length === 0;
	}

	private _trackedTimeRange: { start: Date; end: Date };

	get trackedTimeRange(): { start: Date; end: Date } {
		return this._trackedTimeRange;
	}

	@Input() set trackedTimeRange(value: { start: Date; end: Date }) {
		if (
			value &&
			(!this._trackedTimeRange ||
				value.start.getTime() !== this._trackedTimeRange.start.getTime() ||
				value.end.getTime() !== this._trackedTimeRange.end.getTime())
		) {
			this._trackedTimeRange = value;
			this.updateBreakDates();
		}
	}

	ngOnInit(): void {
		this.listenToBreakChanges();
		this.initializeBreaksFormArray();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}


	writeValue(value: PauseButtonDataType): void {
		if (value) {
			this.timeRangeControl.patchValue(value, { emitEvent: false });
			this.breaks = value.breaks || [];
			this.initializeBreaksFormArray();
		}
	}

	registerOnChange(fn: (value: PauseButtonDataType) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	validate(): ValidationErrors | null {
		return this.timeRangeControl.valid ? null : { invalidPause: true };
	}

	markAsTouched(): void {}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	durationFormatted(duration: number): { hr: string; min: string } {
		return formatTimeToHHMM(duration);
	}

	addBreak(): void {
		if (!this.trackedTimeRange) {
			console.error('TrackedTimePauseButton: trackedTimeRange is not set');
			return;
		}
		const breaksFormArray = this.timeRangeControl.get('breaks') as FormArray;

		//only first break is added with a default value
		if (breaksFormArray.length === 0) {
			const { start: rangeStart, end: rangeEnd } = this.trackedTimeRange;

			const breakStart = new Date(rangeStart);
			const breakEnd = new Date(rangeStart);

			breakStart.setHours(12, 0, 0, 0);
			breakEnd.setHours(12, 30, 0, 0);

			const isBreakValid = breakStart >= rangeStart && breakEnd <= rangeEnd;

			const newBreakStart = isBreakValid ? breakStart : null;
			const newBreakEnd = isBreakValid ? breakEnd : null;

			breaksFormArray.push(
				this.formBuilder.group({
					start: [newBreakStart],
					end: [newBreakEnd],
				}),
			);
		} else {
			breaksFormArray.push(
				this.formBuilder.group({
					start: [null],
					end: [null],
				}),
			);
		}

		this.updateParentForm();
	}

	removeBreak(index: number): void {
		const breaksFormArray = this.timeRangeControl.get('breaks') as FormArray;
		breaksFormArray.removeAt(index);
		this.updateParentForm();
	}

	getTotalPauseDuration(): { hr: string; min: string } {
		const totalSeconds = this.breaks.reduce((total, breakItem) => {
			if (breakItem.start && breakItem.end) {
				return total + differenceInSeconds(breakItem.end, breakItem.start);
			}
			return total;
		}, 0);
		return formatTimeToHHMM(totalSeconds);
	}

	private onChange: (value: PauseButtonDataType) => void = () => {};

	private onTouched: () => void = () => {};

	private listenToBreakChanges(): void {
		this.timeRangeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.breaks = value.breaks;
			this.updateParentForm();
		});
	}

	private updateParentForm(): void {
		const formValue = this.timeRangeControl.value;
		const breaks = formValue.breaks || [];

		// Calculate start time (start of the first break)
		const start = breaks.length > 0 ? breaks[0].start : null;

		// Calculate total duration of all breaks in seconds
		let duration = breaks.reduce((total, breakItem) => {
			if (breakItem.start && breakItem.end) {
				return total + differenceInSeconds(breakItem.end, breakItem.start);
			}
			return total;
		}, 0);
		if (duration <= 0) {
			duration = 0;
		}

		const updatedFormValue = {
			...formValue,
			start,
			duration,
		};

		this.onChange(updatedFormValue);
		this.onTouched();
	}

	//needed when the user opens an existing tracked time, if no breaks array exist it should create one from the start and duration fields
	private initializeBreaksFormArray(): void {
		const breaksFormArray = this.timeRangeControl.get('breaks') as FormArray;
		//should only populate the breaks form array if the breaks array is empty or if the breaks array is not the same as the breaks array in the form
		const needsToAddBreak = this.breaks.every((breakItem) => breakItem.start !== null && breakItem.end !== null && breaksFormArray.controls.every((formBreak) => formBreak.value.start !== breakItem.start && formBreak.value.end !== breakItem.end));
		if (needsToAddBreak) {
			breaksFormArray.clear();
		this.breaks.forEach((breakItem) => {
			breaksFormArray.push(
				this.formBuilder.group({
					start: [breakItem.start, Validators.required],
						end: [breakItem.end, Validators.required],
					}),
				);
			});
		}
		
	}

	private updateBreakDates(): void {
		if (!this._trackedTimeRange) return;

		const breaksFormArray = this.timeRangeControl.get('breaks') as FormArray;
		this.breaks = breaksFormArray.controls.map((breakControl) => {
			const breakValue = breakControl.value;
			const updatedBreak = this.modifyBreakDates(breakValue);
			breakControl.patchValue(updatedBreak);
			return updatedBreak;
		});
		this.updateParentForm();
	}

	private modifyBreakDates(breakTime: { start: Date; end: Date }): { start: Date; end: Date } {
		const { start: rangeStart, end: rangeEnd } = this._trackedTimeRange;
		let modifiedBreak = { ...breakTime };

		modifiedBreak.start = this.replaceTime(rangeStart, breakTime.start);
		modifiedBreak.end = this.replaceTime(rangeStart, breakTime.end);

		const startAndEndNotInSameDay = !isSameDay(rangeStart, rangeEnd);

		if (startAndEndNotInSameDay) {
			if (modifiedBreak.start < rangeStart) {
				const newStartDate = this.replaceTime(rangeEnd, breakTime.start);
				if (newStartDate < rangeEnd) {
					modifiedBreak.start = newStartDate;
				}
			}

			if (modifiedBreak.end < rangeStart) {
				modifiedBreak.end = this.replaceTime(rangeEnd, breakTime.end);
			}
		}
		return modifiedBreak;
	}

	private replaceTime(date: Date, time: Date): Date {
		return set(date, {
			hours: time.getHours(),
			minutes: time.getMinutes(),
			seconds: time.getSeconds(),
			milliseconds: time.getMilliseconds(),
		});
	}

}
