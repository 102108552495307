import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

// TODO:: It should be generic pipe like DateFormat

@Pipe({
	name: 'messageDate',
})
export class MessageDatePipe implements PipeTransform {
	transform(isoDate: string, format: string = 'DD MMM YYYY'): string {
		return moment(isoDate).format(format);
	}
}
