import { AppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';

export const selectExternalChatState = (state: AppState) => state.externalChat;

export const selectExternalChatUserState = createSelector(
	selectExternalChatState,
	(state) => state.userState,
);

export const selectExternalChatUserUnreadChannels = createSelector(
	selectExternalChatUserState,
	(state) => state && state.unreadChannels,
);

export const selectExternalChatUserUnreadCount = createSelector(
	selectExternalChatUserUnreadChannels,
	(noOfChannels) => (noOfChannels || []).length,
);
