import { createReducer, on } from '@ngrx/store';
import { InvitationsState } from '../state/invitations.state';
import {
	clearInvitationLink,
	clearInvitations,
	invitationsChanged,
	invitationsSubscriptionError,
	setInvitationLink,
} from '../actions/invitations.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { Invitation } from 'domain-entities';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { updateEntityStore } from '@store/reducers/functions/update-entity-state.functions';

const invitationsAdapter = createEntityAdapter<Invitation>();

const initialInvitationsState: InvitationsState = {
	invitationsLinkState: undefined,
	invitations: invitationsAdapter.getInitialState({
		loadingStatus: StoreLoadingStatus.INITIAL,
	}),
};
const reducer = createReducer<InvitationsState>(
	initialInvitationsState,
	on(setInvitationLink, (state, { invitationLink }) => {
		const link = invitationLink === null ? null : { ...invitationLink };

		return {
			...state,
			invitationsLinkState: link,
		};
	}),
	on(clearInvitationLink, (state) => {
		return {
			...state,
			invitationsLinkState: null,
		};
	}),
	on(invitationsChanged, (state, { payload }) => {
		return {
			...state,
			invitations: updateEntityStore<Invitation>(invitationsAdapter, state.invitations, payload),
		};
	}),
	on(clearInvitations, (state) => {
		return {
			...state,
			invitations: invitationsAdapter.getInitialState({
				loadingStatus: StoreLoadingStatus.INITIAL,
			}),
		};
	}),
	on(invitationsSubscriptionError, (state) => {
		return {
			...state,
			invitations: invitationsAdapter.getInitialState({
				loadingStatus: StoreLoadingStatus.LOADED,
			}),
		};
	}),
);

// tslint:disable-next-line:typedef
export function invitationsReducer(state, action) {
	return reducer(state, action);
}

export const invitationSelectors = invitationsAdapter.getSelectors();
