import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { AppInitComponent } from './app-init.component';
import { PulsingCraftnoteIconModule } from '../pulsing-craftnote-icon/pulsing-craftnote-icon.module';
import { CompanyInitializationModule } from '../../../features/company-initialization/company-initialization.module';
import { AppInitStepperService } from './services/app-init-stepper.service';
import { BrowserCompatibilityModule } from '../browser-compatibility/browser-compatibility.module';
import { NotificationTokenPermissionModule } from '../notification-token-permission/notification-token-permission.module';

@NgModule({
	declarations: [AppInitComponent],
	providers: [AppInitStepperService],
	imports: [
		PulsingCraftnoteIconModule,
		SharedModule,
		CompanyInitializationModule,
		BrowserCompatibilityModule,
		NotificationTokenPermissionModule,
	],
	exports: [AppInitComponent],
})
export class AppInitModule {}
