import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectStatusInformation } from '@shared/models/project.type';

@Component({
	selector: 'app-project-status-progress-bar',
	templateUrl: './project-status-progress-bar.component.html',
	styleUrls: ['./project-status-progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusProgressBarComponent {
	@Input() projectStatus: ProjectStatusInformation;

	get percent(): number {
		return ((this.projectStatus.statusIndex + 1) / this.projectStatus.totalCompanyStatuses) * 100;
	}
}
