import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { isTrackedTime, TrackedTime } from 'domain-entities';
import { Observable } from 'rxjs';

export interface TrackedTimesLoadingFilter {
	projectIds?: string[];
	assigneeIds?: string[];
	workTypeIds?: string[];
	startDate?: number;
}

const TRACKED_TIME_REQUIRED_FIELDS: RequiredKey<TrackedTime>[] = [
	'id',
	'projectId',
	'creatorId',
	'creationTime',
	'startTime',
	'pauseDuration',
	'workTypeId',
	'workTypeName',
	'state',
	'userId',
];

@Injectable({
	providedIn: 'root',
})
export class TrackedTimeConnector {
	private static COLLECTION_NAME = 'trackedTimes';

	constructor(private readonly connector: FirestoreConnector) {}

	/**
	 * This method will load tracked times matching a filter in a best effort manner.
	 * That means that it guarantees to load at least all required data but does not
	 * guarantee to ONLY load that data. This is due to firestore limitations on filtering.
	 *
	 * @param companyId
	 * @param filters
	 */
	watchTrackedTimes(
		companyId: string,
		filters: TrackedTimesLoadingFilter,
	): Observable<TrackedTime[]> {
		const conditions: DataQueryCondition<TrackedTime>[] = [];
		conditions.push({ field: 'companyId', operator: '==', value: companyId });

		const canFilterByProject = filters.projectIds?.length <= 10;
		const canFilterByAssignees = filters.assigneeIds?.length <= 10;
		const canFilterByWorkType = filters.workTypeIds?.length <= 10;

		/**
		 * Firebase only allows for one "in" condition per query. So we have to create a hierarchy and
		 * only load by the first "in" condition that is available in that hierarchy
		 */
		if (canFilterByProject) {
			conditions.push({ field: 'projectId', operator: 'in', value: filters.projectIds });
		} else if (canFilterByAssignees) {
			conditions.push({ field: 'userId', operator: 'in', value: filters.assigneeIds });
		} else if (canFilterByWorkType) {
			conditions.push({ field: 'workTypeId', operator: 'in', value: filters.workTypeIds });
		}
		if (filters.startDate) {
			conditions.push({ field: 'startTime', operator: '>=', value: filters.startDate });
		}

		conditions.push({ field: 'state', operator: '==', value: 'stopped' });

		return this.connector.watchDocuments<TrackedTime>(
			TrackedTimeConnector.COLLECTION_NAME,
			conditions,
			isTrackedTime,
		);
	}

	getTrackedTimeById(id: string): Observable<TrackedTime> {
		return this.connector.watchDocument<TrackedTime>(
			TrackedTimeConnector.COLLECTION_NAME + '/' + id,
			isTrackedTime,
		);
	}

	getTrackedTimeByQuery(query: DataQueryCondition<TrackedTime>[]): Observable<TrackedTime[]> {
		return this.connector.watchDocuments<TrackedTime>(
			TrackedTimeConnector.COLLECTION_NAME,
			query,
			isTrackedTime,
		);
	}

	createTrackedTime(trackedTime: TrackedTime): Promise<void> {
		return this.connector.create(
			TrackedTimeConnector.COLLECTION_NAME,
			trackedTime.id,
			trackedTime,
			isTrackedTime,
		);
	}

	updateTrackedTime(trackedTime: Partial<TrackedTime>): Promise<void> {
		return this.connector.updateDocumentPartial<TrackedTime>(
			TrackedTimeConnector.COLLECTION_NAME,
			trackedTime.id,
			trackedTime,
			TRACKED_TIME_REQUIRED_FIELDS,
		);
	}
}
