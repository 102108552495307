import { Action, createReducer, on } from '@ngrx/store';
import { clearCompanyMetaData, setCompanyMetaData } from '@store/actions/company-metadata.actions';
import { CompanyMetadataState } from '@store/state/company-metadata.state';

const initialState: CompanyMetadataState = {
	companyId: undefined,
	externalUserIds: [],
	userMetaData: {},
};

const reducer = createReducer(
	initialState,
	on(setCompanyMetaData, (_, companyMetadata) => companyMetadata),
	on(clearCompanyMetaData, () => initialState),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function companyMetadataReducer(state: CompanyMetadataState, action: Action) {
	return reducer(state, action);
}
