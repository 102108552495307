import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectParentProject } from '@store/selectors/projects.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ProjectFolderHintComponentData {
	hasParent: boolean;
	parentProjectName: string;
}

@Component({
	selector: 'app-project-folder-hint',
	templateUrl: './project-folder-hint.component.html',
	styleUrls: ['./project-folder-hint.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFolderHintComponent implements OnChanges {
	@Input() projectId: string;

	data$: Observable<ProjectFolderHintComponentData>;

	constructor(private readonly store: Store<AppState>) {}

	ngOnChanges(): void {
		this.initProjectParent();
	}

	private initProjectParent(): void {
		this.data$ = this.store.select(selectParentProject, { projectId: this.projectId }).pipe(
			map((project) => {
				return {
					hasParent: !!project,
					parentProjectName: project?.name,
				};
			}),
		);
	}
}
