import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { loadProjectFilePathAction } from '@store/actions/project-files.actions';
import { Subject } from 'rxjs';
import { selectProjectFilePathById } from '@store/selectors/project-files.selectors';
import { takeUntil } from 'rxjs/operators';
import { ProjectFile } from '@shared/models/project-file.model';
import { ProjectFilePath } from '@store/reducers/project-files.reducer';
import { BaseFileService } from '@injectables/services/base-file.service';

@Component({
	selector: 'app-files-section-icon',
	templateUrl: './files-section-icon.component.html',
	styleUrls: ['./files-section-icon.component.scss'],
})
export class FilesSectionIconComponent implements OnInit, OnDestroy {
	@Input() file: ProjectFile;
	@Input() gridView: boolean;

	filePath: ProjectFilePath;
	destroy$ = new Subject();

	constructor(
		private readonly store: Store<AppState>,
		private readonly baseFileService: BaseFileService,
	) {}

	ngOnInit(): void {
		this.store
			.select(selectProjectFilePathById, { id: this.file.id })
			.pipe(takeUntil(this.destroy$))
			.subscribe((filePath) => {
				this.filePath = filePath;

				if (!filePath) {
					this.store.dispatch(
						loadProjectFilePathAction({
							file: this.file,
						}),
					);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	get hasValidExtension(): boolean {
		return this.baseFileService.getFileExtension(this.file.name, false) !== this.file.name;
	}
}
