import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromMessageActions from '../actions/message.actions';
import { Message } from 'domain-entities';
import { Search } from '@shared/models/search.model';
import { Action, createReducer, on } from '@ngrx/store';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';

export const messagesFeatureKey = 'messages';

export interface MessagesUIState {
	hasEarlierMessages: boolean;
	isLoading: boolean;
	isFilterEnabled: boolean;
	isPdfExportEnabled: boolean;
	replyMessage: Message;
	selectedParentId: string;
	selectedChatMessageToShare: Message;
}

export interface MediaMessageEntityState extends EntityState<Message> {
	loadingStatus: StoreLoadingStatus;
}

export interface MessageEntityState extends EntityState<Message> {
	// additional entities state properties
	UIState: MessagesUIState;
	searchOptions: Search;
	media: MediaMessageEntityState;
	chatInputCache: { [key: string]: string };
}

function selectByMessageId(a: Message): string {
	return a.id;
}

function sortByTimestamp(a: Message, b: Message): number {
	if (a.timestamp === b.timestamp) {
		return a.id.localeCompare(b.id);
	}
	return a.timestamp - b.timestamp;
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
	selectId: selectByMessageId,
	sortComparer: sortByTimestamp,
});

export const mediaAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
	selectId: selectByMessageId,
	sortComparer: sortByTimestamp,
});

export const initialState: MessageEntityState = adapter.getInitialState({
	// additional entity state properties
	UIState: {
		hasEarlierMessages: true,
		isLoading: false,
		isFilterEnabled: false,
		isPdfExportEnabled: false,
		replyMessage: null,
		selectedParentId: null,
		selectedChatMessageToShare: null,
	},
	searchOptions: {},
	media: mediaAdapter.getInitialState({
		loadingStatus: StoreLoadingStatus.INITIAL,
	}),
	chatInputCache: {},
});

const messageReducer2 = createReducer(
	initialState,
	on(fromMessageActions.AddMessage, (state, payload) => {
		return adapter.addOne(payload.message, state);
	}),
	on(fromMessageActions.UpsertMessage, (state, payload) => {
		const message: Message = { ...payload.message };
		message.tags = message.tags ? message.tags : [];
		return adapter.upsertOne(message, state);
	}),
	on(fromMessageActions.UpsertMessages, (state, payload) => {
		const messages: Message[] = payload.messages.map((message) => ({
			...message,
			tags: message.tags || [],
		}));
		return adapter.upsertMany(messages, state);
	}),

	on(fromMessageActions.ClearMessages, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				hasEarlierMessages: true,
			},
			media: mediaAdapter.removeAll({ ...state.media, loadingStatus: StoreLoadingStatus.LOADED }),
		};
		return adapter.removeAll(stateChanges);
	}),
	on(fromMessageActions.NextBatchAvailable, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				hasEarlierMessages: !state.UIState.hasEarlierMessages
					? false
					: state.UIState.hasEarlierMessages,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.NextBatchNotAvailable, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				hasEarlierMessages: false,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.LoadingEnabled, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isLoading: true,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.LoadingDisabled, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isLoading: false,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.SetSearchOptions, (state, payload) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
			},
			searchOptions: {
				...payload.searchOptions,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.FilterEnabled, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isFilterEnabled: true,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.FilterDisabled, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isFilterEnabled: false,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.ToggleFilter, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isFilterEnabled: !state.UIState.isFilterEnabled,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.ClearMessageState, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				hasEarlierMessages: true,
				isLoading: false,
				isFilterEnabled: false,
				isPdfExportEnabled: false,
				replyMessage: null,
				selectedParentId: null,
				selectedChatMessageToShare: null,
			},
			searchOptions: {},
			media: mediaAdapter.removeAll({ ...state.media, loadingStatus: StoreLoadingStatus.LOADED }),
		};
		return adapter.removeAll(stateChanges);
	}),
	on(fromMessageActions.ClearSearchAndFilter, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				hasEarlierMessages: true,
				isLoading: false,
				isFilterEnabled: false,
			},
			searchOptions: {},
		};
		return stateChanges;
	}),
	on(fromMessageActions.PdfExportEnable, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isPdfExportEnabled: true,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.PdfExportDisable, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isPdfExportEnabled: false,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.TogglePdfExport, (state: MessageEntityState) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				isPdfExportEnabled: !state.UIState.isPdfExportEnabled,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.ClearReplyMessage, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				replyMessage: null,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.SetReplyMessage, (state, props) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				replyMessage: { ...props.message },
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.setSelectedParentMessageAction, (state, props) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				selectedParentId: props.messageId,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.setSelectChatMessageToShareAction, (state, { message }) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				selectedChatMessageToShare: message,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.clearSelectedChatMessageToShareAction, (state) => {
		const stateChanges = {
			...state,
			UIState: {
				...state.UIState,
				selectedChatMessageToShare: null,
			},
		};
		return stateChanges;
	}),
	on(fromMessageActions.upsertChatMediaMessagesAction, (state, { messages }) => {
		const stateChanges = {
			...state,
		};

		stateChanges.media = mediaAdapter.upsertMany(messages, {
			...stateChanges.media,
			loadingStatus: StoreLoadingStatus.LOADED,
		});
		return stateChanges;
	}),
	on(fromMessageActions.loadChatMediaByProjectAction, (state) => {
		return {
			...state,
			media: {
				...state.media,
				loadingStatus: StoreLoadingStatus.LOADING,
			},
		};
	}),

	on(fromMessageActions.updateInputCache, (state, { projectId, text }) => ({
		...state,
		chatInputCache: { ...state.chatInputCache, [projectId]: text },
	})),

	on(fromMessageActions.clearInputCache, (state, { projectId }) => {
		const chatInputCache = { ...state.chatInputCache };
		delete chatInputCache[projectId];
		return {
			...state,
			chatInputCache,
		};
	}),
);

// tslint:disable-next-line:typedef
export function MessageReducer(state = initialState, action: Action) {
	return messageReducer2(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const mediaMessageSelectors = mediaAdapter.getSelectors();
