import { wrap } from 'comlink';
import { VersionPollingWorker } from '@modules/core/workers/version-polling.worker';

export function buildVersionPollingWorker() {
	return wrap<typeof VersionPollingWorker>(
		new Worker(new URL('@modules/core/workers/version-polling.worker', import.meta.url), {
			type: 'module',
		}),
	);
}
