import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FILE_TYPE } from '@craftnote/shared-utils';
import { BreadcrumbsResizedEvent } from './directives/on-resize.directive';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { first, last, slice } from 'lodash';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

export interface BreadcrumbUI {
	readOnly?: boolean;
	name: string | '...';
	isEllipse?: boolean;
	onClick?: Function;
	id?: string;
	children?: Array<BreadcrumbUI>;
	hasChildren?: boolean;
}

@Component({
	selector: 'app-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {
	@Input()
	breadCrumbs: Array<BreadcrumbUI>;
	@Input()
	isDragAndDrop = false;
	@Input()
	isDragStarted = false;
	@Output()
	clicked = new EventEmitter<BreadcrumbUI>();
	@Output()
	onDropItem = new EventEmitter<string>();

	private charSizeRatio = 0.5833333333333334; // Mean value of the character ratio according to current font
	private breadcrumbSeparatorWidthRatio = 0.5846666666666667; // Breadcrumbs ratio without margin according to current font
	private ellipseWidthRatio = 0.9966666666666666; // ellipse width ratio according to according to current font
	canCloseEllipseMenu = true;

	FILE_TYPE = FILE_TYPE;
	breadCrumbs$ = new BehaviorSubject<Array<BreadcrumbUI>>(null);
	breadcrumbsDimensions$ = new BehaviorSubject<BreadcrumbsResizedEvent>(null);
	scalableBreadcrumbs$: Observable<Array<BreadcrumbUI>> = combineLatest([
		this.breadCrumbs$,
		this.breadcrumbsDimensions$,
	]).pipe(
		filter((breadCrumbs) => !!breadCrumbs[0] && !!breadCrumbs[1]),
		map(([breadCrumbs, dimensions]) => this.mapToScalableBreadCrumbs(breadCrumbs, dimensions)),
	);
	maxWidth$ = this.breadcrumbsDimensions$.pipe(
		filter((dimensions) => !!dimensions),
		map((dimensions) => {
			return (
				(dimensions.width -
					(dimensions.fontSize * this.ellipseWidthRatio +
						this.getOneBredCrumbWidthRatio(dimensions) * 2)) /
				2
			);
		}),
	);

	onResized(event: BreadcrumbsResizedEvent): void {
		this.breadcrumbsDimensions$.next(event);
	}

	handleClick(event: BreadcrumbUI): void {
		if (!event.readOnly) {
			this.clicked.emit(event);
		}
	}

	getBreadcrumbFontLength(name: string): number {
		const dimensions = this.breadcrumbsDimensions$.getValue();

		return this.calculateFontLength(name.length, dimensions);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['breadCrumbs']) {
			this.breadCrumbs$.next(changes['breadCrumbs'].currentValue);
		}
	}

	identifyBreadcrumb(item): string {
		return item.name;
	}

	mapToScalableBreadCrumbs(
		breadCrumbs: BreadcrumbUI[],
		dimensions: BreadcrumbsResizedEvent,
	): BreadcrumbUI[] {
		const breadcrumbsLength = breadCrumbs.length;
		if (breadcrumbsLength <= 2) {
			return breadCrumbs;
		}

		const firstBreadCrumb = first(breadCrumbs);
		const lastBreadCrumb = last(breadCrumbs);
		let minBreadCrumbsCharLength =
			this.calculateFontLength(
				firstBreadCrumb.name.length + lastBreadCrumb.name.length,
				dimensions,
			) + this.getOneBredCrumbWidthRatio(dimensions);

		if (minBreadCrumbsCharLength > dimensions.width) {
			const ellipse: BreadcrumbUI = {
				name: '...',
				isEllipse: true,
				children: slice(breadCrumbs, 1, breadcrumbsLength - 1),
			};

			return [firstBreadCrumb, ellipse, lastBreadCrumb];
		}

		const breadCrumbsToShow = [];

		for (let i = breadCrumbs.length - 2; i > 0; i--) {
			minBreadCrumbsCharLength =
				minBreadCrumbsCharLength +
				this.calculateFontLength(breadCrumbs[i].name.length, dimensions) +
				this.getOneBredCrumbWidthRatio(dimensions);

			if (minBreadCrumbsCharLength > dimensions.width) {
				const ellipse: BreadcrumbUI = {
					name: '...',
					isEllipse: true,
					children: slice(breadCrumbs, 1, i + 1),
				};

				breadCrumbsToShow.unshift(ellipse);
				break;
			}

			breadCrumbsToShow.unshift(breadCrumbs[i]);
		}

		return [firstBreadCrumb, ...breadCrumbsToShow, lastBreadCrumb];
	}

	mouseEnter(ellipseMenuTrigger: MatMenuTrigger): void {
		ellipseMenuTrigger.openMenu();
	}

	mouseLeave(ellipseMenuTrigger: MatMenuTrigger): void {
		setTimeout(() => {
			if (this.canCloseEllipseMenu) {
				ellipseMenuTrigger.closeMenu();
			}
		}, 50);
	}

	private calculateFontLength(fontLength: number, dimensions: BreadcrumbsResizedEvent): number {
		return fontLength * this.charSizeRatio * dimensions.fontSize;
	}

	private getOneBredCrumbWidthRatio(dimensions: BreadcrumbsResizedEvent): number {
		return dimensions.fontSize * this.breadcrumbSeparatorWidthRatio + 10;
	}
}
