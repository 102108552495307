import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, pluck, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as externalChatActions from '../actions/external-chat.actions';
import { selectLoggedInAuthState } from '../pipes/auth.pipes';
import { ExternalChatUserStateService } from '@injectables/services/external-chat-user-state.service';

@Injectable()
export class ExternalChatUserStateEffects implements OnInitEffects {
	constructor(
		private readonly externalChatUserStateService: ExternalChatUserStateService,
		private readonly store: Store<AppState>,
		private readonly actions$: Actions,
	) {}

	ngrxOnInitEffects(): Action {
		return externalChatActions.loadUserStateAction();
	}

	initUserStateEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(externalChatActions.loadUserStateAction),
			switchMap(() => this.store.pipe(selectLoggedInAuthState)),
			pluck('userId'),
			switchMap((userId) => this.externalChatUserStateService.watchUserState(userId)),
			map((userState) => {
				if (userState.changeType === 'created' || userState.changeType === 'updated') {
					return externalChatActions.setUserStateAction({ userState: userState.payload });
				}
				return externalChatActions.deleteUserStateAction();
			}),
		),
	);
}
