import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { map, of, switchMap } from 'rxjs';
import { clearCompanyMetaData, setCompanyMetaData } from '@store/actions/company-metadata.actions';
import { CompanyMetadataService } from '@injectables/services/company-metadata/company-metadata.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyMetadataEffects {
	constructor(
		private readonly store: Store,
		private readonly companyMetadataService: CompanyMetadataService,
	) {}

	loadMetadata = createEffect(() =>
		this.store.select(selectCompanyId).pipe(
			switchMap((id) => {
				if (!id) {
					return of(null);
				}
				return this.companyMetadataService.getMetadata(id);
			}),
			map((metadata) => {
				if (!metadata) {
					return clearCompanyMetaData();
				}
				return setCompanyMetaData(metadata);
			}),
		),
	);
}
