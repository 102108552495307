import { Invitation, InviteState, Member } from 'domain-entities';
import {
	InternalMember,
	InternalMemberInviteStatus,
} from '@work/project-members/entities/project-member';

export function mapToInternalMember(
	member: Member,
	invitations: Invitation[],
	projectMembers: Member[],
	userId: string,
): InternalMember {
	const internalMember: InternalMember = {
		entity: member,
		invitedStatus: InternalMemberInviteStatus.ADD,
	};

	if (member.id === userId) {
		internalMember.invitedStatus = InternalMemberInviteStatus.ADDED;
		return internalMember;
	}

	const { openInvitation, declinedInvitation } = getOpenAndDeclinedInvitationsOfMember(
		member,
		invitations,
	);

	switch (member.inviteState) {
		case InviteState.INVITED:
			internalMember.invitedStatus = InternalMemberInviteStatus.REMIND;
			internalMember.invitationId = openInvitation;
			break;
		case InviteState.DECLINED:
			internalMember.invitedStatus = InternalMemberInviteStatus.RESEND;
			internalMember.invitationId = declinedInvitation;
			break;
		case InviteState.ACCEPTED:
			if (
				projectMembers.find(
					(projectMember) =>
						projectMember.id === member.id && projectMember.inviteState === InviteState.ACCEPTED,
				)
			) {
				internalMember.invitedStatus = InternalMemberInviteStatus.REMOVE;
			}
			break;
		default:
			internalMember.invitedStatus = InternalMemberInviteStatus.ADDED;
	}

	return internalMember;
}

export function getOpenAndDeclinedInvitationsOfMember(
	member: Member,
	invitations: Invitation[],
): { openInvitation: string; declinedInvitation: string } {
	return invitations.reduce(
		(acc, currentInvitation) => {
			if (member.email !== currentInvitation.inviteeEmail) {
				return acc;
			}

			if (['open', 'read'].includes(currentInvitation.state) && !acc.openInvitation) {
				acc = { ...acc, openInvitation: currentInvitation.id };
			}

			if (['declined'].includes(currentInvitation.state) && !acc.declinedInvitation) {
				acc = { ...acc, declinedInvitation: currentInvitation.id };
			}

			return acc;
		},
		{ openInvitation: null, declinedInvitation: null },
	);
}
