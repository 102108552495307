import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { Member, MemberRole, Project, ProjectType } from 'domain-entities';
import { ProjectMemberDrawerSecondaryViewOptions } from '../project-member-drawer/project-member-drawer.component';
import {
	isAcceptedInternalMember,
	isExternalMember,
	isPendingExternalMember,
	isPendingProjectAdmin,
	isProjectAdmin,
} from '@shared/functions/project/project.functions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { selectUserId } from '@store/selectors/app.selectors';
import { selectProject } from '@store/selectors/projects.selectors';
import { ProjectMemberService } from '@injectables/services/project-member/project-member.service';
import { shareReplayOne } from '@craftnote/shared-utils';

@Component({
	selector: 'app-project-member-details',
	templateUrl: './project-member-details.component.html',
	styleUrls: ['./project-member-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMemberDetailsComponent implements OnInit, OnDestroy {
	@Input() memberEmail: string;
	@Output() openView = new EventEmitter<ProjectMemberDrawerSecondaryViewOptions>();

	member: Member;
	MemberRole = MemberRole;
	isLoading = false;
	destroy$ = new Subject();

	projectType = ProjectType;
	project: Project;
	projectId$ = this.store.select(selectActiveProject);
	project$ = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
	);

	currentProject$ = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
		shareReplayOne(),
	);
	currentProjectMembers$ = this.currentProject$.pipe(pluck('members'));
	currentLoggedInUserId$ = this.store.select(selectUserId);
	isUserProjectAdmin$ = combineLatest([
		this.currentProjectMembers$,
		this.currentLoggedInUserId$,
	]).pipe(
		map(([members, userId]) => {
			const projectMembers = Object.values(members || {});
			const projectMember = projectMembers.find((member) => member?.id === userId);
			return isProjectAdmin(projectMember);
		}),
		shareReplayOne(),
	);

	constructor(
		private readonly store: Store<AppState>,
		private readonly cd: ChangeDetectorRef,
		private readonly projectMemberService: ProjectMemberService,
	) {
		this.project$.subscribe((project) => {
			this.project = project;
		});
	}

	get isRoleEditable$(): Observable<boolean> {
		return combineLatest([this.isUserProjectAdmin$, this.currentLoggedInUserId$]).pipe(
			take(1),
			map(([isUserProjectAdmin, userId]) => {
				return (
					(this.isExternalMember || this.isPendingExternalMember) &&
					isUserProjectAdmin &&
					userId !== this.member?.id
				);
			}),
		);
	}

	get isInternalMember(): boolean {
		return isAcceptedInternalMember(this.member);
	}

	get isExternalMember(): boolean {
		return !this.member?.role || isExternalMember(this.member);
	}

	get isPendingExternalMember(): boolean {
		return isPendingExternalMember(this.member);
	}

	get isProjectAdmin(): boolean {
		return isProjectAdmin(this.member);
	}

	get isPendingProjectAdmin(): boolean {
		return isPendingProjectAdmin(this.member);
	}

	get isMemberDeletable$(): Observable<boolean> {
		return combineLatest([this.isUserProjectAdmin$, this.currentLoggedInUserId$]).pipe(
			take(1),
			map(([isUserProjectAdmin, userId]) => {
				return isUserProjectAdmin && userId !== this.member?.id;
			}),
		);
	}

	showLoader = () => {
		this.isLoading = true;
		this.cd.markForCheck();
	};

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	ngOnInit(): void {
		this.initMemberSubscription();
	}

	async changeRole(role: MemberRole): Promise<void> {
		const projectId = await this.projectId$.pipe(first()).toPromise();
		await this.projectMemberService.updateMemberRole(this.member, projectId, role);
	}

	openProjectListView(): void {
		this.openView.emit(ProjectMemberDrawerSecondaryViewOptions.INITIAL);
	}

	async removeMember(): Promise<void> {
		const projectId = await this.projectId$.pipe(first()).toPromise();
		const isRemoved = await this.projectMemberService.removeMember(
			this.member,
			projectId,
			this.showLoader,
		);
		this.isLoading = false;
		this.cd.markForCheck();

		if (isRemoved) {
			this.openProjectListView();
		}
	}

	async changeNote(): Promise<void> {
		if (!(await this.isUserProjectAdmin$.pipe(first()).toPromise())) {
			return;
		}
		const projectId = await this.projectId$.pipe(first()).toPromise();
		await this.projectMemberService.updateMemberNote(this.member, projectId);
	}

	async changeFunction(): Promise<void> {
		if (!(await this.isUserProjectAdmin$.pipe(first()).toPromise())) {
			return;
		}
		const projectId = await this.projectId$.pipe(first()).toPromise();
		await this.projectMemberService.updateMemberFunction(this.member, projectId);
	}

	private initMemberSubscription(): void {
		this.currentProjectMembers$.pipe(takeUntil(this.destroy$)).subscribe((members) => {
			const currentMember = (members || {})[this.memberEmail];
			if (!currentMember) {
				this.openProjectListView();
				return;
			}

			this.member = currentMember;
		});
	}
}
