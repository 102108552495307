import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Search } from '@shared/models/search.model';
import { Observable } from 'rxjs';
import { SearchResult } from '@shared/models/search-result.model';
import { map } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private http: HttpClient) {}

	chatSearch(search: Search): Observable<SearchResult> {
		let types: string;
		if (search.type && search.type.length !== 0) {
			types = '';
			for (const type of search.type) {
				types += type + ',';
			}

			types = types.slice(0, -1);
		}

		const params = {
			projectid: search.projectId,
			memberid: search.member ? search.member.id : undefined,
			text: search.text,
			from: search.from ? moment(search.from).unix() : search.from,
			to: search.to ? moment(search.to).unix() : search.to,
			type: types,
		};

		// json.parse(json.stringify) in order to remove undefined fields.
		const options = {
			headers: this.createHeaders(),
			params: JSON.parse(JSON.stringify(params)),
		};

		return this.http
			.get<SearchResult>(environment.baseUrl + '/searchchat', options)
			.pipe(map((json) => new SearchResult().deserialize(json)));
	}

	createHeaders(): HttpHeaders {
		return new HttpHeaders().set('Content-Type', 'application/json');
	}
}
