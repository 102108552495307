<div class="tagging-wrap">
	<div class="form-group container" style="margin-top: 15px">
		<em (click)="closeDialog()" class="material-icons float-left" style="cursor: pointer" data-cy="chat-tag-close">close</em>
		<h1 class="tagging-headline text-center" data-cy="chat-tag-header">{{ 'chat.tags_modal_title' | translate }}</h1>

		<div>
			<div [ngClass]="chatMessageService.getMessageClass(message)">
				<div
					*ngIf="
						!message.deleted &&
						message.content !== MessageType.NOTSET &&
						checkMessageType(message, MessageType.IMAGE, MessageType.AUDIO, MessageType.DOCUMENT, MessageType.VIDEO)
					"
				>
					{{ getFile(message) }}
				</div>

				<div *ngIf="message.deleted" class="text-message" data-cy="chat-delete-message-info">
					{{ 'chat.message.deleted' | translate: chatMessageService.getTranslateValues(message.deletedTimestamp) }}
				</div>

				<div
					(click)="openInfoBox()"
					*ngIf="message.messageType === MessageType.STATUS && !message.deleted"
					style="cursor: pointer"
					data-cy="chat-notes-message-info"
				>
					<span>
						<img src="assets/images/icons/note.svg" width="10px" alt="note" />
						<span>{{
							'chat.info.updated'
								| translate
									: chatMessageService.getInfoTranslateValues(
											message.timestamp,
											message.author,
											chatMessageService.getCroppedStatusMessage(message.content)
									  )
						}}</span>
					</span>
				</div>

				<div *ngIf="message.messageType === MessageType.TEXT && !message.deleted">
					<span>{{ message.content }}</span>
				</div>

				<div *ngIf="message.messageType === MessageType.IMAGE && !message.deleted" class="pointer thumb">
					<img
						class="img-fluid"
						*ngIf="files[message.id] && message.content !== MessageType.NOTSET"
						src="{{ files[message.id] }}"
						alt="preview"
					/>
					<mat-spinner color="accent" *ngIf="!files[message.id]" [ngClass]="chatMessageService.getCircleClass(message)"></mat-spinner>
				</div>

				<div *ngIf="message.messageType === MessageType.VIDEO && !message.deleted" class="pointer video-message">
					<div class="thumb">
						<img
							class="img-fluid"
							*ngIf="files[message.id] && message.content !== MessageType.NOTSET"
							src="{{ files[message.id] }}"
							alt="preview"
						/>
						<mat-spinner color="accent" *ngIf="!files[message.id]" [ngClass]="chatMessageService.getCircleClass(message)"></mat-spinner>
					</div>
					<div class="video">
						<em *ngIf="files[message.id]" class="material-icons play-button">play_circle_outline</em>
					</div>
				</div>

				<div
					*ngIf="
						message.messageType === MessageType.DOCUMENT && !message.deleted && chatMessageService.getFileType(message.content) === 'pdf'
					"
					class="document pointer"
				>
					<div>
						<div class="document-text">
							{{ chatMessageService.getFileType(message.content).toUpperCase() }}
						</div>
					</div>
					<span>{{ message.content }}</span>
				</div>

				<div
					*ngIf="
						message.messageType === MessageType.DOCUMENT && !message.deleted && chatMessageService.getFileType(message.content) !== 'pdf'
					"
					class="document pointer"
				>
					<div>
						<div class="document-text">
							{{ chatMessageService.getFileType(message.content).toUpperCase() }}
						</div>
					</div>
					<span>{{ message.content }}</span>
				</div>

				<div *ngIf="message.messageType === MessageType.AUDIO && !message.deleted">
					<mat-spinner color="accent" *ngIf="!files[message.id]" [ngClass]="chatMessageService.getCircleClass(message)"></mat-spinner>
					<vg-player *ngIf="files[message.id] && message.content !== MessageType.NOTSET" class="audio-player">
						<vg-controls>
							<vg-play-pause></vg-play-pause>

							<vg-time-display vgFormat="mm:ss" vgProperty="current"></vg-time-display>
							<vg-time-display vgFormat="mm:ss" vgProperty="total"></vg-time-display>

							<vg-scrub-bar>
								<vg-scrub-bar-current-time></vg-scrub-bar-current-time>
								<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
							</vg-scrub-bar>
						</vg-controls>

						<audio #media [vgMedia]="media" id="myAudio" preload="auto">
							<source src="{{ files[message.id] }}" type="audio/aac" />
						</audio>
					</vg-player>
				</div>
			</div>
		</div>

		<div class="clearfix"></div>

		<div class="tags-list">
			<div *ngFor="let tag of tags; let i = index" class="tag">
				{{ tag }}
				<mat-icon [inline]="true" (click)="removeTag(i)" *ngIf="company && canEditTag(message)" class="cancel-icon pl-1">cancel </mat-icon>
			</div>
		</div>

		<form (submit)="addTag($event)" *ngIf="company && canEditTag(message)">
			<div class="new-tag-input-wrap">
				<input
					(input)="filterTags()"
					[(ngModel)]="newTagText"
					[ngModelOptions]="{ standalone: true }"
					class="new-tag-input"
					matInput
					trim="blur"
					placeholder="{{ 'chat.enter_tag' | translate }}"
					type="text"
				/>
				<button class="add-btn" type="submit">
					<mat-icon [inline]="true" color="accent" class="add-icon">add_circle</mat-icon>
				</button>
			</div>
		</form>

		<div class="suggested-tags-list">
			<div *ngIf="company && canEditTag(message)" class="suggested-tags-list-header">
				{{ 'chat.suggested_tags' | translate }}
			</div>
			<ng-container *ngIf="company && canEditTag(message)">
				<div (click)="addSuggestedTag(tag)" *ngFor="let tag of suggestedTags" class="suggested-tags-list-item">
					{{ tag.name }}
					<span (click)="removeSuggestedTag($event, tag)" *ngIf="isOwnerOrSupervisor(project)" class="float-right pointer member-delete">
						<img src="assets/images/icons/trash-can.svg" alt="delete" />
					</span>
				</div>
			</ng-container>
		</div>
	</div>
</div>
