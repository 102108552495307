<div class="mat-display-2 mb-3">{{ 'global.duplicate.verb' | translate }}</div>
<mat-dialog-content>
	<div class="dialog-content">
		<p class="mb-3">
			{{ 'project.actions.duplicate.description' | translate: { name: project.name } }}
		</p>
		<p>
			<mat-slide-toggle [(ngModel)]="shouldCopyMembers" class="pointer">{{
				'project.actions.duplicate.copy_members' | translate
			}}</mat-slide-toggle>
		</p>
		<p>
			<mat-slide-toggle *ngIf="project.projectType !== ProjectType.FOLDER" [(ngModel)]="shouldCopyFolders" class="pointer">{{
				'project.actions.duplicate.copy_files' | translate
			}}</mat-slide-toggle>
		</p>
		<p>
			<mat-slide-toggle *ngIf="project.projectType !== ProjectType.FOLDER" [(ngModel)]="shouldDuplicateTasks" class="pointer">{{
				'project.actions.duplicate.tasks' | translate
			}}</mat-slide-toggle>
		</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
	<button [disabled]="isLoading" [mat-dialog-close]="false" mat-stroked-button>
		{{ 'button.cancel' | translate }}
	</button>
	<button (click)="duplicateFolderOrProject()" color="accent" [disabled]="isLoading" [loading]="isLoading" mat-flat-button>
		{{ 'global.duplicate.verb' | translate }}
	</button>
</mat-dialog-actions>
