import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Project } from 'domain-entities';
import { Store } from '@ngrx/store';
import { hideAllFailedUploadsFromProjectListAction } from '@store/actions/file-upload.actions';

@Component({
	selector: 'app-failed-uploads-nudge',
	templateUrl: './failed-uploads-nudge.component.html',
	styleUrls: ['./failed-uploads-nudge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FailedUploadsNudgeComponent {
	@Input() projectsWithFailedUploads: Project[] = [];

	private readonly store = inject(Store);

	async closeNudge(): Promise<void> {
		this.store.dispatch(hideAllFailedUploadsFromProjectListAction());
	}
}
