<section [ngClass]="mainWrapperClasses" class="project-task-edit-section d-flex flex-column h-100 position-relative overflow-hidden">
	<div *ngIf="taskIsInvalid" class="p-3 h-100 d-flex flex-column">
		<div class="d-flex align-items-center">
			<button class="mr-3" mat-icon-button [routerLink]="'../../'">
				<mat-icon [size]="30">clear</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				{{ 'task.edit' | translate }}
			</div>
		</div>

		<div class="flex-1 d-flex align-items-center justify-content-center flex-column">
			<mat-icon [size]="40" class="mb-1">error_outline</mat-icon>
			<p class="m-0">{{ 'project-task-edit.task-not-found' | translate }}</p>
			<button class="mr-3" mat-icon-button [routerLink]="'../../'">
				{{ 'button.ok' | translate }}
			</button>
		</div>
	</div>
	<div *ngIf="taskUI$ | async as taskUI">
		<div class="main-view">
			<app-task-edit-details
				(cancelEdit)="cancelEdit()"
				(deleteTask)="deleteTask($event)"
				(openWorkingHours)="openWorkingHours()"
				(openTaskComments)="openTaskComments()"
				(openTaskFiles)="openTaskFiles()"
				[taskUI]="taskUI"
			>
			</app-task-edit-details>
		</div>

		<div class="secondary-view">
			<app-task-edit-working-hours
				[class.d-none]="mainWrapperClasses.indexOf('working-hours-active') === -1"
				(openTaskEditDetails)="openTaskEditDetails()"
				(openWorkingHoursUpdate)="openWorkingHoursUpdate($event)"
				[projectId]="taskUI?.task.projectId"
				[taskId]="taskUI?.task.id"
			>
			</app-task-edit-working-hours>

			<app-task-edit-files
				[class.d-none]="mainWrapperClasses.indexOf('task-edit-files-active') === -1"
				(openTaskEditDetails)="openTaskEditDetails()"
				[projectId]="taskUI.task.projectId"
				[taskId]="taskUI?.task.id"
			>
			</app-task-edit-files>

			<app-task-edit-comments
				[class.d-none]="mainWrapperClasses.indexOf('task-comments-active') === -1"
				(openTaskEditDetails)="openTaskEditDetails()"
				[projectMembers]="taskUI?.project?.members"
				[projectId]="taskUI?.task.projectId"
				[taskId]="taskUI?.task.id"
			>
			</app-task-edit-comments>
		</div>

		<div class="tertiary-view">
			<app-task-edit-working-hours-update
				(openWorkingHours)="openWorkingHours()"
				[class.d-none]="mainWrapperClasses.indexOf('working-hours-update-active') === -1"
			>
			</app-task-edit-working-hours-update>
		</div>
	</div>
</section>
