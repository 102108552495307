<ng-container *ngIf="projectItemData$ | async as data">
	<div class="d-flex flex-column justify-content-start">
		<app-project-item-new
			#projectItem
			[project]="data.project"
			[selected]="selected"
			[showParentHint]="showParentHint"
			[showUnreadDot]="data.project && (isProjectUnread(data.project) | async)"
			[context]="context"
			[isOpenFolderCard]="isOpenFolderCard"
			[userRole]="getUserRole(data.project)"
			[projectTime]="projectTime"
			[isCurrentUserInternalAdminOfProject]="isCurrentUserInternalAdminOfProject(data.project) | async"
			[isCurrentUserArchivedInProject]="isCurrentUserArchivedInProject(data.project) | async"
			[isExternalMemberOfProject]="isExternalMemberOfProject$(data.project) | async"
			[projectStatus]="getProjectStatus(data.project, projectStatus$ | async)"
			(onAction)="performProjectItemAction($event)"
			(onGoBack)="backClicked.emit()"
			(menuOpened)="menuOpened.emit($event)"
			[childProjectsCount]="(getChildProjects(data.project) | async).length"
			[isLastItem]="isLastItem"
			[isChatMessagesMuted]="isChatMessagesMuted"
		>
		</app-project-item-new>
	</div>
</ng-container>
