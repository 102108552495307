interface OmitErrorOptions {
	identifier?: string; // A string to identify where its coming from - just like "FirebaseError" label
	isAsync?: boolean; // to define weather calling from promise or method
}

export function OmitErrors(options?: OmitErrorOptions) {
	return function (_prototype: object, _propertyKey: string, descriptor: PropertyDescriptor) {
		const targetMethod = descriptor.value;

		if (typeof targetMethod !== 'function') {
			console.error(`${options?.identifier || ''} : Decorator only works for methods`);
			return descriptor;
		}

		if (options?.isAsync) {
			descriptor.value = function (...args) {
				return targetMethod
					.apply(this, args)
					.catch((error) => console.error(options?.identifier || '', error));
			};
		} else {
			descriptor.value = function (...args) {
				try {
					return targetMethod.apply(this, args);
				} catch (e) {
					console.error(options?.identifier || '', e);
				}
			};
		}
	};
}
