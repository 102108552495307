import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromProfileLimitsActions from '../actions/profile-limits.actions';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { selectCompanyMembersAcceptedArray, selectUserId } from '../selectors/app.selectors';
import { ProfileLimit } from 'domain-entities';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';

@Injectable()
export class CompanyMembersProfileLimitsEffect implements OnInitEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly profileLimitsService: ProfileLimitsService,
	) {}

	companyMembersProfileLimits$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProfileLimitsActions.loadCompanyMembersProfileLimits),
			switchMap(() =>
				combineLatest([
					this.store.select(selectUserId),
					this.store.select(selectCompanyMembersAcceptedArray),
				]),
			),
			switchMap(([userId, members]) => {
				if (!userId) {
					return of([] as ProfileLimit[]);
				}
				return combineLatest(this.getProfileLimitsMapped(members));
			}),
			map((limits) => limits.filter(Boolean)),
			map((members) =>
				fromProfileLimitsActions.loadCompanyMembersProfileLimitsSuccess({ payload: members }),
			),
		),
	);

	private getProfileLimitsMapped(members): Observable<ProfileLimit>[] {
		return members.map((m) =>
			this.getProfileLimits(m.id).pipe(
				catchError((_) => {
					console.error(`getProfileLimits failing for member id: ${m.id}`);
					return of(null as ProfileLimit);
				}),
			),
		);
	}

	private getProfileLimits(id: string): Observable<ProfileLimit> {
		return this.profileLimitsService.getProfileLimitsById(id);
	}

	ngrxOnInitEffects(): Action {
		return fromProfileLimitsActions.loadCompanyMembersProfileLimits();
	}
}
