<div class="export-dialog d-flex flex-column h-100 p-3 overflow-hidden">
	<p class="mat-display-2 mb-3">{{ 'exportDetailsModal.title' | translate }}</p>
	<div class="flex-grow-1 rounded-small border overflow-y-auto">
		<div *ngFor="let exportEntity of exports">
			<ng-container *ngTemplateOutlet="exportRow; context: { $implicit: exportEntity }"></ng-container>
		</div>
	</div>
	<div class="d-flex mt-4 justify-content-end">
		<button (click)="onClose()" mat-stroked-button>
			{{ 'exportDetailsModal.hide' | translate }}
		</button>
	</div>
</div>

<ng-template #exportRow let-exportEntity>
	<div class="d-flex p-2 border justify-content-between align-items-center">
		<div class="d-flex align-items-center">
			<div class="export-dialog__status-indicator d-flex justify-content-center align-items-center px-2">
				<ng-container *ngIf="!isFailed(exportEntity); then spinner; else failed"></ng-container>
			</div>
			<div>
				<span class="d-block mat-body-2">{{
					exportEntity.fileName ? exportEntity.fileName : ('exportDetailsModal.preparingName' | translate)
				}}</span>
				<span
					class="d-block export-dialog__status mat-small"
					[ngClass]="{ 'export-dialog__status__failed': isFailed(exportEntity) }"
					data-cy="export-preparing"
					>{{ (isFailed(exportEntity) ? 'exportDetailsModal.failed' : 'exportDetailsModal.preparing') | translate }}</span
				>
			</div>
		</div>
		<mat-icon (click)="cancelExport.emit(exportEntity.id)" class="pointer">close</mat-icon>
	</div>
</ng-template>

<ng-template #spinner>
	<mat-spinner color="accent" class="p-3" diameter="26"></mat-spinner>
</ng-template>

<ng-template #failed>
	<mat-icon>error_outline</mat-icon>
</ng-template>
