import * as fromProfileLimitsReducers from '../reducers/profile-limits.reducer';
import * as fromProfileLimitsStates from '../state/profile-limits.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileLimit } from 'domain-entities';
import { Dictionary } from '@ngrx/entity';

type keyType = keyof ProfileLimit;
const { selectEntities, selectAll } =
	fromProfileLimitsReducers.companyMembersProfileLimitsEntityAdapter.getSelectors();

export const profileLimitsSelector =
	createFeatureSelector<fromProfileLimitsStates.ProfileLimitsState>('profileLimits');

export const userProfileLimitsState = createSelector(
	profileLimitsSelector,
	(state) => state.currentUserProfileLimits,
);

export const currentUserProfileLimitsSelector = createSelector(
	profileLimitsSelector,
	(state) => state.currentUserProfileLimits,
);

export const selectCurrentUserProfileLimits = createSelector(
	currentUserProfileLimitsSelector,
	(state) => state.limits,
);

export const selectCurrentUserProfileLimitsLoaded = createSelector(
	currentUserProfileLimitsSelector,
	(state) => state.loaded,
);

export const selectHasCurrentUserProfileLimitsFactory = (limit: keyof ProfileLimit) =>
	createSelector(
		selectCurrentUserProfileLimits,
		(limits: ProfileLimit) => !limits || !!limits[limit],
	);

export const selectHasCurrentUserProfileLimits = createSelector(
	selectCurrentUserProfileLimits,
	(limits: ProfileLimit, props: { limit: keyType }) => !limits || !!limits[props.limit],
);

export const selectUserProfileLimitsSelector = createSelector(
	userProfileLimitsState,
	(state) => state.limits,
);

export const companyMembersProfileLimitsSelector = createSelector(
	profileLimitsSelector,
	(state) => state.companyMembersProfileLimits,
);

export const companyMembersProfileLimitsLoadedSelector = createSelector(
	companyMembersProfileLimitsSelector,
	(state) => state.loaded,
);

export const selectAllCompanyMembersProfileLimits = createSelector(
	companyMembersProfileLimitsSelector,
	selectAll,
);

export const selectEntitiesCompanyMembersProfileLimits = createSelector(
	companyMembersProfileLimitsSelector,
	selectEntities,
);

export const selectCurrentUserProjectInvitationsRemaining = createSelector(
	selectCurrentUserProfileLimits,
	selectCurrentUserProfileLimitsLoaded,
	(limits: ProfileLimit, loaded: boolean) => {
		if (loaded === false) {
			return undefined;
		}

		// If the limit is not set, then the user has unlimited invitations
		const projectInvitationsRemaining = limits?.projectInvitationsRemaining ?? -1;

		return projectInvitationsRemaining === -1
			? Number.POSITIVE_INFINITY
			: projectInvitationsRemaining;
	},
);

export const selectIsCurrentUserHasProjectInvitationsRemaining = () =>
	createSelector(selectCurrentUserProjectInvitationsRemaining, (projectRemainingCount: number) => {
		if (projectRemainingCount === undefined) {
			return undefined;
		}

		return projectRemainingCount !== 0;
	});

export const selectIsMemberHasProjectInvitationsRemaining = (memberId: string) =>
	createSelector(
		selectMemberProjectInvitationsRemaining(memberId),
		(invitationsRemaining) => invitationsRemaining > 0,
	);

export const selectMemberProjectInvitationsRemaining = (memberId: string) =>
	createSelector(
		selectEntitiesCompanyMembersProfileLimits,
		companyMembersProfileLimitsLoadedSelector,
		(limits: Dictionary<ProfileLimit>, loaded: boolean) => {
			if (loaded === false) {
				return undefined;
			}

			const projectInvitationsRemaining = limits[memberId]?.projectInvitationsRemaining ?? -1;

			// If the limit is not set, then the user has unlimited invitations
			return projectInvitationsRemaining === -1
				? Number.POSITIVE_INFINITY
				: projectInvitationsRemaining;
		},
	);
