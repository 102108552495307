import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
	providedIn: 'root',
})
export class SentryService {
	personalizeSentry(uid: string, email: string): void {
		Sentry.configureScope((scope) => {
			scope.setUser({
				id: uid,
				email: email,
			});
		});
	}
}
