import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RemoteConfig } from '../../services/remote-config/remote-config.service';

@Injectable({ providedIn: 'root' })
export class RemoteConfigGuard {
	constructor(private readonly remoteConfig: RemoteConfig, private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		let remoteConfigNameList = [];

		if (Array.isArray(route.data.remoteConfigName)) {
			remoteConfigNameList = route.data.remoteConfigName;
		} else {
			remoteConfigNameList = [route.data.remoteConfigName];
		}

		const remoteConfigActive = await this.remoteConfig.getValueAsyncList(remoteConfigNameList);

		if (remoteConfigActive.every(Boolean)) {
			return true;
		}
		if (route.data.redirectUnauthorisedTo) {
			return this.router.parseUrl(route.data.redirectUnauthorisedTo);
		}
		return false;
	}
}
