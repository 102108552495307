import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { TaskTime } from 'domain-entities';
import { map, startWith, switchMap, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { isEqual, keyBy } from 'lodash';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { TaskTimeEditFormService } from '@modules/shared/components/time-tracking-add-edit/components/task-time-edit-panel/task-time-edit-form/task-time-edit-form.service';
import moment from 'moment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { combineDateTime } from '@craftnote/shared-utils';
import {
	isOwnerOfCompany,
	selectAuthState,
	selectCompanyMembersAcceptedArray,
} from '@store/selectors/app.selectors';
import { combineLatest, Subject } from 'rxjs';
import { selectCompanyProjectsEntities } from '@store/selectors/company-projects.selectors';
import {
	selectAllActiveProjectsEntities,
	selectProjectAcceptedMembersEntities,
} from '@store/selectors/projects.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { shareReplayOne } from '@craftnote/shared-utils';

interface TaskTimeEditForm {
	startDate: Date;
	startTime: Date;
	endDate: Date;
	endTime: Date;
	taskName: string;
	assignee: string[];
	projectId: string;
}

@Component({
	selector: 'app-task-time-edit-form',
	templateUrl: './task-time-edit-form.component.html',
	styleUrls: ['./task-time-edit-form.component.scss'],
	providers: [
		TaskTimeEditFormService,
		{
			provide: DateAdapter, // FIXME:: remove me once moment adapter is removed from dashboard module
			useClass: NativeDateAdapter,
		},
	],
})
export class TaskTimeEditFormComponent implements OnChanges, OnInit, OnDestroy {
	@Input() taskTime: TaskTime;
	@Input() taskName: string;
	@Input() taskProjectId: string;
	@Output() close = new EventEmitter<void>();
	taskTimeUpdated: TaskTime;
	taskTimeForm = this.fb.group({
		taskName: [{ value: '', disabled: true }],
		startDate: [[Validators.required]],
		startTime: [[Validators.required]],
		endDate: [[Validators.required]],
		endTime: [[Validators.required]],
		assignee: [[Validators.required]],
		projectId: [[Validators.required]],
	});
	projects$ = this.store.select(isOwnerOfCompany).pipe(
		switchMap((isOwner) =>
			isOwner
				? this.store.select(selectCompanyProjectsEntities)
				: this.store.select(selectAllActiveProjectsEntities),
		),
		shareReplayOne(),
	);
	projectMembers$ = this.taskTimeForm.get('projectId').valueChanges.pipe(
		switchMap((projectId) =>
			combineLatest([
				this.store.select(selectProjectAcceptedMembersEntities, { projectId }),
				this.store.select(selectAuthState),
			]),
		),
		map(([memberEntities, authState]) => {
			memberEntities[authState.userId] = Object.assign({ id: authState.userId }, authState.user);
			return memberEntities;
		}),
		startWith({}),
		shareReplayOne(),
	);
	// If assignee is not found in project members stream then use this stream
	fallBackAssignees$ = this.taskTimeForm.get('projectId').valueChanges.pipe(
		switchMapTo(this.store.select(selectCompanyMembersAcceptedArray)),
		map((companyMembers) => ({
			[this.taskTime.userId]: { name: this.taskTime.userName || '' },
			...keyBy(companyMembers, 'id'),
		})),
		shareReplayOne(),
	);
	private destroy$ = new Subject();

	constructor(
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly taskTimeEditFormService: TaskTimeEditFormService,
		private readonly fb: UntypedFormBuilder,
		private readonly store: Store<AppState>,
	) {}

	get isComponentReady(): boolean {
		return this.taskTime && this.taskTimeUpdated && !!this.taskTimeForm;
	}

	get startEndDuration(): { hr: number; min: number } {
		const duration = moment(this.endDateTime).diff(moment(this.startDateTime), 'seconds');
		return {
			hr: Math.floor(duration / 3600),
			min: Math.floor((duration % 3600) / 60),
		};
	}

	get isFormValueChanged(): boolean {
		return !isEqual(this.taskTime, this.taskTimeUpdated);
	}

	get projectId(): string {
		return this.taskTimeForm.get('projectId').value;
	}

	get startTime(): Date {
		return this.taskTimeForm.get('startTime').value;
	}

	get endTime(): Date {
		return this.taskTimeForm.get('endTime').value;
	}

	get startDate(): Date {
		return this.taskTimeForm.get('startDate').value;
	}

	get endDate(): Date {
		return this.taskTimeForm.get('endDate').value;
	}

	get startDateTime(): Date {
		return combineDateTime(this.startDate, this.startTime);
	}

	get endDateTime(): Date {
		return combineDateTime(this.endDate, this.endTime);
	}

	get isFormValid(): boolean {
		return this.taskTimeForm.valid && this.isFormValueChanged && this.isDateTimeValid;
	}

	get isDateTimeValid(): boolean {
		return moment(this.startDateTime).isBefore(this.endDateTime);
	}

	ngOnInit(): void {
		this.taskTimeForm.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (formValue: TaskTimeEditForm) => {
				await this.formValueChanges(formValue);
			});
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.taskTime?.currentValue) {
			const newFormValue = await this.fromEntityToFormValue(changes.taskTime.currentValue);
			this.taskTimeForm.setValue(newFormValue);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	async onCancel(): Promise<void> {
		if (this.isFormValueChanged) {
			const result = await this.confirmDialogService
				.open({
					title: this.translateService.instant('task-time-edit-form.dialog.title'),
					message: this.translateService.instant('task-time-edit-form.dialog.message'),
					primaryButtonColor: 'accent',
					showCrossBtn: false,
					primaryButtonText: this.translateService.instant(
						'task-time-edit-form.dialog.primaryButtonText',
					),
					secondaryButtonText: this.translateService.instant(
						'task-time-edit-form.dialog.secondaryButtonText',
					),
				})
				.afterClosed()
				.pipe(take(1))
				.toPromise();
			if (result) {
				return;
			}
		}
		this.close.emit();
	}

	onChangeStartDate(event: MatDatepickerInputEvent<Date>): void {
		this.taskTimeForm.patchValue(
			{
				startTime: combineDateTime(event.value, this.startTime),
			},
			{ emitEvent: false },
		);
	}

	onChangeEndDate(event: MatDatepickerInputEvent<Date>): void {
		this.taskTimeForm.patchValue(
			{
				endTime: combineDateTime(event.value, this.endTime),
			},
			{ emitEvent: false },
		);
	}

	async deleteTaskTime(taskTime: TaskTime): Promise<void> {
		const result = await this.confirmDialogService
			.open({
				title: this.translateService.instant('task-time-edit-form.delete-dialog.title'),
				message: this.translateService.instant('task-time-edit-form.delete-dialog.message'),
				primaryButtonColor: 'warn',
				showCrossBtn: false,
				primaryButtonText: this.translateService.instant(
					'task-time-edit-form.delete-dialog.primaryButtonText',
				),
				secondaryButtonText: this.translateService.instant(
					'task-time-edit-form.delete-dialog.secondaryButtonText',
				),
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();
		if (!result) {
			return;
		}

		await this.taskTimeEditFormService.deleteTaskTime(taskTime.id);
		this.close.emit();
	}

	async submitForm(taskTime: TaskTime): Promise<void> {
		await this.taskTimeEditFormService.updateTaskTime(taskTime);
		this.close.emit();
	}

	private async fromEntityToFormValue(taskTime: TaskTime): Promise<TaskTimeEditForm> {
		return {
			taskName: this.taskName,
			startDate: moment.unix(taskTime.startTime).toDate(),
			startTime: moment.unix(taskTime.startTime).toDate(),
			endDate: moment.unix(taskTime.endTime).toDate(),
			endTime: moment.unix(taskTime.endTime).toDate(),
			assignee: [taskTime.userId],
			projectId: this.taskProjectId,
		};
	}

	private async fromFormValueToEntity(formValue: TaskTimeEditForm): Promise<TaskTime> {
		const taskTime = this.taskTime;

		return {
			id: taskTime.id,
			taskId: taskTime.taskId,
			startTime: moment(combineDateTime(formValue.startDate, formValue.startTime)).unix(),
			endTime: moment(combineDateTime(formValue.endDate, formValue.endTime)).unix(),
			userId: taskTime.userId,
			userName: taskTime.userName,
			companyId: taskTime.companyId,
		};
	}

	private async formValueChanges(formValue: TaskTimeEditForm): Promise<void> {
		this.taskTimeUpdated = await this.fromFormValueToEntity(formValue);
	}
}
