import { Component } from '@angular/core';
import { CompanyEmployeeService } from '@injectables/services/company-employee.service';

@Component({
	selector: 'app-project-internal-invite-member',
	templateUrl: './project-internal-invite-member.component.html',
	styleUrls: ['./project-internal-invite-member.component.scss'],
})
export class ProjectInternalInviteMemberComponent {
	constructor(private readonly companyEmployeeService: CompanyEmployeeService) {}

	async openMemberInviteDialog(): Promise<void> {
		return this.companyEmployeeService.openAddEmployeeDialog('project-members');
	}
}
