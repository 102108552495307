import { Pipe, PipeTransform } from '@angular/core';
import { CompanyMember, getMemberFullName } from '@craftnote/shared-utils';
import { Member } from 'domain-entities';

@Pipe({
	name: 'memberFullName',
})
export class MemberFullNamePipe implements PipeTransform {
	transform(
		member: Member | CompanyMember,
		fallBackKey?: keyof Member,
		isLastNameFirst = true,
		fallBackString: string = '',
	): string {
		return getMemberFullName(member, fallBackString, fallBackKey, isLastNameFirst);
	}
}
