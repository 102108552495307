import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import firebase from 'firebase/compat/app';
import { isNil } from 'lodash';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class RemoteConfig {
	private activated$: Promise<boolean>;
	private config: { [key: string]: firebase.remoteConfig.Value } = {};

	constructor(
		private remoteConfig: AngularFireRemoteConfig,
		private readonly localStorageService: LocalStorageService,
	) {
		this.activated$ = this.remoteConfig.fetchAndActivate();
		void this.initConfig();
	}

	public getValue(key: string): number {
		const localOverride = this.getLocalOverride(key);
		if (!isNil(localOverride)) {
			return localOverride as number;
		}
		return this.config[key]?.asNumber();
	}

	public getConfig(key: string): string {
		const localOverride = this.getLocalOverride(key);
		if (!isNil(localOverride)) {
			return localOverride as string;
		}
		return this.config[key]?.asString();
	}

	public getBoolean(key: string): boolean {
		const localOverride = this.getLocalOverride(key);
		if (!isNil(localOverride)) {
			return localOverride as boolean;
		}
		return this.config[key]?.asBoolean();
	}

	public async getValueAsync(key: string): Promise<number> {
		await this.initConfig();
		return this.getValue(key);
	}

	public async getBooleanAsync(key: string): Promise<boolean> {
		await this.initConfig();
		return this.getBoolean(key);
	}

	async getConfigAsync(key: string): Promise<string> {
		await this.initConfig();
		return this.getConfig(key);
	}

	public async getValueAsyncList(configs: string[]): Promise<boolean[]> {
		const result = await Promise.all(configs.map((c) => this.getValueAsync(c)));
		return result.map((value) => !!value);
	}

	private async initConfig(): Promise<void> {
		await this.activated$;
		this.config = await this.remoteConfig.getAll();
	}

	private getLocalOverride(key: string): unknown | undefined {
		return this.localStorageService.getRemoteConfig(key);
	}
}
