<div class="d-flex align-items-center project-members-list-item">
	<mt-named-icon [maxChars]="2" [name]="member | memberFullName: 'email':true" class="my-2 mr-2" color="#eae7e699">
		<div class="icon-before-right icon-before color-primary" *ngIf="member?.inviteState === InviteState.INVITED">
			<mat-icon class="align-top">help_outline</mat-icon>
		</div>
		<div class="icon-before-right icon-before" *ngIf="inviteStatus === InternalMemberInviteStatus.RESEND">
			<mat-icon class="align-top transparent">error_outline</mat-icon>
		</div>
	</mt-named-icon>
	<div class="flex-grow-1 text-truncate p-1">
		<div class="text-truncate font-weight-bold" data-cy="member-overview-name">
			{{ member | memberFullName: 'email':true }}
			<span *ngIf="(currentLoggedInUserId$ | async) === member?.id">({{ 'projectMembers.member.you' | translate }} )</span>
		</div>
		<div>
			<span
				*ngIf="member?.inviteState !== InviteState.INVITED && inviteStatus !== InternalMemberInviteStatus.RESEND"
				class="mat-small function-text"
				data-cy="member-overview-role"
				>{{ member.jobTitle || '-' }}</span
			>
			<span class="color-primary mat-small" *ngIf="member?.inviteState === InviteState.INVITED">{{
				'projectMembers.member.pendingInvitation' | translate
			}}</span>
			<span class="mat-small function-text" *ngIf="inviteStatus === InternalMemberInviteStatus.RESEND">{{
				'projectMembers.member.declinedInvitation' | translate
			}}</span>
		</div>
	</div>
	<div class="flex-shrink-0">
		<ng-container *ngIf="!isInviteStatusNil; then withInviteStatus; else withoutInviteStatus"></ng-container>
	</div>
</div>

<ng-template #withInviteStatus>
	<button
		*ngIf="inviteStatus === InternalMemberInviteStatus.ADD && (isOwnerOrSupervisor$ | async)"
		mat-flat-button
		[disabled]="disabled"
		color="accent"
		class="mat-button-small font-weight-bold"
		data-cy="add-internal-member"
		(click)="addMember.emit(member)"
	>
		{{ 'projectMembers.member.status.add' | translate }}
	</button>
	<button
		*ngIf="inviteStatus === InternalMemberInviteStatus.RESEND && invitationId && (isOwner$ | async)"
		mat-stroked-button
		[disabled]="disabled"
		color="accent"
		(click)="resendInvitation.emit(member)"
		class="mat-button-small font-weight-bold"
		data-cy="internal-member-resend"
	>
		{{ 'projectMembers.member.status.resend' | translate }}
	</button>
	<button
		*ngIf="inviteStatus === InternalMemberInviteStatus.REMIND && invitationId && (isOwner$ | async)"
		mat-stroked-button
		[disabled]="disabled"
		color="accent"
		(click)="remindInvitation.emit(member)"
		class="mat-button-small font-weight-bold"
		data-cy="internal-member-remind"
	>
		{{ 'projectMembers.member.status.remind' | translate }}
	</button>
	<button
		*ngIf="inviteStatus === InternalMemberInviteStatus.REMOVE && (isOwnerOrSupervisor$ | async)"
		mat-stroked-button
		color="accent"
		[disabled]="disabled"
		(click)="removeMember.emit(member)"
		class="mat-button-small font-weight-bold"
		data-cy="internal-member-remove"
	>
		{{ 'projectMembers.member.status.remove' | translate }}
	</button>
	<span *ngIf="inviteStatus === InternalMemberInviteStatus.ADDED" class="transparent mat-body-1">
		{{ 'projectMembers.member.status.added' | translate }}
	</span>
</ng-template>
<ng-template #withoutInviteStatus>
	<span class="mat-small craftnote-label" *ngIf="isAdmin">
		{{
			'projectOrFolderMembers.member.role.admin'
				| translate
					: {
							projectType: project.projectType == projectType.FOLDER ? ('global_folder' | translate) : ('global_project' | translate)
					  }
		}}
	</span>
</ng-template>
