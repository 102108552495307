import { Component } from '@angular/core';
import { AuthService } from '@injectables/services/auth/auth.service';
import { AuthPasswordresetRequestedEventBuilder } from '@generated/events/AuthEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { EMAIL_PATTERN } from '@constants/validators.constants';

@Component({
	selector: 'app-password-forgotten',
	templateUrl: './password-forgotten.component.html',
	styleUrls: ['password-forgotten.component.scss'],
})
export class PasswordForgottenComponent {
	email: string;
	emailPattern = EMAIL_PATTERN;

	constructor(
		private readonly authService: AuthService,
		private readonly trackingService: TrackingService,
	) {}

	async sendResetMail(): Promise<void> {
		await this.authService.forgotPassword(this.email);
		await this.trackingService.trackEvent(new AuthPasswordresetRequestedEventBuilder({}));
	}
}
