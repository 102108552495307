import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-dark-overlay',
	templateUrl: './dark-overlay.component.html',
	styleUrls: ['./dark-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkOverlayComponent {
	@Input()
	visible = false;

	@Input()
	zIndex = 0;
}
