import { Injectable } from '@angular/core';
import { ProfileClickId } from 'domain-entities';
import moment from 'moment';
import { ProfileClickIdConnector } from '../connectors/profile-click-id.connector';
import { keys } from 'lodash';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

export interface ClickIdInput {
	gclid?: string;
	fbclid?: string;
	msclkid?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ClickIdService {
	constructor(
		private profileClickIdConnector: ProfileClickIdConnector,
		private readonly localStorageService: LocalStorageService,
	) {}

	async handleClickIdProfile(userId: string): Promise<void> {
		let id: string;
		let source: 'google' | 'facebook' | 'microsoft';
		await firstValueFrom(
			this.localStorageService.isEntryPending('clickId').pipe(filter((isPending) => !isPending)),
		);
		const input = await this.getClickIdFromLocalStorage();

		if (input?.gclid) {
			id = input.gclid;
			source = 'google';
		} else if (input?.fbclid) {
			id = input.fbclid;
			source = 'facebook';
		} else if (input?.msclkid) {
			id = input.msclkid;
			source = 'microsoft';
		} else {
			return;
		}

		const profileClickId: ProfileClickId = {
			id,
			source,
			creationDate: moment().unix(),
		};

		await this.profileClickIdConnector.createProfileClickId(userId, profileClickId);
		this.clearClickIdFromLocalStorage();
	}

	setClickIdsToLocalStorage(parameters: ClickIdInput): void {
		// We only write non-empty click id inputs to local storage to prevent overrides with empty inputs
		if (keys(parameters).length) {
			this.localStorageService.set('clickId', parameters);
		}
	}

	clearClickIdFromLocalStorage(): void {
		this.localStorageService.set('clickId', null);
	}

	private async getClickIdFromLocalStorage(): Promise<ClickIdInput> {
		const serializedId = await this.localStorageService.get('clickId');
		return (serializedId as ClickIdInput) ?? null;
	}
}
