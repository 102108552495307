import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '@store/state/app.state';
import { NotificationInfoService } from '@injectables/services/notification-tokens/notification-info.service';
import { Injectable } from '@angular/core';
import { NotificationTokenService } from '@injectables/services/notification-token/notification-token.service';
import { NotificationPermission } from '@injectables/services/browser-notification.service';
import { BrowserService } from '@injectables/services/browser.service';
import { combineLatest, of } from 'rxjs';
import { selectNotificationPermission } from '@store/selectors/browser.selector';
import { selectUserEmail, selectUserId } from '@store/selectors/app.selectors';
import { IterableService } from '@injectables/services/iterable.service';
import { setCurrentDevice } from '@store/actions/device.actions';
import { getUnixTime } from 'date-fns';

@Injectable()
export class NotificationInfoEffects {
	updateNotificationInfo$ = createEffect(
		() =>
			combineLatest([
				this.store.select(selectUserId),
				this.store.select(selectUserEmail),
				this.store.select(selectNotificationPermission).pipe(distinctUntilChanged()),
			]).pipe(
				tap(async ([userId, email, permission]) => {
					if (!userId || !email) {
						return;
					}
					let notificationToken: string = null;
					if (
						this.browserService.isPermissionAPISupported() &&
						permission === NotificationPermission.GRANTED
					) {
						notificationToken = await this.messagingService.getNotificationToken();
						await this.iterableService.setUserToken(email, notificationToken);
					}
					await this.notificationInfoService.upsertNotificationInfo({
						notificationToken,
						lastUsedDate: getUnixTime(new Date()),
					});
				}),
			),
		{ dispatch: false },
	);

	watchCurrentUsersDevice$ = createEffect(() =>
		this.store.select(selectUserId).pipe(
			switchMap((userId) => {
				if (!userId) {
					return of(null);
				}
				return this.notificationInfoService.watchDevice(userId);
			}),
			map((device) => setCurrentDevice({ device })),
		),
	);

	constructor(
		private readonly notificationInfoService: NotificationInfoService,
		private readonly messagingService: NotificationTokenService,
		private readonly browserService: BrowserService,
		private readonly store: Store<AppState>,
		private readonly iterableService: IterableService,
	) {}
}
