<div class="d-flex align-items-center">
	<button (click)="closePanel.emit()" mat-icon-button>
		<mat-icon>arrow_back</mat-icon>
	</button>
	<span class="font-weight-bold pl-3">{{ 'time-tracking.log.all-changes' | translate }}</span>
</div>
<div class="mt-4" *ngFor="let block of changes$ | async">
	<div class="font-weight-bold mb-1">{{ block[0].changedAt | unixToMillis | date: 'shortDate' }}</div>
	<div *ngFor="let change of block">
		<div class="text-muted text-right pb-2">{{ change.changedBy }} {{ change.changedAt | unixToMillis | date: 'shortTime' }}</div>
		<ul>
			<li class="mat-body-2 font-weight-normal" *ngFor="let line of change.changes">{{ line }}</li>
		</ul>
	</div>
</div>
