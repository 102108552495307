import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalChatUserState } from 'domain-entities';
import { ExternalChatUserStatesConnector } from '@shared/firebase/connectors/firestore/collections/external-chat/external-chat-user-states.connector';
import { DocumentChanges } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class ExternalChatUserStateService {
	constructor(private readonly externalChatUserStatesConnector: ExternalChatUserStatesConnector) {}

	watchUserState(userId: string): Observable<DocumentChanges<ExternalChatUserState>> {
		return this.externalChatUserStatesConnector.watchUserStateById(userId);
	}
}
