import { createAction, props } from '@ngrx/store';
import { InvitationLink } from '@store/state/invitations.state';
import { EntityChanges } from '@craftnote/shared-utils';
import { Invitation } from 'domain-entities';

export const setInvitationLink = createAction(
	'[Invitation] set invitation link',
	props<{ invitationLink: InvitationLink | null }>(),
);

export const clearInvitationLink = createAction('[Invitation] Clear invitation link');

export const initInvitationLink = createAction('[Invitation] Init invitation link');

export const subscribeToInvitationsChanges = createAction(
	'[Invitation] Subscribing to invitations for changes (Added, modified, deleted)',
);

export const invitationsChanged = createAction(
	'[Invitation] Invitations Are changed (Added, modified, deleted)',
	props<{ payload: EntityChanges<Invitation> }>(),
);

export const clearInvitations = createAction('[Invitation] Clear Invitations state');

export const invitationsSubscriptionError = createAction(
	'[Invitation] Invitations subscription error',
);
