import { AppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { selectProfile } from './app.selectors';
import { DEFAULT_TRADE_LOCALE } from '@shared/constants/trade.constants';

export const selectTradeOptionsState = (state: AppState) => state.tradeOptions;

export const selectUserTradeOptions = createSelector(
	selectTradeOptionsState,
	selectProfile,
	(state, profile) => {
		if (state.options === undefined || profile === undefined) {
			return undefined;
		}
		let option = state.options.find((opt) => opt.locale === profile.locale);
		if (!option) {
			option = state.options.find((opt) => opt.locale === DEFAULT_TRADE_LOCALE);
		}
		return option.trades;
	},
);
