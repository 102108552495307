import { Injectable } from '@angular/core';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogConfig } from './confirm-dialg.types';

@Injectable()
export class ConfirmDialogService {
	private confirmDialogDefaultConfig: ConfirmDialogConfig = {
		title: 'Are you sure?',
		message: '',
		contentHint: '',
		primaryButtonText: 'Yes',
		secondaryButtonText: 'No',
		tertiaryButtonText: '',
		primaryButtonColor: 'primary',
		secondaryButtonColor: undefined,
		tertiaryButtonColor: undefined,
		primaryButtonValue: true,
		secondaryButtonValue: false,
		tertiaryButtonValue: undefined,
		showCrossBtn: true,
		showSecondaryButton: true,
		showTertiaryButton: false,
		showCheckbox: false,
		checkboxLabel: '',
	};

	constructor(private readonly dialog: MatDialog) {}

	open(confirmDialogConfig?: ConfirmDialogConfig): MatDialogRef<ConfirmDialogComponent> {
		const matDialogConfig: MatDialogConfig<ConfirmDialogConfig> = {
			disableClose: confirmDialogConfig?.disableClose || false,
			hasBackdrop: true,
			autoFocus: false,
			minWidth: '600px',
			maxHeight: '80vh',
			panelClass: 'confirmation-dialog',
			data: { ...this.confirmDialogDefaultConfig, ...confirmDialogConfig },
		};

		return this.dialog.open(ConfirmDialogComponent, matDialogConfig);
	}
}
