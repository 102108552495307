import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, mergeMap, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { Project } from 'domain-entities';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import {
	selectArchivedProjectsWithParents,
	selectParentProject,
	selectProject,
} from '@store/selectors/projects.selectors';
import { ChatHeaderButton } from '../chat/chat-header/chat-header.component';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Component({
	selector: 'app-archive',
	templateUrl: './archive.component.html',
	styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit, OnDestroy {
	projects: Project[] = [];
	projectId$ = this.store.select(selectActiveProject);
	projectIdFound$ = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProject, { projectId })),
		map(Boolean),
	);
	loaded: boolean;
	archivedProjects: Project[] = [];
	menuButton$ = new EventEmitter<ChatHeaderButton>();
	@Output() menuButtonRightSide = new EventEmitter<ChatHeaderButton>();

	private destroy$ = new Subject();
	private authSub: any;
	private openedProjectId: string;
	filterActive = false;

	constructor(
		private readonly authService: AuthService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly projectService: ProjectService,
		private readonly localStorageService: LocalStorageService,
		private readonly store: Store<AppState>,
	) {
		this.loaded = false;
	}

	ngOnInit(): void {
		if (!this.authService.currentUser()) {
			if (this.authSub) {
				this.authSub.unsubscribe();
			}
			this.authSub = this.authService.$firebaseUser
				.asObservable()
				.pipe(
					skipWhile((user) => !user),
					take(1),
				)
				.subscribe(() => {
					this.loadProjects();
				});
		} else {
			this.loadProjects();
		}

		this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(async (query) => {
			if (query['id']) {
				this.openedProjectId = query['id'];
			} else {
				this.openedProjectId = await this.localStorageService.get('projectArchived');
			}
		});

		this.projectService
			.getProjects(true)
			.pipe(
				skipWhile((projects) => !projects.length),
				take(1),
				mergeMap(() => {
					return this.store.select(selectParentProject, { projectId: this.openedProjectId });
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((project) => {
				this.store.dispatch(setSelectedFolderAction({ folderId: project?.id }));
			});
	}

	loadProjects(): void {
		this.store
			.select(selectArchivedProjectsWithParents)
			.pipe(takeUntil(this.destroy$))
			.subscribe((projects) => {
				this.loaded = true;
				this.projects = projects;
			});
	}

	setOpenFolder(project): void {
		this.store.dispatch(setSelectedFolderAction({ folderId: project.id }));
	}

	folderProjectChanged($event: Project): void {
		this.setOpenFolder($event);
	}

	sortAndSearchChanged(projects: Project[]): void {
		this.archivedProjects = projects;
	}

	handleMenuButton(menuButton: ChatHeaderButton): void {
		this.menuButtonRightSide.emit(menuButton);
		return;
	}

	ngOnDestroy(): void {
		this.store.dispatch(setSelectedFolderAction({ folderId: null }));
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
