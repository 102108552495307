import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MemberRole, Permission, Permissions } from 'domain-entities';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { FileDocument } from '@craftnote/shared-utils';
import { DOCUMENT } from '@angular/common';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { omit } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-manage-access-dialog',
	templateUrl: './manage-access-dialog.component.html',
	styleUrls: ['./manage-access-dialog.component.scss'],
})
export class ManageAccessDialogComponent {
	permissions: Permissions;
	file: FileDocument;
	MemberRoles = MemberRole;
	folderIsPublic: boolean;
	sharedLink: string;
	token: string;
	publicFolderLinkCopied = false;

	accessForm = this.formBuilder.group({
		[MemberRole.SUPERVISOR]: [false, Validators.requiredTrue],
		[MemberRole.EMPLOYEE]: [false, Validators.requiredTrue],
		[MemberRole.EXTERNAL]: [false, Validators.requiredTrue],
		isPublic: [false, Validators.requiredTrue],
	});

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		public dialogRef: MatDialogRef<ManageAccessDialogComponent>,
		public fileExplorerService: FileExplorerService,
		public readonly confirmDialogService: ConfirmDialogService,
		private readonly translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		@Inject(DOCUMENT) public document: Document,
	) {
		this.permissions = data.permissions || {
			roles: {
				[MemberRole.EXTERNAL]: [Permission.READ, Permission.WRITE],
				[MemberRole.SUPERVISOR]: [Permission.READ, Permission.WRITE],
				[MemberRole.EMPLOYEE]: [Permission.READ, Permission.WRITE],
			},
		};

		this.file = data.element;
		this.token = data.token;
		this.folderIsPublic = this.fileExplorerService.isFolderCurrentlyPublic(this.file);
		this.sharedLink = this.fileExplorerService.generateShareLink(this.file.id, this.token);
		this.updateFormValues();
	}

	async cancelHandler(): Promise<void> {
		if (this.accessForm.pristine) {
			this.dialogRef.close();
			return;
		}

		const isConfirmed = await this.confirmDialogService
			.open({
				title: this.translate.instant('explorer.manageAccess.confirmationDialog.title'),
				primaryButtonText: this.translate.instant(
					'explorer.manageAccess.confirmationDialog.confirmBtn',
				),
				secondaryButtonText: this.translate.instant(
					'explorer.manageAccess.confirmationDialog.cancelBtn',
				),
				primaryButtonColor: 'warn',
			})
			.afterClosed()
			.toPromise();
		if (isConfirmed) {
			this.dialogRef.close();
		}
	}

	changePermissions(memberRole: MemberRole, event: MatSlideToggleChange): void {
		const updateRoles = {
			[memberRole]: event.checked ? [Permission.READ, Permission.WRITE] : [],
		};

		if (memberRole === MemberRole.EXTERNAL) {
			updateRoles[MemberRole.EXTERNALSUPERVISOR] = event.checked
				? [Permission.READ, Permission.WRITE]
				: [];
		}

		this.permissions = {
			roles: {
				...this.permissions.roles,
				...updateRoles,
			},
		};
	}

	changeFolderIsPublic(event: MatSlideToggleChange): void {
		this.folderIsPublic = event.checked;
	}

	copyLinkToClipboard(): void {
		this.publicFolderLinkCopied = true;
		this.document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', this.sharedLink);
			e.preventDefault();
			this.document.removeEventListener('copy', null);
		});
		this.document.execCommand('copy');
	}

	private updateFormValues(): void {
		const { roles } = this.permissions;
		Object.keys(omit(roles, MemberRole.EXTERNALSUPERVISOR)).forEach((memberRole) =>
			this.accessForm.patchValue({
				[memberRole]: roles[memberRole].length > 0,
			}),
		);
		if (this.folderIsPublic) {
			this.accessForm.patchValue({
				isPublic: true,
			});
		}
	}
}
