import { NgModule } from '@angular/core';
import { ProjectAddressPipe } from '@modules/shared/pipes/project-address/project-address.pipe';

const PIPE = [ProjectAddressPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class ProjectAddressPipeModule {}
