import { Injectable } from '@angular/core';
import {
	FirebaseGoogleAnalyticsConnector,
	GoogleAnalyticsPurchaseEventParameters,
} from '@injectables/connectors/firebase-google-analytics.connector';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { EventParams } from '@globalTypes/interfaces/event';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import {
	selectCompany,
	selectCompanyId,
	selectUserEmail,
	selectUserId,
} from '@store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import {
	CraftnoteEvent,
	GlobalCraftnoteEventParameters,
} from '@generated/events/CraftnoteEvent.generated';
import { IterableAnalyticsConnector } from '@injectables/connectors/iterable-analytics.connector';
import { TocService } from '@injectables/services/toc.service';

// FIXME:: Move me under tracking folder

// Until we have auto versioning in place, let this be the version of application
export const APP_VERSION = '6.46.0';

interface EventBuilder {
	build: (
		resolve: () => Promise<GlobalCraftnoteEventParameters>,
	) => Promise<CraftnoteEvent<EventParams>>;
}

@Injectable({
	providedIn: 'root',
})
export class TrackingService {
	constructor(
		private readonly googleProvider: FirebaseGoogleAnalyticsConnector,
		private readonly iterableProvider: IterableAnalyticsConnector,
		private readonly errorHandler: ErrorHandlerService,
		private readonly store: Store<AppState>,
		private readonly tocService: TocService,
	) {
		this.initUserAndCompanySubscription();
	}

	async setCurrentUser(userId: string, email: string): Promise<void> {
		const isTocEnabled = await this.tocService.isCompanyAccepted();
		try {
			await this.googleProvider.setUser(userId || null);
			if (email && isTocEnabled) {
				await this.iterableProvider.setUser(email);
			} else {
				await this.iterableProvider.removeUser();
			}
		} catch (e) {
			this.errorHandler.handleError(e);
		}
	}

	async removeCurrentUser(): Promise<void> {
		await this.iterableProvider.removeUser();
	}

	private initUserAndCompanySubscription(): void {
		combineLatest([
			this.store.select(selectUserEmail),
			this.store.select(selectUserId),
			this.store.select(selectCompany),
		]).subscribe(async ([userEmail, userId]) => {
			this.setCurrentUser(userId, userEmail);
		});
	}

	async trackEvent(builder: EventBuilder): Promise<void> {
		try {
			const resolver = () => this.getDefaultParameters();
			const event = await builder.build(resolver);
			const providers = event.getProviders();
			const googleEvent = providers.find((provider) => provider.name === 'firebase')?.eventName;
			const iterableEvent = providers.find((provider) => provider.name === 'iterable')?.eventName;
			const isTocEnabled = await this.tocService.isCompanyAccepted();
			/**
			 * Connectors will be added in the future based on the providers
			 */

			if (googleEvent) {
				await this.googleProvider.trackEvent(googleEvent, event.getParameters());
			}
			if (iterableEvent && isTocEnabled) {
				await this.iterableProvider.trackEvent(iterableEvent, event.getParameters());
			}
		} catch (e) {
			this.errorHandler.handleError(e);
		}
	}

	/**
	 * This events is a special Google Analytics event regarding ecommerce see
	 * https://firebase.google.com/docs/analytics/measure-ecommerce
	 */
	async trackGoogleAnalyticsPurchaseEvent(
		parameters: GoogleAnalyticsPurchaseEventParameters,
	): Promise<void> {
		await this.googleProvider.trackGoogleAnalyticsPurchaseEvent(parameters);
	}

	async getDefaultParameters(): Promise<GlobalCraftnoteEventParameters> {
		const version = APP_VERSION;
		const [userId, companyId] = await combineLatest([
			this.store.select(selectUserId),
			this.store.select(selectCompanyId),
		])
			.pipe(take(1))
			.toPromise();

		return {
			userId,
			companyId,
			version,
			isOnline: true,
			networkType: 'UNKNOWN',
		};
	}
}
