import { NgModule } from '@angular/core';
import { HoursFromDatePipe } from './hours-from-date.pipe';

const PIPE = [HoursFromDatePipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class HoursFromDatePipeModule {}
