import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-dialog-line-right',
	templateUrl: './line-right.component.html',
	styleUrls: ['./line-right.component.scss'],
})
export class LineRightComponent {
	@Input() content?: string;
	@Input() contentTranslate?: string;
}
