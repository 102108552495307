import { Injectable } from '@angular/core';
import { FilterType } from '@shared/constants/sort';
import { AuthService } from '@injectables/services/auth/auth.service';
import { FILE_TYPE } from '@craftnote/shared-utils';

@Injectable()
export class FileExplorerSortHelper {
	constructor(private authService: AuthService) {}

	sort(files, filterType) {
		const authId = this.authService.currentUserId();

		if (!files || !authId) {
			return files;
		}

		return files.sort((n1, n2) => {
			switch (filterType) {
				case FilterType.NAME:
					if (n1.type === FILE_TYPE.FOLDER && n2.type === FILE_TYPE.FOLDER) {
						return n1.name.localeCompare(n2.name);
					} else if (n1.type === FILE_TYPE.FOLDER && n2.type !== FILE_TYPE.FOLDER) {
						return -1;
					} else if (n1.type !== FILE_TYPE.FOLDER && n2.type === FILE_TYPE.FOLDER) {
						return 1;
					}

					return n1.name.localeCompare(n2.name);

				case FilterType.CREATION_DATE:
					const dateOne = new Date(n1.creationTimestamp * 1000).getTime();
					const dateTwo = new Date(n2.creationTimestamp * 1000).getTime();
					if (n1.type === FILE_TYPE.FOLDER && n2.type === FILE_TYPE.FOLDER) {
						return dateOne - dateTwo;
					} else if (n1.type === FILE_TYPE.FOLDER && n2.type !== FILE_TYPE.FOLDER) {
						return -1;
					} else if (n1.type !== FILE_TYPE.FOLDER && n2.type === FILE_TYPE.FOLDER) {
						return 1;
					}

					return dateOne - dateTwo;
			}
		});
	}
}
