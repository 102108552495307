import { animate, style, transition, trigger } from '@angular/animations';

export const ShrinkOutAnimation = trigger('shrinkOut', [
	transition('* => void', [
		style({ height: '*', opacity: 0 }),
		animate(300, style({ height: 0, opacity: 0 })),
	]),
	transition('void => *', [
		style({ height: 0, opacity: 0 }),
		animate(300, style({ height: '*', opacity: 1 })),
	]),
]);
