import { NgModule } from '@angular/core';
import { AutoscrollDirective } from './autoscroll.directive';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	declarations: [AutoscrollDirective],
	imports: [SharedModule],
	exports: [AutoscrollDirective],
})
export class AutoscrollModule {}
