import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@env/environment';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
	AngularFireRemoteConfigModule,
	SETTINGS as REMOTE_CONFIG_SETTINGS,
} from '@angular/fire/compat/remote-config';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { CraftnoteAuthGuard } from '@injectables/guards/auth/craftnote-auth-guard';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';

@NgModule({
	providers: [
		AngularFireDatabase,
		AngularFireAuth,
		{ provide: REGION, useValue: 'europe-west1' },
		{
			provide: AngularFireAuthGuard,
			useClass: CraftnoteAuthGuard,
		},
		{
			provide: REMOTE_CONFIG_SETTINGS,
			useValue: { minimumFetchIntervalMillis: 300_000, fetchTimeoutMillis: 60_000 },
		},
	],
	imports: [
		AngularFireModule.initializeApp(environment.firebaseConfig),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
	],
	exports: [
		AngularFireModule,
		AngularFireAuthModule,
		AngularFireAuthGuardModule,
		AngularFirePerformanceModule,
		AngularFirestoreModule,
		AngularFireDatabaseModule,
		AngularFireStorageModule,
		AngularFireFunctionsModule,
		AngularFireRemoteConfigModule,
		AngularFireAnalyticsModule,
		AngularFireMessagingModule,
	],
})
export class AppFirebaseModule {}
