import { Injectable } from '@angular/core';
import { TaskTime } from 'domain-entities';

import {  Observable } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { TaskTimeConnector } from '@shared/firebase/connectors/firestore/collections/task-time/task-time.connector';

// TODO:: Rewrite this service
@Injectable({
	providedIn: 'root',
})
export class TasksDashboardService {
	constructor(
		private readonly taskTimeConnector: TaskTimeConnector,
	) {}


	getTaskTimesById(taskId: string): Observable<EntityChanges<TaskTime>> {
		return this.taskTimeConnector.watchTasksTimesOfTask(taskId);
	}

	createTaskTime(taskTime: TaskTime): Promise<void> {
		return this.taskTimeConnector.createTaskTime(taskTime);
	}
}
