import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ChatService } from '@injectables/services/chat/chat.service';
import { CompanyService } from '@injectables/services/company/company.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { ComponentCanDeactivate } from '@injectables/guards/upload/upload.guard';
import { Company } from '@shared/models/company.model';
import { Profile, Project } from 'domain-entities';
import { MenuService } from '@injectables/services/menu.service';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectRightSideMenu } from '@store/selectors/route.selectors';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-work',
	templateUrl: './work.component.html',
	styleUrls: ['./work.component.scss'],
})
export class WorkComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
	lastScrollPosition = 0;
	public menuItem: string;
	public projects: Project[];
	private destroy$: Subject<boolean> = new Subject();
	archive: boolean;
	rightSideMenuActive$ = this.store.select(selectRightSideMenu);

	constructor(
		private readonly authService: AuthService,
		private readonly projectService: ProjectService,
		private readonly profileService: ProfileService,
		private readonly companyService: CompanyService,
		private readonly el: ElementRef,
		private readonly menuService: MenuService,
		private readonly chatService: ChatService,
		private readonly fileExplorerService: FileExplorerService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly store: Store<AppState>,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
	) {
		if (
			navigator.userAgent.indexOf('MSIE ') === -1 &&
			navigator.userAgent.indexOf('Trident/') === -1
		) {
			this.setArchive();
		}
	}

	ngOnInit(): void {
		this.authService.$firebaseUser
			.asObservable()
			.pipe(
				skipWhile((user) => !user),
				take(1),
			)
			.subscribe((authState) => {
				this.authService.firebaseUser = authState;
				this.loadProfileAndCompany();
			});

		this.menuService
			.getMenuItem()
			.pipe(takeUntil(this.destroy$))
			.subscribe((item) => {
				this.menuItem = item;
			});
	}

	setScrollPosition(): void {
		setTimeout(() => {
			this.el.nativeElement.getElementsByClassName('projects')[0].scrollTop =
				this.lastScrollPosition;
			this.lastScrollPosition = 0;
		}, 0);
	}

	private loadProfileAndCompany(): void {
		this.profileService
			.getProfile(this.authService.currentUserId())
			.pipe(
				switchMap((profile: Profile) =>
					this.companyService.getCompany(profile.company).pipe(take(1)),
				),
				switchMap((_company: Company) => this.projectService.getProjects(false)),
				takeUntil(this.destroy$),
			)
			.subscribe((projects: Project[]) => {
				this.projects = projects;
			});
	}

	saveScrollPosition(): void {
		this.lastScrollPosition = this.el.nativeElement.getElementsByClassName('projects')[0].scrollTop;
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	@HostListener('window:beforeunload')
	canDeactivate(internalNavigation: true | undefined): Observable<boolean> | boolean {
		if (!internalNavigation) {
			return !this.chatService.uploading && this.fileExplorerService.loaded;
		}

		return this.confirmDialogService
			.open({
				title: this.translateService.instant('error.uploadDialog.title'),
				message: this.translateService.instant('error.uploadDialog.description'),
				primaryButtonText: this.translateService.instant('button.ok'),
				secondaryButtonText: this.translateService.instant('button.cancel'),
				primaryButtonValue: true,
				secondaryButtonValue: false,
				showCrossBtn: false,
			})
			.afterClosed()
			.pipe(take(1));
	}

	async handleMenuButton(selectedButtonId: string): Promise<void> {
		const rightSideMenu = await this.store.select(selectRightSideMenu).pipe(first()).toPromise();
		const routerNavigate = (param: string): void => {
			this.router.navigate([param], { relativeTo: this.activatedRoute.children[0] });
		};

		if (selectedButtonId === rightSideMenu) {
			routerNavigate('.');

			return;
		}

		routerNavigate(selectedButtonId);

		return;
	}

	private setArchive(): void {
		this.archive = !!this.activatedRoute.snapshot.data['archive'];
	}
}
