import { NgModule } from '@angular/core';
import { PatternRestrictDirective } from './pattern-restrict.directive';

const DIRECTIVE = [PatternRestrictDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class PatternRestrictModule {}
