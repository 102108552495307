<div appFullScreenSizeDirective class="d-flex flex-column">
	<app-navbar *ngIf="isNavbarRoute$ | async" [authenticated]="authenticated$ | async" [publicRoute]="isPublicRoute$ | async"></app-navbar>

	<ng-container [ngSwitch]="activeBanner$ | async">
		<app-trial-banner *ngSwitchCase="AppBanner.TRIAL" [trialSubscription]="trialSubscription$ | async"></app-trial-banner>
		<app-toc-declined-banner *ngSwitchCase="AppBanner.TOC_DECLINED"></app-toc-declined-banner>
	</ng-container>

	<app-browser-error-banner (active)="setWarningBannerActive($event)" *ngIf="isNavbarRoute$ | async"></app-browser-error-banner>

	<div class="d-flex flex-grow-1 overflow-auto">
		<ng-template [ngIf]="isSideBarEnabled$ | async">
			<div class="col-auto sidebar-container d-none d-md-block">
				<app-sidebar (openNotificationCenter)="notificationCenter.toggle()"></app-sidebar>
			</div>
		</ng-template>

		<div class="flex-grow-1 h-100">
			<app-notification-center #notificationCenter>
				<router-outlet></router-outlet>
			</app-notification-center>
		</div>
	</div>
</div>
<app-app-init *ngIf="isSplashScreenEnabled && (appInitStatus$ | async) === AppInitStatus.IN_PROGRESS"></app-app-init>
<app-notification-snackbar class="notification-snackbar" data-cy="register-success-notification"></app-notification-snackbar>
<app-paywall-header-dialog></app-paywall-header-dialog>
