export class BillingAddressModel {
	billingName?: string;
	_billingEmail?: string;
	billingStreet?: string;
	billingZipcode?: string;
	billingCity?: string;
	billingCountry?: string;

	set billingEmail(email: string) {
		this._billingEmail = email && email.toLowerCase();
	}

	get billingEmail() {
		return this._billingEmail;
	}
}
