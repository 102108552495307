import { isProject, Project } from 'domain-entities';
import { clearEntities, DataQueryCondition, EntityChanges } from '@craftnote/shared-utils';
import { Observable, of } from 'rxjs';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class DeletedProjectConnector {
	private static COLLECTION_NAME = 'projectsDeleted';

	constructor(private readonly connector: FirestoreConnector) {}

	watchDeletedProjects(
		companyId: string,
		changedAfter?: number,
	): Observable<EntityChanges<Project>> {
		if (!companyId) {
			return of(clearEntities);
		}

		const conditions: DataQueryCondition<Project>[] = [
			{
				field: 'company',
				operator: '==',
				value: companyId,
			},
		];
		if (!isNil(changedAfter)) {
			conditions.push({
				field: 'status.setAt' as any,
				operator: '>=',
				value: changedAfter,
			});
		}
		return this.connector.watchDocumentsChanges<Project>(
			DeletedProjectConnector.COLLECTION_NAME,
			conditions,
		);
	}

	getDeletedProject(projectId: string): Promise<Project | undefined> {
		return this.connector.getDocument<Project>(
			`${DeletedProjectConnector.COLLECTION_NAME}/${projectId}`,
			isProject,
		);
	}
}
