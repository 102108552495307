import { Directive, HostBinding, NgZone, OnDestroy } from '@angular/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

@Directive({
	selector: '[appFullScreenSizeDirective]',
})
export class FullScreenHeightDirective implements OnDestroy {
	@HostBinding('style.height') heightOfHost = '100vh';

	private destroy$ = new Subject();

	constructor(private readonly viewportRuler: ViewportRuler, private readonly ngZone: NgZone) {
		this.initScreenSizeWatcher();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	initScreenSizeWatcher(): void {
		this.viewportRuler
			.change(200)
			.pipe(startWith({}), takeUntil(this.destroy$))
			.subscribe(() => {
				this.ngZone.run(() => {
					const vh = (window as any).innerHeight;
					this.heightOfHost = `${vh}px`;
				});
			});
	}
}
