import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FileExplorerService } from '../../services/file-explorer/file-explorer.service';
import { ChatService } from '../../services/chat/chat.service';

export interface ComponentCanDeactivate {
	canDeactivate: (internalNavigation: true | undefined) => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class UploadGuard {
	constructor(private chatService: ChatService, private fileExplorerService: FileExplorerService) {}

	canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
		// FIXME:: I think fileExplorerService logic shouldn't be here and make this guard as ChatUploadingGuard
		if (!this.chatService.uploading && this.fileExplorerService.loaded) {
			return true;
		}
		return component.canDeactivate(true);
	}
}
