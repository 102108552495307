<section class="project-contacts-list-section p-3" *ngIf="!addEditProjectContactVisible">
	<div class="d-flex align-items-center mb-3">
		<div class="d-flex align-items-center">
			<button class="mr-3" mat-icon-button (click)="close.emit()" data-cy="contact-list-back">
				<mat-icon class="close-btn-icon">arrow_back</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				{{ 'project.contacts_details' | translate }}
			</div>
		</div>

		<div *appMemberRole="[MemberRole.OWNER, MemberRole.SUPERVISOR]" class="ml-auto d-flex align-items-center">
			<button color="accent" data-cy="contact-list-add" mat-flat-button (click)="addContact()">
				{{ 'project-contacts-list.add-btn' | translate }}
			</button>
		</div>
	</div>

	<ul class="list-unstyled" *ngVar="projectContacts$ | async as projectContacts">
		<li *ngFor="let contact of projectContacts || []; let i = index" class="border-bottom p-1">
			<div class="align-items-center d-flex">
				<div>
					<p data-cy="contact-list-name-info" class="m-0">{{ contact.name }}</p>
					<div *ngIf="contact?.emails; else emptyPlaceholder">
						<div *ngFor="let email of contact?.emails" class="text-muted mat-small" data-cy="contact-list-email-info">{{ email }}</div>
					</div>
					<div *ngIf="contact?.phones; else emptyPlaceholder">
						<div *ngFor="let phone of contact?.phones" class="text-muted mat-small">{{ phone }}</div>
					</div>
				</div>

				<div class="ml-auto">
					<button mat-icon-button (click)="deleteContact(i)">
						<mat-icon>delete</mat-icon>
					</button>
					<button mat-icon-button (click)="editContact(contact, i)" data-cy="contact-detail-edit">
						<mat-icon>edit</mat-icon>
					</button>
				</div>
			</div>
		</li>
		<li *ngIf="projectContacts.length === 0" class="text-center" data-cy="no-contacts-text">
			{{ 'project-add-edit-contact-form.no-contract-text' | translate }}
		</li>
	</ul>

	<ng-template #emptyPlaceholder>
		<div>-</div>
	</ng-template>
</section>

<app-project-add-edit-contact-form
	(close)="closeAddEditProjectContact()"
	*ngIf="addEditProjectContactVisible"
	[contactUI]="addEditProjectContact"
	(update)="onUpdateContact($event)"
>
</app-project-add-edit-contact-form>
