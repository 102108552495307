import { Pipe, PipeTransform } from '@angular/core';
import { MemberRole } from 'domain-entities';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'roleName',
})
export class RoleNamePipe implements PipeTransform {
	constructor(private readonly translate: TranslateService) {}

	transform(role: MemberRole): string {
		let key = '';
		switch (role) {
			case MemberRole.OWNER:
				key = 'global_roles_owner';
				break;
			case MemberRole.SUPERVISOR:
				key = 'global_roles_supervisor';
				break;
			case MemberRole.EMPLOYEE:
				key = 'global_roles_employee';
				break;
			case MemberRole.EXTERNALSUPERVISOR:
				key = 'global_roles_external_supervisor';
				break;
			case MemberRole.EXTERNAL:
				key = 'global_roles_external';
				break;
		}
		return this.translate.instant(key);
	}
}
