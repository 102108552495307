import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { ProjectTaskEditComponent } from './project-task-edit/project-task-edit.component';
import { TaskEditSharedModule } from '@modules/shared/components/task-edit/task-edit-shared.module';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconSizeModule } from '@modules/shared/directives/mat-icon-size.module';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [ProjectTaskEditComponent],
	imports: [
		TaskEditSharedModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatDatetimepickerModule,
		MatDividerModule,
		MatListModule,
		SharedModule,
		MatIconModule,
		MatButtonModule,
		MatIconSizeModule,
		RouterModule,
	],
	exports: [],
})
export class TasksModule {}
