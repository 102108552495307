import { Inject, Injectable } from '@angular/core';
import { FirebaseStorageConnector } from '@shared/firebase/connectors/storage/firebase-storage.connector';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

@Injectable({
	providedIn: 'root',
})
export class FileDownloadService {
	constructor(
		private readonly connector: FirebaseStorageConnector,
		private readonly errorService: ErrorHandlerService,
		@Inject(DOCUMENT) public document: Document,
	) {}

	async downloadExportFile(location: string, filename: string): Promise<void> {
		return this.downloadFile(location, filename, environment.export_data_bucket);
	}

	private async downloadFile(location: string, fileName: string, bucket?: string): Promise<void> {
		const url = await this.connector.getDownloadUrl(location, bucket);
		const link = this.document.createElement('a');

		link.href = url;
		link.download = fileName;
		link.style.visibility = 'hidden';
		this.document.body.appendChild(link);
		link.click();
		this.document.body.removeChild(link);
	}

	async getBlob(location: string): Promise<Blob> {
		const url = await this.connector.getDownloadUrl(location);
		const res = await fetch(url);

		if (res.type === 'opaque') {
			this.errorService.handleError(
				new Error(
					`OPAQUE FETCH RESULT: Trying to fetch ${location} returned a result with type of OPAQUE`,
				),
			);
		}

		return await res.blob();
	}
}
