import { NgModule } from '@angular/core';
import { NotificationTokenPermissionComponent } from './notification-token-permission.component';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { NotificationTokenPermissionDeniedComponent } from './components/notification-token-permission-denied/notification-token-permission-denied.component';
import { NotificationTokenPermissionDefaultComponent } from './components/notification-token-permission-default/notification-token-permission-default.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';

@NgModule({
	declarations: [
		NotificationTokenPermissionComponent,
		NotificationTokenPermissionDeniedComponent,
		NotificationTokenPermissionDefaultComponent,
	],
	exports: [NotificationTokenPermissionComponent],
	imports: [SharedModule, MatIconModule, MatIconSizeModule, MatButtonModule],
})
export class NotificationTokenPermissionModule {}
