<div class="w-100">
	<div class="mt-4 col-3 mb-4 ml-3">
		<a class="d-flex align-items-center" href="#" [routerLink]="backNavigationRoute" data-cy="archive-project-list-back-to-settings">
			<em class="material-icons mr-4">arrow_back</em>
			<h1 class="m-0">
				<ng-content></ng-content>
			</h1>
		</a>
	</div>
</div>
