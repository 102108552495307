import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { selectProject } from '@store/selectors/projects.selectors';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProjectType } from 'domain-entities';

@Injectable({ providedIn: 'root' })
export class RightSideMenuGuard {
	constructor(private readonly store: Store, private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const project = await this.store
			.select(selectProject, { projectId: route.params.projectId })
			.pipe(take(1))
			.toPromise();

		if (!project || project.projectType === ProjectType.PROJECT) {
			return true;
		}
		return this.router.createUrlTree([route.parent.parent.url[0].path, route.parent.url[0].path]);
	}
}
