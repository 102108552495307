import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LinkService } from '@injectables/services/link/link.service';

@Component({
	selector: 'app-toc-confirmation-dialog',
	templateUrl: './toc-confirmation-dialog.component.html',
	styleUrls: ['./toc-confirmation-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TocConfirmationDialogComponent {
	tocForm = this.formBuilder.group({
		state: [false, Validators.requiredTrue],
	});

	constructor(
		private readonly dialogRef: MatDialogRef<TocConfirmationDialogComponent>,
		private readonly formBuilder: UntypedFormBuilder,
		public readonly linkService: LinkService,
	) {}

	agreeToc(): void {
		this.dialogRef.close(true);
	}
}
