<section class="notification-item-section d-flex flex-column pl-3 pr-4 pt-3 h-100 overflow-hidden position-relative">
	<div class="d-flex align-content-center w-100 mb-2 position-relative">
		<div class="flex-1 m-0 mat-body-2 notification-title">{{ data.title }}</div>

		<div class="unread-badge">
			<mat-icon *ngIf="data.isUnread" color="accent" [size]="16">fiber_manual_record</mat-icon>
		</div>
	</div>

	<div class="d-flex align-content-center mb-2">
		<h1 class="m-0 notification-resource-name">{{ data.resourceName }}</h1>
	</div>

	<div class="m-0 mr-5 mat-body-1 notification-body" [innerHTML]="data.body"></div>

	<span class="notification-timestamp mat-small">{{ data.timestamp | unixToMillis | fromNow }}</span>
</section>
