<section class="h-100 d-flex justify-content-center align-items-center">
	<div *ngIf="projectInvitationResponse$ | async as message">
		<ng-container *ngIf="!isLoading">
			<div class="project-invite-error">
				<mat-icon>error</mat-icon>
				<h3>
					{{ message | translate }}
				</h3>
				<a [routerLink]="['/project']" mat-flat-button color="accent" href="">
					{{ 'project-invite.show-projects' | translate }}
				</a>
			</div>
		</ng-container>
	</div>

	<app-pulsing-craftnote-icon *ngIf="isLoading"></app-pulsing-craftnote-icon>
</section>
