<section class="add-edit-company-employee">
	<div *ngIf="memberForm">
		<ng-container *ngIf="operation === CompanyMemberOperation.ADD" [ngTemplateOutlet]="addOrEditInvitation"></ng-container>
		<ng-container
			*ngIf="operation === CompanyMemberOperation.EDIT && member?.inviteState === InviteState.INVITED"
			[ngTemplateOutlet]="addOrEditInvitation"
		></ng-container>
		<ng-container
			*ngIf="operation === CompanyMemberOperation.EDIT && member?.inviteState === InviteState.ACCEPTED"
			[ngTemplateOutlet]="editMemberForm"
		></ng-container>
	</div>
</section>

<ng-template #addOrEditInvitation>
	<form [formGroup]="memberForm" (ngSubmit)="onInvitationSubmit()">
		<div class="row">
			<div class="col-12" *ngIf="operation === CompanyMemberOperation.ADD">
				<div class="add-edit-company-employee__hint px-3 py-2 mb-4" [innerHTML]="'companyMembers.invite.dialog.hint' | translate"></div>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.email.label' | translate }}</mat-label>
					<input trim matInput formControlName="email" data-cy="setting-add-employee-button-email-field" required />
					<mat-icon matSuffix *ngIf="getControlWithName('email').disabled">lock</mat-icon>
					<mat-error *ngIf="getControlWithName('email').dirty && getControlWithName('email').errors">
						<span *ngIf="getControlWithName('email').errors?.required">
							{{ 'companyMembers.invite.dialog.errors.email.required' | translate }}
						</span>
						<span *ngIf="getControlWithName('email').errors?.pattern">
							{{ 'companyMembers.invite.dialog.errors.email.pattern' | translate }}
						</span>
						<span *ngIf="getControlWithName('email').errors?.companyMemberExists">
							{{ 'companyMembers.invite.dialog.errors.email.company-member-exists' | translate }}
						</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label> {{ 'companyMembers.invite.dialog.jobTitle.label' | translate }}</mat-label>
					<input matInput formControlName="jobTitle" />
					<mat-icon matSuffix *ngIf="getControlWithName('jobTitle').disabled">lock</mat-icon>
					<mat-error *ngIf="getControlWithName('jobTitle').dirty && getControlWithName('jobTitle').errors">
						<span *ngIf="getControlWithName('jobTitle').errors?.pattern">
							{{ 'companyMembers.invite.dialog.errors.jobPosition.pattern' | translate }}
						</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12 mt-4 mat-body-2">{{ 'companyMembers.invite.dialog.role.title' | translate }}</div>
			<div class="col-12 mt-2 d-flex align-items-center">
				<mat-button-toggle-group formControlName="role" aria-label="Font Style">
					<mat-button-toggle [value]="MemberRole.OWNER">{{
						'companyMembers.invite.dialog.role.owner.title' | translate
					}}</mat-button-toggle>
					<mat-button-toggle [value]="MemberRole.SUPERVISOR">{{
						'companyMembers.invite.dialog.role.supervisor.title' | translate
					}}</mat-button-toggle>
					<mat-button-toggle [value]="MemberRole.EMPLOYEE">{{
						'companyMembers.invite.dialog.role.employee.title' | translate
					}}</mat-button-toggle>
				</mat-button-toggle-group>
				<mat-icon *ngIf="getControlWithName('role').disabled" class="ml-2">lock</mat-icon>
			</div>
			<div class="col-12 mt-4 mat-body-2">
				<ng-container [ngSwitch]="getControlWithName('role').value">
					<span *ngSwitchCase="MemberRole.OWNER" [innerHTML]="'companyMembers.invite.dialog.role.owner.rightsTxt' | translate"> </span>
					<span *ngSwitchCase="MemberRole.SUPERVISOR" [innerHTML]="'companyMembers.invite.dialog.role.supervisor.rightsTxt' | translate">
					</span>
					<span *ngSwitchCase="MemberRole.EMPLOYEE" [innerHTML]="'companyMembers.invite.dialog.role.employee.rightsTxt' | translate">
					</span>
				</ng-container>
			</div>
			<div class="col-12 my-2">
				<ng-container [ngSwitch]="getControlWithName('role').value">
					<span *ngSwitchCase="MemberRole.OWNER" [innerHTML]="'companyMembers.invite.dialog.role.owner.rights' | translate"> </span>
					<span *ngSwitchCase="MemberRole.SUPERVISOR" [innerHTML]="'companyMembers.invite.dialog.role.supervisor.rights' | translate">
					</span>
					<span *ngSwitchCase="MemberRole.EMPLOYEE" [innerHTML]="'companyMembers.invite.dialog.role.employee.rights' | translate"> </span>
				</ng-container>
			</div>
			<div class="col-12 mt-4" [ngClass]="context === 'onboarding' ? 'text-center' : 'text-right'">
				<button mat-stroked-button *ngIf="showBackButton" class="mr-1" (click)="backButtonPressed.emit()">
					{{ backButtonTextKey | translate }}
				</button>

				<button
					*ngIf="operation === CompanyMemberOperation.ADD"
					mat-flat-button
					color="accent"
					type="submit"
					data-cy="setting-add-employee-button-invite-button"
					[disabled]="memberForm.invalid"
					[loading]="isLoading"
				>
					{{ inviteButtonTextKey | translate }}
				</button>
				<button
					*ngIf="operation === CompanyMemberOperation.EDIT && (isLoggedInUserOwner$ | async)"
					mat-flat-button
					color="accent"
					type="submit"
					[disabled]="memberForm.invalid || memberForm.disabled"
					[loading]="isLoading"
				>
					{{ 'companyMembers.invite.dialog.saveTxt' | translate }}
				</button>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #editMemberForm>
	<form [formGroup]="memberForm" (ngSubmit)="editMember()">
		<div class="row">
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.name.label' | translate }}</mat-label>
					<input matInput formControlName="name" required />
					<mat-icon matSuffix *ngIf="getControlWithName('name').disabled">lock</mat-icon>
					<mat-error *ngIf="getControlWithName('name').dirty && getControlWithName('name').errors">
						<span *ngIf="getControlWithName('name').errors?.required">
							{{ 'companyMembers.invite.dialog.errors.name.required' | translate }}
						</span>
						<span *ngIf="getControlWithName('name').errors?.pattern">
							{{ 'companyMembers.invite.dialog.errors.name.pattern' | translate }}
						</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.lastname.label' | translate }}</mat-label>
					<input matInput formControlName="lastname" required />
					<mat-icon matSuffix *ngIf="getControlWithName('lastname').disabled">lock</mat-icon>
					<mat-error *ngIf="getControlWithName('lastname').dirty && getControlWithName('lastname').errors">
						<span *ngIf="getControlWithName('lastname').errors?.required">
							{{ 'companyMembers.invite.dialog.errors.lastname.required' | translate }}
						</span>
						<span *ngIf="getControlWithName('lastname').errors?.pattern">
							{{ 'companyMembers.invite.dialog.errors.lastname.pattern' | translate }}
						</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.email.label' | translate }}</mat-label>
					<input matInput formControlName="email" required />
					<mat-icon matSuffix *ngIf="getControlWithName('email').disabled">lock</mat-icon>
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.jobTitle.label' | translate }}</mat-label>
					<input matInput formControlName="jobTitle" />
					<mat-icon matSuffix *ngIf="getControlWithName('jobTitle').disabled">lock</mat-icon>
					<mat-error *ngIf="getControlWithName('jobTitle').dirty && getControlWithName('jobTitle').errors">
						<span *ngIf="getControlWithName('jobTitle').errors?.pattern">
							{{ 'companyMembers.invite.dialog.errors.jobPosition.pattern' | translate }}
						</span>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.mobile.label' | translate }}</mat-label>
					<input matInput formControlName="mobile" />
					<mat-icon matSuffix *ngIf="getControlWithName('mobile').disabled">lock</mat-icon>
				</mat-form-field>
			</div>
			<div class="col-6" *ngIf="isLoggedInUserOwner$ | async">
				<mat-form-field class="w-100">
					<mat-label>{{ 'companyMembers.invite.dialog.employeeId.label' | translate }}</mat-label>
					<input matInput formControlName="employeeId" />
					<mat-icon matSuffix *ngIf="getControlWithName('employeeId').disabled">lock</mat-icon>
				</mat-form-field>
			</div>
			<div class="col-12 mt-4 mat-body-2">{{ 'companyMembers.invite.dialog.role.title' | translate }}</div>
			<div class="col-12 mt-2 d-flex align-items-center">
				<mat-button-toggle-group formControlName="role" aria-label="Font Style">
					<mat-button-toggle [value]="MemberRole.OWNER">{{
						'companyMembers.invite.dialog.role.owner.title' | translate
					}}</mat-button-toggle>
					<mat-button-toggle [value]="MemberRole.SUPERVISOR">{{
						'companyMembers.invite.dialog.role.supervisor.title' | translate
					}}</mat-button-toggle>
					<mat-button-toggle [value]="MemberRole.EMPLOYEE">{{
						'companyMembers.invite.dialog.role.employee.title' | translate
					}}</mat-button-toggle>
				</mat-button-toggle-group>
				<mat-icon *ngIf="getControlWithName('role').disabled" class="ml-2">lock</mat-icon>
			</div>
			<div class="col-12 mt-4 mat-body-2">
				<ng-container [ngSwitch]="getControlWithName('role').value">
					<span *ngSwitchCase="MemberRole.OWNER" [innerHTML]="'companyMembers.invite.dialog.role.owner.rightsTxt' | translate"> </span>
					<span *ngSwitchCase="MemberRole.SUPERVISOR" [innerHTML]="'companyMembers.invite.dialog.role.supervisor.rightsTxt' | translate">
					</span>
					<span *ngSwitchCase="MemberRole.EMPLOYEE" [innerHTML]="'companyMembers.invite.dialog.role.employee.rightsTxt' | translate">
					</span>
				</ng-container>
			</div>
			<div class="col-12 my-2">
				<ng-container [ngSwitch]="getControlWithName('role').value">
					<span *ngSwitchCase="MemberRole.OWNER" [innerHTML]="'companyMembers.invite.dialog.role.owner.rights' | translate"> </span>
					<span *ngSwitchCase="MemberRole.SUPERVISOR" [innerHTML]="'companyMembers.invite.dialog.role.supervisor.rights' | translate">
					</span>
					<span *ngSwitchCase="MemberRole.EMPLOYEE" [innerHTML]="'companyMembers.invite.dialog.role.employee.rights' | translate"> </span>
				</ng-container>
			</div>
			<div class="col-12 mt-4 text-right">
				<button
					*ngIf="(isLoggedInUserOwner$ | async) || (isMemberHimself$ | async)"
					mat-flat-button
					color="accent"
					type="submit"
					[disabled]="memberForm.pristine || memberForm.invalid || memberForm.disabled"
					[loading]="isLoading"
				>
					{{ 'companyMembers.invite.dialog.saveTxt' | translate }}
				</button>
			</div>
		</div>
	</form>
</ng-template>
