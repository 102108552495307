import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
	AuthLoginExecutedEventBuilder,
	AuthLogoutExecutedEventBuilder,
	AuthRegisterExecutedEventBuilder,
} from '@generated/events/AuthEvents.generated';
import { AlertService } from '@injectables/services/alert/alert.service';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { SentryService } from '@injectables/services/sentry/sentry.service';
import { TrackingService } from '@injectables/services/tracking.service';
import { TocConfirmationDialogComponent } from '@modules/shared/dialog/components/toc-confirmation-dialog/toc-confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ALERT_DURATION } from '@shared/constants/animation';
import { ERROR } from '@shared/constants/firebase';
import { updateProfileToc } from '@shared/firebase/profile/profile-update.functions';
import { SignInProvider } from '@shared/models/sign-in-provider.enum';
import {
	sendEmailVerificationLink,
	setUserRegistrationType,
	updateUserStateAction,
} from '@store/actions/auth.actions';
import { updateLastActiveAction } from '@store/actions/profile.actions';
import { selectProfile } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { UserRegistrationType } from '@store/state/auth.state';
import { Profile } from 'domain-entities';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import OAuthProvider = firebase.auth.OAuthProvider;
import User = firebase.User;
import {AuthServiceError} from '@injectables/services/auth/auth.service.error';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	firebaseUser: firebase.User = null;
	$firebaseUser = new BehaviorSubject<firebase.User>(null);
	fireBaseAuthState: Observable<firebase.User>;
	private logoutActions: (() => Promise<void>)[] = [];
	private loaded = false;

	constructor(
		private readonly afAuth: AngularFireAuth,
		private readonly auth: Auth,
		private readonly router: Router,
		private readonly alertService: AlertService,
		private readonly localStorageService: LocalStorageService,
		private readonly store: Store<AppState>,
		private readonly http: HttpClient,
		private readonly sentryService: SentryService,
		private readonly profileService: ProfileService,
		private readonly translate: TranslateService,
		private readonly trackingService: TrackingService,
		private readonly errorHandlerService: ErrorHandlerService,
		private readonly remoteConfig: RemoteConfig,
		private readonly dialog: MatDialog,
	) {
		this.fireBaseAuthState = afAuth.authState;
		afAuth.authState.subscribe(async (user) => {
			this.firebaseUser = user;
			this.$firebaseUser.next(user);
			this.dispatchUserState(user);
			if (user && user.uid) {
				this.sentryService.personalizeSentry(user.uid, user.email);
			}
			this.loaded = true;
		});
	}

	public authToken(): Observable<string> {
		return idToken(this.auth);
	}

	private login(): void {
		this.router.navigate(['/']);
	}

	isLoaded(): boolean {
		return this.loaded;
	}

	currentUserId(): string {
		return this.firebaseUser !== null ? this.firebaseUser.uid : '';
	}

	currentUserEmail(): string {
		return this.firebaseUser.email;
	}

	async loginWithProvider(type: SignInProvider): Promise<Profile | null> {
		try {
			this.store.dispatch(
				setUserRegistrationType({ registrationType: UserRegistrationType.IN_PROGRESS }),
			);
			const provider = this.getProviderWithAccess(type);
			const { user, additionalUserInfo: userInfo } = await this.afAuth.signInWithPopup(provider);

			if (!user || !userInfo) {
				throw { code: 'auth/data-not-found' };
			}
			this.firebaseUser = user;
			const isNewUser = !(await this.profileService.checkForProfileExistence(user.uid));

			if (isNewUser) {
				const isTocAccepted = await this.getTocUserConfirmation();

				if (!isTocAccepted) {
					await this.deleteUser();
					throw { code: 'auth/toc-not-accepted' };
				}

				const profile = await this.getNewUserProfile(user, userInfo);
				if (!profile) {
					await this.deleteUser();
					throw { code: 'auth/user-data-not-found' };
				} else {
					return profile;
				}
			}
			return null;
		} catch (e) {
			this.store.dispatch(
				setUserRegistrationType({ registrationType: UserRegistrationType.UNAUTHENTICATED }),
			);
			switch (e?.code) {
				case 'auth/account-exists-with-different-credential': {
					this.alertService.showAlert(
						this.translate.instant('registration_error_email_already_in_use'),
					);
					break;
				}
				case 'auth/email-already-in-use': {
					this.alertService.showAlertWithAction(
						ERROR + e.code,
						ALERT_DURATION,
						'button.login',
						this.login,
					);
					break;
				}
				case 'auth/toc-not-accepted':
				case 'auth/cancelled-popup-request':
				case 'auth/popup-closed-by-user': {
					break;
				}
				default: {
					this.alertService.showAlert(e.message);
				}
			}
		}
	}

	async loginWithSocialProvider(type: SignInProvider): Promise<void> {
		await this.setFirebasePersistence(firebase.auth.Auth.Persistence.LOCAL);
		this.loginWithProvider(type)
			.then((profile) => {
				if (profile) {
					profile.verified = true;
					this.trackAuthEvent(type, 'registration');
					return this.profileService.createProfile(this.firebaseUser.uid, profile);
				} else {
					this.trackAuthEvent(type);
				}

				return Promise.resolve();
			})
			.then(() => {
				this.setLastActiveTime();
			})
			.catch((e) => {
				this.store.dispatch(
					setUserRegistrationType({ registrationType: UserRegistrationType.UNAUTHENTICATED }),
				);
				this.alertService.showAlert(ERROR + e.code);
			});
	}

	async getTocDetails(): Promise<{
		toc: Profile['toc'];
		tocDateAgreed: number;
		tocVersionAgreed: number;
	}> {
		const version = Number(await this.remoteConfig.getValueAsync('version_current_toc'));
		const { tocDateAgreed, tocVersionAgreed, toc } = updateProfileToc(
			'accepted',
			moment().unix(),
			version,
		)(null);
		return {
			tocDateAgreed,
			tocVersionAgreed,
			toc,
		};
	}

	private async getNewUserProfile(
		user: firebase.User,
		userInfo: firebase.auth.AdditionalUserInfo,
	): Promise<Profile | null> {
		const profile: Profile = {
			name: undefined,
			lastname: undefined,
			email: undefined,
			company: undefined,
		};

		profile.avvVersionAgreed = 0;
		profile.avvDateAgreed = 0;
		profile.notificationTokens = {};
		const providerProfile = (userInfo.profile || {}) as any;
		const name = user.displayName ? user.displayName.split(' ') : [];
		profile.email = (user.email ? user.email.toLowerCase() : providerProfile.email) || '';
		profile.name =
			providerProfile.given_name || providerProfile.first_name || name.length ? name[0] : '';
		profile.lastname =
			providerProfile.family_name || providerProfile.last_name || name.length
				? name[name.length - 1]
				: '';
		const { tocDateAgreed, tocVersionAgreed, toc } = await this.getTocDetails();

		profile.tocDateAgreed = tocDateAgreed;
		profile.tocVersionAgreed = tocVersionAgreed;
		profile.toc = toc;

		if (!profile.email) {
			return null;
		}

		return profile;
	}

	currentUser(): any {
		return this.firebaseUser !== null ? this.firebaseUser : null;
	}

	isUserLoggedIn(): boolean {
		return this.firebaseUser !== null;
	}

	async signUp(email: string, password: string, signUpFinished: Function): Promise<void> {
		await this.afAuth.useDeviceLanguage();

		await this.setFirebasePersistence(firebase.auth.Auth.Persistence.LOCAL);

		await this.afAuth
			.createUserWithEmailAndPassword(email, password)
			.then((userCredential) => signUpFinished(userCredential.user))
			.catch(async (error) => {
				if (error.code === 'auth/email-already-in-use') {
					this.alertService.showAlert(
						this.translate.instant('registration_error_email_already_in_use'),
					);
					return;
				}

				this.alertService.showAlert(ERROR + error.code);
			});
	}

	async getUserIdToken(forceRefresh = false): Promise<string | undefined> {
		const user = await this.getUser();
		if (!user) {
			return undefined;
		}
		return user.getIdToken(forceRefresh);
	}

	async loginWithEmail(email: string, password: string): Promise<void> {
		try {
			const userCredentials = await this.afAuth.signInWithEmailAndPassword(email, password);
			this.firebaseUser = userCredentials.user;
			await this.trackAuthEvent();
		} catch (exception) {
			throw new AuthServiceError(exception.code);
		}
	}

	async checkPassword(password: string): Promise<void> {
		const user: firebase.User = this.currentUser();
		await user.reauthenticateWithCredential(
			firebase.auth.EmailAuthProvider.credential(user.email, password),
		);
	}

	changeUserPassword(password: string): Promise<void> {
		const user: firebase.User = this.currentUser();
		return user.updatePassword(password);
	}

	updateFirebaseAuthEmail(email: string): Promise<void> {
		const user: firebase.User = this.currentUser();
		return user.updateEmail(email);
	}

	async forgotPassword(email: string): Promise<void> {
		try {
			await this.afAuth.sendPasswordResetEmail(email);
		} catch (error) {}
		await this.router.navigate(['/']);
		this.alertService.showAlert('passwordForgotten.resetSuccess');
	}

	registerLogoutAction(action: () => Promise<void>): void {
		this.logoutActions.push(action);
	}

	async signOut(): Promise<void> {
		const logoutActions = Promise.all(
			this.logoutActions.map(async (action) => {
				try {
					await action();
				} catch (error) {}
			}),
		);
		await Promise.race([timer(5000).toPromise(), logoutActions]);
		try {
			await this.afAuth.signOut();
			await this.trackingService.trackEvent(new AuthLogoutExecutedEventBuilder({}));
		} catch (e) {
			this.errorHandlerService.handleError(e);
		}

		this.localStorageService.remove('tab');
		this.localStorageService.remove('project');
	}

	async deleteUser(): Promise<void> {
		const user = await this.getUser();
		if (user) {
			await user.delete();
		}
	}

	async resendVerification(): Promise<void> {
		const user = await this.getUser();
		if (!user) {
			return;
		}
		this.store.dispatch(sendEmailVerificationLink());
	}

	async reloadUser(): Promise<void> {
		const user = await this.getUser();
		if (!user) {
			return;
		}

		await user.reload();

		this.dispatchUserState(user);
	}

	async verifyEmailVerificationCode(actionCode: string): Promise<boolean> {
		try {
			await this.afAuth.applyActionCode(actionCode);
			return true;
		} catch (e) {
			return false;
		}
	}

	async sendEmailVerificationLink(): Promise<void> {
		await this.http
			.get(`${environment.baseUrl}sendEmailVerificationLink`)
			.pipe(take(1))
			.toPromise();
	}

	async verifyPasswordResetCode(actionCode: string): Promise<string> {
		return this.afAuth.verifyPasswordResetCode(actionCode);
	}

	async confirmResetPassword(actionCode: string, password: string): Promise<void> {
		return this.afAuth.confirmPasswordReset(actionCode, password);
	}

	private dispatchUserState(user: firebase.User): void {
		const userId = user ? user.uid : undefined;
		const emailVerified = user ? user.emailVerified : undefined;
		this.store.dispatch(updateUserStateAction({ userId, emailVerified, email: user?.email }));
	}

	private getUser(): Promise<User> {
		return this.afAuth.user.pipe(take(1)).toPromise();
	}

	async setFirebasePersistence(
		persistence: firebase.auth.Auth.Persistence = firebase.auth.Auth.Persistence.SESSION,
	): Promise<void> {
		return this.afAuth.setPersistence(persistence);
	}

	setLastActiveTime(): void {
		this.store.dispatch(updateLastActiveAction());
	}

	private getProviderWithAccess(
		type: SignInProvider,
	): GoogleAuthProvider | FacebookAuthProvider | OAuthProvider {
		let provider: GoogleAuthProvider | FacebookAuthProvider | OAuthProvider;
		switch (type) {
			case SignInProvider.GOOGLE:
				provider = new firebase.auth.GoogleAuthProvider();
				provider.addScope('profile');
				provider.addScope('email');
				return provider;
			case SignInProvider.FACEBOOK:
				return new firebase.auth.FacebookAuthProvider();
			case SignInProvider.APPLE:
				provider = new firebase.auth.OAuthProvider('apple.com');
				provider.addScope('name');
				provider.addScope('email');
				return provider;
		}
	}

	async trackAuthEvent(
		provider: SignInProvider | 'password' = 'password',
		type: 'registration' | 'login' = 'login',
		additionalPayload: { [key: string]: string } = {},
	): Promise<void> {
		let event: AuthRegisterExecutedEventBuilder | AuthLoginExecutedEventBuilder;

		switch (type) {
			case 'registration':
				event = new AuthRegisterExecutedEventBuilder({
					provider: provider.toLowerCase(),
					...additionalPayload,
				});
				break;
			case 'login':
				event = new AuthLoginExecutedEventBuilder({ provider: provider.toLowerCase() });
				break;
		}

		await this.store.select(selectProfile).pipe(filter(Boolean), take(1)).toPromise();
		await this.trackingService.trackEvent(event);
	}

	getTocUserConfirmation(): Promise<boolean> {
		return this.dialog
			.open(TocConfirmationDialogComponent, {
				width: 'min(100%, 600px)',
				autoFocus: false,
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();
	}
}
