import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import {
	checkEmailVerificationStatusAction,
	resendVerificationAction,
} from '@store/actions/auth.actions';
import { AuthService } from '@injectables/services/auth/auth.service';

@Component({
	selector: 'app-verify-address-dialog',
	templateUrl: './verify-address-dialog.component.html',
	styleUrls: ['./verify-address-dialog.component.scss'],
})
export class VerifyAddressDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		readonly dialogRef: MatDialogRef<VerifyAddressDialogComponent>,
		private readonly store: Store<AppState>,
		private readonly authService: AuthService,
	) {
		dialogRef.disableClose = true;
	}

	logout(): void {
		void this.authService.signOut();
	}

	resendVerification(): void {
		this.store.dispatch(resendVerificationAction());
	}

	checkVerification(): void {
		this.store.dispatch(checkEmailVerificationStatusAction());
	}
}
