import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { PaywallCtaComponent } from './paywall-cta.component';

@NgModule({
	declarations: [PaywallCtaComponent],
	imports: [SharedModule],
	exports: [PaywallCtaComponent],
})
export class PaywallCtaModule {}
