import { NgModule } from '@angular/core';
import { VarDirective } from './ng-var.directive';

const DIRECTIVE = [VarDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class NgVarModule {}
