<div class="d-flex w-100 justify-content-end align-items-end pr-2 pl-2">
	<div class="d-flex w-100 justify-content-between">
		<div>
			<ng-content></ng-content>
		</div>
		<div class="d-flex align-items-end">
			<button (click)="onConfirm()" [disabled]="disabled" color="accent" mat-flat-button [loading]="locked">
				{{ 'subscription.change.confirm' | translate }}
			</button>
		</div>
	</div>
</div>
