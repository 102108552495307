import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	public selectedItemState = new BehaviorSubject(null);

	setMenuItem(menuItem: string): void {
		this.selectedItemState.next(menuItem);
	}

	getMenuItem(): Observable<string> {
		return this.selectedItemState.asObservable();
	}
}
