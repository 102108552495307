import { Pipe, PipeTransform } from '@angular/core';
import { Project } from 'domain-entities';
import { getAddressOfProject } from '@shared/functions/project/project.functions';

@Pipe({
	name: 'projectAddress',
})
export class ProjectAddressPipe implements PipeTransform {
	transform(project: Project): string {
		return getAddressOfProject(project);
	}
}
