<div class="ch-dialog align-items-left">
	<mat-dialog-content class="my-4">
		<div class="w-auto">
			<h2>
				<p>
					{{ 'files-section.dialog.delete.title' | translate }}
				</p>
			</h2>
			<ng-container *ngIf="folders.length; else onlyFiles">
				<p>
					<strong> {{ 'files-section.dialog.delete.folders' | translate }}: </strong>
				</p>
				<ul>
					<li *ngFor="let folder of folders">
						{{ folder.file.name }}
						(<span *ngIf="folder.subFolderCount && folder.subFolderCount > 0"
							>{{ folder.subFolderCount }} {{ 'files-section.dialog.delete.subfolders' | translate }} </span
						>{{ folder.fileCount }} {{ 'files-section.dialog.delete.files' | translate }})
					</li>
				</ul>
				<p *ngIf="files.length">
					<strong> {{ 'files-section.dialog.delete.otherElements' | translate }}: </strong>
				</p>
				<ul>
					<li *ngIf="files.length">{{ files.length }} {{ 'files-section.dialog.delete.files' | translate }}</li>
				</ul>
			</ng-container>
			<ng-template #onlyFiles>
				<ul>
					<li *ngFor="let file of files | slice: 0:3">
						{{ file.file.name }}
					</li>
				</ul>
				<p *ngIf="files.length > 3">
					{{ 'files-section.dialog.delete.otherFiles' | translate: { leftOverFileCount: files.length - 3 } }}
				</p>
			</ng-template>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="justify-content-center align-items-center p-0 flex-column">
		<div>
			<button [mat-dialog-close]="false" data-cy="delete-file-cancel" mat-flat-button>
				{{ 'files-section.dialog.delete.cancel-btn' | translate }}
			</button>
			<button [mat-dialog-close]="true" color="warn" data-cy="delete-file-confirm" mat-flat-button>
				{{ 'files-section.dialog.delete.confirm-btn' | translate }}
			</button>
		</div>
	</mat-dialog-actions>
</div>
