import { BrowserState } from '../state/browser.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
	setCanIndexDbBeMutatedAction,
	setNotificationPermissionAction,
} from '../actions/browser.actions';

const initialState: BrowserState = {
	indexDbCanBeMutated: undefined,
	notificationPermission: undefined,
};

const reducer = createReducer(
	initialState,
	on(setCanIndexDbBeMutatedAction, (state, { indexDbCanBeMutated }) => {
		return { ...state, indexDbCanBeMutated };
	}),
	on(setNotificationPermissionAction, (state, { permission: notificationPermission }) => {
		return { ...state, notificationPermission };
	}),
);

// tslint:disable-next-line:typedef
export function browserReducer(state: BrowserState, action: Action) {
	return reducer(state, action);
}
