import { Component, EventEmitter, Output } from '@angular/core';
import { TaskTime } from 'domain-entities';

@Component({
	selector: 'app-task-time-edit-panel',
	templateUrl: './task-time-edit-panel.component.html',
	styleUrls: ['./task-time-edit-panel.component.scss'],
})
export class TaskTimeEditPanelComponent {
	@Output('closePanel') closePanel = new EventEmitter<void>();
	taskTime: TaskTime;
	taskName: string;
	taskProjectId: string;

	async init(taskTime: TaskTime, taskName: string, taskProjectId: string): Promise<void> {
		this.taskTime = { ...taskTime };
		this.taskName = taskName;
		this.taskProjectId = taskProjectId;
	}
}
