import { FirestoreConnector, RequiredKey } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { File, isFile } from 'domain-entities';
import { EntityChanges } from '@craftnote/shared-utils';
import { DataQueryCondition } from '@craftnote/shared-utils';
import { buildFirestoreDocumentPath } from '@utils/functions/firestore/firestore-path.functions';

const FILE_REQUIRED_FIELDS: RequiredKey<File>[] = ['id', 'projectId'];

// FIXME:: This should be fileConnector, follow the norm which we are following

@Injectable({
	providedIn: 'root',
})
export class ProjectFilesConnector {
	private static COLLECTION_NAME = 'files';

	constructor(private readonly connector: FirestoreConnector) {}

	watchProjectFileById(fileId: string): Observable<File> {
		return this.connector.watchDocument<File>(
			buildFirestoreDocumentPath([
				{ collectionName: ProjectFilesConnector.COLLECTION_NAME, documentId: fileId },
			]),
		);
	}

	watchProjectFiles(projectId: string): Observable<File[]> {
		const condition: DataQueryCondition<File> = {
			field: 'projectId',
			operator: '==',
			value: projectId,
		};
		return this.connector.watchDocuments<File>(ProjectFilesConnector.COLLECTION_NAME, [condition]);
	}

	watchProjectFileEntityChanges(projectId: string): Observable<EntityChanges<File>> {
		const condition: DataQueryCondition<File> = {
			field: 'projectId',
			operator: '==',
			value: projectId,
		};
		return this.connector.watchDocumentsChanges<File>(ProjectFilesConnector.COLLECTION_NAME, [
			condition,
		]);
	}

	createProjectFile(file: File): Promise<void> {
		return this.connector.create(ProjectFilesConnector.COLLECTION_NAME, file.id, file, isFile);
	}

	updateProjectFilePartial(id: string, file: Partial<File>): Promise<void> {
		return this.connector.updateDocumentPartial<File>(
			ProjectFilesConnector.COLLECTION_NAME,
			id,
			file,
			FILE_REQUIRED_FIELDS,
		);
	}

	deleteProjectFile(id: string): Promise<void> {
		return this.connector.deleteDocument(ProjectFilesConnector.COLLECTION_NAME, id);
	}
}
