import { NgModule } from '@angular/core';
import { ViewContainerDirective } from './view-container.directive';

const DIRECTIVE = [ViewContainerDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class ViewContainerModule {}
