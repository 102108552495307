import { Inject, Injectable } from '@angular/core';
import { HEIGHT, WIDTH } from '@shared/constants/thumbnail';
import { FileType } from 'domain-entities';
import { PDF } from '@shared/constants/upload.types';
import { BaseFileService } from '@injectables/services/base-file.service';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ThumbnailService {
	constructor(
		private readonly baseFileService: BaseFileService,
		@Inject(DOCUMENT) public document: Document,
	) {}

	async createVideoThumbnail(file: File): Promise<File> {
		return this.createMediaThumbnail(file, FileType.VIDEO);
	}

	async createImageThumbnail(file: File): Promise<File> {
		return this.createMediaThumbnail(file, FileType.IMAGE);
	}

	private async createMediaThumbnail(file: File, fileType: FileType): Promise<File> {
		const fileReader = new FileReader();
		await new Promise((resolve) => {
			fileReader.readAsDataURL(file);
			fileReader.onloadend = resolve;
		});
		let tagName;
		let widthAttribute: string;
		let heightAttribute: string;
		let callback: string;
		switch (fileType) {
			case FileType.IMAGE:
				tagName = 'img';
				widthAttribute = 'width';
				heightAttribute = 'height';
				callback = 'load';
				break;
			case FileType.VIDEO:
				tagName = 'video';
				widthAttribute = 'videoWidth';
				heightAttribute = 'videoHeight';
				callback = 'loadeddata';
				break;
		}
		const element = this.document.createElement(tagName);

		element.setAttribute('src', fileReader.result as string);
		const result = await this.getEventOrTimeout(element, callback);
		if (!result) {
			return this.getDefaultThumbnail();
		}
		if (fileType === FileType.VIDEO) {
			element.currentTime = element.duration / 2;
			await this.getEventOrTimeout(element, 'seeked');
			if (!result) {
				return this.getDefaultThumbnail();
			}
		}

		const width = element[widthAttribute];
		const height = element[heightAttribute];

		const canvas = this.document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		let ratio, ratio1, ratio2;
		ratio1 = WIDTH / width;
		ratio2 = HEIGHT / height;

		if (ratio1 < ratio2) {
			ratio = ratio1;
		} else {
			ratio = ratio2;
		}

		if (ratio > 1) {
			ratio = 1;
		}

		const newWidth = width * ratio;
		const newHeight = height * ratio;

		canvas.width = newWidth;
		canvas.height = newHeight;

		ctx.drawImage(element as any, 0, 0, newWidth, newHeight);

		return new Promise((resolve) => {
			canvas.toBlob(resolve as BlobCallback, 'image/jpeg', 1);
		});
	}

	async createDocumentThumbnail(file: File): Promise<File | undefined> {
		const fileType = this.baseFileService.getFileExtension(file.name);

		if (fileType !== PDF) {
			return undefined;
		}

		const pdfJs = (window as any).pdfjsLib;

		const fileReader = await this.createReadyFileReader(file);
		const pdf = await pdfJs.getDocument(fileReader.result as string).promise;
		const page = await pdf.getPage(1);

		const canvas = this.document.createElement('canvas');
		const scale_required = canvas.width / page.getViewport({ scale: 1 }).width;
		const viewport = page.getViewport({ scale: scale_required });

		canvas.height = viewport.height;
		canvas.width = viewport.width;
		const ctx = canvas.getContext('2d');
		const renderContext = {
			canvasContext: ctx,
			viewport: viewport,
		};

		try {
			await page.render(renderContext).promise;
			const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			ctx.putImageData(imgData, canvas.width, canvas.height);
		} catch (error) {
			return undefined;
		}

		return new Promise((resolve) => {
			return canvas.toBlob(resolve as BlobCallback, 'image/jpeg', 0.8);
		});
	}

	private async createReadyFileReader(file: File): Promise<FileReader> {
		const fileReader = new FileReader();
		await new Promise((resolve) => {
			fileReader.readAsDataURL(file);
			fileReader.onloadend = resolve;
		});
		return fileReader;
	}

	private getEventOrTimeout(element: HTMLElement, callback: string): Promise<any> {
		const timeout$ = timer(5000)
			.pipe(map((_) => undefined))
			.toPromise();
		const callback$ = new Promise((resolve) => {
			element.addEventListener(callback, resolve);
		});
		return Promise.race([timeout$, callback$]);
	}

	private async getDefaultThumbnail(): Promise<File> {
		const response = fetch('http://localhost:4200/assets/images/logo_dark.svg');
		return (await (await response).blob()) as File;
	}
}
