import { OrderByType, SortByType } from 'domain-entities';

export enum GroupByOption {
	NONE = 'NONE',
	STATUS = 'STATUS',
}

export interface ProjectSortConfig {
	sortDirection: OrderByType;
	sortBy: SortByType;
	groupBy?: GroupByOption;
}
