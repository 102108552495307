import { Component } from '@angular/core';
import { map, pluck, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TrackedTime } from 'domain-entities';
import { TrackedTimesProjectsService } from '@modules/shared/components/time-tracking-add-edit/components/tracked-times-projects/tracked-times-projects.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isEmployeeOfCompany, selectUserId } from '@store/selectors/app.selectors';
import moment from 'moment';
import { ALERT_DURATION } from '@shared/constants/animation';
import { selectAllProjectsEntities } from '@store/selectors/projects.selectors';
import { ProjectService } from '@injectables/services/project/project.service';
import { AlertService } from '@injectables/services/alert/alert.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { selectCompanyProjectsEntities } from '@store/selectors/company-projects.selectors';
import { orderBy } from 'lodash';
import { isUserInternalProjectAdmin } from '@shared/functions/project/project.functions';
import { shareReplayOne } from '@craftnote/shared-utils';

export enum TrackedTimesFilterBy {
	ALL = 'all',
	ASSIGNED_TO_ME = 'assignedToMe',
}

enum TrackedTimesListType {
	OVER_DUE = 'overDue',
	DUE_TODAY = 'dueToday',
	UP_COMING = 'upComing',
}

interface TrackedTimesList {
	list: TrackedTime[];
	title: string;
	type: TrackedTimesListType;
}

@Component({
	selector: 'app-tracked-times-list',
	templateUrl: './tracked-times-list.component.html',
	styleUrls: ['./tracked-times-list.component.scss'],
})
export class TrackedTimesListComponent {
	readonly TrackedTimesFilterBy = TrackedTimesFilterBy;
	readonly projectId$ = this.activatedRoute.params.pipe(pluck('projectId'), shareReplayOne());
	readonly trackedTimesFilterBy$$ = new BehaviorSubject<TrackedTimesFilterBy>(
		this.activatedRoute.snapshot.queryParamMap.get('filter') === TrackedTimesFilterBy.ASSIGNED_TO_ME
			? TrackedTimesFilterBy.ASSIGNED_TO_ME
			: TrackedTimesFilterBy.ALL, // Default filter value
	);
	readonly trackedTimesListUI$ = this.trackedTimesFilterBy$$.pipe(
		switchMap((trackedTimesFilterBy) =>
			trackedTimesFilterBy === TrackedTimesFilterBy.ASSIGNED_TO_ME
				? this.currentUserTrackedTimes$
				: this.allTrackedTimes$,
		),
		map(
			(trackedTimes) => this.segregateTrackedTimes(orderBy(trackedTimes, ['startTime'], ['desc'])),
			shareReplayOne(),
		),
	);
	readonly noEntriesInView$ = this.trackedTimesListUI$.pipe(
		map((trackedTimeListUI) =>
			trackedTimeListUI.reduce((listUI, currentItem) => {
				listUI.push(...currentItem.list);
				return listUI;
			}, []),
		),
		map((listUI) => listUI.length === 0),
	);
	projects$ = combineLatest([
		this.store.select(selectCompanyProjectsEntities),
		this.store.select(selectAllProjectsEntities),
	]).pipe(
		map(([companyProjectsEntities, activeProjectsEntities]) => ({
			...companyProjectsEntities,
			...activeProjectsEntities,
		})),
		shareReplayOne(),
	);
	private readonly allTrackedTimes$ = combineLatest([
		this.projects$,
		this.projectId$,
		this.store.select(selectUserId),
	]).pipe(
		switchMap(([projects, projectId, userId]) => {
			return this.trackedTimesProjectsService
				.getTrackedTimes(projects[projectId].company, {
					projectIds: [projectId],
				})
				.pipe(
					map((times) =>
						isUserInternalProjectAdmin(userId, projects[projectId])
							? times
							: times.filter((time) => time.userId === userId),
					),
				);
		}),
		map((trackedTimes) =>
			trackedTimes.map((trackedTime) => {
				trackedTime['trackedTimeCanNotEditObservable'] = this.trackedTimeCanNotEdit(trackedTime);
				return trackedTime;
			}),
		),
		shareReplayOne(),
	);
	readonly allTrackedTimesLength$ = this.allTrackedTimes$.pipe(pluck('length'));
	private readonly currentUserTrackedTimes$ = combineLatest([
		this.allTrackedTimes$,
		this.store.select(selectUserId),
	]).pipe(
		map(([allTrackedTimes, userId]) =>
			allTrackedTimes.filter((trackedTimes) => trackedTimes.userId === userId),
		),
		shareReplayOne(),
	);
	readonly assignedToMeTrackedTimesLength$ = this.currentUserTrackedTimes$.pipe(pluck('length'));

	constructor(
		public readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly trackedTimesProjectsService: TrackedTimesProjectsService,
		private readonly store: Store<AppState>,
		private readonly projectService: ProjectService,
		private readonly alertService: AlertService,
		private readonly translateService: TranslateService,
		private readonly snackBar: MatSnackBar,
	) {}

	getStartEndDuration({ startTime, endTime, pauseDuration }: TrackedTime): {
		hours: number;
		minutes: number;
	} {
		const duration = endTime - startTime - pauseDuration;
		return {
			hours: Math.floor(duration / 3600),
			minutes: Math.floor((duration % 3600) / 60),
		};
	}

	segregateTrackedTimes(trackedTimes: TrackedTime[]): TrackedTimesList[] {
		return trackedTimes.reduce(
			(trackedTimesListUI: TrackedTimesList[], trackedTime: TrackedTime) => {
				const [upComingList, dueTodayList, overDueList] = trackedTimesListUI;

				// overDue
				if (moment.unix(trackedTime.startTime).isBefore(moment().startOf('day'))) {
					overDueList.list.push(trackedTime);
					return trackedTimesListUI;
				}
				// dueToday
				if (
					moment
						.unix(trackedTime.startTime)
						.isBetween(moment().startOf('day'), moment().endOf('day'), undefined, '[]')
				) {
					dueTodayList.list.push(trackedTime);
					return trackedTimesListUI;
				}
				// upComing
				upComingList.list.push(trackedTime);
				return trackedTimesListUI;
			},
			[
				{
					list: [],
					title: this.translateService.instant('project-task-list.title-upcoming'),
					type: TrackedTimesListType.UP_COMING,
				},
				{
					list: [],
					title: this.translateService.instant('project-task-list.title-today'),
					type: TrackedTimesListType.DUE_TODAY,
				},
				{
					list: [],
					title: this.translateService.instant('project-task-list.title-over-due'),
					type: TrackedTimesListType.OVER_DUE,
				},
			],
		);
	}

	async updateTrackedTimesFilterBy(filter: TrackedTimesFilterBy): Promise<void> {
		await this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { filter } });
		this.trackedTimesFilterBy$$.next(filter);
	}

	trackedTimeCanNotEdit(trackedTime: TrackedTime): Observable<string> {
		return combineLatest([
			this.store.select(isEmployeeOfCompany),
			this.store.select(selectAllProjectsEntities),
			this.store.select(selectUserId),
		]).pipe(
			switchMap(async ([isEmployee, projectsEntities, currentUserId]) => {
				const isExternalMemberOfProject = await this.projectService
					.isExternalMemberOfProject$(projectsEntities[trackedTime.projectId])
					.pipe(take(1))
					.toPromise();

				if ((isEmployee || isExternalMemberOfProject) && currentUserId !== trackedTime.userId) {
					return this.translateService.instant('project-task-list.edit-denied-access');
				}
				return '';
			}),
			shareReplayOne(),
		);
	}

	async openTrackedTimeEdit(
		trackedTime: TrackedTime,
		trackedTimeCanNotEdit: string,
	): Promise<void> {
		if (trackedTimeCanNotEdit) {
			this.alertService.showAlertWithAction(
				trackedTimeCanNotEdit,
				ALERT_DURATION,
				'button.ok',
				this.snackBar.dismiss,
			);
			return;
		}
		const currentFilterValue = await this.trackedTimesFilterBy$$.pipe(take(1)).toPromise();
		await this.router.navigate([trackedTime.id, 'edit'], {
			queryParams: {
				filter: currentFilterValue,
			},
			relativeTo: this.activatedRoute,
		});
	}
}
