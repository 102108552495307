<div *ngIf="pdfExportActive" class="row export-panel form-group no-gutters">
	<form class="form mt-3" role="form">
		<div class="row checkboxes">
			<div class="col-6">
				<mat-checkbox
					(change)="selectAll($event.checked, $event)"
					[(ngModel)]="exportAll"
					[ngModelOptions]="{ standalone: true }"
					class="col-10"
					style="text-decoration: underline"
					>{{ 'project.selectAll' | translate }}
				</mat-checkbox>
			</div>
			<div class="col-6">
				<mat-radio-group
					(change)="docTypeChange($event)"
					[(ngModel)]="docExportType"
					[ngModelOptions]="{ standalone: true }"
					[value]="docExportType"
					class="d-flex col-12 mt-0 justify-content-end"
				>
					<mat-radio-button [value]="'pdf'" style="margin-left: 12px">
						{{ 'pdf' }}
					</mat-radio-button>
					<mat-radio-button *appHasLimits="[profileLimitKey.DOC_X_REPORTS]" [value]="'docx'" style="margin-left: 12px">
						{{ 'docx' }}
					</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
	</form>
</div>

<div *ngIf="project && (isLoading$ | async)" class="spinner">
	<div class="spinner-inner">
		<mat-spinner color="accent"></mat-spinner>
	</div>
</div>

<div
	*ngIf="project"
	[ngClass]="getChatContentClass$ | async"
	class="chat-content d-flex flex-grow-1 no-gutters with-new-chat-view w-100"
	[class.pdf-export-enabled]="isPdfExportEnabled$ | async"
>
	<div
		*ngIf="!isFolder"
		(filesChanged)="onFilesChange($event)"
		appDnd
		class="chat-drag-drop col d-flex flex-column flex-grow-1 h-100"
		[ngClass]="tagsVisible ? 'col-8' : 'col-12'"
		dropzone="move"
	>
		<ng-container *ngIf="!isFolder">
			<div class="row no-gutters px-3 py-2 bg-white chat-search-container">
				<app-chat-search *ngIf="project" [project]="project" [chatFilterMenuContainer]="chatFilterMenuContainer" class="d-flex col">
				</app-chat-search>
				<div *ngIf="userCanExportChat$ | async" class="col-auto d-flex align-items-center invert-color pt-2">
					<a href="javascript:void(0)" (click)="goToPDFExportToggle()">
						<ng-container>
							<img [class.d-none]="isPdfExportEnabled$ | async" src="assets/images/icons/chat/export-icon.svg" alt="export-icon" />
							<img
								[class.d-none]="!(isPdfExportEnabled$ | async)"
								src="assets/images/icons/chat/export-icon-active.svg"
								alt="export-icon-active"
							/>
						</ng-container>
					</a>
				</div>
			</div>
			<div id="chat-filter-menu">
				<div appViewContainer #chatFilterMenuContainer="appViewContainer"></div>
			</div>
		</ng-container>

		<div class="drag-content" dropzone="move">{{ 'chat.drop' | translate }}</div>

		<div class="d-flex flex-column flex-grow-1 message-container" [class.with-pdf-export]="isPdfExportEnabled$ | async">
			<div
				#messageContainer
				(onScroll)="scrollEvent($event)"
				(paste)="onPaste($event)"
				*ngIf="!isFolder"
				class="flex-grow-1 pb-3 px-3 overflow-auto message-content"
				data-cy="chat-middle-frame"
				detect-scroll
				lock-y-offset="20"
				appAutoScroll
				observe-attributes
			>
				<ng-container *ngFor="let message of chatMessages$ | async; trackBy: chatMessageTrackBy">
					<div *ngIf="getMessageDate$(message) | async as stickyDate" class="message-date sticky-top pt-4 pb-3">
						<span>{{ stickyDate | messageDate }}</span>
					</div>
					<app-chat-message
						[company]="company"
						[(exportList)]="exportList"
						[message]="message"
						[pdfExportActive]="pdfExportActive"
						[project]="project"
						[file]="files[message.id]"
						[isPreviewAvailable]="checkPreviewAvailablity(message)"
						(replyTo)="replyToMessage($event)"
						[messageDates]="messageDates"
						[messageSend]="isTextMessageSend(message)"
						(getFile)="getFile($event)"
						(goToTagging)="goToTagging($event)"
						(shareMessageToFile)="shareMessageToFile($event)"
						(showImage)="showFile($event)"
						(showPdf)="showFile($event)"
						(showVideo)="showFile($event)"
						(gotToParent)="goToParentMessage($event)"
						[files]="files"
						*ngIf="!pdfExportActive || (pdfExportActive && canBeExported(message))"
						[attr.id]="message.id"
						[ngClass]="{ animate__flash: message.id === (selectedParentMessage$ | async) }"
						data-cy="chat-message"
					>
					</app-chat-message>
				</ng-container>

				<ng-template [ngIf]="!(chatMessages$ | async).length">
					<app-empty-state [type]="type"></app-empty-state>
				</ng-template>
			</div>
			<div *ngIf="!isFolder && !pdfExportActive" class="w-100 pt-2 px-3">
				<div *ngIf="isSearchedOrFiltered$ | async; then searchText; else chatMessageForm"></div>
			</div>
		</div>

		<div *ngIf="!isFolder && pdfExportActive" class="row footer-export no-gutters w-100">
			<div class="footer-export__buttons bg-primary col-12">
				<div class="row no-gutters">
					<div class="col-12">
						<span>{{ 'project.messages_selected' | translate: getNumberOfMessages() }}</span>
						<button (click)="exportToPdf()" mat-stroked-button class="ml-3 text-white">
							{{ 'project.report' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div [ngClass]="tagsVisible ? 'col-4' : 'col-0'" class="col p-0 h-100">
		<ng-container *ngIf="project && tagsVisible" [ngTemplateOutlet]="tagsView"></ng-container>
	</div>
</div>

<ng-template #searchText>
	<div class="row h-100">
		<div class="col-12 filter-padding">
			<span>{{ 'project.search.result' | translate }}</span>
			<span>{{ 'project.search.term' | translate }}{{ getSearchText$ | async }} </span>
			<strong (click)="clearSearchAndFilter()" class="clear-text pointer" data-cy="chat-clear-search">
				{{ 'project.search.chatClearAllFilters' | translate }}
			</strong>
		</div>
	</div>
</ng-template>

<ng-template #sendMessageForm>
	<form>
		<div class="row h-100 send-message-form align-items-end">
			<label [ngClass]="{ invisible: replyMessage$ | async }" class="col-auto text-center send-message-form__attachment" for="file">
				<em class="material-icons icon pointer">attach_file</em>
				<input (change)="fileEvent($event)" hidden id="file" multiple name="file" type="file" />
			</label>
			<div class="col">
				<div class="input-group input-height align-items-end">
					<div class="w-100">
						<ng-container [ngTemplateOutlet]="replyMessageTemplate"></ng-container>
						<textarea
							#chatMessageInput
							(keydown.enter)="sendMessage(MessageType.TEXT)"
							[(ngModel)]="content"
							[ngClass]="{ 'remove-border-top-radius': replyMessage$ | async }"
							autocomplete="off"
							class="input-message"
							id="message"
							maxlength="10000"
							name="message"
							placeholder="{{ 'chat.placeholder' | translate }}"
							rows="2"
							type="text"
						></textarea>
					</div>
					<button #emojiMenu="matMenuTrigger" [matMenuTriggerFor]="emojiContainer" class="chat-button ml-2">
						<em class="material-icons icon pointer">mood</em>
					</button>
					<button (click)="sendMessage(MessageType.TEXT)" class="chat-button last ml-2">
						<em class="material-icons icon pointer">send</em>
					</button>
				</div>
				<p class="m-0 send-hint grey-both">{{ 'chat.send.hint' | translate }}</p>
			</div>
		</div>
	</form>
</ng-template>

<mat-menu #emojiContainer yPosition="above">
	<div appEventStopPropagation class="emoji-container">
		<emoji-mart
			(emojiSelect)="onEmojiSelect($event)"
			[showPreview]="false"
			emoji="point_up"
			isNative="true"
			set="google"
			[darkMode]="isDarkTheme$ | async"
			[i18n]="emojiTranslatedKeys"
			title="Pick your emoji…"
		></emoji-mart>
	</div>
</mat-menu>

<ng-template #replyMessageTemplate>
	<div *ngIf="replyMessage$ | async as replyMessage" class="send-message-form__message pb-3">
		<app-reply-message [file]="files[replyMessage.id]" [replyMessage]="replyMessage">
			<div class="row no-gutters">
				<div class="col-11 font-weight-bold">
					{{ 'chat.reply.replying-to' | translate: { name: replyMessage.author } }}
				</div>
				<div class="col-1 text-right">
					<mat-icon (click)="clearReplyMessage()" class="pointer">close</mat-icon>
				</div>
			</div>
		</app-reply-message>
	</div>
</ng-template>

<ng-template #tagsView>
	<app-tagging-action-dialog
		(close)="onCloseTagsDialog()"
		[company]="company | async"
		[message]="tagsActiveMessage"
		[project]="project"
	></app-tagging-action-dialog>
</ng-template>

<ng-template #chatMessageForm>
	<div class="d-flex mb-1 align-items-end">
		<div class="d-flex flex-grow-1 flex-column">
			<ng-container [ngTemplateOutlet]="replyMessageTemplate"></ng-container>
			<mat-form-field class="flex-grow-1" appearance="fill">
				<textarea
					matInput
					class="m-0 p-0 message-textarea"
					cdkTextareaAutosize
					#chatMessageInput
					(keydown.enter)="sendMessage(MessageType.TEXT, $event)"
					(keyup)="updateInputCache()"
					(paste)="onPaste($event)"
					[(ngModel)]="content"
					#autosize="cdkTextareaAutosize"
					placeholder="{{ 'chat.placeholder' | translate }}"
					cdkAutosizeMinRows="1"
					cdkAutosizeMaxRows="5"
					data-cy="chat-input-box"
				></textarea>
			</mat-form-field>
		</div>
		<div class="d-flex chat-buttons">
			<button
				mat-stroked-button
				class="ml-2 px-2 pb-1"
				(click)="inputFile.click()"
				data-cy="chat-attach-file"
				[disabled]="replyMessage$ | async"
			>
				<mat-icon>attach_file</mat-icon>
				<input (change)="fileEvent($event)" hidden id="file" multiple name="file" type="file" #inputFile />
			</button>
			<button
				mat-stroked-button
				class="mx-2 px-2"
				#emojiMenu="matMenuTrigger"
				[matMenuTriggerFor]="emojiContainer"
				data-cy="chat-smilie-icon"
			>
				<mat-icon>mood</mat-icon>
			</button>
			<button mat-flat-button class="px-3" color="accent" (click)="sendMessage(MessageType.TEXT)" data-cy="chat-send-message">
				<mat-icon class="mx-3">send</mat-icon>
			</button>
		</div>
	</div>
	<div class="m-0 send-hint grey-both" [innerHTML]="'chat.send.hint' | translate"></div>
</ng-template>
