import { animate, style, transition, trigger } from '@angular/animations';

export const blockInitialAnimation = trigger('blockInitialRenderAnimation', [
	transition(':enter', []),
]);

export const enterExitLeftAnimation = trigger('enterExitLeft', [
	transition(':enter', [
		style({ opacity: 0, transform: 'translateX(400px)' }),
		animate('250ms', style({ opacity: 1, transform: 'translateX(0)' })),
	]),
	transition(':leave', [animate('250ms', style({ opacity: 0, transform: 'translateX(-400px)' }))]),
]);
