import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';

export enum DEVICE_TYPE {
	ANDROID,
	IOS,
	OTHER,
}

@Injectable({
	providedIn: 'root',
})
export class DeviceTypeService {
	constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

	getDeviceType(): DEVICE_TYPE {
		if (/iPhone|iPad/.test(navigator.userAgent)) {
			return DEVICE_TYPE.IOS;
		} else if (/Android/.test(navigator.userAgent)) {
			return DEVICE_TYPE.ANDROID;
		} else {
			return DEVICE_TYPE.OTHER;
		}
	}

	public isTouchDevice(): boolean {
		return !!this.windowRef.matchMedia('(pointer: coarse)').matches;
	}
}
