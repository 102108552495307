import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectUnauthenticatedToLogin } from '@injectables/services/route-guard-functions';
import { CompanyInitializationComponent } from './company-initialization.component';
import { CompanyInitializationGuard } from '@injectables/guards/company-initialization/company-initialization.guard';

const routes: Routes = [
	{
		path: '',
		component: CompanyInitializationComponent,
		pathMatch: 'full',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
		},
		canActivate: [CompanyInitializationGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CompanyInitializationRoutingModule {}
