import { Inject, Injectable } from '@angular/core';
import { ScriptLoaderService } from '@injectables/services/script-loader.service';
import { WINDOW } from '@craftnote/shared-utils';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { EventParams } from '@globalTypes/interfaces/event';
import { HttpClient } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { createApiTokenHeaders } from '@craftnote/shared-utils';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { TocService } from '@injectables/services/toc.service';
import {
	ConsentManagementService,
	DataProcessingService,
} from '@injectables/services/consent-management/consent-management.service';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';

const iterableScriptURL = 'https://js.iterable.com/analytics.js';
const iterableApiURL = 'https://api.iterable.com/api';

@Injectable({
	providedIn: 'root',
})
export class IterableService {
	private isScriptLoaded$ = new BehaviorSubject(false);
	private isConsentGiven$ = this.consentService.watchServiceConsent(DataProcessingService.ITERABLE);
	private isUserSet$ = new BehaviorSubject(false);
	private iterableObj;

	constructor(
		private readonly scriptLoaderService: ScriptLoaderService,
		private readonly remoteConfig: RemoteConfig,
		private readonly httpClient: HttpClient,
		@Inject(WINDOW) private readonly windowRef: Window,
		private readonly errorHandler: ErrorHandlerService,
		private readonly tocService: TocService,
		private readonly consentService: ConsentManagementService,
		@Inject(ENVIRONMENT) private readonly environmentRef: Environment,
	) {
		void this.loadIterableScript();
	}

	private getApiKey(): Promise<string> {
		return this.remoteConfig.getConfigAsync('iterable_api_key');
	}

	async removeUser(): Promise<void> {
		this.isUserSet$.next(false);
	}

	async loadIterableScript(): Promise<void> {
		await this.isConsentGiven$.pipe(filter(Boolean), take(1)).toPromise();
		await this.scriptLoaderService.loadScript('iterable-sdk', iterableScriptURL);
		this.iterableObj = this.windowRef['_iaq'];
		const apiKey = await this.getApiKey();
		this.iterableObj.account(apiKey);
		this.isScriptLoaded$.next(true);
	}

	async setUserWithEmail(email: string): Promise<void> {
		await this.isScriptLoaded$.pipe(filter(Boolean), take(1)).toPromise();
		this.iterableObj.identify(email, {});
		await this.sendEmailToServiceWorker(email);
		this.isUserSet$.next(true);
	}

	async trackEvent(eventName: string, payload: EventParams): Promise<void> {
		if (!this.environmentRef.production) {
			console.log('IterableService: trackEvent');
		}
		const isConsentGiven = await this.isConsentGiven$.pipe(take(1)).toPromise();
		if (!isConsentGiven) {
			return;
		}

		await combineLatest([this.isScriptLoaded$, this.isUserSet$])
			.pipe(
				filter((status) => status.every(Boolean)),
				take(1),
			)
			.toPromise();
		if (!this.environmentRef.production) {
			console.log('Iterable', eventName, payload);
		}
		this.iterableObj.track(eventName, payload);
	}

	async setUserToken(email: string, browserToken: string): Promise<void> {
		const isTocEnabled = await this.tocService.isCompanyAccepted();
		if (!isTocEnabled) {
			return;
		}
		const apiKey = await this.getApiKey();
		await this.httpClient
			.post(
				`${iterableApiURL}/users/registerBrowserToken`,
				{
					email,
					browserToken,
				},
				{
					headers: createApiTokenHeaders(apiKey),
				},
			)
			.toPromise()
			.catch((error) => this.errorHandler.handleError(error));
	}

	private async sendEmailToServiceWorker(email: string): Promise<void> {
		try {
			const serviceWorkers = this.windowRef.navigator.serviceWorker;
			const firebaseServiceWorker = (await serviceWorkers.getRegistrations()).find((worker) =>
				worker.active?.scriptURL.match('firebase-messaging-sw.js'),
			);
			firebaseServiceWorker?.active.postMessage({
				email,
			});
		} catch (e) {
			this.errorHandler.handleError(e);
		}
	}
}
