<mat-form-field class="select-search-dropdown">
	<mat-select
		[multiple]="config.multiple"
		panelClass="select-search-dropdown-panel"
		[disabled]="isDropdownDisabled"
		[(value)]="value"
		(valueChange)="valueChange.emit($event)"
		(openedChange)="panelStateChanged($event)"
		[compareWith]="compareWith"
		[tabIndex]="-1"
	>
		<mat-form-field *ngIf="config.search" appearance="fill" class="search-input rounded px-2 pt-2">
			<input
				matInput
				autocomplete="off"
				[placeholder]="config.searchPlaceholder"
				[formControl]="searchControl"
				data-cy="filter-search-box"
			/>
			<div matSuffix>
				<button mat-icon-button [class.d-none]="searchControl.value.length">
					<mat-icon>search</mat-icon>
				</button>
				<button mat-icon-button [class.d-none]="!searchControl.value.length" (click)="clearSearchInput()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</mat-form-field>
		<nav class="d-flex justify-content-center" mat-tab-nav-bar *ngIf="config.groupIds?.length > 0" [tabPanel]="tabPanel">
			<a
				mat-tab-link
				*ngFor="let groupId of config.groupIds"
				(click)="selectedGroup$$.next(groupId)"
				[active]="(selectedGroup$$ | async) === groupId"
				class="px-3"
				[disabled]="!(groupedOptionValues$$ | async)[groupId]?.length"
			>
				{{ groupId | translate }} ({{ (groupedOptionValues$$ | async)[groupId]?.length || 0 }})
			</a>
		</nav>
		<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

		<div class="multi-select-clear-button px-2" [class.invisible]="!isValueSelected">
			<span class="d-inline-block m-0 mat-subheading-2 p-2 pointer text-primary" (click)="clearSelection()">
				<ng-container *ngIf="config.multiple">{{ 'basic-dropdown.clear-all-selection' | translate }}</ng-container>
				<ng-container *ngIf="!config.multiple">{{ 'basic-dropdown.clear-selection' | translate }}</ng-container>
			</span>
		</div>

		<div
			class="select-options-container"
			[ngClass]="config.search ? 'border-top pt-2' : ''"
			[style]="{ minWidth: config.minWidth, minHeight: config.minHeight }"
		>
			<ng-container *ngVar="displayedOptions$ | async as filteredOptionValues">
				<mat-option class="text-only" *ngIf="filteredOptionValues.length === 0">
					{{ 'basic-dropdown.search-no-result' | translate }}
				</mat-option>
				<mat-option
					[ngClass]="{ 'with-onclick-listener': option.onClick, 'd-none': !option.visible }"
					*ngFor="let option of filteredOptionValues"
					[value]="option.value"
					[disabled]="option.value.disabled"
					(click)="option.value.onClick && option.onClick(option.value, $event)"
					[matTooltip]="option.value.tooltip"
					[matTooltipDisabled]="!option.value.tooltip"
					matTooltipClass="ch-tooltip"
					data-cy="filtered-option-checkbox"
				>
					{{ option.value.value }}
				</mat-option>
			</ng-container>
		</div>
	</mat-select>
</mat-form-field>

<button
	mat-flat-button
	class="icon-button ch-secondary-btn"
	[ngClass]="{ active: isValueSelected, 'primary-background': config.background === 'primary' }"
	[ngStyle]="{ color: config.fontColor }"
	[disabled]="disabled"
	(click)="onClickDropdownButton()"
>
	<mat-icon *ngIf="config.icon" [fontSet]="config.iconFontSet">{{ config.icon }}</mat-icon>
	<span class="mx-2">
		<span [ngClass]="config.buttonTextClasses" [class.font-weight-bold]="isValueSelected">{{ currentSelectedValueText }}</span>
	</span>
	<mat-icon data-cy="dropdown-btn-dashboard">expand_more</mat-icon>
</button>
