import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { selectUserProfileLimitsSelector } from '@store/selectors/profile-limits.selectors';

@Injectable({ providedIn: 'root' })
export class ProfileLimitsGuard {
	constructor(private readonly store: Store<AppState>, private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		const profileLimits = await this.store
			.select(selectUserProfileLimitsSelector)
			.pipe(
				filter((profileLimitsState) => !!profileLimitsState),
				take(1),
			)
			.toPromise();

		const profileHasAccess = (route.data.profileLimits || []).some(
			(profileLimit) => profileLimits[profileLimit],
		);

		if (profileHasAccess) {
			return true;
		}

		if (route.data.redirectUnauthorisedTo) {
			return this.router.parseUrl(route.data.redirectUnauthorisedTo);
		}

		return false;
	}
}
