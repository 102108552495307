import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_PATTERN, REGISTRATION_PHONE_NUMBER_PATTERN } from '@constants/validators.constants';
import {
	checkPasswordStrength,
	PasswordStrength,
} from '@modules/authentication/components/helper/password-strength.function';
import { Store } from '@ngrx/store';
import { AlertService } from '@injectables/services/alert/alert.service';
import { ERROR } from '@shared/constants/firebase';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { LinkService } from '@injectables/services/link/link.service';
import { Profile } from 'domain-entities';
import { SignInProvider } from '@shared/models/sign-in-provider.enum';
import { sendEmailVerificationLink, setUserRegistrationType } from '@store/actions/auth.actions';
import { selectIsLoggedIn } from '@store/selectors/app.selectors';
import { selectInvitationLink } from '@store/selectors/invitations.selectors';
import { AppState } from '@store/state/app.state';
import firebase from 'firebase/compat/app';
import { isNil, isNull, omitBy } from 'lodash';
import { UserRegistrationType } from '@store/state/auth.state';
import { TocService } from '@injectables/services/toc.service';
import { from } from 'rxjs';

/**
 * The following order should be the same, at the end the concatenated string is going to be in the format of
 * utm_source::utm_medium::utm_campaign::utm_term::utm_content
 */
const RegisterEventKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
	SignInProvider = SignInProvider;
	Object = Object;
	emailPattern = EMAIL_PATTERN;
	phoneNumberPattern = REGISTRATION_PHONE_NUMBER_PATTERN;

	@ViewChild('registerForm')
	registerForm: NgForm;
	passwordStrength = PasswordStrength;
	profile: Profile = {
		name: undefined,
		lastname: undefined,
		email: undefined,
		company: undefined,
	};
	email: string;
	password: string;
	confirm: string;
	isPasswordVisibility = true;
	agreed = false;
	agreedAVV = false;
	inputEventsSent: { [keys: string]: boolean } = {};
	invitationLink$ = this.store.select(selectInvitationLink);
	isUserAuthenticated$ = this.store.select(selectIsLoggedIn);
	isTocEnabled$ = from(this.tocService.isFeatureEnabled());

	constructor(
		private alertService: AlertService,
		private authService: AuthService,
		private profileService: ProfileService,
		public linkService: LinkService,
		private router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly store: Store<AppState>,
		private readonly tocService: TocService,
	) {}

	register(): void {
		if (!this.registerForm || !this.registerForm.valid) {
			this.alertService.showAlert('error.form.fields');
			return;
		}

		if (!this.passwordIsValid()) {
			this.alertService.showAlert('error.password.rules');
			return;
		}
		void this.signUp();
	}

	async signUp(): Promise<void> {
		this.store.dispatch(
			setUserRegistrationType({ registrationType: UserRegistrationType.IN_PROGRESS }),
		);
		this.profile.avvVersionAgreed = 0;
		this.profile.avvDateAgreed = 0;
		this.profile.notificationTokens = {};
		this.profile.email = this.email.toLowerCase();

		const { tocDateAgreed, tocVersionAgreed, toc } = await this.authService.getTocDetails();

		this.profile.tocDateAgreed = tocDateAgreed;
		this.profile.tocVersionAgreed = tocVersionAgreed;
		this.profile.toc = toc;

		this.authService.signUp(this.email, this.password, this.signUpFinished).catch();
	}

	getRule(valid: boolean): string {
		if (valid) {
			return 'rule-ok';
		}

		return 'rule-error';
	}

	back(): void {
		this.router.navigate(['/']);
	}

	isLoaded(): boolean {
		return this.authService.isLoaded();
	}

	hasEightSigns(): boolean {
		return this.password && this.password.length >= 8;
	}

	getPasswordStrength(): PasswordStrength {
		return checkPasswordStrength(this.password);
	}

	passwordIsValid(): boolean {
		return this.hasEightSigns() && this.agreed && this.agreedAVV;
	}

	async registerWithProvider(type: SignInProvider): Promise<void> {
		const referralId = this.activatedRoute.snapshot.queryParams['referralId'];
		const eventPayload = this.getRegisterEventPayload();

		await this.authService.setFirebasePersistence(firebase.auth.Auth.Persistence.LOCAL);
		this.authService
			.loginWithProvider(type)
			.then((profile) => {
				if (profile) {
					if (referralId) {
						profile.invitedBy = referralId;
					}

					profile.verified = true;

					this.authService.trackAuthEvent(type, 'registration', eventPayload);
					return this.profileService.createProfile(this.authService.firebaseUser.uid, profile);
				}
				return Promise.resolve();
			})
			.catch((e) => {
				this.store.dispatch(
					setUserRegistrationType({ registrationType: UserRegistrationType.UNAUTHENTICATED }),
				);
				this.alertService.showAlert(ERROR + e.code);
			});
	}

	goToLogin(): void {
		void this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
	}

	private signUpFinished = (user: firebase.User): void => {
		const referralId = this.activatedRoute.snapshot.queryParams['referralId'];
		const eventPayload = this.getRegisterEventPayload();

		if (referralId) {
			this.profile.invitedBy = referralId;
		}

		this.profile.verified = false;

		this.authService.firebaseUser = user;
		this.profileService
			.createProfile(user.uid, this.profile)
			.then(() => {
				void this.authService.trackAuthEvent('password', 'registration', eventPayload);
				this.store.dispatch(sendEmailVerificationLink());
			})
			.catch((error) => {
				this.alertService.showAlert('error.firestore' + ERROR + error.code);
			});
	};

	private getRegisterEventPayload(): { [keys: string]: string } {
		const tracker = RegisterEventKeys.map(
			(eventKey) => this.activatedRoute.snapshot.queryParams[eventKey] || null,
		);

		if (tracker.every(isNil)) {
			return {};
		}

		const payload = {
			tracker: tracker.join('::'),
			campaign: tracker[2],
		};

		return omitBy(payload, isNull);
	}
}
