import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Project } from 'domain-entities';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ProjectType } from '@shared/models/project.type';
import { selectActiveProject, selectRightSideMenu } from '@store/selectors/route.selectors';
import { selectProject } from '@store/selectors/projects.selectors';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { isEmployeeOfCompany } from '@store/selectors/app.selectors';

export interface ChatHeaderButton {
	text: string;
	icon: string;
	active: boolean;
	id: string;
	isVisible: () => boolean;
	width: number;
}

@Component({
	selector: 'app-chat-header',
	templateUrl: './chat-header.component.html',
	styleUrls: ['./chat-header.component.scss'],
})
export class ChatHeaderComponent implements OnDestroy {
	projectId$ = this.store.select(selectActiveProject);
	isEmployee$ = this.store.select(isEmployeeOfCompany);
	@Output() activeButton = new EventEmitter<string>();
	project: Project = null;
	projectType = ProjectType;
	buttons: ChatHeaderButton[] = [
		{
			text: 'chat-header.time-tracking',
			icon: 'time-tracking',
			active: false,
			id: 'time-tracking',
			isVisible: () =>
				this.project?.projectType === ProjectType.PROJECT &&
				this.isAddEditTimeTrackingProjectsEnabled,
			width: 112,
		},
		{
			text: 'chat-header.tasks',
			icon: 'tasks',
			active: false,
			id: 'tasks',
			isVisible: () => this.project?.projectType === ProjectType.PROJECT,
			width: 95,
		},
		{
			text: 'chat-header.members',
			icon: 'members',
			active: false,
			id: 'members',
			isVisible: () => true,
			width: 95,
		},
		{
			text: 'chat-header.files',
			icon: 'files',
			active: false,
			id: 'files',
			isVisible: () => this.project?.projectType === ProjectType.PROJECT,
			width: 95,
		},
		{
			text: 'chat-header.details',
			icon: 'details',
			active: false,
			id: 'details',
			isVisible: () => true,
			width: 95,
		},
	];
	destroy$: Subject<boolean> = new Subject();
	private rightSideMenu$ = this.store.select(selectRightSideMenu);

	get isAddEditTimeTrackingProjectsEnabled(): boolean {
		return this.remoteConfig.getBoolean('feature_add_edit_time_tracking_projects');
	}

	constructor(private readonly store: Store, private readonly remoteConfig: RemoteConfig) {
		this.initToggleOnRoute();
		this.listenSelectedProjectChanges();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	initToggleOnRoute(): void {
		this.rightSideMenu$.pipe(takeUntil(this.destroy$)).subscribe((route) => {
			this.buttons.forEach((button) => {
				button.active = button.id === route;
			});
		});
	}

	toggleButton(selectedButton: ChatHeaderButton, evt: Event): void {
		evt.preventDefault();

		this.activeButton.emit(selectedButton.id);
	}

	private listenSelectedProjectChanges(): void {
		this.projectId$
			.pipe(
				takeUntil(this.destroy$),
				switchMap((project) => this.store.select(selectProject, { projectId: project })),
				filter((project) => !!project),
			)
			.subscribe((project) => {
				this.project = { ...this.project, ...project };
			});
	}
}
