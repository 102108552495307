import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProjectStatus } from '@store/reducers/company-settings.reducer';
import { Observable } from 'rxjs';
import { NO_PROJECT_STATUS } from '@shared/models/project.type';
import { MemberRole } from 'domain-entities';

export interface UpdateProjectStatusData {
	projectName: string;
	selectedStatusId: string;
	statuses$: Observable<Array<ProjectStatus>>;
}

@Component({
	selector: 'app-update-project-status-dialog',
	templateUrl: './update-project-status-dialog.component.html',
	styleUrls: ['./update-project-status-dialog.component.scss'],
})
export class UpdateProjectStatusDialogComponent implements OnInit {
	selectedProjectStatusId: string;
	noProjectStatus = NO_PROJECT_STATUS;

	constructor(@Inject(MAT_DIALOG_DATA) public data: UpdateProjectStatusData) {}

	readonly MemberRole = MemberRole;

	ngOnInit(): void {
		this.selectedProjectStatusId = this.data.selectedStatusId || NO_PROJECT_STATUS;
	}
}
