<section class="notification-center-section h-100 position-relative">
	<mat-sidenav-container class="h-100" (backdropClick)="onClickBackDrop()">
		<mat-sidenav #sidenav [autoFocus]="false" [disableClose]="true">
			<div class="flex-scroll">
				<div class="notification-center-container flex-scroll__container" (click)="onClickNotificationContainer($event)">
					<div class="py-3 pr-3 border-bottom">
						<div class="d-flex align-items-center pl-3">
							<h1 class="m-0">{{ 'notification-center.title' | translate }}</h1>
							<button mat-icon-button class="ml-auto" (click)="close()">
								<mat-icon>close</mat-icon>
							</button>
						</div>

						<div class="notification-center-filters position-relative">
							<div class="notification-center-filters-container">
								<ng-container *ngIf="isNotificationCenterEnabled$ | async">
									<button
										mat-button
										class="mat-body-1"
										[class.text-primary]="notificationFilter?.unread !== true"
										(click)="onClickNotificationFilter({})"
									>
										{{ 'notification-center.filter.all' | translate }}
									</button>
									<button
										mat-button
										class="mat-body-1"
										[class.text-primary]="notificationFilter?.unread === true"
										(click)="onClickNotificationFilter({ unread: true })"
									>
										{{ 'notification-center.filter.unread' | translate }} {{ notificationCenterService.notificationUnreadCount }}
									</button>
								</ng-container>

								<div class="ml-auto">
									<button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="text-muted">
										<mat-icon id="notification-menu-icon">more_horiz</mat-icon>
									</button>
									<mat-menu #notificationMenu="matMenu" [hasBackdrop]="false">
										<button mat-menu-item appEventStopPropagation (click)="markAllNotificationAsRead()">
											{{ 'notification-center.mark-all-as-seen' | translate }}
										</button>
									</mat-menu>
								</div>
							</div>
						</div>
					</div>

					<ng-container *ngIf="notificationsList !== null">
						<ng-container *ngIf="notificationsList.length">
							<ul
								class="list-unstyled flex-scroll__container__scrollable-content notification-list"
								(scroll)="onScrollDebounced($event)"
								[@listAnimation]="notificationsList.length"
								[class.is-basic-user]="!(isNotificationCenterEnabled$ | async)"
							>
								<li
									*ngFor="let notification of notificationsList"
									(click)="openNotification(notification)"
									class="notification-list-item border-bottom pointer"
								>
									<app-notification-item [data]="notification" (onNotificationRead)="onNotificationRead($event)"> </app-notification-item>
								</li>
							</ul>
							<div class="d-flex justify-content-center py-3" *ngIf="fetchingNotifications">
								<mat-spinner color="accent" [diameter]="40"></mat-spinner>
							</div>
						</ng-container>

						<ng-container *ngIf="notificationsList.length === 0">
							<div class="absolute-center text-center w-100">
								<div class="mat-display-2 mb-4 text-black-50 invert-color">
									{{
										(notificationFilter?.unread
											? 'notification-center.empty-screen.unread-title'
											: 'notification-center.empty-screen.title'
										) | translate
									}}
								</div>

								<h1 class="mb-1 text-black-50 font-weight-normal invert-color">
									{{
										(notificationFilter?.unread
											? 'notification-center.empty-screen.unread-text-1'
											: 'notification-center.empty-screen.text-1'
										) | translate
									}}
								</h1>
								<h1 *ngIf="!notificationFilter?.unread" class="text-black-50 font-weight-normal invert-color">
									{{ 'notification-center.empty-screen.text-2' | translate }}
								</h1>

								<img src="assets/images/tt-empty-state.svg" [size]="48" alt="" class="invert-color mt-4" />
							</div>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="notificationsList === null">
						<ng-container *ngIf="!showErrorState">
							<ul class="list-unstyled">
								<li class="notification-list-item border-bottom d-flex flex-column pl-3 pr-4 py-3" *ngFor="let n of [1, 2, 3, 4, 5]">
									<span class="skeleton-loading mb-1" [style.height.px]="20" [style.width.%]="100"></span>
									<span class="skeleton-loading mb-1" [style.height.px]="20" [style.width.%]="10"></span>
									<span class="skeleton-loading mb-1" [style.height.px]="20" [style.width.%]="70"></span>
									<span class="skeleton-loading" [style.height.px]="12" [style.width.%]="15"></span>
								</li>
							</ul>
						</ng-container>
						<ng-container *ngIf="showErrorState">
							<div class="absolute-center text-center w-100">
								<div class="mat-display-2 mb-4 text-black-50 invert-color">
									{{ 'notification-center.error-screen.title' | translate }}
								</div>

								<h1 class="mb-1 text-black-50 font-weight-normal invert-color">
									{{ 'notification-center.error-screen.text-1' | translate }}
								</h1>
								<h1 class="mb-4 text-black-50 font-weight-normal invert-color">
									{{ 'notification-center.error-screen.text-2' | translate }}
								</h1>

								<mat-icon class="mat-icon-grey" [size]="48">error_outline</mat-icon>
							</div>
						</ng-container>
					</ng-container>
				</div>

				<div class="notification-center-paywall-info mat-elevation-z6" *ngIf="showPaywall$ | async">
					<h1 class="text-center">{{ 'notification-center.paywall.heading' | translate }}</h1>

					<div class="d-flex mb-3 justify-content-around mr-2 pr-2 mat-small">
						<ul class="list-unstyled">
							<li class="orange-tick">{{ 'notification-center.paywall.point-1' | translate }}</li>
							<li class="orange-tick">{{ 'notification-center.paywall.point-2' | translate }}</li>
						</ul>
					</div>

					<a *ngIf="isOwner$ | async" (click)="onClickPaywallCta()" class="w-100" rel="noopener" mat-flat-button color="accent"
						>{{ 'notification-center.paywall.cta-owner' | translate }}
					</a>
					<a
						*ngIf="!(isOwner$ | async)"
						href="https://v2.craftnote.de/preise"
						(click)="onClickPaywallCta()"
						class="w-100"
						rel="noopener"
						target="_blank"
						mat-flat-button
						color="accent"
					>
						{{ 'notification-center.paywall.cta-non-owner' | translate }}
					</a>
				</div>
			</div>
		</mat-sidenav>

		<mat-sidenav-content>
			<ng-content></ng-content>
		</mat-sidenav-content>
	</mat-sidenav-container>
</section>
