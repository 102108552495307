import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Profile } from 'domain-entities';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileConnector } from '@shared/firebase/connectors/firestore/collections/profiles/profile.connector';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	public profile: Profile;

	constructor(
		private profileConnector: ProfileConnector,
		private readonly http: HttpClient,
		@Inject(LOCALE_ID) private readonly locale: string,
	) {}

	public async createProfile(id: string, profile: Profile): Promise<void> {
		profile.name = profile.name?.trim();
		profile.lastname = profile.lastname?.trim();
		profile.nameLowercase = profile.name.toLowerCase();
		profile.locale = this.locale || 'de-DE';
		this.profile = profile;
		await this.profileConnector.createProfile(id, this.profile);
	}

	public async updateProfile(uuid: string, profile: Partial<Profile>): Promise<void> {
		await this.profileConnector.updateProfile(uuid, profile);
	}

	public async updateProfileTransactional(
		profileId: string,
		updateFunction: (oldProject: Profile) => Partial<Profile> | undefined,
	): Promise<void> {
		await this.profileConnector.updateProfileTransactional(profileId, updateFunction);
	}

	public getProfile(uuid: string): Observable<Profile> {
		return this.profileConnector.getProfileById(uuid);
	}

	public checkForProfileExistence(uuid: string): Promise<boolean> {
		return this.profileConnector.checkProfileExists(uuid);
	}

	createProfileLink(): Observable<void> {
		return this.http.get<void>(`${environment.baseUrl}createReferralLink`);
	}
}
