import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import * as fromFileUpload from '@store/actions/file-upload.actions';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';
import { values } from 'lodash';
import { getUnixTime } from 'date-fns';

export interface FileSectionUploadState extends FileUploadState {
	context: FileContext.PROJECT;
	projectId: string;
	fileName: string;
	folderId?: string;
}

export interface FileUploadState {
	id: string;
	percentage: number;
	context: FileContext;
	status: 'ongoing' | 'error' | 'paused' | 'cancellation-requested';
	hiddenInProjectNudge?: boolean;
	failureTime?: number;
}

const adapter = createEntityAdapter<FileUploadState>();

const initialState = adapter.getInitialState();
const fileUploadReducer = createReducer(
	initialState,
	on(fromFileUpload.setChatUploadPercentageAction, (state, props) => {
		return adapter.upsertOne({ ...props, context: FileContext.CHAT, status: 'ongoing' }, state);
	}),
	on(fromFileUpload.createProjectFileUpload, (state, props) => {
		return adapter.addOne(
			{ ...props, percentage: 0, context: FileContext.PROJECT, status: 'paused' },
			state,
		);
	}),
	on(fromFileUpload.setProjectUploadPercentageAction, (state, { id, percentage }) => {
		return adapter.updateOne({ id, changes: { percentage } }, state);
	}),
	on(fromFileUpload.setUploadFailedAction, (state, { id }) => {
		return adapter.updateOne(
			{ id, changes: { status: 'error', failureTime: getUnixTime(Date.now()) } },
			state,
		);
	}),
	on(fromFileUpload.clearPercentageAction, (state, { id }) => {
		return adapter.removeOne(id, state);
	}),
	on(fromFileUpload.dismissFailedUploadsAction, (state) => {
		const idsOfFailedUploads = values(state.entities)
			.filter((fileUpload) => fileUpload.status === 'error')
			.map((fileUpload) => fileUpload.id);
		return adapter.removeMany(idsOfFailedUploads, state);
	}),

	on(fromFileUpload.cancelUploadAction, (state, { id }) => {
		const fileUploadState = state.entities[id];
		if (fileUploadState?.status === 'error') {
			return adapter.removeOne(id, state);
		}
		return adapter.updateOne({ id, changes: { status: 'cancellation-requested' } }, state);
	}),

	on(fromFileUpload.resumeUploadAction, (state, { id }) => {
		const fileUploadState = state.entities[id];
		if (fileUploadState?.status === 'paused') {
			return adapter.updateOne({ id, changes: { status: 'ongoing' } }, state);
		}
		return state;
	}),

	on(fromFileUpload.hideAllFailedUploadsFromProjectListAction, (state) => {
		const failedUploads = values(state.entities)
			.filter(
				(fileUpload) => fileUpload.context === FileContext.PROJECT && fileUpload.status === 'error',
			)
			.map((failedUpload) => ({ id: failedUpload.id, changes: { hiddenInProjectNudge: true } }));
		return adapter.updateMany(failedUploads, state);
	}),
);

export const fileUploadSelectors = adapter.getSelectors();

// tslint:disable-next-line:typedef
export function reducerFileUpload(state, action: Action) {
	return fileUploadReducer(state, action);
}
