import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectInviteService } from './project-invite.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-project-invite',
	templateUrl: './project-invite.component.html',
	styleUrls: ['./project-invite.component.scss'],
	providers: [ProjectInviteService],
})
export class ProjectInviteComponent implements OnInit {
	private inviteId = this.activatedRoute.snapshot.queryParams['inviteId'];
	isLoading = true;
	projectInvitationResponse$: Observable<string>;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly service: ProjectInviteService,
	) {}

	ngOnInit(): void {
		this.projectInvitationResponse$ = this.service
			.sendInviteVerificationLink(this.inviteId)
			.pipe(finalize(() => (this.isLoading = false)));
	}
}
