import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export interface ProjectPrefillConfirmDialogData {
	invite: boolean;
	address: boolean;
	billing: boolean;
	contacts: boolean;
}

@Component({
	selector: 'app-project-prefill-dialog',
	templateUrl: './project-prefill-dialog.component.html',
	styleUrls: ['./project-prefill-dialog.component.scss'],
})
export class ProjectPrefillDialogComponent {
	invite = true;
	address = true;
	billing = true;
	contacts = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ProjectPrefillDialogComponent>,
	) {}

	closeDialog(): void {
		this.dialogRef.close();
	}

	apply() {
		this.dialogRef.close({
			invite: this.invite,
			address: this.address,
			billing: this.billing,
			contacts: this.contacts,
		});
	}

	skip() {
		this.dialogRef.close({ invite: false, address: false, billing: false, contacts: false });
	}
}
