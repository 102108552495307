import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Self,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { isDate, isNil, noop } from 'lodash';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { set } from 'date-fns';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	providers: [{ provide: MatFormFieldControl, useExisting: TimePickerComponent }],
})
export class TimePickerComponent
	implements
		MatFormFieldControl<Date>,
		ControlValueAccessor,
		OnDestroy,
		OnInit,
		Validator,
		OnChanges
{
	private onChange: Function = null;
	private onTouch: Function = noop;
	private longPressInterval: any;
	private speedUpTimeout: any;
	private currentSpeed = 300; // Initial interval in milliseconds
	private subscriptions: Subscription[] = [];
	static nextId = 0;
	@Input() min: Date = null;
	@Input() max: Date = null;
	@ViewChild('hoursInputRef', { static: true }) hoursInputRef: ElementRef<HTMLInputElement>;
	@ViewChild('minutesInputRef', { static: true }) minutesInputRef: ElementRef<HTMLInputElement>;
	lastFocusedInput: 'hours-input' | 'minutes-input' = 'hours-input';
	stateChanges = new Subject<void>();
	focused = false;
	errorState = false;
	controlType = 'time-picker-form';
	@HostBinding() id = `time-picker-${TimePickerComponent.nextId++}`;
	@HostBinding('[attr.aria-describedby]') describedBy = '';
	value: Date;

	constructor(
		private readonly fm: FocusMonitor,
		private readonly elRef: ElementRef,
		private readonly cdr: ChangeDetectorRef,
		@Optional() @Self() public readonly ngControl: NgControl,
	) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}

		fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
			this.focused = !!origin;
			this.stateChanges.next();
		});
	}

	@HostBinding('class.floating') get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	get empty(): boolean {
		return isNil(this.value);
	}

	private _placeholder: string;

	@Input()
	get placeholder(): string {
		return this._placeholder;
	}

	set placeholder(plh) {
		this._placeholder = plh;
		this.stateChanges.next();
	}

	private _required = false;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(req) {
		this._required = coerceBooleanProperty(req);
		this.stateChanges.next();
	}

	private _disabled = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(dis) {
		this._disabled = coerceBooleanProperty(dis);
		this.stateChanges.next();
	}

	private get midNightTime(): Date {
		return moment().startOf('day').toDate();
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit(): void {
		this.ngControl.control.setValidators([this.validate.bind(this)]);
		this.ngControl.control.updateValueAndValidity({ emitEvent: false });
		this.setupLongPressListeners();

		this.stateChanges.subscribe(() =>
			this.ngControl.control.updateValueAndValidity({ emitEvent: false }),
		);
	}

	ngOnDestroy(): void {
		this.stateChanges.complete();
		this.fm.stopMonitoring(this.elRef.nativeElement);
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.max?.currentValue) {
			this.ngControl.control.updateValueAndValidity({ emitEvent: false });
		}
	}

	setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	onContainerClick(_event: MouseEvent): void {
		// Dont remove me
	}

	onClickIncrementButton(): void {
		if (this.disabled) {
			return;
		}
		if (this.lastFocusedInput === 'hours-input') {
			this.incrementHour();
		}
		if (this.lastFocusedInput === 'minutes-input') {
			this.incrementMinute();
		}
	}

	onClickDecrementButton(): void {
		if (this.disabled) {
			return;
		}

		if (this.lastFocusedInput === 'hours-input') {
			this.decrementHour();
		}
		if (this.lastFocusedInput === 'minutes-input') {
			this.decrementMinute();
		}
	}

	validate(): ValidationErrors {
		let errors = {};

		if (Object.keys(errors).length) {
			this.errorState = true;
			return errors;
		} else {
			this.errorState = false;
			return null;
		}
	}

	onHourInputChange(hours: string): void {
		const parsedInput = Number.parseInt(hours, 10);
		if (this.isValidHours(parsedInput)) {
			const updatedValue = this.value || this.midNightTime;
			updatedValue.setHours(parsedInput);
			this.setValueWithinRange(updatedValue);
		}
		this.emitChanges();
	}

	onMinutesInputChange(minutes: string): void {
		const parsedInput = Number.parseInt(minutes, 10);
		if (this.isValidMinutes(parsedInput)) {
			const updatedValue = this.value || this.midNightTime;
			updatedValue.setMinutes(parsedInput);
			this.setValueWithinRange(updatedValue);
		}
		this.emitChanges();
	}

	incrementHour(event?: Event): void {
		this.lastFocusedInput = 'hours-input';
		if (this.value) {
			let newDate = new Date(this.value.getTime());
			newDate.setHours(newDate.getHours() + 1);
			this.setValueWithinRange(newDate);
		} else {
			this.setValueWithinRange(this.midNightTime);
		}
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	decrementHour(event?: Event): void {
		this.lastFocusedInput = 'hours-input';
		if (this.value) {
			let newDate = new Date(this.value.getTime());
			newDate.setHours(newDate.getHours() - 1);
			this.setValueWithinRange(newDate);
		} else {
			this.setValueWithinRange(new Date(this.midNightTime.getTime() - 1));
		}
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	incrementMinute(event?: Event): void {
		this.lastFocusedInput = 'minutes-input';
		if (this.value) {
			let newDate = new Date(this.value.getTime());
			newDate.setMinutes(newDate.getMinutes() + 1);
			this.setValueWithinRange(newDate);
		} else {
			this.setValueWithinRange(this.midNightTime);
		}
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	decrementMinute(event?: Event): void {
		this.lastFocusedInput = 'minutes-input';
		if (this.value) {
			let newDate = new Date(this.value.getTime());
			newDate.setMinutes(newDate.getMinutes() - 1);
			this.setValueWithinRange(newDate);
		} else {
			this.setValueWithinRange(new Date(this.midNightTime.getTime() - 60000));
		}
		if (event) {
			this.moveCursorToEnd(event.target as HTMLInputElement);
		}
	}

	writeValue(value: Date): void {
		this.value = isDate(value) ? value : null;
		this.updateInputValues();
		this.cdr.detectChanges();
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	private emitChanges(): void {
		this.onChange(this.value);
		this.onTouch();
		this.stateChanges.next();
	}

	private moveCursorToEnd(element: HTMLInputElement): void {
		setTimeout(() => {
			element.selectionStart = element.selectionEnd = element.value.length;
		});
	}

	private setValueWithinRange(newDate: Date): void {
		if (!this.isValidInput(newDate)) {
			return;
		}

		let adjustedDate = this.getAdjustedDate(newDate);

		if (this.hasDateChanged(adjustedDate)) {
			this.updateComponentValue(adjustedDate);
		}
	}

	private isValidInput(newDate: Date): boolean {
		return !(!newDate || !this.min || !this.max);
	}

	private getAdjustedDate(newDate: Date): Date {
		// Check if the time is valid within the range, regardless of the day
		const newTimeOnMinDay = this.setTimeOnDate(this.min, newDate);
		const newTimeOnMaxDay = this.setTimeOnDate(this.max, newDate);

		if (this.isTimeWithinRange(newTimeOnMinDay)) {
			return newTimeOnMinDay;
		} else if (this.isTimeWithinRange(newTimeOnMaxDay)) {
			return newTimeOnMaxDay;
		} else {
			return this.clampToMinOrMax(newDate);
		}
	}

	private setTimeOnDate(baseDate: Date, timeDate: Date): Date {
		return set(baseDate, {
			hours: timeDate.getHours(),
			minutes: timeDate.getMinutes(),
			seconds: timeDate.getSeconds(),
		});
	}

	private isTimeWithinRange(date: Date): boolean {
		return date >= this.min && date <= this.max;
	}

	private clampToMinOrMax(date: Date): Date {
		if (date < this.min) {
			return new Date(this.min);
		} else if (date > this.max) {
			return new Date(this.max);
		}
		return date;
	}

	private hasDateChanged(newDate: Date): boolean {
		return !this.value || newDate.getTime() !== this.value.getTime();
	}

	private updateComponentValue(newDate: Date): void {
		this.value = newDate;
		this.updateInputValues();
		this.errorState = false;
		this.stateChanges.next();
		this.emitChanges();
	}

	private updateInputValues(): void {
		if (this.value && this.hoursInputRef?.nativeElement && this.minutesInputRef?.nativeElement) {
			this.hoursInputRef.nativeElement.value = String(this.value.getHours()).padStart(2, '0');
			this.minutesInputRef.nativeElement.value = String(this.value.getMinutes()).padStart(2, '0');
		}
	}

	private isValidHours(hours: number): boolean {
		return hours > -1 && hours < 24;
	}

	private isValidMinutes(minutes: number): boolean {
		return minutes > -1 && minutes < 60;
	}

	private setupLongPressListeners(): void {
		const incrementButton = this.elRef.nativeElement.querySelector('.increment-button');
		const decrementButton = this.elRef.nativeElement.querySelector('.decrement-button');

		this.setupLongPressForButton(incrementButton, () => this.onClickIncrementButton());
		this.setupLongPressForButton(decrementButton, () => this.onClickDecrementButton());
	}

	private setupLongPressForButton(button: HTMLElement, action: () => void): void {
		const mousedown$ = fromEvent(button, 'mousedown');
		const mouseup$ = fromEvent(button, 'mouseup');
		const mouseleave$ = fromEvent(button, 'mouseleave');

		const subscription = mousedown$.subscribe(() => {
			this.startLongPress(action);

			mouseup$.pipe(takeUntil(mouseleave$)).subscribe(() => this.stopLongPress());
			mouseleave$.pipe(takeUntil(mouseup$)).subscribe(() => this.stopLongPress());
		});

		this.subscriptions.push(subscription);
	}

	private startLongPress(action: () => void): void {
		action(); // Immediate action on press
		this.currentSpeed = 300; // Reset speed

		this.longPressInterval = setInterval(() => {
			action();
		}, this.currentSpeed);

		this.speedUpTimeout = setTimeout(() => {
			this.speedUp(action);
		}, 1000); // Start speeding up after 1 second
	}

	private speedUp(action: () => void): void {
		clearInterval(this.longPressInterval);
		this.currentSpeed = Math.max(50, this.currentSpeed - 50); // Speed up, but not faster than 50ms

		this.longPressInterval = setInterval(() => {
			action();
		}, this.currentSpeed);

		this.speedUpTimeout = setTimeout(() => {
			this.speedUp(action);
		}, 1000); // Continue speeding up every second
	}

	private stopLongPress(): void {
		clearInterval(this.longPressInterval);
		clearTimeout(this.speedUpTimeout);
	}
}
