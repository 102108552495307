import { Pipe, PipeTransform } from '@angular/core';
import { isNil, padStart } from 'lodash';

@Pipe({
	name: 'padStart',
})
export class PadStartPipe implements PipeTransform {
	transform(text: number | string, maxLength = 0, fillString = ''): string | null {
		return isNil(text) ? null : padStart(String(text), maxLength, fillString);
	}
}
