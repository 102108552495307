import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@craftnote/shared-utils';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';

@Injectable({
	providedIn: 'root',
})
export class LoopedInService {
	constructor(
		@Inject(WINDOW) private readonly windowRef: Window,
		@Inject(ENVIRONMENT) public readonly environmentRef: Environment,
	) {}

	async initializeLoopedIn(): Promise<void> {
		setTimeout(() => {
			this.windowRef['li_sidebar'] = {
				workspace_id: this.environmentRef.loopedIn.workspace_id,
			};
			try {
				this.windowRef['LoopedIn'].sidebar.init();
			} catch (e) {
				throw new Error('LoopedIn failed to load');
			}
		}, 4000);
	}
}
