<div class="w-100 d-flex align-items-center justify-content-between banner font-weight-light pl-5">
	<div class="d-flex align-items-center">
		<span class="font-weight-bold mr-1">{{ daysRemaining }}</span>
		<span>{{ 'trialBanner.daysRemaining' | translate }}</span>
		<a href="" class="ml-1 skip-a-styles text-decoration-underline font-weight-bold" (click)="openSubscriptionInfoDialog()">{{
			'trialBanner.moreInfo' | translate
		}}</a>
	</div>
	<div class="pr-5 d-flex align-items-center">
		<span>{{ 'trialBanner.enjoyPro' | translate }}</span>
		<button (click)="navigateToProducts()" class="mx-3 font-weight-bold" mat-stroked-button>
			{{ 'trialBanner.upgradeNow' | translate }}
		</button>
	</div>
</div>

<ng-template #subscriptionInfoTemplateRef>
	<div class="d-flex align-items-center justify-content-between m-0" mat-dialog-title>
		<span class="mat-display-1 m-0">{{ 'trialBanner.subscriptionInfoDialog.title' | translate }}</span>

		<button [mat-dialog-close] mat-icon-button>
			<mat-icon>clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content>
		<p>
			{{
				'trialBanner.subscriptionInfoDialog.description'
					| translate: { renewalDate: trialSubscription.renewalDate | unixToMillis | date: 'dd.MM.yyyy' }
			}}
		</p>

		<ul class="list-unstyled">
			<li class="orange-tick">
				<div class="font-weight-bold">
					{{ 'trialBanner.subscriptionInfoDialog.point1.heading' | translate }}
				</div>
				<p>
					{{ 'trialBanner.subscriptionInfoDialog.point1.description' | translate }}
					<a
						href="https://hilfe.mycraftnote.de/hc/de/articles/360029115051-PDF-Formulare-Ausf%C3%BCllen"
						target="_blank"
						rel="noopener"
						class="text-decoration-underline font-weight-bold"
						>{{ 'trialBanner.subscriptionInfoDialog.point1.action' | translate }}</a
					>.
				</p>
			</li>
			<li class="orange-tick">
				<div class="font-weight-bold">
					{{ 'trialBanner.subscriptionInfoDialog.point2.heading' | translate }}
				</div>
				<p>
					{{ 'trialBanner.subscriptionInfoDialog.point2.description' | translate }}
					<a routerLink="/dashboard/time-tracking" [mat-dialog-close] class="text-decoration-underline font-weight-bold">{{
						'trialBanner.subscriptionInfoDialog.point2.action' | translate
					}}</a>
				</p>
			</li>
			<li class="orange-tick">
				<div class="font-weight-bold">
					{{ 'trialBanner.subscriptionInfoDialog.point3.heading' | translate }}
				</div>
				<p>
					{{ 'trialBanner.subscriptionInfoDialog.point3.description' | translate }}
					<a routerLink="/planning-board" [mat-dialog-close] class="text-decoration-underline font-weight-bold">{{
						'trialBanner.subscriptionInfoDialog.point3.action' | translate
					}}</a>
				</p>
			</li>
		</ul>
		<p>{{ 'trialBanner.subscriptionInfoDialog.footer' | translate }}</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button [mat-dialog-close] mat-stroked-button>
			{{ 'trialBanner.subscriptionInfoDialog.actions.close' | translate }}
		</button>

		<button routerLink="settings/subscription/products" [mat-dialog-close] cdkFocusInitial color="accent" mat-flat-button>
			{{ 'trialBanner.subscriptionInfoDialog.actions.upgradeOptions' | translate }}
		</button>
	</mat-dialog-actions>
</ng-template>
