import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

export interface Menu {
	isRoot: boolean;
	triggerName: string;
	iconName?: string;
	customIconName?: string;
	matIcon?: boolean;
	options?: Menu[];
	ref?: TemplateRef<any>;
	openInModal?: boolean;
	showActive?: boolean;
	disableMenu?: boolean;
	disableMenuText?: string;
	disableMenuRef?: TemplateRef<any>;
}

@Component({
	selector: 'app-multi-menu',
	templateUrl: './multi-menu.component.html',
	styleUrls: ['./multi-menu.component.scss'],
})
export class MultiMenuComponent {
	@Input() level = 0;
	@Input() menuConfig: Menu | undefined;
	@Output() onOpenDisabledDialog = new EventEmitter<void>();

	constructor(public dialog: MatDialog) {}

	openDialog(option: Menu): void {
		if (option.ref) {
			this.dialog.open(option.ref, { autoFocus: false });
		}
	}

	openDisabledDialog(): void {
		if (this.menuConfig?.disableMenu && this.menuConfig?.disableMenuRef) {
			this.onOpenDisabledDialog.emit();
			this.dialog.open(this.menuConfig?.disableMenuRef, { autoFocus: false });
		}
	}

	selectorString(index: number, isChild = false): string {
		return `multi-menu-level-${isChild ? this.level + 1 : this.level}-${index}`;
	}
}
