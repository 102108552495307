import { createFeatureSelector, createSelector } from '@ngrx/store';
import { exportEntitySelectors } from '../reducers/export.reducer';
import { ExportState } from '../state/ExportState';

const finalExportStates = ['complete', 'failed', 'errored'];

const exportSelector = createFeatureSelector<ExportState>('exportState');

export const selectExports = createSelector(exportSelector, (state) => {
	return exportEntitySelectors
		.selectAll(state)
		.filter((exportEntity) =>
			state.startedExports.some((started) => started.id === exportEntity.id),
		);
});

export const selectExportById = createSelector(exportSelector, (state, props: { id: string }) => {
	return exportEntitySelectors.selectEntities(state)[props.id];
});

export const selectedFinalizedExportById = createSelector(
	exportSelector,
	(state, props: { id: string }) => {
		const exportEntity = exportEntitySelectors.selectEntities(state)[props.id];
		if (exportEntity && !finalExportStates.includes(exportEntity.state)) {
			return undefined;
		}
		return exportEntity;
	},
);
