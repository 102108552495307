import Dexie, { Table } from 'dexie';
import { Task } from 'domain-entities';

export class IndexedDb extends Dexie {
	companyTasks: Table<Task, string>;

	constructor() {
		super('CraftnoteLocalCache');
		this.version(2).stores({
			companyProjects: '&id, company',
			companyTasks: '&id, companyId',
		});
	}
}
export const indexedDb = new IndexedDb();
