<div *ngIf="isLoaded()" class="font-weight-light" [class.d-none]="isUserAuthenticated$ | async">
	<app-banner></app-banner>
	<div class="register-screen align-items-center pt-2 pt-lg-5 mb-5 px-3 d-flex justify-content-end">
		<div class="flex-grow-1 d-md-none">
			<img alt="Logo" src="assets/images/icons/craftnote_back_orange.svg" />
		</div>
		<span class="mr-3 d-none d-md-block">{{ 'register.account' | translate }}</span>

		<button mat-stroked-button (click)="goToLogin()" color="accent" data-cy="home-page-login">
			{{ 'login.button' | translate }}
		</button>
		<br />
	</div>
	<div class="col-12 col-lg-6 offset-lg-6 register authentication-viewer-outer">
		<div class="authentication-center-middle">
			<div class="vertical-center-inner">
				<h1 class="h3 text-center mb-4 font-weight-bold">{{ 'register.title' | translate }}</h1>
				<div
					*ngIf="invitationLink$ | async as link"
					[innerHTML]="'invitations.registration-hint-text' | translate: { companyName: link?.companyName }"
					class="mat-body-1 mb-3 p-3 hint-text border-0 text-break"
				></div>
				<form #registerForm="ngForm" (ngSubmit)="register()" class="form pt-3" novalidate role="form">
					<div class="row">
						<div class="form-group col-6">
							<mat-form-field class="w-100">
								<input
									data-cy="register-first-name"
									[(ngModel)]="profile.name"
									[class.ngInvalid]="registerForm.controls.name?.errors"
									name="name"
									type="text"
									matInput
									required
									placeholder="{{ 'register.name' | translate }}"
									pattern="[a-zA-Z- äöüÖÄÜß]+"
								/>
								<mat-error *ngIf="registerForm.controls.name?.errors?.required">
									{{ 'error.required' | translate }}
								</mat-error>
								<mat-error *ngIf="registerForm.controls.name?.errors?.pattern">
									{{ 'error.characters' | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<div class="form-group col-6">
							<mat-form-field class="w-100">
								<input
									data-cy="register-last-name"
									[(ngModel)]="profile.lastname"
									[class.ngInvalid]="registerForm.controls.lastname?.errors"
									name="lastname"
									type="text"
									matInput
									required
									placeholder="{{ 'register.lastname' | translate }}"
									pattern="[a-zA-Z- äöüÖÄÜß]+"
								/>
								<mat-error *ngIf="registerForm.controls.lastname?.errors?.required">
									{{ 'error.required' | translate }}
								</mat-error>
								<mat-error *ngIf="registerForm.controls.lastname?.errors?.pattern">
									{{ 'error.characters' | translate }}
								</mat-error>
							</mat-form-field>
						</div>
					</div>

					<div class="form-group">
						<mat-form-field class="w-100">
							<input
								data-cy="register-email"
								[(ngModel)]="email"
								[class.ngInvalid]="registerForm.controls.email?.errors"
								name="email"
								type="email"
								[pattern]="emailPattern"
								matInput
								email
								required
								placeholder="{{ 'register.email' | translate }}"
							/>
							<mat-icon matPrefix>mail_outline</mat-icon>

							<mat-error *ngIf="registerForm.controls.email?.dirty || registerForm.controls.email?.invalid">
								{{ 'error.email.valid' | translate }}
							</mat-error>
							<mat-error *ngIf="registerForm.controls.email?.errors?.required">
								{{ 'error.required' | translate }}
							</mat-error>
						</mat-form-field>
					</div>

					<div class="form-group">
						<mat-form-field class="w-100">
							<input
								data-cy="register-phone-number"
								[(ngModel)]="profile.mobile"
								[class.ngInvalid]="registerForm.controls.mobile?.errors"
								name="mobile"
								type="tel"
								[required]="!(invitationLink$ | async)"
								[pattern]="phoneNumberPattern"
								matInput
								phone="DE"
								placeholder="{{ 'register.phone' | translate }}"
							/>
							<mat-icon matPrefix svgIcon="custom-smartphone"></mat-icon>

							<mat-error
								*ngIf="registerForm.controls.mobile?.dirty || registerForm.controls.mobile?.invalid"
								[ngSwitch]="Object.keys(registerForm.controls.mobile.errors || {})[0]"
							>
								<ng-container *ngSwitchCase="'pattern'">{{ 'global.errors.invalid-phone-pattern' | translate }}</ng-container>
								<ng-container *ngSwitchDefault>{{ 'error.required' | translate }}</ng-container>
							</mat-error>
						</mat-form-field>
					</div>

					<div class="form-group">
						<mat-form-field class="w-100">
							<input
								data-cy="register-password"
								[(ngModel)]="password"
								[class.ngInvalid]="registerForm.controls.password?.errors"
								[type]="isPasswordVisibility ? 'password' : 'text'"
								name="password"
								#pw="ngModel"
								matInput
								required
								minlength="8"
								placeholder="{{ 'register.password' | translate }}"
							/>
							<mat-icon matPrefix svgIcon="custom-lock"></mat-icon>
							<mat-icon (click)="isPasswordVisibility = !isPasswordVisibility" appEventStopPropagation class="pointer" matSuffix
								>{{ isPasswordVisibility ? 'visibility' : 'visibility_off' }}
							</mat-icon>
							<mat-error *ngIf="registerForm.controls.password?.errors?.required">
								{{ 'error.required' | translate }}
							</mat-error>
							<mat-error *ngIf="registerForm.controls.password?.errors?.minlength">
								{{
									'error.minlength'
										| translate
											: {
													requiredLength: registerForm.controls.password?.errors?.minlength?.requiredLength
											  }
								}}
							</mat-error>
						</mat-form-field>
					</div>

					<div *ngIf="getPasswordStrength() !== passwordStrength.INSUFFICIENT" class="form-group text-right">
						<div class="password-meter text-right align-items-center">
							<div [class.active]="getPasswordStrength() === passwordStrength.STRONG" class="chip"></div>
							<div [class.active]="getPasswordStrength() === passwordStrength.MEDIUM" class="chip"></div>
							<div [class.active]="getPasswordStrength() === passwordStrength.LOW" class="chip"></div>
							<div class="text">
								{{ 'register.password-meter.' + getPasswordStrength() | translate }}
							</div>
						</div>
					</div>

					<div class="form-group mt-2">
						<span>{{ 'register.passwordHint' | translate }}</span>
					</div>

					<div class="form-group mt-2 terms-and-conditions">
						<div>
							<mat-checkbox [(ngModel)]="agreed" name="agreed" data-cy="register-terms-of-service-checkbox">
								<strong>
									{{ 'register.agreed' | translate }}
									<a href="{{ linkService.tocLink }}" target="_blank" rel="noopener" class="link">
										{{ 'toc.agb' | translate }}
									</a>
								</strong>
							</mat-checkbox>
						</div>

						<div>
							<mat-checkbox [(ngModel)]="agreedAVV" name="agreed-avv" data-cy="register-privacy-policy-checkbox">
								<strong>
									{{ 'register.agreed' | translate }}
									<a href="{{ linkService.legalNoticeLink }}" target="_blank" rel="noopener" class="link">
										{{ 'toc.datenschutz' | translate }}
									</a>
								</strong>
							</mat-checkbox>
						</div>
					</div>
					<br />
					<div class="form-group row d-flex align-items-center justify-content-md-center">
						<div class="col-6 text-center register-button-container">
							<button
								data-cy="register-button"
								mat-flat-button
								color="accent"
								[disabled]="registerForm.form.invalid || !passwordIsValid()"
								class="w-100"
								type="submit"
							>
								{{ 'register.button' | translate }}
							</button>
						</div>
					</div>
				</form>
				<div class="oder text-center">
					<span>{{ 'login.or' | translate }}</span>
				</div>
				<div class="login-buttons text-center d-flex align-items-center flex-column">
					<button
						(click)="registerWithProvider(SignInProvider.FACEBOOK)"
						mat-flat-button
						class="icon-button provider-button facebook-login"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="facebook-brand"></mat-icon>
						<span>{{ 'register.btn-facebook' | translate }}</span>
					</button>
					<button
						(click)="registerWithProvider(SignInProvider.GOOGLE)"
						mat-flat-button
						class="icon-button google-login provider-button"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="google-brand"></mat-icon>
						<span>{{ 'register.btn-google' | translate }}</span>
					</button>
					<button
						(click)="registerWithProvider(SignInProvider.APPLE)"
						mat-flat-button
						class="icon-button apple-login provider-button"
						color="accent"
					>
						<mat-icon class="brand-icon" svgIcon="apple-brand"></mat-icon>
						<span>{{ 'register.btn-apple' | translate }}</span>
					</button>

					<div class="row justify-content-between badges-container">
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="madeInGermany"></app-trust-badge>
						</div>
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="dsgvo-fill"></app-trust-badge>
						</div>
						<div class="col-md-3">
							<app-trust-badge [enableDescription]="false" [iconHeight]="70" name="refund"></app-trust-badge>
						</div>
					</div>
				</div>

				<div class="from-group mt-5 mb-5 d-flex justify-content-center privacy-container">
					<a (click)="linkService.openLinkInNewTab(linkService.imprintLink)" class="mr-3" href="#">
						<span>{{ 'settings.imprint' | translate }}</span>
					</a>
					<a (click)="linkService.openLinkInNewTab(linkService.tocLink)" class="mr-3" href="#">
						<span>{{ 'toc.agb' | translate }}</span>
					</a>
					<a (click)="linkService.openLinkInNewTab(linkService.legalNoticeLink)" href="#">
						<span>{{ 'toc.datenschutz' | translate }}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
