<div class="row">
	<app-banner></app-banner>
	<a class="btn-white-text back-position back-button" href="#" routerLink="/">
		<em class="material-icons icon">arrow_back</em>
		<span class="ml-2">{{ 'passwordForgotten.back' | translate }}</span>
	</a>

	<div class="col-12 col-lg-6 offset-lg-6 forgot-password d-flex align-items-center authentication-viewer-outer forgot-password-container">
		<div class="authentication-center-middle">
			<div class="vertical-center-inner">
				<h1 class="h3 text-center font-weight-bold">{{ 'passwordForgotten.title' | translate }}</h1>
				<p class="font-weight-light mt-4 text-center info-text">
					{{ 'passwordForgotten.infoText' | translate }}
				</p>
				<form #forgotPasswordFrom="ngForm" (ngSubmit)="sendResetMail()" class="form mt-4 pt-3" role="form">
					<div class="form-group mb-4">
						<mat-form-field class="w-100">
							<input
								[(ngModel)]="email"
								email
								[pattern]="emailPattern"
								matInput
								name="email"
								placeholder="{{ 'passwordForgotten.email' | translate }}"
								required
								type="text"
							/>
							<mat-icon matPrefix>mail_outline</mat-icon>
							<mat-error
								*ngIf="
									forgotPasswordFrom.controls.email?.dirty &&
									forgotPasswordFrom.controls.email?.invalid &&
									!forgotPasswordFrom.controls.email?.errors?.required
								"
							>
								{{ 'error.email.valid' | translate }}
							</mat-error>
							<mat-error *ngIf="forgotPasswordFrom.controls.email?.errors?.required">
								{{ 'error.required' | translate }}
							</mat-error>
						</mat-form-field>
					</div>

					<div class="form-group row d-flex align-items-center justify-content-center">
						<div class="col-6 text-center">
							<button type="submit" [disabled]="forgotPasswordFrom.form.invalid" mat-flat-button color="accent" class="w-100">
								{{ 'passwordForgotten.button' | translate }}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
