<div class="empty-state container text-center">
	<ng-container [ngSwitch]="type">
		<ng-template [ngSwitchCase]="EMPTY_STATE.FILE_TAB">
			<h3 class="mt-5">{{ 'explorer.empty_state.file_tab.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.file_tab.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.file_tab.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/empty-state-dirk.svg" />
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.TIME_TRACKING">
			<h3 class="mt-2">{{ 'explorer.empty_state.task_time.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.task_time.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.task_time.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/empty-state-dirk.svg" />
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.PROJECTS">
			<h3 class="text-uppercase mt-2">{{ 'explorer.empty_state.projects.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.projects.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.projects.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/empty-state-dirk.svg" />
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.ARCHIVE">
			<h3 class="text-uppercase mt-2">{{ 'explorer.empty_state.archive.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.archive.description' | translate }}</p>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-archive.svg" />
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.PROJECT_TASKS">
			<h3 class="text-uppercase">{{ 'explorer.empty_state.tasks.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.tasks.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.tasks.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-tasks.svg" />
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.PROJECT_CHAT">
			<div class="empty-state__chat">
				<h3 class="text-uppercase">{{ 'explorer.empty_state.chat.title' | translate }}</h3>
				<p>{{ 'explorer.empty_state.chat.description' | translate }}</p>
				<span>{{ 'explorer.empty_state.chat.subtitle' | translate }}</span>
				<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-chat.svg" />
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="EMPTY_STATE.PROJECT_MEMBER">
			<h3 class="text-uppercase">{{ 'explorer.empty_state.members.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.members.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.members.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-members.svg" />
		</ng-template>

		<ng-template [ngSwitchCase]="EMPTY_STATE.COMPANY_TEMPLATE">
			<h3 class="text-uppercase">{{ 'explorer.empty_state.company.title' | translate }}</h3>
			<p>{{ 'explorer.empty_state.company.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.company.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-tasks.svg" />
		</ng-template>

		<ng-template [ngSwitchCase]="EMPTY_STATE.PROJECT_MEMBER_EXTERNAL">
			<h3 class="text-uppercase">
				{{ 'explorer.empty_state.members.external.title' | translate }}
			</h3>
			<p>{{ 'explorer.empty_state.members.external.description' | translate }}</p>
			<span>{{ 'explorer.empty_state.members.external.subtitle' | translate }}</span>
			<img alt="Empty State" class="img-fluid" src="assets/images/icons/mca-empty-state-external.svg" />
		</ng-template>
	</ng-container>
</div>
