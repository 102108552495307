import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromTradeOptions from '../actions/trade-options.actions';
import { map, switchMapTo } from 'rxjs/operators';
import { TradeOptionsService } from '@injectables/services/trade-options/trade-options.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TradeOptionsEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly tradeOptionsService: TradeOptionsService,
	) {}

	setTradeOptions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTradeOptions.initTradeOptionsActions),
			switchMapTo(this.tradeOptionsService.getAllTradeOptions()),
			map((options) => fromTradeOptions.setTradeOptionsActions({ options })),
		),
	);
}
