import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'domain-entities';
import moment from 'moment';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ScheduleDemoService } from '@injectables/services/schedule-demo/schedule-demo.service';
import { UpgradesTrialbannerCtaSelectedEventBuilder } from '@generated/events/UpgradesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';

@Component({
	selector: 'app-trial-banner',
	templateUrl: './trial-banner.component.html',
	styleUrls: ['./trial-banner.component.scss'],
})
export class TrialBannerComponent {
	@ViewChild('subscriptionInfoTemplateRef') subscriptionInfoTemplateRef: TemplateRef<any>;
	@Input()
	trialSubscription: Subscription;

	constructor(
		private readonly router: Router,
		private readonly dialog: MatDialog,
		private readonly trackingService: TrackingService,
		public readonly scheduleDemoService: ScheduleDemoService,
	) {}

	get daysRemaining(): number {
		return this.trialSubscription?.renewalDate
			? moment.unix(this.trialSubscription.renewalDate).diff(moment(), 'days')
			: 0;
	}

	navigateToProducts(): void {
		this.trackTrialBannerUpgradeEvent();
		this.router.navigateByUrl('settings/subscription/products');
	}

	async trackTrialBannerUpgradeEvent(): Promise<void> {
		await this.trackingService.trackEvent(new UpgradesTrialbannerCtaSelectedEventBuilder({}));
	}

	openSubscriptionInfoDialog(): void {
		this.dialog.open(this.subscriptionInfoTemplateRef, { width: '30vw', autoFocus: false });
	}
}
