import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InviteState, Member, Project, ProjectType } from 'domain-entities';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	isOwnerOfCompany,
	isSupervisorOfCompany,
	selectUserId,
} from '@store/selectors/app.selectors';
import { isProjectAdmin } from '@shared/functions/project/project.functions';
import { combineLatest } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { InternalMemberInviteStatus } from '@work/project-members/entities/project-member';
import { isNil } from 'lodash';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { selectProjectById } from '@store/selectors/projects.selectors';

@Component({
	selector: 'app-project-member-list-item',
	templateUrl: './project-member-list-item.component.html',
	styleUrls: ['./project-member-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMemberListItemComponent {
	@Input() member: Member;
	@Input() disabled = false;
	@Input() inviteStatus: InternalMemberInviteStatus;
	@Input() invitationId: string;

	@Output() addMember = new EventEmitter<Member>();
	@Output() removeMember = new EventEmitter<Member>();
	@Output() resendInvitation = new EventEmitter<Member>();
	@Output() remindInvitation = new EventEmitter<Member>();

	InviteState = InviteState;
	InternalMemberInviteStatus = InternalMemberInviteStatus;
	currentLoggedInUserId$ = this.store.select(selectUserId);
	isOwner$ = this.store.select(isOwnerOfCompany);
	isSupervisor$ = this.store.select(isSupervisorOfCompany);
	isOwnerOrSupervisor$ = combineLatest([this.isOwner$, this.isSupervisor$]).pipe(
		filter((roles) => roles.some(Boolean)),
	);
	projectType = ProjectType;
	project: Project;
	projectId$ = this.store.select(selectActiveProject);
	project$ = this.projectId$.pipe(
		switchMap((projectId) => this.store.select(selectProjectById(projectId))),
	);

	constructor(private readonly store: Store<AppState>) {
		this.project$.subscribe((project) => {
			this.project = project;
		});
	}

	get isAdmin(): boolean {
		return isProjectAdmin(this.member);
	}

	get isInviteStatusNil(): boolean {
		return isNil(this.inviteStatus);
	}
}
