import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import moment from 'moment';
import { isNil } from 'lodash';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationTokenService {
	constructor(
		private readonly messaging: AngularFireMessaging,
		private readonly localStorageService: LocalStorageService,
		private readonly remoteConfig: RemoteConfig,
	) {}

	getNotificationEnableReminderWeeks(): number {
		return this.remoteConfig.getValue('notification_enable_reminder_weeks') || 4;
	}

	async isTimestampExpiredOrNotExists(): Promise<boolean> {
		const timestamp = await this.localStorageService.get('notificationReminderTimestamp');

		if (isNil(timestamp)) {
			return true;
		}
		return (
			moment().diff(moment.unix(timestamp), 'weeks', true) >
			this.getNotificationEnableReminderWeeks()
		);
	}

	// INFO:: (Browser Native) Requesting permission and getting tokens, it will not make permission request if it is already granted
	async requestNotificationToken(): Promise<string | null> {
		return this.messaging.requestToken.pipe(take(1)).toPromise();
	}

	async getNotificationToken(): Promise<string | null> {
		return this.messaging.getToken.pipe(take(1)).toPromise();
	}
}
