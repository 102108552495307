import { Serializable } from '@craftnote/shared-utils';
import { Message } from 'domain-entities';

export class SearchResult implements Serializable<SearchResult> {
	constructor(public result: Message[] = []) {}

	deserialize(input: any): SearchResult {
		for (const message of input.result) {
			this.result.push(message);
		}
		return this;
	}
}
