<ng-template [ngIf]="sliderData.type === SharedSliderDialogSideType.TEXT">
	<ng-container [ngTemplateOutlet]="textInput"></ng-container>
</ng-template>
<ng-template [ngIf]="sliderData.type === SharedSliderDialogSideType.SELECTION">
	<ng-container *ngTemplateOutlet="selectionInput; context: { options: sliderData.selectionOptions }"></ng-container>
</ng-template>
<ng-template [ngIf]="sliderData.type === SharedSliderDialogSideType.TOGGLE">
	<ng-container *ngTemplateOutlet="toggleInput; context: { toggles: sliderData.toggles }"></ng-container>
</ng-template>

<ng-template #textInput>
	<div class="cn-card_input w-100">
		<mat-form-field appearance="outline" class="w-100 form-field-outline mt-2" floatLabel="auto">
			<mat-label>{{ sliderData.labelKey | translate }}</mat-label>
			<input
				(change)="sliderControl.markAsTouched() || sliderControl.markAsDirty()"
				[formControl]="sliderControl"
				data-cy="register-company-name"
				matInput
			/>
			<mat-error *ngIf="sliderControl.errors && sliderData.validation?.errorKeysByError[Object.keys(sliderControl.errors)[0]]">
				{{ sliderData.validation.errorKeysByError[Object.keys(sliderControl.errors)[0]] | translate }}
			</mat-error>
		</mat-form-field>
	</div>
</ng-template>

<ng-template #selectionInput let-options="options">
	<div class="cn-card_input w-100">
		<mat-form-field appearance="outline" class="w-100 form-field-outline mt-2" floatLabel="auto">
			<mat-label>{{ sliderData.labelKey | translate }}</mat-label>
			<mat-select [disableOptionCentering]="true" [formControl]="sliderControl" panelClass="cn-selection-panel">
				<mat-option *ngFor="let option of options" [value]="option">{{ option }} </mat-option>
			</mat-select>
			<mat-icon color="accent" data-cy="register-trade-arrow-down" matSuffix>keyboard_arrow_down</mat-icon>
		</mat-form-field>
	</div>
</ng-template>

<ng-template #toggleInput let-toggles="toggles">
	<app-shared-slider-input-toggle [control]="sliderControl" [toggles]="toggles"></app-shared-slider-input-toggle>
</ng-template>
