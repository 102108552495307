<div (onResized)="onResized($event)" appOnResize class="breadcrumbs d-flex">
	<div
		*ngFor="let last = last; let first = first; let breadCrumb; of: scalableBreadcrumbs$ | async; trackBy: identifyBreadcrumb"
		class="d-flex"
	>
		<ng-container
			*ngIf="!breadCrumb.isEllipse"
			[ngTemplateOutletContext]="{ breadCrumb: breadCrumb, last: last, first: first }"
			[ngTemplateOutlet]="nonEllipse"
		></ng-container>
		<ng-container
			*ngIf="breadCrumb.isEllipse"
			[ngTemplateOutletContext]="{ breadCrumb: breadCrumb }"
			[ngTemplateOutlet]="ellipse"
		></ng-container>
	</div>
</div>

<ng-template #nonEllipse let-breadCrumb="breadCrumb" let-first="first" let-last="last">
	<div
		[ngClass]="{
			'breadcrumbs__navigable pointer': !breadCrumb.readOnly,
			'breadcrumbs__last-folder': last && !breadCrumb.readOnly,
			breadcrumbs__dragging: isDragStarted && !last,
			breadcrumbs__folder: breadCrumb.hasChildren
		}"
		(click)="!last && handleClick(breadCrumb)"
		[ngStyle]="{ 'max-width.px': maxWidth$ | async }"
		class="text-truncate"
		#tooltip="matTooltip"
		[matTooltip]="breadCrumb.name"
		matTooltipPosition="above"
		[matTooltipDisabled]="breadCrumb.readOnly || (maxWidth$ | async) > getBreadcrumbFontLength(breadCrumb.name)"
		(mouseup)="!last && isDragAndDrop && isDragStarted && onDropItem.next(breadCrumb.id)"
	>
		{{ breadCrumb.name }}
	</div>
	<div *ngIf="!last" class="breadcrumbs__separator">/</div>
</ng-template>

<ng-template #ellipse let-breadCrumb="breadCrumb">
	<div
		[matMenuTriggerFor]="ellipseMenu"
		#ellipseMenuTrigger="matMenuTrigger"
		class="pointer text-truncate ellipse-menu"
		(mouseenter)="mouseEnter(ellipseMenuTrigger)"
		(mouseleave)="mouseLeave(ellipseMenuTrigger)"
	>
		{{ breadCrumb.name }}
	</div>
	<div class="breadcrumbs__separator">/</div>

	<mat-menu #ellipseMenu="matMenu" class="breadcrumbs__menu">
		<button
			mat-button
			mat-menu-item
			*ngFor="let childBreadcrumb of breadCrumb.children; trackBy: identifyBreadcrumb"
			(click)="handleClick(childBreadcrumb)"
			class="breadcrumbs__menu-item text-truncate text-center pointer"
			(mouseenter)="canCloseEllipseMenu = false"
			(mouseleave)="canCloseEllipseMenu = true; mouseLeave(ellipseMenuTrigger)"
			(mouseup)="isDragAndDrop && isDragStarted && onDropItem.next(childBreadcrumb.id)"
		>
			{{ childBreadcrumb.name }}
		</button>
	</mat-menu>
</ng-template>
