import { Injectable } from '@angular/core';
import { ProjectUnreadsConnector } from '@shared/firebase/connectors/firestore/collections/profiles/project-unread/project-unreads.connector';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectUserId } from '@store/selectors/app.selectors';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { selectProjectUnreadByProjectId } from '@store/selectors/project-unreads.selectors';
import { Project, ProjectType } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class ProjectUnreadsService {
	private userId$ = this.store.select(selectUserId).pipe(filter(Boolean));

	constructor(
		private readonly projectUnreadsConnector: ProjectUnreadsConnector,
		private readonly store: Store<AppState>,
	) {}

	async removeProjectUnread(project: Project): Promise<void> {
		if (project.projectType === ProjectType.FOLDER) {
			return;
		}
		const userId = await firstValueFrom(this.userId$);
		const path = await firstValueFrom(
			this.store.select(selectProjectUnreadByProjectId(project.id)),
		);
		if (path) {
			this.projectUnreadsConnector.removeProjectUnread(path, userId);
		}
	}

	async markProjectAsUnread(project: Project): Promise<void> {
		if (project.projectType === ProjectType.FOLDER) {
			return;
		}
		const parentId = project.parentProject;
		const userId = await firstValueFrom(this.userId$);
		let path = project.id;
		if (parentId) {
			path = `${parentId}/${path}`;
		}
		this.projectUnreadsConnector.addProjectUnread(path, userId);
	}

	watchProjectUnreads(): Observable<string[]> {
		return this.userId$.pipe(
			switchMap((userId) =>
				this.projectUnreadsConnector
					.watchProjectUnreads(userId)
					.pipe(catchError(() => of({ items: [] }))),
			),
			map((resp) => (resp ? resp.items : [])),
		);
	}
}
