import { Injectable } from '@angular/core';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Observable } from 'rxjs';
import { ExternalChatUserState } from 'domain-entities';
import { DocumentChanges } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class ExternalChatUserStatesConnector {
	private static COLLECTION_NAME = 'externalChatUserStates';

	constructor(private readonly connector: FirestoreConnector) {}

	watchUserStateById(userId: string): Observable<DocumentChanges<ExternalChatUserState>> {
		return this.connector.watchDocumentChanges<ExternalChatUserState>(
			ExternalChatUserStatesConnector.COLLECTION_NAME + '/' + userId,
		);
	}
}
