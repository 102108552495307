import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { MemberRole, TrackedTime, WorkType } from 'domain-entities';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
	combineLatest,
	firstValueFrom,
	lastValueFrom,
	of,
	ReplaySubject,
	Subject,
	timer,
} from 'rxjs';
import {
	distinctUntilKeyChanged,
	filter,
	map,
	startWith,
	switchMap,
	switchMapTo,
	take,
	takeUntil,
} from 'rxjs/operators';
import moment from 'moment';
import { TrackedTimeWorkTypeButtonService } from './services/tracked-time-work-type-button.service';
import {
	selectAuthState,
	selectCompanyId,
	selectCompanyMembersAcceptedArray,
	selectUserId,
	selectUserRole,
} from '@store/selectors/app.selectors';
import {
	selectProjectAcceptedMembersEntities,
	selectProjectById,
} from '@store/selectors/projects.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isEqual, isNil, keyBy, omit } from 'lodash';
import { DateAdapter } from '@angular/material/core';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { v4 as uuid } from 'uuid';
import { TrackedTimeAddEditFormService } from '@modules/shared/components/time-tracking-add-edit/components/tracked-time-add-edit-panel/tracked-time-add-edit-form/services/tracked-time-add-edit-form.service';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	TimetrackingTimeCreatedEventBuilder,
	TimetrackingTimeDeletedEventBuilder,
	TimetrackingTimeUpdatedEventBuilder,
} from '@generated/events/TimetrackingEvents.generated';
import { TranslateService } from '@ngx-translate/core';
import { combineDateTime, getMemberFullName, shareReplayOne } from '@craftnote/shared-utils';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePickerService } from '@injectables/services/date-picker.service';
import { addDays, endOfDay, fromUnixTime, getUnixTime, isValid, startOfDay } from 'date-fns';
import {
	TrackedTimeAddEditForm,
	TrackedTimeAddEditFormPauseValue,
} from '@modules/shared/components/time-tracking-add-edit/components/tracked-time-add-edit-panel/types/tracked-time-add-edit-form.types';
import {
	getPauseStart,
	pauseValidator,
	trackedTimeValidator,
} from './functions/tracked-time-add-edit-form.functions';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { TrackedTimesChangesStore } from '@modules/shared/components/time-tracking-add-edit/store/tracked-times-changes.store';
import { isCompanyAdmin } from '@shared/functions/company/company.functions';
import { selectCurrentUserFromCompanyMembers } from '@store/selectors/company.selectors';
import { ProjectService } from '@injectables/services/project/project.service';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { formatTimeToHHMM } from '@shared/shared.utils';

/*
	Info:: We can always have one assignee while initializing form but can have multiple assignees after that.
	Thats why trackedTime has type TrackedTime and trackedTimeUpdates has type TrackedTime[]
 */

@Component({
	selector: 'app-tracked-time-add-edit-form',
	templateUrl: './tracked-time-add-edit-form.component.html',
	styleUrls: ['./tracked-time-add-edit-form.component.scss'],
	providers: [
		TrackedTimeWorkTypeButtonService,
		TrackedTimeAddEditFormService,
		// FIXME:: remove me once moment adapter is removed from dashboard module
		{ provide: DateAdapter, useClass: DatePickerService },
		TrackedTimesChangesStore,
	],
})
export class TrackedTimeAddEditFormComponent implements OnChanges, OnDestroy, OnInit {
	private projectIdOfTrackedTime$ = new ReplaySubject<string>();
	private isCurrentUserAdmin$ = combineLatest([
		this.store.select(selectCompanyId),
		this.store.select(selectCurrentUserFromCompanyMembers),
		this.projectIdOfTrackedTime$,
	]).pipe(
		switchMap(([companyId, currentUserAsMemberInCompany, projectId]) => {
			if (!isCompanyAdmin(currentUserAsMemberInCompany)) {
				return of(false);
			}
			return this.store
				.select(selectProjectById(projectId))
				.pipe(map((project) => project?.company === companyId));
		}),
	);
	private trackedTime$$ = new ReplaySubject<TrackedTime>();
	private destroy$ = new Subject();
	@Input() trackedTime: TrackedTime = null;
	@Input() showProjectRedirection = true;
	@Input() showAssigneePaywall = true;
	@Output() close = new EventEmitter<void>();
	trackedTimeUpdated: TrackedTime[];
	workTypeListPanelVisible = false;
	changeLogVisible = false;
	isDisabledByFourtyEightHoursRule = false;
	trackedTimeForm = this.fb.group(
		{
			startDate: [[Validators.required]],
			startTime: [[Validators.required]],
			endDate: [[Validators.required]],
			endTime: [[Validators.required]],
			pause: [{ start: null, duration: 0, breaks: [] }, [Validators.required]],
			workTypeId: [[Validators.required]],
			assigneeIds: [[Validators.required]],
			projectId: [[Validators.required]],
			comment: [],
		},
		{ validators: [pauseValidator, trackedTimeValidator] },
	);
	projectMembers$ = this.trackedTimeForm.get('projectId').valueChanges.pipe(
		switchMap((projectId) =>
			combineLatest([
				this.store.select(selectProjectAcceptedMembersEntities, { projectId }),
				this.store.select(selectAuthState),
			]),
		),
		map(([memberEntities, authState]) => {
			memberEntities[authState.userId] = Object.assign({ id: authState.userId }, authState.user);
			return memberEntities;
		}),
		startWith({}),
		shareReplayOne(),
	);
	// If assignee is not found in project members stream then use this stream
	fallBackAssignees$ = this.trackedTimeForm.get('projectId').valueChanges.pipe(
		switchMapTo(this.store.select(selectCompanyMembersAcceptedArray)),
		map((companyMembers) => {
			if (this.trackedTime) {
				return {
					[this.trackedTime.userId]: { name: this.trackedTime.userName },
					...keyBy(companyMembers, 'id'),
				};
			}
		}),
		shareReplayOne(),
	);
	isCurrentUserNotAdmin$ = this.isCurrentUserAdmin$.pipe(map((isAdmin) => !isAdmin));
	isFormValidated = false;
	isSubmitRunning = false;
	project$ = this.trackedTime$$.pipe(
		filter(Boolean),
		distinctUntilKeyChanged('projectId'),
		switchMap((trackedTime) => this.projectService.watchProject(trackedTime.projectId)),
	);
	projectRoute$ = combineLatest([
		this.store.select(selectUserId),
		this.project$.pipe(filter(Boolean)),
	]).pipe(
		map(([userId, project]) => {
			if (project.membersActive.includes(userId)) {
				return `/projects/${project.id}`;
			} else if (project.membersArchived.includes(userId)) {
				return `/archive/${project.id}`;
			} else {
				return null;
			}
		}),
	);

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly store: Store<AppState>,
		private readonly trackedTimesChangesStore: TrackedTimesChangesStore,
		private readonly trackedTimeWorkTypeButtonService: TrackedTimeWorkTypeButtonService,
		private readonly localStorageService: LocalStorageService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly trackedTimeAddEditFormService: TrackedTimeAddEditFormService,
		private readonly trackingService: TrackingService,
		private readonly translateService: TranslateService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly projectService: ProjectService,
	) {}

	get isComponentReady(): boolean {
		return this.trackedTime && this.trackedTimeUpdated && !!this.trackedTimeForm;
	}

	get projectId(): string {
		return this.trackedTimeForm.get('projectId').value;
	}

	get startTime(): Date {
		return this.trackedTimeForm.get('startTime').value;
	}

	get endTime(): Date {
		return this.trackedTimeForm.get('endTime').value;
	}

	get startDate(): Date {
		return this.trackedTimeForm.get('startDate').value;
	}

	get endDate(): Date {
		return this.trackedTimeForm.get('endDate').value;
	}

	get startOfStartDate(): Date {
		return startOfDay(this.startDate);
	}

	get endOfStartDate(): Date {
		return endOfDay(this.startDate);
	}

	get startOfEndDate(): Date {
		return startOfDay(this.endDate);
	}

	get endOfEndDate(): Date {
		return endOfDay(this.endDate);
	}

	get pause(): TrackedTimeAddEditFormPauseValue {
		return this.trackedTimeForm.get('pause').value;
	}

	get startDateTime(): Date {
		return combineDateTime(this.startDate, this.startTime);
	}

	get endDateTime(): Date {
		return combineDateTime(this.endDate, this.endTime);
	}

	get workType(): WorkType {
		return (
			this.trackedTime?.id && {
				id: this.trackedTime?.workTypeId,
				name: this.trackedTime?.workTypeName,
				editable: false,
				deleted: false,
			}
		);
	}

	get isFormValid(): boolean {
		const isFormValid = this.trackedTimeForm.valid;
		if (this.isEditMode && !this.isFormValueChanged) {
			return false;
		}
		return isFormValid;
	}

	get isFormValueChanged(): boolean {
		// Form is always initialized with one assignee only
		// trackedTimeUpdated length equals number of assignees
		// If we have more then one assignees then user has added one, and form has changed
		if (this.trackedTimeUpdated?.length > 1) {
			return true;
		}

		// Omitting userName because userName is different in TrackedTime and project Member
		const fieldsToOmit = ['userName', 'lastEditedBy', 'workTypeName'];

		if (this.trackedTimeUpdated?.length <= 0) {
			return false;
		}
		const existingTrackedTime = omit(this.trackedTimeUpdated[0], fieldsToOmit);
		const currentTrackedTime = omit(this.trackedTime, fieldsToOmit);

		if (isNil(currentTrackedTime.pauseStartTime)) {
			currentTrackedTime.pauseStartTime = undefined;
		}
		return !isEqual(currentTrackedTime, existingTrackedTime);
	}

	get startEndDuration(): number {
		let duration = moment(this.endDateTime).diff(moment(this.startDateTime), 'seconds');
		duration = duration - this.pause.duration;
		return duration;
	}

	get startEndDurationFormatted(): { hr: string; min: string } {
		return formatTimeToHHMM(this.startEndDuration);
	}

	get isEditMode(): boolean {
		return !!this.trackedTime?.id?.length;
	}

	get isAddMode(): boolean {
		return !this.isEditMode;
	}

	get trackedTimeRange(): { start: Date; end: Date } {
		return {
			start: this.startDateTime,
			end: this.endDateTime,
		};
	}

	ngOnInit(): void {
		this.trackedTimesChangesStore.getChanges(this.trackedTime?.id);
		this.watchFourtyEightHoursRule();
		this.trackedTimeForm.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (formValue) => this.formValueChanges(formValue));
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.trackedTime?.currentValue) {
			this.projectIdOfTrackedTime$.next(changes.trackedTime.currentValue.projectId);
			const newFormValue = await this.fromEntityToFormValue(changes.trackedTime.currentValue);
			this.trackedTimeForm.setValue(newFormValue);
			this.trackedTime$$.next(changes.trackedTime.currentValue);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	openWorkTypeListPanel(): void {
		this.workTypeListPanelVisible = true;
	}

	closeWorkTypeListPanel(): void {
		this.workTypeListPanelVisible = false;
	}

	openChangeLog(): void {
		this.changeLogVisible = true;
	}

	closeChangeLog(): void {
		this.changeLogVisible = false;
	}

	async onCancel(): Promise<void> {
		if (this.isFormValueChanged) {
			const result = await this.confirmDialogService
				.open({
					title: this.translateService.instant('time-tracking-add-edit-form.dialog.title'),
					message: this.translateService.instant('time-tracking-add-edit-form.dialog.message'),
					primaryButtonColor: 'accent',
					showCrossBtn: false,
					primaryButtonText: this.translateService.instant(
						'time-tracking-add-edit-form.dialog.primaryButtonText',
					),
					secondaryButtonText: this.translateService.instant(
						'time-tracking-add-edit-form.dialog.secondaryButtonText',
					),
				})
				.afterClosed()
				.pipe(take(1))
				.toPromise();
			if (result) {
				return;
			} else {
				this.trackedTimeForm.markAsUntouched();
			}
		}
		this.close.emit();
		this.trackedTime = null;
		this.trackedTimeUpdated = null;
	}

	async deleteTrackedTime(trackedTimes: TrackedTime[]): Promise<void> {
		const result = await firstValueFrom(
			this.confirmDialogService
				.open({
					title: this.translateService.instant('time-tracking-add-edit-form.delete-dialog.title'),
					message: this.translateService.instant(
						'time-tracking-add-edit-form.delete-dialog.message',
					),
					primaryButtonColor: 'warn',
					showCrossBtn: false,
					primaryButtonText: this.translateService.instant(
						'time-tracking-add-edit-form.delete-dialog.primaryButtonText',
					),
					secondaryButtonText: this.translateService.instant(
						'time-tracking-add-edit-form.delete-dialog.secondaryButtonText',
					),
				})
				.afterClosed(),
		);
		if (!result) {
			return;
		}
		const trackedTimeToDelete: TrackedTime = trackedTimes[0];
		trackedTimeToDelete.state = 'deleted';
		await this.trackedTimeAddEditFormService.updateTrackedTime(trackedTimeToDelete, {
			preventOverlap: false,
		});

		this.trackingService.trackEvent(
			new TimetrackingTimeDeletedEventBuilder({
				trackedTimeId: trackedTimeToDelete.id,
				projectId: trackedTimeToDelete.projectId,
			}),
		);
		this.close.emit();
	}

	async submitForm(trackedTimes: TrackedTime[]): Promise<void> {
		if (!this.isFormValid || this.isSubmitRunning) {
			return;
		}
		this.isSubmitRunning = true;
		let result: boolean;
		if (this.isAddMode) {
			result = await this.createTimeTrackedTime(trackedTimes);
		} else {
			result = await this.updateTrackedTime(trackedTimes[0]);
		}
		if (!result) {
			this.displayTimeOverlapError();
			//Block saving for 1s to prevent very frequent clicking by the user
			await lastValueFrom(timer(1000));
			this.isSubmitRunning = false;
			return;
		}

		this.isSubmitRunning = false;
		this.close.emit();
	}

	onChangeStartDate(event: MatDatepickerInputEvent<Date>): void {
		this.trackedTimeForm.patchValue(
			{
				startTime: combineDateTime(event.value, this.startTime),
			},
			{ emitEvent: false },
		);
	}

	onChangeEndDate(event: MatDatepickerInputEvent<Date>): void {
		this.trackedTimeForm.patchValue(
			{
				endTime: combineDateTime(event.value, this.endTime),
			},
			{ emitEvent: false },
		);
	}

	private async fromEntityToFormValue(trackedTime: TrackedTime): Promise<TrackedTimeAddEditForm> {
		const pauseStart = fromUnixTime(trackedTime.pauseStartTime);
		return {
			startDate: fromUnixTime(trackedTime.startTime),
			startTime: fromUnixTime(trackedTime.startTime),
			endDate: fromUnixTime(trackedTime.endTime),
			endTime: fromUnixTime(trackedTime.endTime),
			pause: {
				start: isValid(pauseStart) ? pauseStart : null,
				duration: trackedTime.pauseDuration ?? 0,
				breaks:
					trackedTime.breaks?.map((breakObject) => ({
						start: fromUnixTime(breakObject.start),
						end: fromUnixTime(breakObject.end),
					})) || [],
			},
			workTypeId: trackedTime.workTypeId,
			assigneeIds: [trackedTime.userId],
			projectId: trackedTime.projectId,
			comment: trackedTime.comment || '',
		};
	}

	private omitValues<T extends object>(obj: T, fun: (...args) => unknown): T {
		const newObject = { ...obj };
		Object.keys(newObject).forEach((key) => {
			if (fun(newObject[key])) {
				delete newObject[key];
			}
		});
		return newObject;
	}

	private async fromFormValueToEntityList(
		formValue: TrackedTimeAddEditForm,
	): Promise<TrackedTime[]> {
		const projectMembers = await this.projectMembers$.pipe(take(1)).toPromise();
		let workTypeEntities = await this.trackedTimeWorkTypeButtonService.workTypeEntities$
			.pipe(take(1))
			.toPromise();
		// Adding work type which came from tracked time (external member)
		if (this.isEditMode && !workTypeEntities[this.workType.id]) {
			workTypeEntities = { ...workTypeEntities, ...{ [this.workType.id]: this.workType } };
		}

		return formValue.assigneeIds.map((assignee) =>
			this.omitValues<TrackedTime>(
				Object.assign<TrackedTime, Partial<TrackedTime>>(
					{ ...this.trackedTime },
					{
						startTime: moment(combineDateTime(formValue.startDate, formValue.startTime)).unix(),
						pauseDuration: formValue.pause.duration ?? 0,
						pauseStartTime: getUnixTime(getPauseStart(formValue)) || undefined,
						breaks: formValue.pause.breaks
							.filter((breakObject) => breakObject.start && breakObject.end) // Filter out breaks with null values
							.map((breakObject) => ({
								start: getUnixTime(breakObject.start),
								end: getUnixTime(breakObject.end),
							})),
						workTypeId: formValue.workTypeId,
						workTypeName: workTypeEntities[formValue.workTypeId]?.name,
						// Form is initialized with the owner, which does not exists in project members
						userName: getMemberFullName(projectMembers[assignee], this.trackedTime.userName),
						userId: assignee,
						endTime: moment(combineDateTime(formValue.endDate, formValue.endTime)).unix(),
						comment: formValue.comment,
						projectId: formValue.projectId,
					},
				),
				(value) => value === '',
			),
		);
	}

	private async formValueChanges(formValue: TrackedTimeAddEditForm): Promise<void> {
		this.trackedTimeUpdated = await this.fromFormValueToEntityList(formValue);
	}

	private async createTimeTrackedTime(trackedTimes: TrackedTime[]): Promise<boolean> {
		const groupId = uuid();
		trackedTimes.forEach((trackedTime) => {
			trackedTime.id = uuid();
			trackedTime.creationTime = moment().unix();
			if (trackedTimes.length > 1) {
				trackedTime.groupId = groupId;
			}
		});

		const result = await this.trackedTimeAddEditFormService.createTrackedTimes(trackedTimes);
		if (!result) {
			return false;
		}
		trackedTimes.forEach((trackedTime) => {
			this.trackingService.trackEvent(
				new TimetrackingTimeCreatedEventBuilder({
					projectId: trackedTime.projectId,
					context: 'self-input',
					trackedTimeId: trackedTime.id,
				}),
			);
		});

		await this.localStorageService.set('hasUserCreatedWorkTypeBefore', true);
		return true;
	}

	private async updateTrackedTime(trackedTimeToEdit: TrackedTime): Promise<boolean> {
		trackedTimeToEdit.lastEditedBy = await this.store
			.select(selectUserId)
			.pipe(take(1))
			.toPromise();
		await this.trackingService.trackEvent(
			new TimetrackingTimeUpdatedEventBuilder({
				trackedTimeId: trackedTimeToEdit.id,
				projectId: trackedTimeToEdit.projectId,
			}),
		);
		return this.trackedTimeAddEditFormService.updateTrackedTime(trackedTimeToEdit);
	}

	private displayTimeOverlapError(): void {
		this.notificationSnackbarService.show(BasicSnackbarComponent, {
			componentTypes: {
				description: this.translateService.instant(
					'time-tracking-add-edit-form.errors.time_overlap',
				),
				icon: 'close',
				type: 'warn',
			},
			level: 1,
			timeout: 5000,
		});
	}

	private watchFourtyEightHoursRule(): void {
		const fourtyEighHoursPassed$ = this.trackedTime$$.pipe(
			filter<TrackedTime>(Boolean),
			switchMap((trackedTime) => {
				/**
				 * In case of a new tracked time the creation time is zero.
				 * In that case the 48 hours rule can never be violated.
				 */
				if (trackedTime.creationTime === 0) {
					return of(false);
				}
				return timer(addDays(fromUnixTime(trackedTime.creationTime), 2)).pipe(
					map(() => true),
					startWith(false),
				);
			}),
		);
		combineLatest([this.store.select(selectUserRole), fourtyEighHoursPassed$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([role, fourtyEightHoursPassed]) => {
				if (fourtyEightHoursPassed && role === MemberRole.EMPLOYEE) {
					this.trackedTimeForm.disable();
					this.isDisabledByFourtyEightHoursRule = true;
				} else {
					this.isDisabledByFourtyEightHoursRule = false;
					this.trackedTimeForm.enable();
				}
			});
	}
}
