<div class="col col-lg-6 banner decoration">
	<div class="logo-container">
		<div class="logo"></div>
		<div class="claim row">
			<div class="col-2">
				<img src="assets/images/icons/doublewindow.png" alt="double-window" />
			</div>
			<div class="col-10">
				<h1 class="text-primary">{{ 'banner.claim1' | translate }}</h1>
				<br /><span class="text-color">{{ 'banner.claim1_support' | translate }}</span>
			</div>
		</div>
		<div class="claim row">
			<div class="col-2"><img src="assets/images/icons/thumbsup.png" alt="thumbsup" /></div>
			<div class="col-10">
				<h1 class="text-primary">{{ 'banner.claim2' | translate }}</h1>
				<br /><span class="text-color">{{ 'banner.claim2_support' | translate }}</span>
			</div>
		</div>
		<div class="claim lastclaim row">
			<div class="col-2"><img src="assets/images/icons/arrow.png" alt="arrow" /></div>
			<div class="col-10">
				<h1 class="text-primary">{{ 'banner.claim3' | translate }}</h1>
				<br /><span class="text-color">{{ 'banner.claim3_support' | translate }}</span>
			</div>
		</div>
		<div class="mt-4 pt-3 h5 text-color text-center">
			{{ 'banner.info' | translate }}
		</div>
		<div class="mt-4 row justify-content-center">
			<a *ngIf="android" href="https://play.google.com/store/apps/details?id=de.mycrafty.mycrafty" target="_blank" rel="noopener">
				<img class="mr-3" src="assets/images/google-play-button.png" width="200" alt="google-play" />
			</a>
			<a *ngIf="ios" href="https://itunes.apple.com/de/app/mycrafty-handwerker-app/id1327810465?mt=8" target="_blank" rel="noopener">
				<img src="assets/images/apple-store-button.png" width="200" alt="app-store" />
			</a>
		</div>
		<!--div class="supportlink text-color">
      {{'banner.supportlink' | translate}}<a class="text-color-orange " href=" https://mycrafty.zendesk.com" title="To MyCRAFTY support.">{{'banner.supportlinkHREF' | translate}}</a>
    </div-->
	</div>
	<div class="col col-lg-6 overlay"></div>
</div>
