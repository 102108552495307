import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { invitationSelectors } from '@store/reducers/invitations.reducer';

const invitationState = (state: AppState) => state.invitationsState;

export const selectInvitationLink = createSelector(
	invitationState,
	(state) => state.invitationsLinkState,
);

export const selectInvitationsState = createSelector(invitationState, (state) => state.invitations);

export const selectInvitations = createSelector(
	selectInvitationsState,
	invitationSelectors.selectAll,
);

export const selectOpenInvitations = createSelector(selectInvitations, (invitations) =>
	invitations.filter((invitation) => ['open', 'read'].includes(invitation.state)),
);

export const selectInvitationsLoadingState = createSelector(
	selectInvitationsState,
	(state) => state.loadingStatus,
);

export const selectInvitationById = createSelector(selectInvitationsState, (state, { id }) => {
	const entities = invitationSelectors.selectEntities(state);
	return entities[id] || null;
});
