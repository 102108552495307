<section class="tracked-time-add-edit-panel-section pt-3 h-100">
	<app-tracked-time-add-edit-form
		(close)="closePanel.emit()"
		*ngIf="trackedTime"
		[showProjectRedirection]="showProjectRedirection"
		[showAssigneePaywall]="showAssigneePaywall"
		[trackedTime]="trackedTime"
	>
	</app-tracked-time-add-edit-form>
</section>
