<section class="task-edit-details-section p-3">
	<div class="d-flex align-items-center mb-3">
		<div class="d-flex align-items-center">
			<button (click)="onCancelEditDetails()" class="mr-3" mat-icon-button>
				<mat-icon class="close-btn-icon">clear</mat-icon>
			</button>

			<div class="m-0 d-inline-flex mat-headline font-weight-bold">
				{{ 'task.edit' | translate }}
			</div>
		</div>

		<div class="ml-auto d-flex align-items-center">
			<small class="text-primary d-flex align-items-center" [ngClass]="(showChangesSavedIndicator$ | async) ? 'visible' : 'invisible'">
				<mat-icon class="auto-save-icon mr-1">done</mat-icon>
				{{ 'dashboard.my-tasks.auto-save' | translate }}
			</small>
			<button (click)="onDeleteTask()" mat-icon-button>
				<mat-icon>delete_outline</mat-icon>
			</button>
		</div>
	</div>

	<div class="flex-grow-1 overflow-y-auto min-height-0 pb-5">
		<app-task-edit-form
			(taskUpdated)="onUpdateTaskDebounced($event)"
			*ngIf="taskUI?.task && taskUI?.project"
			[task]="taskUI.task"
			[project]="taskUI.project"
		></app-task-edit-form>

		<a
			(click)="openWorkingHours.emit(taskUI?.task.id)"
			class="working-hours-btn d-flex align-items-center justify-content-between rounded p-3 mb-2"
			href=""
		>
			<mat-icon class="mr-2">schedule</mat-icon>
			<span>{{ 'task.working_hours' | translate }}</span>
			<div class="flex-grow-1"></div>
			<span *ngVar="workingHoursLength$ | async as workingHoursLength" class="font-weight-bold">
				{{ workingHoursLength }}
				<ng-container *ngIf="workingHoursLength <= 1">{{ 'explorer.single-count' | translate }}</ng-container>
				<ng-container *ngIf="workingHoursLength > 1">{{ 'explorer.count' | translate }}</ng-container>
			</span>
			<mat-icon class="ml-auto arrow-icon">keyboard_arrow_right</mat-icon>
		</a>

		<a
			(click)="openTaskFiles.emit(taskUI?.task.id)"
			class="task-file-btn mb-2 d-flex align-items-center justify-content-between rounded p-3"
			href=""
		>
			<mat-icon class="mr-2">attachment</mat-icon>
			<span>{{ 'task-edit-attachments.title' | translate }}</span>
			<div class="flex-grow-1"></div>
			<span *ngVar="taskFilesLength$ | async as taskFilesLength" class="font-weight-bold">
				{{ taskFilesLength }}
				<ng-container *ngIf="taskFilesLength <= 1">{{ 'explorer.single-count' | translate }}</ng-container>
				<ng-container *ngIf="taskFilesLength > 1">{{ 'explorer.count' | translate }}</ng-container>
			</span>
			<mat-icon class="ml-auto arrow-icon">keyboard_arrow_right</mat-icon>
		</a>

		<a
			(click)="openTaskComments.emit(taskUI?.task.id)"
			class="task-comments-btn d-flex align-items-center justify-content-between rounded p-3"
			href=""
		>
			<mat-icon class="mr-2" fontSet="material-icons-outlined">comment</mat-icon>
			<span>{{ 'my-tasks.comments.title' | translate }}</span>
			<div class="flex-grow-1"></div>
			<span *ngVar="taskCommentsLength$ | async as taskCommentsLength" class="font-weight-bold">
				{{ taskCommentsLength }}
			</span>
			<mat-icon class="ml-auto arrow-icon">keyboard_arrow_right</mat-icon>
		</a>
	</div>
</section>
