import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as companyFilesAction from '../actions/company-files.actions';
import { companyFilesChangedAction } from '../actions/company-files.actions';
import { Action, Store } from '@ngrx/store';
import { map, pluck, switchMap } from 'rxjs/operators';
import { AppState } from '../state/app.state';
import { CompanyFileService } from '@injectables/services/company-files/company-file.service';
import { of } from 'rxjs';
import { selectCurrentUserProfileLimits } from '../selectors/profile-limits.selectors';
import { selectCompanyId } from '../selectors/app.selectors';

@Injectable()
export class CompanyFilesEffects implements OnInitEffects {
	loadCompanyFilesEffect$ = createEffect(() =>
		this.actions.pipe(
			ofType(companyFilesAction.loadCompanyFilesAction),
			switchMap(() =>
				this.store.select(selectCurrentUserProfileLimits).pipe(pluck('companyTemplates')),
			),
			switchMap((limits) => {
				if (!limits) {
					return of(companyFilesAction.clearCompanyFilesAction());
				}

				return this.store.select(selectCompanyId).pipe(
					switchMap((companyId) =>
						this.companyFileService.getCompanyFileEntityStateChanges(companyId),
					),
					map((changes) => companyFilesChangedAction({ changes })),
				);
			}),
		),
	);

	constructor(
		private readonly actions: Actions,
		private readonly store: Store<AppState>,
		private readonly companyFileService: CompanyFileService,
	) {}

	ngrxOnInitEffects(): Action {
		return companyFilesAction.loadCompanyFilesAction();
	}
}
