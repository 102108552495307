import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectUnreadsState } from '@store/state/project-unreads.state';
import { projectUnreadsSelectors } from '@store/reducers/project-unreads.reducer';
import { selectAllActiveProjectsEntities } from '@store/selectors/projects.selectors';
import { last } from 'lodash';

const projectUnreadsSelector = createFeatureSelector<ProjectUnreadsState>('projectUnreads');

export const selectAllProjectUnreads = createSelector(projectUnreadsSelector, (state) => {
	return projectUnreadsSelectors.selectAll(state);
});

export const selectAllProjectUnreadsEntities = createSelector(projectUnreadsSelector, (state) => {
	return projectUnreadsSelectors.selectEntities(state);
});

// Note:: Key is project id or folder id and value is either projectId or folderId/ProjectId
export const selectProjectUnreadsOfActiveProjectsEntities = createSelector(
	selectAllActiveProjectsEntities,
	selectAllProjectUnreadsEntities,
	(activeProjects, projectUnreads) => {
		if (!activeProjects || !projectUnreads) {
			return {};
		}
		const projectUnreadsOfActiveProjectsEntities = {};

		Object.keys(projectUnreads).forEach((projectUnread) => {
			const splittedPath = projectUnread.split('/');
			const projectId = last(splittedPath);

			// if its not in active projects we have to skip it
			if (!activeProjects[projectId]) {
				return;
			}

			if (splittedPath.length === 1) {
				// project is on root
				projectUnreadsOfActiveProjectsEntities[projectUnread] = projectUnread;
			}
			{
				// Note:: We have to show dot on folders as well. So we have to keep multiple entries for each folder as well
				splittedPath.forEach((path) => {
					projectUnreadsOfActiveProjectsEntities[path] = projectUnread;
				});
			}
		});

		return projectUnreadsOfActiveProjectsEntities;
	},
);

export const selectProjectUnreadsLoadingStatus = createSelector(projectUnreadsSelector, (state) => {
	return state.loadingStatus;
});

// Note:: Returns projectId, folderId or folderId/projectId for nested projects
export const selectProjectUnreadByProjectId = (projectId: string) =>
	createSelector(selectProjectUnreadsOfActiveProjectsEntities, (entities) => entities[projectId]);

export const selectKeepActiveProjectUnread = createSelector(projectUnreadsSelector, (state) => {
	return state.keepCurrentProjectUnread;
});
