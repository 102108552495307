<div class="cn-card d-flex flex-column align-items-center">
	<div class="cn-card_counter font-weight-bold">
		<span>{{ index + 1 }}</span> / <span>{{ totalNumberOfSlides }}</span>
	</div>
	<p class="cn-card_title mb-2 font-weight-bold" data-cy="register-onboarding-screen">{{ slide.titleKey | translate }}</p>
	<p class="cn-card_subtitle">{{ slide.subtitleKey | translate }}</p>

	<app-shared-slider-control
		*ngIf="[SharedSliderDialogSideType.TEXT, SharedSliderDialogSideType.SELECTION, SharedSliderDialogSideType.TOGGLE].includes(slide.type)"
		[sliderControl]="control"
		[sliderData]="slide"
	></app-shared-slider-control>

	<ng-container *ngIf="slide.type === SharedSliderDialogSideType.CUSTOM_VIEW">
		<ng-container *ngTemplateOutlet="slide.customViewTemplateRef; context: { $implicit: next }"></ng-container>
	</ng-container>

	<ng-container *ngIf="slide.type == SharedSliderDialogSideType.FORM">
		<app-shared-slider-control
			*ngFor="let sliderData of slide.controls; let i = index"
			[sliderControl]="control.controls[i]"
			[sliderData]="sliderData"
		></app-shared-slider-control>
	</ng-container>

	<button
		*ngIf="slide.type !== SharedSliderDialogSideType.CUSTOM_VIEW"
		tabindex="0"
		(click)="onNext()"
		[disabled]="!control.valid"
		mat-button
		mat-flat-button
		color="accent"
		data-cy="register-save-next-button"
	>
		{{ slide.actionKey | translate }}
	</button>
</div>
