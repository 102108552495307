import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { EQUALS } from '@shared/constants/expression';
import { COLLECTION_COMPANY_FILES } from '@shared/constants/firebase';
import { THUMB } from '@shared/constants/thumbnail';
import { JPG } from '@shared/constants/upload.types';
import { BaseFileService } from '@injectables/services/base-file.service';
import { COMPANY_FILE_TYPE, CompanyTemplate } from '@shared/models/company-template.model';
import { Company } from '@shared/models/company.model';
import { FileDocument } from '@craftnote/shared-utils';
import { ApproveDialogComponent } from '@work/file-explorer/modals/approve-dialog/approve-dialog.component';
import { PublicFile } from '@injectables/services/public-folders/public-folders.service';
import {
	CompanyTemplateUploadParameters,
	FileUploadService,
} from '@injectables/services/file-upload/file-upload.service';
import { CompanyFile, File as FileEntity } from 'domain-entities';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileContext } from '@injectables/services/file-upload/file-upload.types';

@Injectable({
	providedIn: 'root',
})
export class CompanyTemplatesService {
	public loading: boolean;
	private destroy$: Subject<boolean> = new Subject();

	constructor(
		private readonly dialog: MatDialog,
		private readonly baseFile: BaseFileService,
		private readonly afStore: AngularFirestore,
		private readonly uploadService: FileUploadService,
		private readonly storage: AngularFireStorage,
	) {}

	countItemsInFolder(element: CompanyTemplate, templates: CompanyTemplate[]) {
		if (this.baseFile.isFolder(element)) {
			const result = [];
			templates.forEach((generalFile: CompanyTemplate) => {
				if (generalFile.folderId === element.id) {
					result.push(generalFile);
				}
			});

			return result.length + ' ';
		}
		const creationDate = new Date(+element.creationTimestamp * 1000);

		return moment(creationDate).format('DD/MM/YYYY');
	}

	queryInFolder(currentRoot: CompanyTemplate | FileDocument, templates: CompanyTemplate[]) {
		this.loading = true;
		const result: CompanyTemplate[] = [];
		templates.forEach((template) => {
			if (currentRoot.id === template.folderId) {
				result.push(this.baseFile.clone(template));
			}
		});
		this.loading = false;

		return result;
	}

	public rootFiles(files: CompanyTemplate[]) {
		const companyFiles = files.filter(
			(file: CompanyTemplate) => !file.folderId && file.type !== COMPANY_FILE_TYPE.FOLDER,
		);
		const companyFolders = files.filter(
			(file: CompanyTemplate) => file.type === COMPANY_FILE_TYPE.FOLDER && !file.folderId,
		);

		return { companyFiles, companyFolders };
	}

	updateFileOrFolder(file: CompanyTemplate) {
		this.loading = true;
		this.afStore
			.collection(COLLECTION_COMPANY_FILES)
			.doc(file.id)
			.set(JSON.parse(JSON.stringify(file)))
			.then(() => (this.loading = false));
	}

	deleteFile(file: CompanyTemplate) {
		const dialogDelete = this.dialog.open(ApproveDialogComponent, { data: { isFile: true } });
		dialogDelete
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result) {
					this.removeFile(file);
				}
			});
	}

	addFolder(folder: { name: string }, currentRoot, companyId: string) {
		const fileDoc = new CompanyTemplate();
		fileDoc.id = uuid();
		fileDoc.name = folder.name;
		fileDoc.companyId = companyId;
		fileDoc.folderId = currentRoot ? currentRoot.id : null;
		fileDoc.type = COMPANY_FILE_TYPE.FOLDER;
		fileDoc.creationTimestamp = Math.floor(new Date().getTime() / 1000);

		return this.createFolder(fileDoc);
	}

	getCompanyTemplates(companyId: string) {
		this.loading = true;
		return this.afStore
			.collection(COLLECTION_COMPANY_FILES, (ref) => ref.where('companyId', EQUALS, companyId))
			.valueChanges()
			.pipe(
				map((json) => {
					const companyTemplates: CompanyTemplate[] = [];
					for (const templateDoc of json) {
						const companyTemplate = new CompanyTemplate().deserialize(templateDoc);
						companyTemplates.push(companyTemplate);
					}

					return companyTemplates;
				}),
				tap(() => {
					this.loading = false;
				}),
			);
	}

	getCompanyTemplateById(_companyId: string, fileId: string): Observable<CompanyTemplate> {
		return this.afStore
			.collection(COLLECTION_COMPANY_FILES)
			.doc(fileId)
			.valueChanges()
			.pipe(
				map((templateDoc) => {
					return new CompanyTemplate().deserialize(templateDoc);
				}),
			);
	}

	async uploadFilesToStorage(
		files: File[],
		currentRoot: CompanyTemplate,
		company: Company,
	): Promise<void> {
		this.loading = true;
		await Promise.all(
			Array.from(files).map((file) => this.uploadFileToStorage(file, currentRoot, company)),
		);
		this.loading = false;
	}

	private async uploadFileToStorage(
		file: File,
		currentRoot: CompanyTemplate,
		company: Company,
	): Promise<void> {
		const id = uuid();
		const params: CompanyTemplateUploadParameters = {
			id: uuid(),
			fileName: id,
			file,
			context: FileContext.COMPANY_TEMPLATES,
			companyId: company.id,
		};
		const fileType = this.uploadService.getFileType(file.name);
		await this.uploadService.uploadFile(params);
		await this.addFileToCollection(id, file, company.id, currentRoot, fileType);
	}

	removeFile(file: CompanyTemplate) {
		return this.afStore.collection(COLLECTION_COMPANY_FILES).doc(file.id).delete();
	}

	async getFile(
		companyId: any,
		file: CompanyTemplate | FileDocument | PublicFile | CompanyFile | FileEntity,
		thumbnail: boolean = true,
	): Promise<string> {
		const fileExt = this.baseFile.getFileExtOrName(file, true);
		const storage = this.storage.storage.ref();
		const extension = thumbnail ? JPG : fileExt;
		const content = thumbnail
			? COLLECTION_COMPANY_FILES + companyId + '/' + file.id + THUMB + '.' + extension
			: COLLECTION_COMPANY_FILES + companyId + '/' + file.id + '.' + extension;

		return storage
			.child(content)
			.getDownloadURL()
			.then((url) => {
				return url;
			})
			.catch(() => {
				return null;
			});
	}

	deleteFolder(template: CompanyTemplate, allFiles: CompanyTemplate[]) {
		const count = this.countItemsInFolder(template, allFiles);

		if (+count > 0) {
			const dialogDelete = this.dialog.open(ApproveDialogComponent, { data: { isFile: false } });
			dialogDelete
				.afterClosed()
				.pipe(take(1))
				.subscribe((result) => {
					if (result) {
						this.deleteAllInsideFolder(template, allFiles);
						this.removeFile(template);
					}
				});
		} else {
			this.removeFile(template);
		}
	}

	private createFolder(file: CompanyTemplate) {
		this.loading = true;
		return this.afStore
			.collection(COLLECTION_COMPANY_FILES)
			.doc(file.id)
			.set(JSON.parse(JSON.stringify(file)))
			.then(() => (this.loading = false));
	}

	private addFileToCollection(
		id,
		file: File,
		companyId: string,
		currentRoot: CompanyTemplate,
		fileType,
	) {
		const fileDoc = new CompanyTemplate();
		fileDoc.id = id;
		fileDoc.name = this.baseFile.getFilenameWithLowercaseExtension(file.name);
		fileDoc.companyId = companyId;
		fileDoc.size = file.size;
		fileDoc.folderId = currentRoot ? currentRoot.id : null;
		fileDoc.type = fileType;
		fileDoc.creationTimestamp = Math.floor(new Date().getTime() / 1000);

		return this.afStore
			.collection(COLLECTION_COMPANY_FILES)
			.doc(id)
			.set(JSON.parse(JSON.stringify(fileDoc)))
			.then(() => (this.loading = false));
	}

	private deleteAllInsideFolder(file: CompanyTemplate, allFiles: CompanyTemplate[]) {
		for (const f of allFiles) {
			if (file.id === f.folderId) {
				this.removeFile(f);
			}
		}
	}
}
