import { createAction, props } from '@ngrx/store';
import { Export } from 'domain-entities';

export const exportEffectsInitActions = createAction('[Export] Export Effect Init');
export const closeExportModalAction = createAction('[Export] Close Export Modal');
export const exportStartedAction = createAction(
	'[Export] Export Started',
	props<{ export: Export }>(),
);

export const addExports = createAction('[Export] Add Exports', props<{ exports: Export[] }>());
export const deleteExports = createAction('[Export] Delete Exports', props<{ ids: string[] }>());
export const clearExports = createAction('[Export] Clear Exports');
