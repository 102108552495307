import { Injectable } from '@angular/core';
import { isProfileTracker, ProfileTracker } from 'domain-entities';
import { FirestoreConnector } from '@craftnote/shared-injectables';
import { EntityChanges } from '@craftnote/shared-utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProfileTrackerConnector {
	private static COLLECTION_NAME_PROFILE = 'profiles';
	private static COLLECTION_NAME_TRACKER = 'trackers';

	constructor(private readonly connector: FirestoreConnector) {}

	createTracker(profileId: string, tracker: ProfileTracker): Promise<void> {
		const path = `${ProfileTrackerConnector.COLLECTION_NAME_PROFILE}/${profileId}/${ProfileTrackerConnector.COLLECTION_NAME_TRACKER}`;
		return this.connector.create(path, tracker.type, tracker, isProfileTracker);
	}

	watchTrackers(profileId: string): Observable<EntityChanges<ProfileTracker>> {
		const path = `${ProfileTrackerConnector.COLLECTION_NAME_PROFILE}/${profileId}/${ProfileTrackerConnector.COLLECTION_NAME_TRACKER}`;
		return this.connector.watchDocumentsChanges<ProfileTracker>(path, []);
	}
}
