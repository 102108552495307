import { Inject, Injectable } from '@angular/core';
import { openDB } from 'idb';
import moment from 'moment';
import { WINDOW } from '@craftnote/shared-utils';
import { TranslateService } from '@ngx-translate/core';
import { BrowserNotificationService, NotificationPermission } from './browser-notification.service';

const databaseName = 'CrafnoteTestDB';
const objectStoreName = 'CrafnoteTestDB_Store';

export enum BrowserLanguage {
	EN = 'en',
	DE = 'de',
}

@Injectable({
	providedIn: 'root',
})
export class BrowserService {
	constructor(
		@Inject(WINDOW) private windowRef: Window,
		private readonly translateService: TranslateService,
		private readonly browserNotificationService: BrowserNotificationService,
	) {}

	async canMutateIndexDb(): Promise<boolean> {
		try {
			const database = await openDB(databaseName, 1, {
				upgrade: (db) => {
					db.createObjectStore(objectStoreName);
				},
			});
			await database
				.transaction(objectStoreName, 'readwrite')
				.objectStore(objectStoreName)
				.put('foo', moment().unix());
			return true;
		} catch (error) {
			return false;
		}
	}

	isPermissionAPISupported(): boolean {
		return !!this.windowRef?.navigator?.permissions?.query;
	}

	async isPushNotificationsEnabled(): Promise<boolean> {
		return this.browserNotificationService.isNotificationPermission(NotificationPermission.GRANTED);
	}

	async isServiceWorkersEnabled(): Promise<boolean> {
		try {
			await this.windowRef.navigator.serviceWorker.getRegistrations();
			return true;
		} catch (e) {
			return false;
		}
	}

	getBrowserLanguage(): BrowserLanguage {
		/**
		 * At this moment we support only two languages and fallback language is always english
		 */
		return this.translateService.getBrowserLang().includes('de')
			? BrowserLanguage.DE
			: BrowserLanguage.EN;
	}
}
