import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCenterComponent } from '@modules/shared/components/notification-center/notification-center.component';
import { NotificationItemComponent } from '@modules/shared/components/notification-center/notification-item/notification-item.component';
import { MatIconSizeModule } from '@modules/shared/directives/mat-icon-size.module';
import { UnixToMillisPipeModule } from '@modules/shared/pipes/unix-to-millis/unix-to-millis-pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import {
	FromNowPipe,
	RenderOnVisibleModule,
	SharedModule,
} from '@craftnote/shared-angular-modules';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

@NgModule({
	declarations: [NotificationCenterComponent, NotificationItemComponent],
	imports: [
		CommonModule,
		MatIconSizeModule,
		RenderOnVisibleModule,
		UnixToMillisPipeModule,
		FromNowPipe,
		TranslateModule,
		MatSidenavModule,
		MatIconModule,
		MatLegacyProgressSpinnerModule,
		MatLegacyButtonModule,
		RouterModule,
		MatLegacyMenuModule,
		SharedModule,
	],
	exports: [NotificationCenterComponent],
})
export class NotificationCenterModule {}
