import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectArchivationDialogComponent } from '@modules/shared/components/projects-area/components/project-archivation-dialog/project-archivation-dialog.component';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { addDays, getUnixTime } from 'date-fns';
import { ProjectListStore } from '@modules/shared/components/projects-area/components/projects-list/store/project-list.store';

const DAYS_MUTED_AFTER_ACTION = 30;

@Component({
	selector: 'app-project-archivation-nudge',
	templateUrl: './project-archivation-nudge.component.html',
	styleUrls: ['./project-archivation-nudge.component.scss'],
})
export class ProjectArchivationNudgeComponent {
	private readonly store = inject(ProjectListStore);
	private readonly dialog = inject(MatDialog);
	private readonly localStorageService = inject(LocalStorageService);

	relevantProjects$ = this.store.archivableProjects$;

	openDialog(): void {
		const dialog = this.dialog.open(ProjectArchivationDialogComponent, {
			width: 'min(80%, 1300px)',
			height: 'min(90vh, 800px)',
			disableClose: true,
			data: {
				projects$: this.relevantProjects$,
			},
		});

		dialog.afterClosed().subscribe((close: string) => {
			if (!close) {
				return;
			}
			const newNudgeMuteTime = getUnixTime(addDays(new Date(), DAYS_MUTED_AFTER_ACTION));
			this.localStorageService.set('projectArchivationNudge', newNudgeMuteTime);
			this.store.setNudgeMuteTime(newNudgeMuteTime);
		});
	}

	onClose(): void {
		this.store.setNudgeMuteTime(null);
	}
}
