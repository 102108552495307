import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsState } from '@store/state/products.state';
import { productsFeatureKey } from '@store/reducers/products.reducer';
import { ProductType } from 'domain-entities';

export const selectProducts = createFeatureSelector<ProductsState>(productsFeatureKey);

export const selectProductsByType = createSelector(
	selectProducts,
	(products, props: { type: ProductType }) =>
		products.filter((product) => product.type === props.type),
);

export const selectProductsTypeSubscription = createSelector(selectProducts, (products) =>
	products.filter((product) => product.type === ProductType.SUBSCRIPTION),
);

export const selectProductsTypeStorageUpgrade = createSelector(selectProducts, (products) =>
	products.filter((product) => product.type === ProductType.STORAGE_UPGRADE),
);

export const selectProductsTypeSubscriptionAndTrial = createSelector(selectProducts, (products) =>
	products.filter(
		(product) => product.type === ProductType.SUBSCRIPTION || product.type === ProductType.TRIAL,
	),
);
