<section class="d-flex w-100 chat-search-section">
	<form
		[formGroup]="chatSearchForm"
		class="flex-grow-1 d-flex align-items-center chat-search-form"
		(ngSubmit)="onSubmitChatSearchForm(chatSearchForm.value)"
	>
		<mat-form-field appearance="fill" class="flex-grow-1">
			<input matInput formControlName="text" data-cy="chat-search-box" [placeholder]="'chat.search.label' | translate" autocomplete="off" />
			<div matSuffix class="d-flex">
				<button mat-icon-button *ngIf="chatSearchText?.length > 0" (click)="onResetChatSearchForm()" type="reset">
					<mat-icon>clear</mat-icon>
				</button>
				<button mat-icon-button type="submit" [disabled]="chatSearchForm.invalid">
					<mat-icon data-cy="chat-search-icon">search</mat-icon>
				</button>
			</div>
		</mat-form-field>
	</form>

	<a
		href=""
		class="chat-filter-toggler mx-2 d-flex align-items-center invert-color"
		[class.active]="chatFilterEnabled"
		(click)="toggleFilter(chatFilterEnabled, $event)"
	>
		<mat-icon data-cy="chat-filter-options">filter_list</mat-icon>
	</a>
</section>

<ng-template #chatFilterMenu>
	<div class="chat-filter-menu" [class.d-none]="!chatFilterEnabled">
		<div class="chat-filter-menu__container mat-elevation-z4 p-3">
			<form [formGroup]="chatFilterForm" (ngSubmit)="onSubmitChatFilterForm(chatFilterForm.value)">
				<div class="row">
					<div class="col-md-6 col-lg-3">
						<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline" color="accent">
							<mat-label>{{ 'project.search.from' | translate }}</mat-label>
							<input
								matInput
								[matDatepicker]="chatFilterFrom"
								formControlName="from"
								[max]="getTodayDate()"
								[min]="getStartOfDay(project.creationDate * 1000)"
								readonly
								(click)="chatFilterFrom.open()"
							/>
							<mat-datepicker-toggle matSuffix [for]="chatFilterFrom">
								<mat-icon matDatepickerToggleIcon color="accent" data-cy="chat-filter-from">today</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #chatFilterFrom></mat-datepicker>
						</mat-form-field>
					</div>

					<div class="col-md-6 col-lg-3">
						<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline" color="accent">
							<mat-label>{{ 'project.search.to' | translate }}</mat-label>
							<input
								matInput
								[matDatepicker]="chatFilterTo"
								formControlName="to"
								[max]="getTodayDate()"
								[min]="getStartOfDay(chatFilterForm.value.from)"
								readonly
								(click)="chatFilterTo.open()"
							/>
							<mat-datepicker-toggle matSuffix [for]="chatFilterTo">
								<mat-icon matDatepickerToggleIcon color="accent" data-cy="chat-filter-to">today</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #chatFilterTo></mat-datepicker>
						</mat-form-field>
					</div>

					<div class="col-md-6 col-lg-3">
						<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline">
							<mat-label>{{ 'project.search.type' | translate }}</mat-label>
							<mat-select multiple formControlName="type">
								<mat-option value="TEXT" data-cy="chat-filter-text">{{ 'project.search.text' | translate }}</mat-option>
								<mat-option value="AUDIO" data-cy="chat-filter-audio">{{ 'project.search.audio' | translate }}</mat-option>
								<mat-option value="VIDEO" data-cy="chat-filter-video">{{ 'project.search.video' | translate }}</mat-option>
								<mat-option value="IMAGE" data-cy="chat-filter-image">{{ 'project.search.image' | translate }}</mat-option>
								<mat-option value="DOCUMENT" data-cy="chat-filter-document">{{ 'project.search.document' | translate }}</mat-option>
							</mat-select>
							<mat-icon matSuffix color="accent" data-cy="chat-msgtype-filter">keyboard_arrow_down</mat-icon>
						</mat-form-field>
					</div>

					<div class="col-md-6 col-lg-3">
						<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline">
							<mat-label>{{ 'project.search.member' | translate }}</mat-label>
							<mat-select formControlName="member">
								<mat-option [value]="null">{{ 'project.search.noMember' | translate }}</mat-option>
								<mat-option *ngFor="let member of project | projectMembers: membersInviteState.ACCEPTED" [value]="member.id"
									>{{ member.name ? member.name + ' ' + member.lastname : member.email }}
								</mat-option>
							</mat-select>
							<mat-icon matSuffix color="accent" data-cy="chat-member-filter">keyboard_arrow_down</mat-icon>
						</mat-form-field>
					</div>
				</div>

				<div class="row">
					<div class="col text-center">
						<button
							type="submit"
							class="px-5"
							mat-flat-button
							color="accent"
							[disabled]="chatFilterForm.invalid"
							data-cy="chat-apply-filter"
						>
							{{ 'project.search.button' | translate }}
						</button>
						<button class="ml-2 px-5" (click)="toggleFilter(chatFilterEnabled, $event)" mat-stroked-button data-cy="chat-close-filter">
							{{ 'button.close' | translate }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>
