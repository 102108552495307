import { Component, Inject } from '@angular/core';
import { Member } from 'domain-entities';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
	AddEditCompanyEmployeeContext,
	CompanyMemberOperation,
} from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.component';

export interface CompanyMemberDialogData {
	member?: Member;
	operation: CompanyMemberOperation;
	context: AddEditCompanyEmployeeContext;
}

@Component({
	selector: 'app-company-employee-dialog',
	templateUrl: './company-employee-dialog.component.html',
	styleUrls: ['./company-employee-dialog.component.scss'],
})
export class CompanyEmployeeDialogComponent {
	CompanyMemberOperation = CompanyMemberOperation;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: CompanyMemberDialogData,
		private readonly dialogRef: MatDialogRef<CompanyEmployeeDialogComponent>,
	) {}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
