import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { SubscriptionWizardComponent } from './components/subscription-wizard/subscription-wizard.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { SubscriptionWizardBillingComponent } from './components/subscription-wizard/billing/subscription-wizard-billing/subscription-wizard-billing.component';
import { SubscriptionTextInputDirective } from './directives/subscription-text-input.directive';
import { TocConfirmationDialogComponent } from './components/toc-confirmation-dialog/toc-confirmation-dialog.component';
import { TocComponent } from './components/toc/toc.component';
import { UserDeactivatedDialogComponent } from './components/user-deactivated-dialog/user-deactivated-dialog.component';
import { TocDeclinedDialogComponent } from './components/toc-declined-dialog/toc-declined-dialog.component';
import { ConfirmButtonModule } from '../components/confirm-button/confirm-button.module';
import { WizardStepperModule } from '../components/wizard-stepper/wizard-stepper.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { LineModule } from '../components/line/line.module';
import { LineLeftModule } from '../components/line-left/line-left.module';
import { LineRightModule } from '../components/line-right/line-right.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ManageCompanyEmployeesModule } from '../../features/subscription/products/manage-company-employess/manage-company-employees.module';
import { UnsavedCompanySettingsDialogComponent } from '@modules/shared/dialog/components/unsaved-company-settings-dialog/unsaved-company-settings-dialog.component';
import { CompanyEmployeeDialogComponent } from '@modules/shared/dialog/components/company-employee-dialog/company-employee-dialog.component';
import { AddEditCompanyEmployeeModule } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.module';
import { PaywallHeaderDialogModule } from '@modules/shared/components/paywall-header-dialog/paywall-header-dialog.module';

@NgModule({
	declarations: [
		SubscriptionWizardComponent,
		SubscriptionWizardBillingComponent,
		SubscriptionTextInputDirective,
		TocComponent,
		TocConfirmationDialogComponent,
		UserDeactivatedDialogComponent,
		TocDeclinedDialogComponent,
		UnsavedCompanySettingsDialogComponent,
		CompanyEmployeeDialogComponent,
	],
	imports: [
		SharedModule,
		CdkStepperModule,
		ConfirmButtonModule,
		WizardStepperModule,
		MatButtonToggleModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		LineModule,
		LineLeftModule,
		LineRightModule,
		MatIconModule,
		MatFormFieldModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatDialogModule,
		MatButtonModule,
		ManageCompanyEmployeesModule,
		AddEditCompanyEmployeeModule,
		PaywallHeaderDialogModule,
	],
})
export class DialogModule {}
