import { Serializable } from '@craftnote/shared-utils';
import { ROLE } from './role.enum';
import { INVITE_STATE } from './invite-state.enum';
import { Contact } from './contact.model';
import { Member } from 'domain-entities';

export class Company implements Serializable<Company> {
	constructor(
		public id?: string,
		public name?: string,
		public phone?: string,
		public email?: string,
		public street?: string,
		public zipcode?: string,
		public city?: string,
		public country?: string,
		public members: any = [],
		public premiumExpirationDate?: number,
		public premiumState?: PremiumState,
		public storageBytes?: number,
		public memberAmount?: number,
		public tags?: {
			[key: string]: number;
		},
		public companyProject?: string,
		public trade?: string,
	) {}

	deserialize(input: any): Company {
		this.id = input.id;
		this.name = input.name;
		this.phone = input.phone;
		this.email = input.email;
		this.street = input.street;
		this.zipcode = input.zipcode;
		this.city = input.city;
		this.country = input.country;
		this.tags = input.tags;
		for (const key of Object.keys(input.members)) {
			this.members.push(new Contact().deserialize(input.members[key]));
		}
		this.premiumExpirationDate = input.premiumExpirationDate;
		this.premiumState = input.premiumState;
		this.storageBytes = input.storageBytes;
		this.memberAmount = input.memberAmount;
		this.companyProject = input.companyProject;
		this.trade = input.trade;
		return this;
	}

	isOwner(email: string) {
		const tmpMembers = this.members.filter((member) => member.email === email);
		return tmpMembers.length > 0 ? tmpMembers[0].role === ROLE.OWNER : false;
	}

	isOwnerOrSupervisor(email: string) {
		const tmpMembers = this.members.filter((member) => member.email === email);
		return tmpMembers.length > 0
			? tmpMembers[0].role === ROLE.OWNER || tmpMembers[0].role === ROLE.SUPERVISOR
			: false;
	}

	getOwnerCount() {
		return this.members.filter(
			(member) => member.role === ROLE.OWNER && member.inviteState === INVITE_STATE.ACCEPTED,
		).length;
	}

	getCompanyMember(email: string) {
		return this.members.filter((member) => member.email === email)[0];
	}

	hasEmail(email: string) {
		return this.members.filter((member) => member.email === email).length !== 0;
	}

	isInvited(email: string) {
		const members = this.members.filter((member) => member.email === email);

		if (members.length === 0) {
			return false;
		}

		return members[0].inviteState === INVITE_STATE.INVITED;
	}

	getMember(email: string) {
		const members = this.members.filter((member) => member.email === email);

		if (members.length === 0) {
			return null;
		}

		return members[0];
	}

	isEmployee(userId: String): boolean {
		return this.checkRole(userId, ROLE.EMPLOYEE);
	}

	checkRole(userId: String, role: ROLE): boolean {
		for (const member in this.members) {
			if (this.members[member].id === userId) {
				return this.members[member].role === role;
			}
		}

		return false;
	}

	getMembers(): { [emails: string]: Member } {
		const members = {};
		(this.members as Contact[]).forEach(
			(contact) => (members[contact.email] = contact.toDomainEntityMember()),
		);
		return members;
	}

	canStartTrial(email: string): boolean {
		return (
			this.premiumState === PremiumState.FREE && !this.premiumExpirationDate && this.isOwner(email)
		);
	}
}

export enum PremiumState {
	FREE = 'FREE',
	TRIAL = 'TRIAL',
	PREMIUM = 'PREMIUM',
}
