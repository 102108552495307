import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Notification } from '@injectables/connectors/notifications.connector';

@Component({
	selector: 'app-notification-item',
	templateUrl: './notification-item.component.html',
	styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements AfterViewInit, OnDestroy {
	@Input() data: Notification;
	@ViewChild('sidenav') sidenav: MatSidenav;
	observer: IntersectionObserver;
	@Input() appRenderOnVisible: HTMLUListElement; // Root element to observe
	@Output() onNotificationRead = new EventEmitter<Notification>();

	constructor(private readonly elementRef: ElementRef) {}

	ngAfterViewInit(): void {
		const observedElement = this.elementRef.nativeElement;
		this.observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					this.onNotificationRead.emit(this.data);
					this.observer.disconnect();
				}
			},
			{ root: this.appRenderOnVisible },
		);
		this.observer.observe(observedElement);
	}

	ngOnDestroy(): void {
		this.observer.disconnect();
	}
}
