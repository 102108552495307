import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setInvitationLink } from '@store/actions/invitations.actions';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { ProfileTrackerService } from '@injectables/profile-tracker/profile-tracker.service';

@Component({
	selector: 'app-invitations',
	template: '',
	styles: [],
})
export class InvitationsComponent {
	constructor(
		private route: ActivatedRoute,
		private readonly store: Store<AppState>,
		private readonly router: Router,
		private readonly trackerService: ProfileTrackerService,
	) {
		this.storeAndRedirect();
	}

	async storeAndRedirect(): Promise<void> {
		const invitationId = this.route.snapshot.params['invitationId'];
		const { companyName, expirationDate } = {
			companyName: this.route.snapshot.queryParams['companyName'],
			expirationDate: this.route.snapshot.queryParams['expirationDate'],
		};
		if (invitationId && companyName && expirationDate) {
			await this.trackerService.setInvitationTrackerToLocalStorage();
			this.store.dispatch(
				setInvitationLink({ invitationLink: { invitationId, companyName, expirationDate } }),
			);
		}
		await this.router.navigate(['/register']);
	}
}
