import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { FirefoxChangeSettingsComponent } from './components/firefox-change-settings/firefox-change-settings.component';
import { MatIconModule } from '@angular/material/icon';

const DECLARATIONS = [FirefoxChangeSettingsComponent];

@NgModule({
	declarations: [...DECLARATIONS],
	imports: [SharedModule, MatIconModule],
	exports: [...DECLARATIONS],
})
export class BrowserCompatibilityModule {}
