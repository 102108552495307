import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { TrackedTimeChange } from '@injectables/connectors/audit-log.connector';
import { TrackedTimeAuditLogService } from '@injectables/services/time-tracking/tracked-time-audit-log.service';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { first, isNil, last, orderBy, takeRight } from 'lodash';

interface TrackedTimeChangesState {
	currentTrackedTime: string;
	changes: TrackedTimeChange[];
}

const defaultState: TrackedTimeChangesState = {
	currentTrackedTime: null,
	changes: [],
};

@Injectable()
export class TrackedTimesChangesStore extends ComponentStore<TrackedTimeChangesState> {
	constructor(private readonly trackedTimeAuditLogService: TrackedTimeAuditLogService) {
		super(defaultState);
	}

	allChanges$ = this.select((state) => orderBy(state.changes, (change) => change.changedAt));

	nonCreationChanges$ = this.allChanges$.pipe(
		map((sortedChanges) => takeRight(sortedChanges, sortedChanges.length - 1)),
	);

	creatorName$ = this.allChanges$.pipe(
		map((sortedChanges) => {
			return first(sortedChanges)?.changedBy;
		}),
	);

	lastChange$ = this.nonCreationChanges$.pipe(map((sortedChanges) => last(sortedChanges)));

	getChanges = this.effect((trackedTimeId$: Observable<string>) => {
		return trackedTimeId$.pipe(
			tap(() => this.setState(defaultState)),
			switchMap((id) =>
				interval(2000).pipe(
					startWith(true),
					switchMap(() =>
						isNil(id)
							? of(null)
							: this.trackedTimeAuditLogService
									.getAuditLogForTrackedTime(id)
									.pipe(map((changes) => ({ trackedTimeId: id, changes }))),
					),
					take(5),
				),
			),
			map((changes) => {
				if (isNil(changes)) {
					return;
				}
				this.setState({ currentTrackedTime: changes.trackedTimeId, changes: changes.changes });
			}),
		);
	});
}
