import { RenderOnVisibleDirective } from './render-on-visible.directive';
import { NgModule } from '@angular/core';

const DIRECTIVE = [RenderOnVisibleDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class RenderOnVisibleModule {}
