export enum ProjectType {
	FOLDER = 'FOLDER',
	PROJECT = 'PROJECT',
}

export enum PROJECT_EVENTS {
	ARCHIVE = 'archive',
	PROJECT = 'project',
	CREATE_RPOJECT = 'createproject',
	CREATE_FOLDER = 'createfolder',
	DASHBOARD = 'dashboard',
	TASKS = 'dashboard/tasks',
	WORK_TIMES = 'dashboard/worktimes',
}

export enum ProjectColorTags {
	COLOR1 = 'tag-red-border',
	COLOR2 = 'tag-orange-border',
	COLOR3 = 'tag-yellow-border',
	COLOR4 = 'tag-green-border',
	COLOR5 = 'tag-purple-border',
	COLOR6 = 'tag-blue-border',
	COLOR7 = 'tag-teal-border',
	COLOR8 = 'tag-pink-border',
	COLOR9 = 'tag-dirty-green-border',
	COLOR10 = 'tag-royal-blue-border',
}

export enum ProjectContext {
	ACTIVE = 'active',
	ARCHIVE = 'archive',
}

export enum ProjectItemActions {
	STATUS_UPDATE = 'StatusUpdate',
	MOVE_PROJECT = 'MoveProject',
	ARCHIVE_ROJECT = 'ArchiveProject',
	DUPLICATE_FOLDER = 'DuplicateFolder',
	RESTORE_PROJECT = 'RestoreProject',
	MUTE_UNMUTE_CHAT = 'MuteUnmuteChat',
	MARK_PROJECT_AS_READ = 'MarkProjectAsRead',
	MARK_PROJECT_AS_UNREAD = 'MarkProjectAsUnread',
}

export type ProjectItemAction =
	| 'StatusUpdate'
	| 'MoveProject'
	| 'ArchiveProject'
	| 'DuplicateFolder'
	| 'RestoreProject'
	| 'MuteUnmuteChat'
	| 'MarkProjectAsRead'
	| 'MarkProjectAsUnread';

export interface ProjectStatusInformation {
	statusName: string;
	statusIndex: number;
	totalCompanyStatuses: number;
}

export const NO_PROJECT_STATUS = 'NO_STATUS';
