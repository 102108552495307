import { createFeatureSelector, createSelector } from '@ngrx/store';
import { profileTrackerSelectors } from '../reducers/profile-tracker.reducer';
import { ProfileTrackerState } from '../state/profile-tracker.state';

const profileTrackersSelector = createFeatureSelector<ProfileTrackerState>('profileTrackers');

export const selectAllProfileTrackers = createSelector(profileTrackersSelector, (state) => {
	return profileTrackerSelectors.selectAll(state);
});

export const selectProfileTrackerLoadingStatus = createSelector(
	profileTrackersSelector,
	(state) => {
		return state.loadingStatus;
	},
);

export const selectProfileTrackerByType = (type: string) => {
	return createSelector(profileTrackersSelector, (state) => {
		return Object.values(state.entities).find((tracker) => tracker.type === type);
	});
};
