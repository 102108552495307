import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { TaskTime } from 'domain-entities';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { selectUserId } from '@store/selectors/app.selectors';
import { first, takeUntil } from 'rxjs/operators';
import { TaskEditWorkingHoursUpdateService } from './task-edit-working-hours-update.service';
import { Subject } from 'rxjs';

interface WorkingHoursForm {
	startDate: Date;
	startTime: Date;
	endDate: Date;
	endTime: Date;
}

@Component({
	selector: 'app-task-edit-working-hours-update',
	templateUrl: './task-edit-working-hours-update.component.html',
	styleUrls: ['./task-edit-working-hours-update.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditWorkingHoursUpdateComponent implements OnInit, OnDestroy {
	@Output() openWorkingHours = new EventEmitter();
	workingHourId: string = null;
	taskId: string = null;
	destroy$: Subject<boolean> = new Subject();

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly store: Store,
		private readonly taskEditWorkingHoursUpdateService: TaskEditWorkingHoursUpdateService,
	) {}

	updateWorkingHoursForm: UntypedFormGroup = this.formBuilder.group({
		startDate: [[Validators.required]],
		startTime: [[Validators.required]],
		endDate: [[Validators.required]],
		endTime: [[Validators.required]],
	});

	ngOnInit(): void {
		this.updateWorkingHoursForm.setValue(this.addWorkingHoursFormInitialValue);
		this.updateWorkingHoursForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateWorkingHoursForm.patchValue(
				{
					startDate: this.startDateTime,
					startTime: this.startDateTime,
					endDate: this.endDateTime,
					endTime: this.endDateTime,
				},
				{ emitEvent: false },
			);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	async onDeleteWorkingHours(id: string): Promise<void> {
		await this.taskEditWorkingHoursUpdateService.removeTaskTime(id);
		this.updateWorkingHoursForm.reset(this.addWorkingHoursFormInitialValue);
		this.openWorkingHours.emit();
	}

	async updateWorkingHours(): Promise<void> {
		const userId = await this.store.select(selectUserId).pipe(first()).toPromise();
		const taskTime: TaskTime = {
			id: this.workingHourId,
			taskId: this.taskId,
			startTime: moment(this.startDateTime).unix(),
			endTime: moment(this.endDateTime).unix(),
			userId,
		};
		await this.taskEditWorkingHoursUpdateService.updateTaskTime(taskTime);
		this.updateWorkingHoursForm.reset(this.addWorkingHoursFormInitialValue);
		this.openWorkingHours.emit();
	}

	get updateWorkingHoursFormInvalid(): boolean {
		return (
			this.updateWorkingHoursForm.invalid || moment(this.startDateTime).isAfter(this.endDateTime)
		);
	}

	get startDateTime(): Date {
		const workingHoursFormValue = this.updateWorkingHoursForm.value;
		const startDate: Moment = moment(workingHoursFormValue.startDate || new Date());
		const startTime: Date = moment(workingHoursFormValue.startTime || new Date()).toDate();
		const startDateTime = startDate
			.set({
				hour: startTime.getHours(),
				minute: startTime.getMinutes(),
				second: 0,
			})
			.toDate();
		return startDateTime;
	}

	get endDateTime(): Date {
		const workingHoursFormValue = this.updateWorkingHoursForm.value;
		const endDate: Moment = moment(workingHoursFormValue.endDate || new Date());
		const endTime: Date = moment(workingHoursFormValue.endTime || new Date()).toDate();
		const endDateTime = endDate
			.set({
				hour: endTime.getHours(),
				minute: endTime.getMinutes(),
				second: 0,
			})
			.toDate();
		return endDateTime;
	}

	get addWorkingHoursFormInitialValue(): WorkingHoursForm {
		return {
			startDate: moment().toDate(),
			startTime: moment('09', 'HH').toDate(),
			endDate: moment().toDate(),
			endTime: moment('18', 'HH').toDate(),
		};
	}

	openTaskEditWorkingHoursUpdate(newWorkingHour: TaskTime): void {
		this.updateWorkingHoursForm.reset({
			startDate: moment.unix(newWorkingHour.startTime).toDate(),
			startTime: moment.unix(newWorkingHour.startTime).toDate(),
			endDate: moment.unix(newWorkingHour.endTime).toDate(),
			endTime: moment.unix(newWorkingHour.endTime).toDate(),
		});
		this.workingHourId = newWorkingHour.id;
		this.taskId = newWorkingHour.taskId;
	}
}
