import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { setUserRegistrationType } from '../actions/auth.actions';
import { catchError, filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { selectCompanyId, selectIsLoggedIn } from '../selectors/app.selectors';
import { combineLatest, of } from 'rxjs';
import {
	initInvitationLink,
	invitationsChanged,
	invitationsSubscriptionError,
	setInvitationLink,
	subscribeToInvitationsChanges,
} from '../actions/invitations.actions';
import { UserRegistrationType } from '../state/auth.state';

@Injectable()
export class InvitationsEffects implements OnInitEffects {
	initInvitationsLink$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(initInvitationLink),
				tap(() => {
					if (!this.activatedRoute.snapshot.params['invitationId']) {
						this.store.dispatch(setInvitationLink({ invitationLink: null }));
					}
				}),
			),
		{ dispatch: false },
	);

	invitationsSubscription$ = createEffect(() =>
		this.actions$.pipe(
			ofType(subscribeToInvitationsChanges),
			switchMapTo(this.store.select(selectCompanyId)),
			switchMap((companyId) => this.invitationService.watchChanges(companyId)),
			map((changes) => invitationsChanged({ payload: changes })),
			catchError(() => of(invitationsSubscriptionError())),
		),
	);

	onNewUserRegistration$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setUserRegistrationType),
				filter(({ registrationType }) => registrationType === UserRegistrationType.FRESH),
				tap(async () => {
					if (!this.remoteConfig.getValue('feature_company_invitation')) {
						return;
					}

					if (!!this.remoteConfig.getValue('feature_splash_screen')) {
						return;
					}

					await combineLatest([
						this.store.select(selectIsLoggedIn),
						this.store.select(selectCompanyId), // Intentionally waiting until the company gets created, TODO: Have to be removed after implementation of splash screen
					])
						.pipe(
							filter((statuses) => statuses.every(Boolean)),
							first(),
						)
						.toPromise();

					await this.invitationService.acceptAndGetNewCompanyId();
				}),
			),
		{ dispatch: false },
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly invitationService: InvitationService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly remoteConfig: RemoteConfig,
	) {}

	ngrxOnInitEffects(): Action {
		return initInvitationLink();
	}
}
