import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-project-action-dialog',
	templateUrl: './project-action-dialog.component.html',
	styleUrls: ['./project-action-dialog.component.scss'],
})
export class ProjectActionDialogComponent implements OnInit {
	title: string;
	content: string;
	isInfoDialog: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private translateService: TranslateService,
	) {}

	ngOnInit(): void {
		this.isInfoDialog = this.data.isInfoDialog;

		this.title = this.translateService.instant(this.data.title);
		this.content = this.translateService.instant(
			this.data.content,
			this.data.name ? { name: this.data.name } : undefined,
		);
	}
}
