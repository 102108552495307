import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { ManageAccessDialogComponent } from './components/manage-access-dialog/manage-access-dialog.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';
import { DndDirective } from '../directives/dnd.directive';
import { NewFolderDialogComponent } from '../file-explorer/modals/new-folder-dialog/new-folder-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
	declarations: [
		ManageAccessDialogComponent,
		RenameDialogComponent,
		NewFolderDialogComponent,
		DndDirective,
	],
	imports: [
		SharedModule,
		MatSlideToggleModule,
		DragDropModule,
		ReactiveFormsModule,
		MatDividerModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		FormsModule,
		NgxTrimDirectiveModule,
		MatInputModule,
	],
	exports: [
		ManageAccessDialogComponent,
		RenameDialogComponent,
		NewFolderDialogComponent,
		DndDirective,
	],
})
export class SharedFilesSectionModule {}
