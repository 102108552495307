import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { SettingsPageHeaderComponent } from './settings-page-header.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [SettingsPageHeaderComponent],
	imports: [SharedModule, MatIconModule, RouterModule],
	exports: [SettingsPageHeaderComponent],
})
export class SettingsPageHeaderModule {}
