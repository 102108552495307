import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { BehaviorSubject } from 'rxjs';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ScriptLoaderService {
	private readonly loadingStatuses$ = new BehaviorSubject<{
		[identifier: string]: StoreLoadingStatus;
	}>({});

	constructor(
		@Inject(DOCUMENT) private readonly document,
		private readonly errorHandler: ErrorHandlerService,
	) {}

	isScriptLoaded(identifier: string): boolean {
		const status = this.loadingStatuses$.getValue();
		return status[identifier] === StoreLoadingStatus.LOADED;
	}

	isScriptLoading(identifier: string): boolean {
		const status = this.loadingStatuses$.getValue();
		return status[identifier] === StoreLoadingStatus.LOADING;
	}

	async loadScript(identifier: string, fromUrl: string): Promise<void> {
		if (this.isScriptLoaded(identifier)) {
			return;
		}

		if (this.isScriptLoading(identifier)) {
			await this.loadingStatuses$
				.pipe(
					filter((loadingStatuses) => loadingStatuses[identifier] === StoreLoadingStatus.LOADED),
					take(1),
				)
				.toPromise();
			return;
		}

		this.sendLoadingStatus(identifier, StoreLoadingStatus.LOADING);
		const script = this.document.createElement('script');
		script.src = fromUrl;

		const loadPromise = new Promise<void>((reject, resolve) => {
			script.onload = () => {
				this.sendLoadingStatus(identifier, StoreLoadingStatus.LOADED);
				resolve();
			};
			script.onerror = () => {
				this.sendLoadingStatus(identifier, StoreLoadingStatus.INITIAL);
				this.errorHandler.handleError(`Error Loading in script from ${fromUrl}`);
				reject();
			};
			this.document.body.appendChild(script);
		});

		try {
			await loadPromise;
		} catch (e) {
			e && this.errorHandler.handleError(e);
		}
	}

	private sendLoadingStatus(identifier: string, status: StoreLoadingStatus): void {
		this.loadingStatuses$.next({
			...this.loadingStatuses$.getValue(),
			[identifier]: status,
		});
	}
}
