import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyEmployee, InviteState, Member, MemberRole } from 'domain-entities';
import {
	AbstractControl,
	AsyncValidatorFn,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { selectOpenInvitations } from '@store/selectors/invitations.selectors';
import {
	isOwnerOfCompany,
	selectCompanyId,
	selectCompanyMembers,
	selectUserId,
} from '@store/selectors/app.selectors';
import { filter, map, switchMapTo, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { CompanyService } from '@injectables/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import {
	selectCompanyEmployee,
	selectLoadingStateOfCompanyEmployees,
} from '@store/selectors/company.selectors';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { EMAIL_PATTERN, NAME_PATTERN } from '@constants/validators.constants';
import { createCompanyInvitationByCompanyIdAndMember } from '@shared/functions/invitations/invitations.functions';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { omit } from 'lodash';
import {
	updateCompanyMemberFactory,
	upsertCompanyEmployee,
} from '@shared/firebase/company/company-update.functions';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@craftnote/shared-utils';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';

export enum CompanyMemberOperation {
	ADD = 'ADD',
	EDIT = 'EDIT',
}

export type AddEditCompanyEmployeeContext =
	| 'company-members'
	| 'project-members'
	| 'project-creation'
	| 'onboarding';

@Component({
	selector: 'app-add-edit-company-employee',
	templateUrl: './add-edit-company-employee.component.html',
	styleUrls: ['./add-edit-company-employee.component.scss'],
})
export class AddEditCompanyEmployeeComponent implements OnInit, OnDestroy {
	@Input() backButtonTextKey = 'companyMembers.invite.dialog.back-btn';
	@Input() member: Member = null;
	@Input() inviteButtonTextKey = 'companyMembers.invite.dialog.inviteTxt';
	@Input() context: AddEditCompanyEmployeeContext = null;
	@Input() showBackButton = false;
	@Input() operation: CompanyMemberOperation = null;
	@Output() backButtonPressed = new EventEmitter();
	@Output() afterInvitationSubmit = new EventEmitter();
	private currentUserId$ = this.store.select(selectUserId);
	private companyMembers$ = this.store.select(selectCompanyMembers);
	private companyOpenInvitations$ = this.store.select(selectOpenInvitations);
	memberForm: UntypedFormGroup;
	MemberRole = MemberRole;
	InviteState = InviteState;
	CompanyMemberOperation = CompanyMemberOperation;
	isLoading = false;
	isLoggedInUserOwner$ = this.store.select(isOwnerOfCompany);
	isMemberHimself$ = this.currentUserId$.pipe(
		map((currentUserId) => currentUserId === this.member?.id),
	);

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly store: Store,
		private readonly invitationService: InvitationService,
		private readonly companyService: CompanyService,
		private readonly notificationService: NotificationSnackbarService,
		private readonly translate: TranslateService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		@Inject(WINDOW) private windowRef: Window,
	) {}

	async ngOnInit(): Promise<void> {
		await this.setMemberForm();

		/**
		 * Only update the validations on edit in different scenarios
		 */
		await this.updateValidations();

		this.updateUrlFragment();
	}

	async ngOnDestroy(): Promise<void> {
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
		});
	}

	getControlWithName(controlName: string): AbstractControl {
		return this.memberForm?.get(controlName);
	}

	async isMemberHimself(): Promise<boolean> {
		return this.isMemberHimself$.pipe(take(1)).toPromise();
	}

	async onInvitationSubmit(): Promise<void> {
		this.isLoading = true;
		await this.addInvitation();
		this.isLoading = false;
		this.afterInvitationSubmit.emit();
	}

	async editMember(): Promise<void> {
		this.isLoading = true;
		const companyId = await this.store.select(selectCompanyId).pipe(take(1)).toPromise();
		const messageKey = 'companyMembers.invite.dialog.editSuccess';

		const { employeeId } = this.memberForm.value;
		const memberToUpdate = omit({ ...this.memberForm.value }, 'employeeId');
		await this.companyService.updateCompanyTransactional(
			companyId,
			updateCompanyMemberFactory(this.member?.email, memberToUpdate),
			messageKey,
		);
		await this.updateCompanyEmployee(employeeId);
		this.isLoading = false;
		this.afterInvitationSubmit.emit();
	}

	private async updateUrlFragment(): Promise<void> {
		const fragment =
			this.operation === CompanyMemberOperation.ADD
				? 'add-company-employee'
				: 'edit-company-employee';

		if (this.context === 'onboarding' && !this.windowRef.location.pathname.includes('register')) {
			return;
		}
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
			fragment,
		});
	}

	private async setMemberForm(): Promise<void> {
		const openInvitations = await this.companyOpenInvitations$.pipe(take(1)).toPromise();
		const openInvitation = openInvitations.find(
			(invitation) => invitation.inviteeEmail === this.member?.email,
		);

		if (this.operation === CompanyMemberOperation.ADD) {
			this.memberForm = this.getAddOrEditInvitationForm();
			return;
		}

		if (
			this.operation === CompanyMemberOperation.EDIT &&
			this.member?.inviteState === InviteState.INVITED &&
			openInvitation
		) {
			this.memberForm = this.getAddOrEditInvitationForm(this.member);
			return;
		}

		/**
		 * EmployeeId should be shown only on edit member form
		 */

		const companyEmployee = await this.getCompanyEmployee().pipe(take(1)).toPromise();
		this.memberForm = this.getEditMemberForm(this.member, companyEmployee);
	}

	private getCompanyEmployee(): Observable<CompanyEmployee> {
		return this.store.select(selectLoadingStateOfCompanyEmployees).pipe(
			filter((state) => state === StoreLoadingStatus.LOADED),
			switchMapTo(this.store.select(selectCompanyEmployee, { employeeId: this.member?.id })),
		);
	}

	private getAddOrEditInvitationForm(member?: Member): UntypedFormGroup {
		return this.fb.group({
			email: [member?.email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
			jobTitle: [member?.jobTitle, Validators.pattern(NAME_PATTERN)],
			role: [member?.role || MemberRole.EMPLOYEE, Validators.required],
		});
	}

	private getEditMemberForm(member: Member, companyEmployee: CompanyEmployee): UntypedFormGroup {
		return this.fb.group({
			name: [member?.name, [Validators.required, Validators.pattern(NAME_PATTERN)]],
			lastname: [member?.lastname, [Validators.required, Validators.pattern(NAME_PATTERN)]],
			email: [member?.email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
			jobTitle: [member?.jobTitle, Validators.pattern(NAME_PATTERN)],
			mobile: [member?.mobile],
			role: [member?.role || MemberRole.EMPLOYEE, Validators.required],
			employeeId: [companyEmployee?.employeeNumber || ''],
		});
	}

	private async updateValidations(): Promise<void> {
		if (this.operation === CompanyMemberOperation.ADD) {
			this.getControlWithName('email').setAsyncValidators(this.memberEmailValidation());
			return;
		}

		const isOwner = await this.isLoggedInUserOwner$.pipe(take(1)).toPromise();
		const isMemberHimself = await this.isMemberHimself();

		/**
		 * the form should be disabled in the following cases
		 * 1. If the member is in invited state (for all roles)
		 * 2. If the member is not himself for supervisors and employees
		 */

		if (this.member?.inviteState === InviteState.INVITED || (!isOwner && !isMemberHimself)) {
			this.memberForm.disable();
			return;
		}

		await this.updateEditMemberFormValidations(isOwner, isMemberHimself, this.memberForm);
	}

	private async updateEditMemberFormValidations(
		isOwner: boolean,
		isMemberHimself: boolean,
		memberForm: UntypedFormGroup,
	): Promise<void> {
		let disabledControls = ['email'];

		if (isOwner) {
			if (!isMemberHimself) {
				disabledControls = [...disabledControls, 'name', 'lastname'];
			} else {
				disabledControls = [...disabledControls, 'role'];
			}
		} else {
			if (isMemberHimself) {
				disabledControls = [...disabledControls, 'role', 'jobTitle', 'mobile'];
			}
		}

		disabledControls.forEach((controlName) => memberForm?.get(controlName)?.disable());
	}

	private async addInvitation(): Promise<void> {
		const [inviteeId, companyId] = await combineLatest([
			this.store.select(selectUserId),
			this.store.select(selectCompanyId),
		])
			.pipe(take(1))
			.toPromise();
		const { email } = this.memberForm.value;
		const memberToUpdate = { ...this.member, ...this.memberForm.value, email: email.trim() };
		const invitation = createCompanyInvitationByCompanyIdAndMember(
			companyId,
			inviteeId,
			memberToUpdate,
		);

		await this.invitationService.create(invitation, this.context);
		this.notificationService.show(BasicSnackbarComponent, {
			componentTypes: {
				description: this.translate.instant('companyMembers.invite.dialog.addSuccess'),
				icon: 'done',
			},
			level: 1,
		});
	}

	private memberEmailValidation(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> =>
			this.companyMembers$.pipe(
				take(1),
				map((companyMembers) => {
					const email = control.value?.trim().toLowerCase();

					if (Object.keys(companyMembers).includes(email)) {
						return { companyMemberExists: true };
					}

					return null;
				}),
			);
	}

	private async updateCompanyEmployee(employeeId: string): Promise<void> {
		const [isOwner, companyId] = await combineLatest([
			this.isLoggedInUserOwner$,
			this.store.select(selectCompanyId),
		])
			.pipe(take(1))
			.toPromise();

		if (!isOwner) {
			return;
		}

		const userId = this.member?.id;
		const updateFunction = upsertCompanyEmployee(userId, employeeId);

		return this.companyService
			.upsertCompanyEmployee(companyId, userId, updateFunction)
			.catch((error) => console.error(error));
	}
}
