import { NgModule } from '@angular/core';
import { ProjectTasksListComponent } from './project-tasks-list.component';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UnixToMillisPipeModule } from '@modules/shared/pipes/unix-to-millis/unix-to-millis-pipe.module';

@NgModule({
	imports: [
		SharedModule,
		MatMenuModule,
		MatCheckboxModule,
		RouterModule,
		MatIconModule,
		MatButtonModule,
		UnixToMillisPipeModule,
	],
	declarations: [ProjectTasksListComponent],
	exports: [],
})
export class ProjectTasksListModule {}
