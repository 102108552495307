import { Injectable } from '@angular/core';
import { EntityChanges } from '@craftnote/shared-utils';
import { ProjectFilesConnector } from '@shared/firebase/connectors/firestore/collections/project-file/project-files.connector';
import { File } from 'domain-entities';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProjectFilesService {
	constructor(private readonly projectFilesConnector: ProjectFilesConnector) {}

	watchProjectFileEntityChanges(projectId: string): Observable<EntityChanges<File>> {
		return this.projectFilesConnector.watchProjectFileEntityChanges(projectId);
	}

	watchProjectFiles(projectId: string): Observable<File[]> {
		return this.projectFilesConnector.watchProjectFiles(projectId);
	}

	watchProjectFileById(fileId: string): Observable<File> {
		return this.projectFilesConnector.watchProjectFileById(fileId);
	}
}
