import { AfterContentInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';

@Directive({
	selector: '[appAutoScroll]',
})
export class AutoscrollDirective implements AfterContentInit, OnDestroy {
	private nativeElement: HTMLElement;
	private _isLocked = false;
	private mutationObserver: MutationObserver;

	constructor(element: ElementRef) {
		this.nativeElement = element.nativeElement;
	}

	public ngAfterContentInit(): void {
		this.mutationObserver = new MutationObserver(() => {
			if (!this._isLocked) {
				this.scrollDown();
			}
		});
		this.mutationObserver.observe(this.nativeElement, {
			childList: true,
			subtree: true,
			attributes: false,
		});
	}

	public ngOnDestroy(): void {
		this.mutationObserver.disconnect();
	}

	public forceScrollDown(): void {
		this.scrollDown();
	}

	public isLocked(): boolean {
		return this._isLocked;
	}

	private scrollDown(): void {
		this.nativeElement.scrollTop = this.nativeElement.scrollHeight;
	}

	@HostListener('scroll')
	public scrollHandler(): void {
		const scrollFromBottom =
			this.nativeElement.scrollHeight -
			this.nativeElement.scrollTop -
			this.nativeElement.clientHeight;
		this._isLocked = scrollFromBottom > 10;
	}
}
