import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Event, NavigationStart, Router } from '@angular/router';
import { WINDOW } from '@craftnote/shared-utils';
import { environment } from '@env/environment';
import { ScriptLoaderService } from '@injectables/services/script-loader.service';
import { VersionPollingService } from '@injectables/services/version-polling.service';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

@Injectable()
export class AppInitService {
	private isMobilePlatform = this.breakpointObserver.observe('(max-width: 760px)');

	constructor(
		private readonly versionPollingService: VersionPollingService,
		@Inject(WINDOW) private readonly windowRef: Window,
		private readonly router: Router,
		private readonly breakpointObserver: BreakpointObserver,
		private readonly angularFireAuth: AngularFireAuth,
		private readonly scriptLoadingService: ScriptLoaderService,
	) {}

	async init(): Promise<void> {
		this.initHashRemovalStream();
		this.initVersionPolling();
		this.initDebugFirebaseSdk();
		this.initLoadPdfJs();
	}

	private initHashRemovalStream(): void {
		this.router.events
			.pipe(
				filter((event: Event) => event instanceof NavigationStart),
				filter((event: NavigationStart) => event.url && event.url.match(/^\/#/)?.length > 0),
			)
			.subscribe((event: NavigationStart) => {
				window.location.href = event.url.replace('/#', '');
			});
	}

	private initVersionPolling(): Promise<void> {
		return new Promise<void>(async (resolve) => {
			const isNewVersionAvailable = await this.versionPollingService.isNewVersionAvailable();
			if (isNewVersionAvailable) {
				this.windowRef.location.reload();
			} else {
				resolve();
			}
		});
	}

	getMobilePlatform(): Observable<boolean> {
		return this.isMobilePlatform.pipe(pluck('matches'));
	}

	/**
	 * This method is exposing the firebase sdk on dev builds to allow for e2e testing
	 * @private
	 */
	private async initDebugFirebaseSdk(): Promise<void> {
		if (environment.production) {
			return;
		}
		this.windowRef['firebaseDebug'] = await this.angularFireAuth.app;
	}

	private async initLoadPdfJs(): Promise<void> {
		await this.scriptLoadingService.loadScript(
			'pdfJs',
			'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js',
		);
		const pdfJs = (this.windowRef as any).pdfjsLib;
		pdfJs.GlobalWorkerOptions.workerSrc =
			'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.min.js';
	}
}
