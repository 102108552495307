import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { noop } from 'lodash';
import { TrackedTimeWorkTypeButtonService } from '../services/tracked-time-work-type-button.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { hasValue } from '@shared/shared.utils';

@Component({
	selector: 'app-tracked-time-work-type-button',
	templateUrl: './tracked-time-work-type-button.component.html',
	styleUrls: ['./tracked-time-work-type-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TrackedTimeWorkTypeButtonComponent,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: TrackedTimeWorkTypeButtonComponent,
			multi: true,
		},
	],
})
export class TrackedTimeWorkTypeButtonComponent implements ControlValueAccessor, OnInit, OnDestroy {
	@Output() openWorkTypeList = new EventEmitter<void>();
	workTypes;
	private touched = false;
	private destroy$ = new Subject();
	@Input() fallbackWorkTypeName = '';
	@Input() isFormValidated: boolean;

	constructor(public readonly trackedTimeWorkTypeButtonService: TrackedTimeWorkTypeButtonService) {}

	private _required = false;

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: boolean) {
		this._required = coerceBooleanProperty(value);
	}

	private _disabled = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
	}

	@Input()
	get value(): string | null {
		return this.trackedTimeWorkTypeButtonService.getSelectedWorkTypeId();
	}

	set value(workType: string | null) {
		this.trackedTimeWorkTypeButtonService.setSelectedWorkTypeId(workType);
	}

	get isInvalid(): boolean {
		const selectedWorkTypeId = this.trackedTimeWorkTypeButtonService.getSelectedWorkTypeId();
		return this.required && !hasValue(selectedWorkTypeId);
	}

	onChange = (_: any) => noop;

	onTouched = () => noop;

	ngOnInit(): void {
		this.trackedTimeWorkTypeButtonService.selectedWorkTypeIdChanges$
			.pipe(takeUntil(this.destroy$))
			.subscribe((workType) => {
				this.markAsTouched();
				this.onChange(workType);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	writeValue(workType: string | null): void {
		this.value = workType;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	markAsTouched(): void {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	validate(): ValidationErrors | null {
		if (this.isInvalid) {
			return {
				required: true,
			};
		}
		return null;
	}
}
