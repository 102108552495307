import { Injectable } from '@angular/core';
import { AuditLogConnector, TrackedTimeChange } from '@injectables/connectors/audit-log.connector';
import { Observable } from 'rxjs';
import { GeoLocationService } from '@injectables/services/geo-location/geo-location.service';

@Injectable({
	providedIn: 'root',
})
export class TrackedTimeAuditLogService {
	constructor(
		private readonly auditLogConnector: AuditLogConnector,
		private readonly locationService: GeoLocationService,
	) {}

	getAuditLogForTrackedTime(trackedTime: string): Observable<TrackedTimeChange[]> {
		const locale = this.locationService.getLocalePrefix();
		return this.auditLogConnector.getAuditLog({
			locale,
			resourceType: 'trackedTime',
			resourceId: trackedTime,
			timezone: this.locationService.getTimezone(),
		});
	}
}
