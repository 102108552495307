<section>
	<div class="d-flex align-items-center mb-3">
		<h1 class="m-0">{{ data?.title }}</h1>
		<em class="material-icons ml-auto pointer" (click)="closeModal()">close</em>
	</div>

	<form [formGroup]="editForm" (ngSubmit)="onSubmitForm()">
		<mat-form-field appearance="outline" floatLabel="always" class="w-100 form-field-outline">
			<mat-label>{{ data?.formLabel }}</mat-label>
			<ng-container [ngSwitch]="data?.formType">
				<ng-template [ngSwitchCase]="'text'">
					<input matInput trim="blur" formControlName="content" />
				</ng-template>
				<ng-template [ngSwitchCase]="'textarea'">
					<textarea
						matInput
						trim="blur"
						class="m-0 p-0 message-textarea"
						cdkTextareaAutosize
						formControlName="content"
						#autosize="cdkTextareaAutosize"
						cdkAutosizeMinRows="1"
						cdkAutosizeMaxRows="5"
					></textarea>
				</ng-template>
				<ng-container *ngSwitchDefault>
					<input trim="blur" matInput formControlName="content" />
				</ng-container>
			</ng-container>
		</mat-form-field>

		<div class="text-right">
			<button color="accent" class="px-5" mat-flat-button [disabled]="editForm.pristine" type="submit">
				{{ data?.primaryBtnText }}
			</button>
		</div>
	</form>
</section>
