import { NgModule } from '@angular/core';
import { BytesPipe } from './bytes.pipe';

const PIPE = [BytesPipe];

@NgModule({
	declarations: [PIPE],
	exports: [PIPE],
	providers: [PIPE],
})
export class BytesPipeModule {}
