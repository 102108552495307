import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { TimeRange } from '@globalTypes/interfaces/time-range.types';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { KeyValue } from '@angular/common';
import { values } from 'lodash';

export interface DateRangeDialogTexts {
	rangeHint: string;
	displayHint: string;
	displaySubHint: string;
}

export enum DateRangeShortcuts {
	TODAY = '01_projectList_dateFilter_options_today',
	LAST_WEEK = '02_projectList_dateFilter_options_lastWeek',
	THIS_WEEK = '03_projectList_dateFilter_options_thisWeek',
	NEXT_WEEK = '04_projectList_dateFilter_options_nextWeek',
	LAST_MONTH = '05_projectList_dateFilter_options_lastMonth',
	THIS_MONTH = '06_projectList_dateFilter_options_thisMonth',
	NEXT_MONTH = '07_projectList_dateFilter_options_nextMonth',
	NONE = '08_projectList_dateFilter_options_noDate',
	CUSTOM = 'hiddenCustomField',
}

@Component({
	selector: 'app-date-range-dialog',
	templateUrl: './date-range-dialog.component.html',
	styleUrls: ['./date-range-dialog.component.scss'],
})
export class DateRangeDialogComponent implements OnInit, OnDestroy {
	@Input() timeRange: UntypedFormControl;
	@Input() textConfig: DateRangeDialogTexts;
	@Output() close = new EventEmitter<void>();
	@Output() set = new EventEmitter<TimeRange | null>();
	private destroy$ = new Subject();

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	currentPreset: DateRangeShortcuts;
	DateRangeShortcuts = DateRangeShortcuts;

	formGroup = this.formBuilder.group({
		start: [moment().startOf('day').toDate()],
		end: [moment().endOf('day').toDate()],
	});

	get startControl(): UntypedFormControl {
		return this.formGroup.controls['start'] as UntypedFormControl;
	}

	get endControl(): UntypedFormControl {
		return this.formGroup.controls['end'] as UntypedFormControl;
	}

	ngOnInit(): void {
		this.initFormgroupListener();
		this.initPreset();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	getPrefixColor(prefix: DateRangeShortcuts): ThemePalette {
		return prefix === this.currentPreset ? 'accent' : undefined;
	}

	applyDateRangeShortcut(shortcut: DateRangeShortcuts): void {
		this.applyTimeRange(this.getTimeRangeForShortcut(shortcut));
	}

	private getTimeRangeForShortcut(shortcut: DateRangeShortcuts): TimeRange {
		let start: Moment = moment();
		let end: Moment = moment();
		switch (shortcut) {
			case DateRangeShortcuts.NONE:
				start = null;
				end = null;
				break;
			case DateRangeShortcuts.TODAY:
				start = moment().startOf('day');
				end = moment().endOf('day');
				break;
			case DateRangeShortcuts.LAST_WEEK:
				const oneWeekAgo = moment().subtract(1, 'week');
				start = oneWeekAgo.clone().startOf('week');
				end = oneWeekAgo.clone().endOf('week');
				break;
			case DateRangeShortcuts.THIS_WEEK:
				start = moment().startOf('week');
				end = moment().endOf('week');
				break;
			case DateRangeShortcuts.NEXT_WEEK:
				const inOneWeek = moment().add(1, 'week');
				start = inOneWeek.clone().startOf('week');
				end = inOneWeek.clone().endOf('week');
				break;
			case DateRangeShortcuts.LAST_MONTH:
				const oneMonthAgo = moment().subtract(1, 'month');
				start = oneMonthAgo.clone().startOf('month');
				end = oneMonthAgo.clone().endOf('month');
				break;
			case DateRangeShortcuts.THIS_MONTH:
				start = moment().startOf('month');
				end = moment().endOf('month');
				break;
			case DateRangeShortcuts.NEXT_MONTH:
				const inOneMonth = moment().add(1, 'month');
				start = inOneMonth.clone().startOf('month');
				end = inOneMonth.clone().endOf('month');
		}

		return { start: start?.toDate() || null, end: end?.toDate() || null };
	}

	apply(): void {
		if (this.currentPreset === DateRangeShortcuts.NONE) {
			this.set.emit(null);
			return;
		}
		const start = !this.startControl.value
			? null
			: moment(this.startControl.value).startOf('day').toDate();
		const end = !this.endControl.value ? null : moment(this.endControl.value).endOf('day').toDate();

		this.set.emit({ start, end });
	}

	reset(): void {
		this.set.emit({ start: null, end: null });
	}

	private initFormgroupListener(): void {
		this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (
				value.start &&
				moment(value.start).startOf('day').valueOf() !== moment(value.start).valueOf()
			) {
				this.startControl.setValue(moment(value.start).startOf('day').toDate());
				return;
			}
			if (value.end && moment(value.end).endOf('day').valueOf() !== moment(value.end).valueOf()) {
				this.endControl.setValue(moment(value.end).endOf('day').toDate());
				return;
			}
			this.currentPreset = this.getShortcutForTimeRange(value);
		});
	}

	getStartDatePlaceholderKey(): string {
		return this.currentPreset === DateRangeShortcuts.NONE
			? 'projectList_dateFilter_notSet'
			: 'projectList_dateFilter_range_start';
	}

	getEndDatePlaceholderKey(): string {
		return this.currentPreset === DateRangeShortcuts.NONE
			? 'projectList_dateFilter_notSet'
			: 'projectList_dateFilter_range_end';
	}

	compareShortCuts(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
		return a.value.localeCompare(b.value);
	}

	private initPreset(): void {
		if (this.timeRange.value && !this.timeRange.value.start && !this.timeRange.value.end) {
			this.applyDateRangeShortcut(DateRangeShortcuts.TODAY);
		} else {
			this.applyTimeRange(this.timeRange.value);
		}
	}

	private getShortcutForTimeRange(range: TimeRange): DateRangeShortcuts | null {
		if (!range) {
			this.currentPreset = DateRangeShortcuts.NONE;
			return DateRangeShortcuts.NONE;
		}
		for (const preset of values(DateRangeShortcuts)) {
			const value = this.getTimeRangeForShortcut(preset);

			if (
				+value.start === new Date(range.start).getTime() &&
				+value.end === new Date(range.end).getTime()
			) {
				return preset;
			}
		}
		return null;
	}

	private applyTimeRange(range: TimeRange): void {
		this.startControl.setValue(range?.start || null);
		this.endControl.setValue(range?.end || null);
	}
}
