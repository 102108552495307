<div (click)="selectProjectItem(project)" class="d-flex project-item flex-column">
	<div
		*ngIf="showParent && project.projectType === ProjectType.PROJECT && (parentProject$ | async) as parentProject"
		class="d-flex overflow-hidden align-items-center"
	>
		<mat-icon class="mr-2" fontSet="material-icons-sharp">folder</mat-icon>
		<div class="flex-grow-1 text-truncate">
			<strong>{{ parentProject.name }}</strong>
		</div>
	</div>
	<div class="d-flex overflow-hidden align-items-center">
		<div class="project-item__icon mr-2">
			<app-project-indicator *ngIf="project.projectType === ProjectType.PROJECT" [project]="project"></app-project-indicator>
			<mat-icon *ngIf="project.projectType === ProjectType.FOLDER" fontSet="material-icons-sharp">folder</mat-icon>
		</div>
		<div class="flex-grow-1 overflow-hidden">
			<div class="project-item__name text-truncate">
				{{ project.name }}
			</div>
			<div class="project-item__id">{{ project.orderNumber }}</div>
			<div class="project-item__address">{{ project | projectAddress }}</div>
		</div>
		<div class="project-item__actions">
			<mat-icon *ngIf="project.projectType === 'PROJECT' && isSelected" class="color-primary mr-2" fontSet="material-icons-sharp">
				check
			</mat-icon>
		</div>
	</div>
</div>
