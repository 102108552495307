import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RemoteConfig } from '../../services/remote-config/remote-config.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyInitializationGuard {
	constructor(private readonly remoteConfig: RemoteConfig, private readonly router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		if (!!(await this.remoteConfig.getValueAsync('feature_splash_screen'))) {
			return this.router.parseUrl(route.data.redirectUnauthorisedTo);
		}

		return true;
	}
}
