import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleSubject = new BehaviorSubject<string>('Craftnote');
  private notificationSubject = new BehaviorSubject<boolean>(false);
  private storageKey = 'craftnote_notification_state';

  constructor(private titleService: Title) {
    this.initStorageListener();
  }

  setProjectTitle(projectName: string): void {
    this.setPageTitle(projectName);
  }

  setPageTitle(pageName: string): void {
    this.titleSubject.next(`${pageName} - Craftnote`);
    this.updateTitle();
  }

  setNotification(hasNotification: boolean): void {
    localStorage.setItem(this.storageKey, JSON.stringify({ hasNotification }));
    this.notificationSubject.next(hasNotification);
    this.updateTitle();
  }

  getTitle(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  resetTitle(): void {
    this.titleSubject.next('Craftnote');
    this.updateTitle();
  }

  private updateTitle(): void {
    const currentTitle = this.titleSubject.getValue();
    const hasNotification = this.notificationSubject.getValue();
    const newTitle = hasNotification ? `● ${currentTitle}` : currentTitle;
    this.titleService.setTitle(newTitle);
  }

  private initStorageListener(): void {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(filter(event => event.key === this.storageKey))
      .subscribe(event => {
        const data = JSON.parse(event.newValue);
        this.notificationSubject.next(data.hasNotification);
        this.updateTitle();
      });
  }
}