import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromMessageActions from '../actions/message.actions';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	first,
	map,
	mergeAll,
	mergeMap,
	skip,
	startWith,
	switchMap,
	switchMapTo,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { MESSAGES_BATCH_SIZE, MessageService } from '@injectables/services/message/message.service';
import { combineLatest, EMPTY, interval, of, ReplaySubject, Subject } from 'rxjs';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { Message } from 'domain-entities';
import { Action, Store } from '@ngrx/store';
import { MessageEntityState, messagesFeatureKey } from '../reducers/message.reducer';
import {
	isNotStatusMessage,
	isVisibleMessage,
	messagesHasEarlier,
	messagesSearchEnabled,
	selectAllMessageIds,
} from '../selectors/message.selectors';
import { SearchService } from '@injectables/services/search/search.service';
import { retryOnError, skipWhenTrue, takeWhenTrue, tapOnce } from '@craftnote/shared-utils';
import { AuthService } from '@injectables/services/auth/auth.service';
import { PerformanceTraceService } from '@injectables/services/performance-trace.service';
import { PerformanceTrace, PerformanceTraceConstants } from '@shared/constants/performace-trace';
import { selectActiveProject } from '@store/selectors/route.selectors';
import { LocalStorageService } from '@injectables/services/local-storage.service';

const FirebaseDatabaseEventToActionMapper = {
	value: fromMessageActions.UpsertMessage,
	child_added: fromMessageActions.UpsertMessage,
	child_changed: fromMessageActions.UpsertMessage,
	child_moved: fromMessageActions.UpsertMessage,
};

const mapFirebaseEventToAction = (messageSnapshot: SnapshotAction<Message>) => {
	const message = messageSnapshot.payload.val();
	return FirebaseDatabaseEventToActionMapper[messageSnapshot.type]({ message: message });
};

@Injectable()
export class MessageEffects {
	private messageSubscriptionDestroyer$ = new Subject();
	private loadMessagesErrorHandlingStopTimer$$: Subject<boolean> = new Subject<boolean>();
	private loadMessagesErrorHandling$$: ReplaySubject<'loading-enabled' | 'loading-disabled'> =
		new ReplaySubject(1);
	private loadMessagesErrorHandling$ = this.loadMessagesErrorHandling$$.asObservable();
	// @ts-ignore
	private loadMessagesErrorHandlingSubscription = this.loadMessagesErrorHandling$$
		.asObservable()
		.pipe(
			switchMap((state) => {
				if (state === 'loading-enabled') {
					return interval(1000).pipe(
						take(21),
						takeUntil(this.loadMessagesErrorHandlingStopTimer$$),
						tap(async (ticker) => {
							if (ticker === 20) {
								const loadMessagesErrorHandlingState = await this.loadMessagesErrorHandling$
									.pipe(take(1))
									.toPromise();
								if (loadMessagesErrorHandlingState === 'loading-enabled') {
									throw new Error('MessageEffects: loadMessages$ loader is still enabled');
								}
							}
						}),
					);
				} else {
					this.loadMessagesErrorHandlingStopTimer$$.next(true);
					return EMPTY;
				}
			}),
		)
		.subscribe();

	constructor(
		private readonly actions$: Actions,
		private readonly messageService: MessageService,
		private readonly store: Store<{ [messagesFeatureKey]: MessageEntityState }>,
		private readonly searchService: SearchService,
		private readonly localStorageService: LocalStorageService,
		private readonly authService: AuthService,
		private readonly performanceTraceService: PerformanceTraceService,
	) {}

	loadChatMediaMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.loadChatMediaByProjectAction),
			mergeMap(({ projectId }) => {
				return this.searchService
					.chatSearch({
						projectId,
						type: ['IMAGE', 'VIDEO', 'DOCUMENT'],
					})
					.pipe(
						take(1),
						map((searchResult) => searchResult.result),
					);
			}),
			map((messages) => {
				return fromMessageActions.upsertChatMediaMessagesAction({ messages });
			}),
		),
	);

	loadMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.LoadMessagesByProject),
			tap(() => {
				this.loadMessagesErrorHandling$$.next('loading-enabled');
				this.store.dispatch(fromMessageActions.LoadingEnabled());
			}),
			switchMap((loadMessages) =>
				combineLatest([
					of(loadMessages.projectId),
					this.messageService.getLatestMessage(loadMessages.projectId).pipe(retryOnError()),
				]),
			),
			switchMap(([projectId, lastMessage]) => {
				this.messageSubscriptionDestroyer$.next(null);
				this.store.dispatch(fromMessageActions.ClearMessages());
				this.store.dispatch(fromMessageActions.loadChatMediaByProjectAction({ projectId }));

				this.loadMessagesErrorHandling$$.next('loading-disabled');
				if (!lastMessage) {
					this.store.dispatch(fromMessageActions.NextBatchNotAvailable());
					this.store.dispatch(fromMessageActions.LoadingDisabled());

					return this.messageService.loadMessages(projectId).pipe(
						retryOnError(),
						startWith(0),
						tapOnce(() => {
							this.performanceTraceService.stop(PerformanceTrace.TRANSACTION_NEW_CHAT_LOADED);
						}),
						filter<SnapshotAction<Message>>(Boolean),
						takeUntil(this.messageSubscriptionDestroyer$),
						tap((message) =>
							this.store.dispatch(
								fromMessageActions.upsertChatMediaMessagesAction({
									messages: [message.payload.val()],
								}),
							),
						),
						map(mapFirebaseEventToAction),
					);
				}

				this.performanceTraceService.start(PerformanceTrace.COLLECTION_CHAT_MESSAGES_LOADED);
				this.store.dispatch(
					fromMessageActions.LoadBatchMessagesByProject({
						projectId: projectId,
						lastMessageId: lastMessage.id,
					}),
				);

				return this.messageService.loadLatestMessagesFromKey(projectId, lastMessage.id).pipe(
					takeUntil(this.messageSubscriptionDestroyer$),
					tap((message) =>
						this.store.dispatch(
							fromMessageActions.upsertChatMediaMessagesAction({
								messages: [message.payload.val()],
							}),
						),
					),
					skip(1), // Had to skip one because of UX. the latest message loads before the predecessor messages and it feels like a bug to the user.
					map(mapFirebaseEventToAction),
				);
			}),
		),
	);

	loadMoreMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.LoadBatchMessagesByProject),
			debounceTime(100),
			distinctUntilChanged(),
			skipWhenTrue(this.store.select(messagesSearchEnabled)),
			takeWhenTrue(this.store.select(messagesHasEarlier)),
			tap(() => this.store.dispatch(fromMessageActions.LoadingEnabled())),
			mergeMap((actionType) => {
				const loadMessages$ = actionType.startFromMessageId
					? this.messageService.loadAllMessagesStartAtKey(
							actionType.projectId,
							actionType.startFromMessageId,
					  )
					: this.messageService.loadMessageBatchEndAtKey(
							actionType.projectId,
							actionType.lastMessageId,
					  );

				return loadMessages$.pipe(
					map((snapshots) => {
						let dispatchAction: Action = fromMessageActions.NextBatchAvailable();

						// If lastMessageId is null then we assume there might be next batch
						if (snapshots.length < MESSAGES_BATCH_SIZE && actionType.lastMessageId !== null) {
							dispatchAction = fromMessageActions.NextBatchNotAvailable();
						}
						this.store.dispatch(dispatchAction);
						this.loadMoreBasedOnVisibility(
							snapshots,
							actionType.loadedMessages,
							actionType.projectId,
						);

						this.performanceTraceService.stop(PerformanceTrace.COLLECTION_CHAT_MESSAGES_LOADED, {
							name: PerformanceTraceConstants.MESSAGE_COUNT,
							count: snapshots.length,
						});

						return snapshots.map(mapFirebaseEventToAction);
					}),
					takeUntil(this.messageSubscriptionDestroyer$),
				);
			}),
			tap(() => this.store.dispatch(fromMessageActions.LoadingDisabled())),
			mergeAll(),
		),
	);

	searchAndFilter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.SearchAndFilter),
			tap((action) => {
				this.store.dispatch(fromMessageActions.ClearMessages());
				this.store.dispatch(fromMessageActions.LoadingEnabled());
				this.store.dispatch(
					fromMessageActions.SetSearchOptions({ searchOptions: action.searchAndFilterOptions }),
				);
			}),
			switchMap((action) =>
				this.searchService.chatSearch(action.searchAndFilterOptions).pipe(
					take(1),
					map((searchResult) => searchResult.result),
				),
			),
			tap(() => this.store.dispatch(fromMessageActions.LoadingDisabled())),
			mergeAll(),
			map((message) => fromMessageActions.UpsertMessage({ message: message })),
		),
	);

	selectParentMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.selectParentMessageAction),
			withLatestFrom(this.store.select(selectAllMessageIds)),
			mergeMap(([action, messageIds]) => {
				const selectedParentMessageId = action.message.parent.id;

				// @ts-ignore
				if (messageIds.find((id) => id === selectedParentMessageId)) {
					return of([
						fromMessageActions.setSelectedParentMessageAction({
							messageId: selectedParentMessageId,
						}),
					]);
				}

				this.store.dispatch(fromMessageActions.LoadingEnabled());
				return this.messageService
					.loadMessageBatchFromKey(action.message.projectId, selectedParentMessageId)
					.pipe(
						takeUntil(this.messageSubscriptionDestroyer$),
						map((snapshots) => {
							const actions = snapshots.map(mapFirebaseEventToAction);
							actions.push(
								fromMessageActions.setSelectedParentMessageAction({
									messageId: selectedParentMessageId,
								}),
							);
							return actions;
						}),
						tap(() => {
							this.store.dispatch(fromMessageActions.LoadingDisabled());
						}),
					);
			}),
			mergeAll(),
		),
	);

	loadAllMessagesOfChat$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.LoadAllMessages),
			tap(() => {
				this.store.dispatch(fromMessageActions.LoadingEnabled());
			}),
			switchMapTo(this.store.select(selectActiveProject).pipe(first())),
			switchMap((projectId) => {
				return this.searchService.chatSearch({ projectId });
			}),
			tap(() => {
				this.store.dispatch(fromMessageActions.LoadingDisabled());
			}),
			map((result) => {
				const messages = result.result;
				return fromMessageActions.UpsertMessages({ messages });
			}),
		),
	);

	clearParentMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.clearSelectedParentMessageAction),
			delay(2000),
			map(() => {
				return fromMessageActions.setSelectedParentMessageAction({ messageId: null });
			}),
		),
	);

	upsertMediaMessage = (messageSnapshot: SnapshotAction<Message>) => {
		const message = messageSnapshot.payload.val();
		this.store.dispatch(fromMessageActions.upsertChatMediaMessagesAction({ messages: [message] }));
	};

	private loadMoreBasedOnVisibility(
		snapshots: SnapshotAction<Message>[],
		lastLoadedMessages: number = 0,
		projectId: string,
	): void {
		const numberOfNewVisibleMessages = snapshots
			.map((snapshot) => snapshot.payload.val())
			.filter(async (message) => !(await this.isHiddenMessage(message))).length;
		const totalNumberOfVisibleMessages = lastLoadedMessages + numberOfNewVisibleMessages;

		// Note:: I think it should be >=
		if (totalNumberOfVisibleMessages > MESSAGES_BATCH_SIZE) {
			return;
		}

		this.store.dispatch(
			fromMessageActions.LoadBatchMessagesByProject({
				projectId,
				lastMessageId: snapshots[0].key,
				loadedMessages: numberOfNewVisibleMessages,
			}),
		);
	}

	private async isHiddenMessage(message: Message): Promise<boolean> {
		if (
			(await this.localStorageService.get('isShowStatusMessages')) === 'hide' &&
			!isNotStatusMessage(message)
		) {
			return true;
		}
		return !isVisibleMessage(message, this.authService.currentUserId());
	}
}
