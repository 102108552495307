import { Action, createReducer, on } from '@ngrx/store';
import { AppUiState } from '../state/app-ui.state';
import {
	setNavbarStateAction,
	setSidebarStateAction,
	setThemeStateAction,
} from '../actions/app.actions';

export enum AppTheme {
	DARK,
	LIGHT,
}

const initialState: AppUiState = {
	isSidebarEnabled: false,
	isNavbarEnabled: false,
	currentTheme: AppTheme.LIGHT,
};

const authReducerCreator = createReducer(
	initialState,
	on(setSidebarStateAction, (state, payload) => ({
		...state,
		isSidebarEnabled: payload.state,
	})),
	on(setNavbarStateAction, (state, payload) => ({
		...state,
		isNavbarEnabled: payload.state,
	})),
	on(setThemeStateAction, (state, payload) => ({
		...state,
		currentTheme: payload.state,
	})),
);

// tslint:disable-next-line:typedef
export function appReducer(state: AppUiState, action: Action) {
	return authReducerCreator(state, action);
}
