import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Project, Task, TaskTime } from 'domain-entities';
import { first, map, mapTo, pluck, scan, startWith, switchMap, switchMapTo } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { assign, debounce } from 'lodash';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { TaskUI } from '@modules/features/tasks-dashboard/tasks-dashboard.component';
import { EntityChanges } from '@craftnote/shared-utils';
import { TaskEditDetailsService } from '@injectables/services/task-edit-details/task-edit-details.service';
import { TasksService } from '@injectables/services/tasks/tasks.service';
import { TasksTaskDeletedEventBuilder } from '@generated/events/TasksEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { shareReplayOne } from '@craftnote/shared-utils';

@Component({
	selector: 'app-task-edit-details',
	templateUrl: './task-edit-details.component.html',
	styleUrls: ['./task-edit-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditDetailsComponent implements OnChanges, OnDestroy {
	@Output() cancelEdit = new EventEmitter();
	@Output() openWorkingHours = new EventEmitter();
	@Output() openTaskComments = new EventEmitter();
	@Output() deleteTask = new EventEmitter<string>();
	@Output() updateTask = new EventEmitter<{ taskUI: TaskUI; closeDrawer: boolean }>();
	@Output() openTaskFiles = new EventEmitter();
	@Input() taskUI: TaskUI = null;
	private destroy$: Subject<boolean> = new Subject();
	taskId$$: Subject<string> = new ReplaySubject(1);
	taskId$: Observable<string> = this.taskId$$.asObservable();
	project$$: Subject<Project> = new ReplaySubject(1);
	project$: Observable<Project> = this.project$$.asObservable();
	workingHoursLength$: Observable<number> = this.getWorkingHoursLength();
	taskCommentsLength$: Observable<number> = this.getTaskCommentsLength();
	taskFilesLength$: Observable<number> = this.taskId$.pipe(
		switchMap((id) => this.taskEditDetailsService.getTaskFiles(id)),
		pluck('length'),
		shareReplayOne(),
	);

	showChangesSavedIndicator$$ = new Subject();
	showChangesSavedIndicator$ = this.showChangesSavedIndicator$$
		.asObservable()
		.pipe(switchMapTo(timer(2000).pipe(mapTo(false), startWith(true))));
	onUpdateTaskDebounced = debounce(this.onUpdateTask, 500);

	constructor(
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translate: TranslateService,
		private readonly tasksService: TasksService,
		private readonly taskEditDetailsService: TaskEditDetailsService,
		private readonly trackingService: TrackingService,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.taskUI?.currentValue) {
			this.newTaskSelected(changes.taskUI.currentValue);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	newTaskSelected(taskUI: TaskUI): void {
		this.taskId$$.next(taskUI.task.id);
		this.project$$.next(taskUI.project);
	}

	async onCancelEditDetails(): Promise<void> {
		this.cancelEdit.emit();
	}

	async onDeleteTask(): Promise<void> {
		const doDelete = await this.confirmDialogService
			.open({
				title: this.translate.instant('task.delete.title'),
				primaryButtonText: this.translate.instant('task.delete.yes'),
				secondaryButtonText: this.translate.instant('task.delete.no'),
				primaryButtonColor: 'warn',
			})
			.afterClosed()
			.pipe(first())
			.toPromise();

		if (doDelete) {
			await this.tasksService.removeTask(this.taskUI.task.id);
			await this.trackingService.trackEvent(
				new TasksTaskDeletedEventBuilder({
					projectId: this.taskUI.project.id,
				}),
			);
			this.deleteTask.emit(this.taskUI.task.id);
		}
	}

	private async onUpdateTask(task: Partial<Task>): Promise<void> {
		const updatedTaskUI: TaskUI = assign(this.taskUI, { task });
		await this.tasksService.updateTask(updatedTaskUI.task);
		this.taskUI = { ...updatedTaskUI };
		this.showChangesSavedIndicator$$.next(null);
		this.updateTask.emit({ taskUI: this.taskUI, closeDrawer: false });
	}

	private getWorkingHoursLength(): Observable<number> {
		return this.taskId$.pipe(
			switchMap((taskId) =>
				this.taskEditDetailsService.getTaskTimesById(taskId).pipe(
					scan((totalWorkingHours: TaskTime[], taskTimesEntityChanges: EntityChanges<TaskTime>) => {
						if (taskTimesEntityChanges.changeType === 'created') {
							totalWorkingHours.push(...taskTimesEntityChanges.entities);
						}
						return totalWorkingHours;
					}, []),
					map((workingHours) => workingHours.length),
				),
			),
		);
	}

	private getTaskCommentsLength(): Observable<number> {
		return this.taskId$.pipe(
			switchMap((taskId) => this.taskEditDetailsService.getTaskComments(taskId)),
			pluck('length'),
		);
	}
}
