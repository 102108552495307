<section *ngVar="companyMembersIsBasic$ | async as companyMembersIsBasic" class="time-tracking-assignee-input-section">
	<mat-form-field appearance="outline" class="form-field-outline w-100" color="accent" floatLabel="always">
		<mat-label>{{ 'time-tracking-assignee-input.assignees' | translate }}</mat-label>
		<mat-chip-list #assigneeChipsListRef>
			<mat-chip (removed)="removeAssigneeFromForm(assignee)" *ngFor="let assignee of assignees" [disabled]="disabled">
				{{ getAssigneeFullName(assignee) }}
				<button matChipRemove>
					<mat-icon data-cy="remove-existing-assignee-time-entry">cancel</mat-icon>
				</button>
			</mat-chip>
			<input
				#assigneeInputRef
				[disabled]="disabled"
				[formControl]="assigneeInputControl"
				[matAutocomplete]="assigneeAutocomplete"
				[matChipInputFor]="assigneeChipsListRef"
				data-cy="time-entry-assignee-input"
			/>
		</mat-chip-list>
		<mat-autocomplete #assigneeAutocomplete="matAutocomplete" (optionSelected)="assigneeSelected($event)">
			<mat-option
				(click)="onClickAutocompleteAssignee(showAssigneePaywall && companyMembersIsBasic[assignee])"
				*ngFor="let assignee of filteredAssignees$ | async"
				[disabled]="showAssigneePaywall && companyMembersIsBasic[assignee]"
				[matTooltipDisabled]="!showAssigneePaywall || !companyMembersIsBasic[assignee]"
				[matTooltip]="'time-tracking-assignee-input.assignees-basic-tooltip' | translate"
				[value]="assignee"
				matTooltipClass="ch-tooltip"
			>
				<mat-checkbox [checked]="isAssigneeSelected(assignee)" [disabled]="showAssigneePaywall && companyMembersIsBasic[assignee]">
				</mat-checkbox>
				<span class="ml-2">{{ projectMembers[assignee] | memberFullName: 'email':true }}</span>
			</mat-option>
		</mat-autocomplete>
		<mat-error>{{ 'time-tracking-assignee-input.assignees-error' | translate }}</mat-error>
	</mat-form-field>
</section>
