import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { MemberRole, Project, ProjectType } from 'domain-entities';
import {
	ProjectColorTags,
	ProjectContext,
	ProjectItemAction,
	ProjectItemActions,
	ProjectStatusInformation,
} from '@shared/models/project.type';

@Component({
	selector: 'app-project-item-new',
	templateUrl: './project-item-new.component.html',
	styleUrls: ['./project-item-new.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectItemNewComponent implements OnChanges {
	@Input() project: Project;
	@Input() userRole: MemberRole;
	@Input() context: ProjectContext;
	@Input() isOpenFolderCard: boolean;
	@Input() selected: boolean;
	@Input() showParentHint: boolean;
	@Input() showUnreadDot: boolean;
	@Input() projectTime: string;
	@Input() isCurrentUserArchivedInProject: boolean;
	@Input() isCurrentUserInternalAdminOfProject: boolean;
	@Input() isExternalMemberOfProject: boolean;
	@Input() projectStatus: ProjectStatusInformation | null;
	@Input() childProjectsCount: number;
	@Input() isLastItem = false;
	@Input() isChatMessagesMuted = false;
	@Output() onAction = new EventEmitter<{ action: ProjectItemAction; project: Project }>();
	@Output() onGoBack = new EventEmitter();
	@Output() menuOpened = new EventEmitter<boolean>();

	projectColorTags = ProjectColorTags;
	projectType = ProjectType;
	projectContext = ProjectContext;
	projectItemAction = ProjectItemActions;
	showThreeDotsMenu = false;

	emitAction(action: ProjectItemAction): void {
		this.onAction.emit({ action, project: this.project });
	}

	onMenuOpened(): void {
		this.menuOpened.emit(true);
	}
	onMenuClosed(): void {
		this.menuOpened.emit(false);
	}

	ngOnChanges(): void {
		this.showMenu();
	}

	showMenu(): void {
		if (this.context === ProjectContext.ARCHIVE) {
			this.showThreeDotsMenu =
				this.isCurrentUserInternalAdminOfProject &&
				this.project?.projectType === this.projectType.PROJECT;
		} else {
			this.showThreeDotsMenu =
				this.isCurrentUserInternalAdminOfProject ||
				this.project?.projectType === this.projectType.PROJECT;
		}
	}

	isUserAllowedToChangeStatus(): boolean {
		let isActiveProject =
			this.context === ProjectContext.ACTIVE && this.project.projectType === ProjectType.PROJECT;
		let isRoleAllowedToUpdateStatus = [
			MemberRole.OWNER,
			MemberRole.SUPERVISOR,
			MemberRole.EMPLOYEE,
		].includes(this.userRole);
		return isActiveProject && isRoleAllowedToUpdateStatus;
	}
}
