<section class="w-100 pt-4 pb-3 mat-caption" *ngVar="filterBy$$ | async as filterBy">
	<span>
		{{ 'projectMembers.header.show' | translate }}:
		<a href="" [matMenuTriggerFor]="projectMembersFilter" class="text-primary font-weight-bold">
			<ng-container [ngSwitch]="filterBy">
				<ng-container *ngSwitchCase="ProjectMemberFilterBy.ALL">
					{{ 'projectMembers.header.filter.all' | translate }} ({{ noOfMembers$ | async }})
				</ng-container>
				<ng-container *ngSwitchCase="ProjectMemberFilterBy.INTERNAL">
					{{ 'projectMembers.header.filter.internalEmployees' | translate }} ({{ noOfInternalMembers$ | async }})
				</ng-container>
				<ng-container *ngSwitchCase="ProjectMemberFilterBy.EXTERNAL">
					{{ 'projectMembers.header.filter.externalEmployees' | translate }} ({{ noOfExternalMembers$ | async }})
				</ng-container>
			</ng-container>
		</a>
		<mat-menu #projectMembersFilter="matMenu" class="craftnote-menu">
			<button
				mat-menu-item
				[class.text-primary]="filterBy === ProjectMemberFilterBy.ALL"
				class="font-weight-bold px-4"
				(click)="filterBy$$.next(ProjectMemberFilterBy.ALL)"
			>
				{{ 'projectMembers.header.filter.all' | translate }} ({{ noOfMembers$ | async }})
			</button>
			<button
				mat-menu-item
				[class.text-primary]="filterBy === ProjectMemberFilterBy.INTERNAL"
				class="font-weight-bold px-4"
				(click)="filterBy$$.next(ProjectMemberFilterBy.INTERNAL)"
			>
				{{ 'projectMembers.header.filter.internalEmployees' | translate }} ({{ noOfInternalMembers$ | async }})
			</button>
			<button
				mat-menu-item
				[class.text-primary]="filterBy === ProjectMemberFilterBy.EXTERNAL"
				class="font-weight-bold px-4"
				(click)="filterBy$$.next(ProjectMemberFilterBy.EXTERNAL)"
			>
				{{ 'projectMembers.header.filter.externalEmployees' | translate }} ({{ noOfExternalMembers$ | async }})
			</button>
		</mat-menu>
	</span>
</section>
