import { Company, CompanyEmployee, CompanyLimit } from 'domain-entities';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { EntityStore } from '@store/entities/entity-store';

export enum CompanyChangeStatus {
	CLEAN,
	PENDING_CHANGES,
}

export interface CompanyState {
	company: Company | undefined | null;
	companyEmployees: EntityStore<CompanyEmployee>;
	companyChangeStatus: CompanyChangeStatus;
	companyLimits: CompanyLimit;
	loadingStatus: StoreLoadingStatus;
}
