<section class="project-members-drawer d-flex h-100 overflow-hidden flex-column position-absolute" [ngClass]="activeViewClass">
	<div class="main-view h-100">
		<app-project-member-list (openView)="openView($event)" (openMemberView)="openMemberDetails($event)"></app-project-member-list>
	</div>
	<div class="secondary-view h-100 position-absolute">
		<app-project-internal-member (openView)="openView($event)" *ngIf="viewOption === ProjectMemberDrawerSecondaryViewOptions.INTERNAL">
		</app-project-internal-member>
		<app-project-external-member (openView)="openView($event)" *ngIf="viewOption === ProjectMemberDrawerSecondaryViewOptions.EXTERNAL">
		</app-project-external-member>
		<app-project-member-details
			*ngIf="viewOption === ProjectMemberDrawerSecondaryViewOptions.MEMBER_DETAILS"
			[memberEmail]="currentMember.email"
			(openView)="openView($event)"
		></app-project-member-details>
	</div>
</section>
