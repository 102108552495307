import { Injectable } from '@angular/core';
import { CompanyMetadataConnector } from '@injectables/connectors/company-metadata.connector';
import { Observable } from 'rxjs';
import { CompanyMetaData } from 'domain-entities';

@Injectable({
	providedIn: 'root',
})
export class CompanyMetadataService {
	constructor(private readonly connector: CompanyMetadataConnector) {}

	getMetadata(companyId: string): Observable<CompanyMetaData> {
		return this.connector.getCompanyMetadata(companyId);
	}
}
