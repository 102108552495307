import { NgModule } from '@angular/core';
import { ProjectBillingAddressComponent } from './components/project-billing-address/project-billing-address.component';
import { ProjectEditComponent } from './project-edit.component';
import { ProjectNotesModule } from '@modules/shared/components/project-notes/project-notes.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ProjectAddEditContactFormComponent } from './components/project-contacts-list/project-add-edit-contact-form/project-add-edit-contact-form.component';
import { ProjectContactsListComponent } from './components/project-contacts-list/project-contacts-list.component';
import { AutofocusModule } from '@modules/shared/directives/autofocus.module';
import { MatIconSizeModule } from '@modules/shared/directives/mat-icon-size.module';
import { MemberRoleModule } from '@modules/shared/directives/member-role.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ColorPickerModule } from './components/color-picker/color-picker.module';

@NgModule({
	declarations: [
		ProjectEditComponent,
		ProjectBillingAddressComponent,
		ProjectAddEditContactFormComponent,
		ProjectContactsListComponent,
	],
	imports: [
		MatDatepickerModule,
		ProjectNotesModule,
		MatTooltipModule,
		AutofocusModule,
		MatIconSizeModule,
		MemberRoleModule,
		ReactiveFormsModule,
		SharedModule,
		MatFormFieldModule,
		MatIconModule,
		NgxTrimDirectiveModule,
		MatInputModule,
		MatButtonModule,
		ColorPickerModule,
	],
	exports: [ProjectEditComponent],
})
export class ProjectEditModule {}
