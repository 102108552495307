import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Project } from 'domain-entities';
import { differenceInDays, fromUnixTime } from 'date-fns';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject, timer } from 'rxjs';
import { chunk, difference, intersection, last } from 'lodash';
import { filter, map, takeUntil } from 'rxjs/operators';
import { createKeyMap } from '@shared/functions/utils/object.functions';
import {
	MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS,
	MatLegacyCheckboxChange,
	MatLegacyCheckboxDefaultOptions,
} from '@angular/material/legacy-checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WINDOW } from '@craftnote/shared-utils';
import { ScriptLoaderService } from '@injectables/services/script-loader.service';
import { ProjectService } from '@injectables/services/project/project.service';

interface ArchivationNudgeProject extends Project {
	daysSinceUsage: number;
}

@Component({
	selector: 'app-archivation-dialog',
	templateUrl: './project-archivation-dialog.component.html',
	styleUrls: ['./project-archivation-dialog.component.scss'],
	providers: [
		{
			provide: MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS,
			useValue: { clickAction: 'check' } as MatLegacyCheckboxDefaultOptions,
		},
	],
})
export class ProjectArchivationDialogComponent
	implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
	@ViewChild('wrapper', { read: ElementRef })
	private wrapperRef: ElementRef;
	private readonly matDialog = inject(MatDialogRef);
	private readonly projectService = inject(ProjectService);
	private readonly data = inject(MAT_DIALOG_DATA);
	private readonly window = inject(WINDOW);
	private readonly scriptLoaderService = inject(ScriptLoaderService);
	private readonly referenceTime = new Date();
	private showingLottie = false;
	private readonly destroy$$ = new Subject();
	private readonly containerSize$$ = new BehaviorSubject<number>(3);
	@ViewChild('lottiContainer', { read: ElementRef }) lottieContainer: ElementRef;
	showErrorHint = false;
	showSuccess = false;
	archivationInProgress$$ = new BehaviorSubject(false);
	isLottieLoaded$$ = new BehaviorSubject(false);
	readonly selectedProjects$$ = new BehaviorSubject<string[]>([]);

	selectedProjectsMap$ = this.selectedProjects$$.pipe(
		map((projects) => createKeyMap<string>((id) => id, projects)),
	);

	activeProjects$ = (this.data.projects$ as Observable<Project[]>).pipe(
		map((projects) => [...projects].sort((a, b) => a.lastEditedDate - b.lastEditedDate)),
		map((projects) =>
			projects.map(
				(project) =>
					({
						...project,
						daysSinceUsage: this.computeDaysSinceUsage(project),
					} as ArchivationNudgeProject),
			),
		),
		takeUntil(this.archivationInProgress$$.pipe(filter(Boolean))),
	);

	chunks$ = combineLatest([this.activeProjects$, this.containerSize$$]).pipe(
		map(([projects, containerSize]) => {
			const chunks = chunk(projects, containerSize);
			const lastRow = last(chunks);
			if (lastRow) {
				const missingProjects = containerSize - lastRow.length;
				for (let i = 0; i < missingProjects; i++) {
					lastRow.push(null);
				}
			}
			return chunks;
		}),
	);

	allSelected$ = combineLatest([this.activeProjects$, this.selectedProjects$$]).pipe(
		map(
			([projects, selectedProjects]) =>
				!!selectedProjects.length &&
				!difference(
					projects.map((project) => project.id),
					selectedProjects,
				).length,
		),
	);

	ngOnInit(): void {
		this.removeUnavailableProjects();
		this.loadLottieScript();
	}

	ngAfterViewInit(): void {
		this.observeWrapperWidth();
	}

	ngAfterViewChecked(): void {
		if (this.lottieContainer?.nativeElement && !this.showingLottie) {
			this.showingLottie = true;
			this.showLottie();
		}
	}

	ngOnDestroy(): void {
		this.destroy$$.next(null);
		this.destroy$$.complete();
	}

	async setAll(completed: MatLegacyCheckboxChange): Promise<void> {
		this.showErrorHint = false;
		if (completed.checked) {
			this.selectedProjects$$.next(
				(await firstValueFrom(this.activeProjects$)).map((project) => project.id),
			);
		} else {
			this.selectedProjects$$.next([]);
		}
	}

	selectProject(project: Project, selected: MatLegacyCheckboxChange): void {
		this.showErrorHint = false;

		if (selected.checked) {
			this.selectedProjects$$.next([...this.selectedProjects$$.value, project.id]);
		} else {
			this.selectedProjects$$.next(
				this.selectedProjects$$.value.filter((innerProject) => innerProject !== project.id),
			);
		}
	}

	async onArchive(): Promise<void> {
		const projectsToArchive = this.selectedProjects$$.value;
		if (!projectsToArchive.length) {
			this.showErrorHint = true;
			return;
		}
		const activeProjectsMap = createKeyMap(
			(project) => project.id,
			await firstValueFrom(this.activeProjects$),
		);

		this.archivationInProgress$$.next(true);

		const promises: Promise<void>[] = [];

		for (const projectId of projectsToArchive) {
			const project = activeProjectsMap[projectId];
			if (project) {
				promises.push(
					this.projectService.archiveProject(project, { requireApproval: false }).then(),
				);
			}
		}

		await Promise.all(promises);
		this.showSuccess = true;
		this.archivationInProgress$$.next(false);
		await firstValueFrom(timer(5000));
		this.matDialog.close('action-taken');
	}

	onClose(): void {
		this.matDialog.close('action-taken');
	}

	private computeDaysSinceUsage(project: Project): number {
		return differenceInDays(this.referenceTime, fromUnixTime(project.lastEditedDate));
	}

	private observeWrapperWidth(): void {
		const observer = new ResizeObserver((entries) => {
			const firstEntry = entries[0];
			if (firstEntry.contentRect.width > 1100) {
				this.containerSize$$.next(3);
			} else if (firstEntry.contentRect.width > 800) {
				this.containerSize$$.next(2);
			} else {
				this.containerSize$$.next(1);
			}
		});
		observer.observe(this.wrapperRef.nativeElement);
		this.destroy$$.subscribe(() => observer.disconnect());
	}

	private removeUnavailableProjects(): void {
		this.activeProjects$.pipe(takeUntil(this.destroy$$)).subscribe((projects) => {
			const currentSelectedProjects = this.selectedProjects$$.getValue();
			const availableProjects = projects.map((project) => project.id);
			const newSelectedProjects = intersection(currentSelectedProjects, availableProjects);
			this.selectedProjects$$.next(newSelectedProjects);
		});
	}

	private async loadLottieScript(): Promise<void> {
		await this.scriptLoaderService.loadScript(
			'lottie',
			'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.10.2/lottie.min.js',
		);
		this.isLottieLoaded$$.next(true);
	}

	private async showLottie(): Promise<void> {
		await firstValueFrom(this.isLottieLoaded$$.pipe(filter(Boolean)));
		(this.window as any).lottie.loadAnimation({
			container: this.lottieContainer.nativeElement,
			path: 'assets/animations/archivation-success-animation.json',
			renderer: 'svg',
			loop: false,
			autoplay: true,
			style: 'height: 20%',
		});
	}
}
