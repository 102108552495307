import {
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@injectables/guards/auth/craftnote-auth-guard';

export const redirectUnauthenticatedToLogin = (_next, state) =>
	redirectUnauthorizedTo([''], {
		queryParams: {
			redirectUrl: state.url,
		},
	});

export const redirectLoggedInToDefault = (next) => {
	return next.data.mobile ? redirectLoggedInTo(['checkout']) : redirectLoggedInTo(['projects']);
};
