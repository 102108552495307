import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import * as projectStatusesActions from '../actions/company-settings.actions';
import {
	delay,
	filter,
	first,
	map,
	mergeMap,
	retryWhen,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { selectCompanyId } from '../selectors/app.selectors';
import { of } from 'rxjs';
import { ProjectStatusService } from '@injectables/services/project-status/project-status.service';
import { retryOnError } from '@craftnote/shared-utils';

@Injectable()
export class CompanySettingsEffects implements OnInitEffects {
	loadProjectStatusesByCompany$ = createEffect(() =>
		this.actions$.pipe(
			ofType(projectStatusesActions.loadProjectStatusesByCompany),
			switchMap(() =>
				this.store.select(selectCompanyId).pipe(
					filter<string>(Boolean),
					switchMap((companyId) => {
						return this.projectStatusesService.getProjectStatus(companyId).pipe(retryOnError());
					}),
				),
			),
			map((projectStatuses) => projectStatusesActions.setProjectStatuses({ projectStatuses })),
		),
	);

	updateProjectStatuses$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(projectStatusesActions.updateProjectStatus),
				switchMap(async (action) => {
					const companyId = await this.store
						.select(selectCompanyId)
						.pipe(filter<string>(Boolean), first())
						.toPromise();
					return { companyId, action };
				}),
				tap((data) => {
					return this.projectStatusesService.setProjectStatus(
						data.companyId,
						data.action.projectStatuses,
					);
				}),
			),
		{ dispatch: false },
	);

	loadWorkTypesByCompany$ = createEffect(() =>
		this.actions$.pipe(
			ofType(projectStatusesActions.loadWorkTypesAction),
			switchMap(() =>
				this.store.select(selectCompanyId).pipe(
					mergeMap((companyId) => {
						if (!companyId) {
							return of([]);
						}
						return this.projectStatusesService.getWorkTypes(companyId).pipe(
							// Getting permission error on sign up
							retryWhen((errors) => errors.pipe(delay(1000), take(5))),
						);
					}),
				),
			),
			map((workTypes) => projectStatusesActions.updateManyWorkTypesAction({ workTypes })),
		),
	);

	initCompanySettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(projectStatusesActions.initCompanySettingsAction),
			mergeMap(() =>
				of(
					projectStatusesActions.loadProjectStatusesByCompany(),
					projectStatusesActions.loadWorkTypesAction(),
				),
			),
		),
	);

	constructor(
		private actions$: Actions,
		private readonly projectStatusesService: ProjectStatusService,
		private store: Store<AppState>,
	) {}

	ngrxOnInitEffects(): Action {
		return projectStatusesActions.initCompanySettingsAction();
	}
}
