import { AppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';

const selectSubscriptionState = (state: AppState) => state.subscriptionState;

export const selectIsTrialSubscription = createSelector(
	selectSubscriptionState,
	(state) => !!state?.trialSubscription,
);

export const selectCheckoutIsInProgress = createSelector(
	selectSubscriptionState,
	(state) => !!state?.checkoutInProgress,
);
