import { Inject, Injectable } from '@angular/core';
import { COMPANY_FILE_TYPE, CompanyTemplate } from '@shared/models/company-template.model';
import { FILE_TYPE, FileDocument } from '@craftnote/shared-utils';
import { FileType, TaskFile } from 'domain-entities';
import { PDF } from '@shared/constants/upload.types';
import { DOCUMENT } from '@angular/common';
import { DownlaodFileType } from '@globalTypes/interfaces/downloadFileType.model';
import { PublicFile } from '@injectables/services/public-folders/public-folders.service';

@Injectable({
	providedIn: 'root',
})
export class BaseFileService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	isFolder(template: CompanyTemplate | FileDocument): boolean {
		if (template instanceof CompanyTemplate) {
			return template.type === COMPANY_FILE_TYPE.FOLDER;
		}
		return template.type === FILE_TYPE.FOLDER;
	}

	getFileName(name): string {
		const types = name.split('.');
		return types.slice(0, -1).join('.');
	}

	getFileExtOrName(file: DownlaodFileType | File, isExt: boolean = true): string {
		const name = this.getFileName(file.name);

		return isExt ? file.name.replace(name + '.', '') : name;
	}

	clone(element: CompanyTemplate | FileDocument) {
		return JSON.parse(JSON.stringify(element));
	}

	fileSize(size) {
		return size < 500 * 1024 * 1024;
	}

	downloadFile(url, fileName): void {
		const xhr = new XMLHttpRequest();

		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.onload = (result) => {
			const urlCreator = window.URL;
			const el: any = result.srcElement;
			const imageUrl = urlCreator.createObjectURL(el.response);
			const tag = this.document.createElement('a');
			tag.href = imageUrl;
			tag.download = fileName;
			this.document.body.appendChild(tag);
			tag.click();
			this.document.body.removeChild(tag);
		};
		xhr.send();
	}

	checkFileType(type: string) {
		type = type.toUpperCase();
		switch (type) {
			case 'JPG':
			case 'JPEG':
			case 'PNG':
			case 'MPEG':
				return 'IMAGE';
			case 'MP4':
			case 'WMV':
			case 'AVI':
			case 'WEBM':
				return 'VIDEO';
			case '3GP':
			case 'MP3':
				return 'AUDIO';
			case 'PDF':
			case 'DOC':
			case 'DOCX':
			case 'CSV':
			case 'XLSM':
			case 'XLSX':
			case 'TXT':
			case 'RTV':
			case 'PAGES':
			case 'ODT':
			case 'ODS':
			case 'ODP':
			case 'XLS':
			case 'KEY':
			case 'NUMBERS':
			case 'DXF':
			case 'ZIP':
			case 'PPT':
			case 'PPTX':
			case 'MOVE':
				return 'DOCUMENT';
			default:
				return 'DOCUMENT';
		}
	}

	isPreviewFileType(taskFile: TaskFile | PublicFile): boolean {
		const fileType = taskFile.type;
		const name = taskFile.name;
		return (
			fileType === FileType.IMAGE ||
			fileType === FileType.VIDEO ||
			(fileType === FileType.DOCUMENT && this.getFileExtension(name) === PDF)
		);
	}

	getFileExtension(name, isTransformed: boolean = true): string {
		const types = name.split('.');

		if (!isTransformed) {
			return types[types.length - 1];
		}

		return types[types.length - 1].toLowerCase();
	}

	getFilenameWithLowercaseExtension(name: string): string {
		return this.getFileName(name) + '.' + this.getFileExtension(name);
	}

	/**
	 * This method assumes that there are no slashes in the filename
	 */
	getFilenameWithExtensionFromPath(path: string): string {
		const split = path.split('/');
		return split[split.length - 1];
	}
}
