<div class="manage-access-title" mat-dialog-title>
	<div class="d-flex justify-content-end">
		<mat-icon class="position-relative close-button" mat-dialog-close data-cy="add-status-close">close</mat-icon>
	</div>
	<div class="text-left" data-cy="update-status-header">
		{{ 'settings.projectStatus.dialogs.update.title' | translate }} - <span class="font-weight-normal"> {{ data.projectName }}</span>
	</div>
</div>
<mat-dialog-content>
	<div class="row">
		<mat-form-field class="col-12">
			<mat-label>{{ 'settings.projectStatus.dialogs.update.select' | translate }}</mat-label>
			<mat-select [(value)]="selectedProjectStatusId" data-cy="status-options-dropdown">
				<mat-option [value]="noProjectStatus">{{ 'settings.projectStatus.dialogs.update.no-status' | translate }}</mat-option>
				<mat-option
					*ngFor="let status of data.statuses$ | async; let i = index"
					[value]="status.id"
					[attr.data-cy]="'project-option-' + status.name"
				>
					{{ status.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</mat-dialog-content>

<div class="project-status-update-hint" *appMemberRole="[MemberRole.SUPERVISOR, MemberRole.OWNER]">
	<div class="p-3 mb-3">
		{{ 'settings.projectStatus.dialogs.update.hint.info' | translate }}:
		<span
			class="font-weight-normal"
			[innerHTML]="'settings.projectStatus.dialogs.update.hint.text' | translate: { link: '/settings/project-status' }"
		></span>
	</div>
</div>

<mat-dialog-actions>
	<div class="row no-gutters mt-2 w-100">
		<div class="col text-right">
			<button [mat-dialog-close]="selectedProjectStatusId" color="accent" mat-flat-button data-cy="project-status-update">
				{{ 'settings.projectStatus.dialogs.update.btn' | translate }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
