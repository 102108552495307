import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';

@Component({
	selector: 'app-basic-user-subscription-dialog',
	templateUrl: './basic-user-subscription-dialog.component.html',
	styleUrls: ['./basic-user-subscription-dialog.component.scss'],
})
export class BasicUserSubscriptionDialogComponent {
	isOwner$ = this.store.select(isOwnerOfCompany);

	constructor(
		private readonly store: Store<AppState>,
		@Inject(MAT_DIALOG_DATA)
		public readonly data: { heading: string; points: string[]; upgradeButtonText: string },
	) {
		//
	}
}
