import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bytes',
})
export class BytesPipe implements PipeTransform {
	transform(bytes: number, decimals = 2): string {
		if (!bytes || bytes === 0) {
			return 'NA';
		}

		const bits = 1024;
		const maxDecimals = Math.max(0, decimals);
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const sizeIndex = Math.floor(Math.log(bytes) / Math.log(bits));

		return (
			parseFloat((bytes / Math.pow(bits, sizeIndex)).toFixed(maxDecimals)) + ' ' + sizes[sizeIndex]
		);
	}
}
