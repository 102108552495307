import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
	declarations: [],
	imports: [SharedModule, MatIconModule],
	exports: [MatIconModule],
})
export class CustomMatIconsModule {
	constructor(
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
	) {
		this.registerIcons();
	}

	private registerIcons(): void {
		const icons = [
			{
				name: 'custom-smartphone',
				fileName: 'custom-smartphone',
			},
			{
				name: 'custom-mail',
				fileName: 'custom-mail',
			},
			{
				name: 'custom-lock',
				fileName: 'custom-lock',
			},
			{
				name: 'custom-eye',
				fileName: 'custom-eye',
			},
			{
				name: 'banner-devices',
				fileName: 'custom-banner-devices',
			},
			{
				name: 'banner-security',
				fileName: 'custom-banner-security',
			},
			{
				name: 'banner-support',
				fileName: 'custom-banner-support',
			},
			{
				name: 'banner-timer',
				fileName: 'custom-banner-timer',
			},
			{
				name: 'email-verified',
				fileName: 'cutom-email-verified',
			},
			{
				name: 'company-logo',
				fileName: 'company-logo',
			},
			{
				name: 'header-gift',
				fileName: 'custom-gift',
			},
			{
				name: 'header-bell',
				fileName: 'custom-bell',
			},
			{
				name: 'sidebar-dashboard',
				fileName: 'custom-dashboard',
			},
			{
				name: 'sidebar-project',
				fileName: 'custom-project',
			},
			{
				name: 'sidebar-calendar',
				fileName: 'custom-calendar',
			},
			{
				name: 'message-label',
				fileName: 'custom-label',
			},
			{
				name: 'empty-folder',
				fileName: 'custom-empty-file',
			},
			{
				name: 'files-folder',
				fileName: 'custom-files-folder',
			},
			{
				name: 'files-folder-shared',
				fileName: 'custom-files-shared',
			},
			{
				name: 'files-folder-company',
				fileName: 'custom-files-company',
			},
			{
				name: 'files-folder-dark',
				fileName: 'custom-files-folder-dark',
			},
			{
				name: 'files-folder-shared-dark',
				fileName: 'custom-files-shared-dark',
			},
			{
				name: 'files-folder-company-dark',
				fileName: 'custom-files-company-dark',
			},
			{
				name: 'logout',
				fileName: 'custom-logout',
			},
			{
				name: 'files-new-folder',
				fileName: 'custom-newfolder',
			},
			{
				name: 'drag-drop-restricted',
				fileName: 'custom-drag-drop-restricted',
			},
			{
				name: 'external-chat',
				fileName: 'custom-partner',
			},
			{
				name: 'schedule-demo',
				fileName: 'schedule-demo',
			},
			{
				name: 'create',
				fileName: 'custom-create',
			},
			{
				name: 'move',
				fileName: 'custom-move',
			},
			{
				name: 'lock-open',
				fileName: 'custom-lock-open',
			},
			{
				name: 'reply',
				fileName: 'custom-reply',
			},
			{
				name: 'delete',
				fileName: 'custom-delete',
			},
			{
				name: 'done',
				fileName: 'custom-done',
			},
			{
				name: 'apple-brand',
				fileName: 'apple-brand',
			},
			{
				name: 'google-brand',
				fileName: 'google-brand',
			},
			{
				name: 'facebook-brand',
				fileName: 'facebook-brand',
			},
			{
				name: 'house',
				fileName: 'custom-house',
			},
			{
				name: 'account-avatar',
				fileName: 'custom-account-avatar',
			},
			{
				name: 'project-note-last-updated',
				fileName: 'add-notes-last-updated',
			},
			{
				name: 'custome-home',
				fileName: 'home',
			},
			{
				name: 'sort-ascending',
				fileName: 'sort-ascending',
			},
			{
				name: 'sort-descending',
				fileName: 'sort-descending',
			},
			{
				name: 'question_mark',
				fileName: 'question_mark',
			},
			{
				name: 'custom-employees',
				fileName: 'custom-employees',
			},
			{
				name: 'custom-status',
				fileName: 'custom-status',
			},
			{
				name: 'custom-start-date',
				fileName: 'custom-start-date',
			},
			{
				name: 'custom-end-date',
				fileName: 'custom-end-date',
			},
			{
				name: 'custom-folder',
				fileName: 'custom-folder',
			},
			{
				name: 'custom-sort',
				fileName: 'custom-sort',
			},
			{
				name: 'custom-live-time-tracking-enabled',
				fileName: 'livetracking-enabled',
			},
		];

		icons.forEach((icon) => this.addIcon(icon.name, icon.fileName));
	}

	private addIcon(iconName: string, fileName: string): void {
		this.matIconRegistry.addSvgIcon(
			iconName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`../../../../assets/images/mat-icons/${fileName}.svg`,
			),
		);
	}
}
