<section class="work-type-list-section flex-scroll">
	<div [class.d-none]="editWorkTimeVisible" class="flex-scroll__container w-100">
		<div class="d-flex align-items-center mb-3">
			<div class="d-flex align-items-center">
				<button (click)="closePanel()" class="mr-3" mat-icon-button>
					<mat-icon class="close-btn-icon" data-cy="back-to-time-entry-details">arrow_back</mat-icon>
				</button>

				<div class="m-0 d-inline-flex mat-headline font-weight-bold" data-cy="work-type-details">
					{{ 'work-type-list-panel.heading' | translate }}
				</div>
			</div>

			<div class="ml-auto d-flex align-items-center">
				<button
					*ngIf="isOwnerOrSupervisorOfCompany$ | async"
					(click)="openEditWorkTime()"
					color="accent"
					mat-flat-button
					data-cy="open-edit-work-types"
				>
					{{ 'work-type-list-panel.edit-work-type-btn' | translate }}
				</button>
			</div>
		</div>

		<div class="flex-scroll__container__scrollable-content">
			<mat-radio-group
				(change)="selectNewWorkType($event.value)"
				[value]="trackedTimesWorkTypeButtonService.getSelectedWorkTypeId()"
				class="d-flex flex-column"
			>
				<mat-radio-button *ngFor="let wt of workTypeList$ | async" [value]="wt.id" class="py-2 border-bottom">
					{{ wt.name }}
				</mat-radio-button>
			</mat-radio-group>

			<div class="hint-text rounded p-2 my-2">
				<span class="font-weight-bold" data-cy="hint-text-time-entry">{{ 'work-type-list-panel.hint-text-bold' | translate }}: </span>
				{{ 'work-type-list-panel.hint-text' | translate }}
			</div>
		</div>
	</div>

	<div [class.d-none]="!editWorkTimeVisible" class="flex-scroll__container">
		<div class="d-flex align-items-center mb-3">
			<div class="d-flex align-items-center">
				<button (click)="closeEditWorkTime()" class="mr-3" mat-icon-button>
					<mat-icon class="close-btn-icon" data-cy="close-edit-work-types">close</mat-icon>
				</button>

				<div class="m-0 d-inline-flex mat-headline font-weight-bold">{{ 'work-type-list-panel.add-work-type-heading' | translate }}</div>
			</div>
		</div>
		<form>
			<mat-form-field appearance="outline" class="col-12 mt-3 p-0" hideRequiredMarker="true">
				<mat-label>{{ 'work-type-list-panel.add-work-type-input' | translate }}</mat-label>
				<input #workTypeInputRef matInput name="name" required type="text" data-cy="enter-new-work-type" />
			</mat-form-field>
			<button
				(click)="addNewWorkType(workTypeInputRef)"
				[disabled]="!workTypeInputRef.value"
				class="w-100"
				color="accent"
				mat-flat-button
				data-cy="save-new-work-type"
			>
				{{ 'work-type-list-panel.add-work-type-btn' | translate }}
			</button>
		</form>

		<ul class="list-unstyled mt-4 flex-scroll__container__scrollable-content">
			<li *ngFor="let wt of workTypeList$ | async" class="border-bottom list-item">
				<div class="align-items-center d-flex w-100">
					<span>{{ wt.name }}</span>
					<div class="ml-auto">
						<ng-container *ngIf="!(isEmployee$ | async)">
							<button
								(click)="removeWorkType(wt.id)"
								*ngIf="wt.editable && trackedTimesWorkTypeButtonService.getSelectedWorkTypeId() !== wt.id"
								mat-icon-button
							>
								<mat-icon data-cy="delete-work-type">delete</mat-icon>
							</button>
						</ng-container>
						<button *ngIf="trackedTimesWorkTypeButtonService.getSelectedWorkTypeId() === wt.id" mat-icon-button>
							<mat-icon>done</mat-icon>
						</button>
					</div>
				</div>
			</li>
		</ul>
	</div>
</section>
