import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';

@Directive({
	selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
	@Input('appFeatureFlag') featureFlagName: string;

	private featureIsActive: boolean;

	constructor(
		private remote: RemoteConfig,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this.featureIsActive = !!this.remote.getBoolean(this.featureFlagName);
		this.updateView();
	}

	private updateView(): void {
		this.viewContainerRef.clear();
		if (this.featureIsActive) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
			return;
		}
	}
}
