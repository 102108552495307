import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from 'domain-entities';
import { DataQueryCondition, EntityChanges } from '@craftnote/shared-utils';

@Injectable({
	providedIn: 'root',
})
export class TasksDeletedConnector {
	static readonly COLLECTION_NAME = 'tasksDeleted';

	constructor(private readonly connector: FirestoreConnector) {}

	watchDeletedCompanyTasks(companyId: string, startAfter?: number): Observable<Task[]> {
		const conditions: DataQueryCondition<Task>[] = [
			{
				field: 'companyId',
				operator: '==',
				value: companyId,
			},
		];
		if (startAfter) {
			conditions.push({
				field: 'status.setAt' as any,
				operator: '>=',
				value: startAfter,
			});
		}
		return this.connector.watchDocuments<Task>(TasksDeletedConnector.COLLECTION_NAME, conditions);
	}

	watchDeletedCompanyTasksEntityChanges(
		companyId: string,
		startAfter?: number,
	): Observable<EntityChanges<Task>> {
		const conditions: DataQueryCondition<Task>[] = [
			{
				field: 'companyId',
				operator: '==',
				value: companyId,
			},
		];
		if (startAfter) {
			conditions.push({
				field: 'status.setAt' as any,
				operator: '>=',
				value: startAfter,
			});
		}
		return this.connector.watchDocumentsChanges<Task>(
			TasksDeletedConnector.COLLECTION_NAME,
			conditions,
		);
	}
}
