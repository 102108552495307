import { Injectable } from '@angular/core';
import { UpgradesPaywallheaderExploreSelectedEventBuilder } from '@generated/events/UpgradesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { PaywallHeaderDialogComponent } from '@modules/shared/components/paywall-header-dialog/paywall-header-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class PaywallHeaderDialogService {
	paywallHeaderDialogComponent: PaywallHeaderDialogComponent = null;

	constructor(private readonly trackingService: TrackingService) {}

	registerComponent(component: PaywallHeaderDialogComponent): void {
		this.paywallHeaderDialogComponent = component;
	}

	async show(): Promise<void> {
		await this.trackingService.trackEvent(new UpgradesPaywallheaderExploreSelectedEventBuilder({}));
		await this.paywallHeaderDialogComponent.open();
	}
}
