import { Contact as ContactEntity, Member, Project } from 'domain-entities';
import moment from 'moment';
import { Contact } from '../../models/contact.model';
import { isEqual } from 'lodash';

const MAP_PREFIXES: string[] = ['membersLastOpened'];

export function updateLastActiveFactory(
	currentUserId: string,
): (oldProject: Project) => Partial<Project> | undefined {
	return function (oldProject: Project): Partial<Project> | undefined {
		const updateObject: Partial<Project> = {};
		const now = moment().unix();

		updateObject.lastOpenedDate = now;

		const mapValActive =
			oldProject.membersLastOpened && oldProject.membersLastOpened[currentUserId];
		const mapValArchived =
			oldProject.membersLastOpenedArchived && oldProject.membersLastOpenedArchived[currentUserId];

		const currentMapVal = mapValActive || mapValArchived;

		if (currentMapVal && currentMapVal !== now) {
			const mapToUpdate = mapValActive
				? { ...oldProject.membersLastOpened }
				: { ...oldProject.membersLastOpenedArchived };
			mapToUpdate[currentUserId] = now;

			if (mapValActive) {
				updateObject.membersLastOpened = mapToUpdate;
			} else {
				updateObject.membersLastOpenedArchived = mapToUpdate;
			}
		}

		return updateObject;
	};
}

export function addMemberToProjectFactory(
	member: Member | Contact,
): (oldProject: Project) => Partial<Project> {
	return (project: Project) => {
		let memberForUpdate: Member;
		if (member instanceof Contact) {
			memberForUpdate = (member as Contact).toDomainEntityMember();
		} else {
			memberForUpdate = member as Member;
		}
		memberForUpdate = { ...memberForUpdate, email: memberForUpdate.email.toLowerCase() };
		const updatedProject: Partial<Project> = { members: { ...project.members } };
		updatedProject.members[memberForUpdate.email] = memberForUpdate;
		return updatedProject;
	};
}

export function updateMemberInProjectFactory(
	member: Member,
): (oldProject: Project) => Partial<Project> {
	// There is no difference between adding or replacing a member entry
	return addMemberToProjectFactory(member);
}

export function removeMembersFromProjectFactory(
	members: Member[],
): (oldProject: Project) => Partial<Project> {
	return (project: Project) => {
		const updatedProject: Partial<Project> = {};
		if (project.members) {
			updatedProject['members'] = { ...project['members'] };
			members.forEach(({ email }) => {
				delete updatedProject.members[email];
			});
		}
		for (const prefix of MAP_PREFIXES) {
			if (project[prefix]) {
				updatedProject[prefix] = { ...project[prefix] };
				members.forEach(({ email }) => {
					delete updatedProject[prefix][email];
				});
			}
			const archivedPrefix = `${prefix}Archived`;
			if (project[archivedPrefix]) {
				updatedProject[archivedPrefix] = { ...project[archivedPrefix] };
				members.forEach(({ email }) => {
					delete updatedProject[archivedPrefix][email];
				});
			}
		}
		return updatedProject;
	};
}

export function addContactToProjectFactory(
	contact: ContactEntity,
): (oldProject: Project) => Partial<Project> {
	return (project: Project) => {
		const existingContacts = project.contacts || [];
		const updatedContacts = existingContacts.filter(
			(contactInProject) => !isEqual(contactInProject, contact),
		);
		updatedContacts.push(contact);
		return { contacts: updatedContacts };
	};
}

export function removeContactFromProjectFactory(
	contact: ContactEntity,
): (oldProject: Project) => Partial<Project> {
	return (project: Project) => {
		if (!project.contacts) {
			return undefined;
		}
		const updatedContacts = project.contacts.filter(
			(contactInProject) => !isEqual(contactInProject, contact),
		);
		return { contacts: updatedContacts };
	};
}

export function updateContactAddIndex(
	index: number,
	contact: ContactEntity,
): (oldProject: Project) => Partial<Project> {
	return (project: Project) => {
		const updatedProject: Partial<Project> = { contacts: [...(project.contacts || [])] };
		updatedProject.contacts[index] = contact;
		return updatedProject;
	};
}
