import { Injectable } from '@angular/core';
import {
	BotInfo,
	BrowserInfo,
	detect,
	NodeInfo,
	ReactNativeInfo,
	SearchBotDeviceInfo,
} from 'detect-browser';
import compareVersions from 'compare-versions';

export type Browser =
	| BrowserInfo
	| SearchBotDeviceInfo
	| BotInfo
	| NodeInfo
	| ReactNativeInfo
	| null;

const compatibleBrowserVersionsOfCustomElements: { [browserName: string]: string } = {
	chrome: '54',
	edge: '79',
	firefox: '63',
	opera: '41',
	ie: '12',
	fxios: '68',
	android: '81',
	ios: '10.3',
	'edge-chromium': '79',
	crios: '100',
};

@Injectable({
	providedIn: 'root',
})
export class BrowserDetectionService {
	private readonly browser: Browser;

	constructor() {
		this.browser = detect();
	}

	isCompatibleWithCustomElements(): boolean {
		if (!this.browser) {
			return true;
		}
		const browserVersion = compatibleBrowserVersionsOfCustomElements[this.browser.name];

		return browserVersion ? compareVersions(this.browser.version, browserVersion) !== -1 : false;
	}

	getBrowserInfo(): Browser {
		return this.browser;
	}

	isFirefoxBrowser(): boolean {
		const browserInfo = this.getBrowserInfo();
		return browserInfo?.type === 'browser' && browserInfo?.name === 'firefox';
	}

	getBrowserName(): string {
		const browserInfo = this.getBrowserInfo();
		return browserInfo?.type === 'browser' && browserInfo?.name;
	}
}
