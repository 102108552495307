import { Action, createReducer, on } from '@ngrx/store';
import * as externalChatActions from '../actions/external-chat.actions';
import { ExternalChatState } from '../state/external-chat.state';

const externalChatState: ExternalChatState = {
	userState: undefined,
};

const externalChatReducerCreator = createReducer(
	externalChatState,
	on(externalChatActions.setUserStateAction, (state, { userState }) => ({
		...state,
		userState: userState,
	})),
	on(externalChatActions.deleteUserStateAction, (state) => ({
		...state,
		userState: undefined,
	})),
	on(externalChatActions.clearUserStateAction, () => {
		return { userState: undefined };
	}),
);

// tslint:disable-next-line:typedef
export function externalChatReducer(state: ExternalChatState, action: Action) {
	return externalChatReducerCreator(state, action);
}
