<section class="time-picker-section d-flex">
	<input
		type="text"
		pattern="^(\d|([0-1]\d)|(1\d)|2[0-3])$"
		attributes="gm"
		appPatternRestrict
		[class.text-muted]="disabled"
		[disabled]="disabled"
		[value]="value | hoursFromDate | padStart: 2:'0'"
		(change)="onHourInputChange(hoursInputRef.value)"
		(keydown.arrowUp)="incrementHour($event)"
		(keydown.arrowDown)="decrementHour($event)"
		#hoursInputRef
		(focus)="lastFocusedInput = 'hours-input'"
	/>
	<span class="colon-divider">:</span>
	<input
		type="text"
		pattern="^([0-5]?[0-9]+)$"
		attributes="gm"
		appPatternRestrict
		[class.text-muted]="disabled"
		[disabled]="disabled"
		[value]="value | minutesFromDate | padStart: 2:'0'"
		(keydown.arrowUp)="incrementMinute($event)"
		(keydown.arrowDown)="decrementMinute($event)"
		#minutesInputRef
		(change)="onMinutesInputChange(minutesInputRef.value)"
		(focus)="lastFocusedInput = 'minutes-input'"
	/>
	<div class="d-flex flex-column counter-buttons-wrapper">
		<mat-icon class="increment-button" color="accent" [mtMatIconDisabled]="disabled">expand_less</mat-icon>
		<mat-icon class="decrement-button" color="accent" [mtMatIconDisabled]="disabled">expand_more</mat-icon>
	</div>
</section>
