import { TemplateRef } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

export type SharedDialogElement =
	| SharedSliderDialogToggleSlide
	| SharedSliderDialogTextSlide
	| SharedSliderDialogSelectionSlide
	| SharedSliderDialogFormSlide
	| SharedSliderDialogCustomViewSlide;

export type SharedSliderDialogData = Array<SharedDialogElement>;

interface SharedSliderDialogSlide {
	type: SharedSliderDialogSideType;
	titleKey?: string;
	subtitleKey?: string;
	actionKey?: string;
}

export interface SharedSliderDialogToggleSlide extends SharedSliderDialogSlide {
	type: SharedSliderDialogSideType.TOGGLE;
	toggles?: SharedSliderDialogToggleOption[];
}

export interface SharedSliderDialogTextSlide extends SharedSliderDialogSlide {
	type: SharedSliderDialogSideType.TEXT;
	labelKey: string;
	value?: string;
	controlName?: string;
	validation?: {
		validators: ValidatorFn[];
		errorKeysByError: { [keys: string]: string };
	};
}

export interface SharedSliderDialogSelectionSlide extends SharedSliderDialogSlide {
	type: SharedSliderDialogSideType.SELECTION;
	labelKey: string;
	selectionOptions?: string[];
}

export interface SharedSliderDialogCustomViewSlide extends SharedSliderDialogSlide {
	type: SharedSliderDialogSideType.CUSTOM_VIEW;
	titleKey: string;
	customViewTemplateRef?: TemplateRef<any>;
}

export interface SharedSliderDialogFormSlide extends SharedSliderDialogSlide {
	type: SharedSliderDialogSideType.FORM;
	titleKey: string;
	actionKey: string;
	controls: SharedSliderDialogTextSlide[];
}

export interface SharedSliderDialogToggleOption {
	imageSource: string;
	caption: string;
}

export enum SharedSliderDialogSideType {
	TOGGLE,
	SELECTION,
	TEXT,
	FORM,
	CUSTOM_VIEW,
	FORM_CONTROL,
}
