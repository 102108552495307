import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Injectable, ViewRef } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { closeExportModalAction, exportEffectsInitActions } from '../actions/export.actions';
import { pairwise, switchMapTo, tap } from 'rxjs/operators';
import { selectExports } from '../selectors/export.selectors';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { ExportSnackbarWrapperComponent } from '@modules/shared/components/notification-snackbar/export-snackbar/export-snackbar-wrapper/export-snackbar-wrapper.component';
import { isRunning } from '@shared/firebase/export/export.functions';
import { ExportService } from '@injectables/export/export.service';

@Injectable()
export class ExportEffect implements OnInitEffects {
	private notificationViewRef: ViewRef;

	constructor(
		private readonly actions: Actions,
		private readonly store: Store,
		private readonly notificationSnackbarService: NotificationSnackbarService,
		private readonly exportService: ExportService,
	) {}

	openExportModalEffect = createEffect(
		() =>
			this.actions.pipe(
				ofType(exportEffectsInitActions),
				switchMapTo(this.store.select(selectExports)),
				tap((exports) => {
					const exportsAreRunning = exports.some(isRunning);

					if (this.notificationViewRef || !exportsAreRunning) {
						return;
					}

					this.notificationViewRef = this.notificationSnackbarService.show(
						ExportSnackbarWrapperComponent,
						{ timeout: null },
					);
					return;
				}),
			),
		{ dispatch: false },
	);

	downloadCompletedExportEffect = createEffect(
		() =>
			this.actions.pipe(
				ofType(exportEffectsInitActions),
				switchMapTo(this.store.select(selectExports)),
				pairwise(),
				tap(([prevExports, newExports]) => {
					const previouslyRunning = prevExports.filter(isRunning);
					const currentlyCompleted = newExports.filter(
						(exportEntity) => exportEntity.state === 'complete',
					);
					const exportsToBeDownloaded = currentlyCompleted.filter((exportEntity) =>
						previouslyRunning.some((prevRunning) => prevRunning.id === exportEntity.id),
					);
					exportsToBeDownloaded.forEach((exp) => this.exportService.downloadExport(exp));
				}),
			),
		{ dispatch: false },
	);

	closeExportModalEffect = createEffect(
		() =>
			this.actions.pipe(
				ofType(closeExportModalAction),
				tap(() => {
					if (this.notificationViewRef) {
						this.notificationSnackbarService.hide(this.notificationViewRef);
						this.notificationViewRef = null;
					}
				}),
			),
		{ dispatch: false },
	);

	ngrxOnInitEffects(): Action {
		return exportEffectsInitActions();
	}
}
