import { createAction, props } from '@ngrx/store';
import { Profile, ProfileLimit } from 'domain-entities';
import { AuthLifecycle, UserRegistrationType } from '../state/auth.state';

export const updateUserStateAction = createAction(
	'[Auth] Login',
	props<{
		userId: string | undefined;
		emailVerified: boolean | undefined;
		email: string | undefined;
	}>(),
);

export const updateCurrentProfileAction = createAction(
	'[Auth] Update Auth State',
	props<{ user: Profile | undefined; userId: string | undefined; lifeCycle: AuthLifecycle }>(),
);

export const resendVerificationAction = createAction('[Auth] Resend Verification');

export const checkEmailVerificationStatusAction = createAction(
	'[Auth] Check Email Verification Status',
);

export const sendEmailVerificationLink = createAction('[Auth] Send email verification link');

export const initAuthChangeSubscriptionAction = createAction('[Auth] Auth change subscription');

export const setProfileLimits = createAction(
	'[Auth] Set profile limits',
	props<{ profileLimits: ProfileLimit }>(),
);

export const setUserRegistrationType = createAction(
	'[Auth] Set User registration type',
	props<{ registrationType: UserRegistrationType }>(),
);
