import moment from 'moment';

export function hasValue(value: string | number | null | undefined): boolean {
	return value !== null && value !== '' && value !== undefined;
}

export function is48HoursPassed(timestamp: number): boolean {
	return moment().diff(moment.unix(timestamp), 'hours') >= 48;
}

export function formatTimeToHHMM(duration: number): { hr: string; min: string } {
	if (duration <= 0) {
		return {
			hr: '00',
			min: '00',
		};
	}
	const hr = Math.floor(duration / 3600);
	const min = Math.floor((duration % 3600) / 60);
	return {
		hr: hr < 10 ? `0${hr}` : hr.toString(),
		min: min < 10 ? `0${min}` : min.toString(),
	};
}
