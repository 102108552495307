import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityChanges } from '@craftnote/shared-utils';
import { TaskComment, TaskFile, TaskTime } from 'domain-entities';
import { TaskTimeConnector } from '@shared/firebase/connectors/firestore/collections/task-time/task-time.connector';
import { TaskCommentsConnector } from '@shared/firebase/connectors/firestore/collections/task-comments/task-comments.connector';
import { TaskFilesConnector } from '@shared/firebase/connectors/firestore/collections/task-files/task-files.connector';
import { TasksService } from '@injectables/services/tasks/tasks.service';

@Injectable({ providedIn: 'root' })
export class TaskEditDetailsService {
	constructor(
		public readonly taskTimeConnector: TaskTimeConnector,
		public readonly taskCommentsConnector: TaskCommentsConnector,
		public readonly tasksService: TasksService,
		public readonly taskFilesConnector: TaskFilesConnector,
	) {}

	getTaskTimesById(taskId: string): Observable<EntityChanges<TaskTime>> {
		return this.taskTimeConnector.watchTasksTimesOfTask(taskId);
	}

	getTaskComments(taskId: string): Observable<TaskComment[]> {
		return this.taskCommentsConnector.getTaskComments(taskId);
	}

	getTaskFiles(id: string): Observable<TaskFile[]> {
		return this.taskFilesConnector.getTaskFiles(id);
	}
}
