import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	Renderer2,
} from '@angular/core';

@Directive({
	selector: '[appDnd]',
})
export class DndDirective {
	@Input() isFileActive: boolean;
	@Output() private filesChanged: EventEmitter<FileList> = new EventEmitter();
	@Output() private dataTransfer: EventEmitter<DataTransfer> = new EventEmitter();

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('dragover', ['$event'])
	onDragOver($event): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.showDropZone();
	}

	@HostListener('dragleave')
	onDragLeave(): void {
		this.renderer.removeClass(this.el.nativeElement, 'dragover');
	}

	@HostListener('drop', ['$event'])
	onDrop($event): boolean {
		this.triggerFileDropEvents($event);
		return false;
	}

	private showDropZone(): boolean {
		if (this.isFileActive && this.el.nativeElement.closest('.chat-drag-drop')) {
			return false;
		}
		this.renderer.addClass(this.el.nativeElement, 'dragover');
	}

	private triggerFileDropEvents($event): void {
		if (this.el.nativeElement.classList.contains('dragover')) {
			this.renderer.removeClass(this.el.nativeElement, 'dragover');
		}

		if ($event.dataTransfer.files.length > 0) {
			this.filesChanged.emit($event.dataTransfer.files);
		}
		this.dataTransfer.emit($event.dataTransfer);
	}
}
