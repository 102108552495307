import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectNotesDialogComponent } from './project-notes-modal/project-notes-dialog.component';
import { Message, MessageType, Project } from 'domain-entities';
import { Store } from '@ngrx/store';
import {
	isEmployeeOfCompany,
	selectUserFullName,
	selectUserId,
} from '@store/selectors/app.selectors';
import { ProjectType } from '@shared/models/project.type';
import { ProjectService } from '@injectables/services/project/project.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AlertService } from '@injectables/services/alert/alert.service';
import { ChatService } from '@injectables/services/chat/chat.service';
import { take } from 'rxjs/operators';
import { isNil } from 'lodash';
import { TrackingService } from '@injectables/services/tracking.service';
import { ProjectsProjectNoteUpdatedEventBuilder } from '@generated/events/ProjectsEvents.generated';

@Component({
	selector: 'app-project-notes[projectId]',
	templateUrl: './project-notes.component.html',
	styleUrls: ['./project-notes.component.scss'],
})
export class ProjectNotesComponent {
	@Input() projectId: string = null;
	@Output() note = new EventEmitter<string>();

	project: Project = null;

	constructor(
		private readonly dialog: MatDialog,
		private readonly store: Store,
		private readonly projectService: ProjectService,
		private readonly database: AngularFireDatabase,
		private readonly alertService: AlertService,
		private readonly chatService: ChatService,
		private readonly trackingService: TrackingService,
	) {}

	private async updateProjectNote(newNote: string): Promise<void> {
		const noteAuthor = await this.store.select(selectUserFullName).pipe(take(1)).toPromise();
		const authorId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const timestamp = Math.floor(new Date().getTime() / 1000);
		const updatedProject: Partial<Project> = {};
		updatedProject.noteAuthor = noteAuthor;
		updatedProject.noteContent = newNote;
		updatedProject.noteTimestamp = timestamp;

		await this.projectService.updateProjectPartial(this.projectId, updatedProject);
		await this.trackingService.trackEvent(
			new ProjectsProjectNoteUpdatedEventBuilder({
				projectId: this.project.id,
			}),
		);

		if (this.project.projectType === ProjectType.PROJECT) {
			const id = this.database.createPushId();
			const message: Message = {
				id,
				projectId: this.project.id,
				timestamp,
				authorId,
				author: noteAuthor,
				messageType: MessageType.STATUS,
				content: newNote,
			};

			await this.chatService.sendMessage(this.project.id, message).then(() => {
				this.alertService.showAlert('information.changed');
			});
		} else {
			this.alertService.showAlert('information.changed');
		}
		this.note.emit(newNote);
	}

	async addProjectNotes(): Promise<void> {
		this.project = await this.projectService.getProject(this.projectId).pipe(take(1)).toPromise();
		const isEmployee = await this.store.select(isEmployeeOfCompany).pipe(take(1)).toPromise();
		const oldNoteContent = this.project.noteContent;
		const newNote = await this.dialog
			.open(ProjectNotesDialogComponent, {
				width: '49vw',
				data: {
					project: { ...this.project },
					isEmployee,
				},
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.toPromise();

		if (!isNil(newNote) && oldNoteContent !== newNote) {
			await this.updateProjectNote(newNote);
		}
	}
}
