import { NgModule } from '@angular/core';
import { FeatureFlagDirective } from './feature-flag.directive';

const DIRECTIVE = [FeatureFlagDirective];

@NgModule({
	declarations: [DIRECTIVE],
	imports: [],
	exports: [DIRECTIVE],
})
export class FeatureFlagModule {}
