import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { initAppAction, setNavbarStateAction, setSidebarStateAction } from '../actions/app.actions';
import { AppState } from '../state/app.state';
import { filter, map, mergeMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { selectIsLoggedIn, selectRouterState } from '../selectors/app.selectors';
import { NavigationEnd, Router } from '@angular/router';

const sideBarDisabledRoutes: string[] = [
	'/pdf-viewer',
	'/authentication',
	'/publicFolders',
	'/inviteLink',
	'/company-init',
	'/checkout',
	'/settings',
	'/archive',
];

const navBarDisabledRoutes: string[] = ['/company-init', '/inviteLink'];

@Injectable()
export class AppEffects implements OnInitEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly router: Router,
	) {}

	ngrxOnInitEffects(): Action {
		return initAppAction();
	}

	initSidebar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initAppAction),
			mergeMap(() => {
				return combineLatest([
					this.store.select(selectIsLoggedIn),
					this.router.events.pipe(
						filter((event) => event instanceof NavigationEnd),
					) as Observable<NavigationEnd>,
				]);
			}),
			map(([isLoggedIn, routerEvent]) => {
				const isSideEnabled =
					isLoggedIn && !sideBarDisabledRoutes.some((route) => routerEvent.url.startsWith(route));
				return setSidebarStateAction({ state: !!isSideEnabled });
			}),
		),
	);

	initNavbar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initAppAction),
			mergeMap(() =>
				combineLatest([this.store.select(selectIsLoggedIn), this.store.select(selectRouterState)]),
			),
			map(([isLoggedIn, currentRouteState]) => {
				const isNavbarEnabled =
					isLoggedIn &&
					currentRouteState &&
					!navBarDisabledRoutes.some((route) => currentRouteState.state.url.startsWith(route));
				return setNavbarStateAction({ state: !!isNavbarEnabled });
			}),
		),
	);
}
