import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { LinkService } from '@injectables/services/link/link.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectUserId } from '@store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import { updateProfileToc } from '@shared/firebase/profile/profile-update.functions';
import moment from 'moment';
import { TocService } from '@injectables/services/toc.service';
import { from } from 'rxjs';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';

@Component({
	selector: 'app-toc-declined-banner',
	templateUrl: './toc-declined-banner.component.html',
	styleUrls: ['./toc-declined-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class TocDeclinedBannerComponent {
	tocDeadline$ = from(this.tocService.getDueDate().then((date) => date.format('DD.MM.YYYY')));

	constructor(
		private readonly profileService: ProfileService,
		private readonly store: Store<AppState>,
		private readonly tocService: TocService,
		public readonly linkService: LinkService,
		private readonly remoteConfig: RemoteConfig,
	) {}

	async acceptToc(): Promise<void> {
		const profileId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
		const version = Number(await this.remoteConfig.getValueAsync('version_current_toc'));
		await this.profileService.updateProfileTransactional(
			profileId,
			updateProfileToc('accepted', moment().unix(), version),
		);
	}
}
