import { AngularFireStorage } from '@angular/fire/compat/storage';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FirebaseStorageConnector {
	constructor(private readonly firebaseStorage: AngularFireStorage) {}

	getDownloadUrl(location: string, bucket?: string): Promise<string> {
		if (!bucket) {
			return this.firebaseStorage.ref(location).getDownloadURL().pipe(take(1)).toPromise();
		}
		return this.firebaseStorage.storage.app
			.storage(`gs://${bucket}`)
			.ref(location)
			.getDownloadURL();
	}
}
