import { NgModule } from '@angular/core';
import { ThemeTogglerComponent } from './theme-toggler.component';
import { SharedModule } from '@craftnote/shared-angular-modules';

@NgModule({
	declarations: [ThemeTogglerComponent],
	imports: [SharedModule],
	exports: [ThemeTogglerComponent],
})
export class ThemeTogglerModule {}
