import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { entries, isNil } from 'lodash';
import { TimeRange } from '@globalTypes/interfaces/time-range.types';
import { ProjectFilters } from '@shared/models/project-filters.model';
import { GroupByOption, ProjectSortConfig } from '@work/project-search/types/project-sort.types';
import { OrderByType, SortByType } from 'domain-entities';

export const FilterDefaultConfig = {
	search: [''],
	status: [[]],
	folder: [[]],
	startDate: [{ start: null, end: null } as TimeRange],
	endDate: [{ start: null, end: null } as TimeRange],
	employees: [[]],
};

export const SortDefaultConfig: ProjectSortConfig = {
	sortBy: SortByType.ALPHABETICAL,
	groupBy: GroupByOption.NONE,
	sortDirection: OrderByType.ASC,
};

export class ProjectFilterFormHelper {
	readonly searchControl: UntypedFormControl;
	readonly statusControl: UntypedFormControl;
	readonly folderControl: UntypedFormControl;
	readonly startDateControl: UntypedFormControl;
	readonly endDateControl: UntypedFormControl;
	readonly employeesControl: UntypedFormControl;

	constructor(private readonly formGroup: UntypedFormGroup) {
		this.searchControl = this.formGroup.controls['search'] as UntypedFormControl;
		this.statusControl = this.formGroup.controls['status'] as UntypedFormControl;
		this.folderControl = this.formGroup.controls['folder'] as UntypedFormControl;
		this.startDateControl = this.formGroup.controls['startDate'] as UntypedFormControl;
		this.endDateControl = this.formGroup.controls['endDate'] as UntypedFormControl;
		this.employeesControl = this.formGroup.controls['employees'] as UntypedFormControl;
	}

	get statusControlValue(): (string | null)[] {
		return this.statusControl.value;
	}

	get folderControlValue(): string[] {
		return this.folderControl.value;
	}

	setFormGroupValues(filterValues: ProjectFilters): void {
		for (const key in filterValues) {
			this.formGroup.controls[key].setValue(filterValues[key]);
		}
	}

	get searchControlValue(): string {
		return this.searchControl.value;
	}

	get startDateControlValue(): TimeRange | null {
		return this.startDateControl.value;
	}

	get endDateControlValue(): TimeRange | null {
		return this.endDateControl.value;
	}

	get employeesControlValue(): [] {
		return this.employeesControl.value;
	}

	get isSearchActive(): boolean {
		return !!this.searchControlValue;
	}

	get isStatusActive(): boolean {
		return !!this.statusControlValue.length;
	}

	get isFolderActive(): boolean {
		return !!this.folderControlValue.length;
	}

	/**
	 * If the startDate value is not set this means we are looking for
	 * projects with no start date value which IS an active filter.
	 * Only when a start date is set with open ends do we include all values
	 * which IS NOT considered a filter.
	 * Vice versa for end date.
	 */
	get isStartDateActive(): boolean {
		return (
			isNil(this.startDateControlValue) ||
			!(isNil(this.startDateControlValue.start) && isNil(this.startDateControlValue.end))
		);
	}

	get isEndDateActive(): boolean {
		return (
			isNil(this.endDateControlValue) ||
			!(isNil(this.endDateControlValue.start) && isNil(this.endDateControlValue.end))
		);
	}

	get isEmployeesActive(): boolean {
		return this.employeesControlValue.length > 0;
	}

	get getNumberOfFiltersActive(): number {
		return (
			+this.isStatusActive +
			+this.isSearchActive +
			+this.isStartDateActive +
			+this.isEndDateActive +
			+this.isFolderActive +
			+this.isEmployeesActive
		);
	}

	get anyFilterActive(): boolean {
		return this.getNumberOfFiltersActive > 0;
	}

	get getNumberOfActiveNonSearchFilters(): number {
		return this.getNumberOfFiltersActive - +this.isSearchActive;
	}

	get anyNonSearchFilterActive(): boolean {
		return this.getNumberOfActiveNonSearchFilters > 0;
	}

	resetAllNonSearchFilters(): void {
		for (const control of entries(this.formGroup.controls)) {
			if (control[0] === 'search') {
				continue;
			} else if (['status', 'employees', 'folder'].includes(control[0])) {
				control[1].reset([]);
			} else if (['startDate', 'endDate'].includes(control[0])) {
				control[1].reset({ start: null, end: null });
			} else {
				control[1].reset();
			}
		}
	}
}
