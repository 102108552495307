<div #wrapper class="w-100 wrap d-flex flex-column px-5 pt-5 pb-4 bg-white">
	<div class="d-flex">
		<div class="flex-grow-1 w-25"></div>
		<div *ngIf="!showSuccess" class="d-flex flex-column justify-content-center align-items-center pb-4 w-100">
			<div class="mat-display-2 mb-2">{{ 'project-archivation-dialog.header.title' | translate }}</div>
			<div>{{ 'project-archivation-dialog.header.subtitle' | translate }}</div>
		</div>
		<div class="d-flex justify-content-end w-25">
			<mat-icon *ngIf="!(archivationInProgress$$ | async)" [matDialogClose]="showSuccess ? 'action-taken' : undefined">close</mat-icon>
		</div>
	</div>

	<ng-container [ngTemplateOutlet]="showSuccess ? success : content"></ng-container>
</div>

<ng-template #content>
	<div class="pl-1">
		<div class="p-2">
			<mat-checkbox [checked]="allSelected$ | async" [disabled]="archivationInProgress$$ | async" (change)="setAll($event)">
				<span class="mat-small"
					>{{ 'project-archivation-dialog.archive-all.label' | translate }} ({{ (activeProjects$ | async).length }})</span
				></mat-checkbox
			>
		</div>
	</div>
	<div class="overflow-auto flex-grow-1" [class.error-border]="showErrorHint">
		<cdk-virtual-scroll-viewport itemSize="64" class="h-100">
			<ng-container *cdkVirtualFor="let chunk of chunks$ | async">
				<div class="d-flex archivation-card-row pb-2 px-1">
					<ng-container
						*ngFor="let project of chunk"
						[ngTemplateOutlet]="archivationCard"
						[ngTemplateOutletContext]="{ $implicit: project }"
					></ng-container>
				</div>
			</ng-container>
		</cdk-virtual-scroll-viewport>
	</div>
	<div *ngIf="showErrorHint" class="pt-1 text-warn">{{ 'project-archivation-dialog.error.no-projects' | translate }}</div>
	<div class="py-2">
		<mat-dialog-actions class="d-flex justify-content-end">
			<button mat-button color="accent" [disabled]="archivationInProgress$$ | async" (click)="onClose()">
				{{ 'project-archivation-dialog.actions.later' | translate }}
			</button>
			<button
				mat-flat-button
				color="accent"
				[disabled]="archivationInProgress$$ | async"
				[loading]="archivationInProgress$$ | async"
				(click)="onArchive()"
			>
				{{ 'project-archivation-dialog.actions.archive' | translate }}
			</button>
		</mat-dialog-actions>
	</div>

	<div class="d-flex flex-column mt-2 w-100">
		<div class="did-you-know d-flex p-3 mat-background-grey-200">
			<img alt="cleaning-up" src="assets/images/archivation-dialog.svg" />
			<div>
				<h1 class="mb-1">{{ 'project-archivation-dialog.did-you-know.title' | translate }}</h1>
				<p [innerHTML]="'project-archivation-dialog.did-you-know.subtitle' | translate"></p>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #archivationCard let-project>
	<div
		*ngIf="project; else empty"
		[class.archivation-card__selected]="(selectedProjectsMap$ | async)[project.id]"
		class="d-flex align-items-center archivation-card py-3 pl-2 pr-1 w-100 min-width-0"
	>
		<mat-checkbox
			[checked]="(selectedProjectsMap$ | async)[project.id]"
			[disabled]="archivationInProgress$$ | async"
			(change)="selectProject(project, $event)"
		></mat-checkbox>
		<div class="archivation-card__days-unused flex-shrink-0 px-2">
			{{ project.daysSinceUsage }}
			{{ 'project-archivation-dialog.list.unused' | translate }}
		</div>
		<div class="archivation-card__project-info min-width-0">
			{{ project.name
			}}<ng-container *ngIf="project.orderNumber">
				- <span class="font-weight-light">{{ project.orderNumber }}</span></ng-container
			>
		</div>
	</div>
</ng-template>

<ng-template #success>
	<div class="d-flex flex-column justify-content-center align-items-center h-100">
		<div class="success__icon" #lottiContainer></div>
		<div class="mat-display-3 mb-4">{{ 'project-archivation-dialog.success.title' | translate }}</div>
		<span
			[innerHTML]="'project-archivation-dialog.success.subtitle' | translate: { numberOfProjects: selectedProjects$$.value.length }"
			class="text-center"
		></span>
	</div>
</ng-template>

<ng-template #empty><div class="pl-2 pr-1 w-100 empty"></div></ng-template>
