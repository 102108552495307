import { animate, animation, style, transition, trigger } from '@angular/animations';

const defaultParams = { leave: { time: '2s' }, enter: { time: '2s' } };

interface DefaultParams {
	leave: { time: string };
	enter: { time: string };
}

export const fadeInOutAnimation = (params: DefaultParams = defaultParams) =>
	trigger('fadeInOutAnimation', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate(`${params.enter.time} ease-out`, style({ opacity: 1 })),
		]),
		transition(
			':leave',
			animation([
				style({ opacity: 1 }),
				animate(`${params.leave.time} ease-in`, style({ opacity: 0 })),
			]),
			{ params: params.leave },
		),
	]);
