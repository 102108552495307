import { createEntityAdapter } from '@ngrx/entity';
import { Export } from 'domain-entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromExport from '../actions/export.actions';
import { ExportState } from '../state/ExportState';

const exportEntityAdapter = createEntityAdapter<Export>();
export const initialState: ExportState = exportEntityAdapter.getInitialState({
	startedExports: [],
});

const reducer = createReducer(
	initialState,
	on(fromExport.addExports, (state, payload) => {
		return exportEntityAdapter.upsertMany(payload.exports, state);
	}),
	on(fromExport.deleteExports, (state, payload) => {
		return exportEntityAdapter.removeMany(payload.ids, state);
	}),
	on(fromExport.exportStartedAction, (state, payload) => {
		return { ...state, startedExports: [...state.startedExports, payload.export] };
	}),
	on(fromExport.clearExports, (state) => {
		exportEntityAdapter.removeAll(state);
		return { ...state, startedExports: [] };
	}),
);

export const exportEntitySelectors = exportEntityAdapter.getSelectors();

// tslint:disable-next-line:typedef
export function exportReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
