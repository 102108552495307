import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProfileLimit } from 'domain-entities';
import { Subject } from 'rxjs';
import { ProfileLimitKey, ProfileLimitsRequire } from '@shared/models/profile-limit-key.enum';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
	selector: '[appHasLimits]',
})
export class HasLimitsDirective implements OnDestroy {
	private destroy$ = new Subject();

	private limitKeys: Array<ProfileLimitKey>;
	private profileLimit: ProfileLimit;
	private _require: ProfileLimitsRequire = ProfileLimitsRequire.ALL;

	constructor(
		private profileLimitService: ProfileLimitsService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {
		this.profileLimitService
			.getProfileLimits()
			.pipe(takeUntil(this.destroy$))
			.subscribe((profileLimit) => {
				this.profileLimit = profileLimit;
				this.updateView();
			});
	}

	@Input()
	set appHasLimits(limitKeys: Array<ProfileLimitKey>) {
		this.limitKeys = limitKeys;
		this.updateView();
	}

	@Input()
	set require(require: ProfileLimitsRequire) {
		this._require = require || this._require;
	}

	get require(): ProfileLimitsRequire {
		return this._require;
	}

	private isAccessibleLimit(limitKeys: Array<ProfileLimitKey>): boolean {
		if (!this.profileLimit || !this.limitKeys) {
			return true;
		}

		if (this.require === ProfileLimitsRequire.ONE) {
			return limitKeys.some((limitKey) => {
				return this.profileLimitService.isAccessibleLimit(limitKey);
			});
		}

		const accessibleLimit = limitKeys.filter((limitKey) => {
			return this.profileLimitService.isAccessibleLimit(limitKey);
		});
		return limitKeys.length === accessibleLimit.length;
	}

	private updateView(): void {
		this.viewContainerRef.clear();
		if (!this.profileLimit) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
			return;
		}
		const hasRight = this.isAccessibleLimit(this.limitKeys);
		if (hasRight) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
