import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BrowserLanguage, BrowserService } from '@injectables/services/browser.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import moment from 'moment/moment';

@Component({
	selector: 'app-firefox-change-settings',
	templateUrl: './firefox-change-settings.component.html',
	styleUrls: ['./firefox-change-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirefoxChangeSettingsComponent {
	@Output() completed = new EventEmitter<void>();

	constructor(
		private readonly browserService: BrowserService,
		private readonly localStorageService: LocalStorageService,
	) {}

	get browserLanguage(): BrowserLanguage {
		return this.browserService.getBrowserLanguage();
	}

	get changeSettingsImageURL(): string {
		return `assets/images/${this.browserLanguage}-firefox-change-settings.png`;
	}

	get changeSettingsMenuImageURL(): string {
		return `assets/images/${this.browserLanguage}-firefox-settings-menu.png`;
	}

	async close(): Promise<void> {
		await this.localStorageService.set('firefoxBrowserSettingsVisit', moment().unix());
		this.completed.emit();
	}
}
