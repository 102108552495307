import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import {
	NotificationStatusType,
	OrderByType,
	ProfileSetting,
	SortByType,
	SortOptions,
} from 'domain-entities';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { COLLECTION_PROFILE_SETTING } from '@shared/constants/firebase';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { selectUserId } from '@store/selectors/app.selectors';
import { Store } from '@ngrx/store';

const DEFAULT_PROFILE_SETTING: ProfileSetting = {
	sortOptions: {
		sortBy: SortByType.LAST_EDITED,
		orderBy: OrderByType.DESC,
	},
	notificationStatus: NotificationStatusType.SHOW,
};

@Injectable({
	providedIn: 'root',
})
export class ProfileSettingService {
	private profileSetting$ = new BehaviorSubject<ProfileSetting>(DEFAULT_PROFILE_SETTING);

	constructor(
		private readonly afStore: AngularFirestore,
		private readonly afAuth: AngularFireAuth,
		private readonly store: Store,
	) {
		this.subscribeToProfileSettingChanges();
	}

	subscribeToProfileSettingChanges(): void {
		this.store
			.select(selectUserId)
			.pipe(
				switchMap((userID) => {
					if (!userID) {
						return of(DEFAULT_PROFILE_SETTING);
					}
					return this.getProfileSettingDocument(userID);
				}),
			)
			.subscribe((profileSetting) => this.profileSetting$.next(profileSetting));
	}

	getProfileSettings(): Observable<ProfileSetting> {
		return this.profileSetting$.asObservable();
	}

	getProfileSettingDocument(uid: string): Observable<ProfileSetting> {
		return this.afStore
			.collection<ProfileSetting>(COLLECTION_PROFILE_SETTING)
			.doc<ProfileSetting>(uid)
			.valueChanges()
			.pipe(filter((projectSetting) => !!projectSetting));
	}

	async muteUnMuteProjectChat(projectId: string): Promise<void> {
		const currentUserId = await firstValueFrom(this.store.select(selectUserId));
		const profileSetting = { ...this.profileSetting$.value };
		const mutedPushes = profileSetting?.mutedPushes ?? { ChatNewMessage: { identifiers: [] } };
		const chatNewMessage = mutedPushes.ChatNewMessage ?? { identifiers: [] };
		const identifiers = chatNewMessage.identifiers;

		const projectIndex = identifiers.indexOf(projectId);
		if (projectIndex === -1) {
			identifiers.push(projectId);
		} else {
			identifiers.splice(projectIndex, 1);
		}

		profileSetting.mutedPushes = mutedPushes;
		await this.setProfileSettings(profileSetting, currentUserId);
	}

	async updateSortByOptions(sortOptions: SortOptions): Promise<void> {
		const projectSetting = { ...this.profileSetting$.value, sortOptions };

		await this.afAuth.authState
			.pipe(
				filter((user) => !!user),
				take(1),
				map((user) => this.setProfileSettings(projectSetting, user.uid)),
			)
			.toPromise();
	}

	private async setProfileSettings(profileSetting: ProfileSetting, uid: string): Promise<void> {
		await this.afStore
			.collection<ProfileSetting>(COLLECTION_PROFILE_SETTING)
			.doc<ProfileSetting>(uid)
			.set(profileSetting);
	}
}
