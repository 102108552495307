import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';

export interface ProjectEditDetailDialogData {
	title: string;
	formLabel: string;
	formValue: string;
	primaryBtnText: string;
	formType: 'text' | 'textarea';
}

@Component({
	selector: 'app-project-edit-detail-dialog',
	templateUrl: './project-edit-detail-dialog.component.html',
	styleUrls: ['./project-edit-detail-dialog.component.scss'],
})
export class ProjectEditDetailDialogComponent {
	editForm = this.fb.group({
		content: [this.data?.formValue],
	});

	constructor(
		private readonly dialog: MatDialogRef<ProjectEditDetailDialogComponent>,
		private readonly fb: UntypedFormBuilder,
		@Inject(MAT_DIALOG_DATA) readonly data: ProjectEditDetailDialogData,
	) {}

	closeModal(): void {
		this.dialog.close(null);
	}

	onSubmitForm(): void {
		this.dialog.close(this.editForm.value?.content);
	}
}
